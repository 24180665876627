
import * as angular from 'angular';
import { MeurhSolicitacoesvtsadicionaisService } from './solicitacoesvtsadicionais.service';
import { MeurhSolicitacoesvtsadicionaisListRouting } from './solicitacoesvtsadicionais.routes';
import { MeurhSolicitacoesvtsadicionaisIndexController } from './index/solicitacoesvtsadicionais.index.controller';
import { MeurhSolicitacoesvtsadicionaisShowController } from './show/solicitacoesvtsadicionais.show.controller';
import { MeurhSolicitacoesvtsadicionaisShowFormController } from './showform/solicitacoesvtsadicionais.showform.controller';
import { MeurhSolicitacoesvtsadicionaisEditController } from './edit/solicitacoesvtsadicionais.edit.controller';
import { MeurhSolicitacoesvtsadicionaisFormController } from './form/solicitacoesvtsadicionais.form.controller';
import { MeurhSolicitacoesvtsadicionaisNewController } from './new/solicitacoesvtsadicionais.new.controller';
import { MeurhSolicitacoesvtsadicionaisShowFormComponent } from './showform/solicitacoesvtsadicionais.showform.component';
import { MeurhSolicitacoesvtsadicionaisFormComponent } from './form/solicitacoesvtsadicionais.form.component';

export const MeurhSolicitacoesvtsadicionaisModule = angular.module('MeurhSolicitacoesvtsadicionaisModule', ['ui.router.state', ])
    .controller('MeurhSolicitacoesvtsadicionaisIndexController', MeurhSolicitacoesvtsadicionaisIndexController)
    .controller('MeurhSolicitacoesvtsadicionaisShowController', MeurhSolicitacoesvtsadicionaisShowController)
    .controller('MeurhSolicitacoesvtsadicionaisShowFormController', MeurhSolicitacoesvtsadicionaisShowFormController)
    .controller('MeurhSolicitacoesvtsadicionaisEditController', MeurhSolicitacoesvtsadicionaisEditController)
    .controller('MeurhSolicitacoesvtsadicionaisFormController', MeurhSolicitacoesvtsadicionaisFormController)
    .controller('MeurhSolicitacoesvtsadicionaisNewController', MeurhSolicitacoesvtsadicionaisNewController)
    .component('meurhSolicitacoesvtsadicionaisShowFormComponent', MeurhSolicitacoesvtsadicionaisShowFormComponent)
    .component('meurhSolicitacoesvtsadicionaisFormComponent', MeurhSolicitacoesvtsadicionaisFormComponent)
    .service('MeurhSolicitacoesvtsadicionaisService', MeurhSolicitacoesvtsadicionaisService)
    .config(MeurhSolicitacoesvtsadicionaisListRouting)
    .name;
