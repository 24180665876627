declare var nsjGlobals: any;
import { IDepartamentoPessoal } from '../models/departamentopessoal.model';

export class DepartamentoPessoalIndexController {
    static $inject = ['$rootScope', '$scope', 'DepartamentoPessoalService', 'Usuarios', '$uibModal', 'TrabalhadoresService', '$state', 'NewToaster', '$stateParams', 'EstabelecimentosService', 'ModalCardsService'];

    public loadAvisos: any = false;
    public isLoadingModal: any = false;
    public filtroAviso: any = null;
    public avisos: any;
    public modalValues: any;
    public filtro: any;
    public semGeridos: any;
    public semGeridosAtivos: any;
    public estabelecimento: any;
    public totalizadores: any;

    constructor(
        // public $uibModalInstance: any,
        public $rootScope: any,
        public $scope: any,
        public DepartamentoPessoalService: any,
        public Usuarios: any,
        public $uibModal: any,
        public TrabalhadoresService: any,
        public $state: any,
        public NewToaster: any,
        public $stateParams: any,
        public EstabelecimentosService: any,
        public ModalCardsService: any
    ) { }

    isBusy() {
        return this.DepartamentoPessoalService.busy;
    }

    // ok() {
    //     this.$uibModalInstance.close();
    // }

    // cancel() {
    //     this.$uibModalInstance.dismiss('cancel');
    // }

    retornoTextoDias(operando1: any, operando2: any = 0) {
        let retorno = '';
        operando1 = operando1 === undefined ? 0 : operando1;
        operando2 = operando2 === undefined ? 0 : operando2;
        let somatorio = operando1 + operando2;
        retorno = somatorio === 1 ? somatorio + ' Dia' : somatorio > 1 ? somatorio + ' Dias' : somatorio;
        return retorno;
    }

    redirectApontamentosList() {
        let stringRota = this.defineRedirecionamentoApontamentos();
        if (stringRota !== '') {
            this.$state.go(stringRota, { 'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento });
        }
    }

    defineRedirecionamentoApontamentos() {
        if (this.$rootScope.temPermissao('registrar_apontamento')) {
            return 'apontamentos_apontamentos';
        } else if (this.$rootScope.temPermissao('registrar_apontamento_grade')) {
            return 'apontamentos_grade';
        }
        return '';
    }

    possuiAcessoApontamentos() {
        if (this.$rootScope.temPermissao(['registrar_apontamento', 'registrar_apontamento_grade'])) {
            return true;
        }
        return false;
    }

    defineTituloSecaoAvisos() {
        if (this.avisos === null || this.avisos === undefined || this.avisos.length === 0) {
            return 'Avisos';
        } else if (this.avisos.length === 1) {
            return '1 Aviso';
        } else {
            return this.avisos.length + ' Avisos';
        }
    }

    defineRedirecionamentoLinkFuncionarios(entity: IDepartamentoPessoal) {
        if (entity.tipo_pendencia !== 5) {
            if (this.$rootScope.temPermissao('visualizar_funcionarios')) {
                this.$state.go('trabalhadores_show', { 'trabalhador': entity.id_funcionario });
            }
        } else {
            var stringRota = this.defineRedirecionamentoApontamentos();
            if (stringRota !== '') {
                this.$state.go(stringRota, { 'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento });
            }
        }
    }

    selectCard(param: any) {
        this.isLoadingModal = true;
        switch (param) {
            case 'ferias':
                this.modalValues = {
                    type: 'ferias',
                    title: 'Funcionários de Férias'
                };
                this.DepartamentoPessoalService
                    .carregaTrabalhadores({ 'estabelecimento': this.$scope.session.entities.estabelecimento.estabelecimento, 'grupoempresarial': this.$scope.session.entities.grupoempresarial.codigo, 'status': '03' })
                    .then((response: any) => {
                        this.modalValues.trabalhadores = response.data;
                        this.isLoadingModal = false;
                        this.ModalCardsService.open(null, this.modalValues);
                    });
                break;
            case 'dobraFerias':
                this.modalValues = {
                    type: 'dobraferias',
                    title: 'Dobra de Férias de Funcionários'
                };
                this.DepartamentoPessoalService
                    .carregaDobraFerias(this.$scope.session.entities.estabelecimento.estabelecimento)
                    .then((response: any) => {
                        this.modalValues.trabalhadores = response.data;
                        this.isLoadingModal = false;
                        this.ModalCardsService.open(null, this.modalValues);
                    });
                break;
            case 'afastamentos':
                this.modalValues = {
                    type: 'afastamentos',
                    title: 'Funcionários Afastados'
                };
                this.TrabalhadoresService.constructors = { 'estabelecimento': nsjGlobals.get('estabelecimento'), 'grupoempresarial': this.$scope.session.entities.grupoempresarial.codigo, 'status': '04,05,06,07,08,09,10,12,13,14,15,16' };
                this.modalValues.trabalhadores = this.TrabalhadoresService.reload();
                this.isLoadingModal = false;
                this.ModalCardsService.open(null, this.modalValues);
                break;
            default:
                break;
        }
    }

    searchAvisos(aviso: any) {
        return true;
    }

    firstLoad() {
        this.DepartamentoPessoalService.constructors = {
            estabelecimento: nsjGlobals.get('estabelecimento')
        };

        if (this.$rootScope.modoGestorPermissao('visualizar_funcionarios')) {
            this.semGeridos = nsjGlobals.get('semGeridos');
            this.semGeridosAtivos = nsjGlobals.get('semGeridosAtivos');
        }

        this.estabelecimento = this.$rootScope.nsjGlobals.a.estabelecimentosInfos.filter((estabelecimento: any) => {
            return this.DepartamentoPessoalService.constructors.estabelecimento === estabelecimento.id;
        });

        this.DepartamentoPessoalService.avisosFilter = {};
        this.DepartamentoPessoalService.carregaTotalizadores(nsjGlobals.get('estabelecimento')).then((response: any) => {
            this.totalizadores = response.data;
        });
        this.DepartamentoPessoalService.carregaAvisos(nsjGlobals.get('estabelecimento')).then((response: any) => {
            this.avisos = response.data;
            this.DepartamentoPessoalService.busy = false;
        });
    }

    filter() {
        this.DepartamentoPessoalService.avisosFilter = this.filtro;
        this.DepartamentoPessoalService.carregaAvisos(nsjGlobals.get('estabelecimento')).then((response: any) => {
            this.avisos = response.data;
            this.DepartamentoPessoalService.busy = false;
        });
    }

    efetuarRescisaoAdmissao(aviso: any) {
        return (aviso.dias_para_ocorrencia <= 7) && (aviso.tipo_pendencia === 4);
    }

    efetuarAdmissaoFuncionario(aviso: any) {
        this.DepartamentoPessoalService.AdmitirFuncionario(aviso.id_funcionario, this.$scope.usuario)
            .then((response: any) => {
                var resposta = response.data;
                if (resposta.admitido) {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A Admissão do Funcionário ' + aviso.nome_funcionario + ' foi realizada com sucesso!'
                    });

                    this.DepartamentoPessoalService.carregaAvisos(nsjGlobals.get('estabelecimento')).then((response: any) => {
                        this.avisos = response.data;
                        this.DepartamentoPessoalService.busy = false;
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Funcionário "' + aviso.nome_funcionario + '" não encontrado.'
                    });
                }
            }, () => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao realizar a Admissão do Funcionário ' + aviso.nome_funcionario + '!'
                });
            });
    }
}
