import angular from 'angular';
import moment from 'moment';
import { ExportacaoArquivoAejService } from '../../ExportacaoArquivoAEJ/exportacaoarquivoaej.service';
import { Empresa, Estabelecimento, Obra } from '../../../../../core/usuario/models/usuario-model';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { ObrasService } from '../../../../Obras/obras.service';


export class ExportacaoArquivoAejIndexController {

    static $inject = ['$scope', '$rootScope', '$stateParams', 'ExportacaoArquivoAejService', 'ListagemService', '$state', 'NewToaster', 'ObrasService'];
    public busy: boolean = false;
    public dataFinal: string | moment.Moment = moment().endOf('month').format('YYYY-MM-DD');
    public dataInicial: string | Date = new Date();
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public obras : Array<Obra>;
    public filters: any = {
        tenant: this.$rootScope.nsjGlobals.a.tenant,
        empresa: '',
        estabelecimento: '',
        datainicial: null,
        datafinal: null,
        obra: ''
    };
    public botaoExportar: any = false;
    public alertDataFinal: any = false;
    public alertEmpresa: any = false;
    public alertEstabelecimento: any = false;
    public inputDatafinal: any;
    public inputEmpresa: any;
    public inputEstabelecimento: any;
    public inputObra: any = false;

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean, temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, session: any, configuracoes: any, nsjGlobals: any },
        public $stateParams: angular.ui.IStateParamsService,
        public ExportacaoArquivoAejService: ExportacaoArquivoAejService,
        public ListagemService: ListagemService,
        public $state: any,
        public NewToaster: any,
        public ObrasService: ObrasService
    ) {
        if (!this.$rootScope.liberadoTela('LIBERAR_PONTO_EXPORTACAO_AEJ')) {
            $state.go('dp');
        }
        this.busy = false;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas);
        this.obras = this.ObrasService.load();

        $scope.$on('arquivosaej_index_sucesso', (event: angular.IAngularEvent, args: any) => {
            this.busy = false;
            this.NewToaster.pop({
				type: 'success',
				title: 'O arquivo AEJ foi exportado com sucesso.',
			});
        });
        $scope.$on('arquivosaej_index_error', (event: angular.IAngularEvent, args: any) => {
			this.busy = false;
            this.NewToaster.pop({
				type: 'error',
				title: 'O arquivo AEJ não foi exportado. Dados não encontrados, verifique novamente os parâmetros da requisição.',
			});
        });

    }

    filtrarEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas) : [];
        this.filters.estabelecimento = '';
        this.alertEmpresa = false;
        if (this.inputEmpresa) {
            this.inputEmpresa.style.borderColor = '';
        }
    }

    removeAlertaDF(): void {
        this.alertDataFinal = false;
        if (this.inputDatafinal) {
            this.inputDatafinal.style.borderColor = '';
        }
    }
    filtrarObra(): void {
        this.alertEstabelecimento = false;
        if (this.inputEstabelecimento) {
            this.inputEstabelecimento.style.borderColor = '';
        }
        this.ObrasService.constructors.estabelecimento = this.filters.estabelecimento;
        this.ObrasService.reload(false);
    }
    cleanFiltro(): void {
        this.filters.datainicial = '';
        this.filters.datafinal = '';
        this.filters.empresa = '';
        this.filters.estabelecimento = '';
        this.filters.obra = '';
    }

    exportar(): void {
        this.busy = true;
        const rota = 'arquivosaej_index';
        const constructor = {
            tenant: this.filters.tenant,
            estabelecimento: this.filters.estabelecimento,
            datainicial: this.filters.datainicial,
            datafinal: this.filters.datafinal,
            obra: this.filters.obra ? this.filters.obra : null
        };

        this.ExportacaoArquivoAejService.exportar(rota, constructor);
    }
    escondeBotao() {
        if (this.filters.datainicial) {
            this.botaoExportar = true;
        } else {
            this.botaoExportar = false;
        }
    }
    carregarExportar() {
        this.inputDatafinal = document.getElementById('datafinal');
        this.inputEmpresa = document.getElementById('empresa');
        this.inputEstabelecimento = document.getElementById('estabelecimento');
        if (!this.filters.datafinal) {
            this.inputDatafinal.style.borderColor = '#CC0000';
            this.alertDataFinal = true;
        } else if (!this.filters.empresa) {
            this.inputEmpresa.style.borderColor = '#CC0000';
            this.alertEmpresa = true;
        } else if (!this.filters.estabelecimento) {
            this.inputEstabelecimento.style.borderColor = '#CC0000';
            this.alertEstabelecimento = true;
        } else {
            this.exportar();
            this.cleanFiltro();
        }
    }

}
