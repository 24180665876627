export class ModalCancelarController {
    static $inject = ['entity', '$uibModalInstance', 'tipo', 'textoPersonalizado'];

    public textnum = this.entity ? (this.entity.trabalhador ? this.entity.trabalhador.nome : '') : null;

    // texto default para solicitações diversas
    public textos: any = {
        title: 'Cancelar',
        subtitle: 'Você está prestes a cancelar a ' + this.tipo + ' de',
        confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
        confirmTextNum: this.textnum,
        closeButton: 'Voltar',
        confirmButton: 'Cancelar solicitação',
    };

    constructor(
        public entity: any,
        public $uibModalInstance: any,
        public tipo: any,
        public textoPersonalizado: any
    ) {
        // texto personalizado
        if (textoPersonalizado) {
            this.textos = textoPersonalizado;
        }
    }

    confirmar() {
        this.$uibModalInstance.close(this.entity);
    }

    close() {
        this.$uibModalInstance.dismiss('cancelar');
    }
}
