import angular from 'angular';
import { MeurhSolicitacoesrecisoesFormController } from './solicitacoesrescisoes.form.controller';

export class MeurhSolicitacoesrecisoesFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesrecisoesFormComponent';
	static controller = MeurhSolicitacoesrecisoesFormController;
	static template = require('!!html-loader!./solicitacoesrescisoes.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
		tiposubmit: '='
	};
}
