import * as angular from 'angular';
import { MeurhVagasValetransporteFormmodalController } from './valetransporte.formmodal.controller';

export class MeurhVagasValetransporteFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhVagasValetransporteFormmodalComponent';
	static controller = MeurhVagasValetransporteFormmodalController;
	static template = require('!!html-loader!./valetransporte.formmodal.html');
	static transclude = true;
	static bindings = {
		valetransporte: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
