export class ModalCardsService {
    static $inject = ['$uibModal'];

    constructor(public $uibModal: ng.ui.bootstrap.IModalService) { }

    open(size: any = null, modalValues: any): angular.ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modalcards.html'),
            controller: 'ModalCardsController',
            controllerAs: 'self',
            size: size,
            windowClass: '',
            backdrop: true,
            resolve: {
                modalValues: (): any => modalValues
            }
        });
    }
}

