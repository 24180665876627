import * as angular from 'angular';
import { MeurhSolicitacoesFaltasService } from './solicitacoesfaltas.service';

export const MeurhSolicitacoesfaltasListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        let resolve = {
            'entity': [
                'MeurhSolicitacoesFaltasService',
                '$stateParams',
                '$state',
                '$q',
                function (
                    entityService: MeurhSolicitacoesFaltasService,
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ): angular.ui.IStateParamsService | angular.IQService | {} {
                    if ($stateParams['entity']) {
                        return $stateParams['entity'];
                    }

                    if (!$stateParams['solicitacao']) {
                        return {};
                    }

                    return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                        entityService.get($stateParams['solicitacao'])
                            .then((data: any) => {
                                resolve(data);
                            })
                            .catch((error: any) => {
                                if (error.status === 404) {
                                    if ($state.href('meurh_solicitacoesfaltas_not_found', $stateParams)) {
                                        $state.go('meurh_solicitacoesfaltas_not_found', $stateParams);
                                    } else {
                                        $state.go('not_found', $stateParams);
                                    }
                                }
                            });
                    });
                }
            ]
        };

        $stateProvider
            .state('meurh_solicitacoesfaltas', {
                url: '/solicitacoes/faltas?q?situacao?estabelecimento',
                params: { q: { dynamic: true }, trabalhador: { tipo: '-1' } },
                template: require('html-loader!./index/solicitacoesfaltas.index.html'),
                controller: 'Meurh\SolicitacoesfaltasIndexController',
                controllerAs: 'mrh_slctcsflts_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('meurh_solicitacoesfaltas_funcionarios', {
                url: '/solicitacoes/faltas?q?situacao?estabelecimento',
                params: { q: { dynamic: true }, trabalhador: { tipo: '0' } },
                template: require('html-loader!./index/solicitacoesfaltas.index.html'),
                controller: 'Meurh\SolicitacoesfaltasIndexController',
                controllerAs: 'mrh_slctcsflts_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('meurh_solicitacoesfaltas_contribuintes', {
                url: '/solicitacoes/faltas?q?situacao?estabelecimento',
                params: { q: { dynamic: true }, trabalhador: { tipo: '1' } },
                template: require('html-loader!./index/solicitacoesfaltas.index.html'),
                controller: 'Meurh\SolicitacoesfaltasIndexController',
                controllerAs: 'mrh_slctcsflts_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('meurh_solicitacoesfaltas_estagiarios', {
                url: '/solicitacoes/faltas?q?situacao?estabelecimento',
                params: { q: { dynamic: true }, trabalhador: { tipo: '2' } },
                template: require('html-loader!./index/solicitacoesfaltas.index.html'),
                controller: 'Meurh\SolicitacoesfaltasIndexController',
                controllerAs: 'mrh_slctcsflts_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('meurh_solicitacoesfaltas_new', {
                parent: 'meurh_solicitacoesfaltas',
                url: '/new?tipo',
                resolve: resolve,
                template: require('html-loader!./new/solicitacoesfaltas.new.html'),
                controller: 'MeurhSolicitacoesfaltasNewController',
                controllerAs: 'mrh_slctcsflts_frm_nw_cntrllr'
            })
            .state('meurh_solicitacoesfaltas_show', {
                parent: 'meurh_solicitacoesfaltas',
                url: '/:solicitacao?path',
                resolve: resolve,
                template: require('html-loader!./show/solicitacoesfaltas.show.html'),
                controller: 'MeurhSolicitacoesfaltasShowController',
                controllerAs: 'mrh_slctcsflts_frm_shw_cntrllr'
            })
            .state('meurh_solicitacoesfaltas_edit', {
                parent: 'meurh_solicitacoesfaltas',
                url: '/:solicitacao/edit',
                resolve: resolve,
                template: require('html-loader!./edit/solicitacoesfaltas.edit.html'),
                controller: 'MeurhSolicitacoesfaltasEditController',
                controllerAs: 'mrh_slctcsflts_frm_edit_cntrllr'
            });
    }
];
