import * as angular from 'angular';
import { MeurhTransferenciaColaboradoresFormController } from './transferenciacolaboradores.form.controller';

export class MeurhTransferenciaColaboradoresFormComponent implements angular.IComponentOptions {
	static selector = 'meurhTransferenciaColaboradoresFormComponent';
	static controller = MeurhTransferenciaColaboradoresFormController;
	static template = require('!!html-loader!./transferenciacolaboradores.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<?',
		busy: '=?',
		action: '<?'
	};
}
