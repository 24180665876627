import angular from 'angular';

export const ApontamentosImportacaoListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		$stateProvider
			.state('apontamentos_importacao', {
				url: '/movimento/importacao',
				template: require('html-loader!./index/apontamentosimportacao.index.html'),
				controller: 'ApontamentosImportacaoIndexController',
				controllerAs: 'pntmnts_mprtc_lst_cntrllr',
				reloadOnSearch: false
			});
	}
];
