import angular from 'angular';
import { MeurhAdmissoesValetransporteFormmodalController } from './valetransporte.formmodal.controller';

export class MeurhAdmissoesValetransporteFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesValetransporteFormmodalComponent';
	static controller = MeurhAdmissoesValetransporteFormmodalController;
	static template = require('!!html-loader!./valetransporte.formmodal.html');
	static transclude = true;
	static bindings = {
		valetransporte: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
