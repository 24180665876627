import angular from 'angular';
import moment from 'moment';
import { MeurhFeriasService } from '../../ferias.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IFerias } from '../../models/ferias.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';
import { conversaoFormatoData, conversaoFormatoPeriodo } from '../../../../../utils/utils';

export class MeurhFeriasmarcacoesIndexController implements angular.IController {
	static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$http',
		'$stateParams',
		'$location',
		'nsjRouting',
		'NewToaster',
		'MeurhFeriasService',
		'ListagemService'
	];

	public busy: boolean = false;
	public dataInicial: string | null = null;
	public dataFinal: string | null = null;
	public dataAtual: moment.Moment = moment();
	public nomesMeses: Array<string> = new Array('Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro');
	public options = { 'situacao': 'Situação', 'estabelecimento': 'Estabelecimento', };
	public selects = {
		'situacao': {
			'-1': 'Rascunho',
			'0': 'Enviada',
			'1': 'Efetuada',
			'2': 'Fechada'
		},
	};
	public tipoInicial: string;
	public filters: any;
	public stateService: angular.ui.IStateService;
	public constructors: any;
	public entities: Array<IFerias>;

	public empresas: Array<Empresa>;
	public estabelecimentos: Array<Estabelecimento>;
	public listagemPermissao: Array<string> = ['visualizar_ferias'];
	public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

	constructor(
		public $rootScope: angular.IScope & {
			temPermissao: ((args: string) => boolean),
			temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
			session: any,
			nsjGlobals: any
		},
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $http: angular.IHttpService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: angular.ILocationService,
		public nsjRouting: any,
		public NewToaster: { pop: Function },
		public entityService: MeurhFeriasService,
		public ListagemService: ListagemService
	) {
		entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
		this.filters = entityService.filters = {};
		this.montaListagemVisibilidade();
		this.stateService = $state;
		this.constructors = entityService.constructors = [];
		entityService.reload();

		if (!$rootScope.temPermissao('visualizar_ferias')) {
			$state.go('dp', $stateParams);
		}

		for (var i in $stateParams) {
			if (['situacao', 'estabelecimento'].indexOf(i) > -1 && $stateParams[i] !== undefined) {
				entityService.filters[i] = $stateParams[i];
			} else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
				entityService.constructors[i] = $stateParams[i];
			}
		}

		$rootScope.$on('meurh_solicitacoesferias_deleted', (event: angular.IAngularEvent) => {
			entityService.reload();
		});

		$rootScope.$on('$destroy', () => {
			if (entityService.loading_deferred) {
				entityService.loading_deferred.resolve();
			}
		});

		$rootScope.$on('meurh_solicitacoesferias_submitted', (event: angular.IAngularEvent, args: any) => {
			if (!args.autosave) {
				entityService.reload();
			}
		});

		$rootScope.$on('meurh_solicitacoesferias_busca_padrao_list_finished', (event: angular.IAngularEvent, args: any) => {
			this.entities = args;
			this.tratarEntities();
		});
	}

	montaListagemVisibilidade(): void {
		this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
		this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
		this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
		this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
	}

	mudaEstabelecimentos(): void {
		this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
		this.filters.estabelecimento = '';
		this.filtraEmpresaEstabelecimento();
	}

	filtraEmpresaEstabelecimento(): void {
		this.entityService.constructors['empresa'] = this.filters.empresa;
		this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
		this.filterReload();
	}

	reload(): void {
		this.filterReload();
	}

	search(): Array<IFerias> {
		if (this.entityService.filter !== '') {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
		} else {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
		}

		var filter = { search: this.entityService.filter, filters: angular.copy(this.entityService.filters) };

		return this.entityService.search(filter);
	}

	loadMore(): void {
		this.entityService.loadMore();
	}

	finished(): boolean {
		return this.entityService.loadParams.finished;
	}

	generateRoute(route: string, params: any): string {
		return this.nsjRouting.generate(route, params, true);
	}

	isBusy(): boolean {
		return this.entityService.loadParams.busy;
	}

	filterReload(): void {
		if (this.filters.hasOwnProperty('situacao') && this.filters.situacao === '') {
			delete this.filters.situacao;
		}

		this.search();
	}

	filterReloadCreatedAt(): void {
		let filtroDataInvalido = false;

		if (this.dataInicial !== null && this.dataFinal !== null && this.dataInicial !== '' && this.dataFinal !== '' && this.dataInicial > this.dataFinal) {
			this.NewToaster.pop({
				type: 'error',
				title: 'A data inicial não pode ser posterior a data final'
			});

			filtroDataInvalido = true;
		}

		if (!filtroDataInvalido) {
			let datainiciogozo: Array<{ condition: string, value: string }> = [];

			if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
				this.dataInicial = null;
				this.constructors.datainicial = null;
			}

			if (this.dataInicial !== null) {
				datainiciogozo.push({
					condition: 'gte',
					value: this.dataInicial
				});

				delete this.constructors.datainicial;
			}

			if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
				this.dataFinal = null;
				this.constructors.datafinal = null;
			}

			if (this.dataFinal !== null) {
				datainiciogozo.push({
					condition: 'lte',
					value: this.dataFinal
				});

				delete this.constructors.datafinal;
			}

			this.constructors.datainiciogozo = datainiciogozo;
			this.entityService.constructors = this.constructors;
			this.entityService.reload();
		}
	}

	private tratarEntities(): void {
		this.entities.forEach((entity: IFerias) => {
			entity.datafimgozo = conversaoFormatoData(entity.datafimgozo);
			entity.datainiciogozo = conversaoFormatoData(entity.datainiciogozo);
			entity.created_at = conversaoFormatoPeriodo(entity.created_at);
		});
	}
}
