import angular from 'angular';
import { IFiltroEventoEscala } from '../models/escala.filtros.model';

export class MeurhEscalaVisualizacaoModalController {
  static $inject = ['$uibModalInstance', 'entity'];
  public entityCopy: IFiltroEventoEscala;

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public entity: IFiltroEventoEscala
  ) {
    this.entityCopy = angular.copy(this.entity);
  }

  ok(): void {
    this.entity = angular.copy(this.entityCopy);
    this.$uibModalInstance.close(this.entity);
  }

  close(): void {
    this.$uibModalInstance.dismiss('fechar');
  }
}
