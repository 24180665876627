import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesvtsFormController } from './solicitacoesalteracoesvts.form.controller';

export class MeurhSolicitacoesalteracoesvtsFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesalteracoesvtsFormComponent';
	static controller = MeurhSolicitacoesalteracoesvtsFormController;
	static template = require('!!html-loader!./solicitacoesalteracoesvts.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
