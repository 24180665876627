export class MeurhSolicitacoesalteracoesenderecosConfirmFecharService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: any) { }

	open(cnfEntity: any): any {
        return this.$uibModal.open({
            template: require('html-loader!./modal.fechar.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                function MeurhSolicitacoesalteracoesenderecosModal(
					entity: any,
					$uibModalInstance: any
                ) {
                    let selfModal = this;
                    selfModal.entity = entity;

                    selfModal.confirmar = function () {
                        $uibModalInstance.close(selfModal.entity);
                    };

                    selfModal.close = function () {
                        $uibModalInstance.dismiss('fechar');
                    };
                }
            ],
            controllerAs: 'confirm',
            windowClass: '',
            resolve: {
                entity: function () {
                    return cnfEntity;
                }
            }
        });
    }
}
