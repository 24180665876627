import ng from 'angular';
import moment from 'moment';

export class MeurhAdmissoesestagiarioDocumentacaoFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'Paises'];

    public entity: any;
    public uFArray = [
        'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'
    ];
    public categoriaCnh = ['A', 'B', 'C', 'D', 'E', 'AB', 'AC', 'AD', 'AE'];
    public ctpsPreenchida: boolean = false;
    public cnhPreenchida: boolean = false;

    // inicio - validação de datas
    public anoMinimo: moment.Moment;
    public anoMaximo: moment.Moment;
    public dataAtual: moment.Moment = moment();
    // fim - validação de datas

     constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public Paises: any,
    ) {
        this.bloqueiaAnoMinimo();
        this.bloqueiaAnoMaximo();
    }

    preenchePix(): void {
        if (this.entity.tiporecebimentobolsa === '128') { // cpf
            this.entity.chavepix = this.entity.cpf;
        }
    }

    verificaCtps(): void {
        if (this.valorExiste(this.entity.numeroctps)) {
            this.ctpsPreenchida = true;
        } else {
            this.ctpsPreenchida = false;
        }
    }

    public verificaCnh(): void {
        if (this.valorExiste(this.entity.numerocnh)) {
            this.cnhPreenchida = true;
        } else {
            this.cnhPreenchida = false;
        }
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    // inicio - tratamento de datas
    private bloqueiaAnoMinimo(): void {
        this.anoMinimo = moment().subtract(120, 'years'); // > 120 anos
    }

    private bloqueiaAnoMaximo(): void {
        this.anoMaximo = moment().add(120, 'years'); // < 120 anos
    }
    // fim - tratamento de datas
}
