import angular from 'angular';

export const MeurhApontamentosGradeListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
            .state('apontamentos_grade', {
                url: '/registro-grade?tipo',
                template: require('html-loader!./index/apontamentosgrade.index.html'),
                controller: 'MeurhApontamentosGradeIndexController',
                controllerAs: 'grdCtrl',
                reloadOnSearch: true
            });
    }
];
