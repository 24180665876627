import angular from 'angular';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { IAfastamento } from '../models/afastamento.model';
import { MeurhAfastamentosService } from '../afastamentos.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';

export class MeurhAfastamentosShowController implements angular.IController {
	static $inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$state',
        'MeurhAfastamentosService',
        'entity',
        'NewToaster',
        'ModalConfirmService',
        'WorkflowService',
        'MeurhSolicitacoes',
        'TiposDocumentosRequeridosService',
        'MeurhAcompanhamentoService',
    ];

    public action: string = 'retrieve';
    public busy: boolean = false;
    public constructors: any = {};
    public entitySave: Partial<IAfastamento> = {};
    public path: number = this.$stateParams.path;
	public tiposdocumentosrequeridos: Array<any>;
	public busyTipoDocumentos: boolean = true;
	public entityAnexo: IAnexo = {
        casodeuso: 'AFASTAMENTO'
    };
	public formAnexo: object = {};
	public editanexo: boolean = true;
	public entityAnexoLista: Array<IAnexo> = [];
	public adicionarAnexo: boolean = false;
    public actionAnexo: string = 'show';

    constructor(
        public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhAfastamentosService,
        public entity: IAfastamento,
        public NewToaster: any,
        public ModalConfirmService: any,
        public WorkflowService: WorkflowService,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
    ) {
        this.$scope.$on('meurh_solicitacoes_canceled', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				tipodocumentorequerido.file = '';
				return tipodocumentorequerido;
			});
		});

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();

        this.onDeleteSuccess();
        this.onDeleteError();
        this.onSubmitSuccess();
        this.onSubmitError();
    }

    $onInit(): void {
        this.documentosAnexos();
        this.afterReloadWorkflow();
        // this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesafastamentos_');
    }

    afterReloadWorkflow(): void {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
          this.$state.reload();
        });
    }

    delete(force: boolean): void {
        this.busy = true;
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    documentosAnexos(): void {
        const estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
        this.setEntityAnexo({
            solicitacao: this.entity.solicitacao,
            estabelecimento
        });
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.carregaTiposDocumentosRequeridos({
                empresa: this.entity.empresaobj.empresa,
                estabelecimento
            });
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
    }

    carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.AFASTAMENTO,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

	resetTiposDocumentosRequeridosService(): void {
		this.TiposDocumentosRequeridosService.entities = [];
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
	}

    setEntityAnexo(params: { solicitacao: string, estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
        this.entityAnexo['solicitacao'] = params.solicitacao;
    }

    onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de Afastamento foi excluída com sucesso!'
            });
            this.$state.go('meurh_afastamentos', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_delete_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (
                args.response.data &&
                typeof (args.response.data.message) !== 'undefined' &&
                args.response.data.message
            ) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao tentar excluir.'
                });
            }
        });
    }

    cancelar(entity: IAfastamento & ISolicitacao): void {
        let customText = {
            title: 'Cancelar',
            subtitle: 'Você está prestes a cancelar a solicitação de afastamento de',
            confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
            confirmTextNum: this.entity.trabalhadorobj.nome,
            closeButton: 'Voltar',
            confirmButton: 'Cancelar solicitação',
        };
        let confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de afastamento', customText);
        confirm.result.then((entity: IAfastamento & ISolicitacao) => {
            this.busy = true;
            this.MeurhSolicitacoes.cancelar(entity).then((response: any) => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A Solicitação de Afastamento foi cancelada com sucesso.'
                });
                this.entityService.reload();
                this.$state.go('meurh_afastamentos', angular.extend(this.entityService.constructors));
            }).catch((response: any) => {
                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao cancelar.'
                    });
                }
            }).finally(() => {
                this.busy = false;
            });
        }).catch((error: any) => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    enviar(): void {
        this.busy = true;
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = 0;
        this.entityService.save(this.entitySave, {
            empresa: this.entity.empresaobj.empresa,
            estabelecimento: this.entity.estabelecimentoobj.estabelecimento
        });
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de Afastamento foi enviada com sucesso!'
            });
            this.$state.go('meurh_afastamentos_show', {'solicitacao': args.entity.solicitacao});
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
				if (
                    args.response.data &&
                    typeof (args.response.data.message) !== 'undefined' &&
                    args.response.data.message
                ) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;
                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
                                    if (children[child].errors.hasOwnProperty(error)) {
                                        message += '<li>' + children[child].errors[error] + '</li>';
                                    }
                                }
                            }
                        }
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao enviar a Solicitação de Afastamento!'
                    });
                }
            }
        });
    }
}
