import angular = require('angular');
import moment = require('moment');

export class MeurhCreditosdescontosIndisponivelmodalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: any) { }

  open(tipo: any) {

    return this.$uibModal.open({
      template: require('html-loader!./creditosdescontos.indisponivelmodal.html'),
      controller: ['entity', '$uibModalInstance', '$sce', '$compile', '$scope', function MeurhCreditosdescontosIndisponivelModal(entity: any, $uibModalInstance: any, $sce: any, $compile: any, $scope: any) {
        var selfModal = this;
        selfModal.tipo = tipo;

        selfModal.close = () => {
          $uibModalInstance.dismiss('fechar');
        };
      }],
      controllerAs: '$ctrl',
      windowClass: '',
      resolve: {
        entity: function () {
          return angular.copy(tipo);
        }
      }
    });
  }
}
