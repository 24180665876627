import angular from 'angular';

export const SimulacaoRescisaoListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('simulacao_rescisao', {
                url: '/simulacoes/simulacaorescisao',
                template: require('html-loader!./index/simulacaorescisao.index.html'),
                controller: 'SimulacaoRescisaoIndexController',
                controllerAs: '$ctrl'
            });
    }
];
