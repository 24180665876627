import angular = require('angular');
export class MeurhConfirmarEnvioModalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: any) {
  }

  open() {
    return this.$uibModal.open({
      template: require('html-loader!./confirmarenvio.modal.html'),
      controller: 'meurhConfirmarEnvioModalController',
      controllerAs: 'mrh_cnfrmrnv_mdl_cntrllr',
      size: 'md',
    });
  }
}
