import angular from 'angular';
import { IAlteracaoFoto } from '../models/alteracaofoto.model';
import { MeurhAlteracaoFotoService } from '../alteracaofoto.service';
import { ModalConfirmService } from '../../../../shared/components/modal-confirm/modal.confirm.service';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';

export class MeurhAlteracaoFotoShowController {

    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        'NewToaster',
        'MeurhAlteracaoFotoService',
        'MeurhSolicitacoes',
        'ModalConfirmService',
        'WorkflowService'
    ];

    public entity: IAlteracaoFoto;
    public form: angular.IFormController;
    public action = 'show';
    public busy = false;

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean
        },
		public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: { pop: Function },
        public MeurhAlteracaoFotoService: MeurhAlteracaoFotoService,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public ModalConfirmService: ModalConfirmService,
        public WorkflowService: WorkflowService,
	) {
        if (
            !this.$rootScope.temPermissao('visualizar_alteracaofoto') ||
            !this.$rootScope.liberadoTela('LIBERAR_MEURH_SOLICITACAO_ALT_FOTO')
        ) {
            $state.go('dp');
        }

        this.busy = true;
        this.MeurhAlteracaoFotoService.get(this.$stateParams['solicitacao']);
        $scope.$on('meurh_solicitacoesalteracoesfoto_loaded', (event: angular.IAngularEvent, args: IAlteracaoFoto) => {
			this.entity = args;
            this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesalterecoesfoto_');
            this.busy = false;
		});
        $scope.$on('workflow_reloaded_after', (): void => {
            this.$state.reload();
        });
    }

    modalCancelar(entity: IAlteracaoFoto): void {
        const customText = {
            title: 'Cancelar',
            subtitle: 'Você está prestes a cancelar a solicitação de alteração de foto de',
            confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
            confirmTextNum: entity.trabalhadorobj.nome,
            closeButton: 'Voltar',
            confirmButton: 'Cancelar solicitação',
        };
        const confirm = this.ModalConfirmService.openCancelar(entity, 'Solicitação de alteração de foto', customText);
        confirm.result.then((entity: IAlteracaoFoto) => {
            this.cancelar(entity);
        }).catch(() => {/**/});
    }

    cancelar(entity: IAlteracaoFoto) {
        this.busy = true;
        this.MeurhSolicitacoes.cancelar(entity).then(() => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de alteração de foto foi cancelada com sucesso.'
            });
            this.$state.reload();
        }).catch((response: any) => {
            let error: string;
            if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                error = response.data.message;
            } else {
                error = 'Ocorreu um erro ao cancelar solicitação de foto.';
            }
            this.NewToaster.pop({
                type: 'error',
                title: error
            });
        }).finally(() => {
            this.busy = false;
        });
    }
}
