import ng from 'angular';
import { IWorkflowAcao, IWorkflowAcaoEnviar } from '../models/workflow.acao.model';
import angular from 'angular';
import { WorkflowService } from '../workflow.service';
import { WorkflowOldModalService } from '../modais/oldModal/workflow.old.service';
import { WorkflowDefaultModalService } from '../modais/defaultModal/workflow.default.service';
import { WorkflowCommentModalService } from '../modais/commentModal/workflow.comment.service';
import { WorkflowDynamicModalService } from '../modais/dynamicModal/workflow.dynamic.service';
import { ITrabalhador } from '../../Trabalhadores/models/trabalhador.model';

export class WorkflowListController implements ng.IController {

  static $inject = [
    'NewToaster',
    '$rootScope',
    'WorkflowService',
    'WorkflowOldModalService',
    'WorkflowDefaultModalService',
    'WorkflowCommentModalService',
    'WorkflowDynamicModalService'
  ];

  public acao: IWorkflowAcao;
  public acaoEnviar: IWorkflowAcaoEnviar;
  public colaborador: string;
  public workflowBusy: boolean;
  public workflowAcoes: IWorkflowAcao[] = [];
  public workflowId: any;
  public workflowTrabalhador: ITrabalhador;

  constructor(
    private NewToaster: any,
    private $rootScope: ng.IRootScopeService,
    private WorkflowService: WorkflowService,
    private WorkflowOldModalService: WorkflowOldModalService,
    private WorkflowDefaultModalService: WorkflowDefaultModalService,
    private WorkflowCommentModalService: WorkflowCommentModalService,
    private WorkflowDynamicModalService: WorkflowDynamicModalService
  ) {

    this.$rootScope.$on('workflow_acoes', (event: any, args: any) => {
      this.workflowAcoes = [];
      this.workflowId = args.id;
      for (let i = 0; i < args._acoes.length; i++) {
        this.workflowAcoes.push(args._acoes[i]);
      }
    });
  }

  $onInit() {
    if (this.workflowBusy === undefined || this.workflowBusy === null) {
      this.workflowBusy = false;
    }
    this.dadosIniciais();
  }

  // início - gerenciamento de estados
  public isBusy(): boolean {
    return this.WorkflowService.loadParams.busy;
  }
  // fim - gerenciamento de estados


  // início - ações do workflow
  public executarAcao(acao: IWorkflowAcao): void {
    this.acao = angular.copy(acao);

    if (!this.acao.modal && !this.acao.requiredcomment) {
      this.concluirAcao();
      return;
    }

    const confirm: ng.ui.bootstrap.IModalInstanceService = this.getModalService();

    confirm.result.then(() => {
      this.concluirAcao();
    })
      .catch((error: any) => {
        if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
          this.NewToaster.pop({
            type: 'error',
            title: error
          });
        }
      });
  }
  // fim - ações do workflow


  // início - chamada de APIs
  private concluirAcao(): void {
    this.acaoEnviar = this.definirDadosEnvio();

    this.workflowBusy = true;
    this.WorkflowService.executarAcao(this.acaoEnviar)
      .then((response: any) => {
        this.handleAcaoSuccess(this.acaoEnviar.descricao);
      })
      .catch((e: any) => {
        this.handleAcaoError(this.acaoEnviar.descricao);
      })
      .finally(() => {
        this.workflowBusy = false;
      });
  }
  // fim - chamada de APIs


  // início - definição de dados
  private dadosIniciais(): void {
    this.acaoEnviar = {
      codigoAcao: null,
      titulo: null,
      descricao: null,
      requiredcomment: null,
      id: this.workflowId
    };
  }

  private definirDadosEnvio(): IWorkflowAcaoEnviar {
    return {
      codigoAcao: this.acao.code,
      titulo: this.acao.title,
      descricao: this.acao.description,
      requiredcomment: this.acao.requiredcomment === null ? false : this.acao.requiredcomment,
      id: this.workflowId,
      comentario: this.acao.comentario,
      dataprorrogacaocontrato: this.acao.dataprorrogacaocontrato
    };
  }

  private getModalService(): ng.ui.bootstrap.IModalInstanceService {
    if (!this.acao.modal && this.acao.requiredcomment) {
      return this.WorkflowOldModalService.open(this.acao, this.workflowTrabalhador);
    }

    if (this.acao.requiredconfirmation) {
      return this.WorkflowDefaultModalService.open(this.acao, this.workflowTrabalhador);
    }

    if (this.acao.modal!.comentario) {
      return this.WorkflowCommentModalService.open(this.acao, this.workflowTrabalhador);
    }

    return this.WorkflowDynamicModalService.open(this.acao, this.workflowTrabalhador);
  }
  // fim - definição de dados


  // início - exibição de toasters
  private handleAcaoError(description: any): void {
    this.NewToaster.pop({
      type: 'error',
      title: 'Erro ao ' + description
    });
  }

  private handleAcaoSuccess(description: any): void {
    this.NewToaster.pop({
      type: 'success',
      title: 'Sucesso ao ' + description
    });
  }
  // fim - exibição de toasters
}
