import * as angular from 'angular';
import { MeurhAcompanhamentoListController } from './acompanhamento.index.controller';

export class MeurhAcompanhamentoDefault implements angular.IComponentOptions {
	static selector = 'meurhAcompanhamentoDefault';
	static controller = MeurhAcompanhamentoListController;
	static controllerAs = 'mrh_cmpnhmnt_lst';
	static template = require('!!html-loader!./acompanhamento.index.html');
	static transclude = true;

	static bindings = {
		form: '<',
		nomeTrabalhador: '<',
		entityType: '@',
		criadoautomaticamente: '<',
		retificacao: '<'
	};
}
