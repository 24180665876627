import angular from 'angular';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhPromocoesShowFormController implements angular.IController {
    static $inject = [
        '$rootScope',
        'TiposDocumentosRequeridosService',
        '$scope',
    ];

    public entity: any;
    public unidadesSalario = {
        1: 'Hora',
        2: 'Dia',
        3: 'Semana',
        4: 'Mês',
        5: 'Tarefa',
        6: 'Variável'
    };

    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;
    public action: string = 'show';

    // documentos anexos
    public tiposdocumentosrequeridos: Array<any>;
    public entityAnexo: IAnexo = {};
    public editanexo: boolean = false;
    public busyTipoDocumentos: boolean = true;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            modoGestorPermissao: (arg: string) => boolean,
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean
        },
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public $scope: angular.IScope,
    ) {
    }

    // monitorando quando há mudanças na entity, para fazer o processamento de anexos apenas quando ela está devidamente carregada
    $onChanges(changes: angular.IOnChangesObject) {
        if (changes.entity && changes.entity.currentValue && this.entity?.solicitacao) {
            this.entityAnexo['solicitacao'] = this.entity.solicitacao;
            this.entityAnexo.casodeuso = 'PROMOCOES';
            this.resetTiposDocumentosRequeridosService();
            this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
            this.onTiposDocumentosRequeridosFinished();
        }
    }

    private onTiposDocumentosRequeridosFinished(): void {
        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                tipodocumentorequerido.file = '';
                return tipodocumentorequerido;
            });
        });
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': this.tipoSolicitacaoEnum.PROMOCAO };
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

}
