import angular from 'angular';
import { ApuracoesPontoService } from '../apuracoesponto.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IApuracaoPonto } from '../../models/apuracaoponto.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';

export class ApuracoesPontoIndexController {

    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$http',
        'ApuracoesPontoService',
        'ListagemService',
        'NewToaster'
    ];

    // initial state
    public entities: Array<IApuracaoPonto> = [];
    public busy: boolean = false;
    public filters = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
    };

    public tempoParaRefresh: number = 120000; // 2 minutos
    public refreshArray: Array<IApuracaoPonto> = [];

    public listagemPermissao: Array<string> = ['visualizar_apuracoes'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;

    constructor (
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
			temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
			session: any,
            nsjGlobals: any
		},
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $http: angular.IHttpService,
        public ApuracoesPontoService: ApuracoesPontoService,
        public ListagemService: ListagemService,
        public NewToaster: any
    ) {
        if (
            !this.$rootScope.temPermissao('visualizar_apuracoes') ||
            !this.$rootScope.liberadoTela('LIBERAR_APURACOES_PONTO')
        ) {
            $state.go('dp');
        }

        this.busy = true;
        this.carregaApuracoes();
        this.montaListagemVisibilidade();

        $scope.$on('apuracoesponto_index_loaded', (event: angular.IAngularEvent, args: any) => {
            if (this.entities.length !== 0) {
                this.refreshArray = angular.copy(args);
                this.entities.forEach((element: any) => {
                    this.refreshArray.forEach((elementRefresh: any) => {
                        if (element.apuracaoponto === elementRefresh.apuracaoponto) {
                            element.apuracaoprocessamento.statusfolha = element.apuracaoprocessamento.statusfolha === elementRefresh.apuracaoprocessamento.statusfolha ? element.apuracaoprocessamento.statusfolha : angular.copy(elementRefresh.apuracaoprocessamento.statusfolha);
                            element.apuracaoprocessamento.statuspagamento = element.apuracaoprocessamento.statuspagamento === elementRefresh.apuracaoprocessamento.statuspagamento ? element.apuracaoprocessamento.statuspagamento : angular.copy(elementRefresh.apuracaoprocessamento.statuspagamento);
                        }
                    });
                });
            }
            this.busy = false;
        });

        $scope.$on('$destroy', () => {
            clearInterval(refresh);
        });

        const refresh = setInterval(() => {
            if (this.ApuracoesPontoService.pendingRequestsCounter > 0) {
                return;
            }
            this.refreshArray = [];
            this.ApuracoesPontoService.reload();
        }, this.tempoParaRefresh);
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        this.carregaApuracoes();
    }

	carregaApuracoes() {
        this.busy = true;
		this.ApuracoesPontoService.constructors['vigente'] = false;
		this.ApuracoesPontoService.constructors['empresa'] = this.filters.empresa;
		this.ApuracoesPontoService.constructors['estabelecimento'] = this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE') ? this.filters.estabelecimento : '';
		this.entities = this.ApuracoesPontoService.reload();
	}
}

