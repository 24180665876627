import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesvtsShowFormController } from './solicitacoesalteracoesvts.show.form.controller';

export class MeurhSolicitacoesalteracoesvtsShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesalteracoesvtsShowFormComponent';
	static controller = MeurhSolicitacoesalteracoesvtsShowFormController;
	static template = require('!!html-loader!./solicitacoesalteracoesvts.show.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
