import angular = require('angular');
import moment = require('moment');
import { MeurhAdmissaoestagiarioModalService } from '../../modal/admissaoestagiario.modal.service';
import { MeurhVagasService } from '../../../Vagas/vagas.service';

export class MeurhAdmissoesestagiarioBeneficiosFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'MeurhAdmissaoestagiarioModalService', 'ModalConfirmService', 'MeurhVagasService', ];

    public entity: any;
    public action: string;
    public fields: any = [
        {
            value: 'beneficio.descricao',
            label: 'Benefício',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'quantidade',
            label: 'Quantidade',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'lotacao.nome',
            label: 'Lotação',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'usobeneficio',
            label: 'Uso',
            type: 'options',
            style: 'default',
            options: { 'Titular': 'entity.usobeneficio === 1', 'Dependente': 'entity.usobeneficio === 2' },
            copy: '',
        },
    ];

    public idCount: number = 0;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public MeurhAdmissaoestagiarioModalService: MeurhAdmissaoestagiarioModalService,
        public ModalConfirmService: any,
        public MeurhVagasService: MeurhVagasService,
    ) {
        this.$scope.$watch('$ctrl.entity.nome', function (newValue: any, oldValue: any) {
            if (newValue !== oldValue && this.entity.beneficios.length > 0) {
                this.entity.beneficios.forEach((beneficio: any) => {
                    if (beneficio.usobeneficio === 1) {
                        beneficio.nomepessoa = newValue;
                    }
                });

            }
        }.bind(this), true);

        this.$scope.$watch('$ctrl.entity.dependentes', function (newValue: any, oldValue: any) {
            if (newValue !== oldValue && newValue.length === oldValue.length && this.entity.beneficios.length > 0) {

                let indexDependenteAlterado;

                for (let i = 0; i < newValue.length; i++) {

                    // verificando se algum dos campos exibidos na tabela de benefícios foi alterado
                    if (newValue[i].nome !== oldValue[i].nome || newValue[i].parentesco !== oldValue[i].parentesco) {
                        indexDependenteAlterado = i;
                        newValue[indexDependenteAlterado].parentescoDescricao = this.getDescricaoParentesco(newValue[indexDependenteAlterado].parentesco);
                    }
                }

                // atualizando os dados exibidos na tabela de benefícios, para a cada alteração de dependente refletir no mesmo momento
                if (indexDependenteAlterado != null) {
                    this.entity.beneficios.forEach((beneficio: any) => {
                        if (beneficio.usobeneficio === 2 && beneficio.dependenteSelecionado?.cpf === newValue[indexDependenteAlterado]?.cpf) {
                            beneficio.nomepessoa = newValue[indexDependenteAlterado].nome;
                            beneficio.dependenteSelecionado = newValue[indexDependenteAlterado];
                        }
                    });
                }

            }
        }.bind(this), true);

    }

    $onInit(): void {
        if (this.entity.gestaoBeneficioAvancadaAtiva) {
            this.fields.push(
                {
                    value: 'nomepessoa',
                    label: 'Nome',
                    type: 'string',
                    style: 'default',
                    copy: '',
                },
                {
                    value: 'dependenteSelecionado.parentescoDescricao',
                    label: 'Parentesco',
                    type: 'string',
                    style: 'default',
                    copy: '',
                },
            );
        }

        if (!this.entity.beneficios) {
            this.entity.beneficios = [];
        } else {
            if (this.entity.beneficios.length > 0) {
                this.entity.beneficios.forEach(async (beneficio: any) => {
                    beneficio['counter'] = this.idCount++;

                    // se for edição ou visualização de admissão
                    if (this.entity.solicitacao) {
                        if (beneficio.dependente) {
                            beneficio.usobeneficio = 2;
                            beneficio.dependenteSelecionado = this.entity.dependentes.filter((dependente) => {
                                return dependente.dependenteadmissaocompleta === beneficio.dependente;
                            })[0];
                            beneficio.nomepessoa = beneficio.dependenteSelecionado.nome;
                            beneficio.dependenteSelecionado.parentescoDescricao = this.getDescricaoParentesco(beneficio.dependenteSelecionado.parentesco);

                        } else {
                            beneficio.usobeneficio = 1;
                            beneficio.nomepessoa = this.entity.nome;

                            // se houver vaga selecionada e benefício vier dela, não posso deixar editar e excluir
                            if (this.entity.vaga?.vaga && this.action !== 'show') {

                                // verificando se o benefício veio da vaga
                                await this.MeurhVagasService.get(this.entity.vaga.vaga)
                                .then((dadosVaga: any) => {
                                    if (dadosVaga.beneficios.length > 0) {
                                        dadosVaga.beneficios.forEach(beneficioVaga => {
                                            if (beneficioVaga.beneficio.beneficio === beneficio.beneficio.beneficio) {
                                                beneficio.beneficiovaga = beneficioVaga.beneficiovaga;
                                            }
                                        });
                                    }
                                })
                                .catch((error) => {
                                    this.NewToaster.pop({
                                        type: 'error',
                                        title: 'Não foi possível buscar os benefícios da vaga'
                                    });
                                });
                            }

                        }
                    }

                });
            }
        }
    }

    async abrirModal(beneficio: any = {}, action: string = ''): Promise<void> {
        let parameters = {
            'componente' : 'Benefício',
            'action' : action ? action : this.action ,
            'objadmissao' : this.entity,
            'desativausobeneficio': false,
            'beneficiosvaga': [],
        };

        let beneficiosVaga = [];

        if (parameters.objadmissao.solicitacao) {
            if (parameters.objadmissao.vaga?.vaga && !parameters.objadmissao.vaga.hasOwnProperty('beneficios')) {
                await this.MeurhVagasService.get(parameters.objadmissao.vaga.vaga)
                .then((dadosVaga: any) => {
                    beneficiosVaga = angular.copy(dadosVaga.beneficios);
                });
            } else if (parameters.objadmissao.vaga?.hasOwnProperty('beneficios')) {
                beneficiosVaga = angular.copy(parameters.objadmissao.vaga.beneficios);
            }
        } else {
            if (parameters.objadmissao.vaga && parameters.objadmissao.vaga?.hasOwnProperty('beneficios')) {
                beneficiosVaga = angular.copy(parameters.objadmissao.vaga.beneficios);
            }
        }

        if (beneficio?.usobeneficio !== 1 && beneficiosVaga.length > 0 && parameters.objadmissao.dependentes?.length > 0) {
            parameters.desativausobeneficio = true;

            // obtendo apenas benefícios que vieram da vaga selecionada
            beneficiosVaga.forEach((beneficio: any) => {
                if (beneficio.beneficiovaga != null) {
                    parameters.beneficiosvaga.push(beneficio.beneficio);
                }
            });
        }

        if (!this.entity.gestaoBeneficioAvancadaAtiva && (action !== 'edit' && action !== 'show') && beneficiosVaga.length > 0) {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Benefício para titular excedido',
                body: 'O limite para adicionar benefícios ao titular foi atingido.'
            });
            this.$scope.$applyAsync();
            return;
        }

        if ((action !== 'edit' && action !== 'show') && beneficiosVaga.length > 0 && (!parameters.objadmissao.dependentes || parameters.objadmissao.dependentes?.length < 1)) {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Benefício para titular excedido',
                body: 'Limite de benefícios atingido para o titular. Adicione um dependente para incluir mais um benefício'
            });
            this.$scope.$applyAsync();
            return;
        }

        let modal = this.MeurhAdmissaoestagiarioModalService.open(beneficio, parameters);

        modal.result
            .then((response: any): void => {
                if (!this.valorExiste(response.counter)) {
                    response.counter = this.idCount;
                    this.idCount++;
                    this.entity.beneficios.push(angular.copy(response));
                    this.$scope.$applyAsync();
                } else {
                    let achouBeneficio = false;
                    this.entity.beneficios.forEach((element: any, index: any) => {
                        if ((achouBeneficio === false) && (beneficio.counter === element.counter)) {
                            this.entity.beneficios[index] = angular.copy(response);
                            achouBeneficio = true;
                        }
                    });
                }
            })
            .catch((error: any): void => {/**/});
    }

    excluirBeneficio(beneficio: any): void {
        let textos = {
            title: 'Excluir benefício',
            subtitle: 'Após a exclusão não será mais possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir este benefício?',
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir benefício',
        };
		let confirm = this.ModalConfirmService.open(this.entity, 'Benefício', false, textos);

        confirm.result.then((): void => {
            this.excluir(beneficio);
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    excluir(beneficio: any): void {
        let achouBeneficio = false;
        this.entity.beneficios.forEach((element: any, index: any) => {
            if ((achouBeneficio === false) && (beneficio.counter === element.counter)) {
                achouBeneficio = true;
                this.entity.beneficios.splice(index, 1);
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    getDescricaoParentesco(parentesco: string | number) {

        if (parentesco == null) {
            return;
        }

        let parentescoString = typeof(parentesco) === 'string' ? parentesco : parentesco.toString();

        switch (parentescoString) {
            case '1':
                return 'Cônjuge';
            case '2':
                return 'Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua declaração de união estável';
            case '3':
                return 'Filho(a) ou enteado(a)';
            case '4':
                return 'Filho(a) ou enteado(a) universitário(a) ou cursando escola técnica de 2º grau, até 24 (vinte e quatro) anos';
            case '6':
                return 'Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial';
            case '9':
                return 'Pais, avós e bisavós';
            case '10':
                return 'Menor pobre do qual detenha a guarda judicial';
            case '11':
                return 'A pessoa absolutamente incapaz, da qual seja tutor ou curador';
            case '12':
                return 'Ex-cônjuge';
            case '99':
                return 'Agregado/Outros';
        }

    }

}
