import { ui } from 'angular';
import { IModalConfirmacao } from '../models/modalconfirm';
import { IRescisao } from '../models/rescisao.model';

export class MeurhRescisoesModalConfirmService {

    static $inject = [
        '$uibModal'
    ];

    public textoConfirmarEnvio: string = '';

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
    ) { }

    public open(entity: IRescisao, textos: IModalConfirmacao, prorrogacaoContrato: boolean = false): ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modalrescisao.confirm.html'),
            controller: 'MeurhRescisoesModalConfirmController',
            controllerAs: 'confirm',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                entity: () => entity,
                textos: () => textos,
                prorrogacaoContrato: () => prorrogacaoContrato
            }
        });
    }
}
