declare var nsjGlobals: any;
import angular from 'angular';
import moment from 'moment';
import { StatusProcessoEnum } from '../../enums/statusprocesso';

export class MeurhAdmissoesDadospessoaisFormController implements angular.IController {

    static $inject = ['$rootScope'];

    public entity: any;
    public bloqueiaAno: any;
    public mesInit: any;
    public dataAtual = new Date();
    public dataMinima: any;

    public statusProcessoEnum = StatusProcessoEnum;

    public clienteAna: boolean;

    constructor(
        public $rootScope: angular.IRootScopeService & { session: any }
    ) {
        this.clienteAna = nsjGlobals.get('clienteana');
    }

    $onInit(): void {
        this.dataMinima = moment(this.dataAtual).subtract(18, 'years');
        this.bloqueiaQuatorzeanos();
        this.calculaMes();
    }

    preencheCidade(): void {
        if (this.valorExiste(this.entity.municipionascimento)) {
            this.entity.cidadenascimento = this.entity.municipionascimento.nome;
        } else {
            this.entity.cidadenascimento = null;
        }
    }

    bloqueiaQuatorzeanos(): any {
        this.bloqueiaAno = moment(this.dataMinima).add(4, 'years'); // =14 anos
    }

    calculaMes(): void {
        this.mesInit = moment(this.dataAtual).subtract(216, 'month'); // 18 anos
    }

    verificaEstabelecimento(): { estabelecimento: string } {
        if (this.entity.estabelecimento) {
          return { 'estabelecimento': this.entity.estabelecimento };
        } else {
          return { 'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento };
        }
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    public alterarPossuiFilhos() {
        this.entity.possuifilhos = !this.entity.possuifilhos;
    }

    alteraPais() {
        if (!this.entity.paisnascimento || this.entity.paisnascimento.pais === '1058') {
            this.entity.datachegadapais = null;
            this.entity.temporesidenciaestrangeiro = null;
            this.entity.classificacaoestrangeiro = null;
            this.entity.municipionascimento = null;
            this.entity.cidadenascimento = null;
        } else if (this.entity.paisnascimento.pais !== '1058') {
            this.entity.municipionascimento = {
                ibge: '9999999',
                nome: 'EXTERIOR - EX',
                uf: 'EX'
            };

            this.entity.cidadenascimento = 'EXTERIOR';
        }
    }

    alteraSemFiliacao() {
        if (this.entity.semfiliacao) {
            this.entity.nomemae = this.entity.nomepai = null;
        }
    }

}
