import angular = require('angular');
import moment = require('moment');

export class MeurhAdmissoesestagiarioBeneficiosFormmodalController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'MeurhAdmissaoestagiarioModalService'];

    public beneficio: any;
    public action: string;
    public objadmissao: any;
    public desativaValor: boolean = true;
    public desativaDesconto: boolean = true;
    public dataAtual = new Date();
    public anoAtual = this.dataAtual.getFullYear();
    public beneficioDuplicado: boolean = false;
    public mesesArray = [
        {
            id: 1,
            mes: 'Janeiro'
        },
        {
            id: 2,
            mes: 'Fevereiro'
        },
        {
            id: 3,
            mes: 'Março',
        },
        {
            id: 4,
            mes: 'Abril',
        },
        {
            id: 5,
            mes: 'Maio',
        },
        {
            id: 6,
            mes: 'Junho',
        },
        {
            id: 7,
            mes: 'Julho',
        },
        {
            id: 8,
            mes: 'Agosto',
        },
        {
            id: 9,
            mes: 'Setembro',
        },
        {
            id: 10,
            mes: 'Outubro',
        },
        {
            id: 11,
            mes: 'Novembro',
        },
        {
            id: 12,
            mes: 'Dezembro',
        }];
    public anosArray = [
        this.anoAtual, (this.anoAtual + 1), (this.anoAtual + 2), (this.anoAtual + 3), (this.anoAtual + 4), (this.anoAtual + 5)
    ];

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
    ) {

    }

    $onInit(): void {
       if (this.valorExiste(this.beneficio.quantidade)) {
           this.beneficio.tipoperiodo = this.beneficio.tipoperiodo >= 0 ? this.beneficio.tipoperiodo.toString() : this.beneficio.tipoperiodo;
           this.beneficio.mesinicial = this.beneficio.mesinicial ? this.beneficio.mesinicial.toString() : this.beneficio.mesinicial;
           this.beneficio.anoinicial = this.beneficio.anoinicial ? this.beneficio.anoinicial.toString() : this.beneficio.anoinicial;
           this.beneficio.mesfinal = this.beneficio.mesfinal ? this.beneficio.mesfinal.toString() : this.beneficio.mesfinal;
           this.beneficio.anofinal = this.beneficio.anofinal ? this.beneficio.anofinal.toString() : this.beneficio.anofinal;
           this.beneficio.mes = this.beneficio.mes ? this.beneficio.mes.toString() : this.beneficio.mes;

           if (this.beneficio.usobeneficio === 2) {
                this.beneficio.dependenteSelecionado = this.objadmissao.dependentes.filter((dependente) => {
                    return dependente.cpf === this.beneficio.dependenteSelecionado.cpf;
                })[0];

                this.beneficio.nomepessoa = this.beneficio.dependenteSelecionado.nome;

                if (this.beneficio.desativausobeneficio && this.beneficio.beneficiosvaga?.length > 0) {
                    this.beneficio.beneficio = this.beneficio.beneficiosvaga.filter((beneficioVaga) => {
                        return beneficioVaga.beneficio === this.beneficio.beneficio.beneficio;
                    })[0];
                }

            }

       } else {
           this.beneficio.quantidade = 1;
           this.beneficio.lotacao = this.objadmissao.lotacao;

           if (!this.beneficio.desativausobeneficio) {
               this.beneficio.usobeneficio = 1;
               this.beneficio.nomepessoa = this.objadmissao.nome;
           }
       }
       this.verificaValor();
    }

    getQuantidade(event: angular.IAngularEvent & { detail: any }): void {
        this.beneficio.quantidade = event.detail.value;
        this.beneficio.erroQuantidade = event.detail.error;
    }

    verificaValor(): void {
        this.verificaDuplicidadeBeneficio(this.beneficio);
        this.beneficio.descricao = this.valorExiste(this.beneficio.beneficio) ? this.beneficio.beneficio.descricao : '';
        if (this.valorExiste(this.beneficio.beneficio) && this.beneficio.beneficio.tipovalor === 1) {
            this.desativaValor = false;
        } else {
            this.desativaValor = true;
            this.beneficio.valor = null;
        }
        this.verificaDesconto();
    }

    verificaDesconto(): void {
        if (this.valorExiste(this.beneficio.beneficio) && this.beneficio.beneficio.tipodesconto === 2) {
            this.desativaDesconto = false;
        } else {
            this.desativaDesconto = true;
            this.beneficio.desconto = null;
        }
    }

    verificaDuplicidadeBeneficio(beneficioSelecionado: any) {
        if (this.objadmissao.beneficios.length > 0 && beneficioSelecionado.beneficio) {

            // benefício sendo adicionado ao titular
            if (beneficioSelecionado.usobeneficio === 1) {

                this.beneficioDuplicado = this.objadmissao.beneficios.some((beneficioJaAdicionado) => {
                    return beneficioJaAdicionado.beneficio.beneficio === beneficioSelecionado.beneficio.beneficio &&
                            beneficioJaAdicionado.usobeneficio === beneficioSelecionado.usobeneficio &&
                            beneficioJaAdicionado.counter !== beneficioSelecionado.counter; // verifica se estou editando um benefício já existente
                });

                if (this.beneficioDuplicado) {
                    this.objadmissao.desativaBotoesSalvarModal = true;
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'O benefício selecionado já foi adicionado ao titular'
                    });
                } else {
                    this.objadmissao.desativaBotoesSalvarModal = false;
                }

            } else {

                this.beneficioDuplicado = this.objadmissao.beneficios.some((beneficioJaAdicionado) => {
                    return beneficioJaAdicionado.beneficio.beneficio === beneficioSelecionado.beneficio.beneficio &&
                            beneficioJaAdicionado.usobeneficio === beneficioSelecionado.usobeneficio &&
                            beneficioJaAdicionado.dependenteSelecionado.cpf === beneficioSelecionado?.dependenteSelecionado?.cpf &&
                            beneficioJaAdicionado.counter !== beneficioSelecionado.counter; // verifica se estou editando um benefício já existente
                });

                if (this.beneficioDuplicado) {
                    this.objadmissao.desativaBotoesSalvarModal = true;
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'O benefício selecionado já foi adicionado ao dependente escolhido'
                    });
                } else {
                    this.objadmissao.desativaBotoesSalvarModal = false;
                }
            }

        } else {
            this.beneficioDuplicado = false;
            this.objadmissao.desativaBotoesSalvarModal = false;
        }

    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    changeUsoBeneficio() {
        if (this.beneficio.usobeneficio === 1) {
            this.beneficio.dependenteSelecionado = null;
            this.beneficio.nomepessoa = this.objadmissao.nome;
        }
        this.verificaDuplicidadeBeneficio(this.beneficio);
    }

    changeDependente() {
        this.beneficio.dependenteSelecionado.parentescoDescricao = this.getDescricaoParentesco(this.beneficio.dependenteSelecionado.parentesco);
        this.beneficio.nomepessoa = this.beneficio.dependenteSelecionado.nome;
        this.verificaDuplicidadeBeneficio(this.beneficio);
    }

    getDescricaoParentesco(parentesco: string | number) {

        if (parentesco == null) {
            return;
        }

        let parentescoString = typeof(parentesco) === 'string' ? parentesco : parentesco.toString();

        switch (parentescoString) {
            case '1':
                return 'Cônjuge';
            case '2':
                return 'Companheiro(a) com o(a) qual tenha filho ou viva há mais de 5 (cinco) anos ou possua declaração de união estável';
            case '3':
                return 'Filho(a) ou enteado(a)';
            case '4':
                return 'Filho(a) ou enteado(a) universitário(a) ou cursando escola técnica de 2º grau, até 24 (vinte e quatro) anos';
            case '6':
                return 'Irmão(ã), neto(a) ou bisneto(a) sem arrimo dos pais, do(a) qual detenha a guarda judicial';
            case '9':
                return 'Pais, avós e bisavós';
            case '10':
                return 'Menor pobre do qual detenha a guarda judicial';
            case '11':
                return 'A pessoa absolutamente incapaz, da qual seja tutor ou curador';
            case '12':
                return 'Ex-cônjuge';
            case '99':
                return 'Agregado/Outros';
        }

    }

}
