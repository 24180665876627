import ng from 'angular';

export class MeurhAcompanhamentoModalController implements ng.IController {

    static $inject = [
        'textos',
        '$uibModalInstance'
    ];

    constructor(
        public textos: { titulo: string, descricao: string },
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    ) { }

    public close() {
        this.$uibModalInstance.dismiss();
    }
}
