import angular from 'angular';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { TipoJustificativaEnum } from '../../../../shared/enums/TipoJustificativaEnum';
import { IFalta } from '../models/falta.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhSolicitacoesfaltasFormController implements angular.IController {
	static $inject = [
        '$scope',
        '$stateParams',
        '$rootScope',
        'ListagemService'
    ];

	public tipo: string = this.$stateParams['tipo'] ? this.$stateParams['tipo'] : '-1';
	public entity: IFalta;
	public form: angular.IFormController;
	public enum = TipoJustificativaEnum;
    public busy: boolean = false;
    public action: string;

    public filters = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
    };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['criar_falta'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    public constructorLookupColaborador;

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            liberadoTela: (arg: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public ListagemService: ListagemService
	) {
		$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.$setDirty();

				if ((newValue.datas === '' || newValue.datas === undefined) && this.form.datas.$dirty) {
					this.form.datas.$setValidity('required', false);
				} else {
					this.form.datas.$setValidity('required', true);
				}
			}
		}, true);

	}

    $onInit(): void {
        this.montaListagemVisibilidade();

        this.constructorLookupColaborador = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '01',
            'forcagestor': this.forcaGestor(),
            ...(this.tipo && { 'tipo': this.tipo }),
        };

    }

    montaListagemVisibilidade(): void {
        if (this.action === 'update') {
            this.filters.empresa = this.entity.estabelecimentoobj.empresa;
            this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
        }
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.constructorLookupColaborador = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '01',
            'forcagestor': this.forcaGestor(),
            ...(this.tipo && { 'tipo': this.tipo }),
        };
    }

	forcaGestor(): boolean {
		return this.$rootScope.modoGestorPermissao('criar_falta');
	}

	mudarTipo(): void {
		this.entity.justificada = this.entity.justificadaSelect === '0' ? false : true;

		if (!this.entity.justificada) {
			this.entity.justificativa = '';
			this.entity.tipojustificativa = '';
		}
	}

}
