
import angular from 'angular';

import { MeurhPromocoesWizardController } from './wizard/promocoes.wizard.controller';
import { MeurhPromocoesPassozeroController } from './passo00/promocoes.passozero.controller';
import { MeurhPromocoesPassoumController } from './passo01/promocoes.passoum.controller';
import { MeurhPromocoesPassodoisController } from './passo02/promocoes.passodois.controller';
import { MeurhPromocoesPassotresController } from './passo03/promocoes.passotres.controller';
import { MeurhPromocoesPassoquatroController } from './passo04/promocoes.passoquatro.controller';
import { MeurhPromocoesListController } from './index/promocoes.index.controller';
import { MeurhPromocoesShowController } from './show/promocoes.show.controller';
import { MeurhPromocoesDocumentosanexosFormController } from './documentosanexos/documentosanexos.form.controller';

import { MeurhPromocoesShowFormComponent } from './showform/promocoes.form.component';
import { MeurhPromocoesPassozeroComponent } from './passo00/promocoes.passozero.component';
import { MeurhPromocoesPassoumComponent } from './passo01/promocoes.passoum.component';
import { MeurhPromocoesPassodoisComponent } from './passo02/promocoes.passodois.component';
import { MeurhPromocoesPassotresComponent } from './passo03/promocoes.passotres.component';
import { MeurhPromocoesPassoquatroComponent } from './passo04/promocoes.passoquatro.component';
import { MeurhPromocoesDocumentosanexosFormComponent } from './documentosanexos/documentosanexos.form.component';

import { MeurhSolicitacoespromocoesService } from './promocoes.service';
import { MeurhPromocoesListRouting } from './promocoes.routes';

export const MeurhPromocoesModule = angular.module('MeurhPromocoesModule', ['ui.router.state', ])
    .controller('MeurhPromocoesWizardController', MeurhPromocoesWizardController)
    .controller('MeurhPromocoesPassozeroController', MeurhPromocoesPassozeroController)
    .controller('MeurhPromocoesPassoumController', MeurhPromocoesPassoumController)
    .controller('MeurhPromocoesPassodoisController', MeurhPromocoesPassodoisController)
    .controller('MeurhPromocoesPassotresController', MeurhPromocoesPassotresController)
    .controller('MeurhPromocoesPassoquatroController', MeurhPromocoesPassoquatroController)
    .controller('MeurhPromocoesListController', MeurhPromocoesListController)
    .controller('MeurhPromocoesShowController', MeurhPromocoesShowController)
    .controller('MeurhPromocoesDocumentosanexosFormController', MeurhPromocoesDocumentosanexosFormController)
    .component('meurhPromocoesShowFormComponent', MeurhPromocoesShowFormComponent)
    .component('meurhPromocoesPassozeroComponent', MeurhPromocoesPassozeroComponent)
    .component('meurhPromocoesPassoumComponent', MeurhPromocoesPassoumComponent)
    .component('meurhPromocoesPassodoisComponent', MeurhPromocoesPassodoisComponent)
    .component('meurhPromocoesPassotresComponent', MeurhPromocoesPassotresComponent)
    .component('meurhPromocoesPassoquatroComponent', MeurhPromocoesPassoquatroComponent)
    .component('meurhPromocoesDocumentosanexosFormComponent', MeurhPromocoesDocumentosanexosFormComponent)
    .service('MeurhSolicitacoespromocoesService', MeurhSolicitacoespromocoesService)
    .config(MeurhPromocoesListRouting)
    .name;
