import angular = require('angular');
import { IVagas } from '../models/vagas.model';
import { MeurhVagasService } from '../vagas.service';

export class MeurhVagasShowController implements ng.IController {
	static $inject = [ '$scope', '$stateParams', '$state', 'MeurhVagasService', 'entity', 'NewToaster', 'ModalConfirmService'];

    public busy: boolean = false;
    public constructors: any = {};
    public entitySave: Partial<IVagas> = {};

    constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhVagasService,
        public entity: IVagas,
        public NewToaster: any,
        public ModalConfirmService: any) {

        this.onDeleteSuccess();
        this.onDeleteError();
    }

    delete(force: boolean): void {
        let customText = {
            title: 'Excluir',
            subtitle: 'Após a exclusão, o registro deixará de existir até para consultas e não será possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir a vaga ',
            confirmTextNum: '#' + this.entity.codigo,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir',
        };
        let confirm = this.ModalConfirmService.open(this.entity, 'Vaga', false, customText);
        confirm.result
        .then((entity: IVagas): void => {
            this.busy = true;
            this.entityService.delete(this.$stateParams['vaga'], force);
        })
        .catch(function () {/**/});
    }

    onDeleteSuccess(): void {
        this.$scope.$on('meurh_vagas_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Vaga foi excluída com sucesso!'
            });
            this.$state.go('meurh_vagas', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_vagas_delete_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                if (args.response.data.message === 'Validation Failed') {
                    let message = args.response.data.errors.errors[0];

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de Validação',
                        body: message,
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao tentar excluir.'
                    });
            }
        });
    }
}
