
import angular from 'angular';

import { MeurhMudancaHorarioIndexController } from './index/mudancahorario.index.controller';
import { MeurhMudancaHorarioNewController } from './new/mudancahorario.new.controller';
import { MeurhMudancaHorarioShowController } from './show/mudancahorario.show.controller';

import { MeurhMudancaHorarioFormController } from './form/mudancahorario.form.controller';
import { MeurhMudancaHorarioFormComponent  } from './form/mudancahorario.form.component';

import { MeurhMudancaHorarioService } from './mudancahorario.service';
import { MeurhMudancaHorarioModalService } from './modal/mudancahorario.modal.service';

import { MeurhMudancaHorarioListRouting } from './mudancahorario.routes';

export const MeurhMudancaHorarioModule = angular.module('MeurhMudancaHorarioModule', ['ui.router.state', ])
    .controller('MeurhMudancaHorarioIndexController', MeurhMudancaHorarioIndexController)
    .controller('MeurhMudancaHorarioNewController', MeurhMudancaHorarioNewController)
    .controller('MeurhMudancaHorarioFormController', MeurhMudancaHorarioFormController)
    .controller('MeurhMudancaHorarioShowController', MeurhMudancaHorarioShowController)
    .component('meurhMudancahorarioFormComponent', MeurhMudancaHorarioFormComponent)
    .service('MeurhMudancaHorarioService', MeurhMudancaHorarioService)
    .service('MeurhMudancaHorarioModalService', MeurhMudancaHorarioModalService)
    .config(MeurhMudancaHorarioListRouting)
    .name;
