import * as angular from 'angular';

export const MeurhInformesrendimentosRouting = [
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
        $stateProvider
            .state('relatorios_informerendimentos', {
                url: '/relatorios/informerendimentos',
                template: require('html-loader!./index/informerendimentos.index.html'),
                controller: 'MeurhInformesrendimentosListController',
                controllerAs: 'nfrm_rndmnts_cntrllr'
            });
    }
];
