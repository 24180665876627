import * as angular from 'angular';
import { MeurhAdmissoesestagiarioBeneficiosFormmodalController } from './beneficios.formmodal.controller';

export class MeurhAdmissoesestagiarioBeneficiosFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioestagiarioBeneficiosFormmodalComponent';
	static controller = MeurhAdmissoesestagiarioBeneficiosFormmodalController;
	static template = require('!!html-loader!./beneficios.formmodal.html');
	static transclude = true;
	static bindings = {
		beneficio: '=',
		form: '<',
		busy: '=',
		action: '<',
		objadmissao: '=',
	};
}
