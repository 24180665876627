import * as angular from 'angular';
import { MeurhFeriasMarcacoesShowFormController } from './feriasmarcacoes.show.form.controller';

export class MeurhFeriasMarcacoesShowFormComponent implements angular.IComponentOptions {

  static selector = 'MeurhFeriasMarcacoesShowFormComponent';
  static controller = MeurhFeriasMarcacoesShowFormController;
  static template = require('!!html-loader!./feriasmarcacoes.show.form.html');
  static transclude = true;
  static bindings = {
    entity: '<',
    form: '<',
    busy: '=',
    action: '<'
  };

}
