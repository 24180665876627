import angular from 'angular';
import { IAnalisePonto } from '../models/analiseponto.model';
import { LoadParams } from '../../../../shared/models/loadparams.model';

export class AnalisesPontoService {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

	public entity: IAnalisePonto;
	public entities: Array<IAnalisePonto> = [];
	public loaded = false;
	public constructors: any = {};
	public after: any = {};
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $q: angular.IQService,
	) {
		this.loadParams = new LoadParams();
	}

	reload(): Array<IAnalisePonto> {
		if (this.loading_deferred) {
			this.loading_deferred.resolve();
		}

		this.loadParams.finished = false;
		this.loadParams.to_load = 3;
		this.after = {};
		this.loaded = false;
		this.entities.length = 0;
		this.loadParams.busy = false;

		return this.load();
	}

    search(filter: any = undefined): Array<any>  {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

	_load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('ponto_relatorioanalise_relatorioanalise', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	load(): Array<IAnalisePonto> {
		if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
			this.loadParams.busy = true;

			this._load(this.constructors, this.after, this.filter)
				.then((data: any): void => {
					if (data.entities && data.entities.length > 0) {
						for (var i = 0; i < data.entities.length; i++) {
							this.entities.push(data.entities[i]);
						}

						this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
						this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
					}

					if (!data.entities || data.entities.length < 20) {
						this.loadParams.finished = true;
						this.$rootScope.$broadcast('relatorioanalise_relatorioanalise_list_finished', this.entities);
					} else {
						this.loadParams.finished = false;
					}

					this.loaded = true;
					this.loadParams.to_load--;

                    if (this.loadParams.to_load === 0 || (!data.entities || data.entities.length <= 20)) {
                        this.$rootScope.$broadcast('relatorioanalise_relatorioanalise_loaded', this.entities);
                    }
				})
				.catch((error: any): void => {
					if (error.xhrStatus !== 'abort') {
						this.loadParams.finished = true;
					}
				})
				.finally((): void => {
					if (this.loaded || this.loadParams.finished) {
						this.loadParams.busy = false;
					}

					this.load();
				});
		}

		return this.entities;
	}

	loadMore(): void {
		this.loadParams.to_load = 3;
		this.load();
	}

	get(identifier: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http.get(
				this.nsjRouting.generate('analisesponto_get', { 'id': identifier }, true),
				{ timeout: this.loading_deferred.promise }
			)
			.then((response: any) => {
				this.$rootScope.$broadcast('analisesponto_loaded', response.data);
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	exportar(objetoExportacao: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('ponto_relatorioanalise_relatorioanaliseexportacao', {}, true),
				data: angular.copy(objetoExportacao),
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('relatorioanalise_relatorioanaliseexportacao_sucesso', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('relatorioanalise_relatorioanaliseexportacao_erro', response);
				reject(response);
			});
		});
	}
}
