import angular from 'angular';
import moment from 'moment';
import { MeurhApontamentosService } from '../apontamentos.service';
import { ApontamentosModalConfirmacaoService } from '../modal.confirmacao/modal.confirmacao.service';
import { IApontamento } from '../models/apontamentos.model';
export class MeurhApontamentosEditController {
    static $inject = ['ApontamentosTrabalhadoresModalService', '$scope', '$stateParams', '$state', 'MeurhApontamentosService',
        'entity', 'NewToaster', '$uibModal', 'nsjRouting', '$rootScope', '$q', '$http', 'RelatoriosService', 'ModalConfirmService', 'ApontamentosModalConfirmacaoService'];

    public SITUACAO_RASCUNHO = -1;
    public SITUACAO_PENDENTE = 0;
    public SITUACAO_APROVADO = 1;

    public busy: any;
    public tipo: any;
    public meses: any = ['', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    public constructors: any;
    public form: angular.IFormController;
    public action: any;
    public semMovimentos: any;
    public loadingDelete: any;
    public movimentosTipoSempre: boolean;

    constructor(
        public ApontamentosTrabalhadoresModalService: any,
        public $scope: any,
        public $stateParams: any,
        public $state: any,
        public entityService: MeurhApontamentosService,
        public entity: IApontamento,
        public NewToaster: any,
        public $uibModal: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any,
        public $http: any,
        public RelatoriosService: any,
        public ModalConfirmService: any,
        public ApontamentosModalConfirmacaoService: ApontamentosModalConfirmacaoService) {
        this.busy = false;
        $rootScope.carregandoApontamento = false;
        this.tipo = this.$stateParams['tipo'];
        this.entity = entity;
        $rootScope.entityApt = entity;
        this.constructors = entityService.constructors;
        this.entity.valor = this.entity.mesreferencia.toString();
        this.action = entity.apontamento ? 'update' : 'insert';
        let self = this;
        $scope.$watch('pntmnts_pntmnts_frm_cntrllr.entity', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);
    }

    $onInit() {
        this.onSubmitError();
        this.onSubmitSuccess();
        this.onDeleteError();
        this.onDeleteSuccess();
    }

    redirectRelatorio(apontamento: any) {
        let carregandoRelatorio = true;
        let constructor = { 'id': apontamento };
        let callback = () => {
            carregandoRelatorio = false;
        };
        this.RelatoriosService.abrirRelatorio('apontamentos_apontamentos_relatorio', constructor, callback);
    }

    ultimoDiaMes(mes: any, ano: any) {
        let result = 0;
        switch (mes) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                result = 31;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                result = 30;
                break;
            case 2:
                if ((ano % 4) === 0) {
                    result = 29;
                } else {
                    result = 28;
                }
                break;
        }
        return result;
    }

    apontamentoPossuiMovimentosPedentes() {
        let situacaoPedente = false;
        if (this.entity.apontamentostrabalhadores) {
            this.entity.apontamentostrabalhadores.forEach((aptTrab: any, i: any) => {
                if (aptTrab.movimentos) {
                    aptTrab.movimentos.forEach((movimento: any, idx: any) => {
                        if (movimento.situacao !== -1) {
                            situacaoPedente = true;
                        }
                    });
                }
            });
        }
        return situacaoPedente;
    }

    openModalEnvio() {
        let modal = this.ApontamentosModalConfirmacaoService.open(this.entity, this.movimentosTipoSempre, this.semMovimentos, this.SITUACAO_PENDENTE, this.busy);
        modal.result.then(() => {
            this.submit();
        }).catch(() => {/* */});
    }

    getDataFechamentoFolha(mes: any, ano: any) {
        // se fechamento é para o próximo mês, incremento o mês e ano se for o caso
        if (this.$rootScope.configuracoes['FOLHA_FECHA_PROXIMO_MES'] === '1') {
            mes = mes + 1;
            if (mes === 13) {
                mes = 1;
                ano = ano + 1;
            }
        }
        let diaFechamento: any = this.$rootScope.configuracoes['FECHAMENTO_FOLHA'];
        let ultimoDiaMes: any = this.ultimoDiaMes(mes, ano);
        if (parseInt(diaFechamento, 10) > parseInt(ultimoDiaMes, 10)) {
            diaFechamento = ultimoDiaMes.toString();
        }
        let data = diaFechamento + '-' + mes + '-' + ano;
        let datafinal = moment(data, 'DD-MM-YYYY').startOf('day');

        return datafinal;
    }

    habilitaApontamentoDias() {
        let datafinal = this.getDataFechamentoFolha(this.entity.mesreferencia, this.entity.anoreferencia);
        return moment().startOf('day').isAfter(datafinal);
    }

    fecharModal() {
        alert('Entrei no close');
        this.$uibModal.close();
    }

    ok() {
        alert('Entrei no Ok');
    }

    delete() {
        this.loadingDelete = true;
        let customText = {
            title: 'Excluir',
            subtitle: 'Após a exclusão, o registro deixará de existir até para consultas e não será possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir o movimento do mês de ' + this.meses[this.entity.mesreferencia] + '?',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir',
        };
        let confirm = this.ModalConfirmService.open(this.entity, 'Apontamento', false, customText);
        confirm.result.then(() => {
            this.entityService.delete(this.$stateParams['apontamento'], true);
        }).catch(() => {
            this.loadingDelete = false;
        });
    }

    onDeleteSuccess() {
        this.$scope.$on('apontamentos_apontamentos_deleted', (event: any, args: any) => {
            this.loadingDelete = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Sucesso ao excluir movimento!'
            });
            this.$state.go('apontamentos_apontamentos', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError() {
        this.$scope.$on('apontamentos_apontamentos_delete_error', (event: any, args: any) => {
            this.loadingDelete = false;
            if (args.response.data && typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.entity.situacao = null;
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    });
            } else {
                this.entity.situacao = null;
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Não é possível remover o apontamento pois ele possui apontamento(s) trabalhador(es) com movimento(s) pendente(s) ou aprovado(s)'
                    });
            }
        });
    }

    setLotacoes(entity: any) {
        entity.apontamentostrabalhadores.forEach((apontamento: any) => {
            apontamento.movimentos.map((elemento: any) => {
                if (elemento.lotacao) {
                    elemento.lotacao = elemento.lotacao.lotacao;
                }
            });
        });
    }

    submit() {
        this.entity.mesreferencia = parseInt(this.entity.valor, 10);
        if (this.form.$valid && !this.entity.$$__submitting) {
            let entityCopy = angular.copy(this.entity);
            this.setLotacoes(entityCopy);
            this.entityService.save(entityCopy);
        } else {
            this.entity.situacao = null;
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros'
            });
        }
    }

    onSubmitSuccess() {
        this.$scope.$on('apontamentos_apontamentos_submitted', (event: any, args: any) => {
            let insertSuccess = 'Sucesso ao inserir um novo movimento!';
            let updateSuccess = 'Sucesso ao enviar o movimento!';

            let insertRascunho = 'Sucesso ao inserir novo Rascunho!';
            let updateRascunho = 'Sucesso ao atualizar Rascunho!';

            if (args.response.config.method !== 'PUT') {
                this.constructors.apontamento = args.entity.apontamento;
            }

            if (this.entity.situacao === this.SITUACAO_RASCUNHO) {
                this.NewToaster.pop({
                    type: 'success',
                    title: args.response.config.method === 'PUT' ? updateRascunho : insertRascunho
                });
            } else {
                this.NewToaster.pop({
                    type: 'success',
                    title: args.response.config.method === 'PUT' ? updateSuccess : insertSuccess
                });
                this.$state.go('apontamentos_apontamentos', this.entityService.constructors);
            }
        });
    }

    onSubmitError() {
        this.$scope.$on('apontamentos_apontamentos_submit_error', (event: any, args: any) => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity);
                }
            } else {
                if (args.response.data && typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    this.entity.situacao = null;
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: args.response.data.message
                        });
                } else {
                    let insertFail = 'Ocorreu um erro ao inserir os movimentos!';
                    let updateFail = 'Ocorreu um erro ao atualizar os movimentos!';
                    this.entity.situacao = null;
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: args.response.config.method === 'PUT' ? updateFail : insertFail
                        });
                }
            }
        });
    }

    draftInsert(entity: any) {
        this.semMovimentos = false;
        this.entity.apontamentostrabalhadores.forEach((aptTrab: any, id: any) => {
            if (aptTrab.movimentos === undefined || aptTrab.movimentos === null || aptTrab.movimentos.length === 0) {
                this.semMovimentos = true;
            }
        });
        if (this.semMovimentos) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não é possível salvar um movimento sem rubricas'
            });
        } else {
            this.entity.situacao = this.SITUACAO_RASCUNHO;
            this.submit();
        }
    }

    draftUpdate(entity: any) {
        this.semMovimentos = false;
        if (this.entity.apontamentostrabalhadores !== undefined && this.entity.apontamentostrabalhadores.length > 0) {
            this.entity.apontamentostrabalhadores.forEach((aptTrab: any, id: any) => {
                if (aptTrab.movimentos === undefined || aptTrab.movimentos === null || aptTrab.movimentos.length === 0) {
                    this.semMovimentos = true;
                }
            });
            if (this.semMovimentos) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível salvar um movimento sem rubricas'
                });
            } else {
                this.entity.situacao = this.SITUACAO_RASCUNHO;
                this.submit();
            }
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não é possível salvar um movimento vazio'
            });
        }

    }

    apontamentosApontamentostrabalhadoresForm() {
        let modal = this.ApontamentosTrabalhadoresModalService.open();
        modal.result.then((subentity: any) => {
            if (this.entity.apontamentostrabalhadores === undefined) {
                this.entity.apontamentostrabalhadores = [subentity];
            } else {
                this.entity.apontamentostrabalhadores.push(subentity);
            }
            this.form.$setDirty();
        })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'fechar') {
                    this.entity.situacao = null;
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }
}
