import * as angular from 'angular';
import { MeurhSolicitacoesadmissoesShowFormController } from './solicitacoesadmissoes.show.form';

export class MeurhSolicitacoesadmissoesShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesadmissoesShowFormComponent';
	static controller = MeurhSolicitacoesadmissoesShowFormController;
	static template = require('!!html-loader!./solicitacoesadmissoes.show.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
