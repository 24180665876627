import angular from 'angular';
import { MeurhSolicitacoesalteracoesvtstarifasFormController } from './solicitacoesalteracoesvtstarifas.form';

export class MeurhSolicitacoesalteracoesvtstarifasFormComponent implements angular.IComponentOptions {
    static selector = 'meurhSolicitacoesalteracoesvtstarifasFormComponent';
    static controller = MeurhSolicitacoesalteracoesvtstarifasFormController;
    static template = require('html-loader!./solicitacoesalteracoesvtstarifas.form.html');
    static transclude = true;
    static bindings = {
        entity: '<',
        form: '<',
        busy: '=',
        action: '<',
        entitiesexcluidas: '<',
        params: '<',
    };
}
