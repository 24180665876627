import angular from 'angular';
import { MeurhAdmissoesContratoFormController } from './contrato.form.controller';

export class MeurhAdmissoesContratoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesContratoFormComponent';
	static controller = MeurhAdmissoesContratoFormController;
	static template = require('!!html-loader!./contrato.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
		desativaportrabalhadortemporario: '=',
	};
}
