import {deepCopy} from '@utils/utils';
import {Catch} from "@core/decorators/catch";
import {NsjRoutingInstance} from '@core/routing/nsj-rotas';
import {httpClient} from '@core/services/http-client';
import {ServerError} from "@core/services/server-error";
import {Usuario} from './models/usuario-model';

class UsuarioService {

    public Usuario: Usuario;
    private static inst: UsuarioService;

    private constructor() {
    }

    public static getUsuario(): UsuarioService {
        if (!UsuarioService.inst) {
            UsuarioService.inst = new UsuarioService();
        }
        return UsuarioService.inst;
    }

    @Catch(TypeError, (error, ctx) => {
        console.error({error, ctx});
        ServerError.showErrorPage('Ocorreu um erro ao carregar dados do usuário');
    })
    public async carregarUsuario() {

        // Passando a o tenant da url, se ele existir, na api de profile para verificar se o mesmo está bloqueado
        const indexUrl = window.location.href.indexOf('://') + 3;
        const indexUrlParams = window.location.href.indexOf('?');
        let urlSemProtocolo = (indexUrlParams > -1) ? window.location.href.substring(indexUrl, indexUrlParams) : window.location.href.substring(indexUrl);
        let baseTag = document.getElementsByTagName('base')[0];
        const dadosUrl = urlSemProtocolo.split('/');
        let domain = dadosUrl[0].split('.');
        let tenantUrl : any = !domain[0].includes('localhost') ? domain[0] : (dadosUrl.length > 1) ? dadosUrl[1] : null;
        const url = NsjRoutingInstance.Routing.generate('profile', {tenant_codigo:tenantUrl}, true);

        const response = await httpClient.get<Usuario>(url, true)
        this.Usuario = deepCopy(response.parsedBody);
    }
}

export const NsjUsuarioInstance: UsuarioService = UsuarioService.getUsuario();

