import angular from 'angular';
import { FormController } from './solicitacoesadiantamentosavulsos.showform.controller';

export class MeurhSolicitacoesadiantamentosavulsosDefaultShow implements angular.IComponentOptions {
  static template = require('html-loader!./solicitacoesadiantamentosavulsos.showform.html');
  static controller = FormController;
  static bindings = {
    entity: '<',
    form: '<',
    busy: '=',
    action: '<'
  };
}
