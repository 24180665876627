import angular from 'angular';
import { LoadParams } from '../../../../shared/models/loadparams.model';
import { IAlteracaoEntidade } from '../models/alteracaoentidade.model';

export class EstabelecimentosHabilitadosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

	public entities: Array<IAlteracaoEntidade> = []; // tipar
	public loaded: boolean = false;
	public constructors: any = {};
	public after: any = {};
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter: any = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $q: angular.IQService,
	) {
		this.loadParams = new LoadParams();
	}

    reload(): Array<IAlteracaoEntidade> {
		if (this.loading_deferred) {
			this.loading_deferred.resolve();
		}

		this.loadParams.finished = false;
		this.loadParams.to_load = 3;
		this.after = {};
		this.loaded = false;
		this.entities.length = 0;
		this.loadParams.busy = false;

		return this.load();
	}

    load(): Array<any> {
		if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
			this.loadParams.busy = true;

			this._load(this.constructors, this.after, this.filter)
				.then((data: any): void => {
					if (data.length > 0) {
						for (var i = 0; i < data.length; i++) {
							this.entities.push(data[i]);
						}
					}

					if (data.length < 20) {
						this.loadParams.finished = true;
						this.$rootScope.$broadcast('ponto_alteracoesentidades_list_finished', this.entities);
					} else {
						this.loadParams.finished = false;
					}

					this.loaded = true;
					this.loadParams.to_load--;

                    if (this.loadParams.to_load === 0 || data.length <= 20) {
						this.$rootScope.$broadcast('ponto_alteracoesentidades_list_loaded', this.entities);
                    }
				})
				.catch((error: any): void => {
					if (error.xhrStatus !== 'abort') {
						this.loadParams.finished = true;
					}
				})
				.finally((): void => {
					if (this.loaded || this.loadParams.finished) {
						this.loadParams.busy = false;
					}

					this.load();
				});
		}

		return this.entities;
	}

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('ponto_alteracoesentidades_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('ponto_alteracoesentidades_get', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('ponto_alteracoesentidades_get_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

	getListAlteracoesColaboradores(constructors?: any) {
		this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('ponto_alteracoescolaboradores_index', constructors, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('ponto_alteracoescolaboradores_list_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
	}

	inserirAlteracoesColaboradores(constructors: any, alteracoescolaboradores: any) {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('ponto_alteracoescolaboradores_inserir_lote', constructors, true),
				data: alteracoescolaboradores,
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_alteracoescolaboradores_inserir_sucesso', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_alteracoescolaboradores_inserir_lote_erro', response);
				reject(response);
			});
		});
	}

	inserirAlteracoesEstabelecimento(constructor: any, data: any) {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('ponto_alteracoesentidades_inserir', constructor, true),
				data,
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_alteracoesentidades_inserir_sucesso', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_alteracoesentidades_inserir_erro', response);
				reject(response);
			});
		});
	}

	getListTrabalhadoresPonto(constructors: any) {
		this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('ponto_trabalhadores_index', constructors, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('ponto_trabalhadores_list_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
					this.$rootScope.$broadcast('ponto_trabalhadores_get_erro', response.data);
                    reject(response);
                });
        });
	}

}
