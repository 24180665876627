export class ModalShowRelatorioController {
  static $inject = [
    'fileURL',
    '$uibModalInstance',
    '$sce'
  ];

  constructor(
    public fileURL: string,
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    private $sce: ng.ISCEService,
  ) { }

  public setStyle(): void {
    let modalDialog: any = document.getElementsByClassName('modal-dialog')[0];
    modalDialog.style.width = '90%';
    modalDialog.style.height = '90%';

    let modalContent: any = document.getElementsByClassName('modal-content')[0];
    modalContent.style.padding = '40px 20px 20px 20px';
    modalContent.style.height = '100%';

    this.fileURL = this.$sce.trustAsResourceUrl(this.fileURL);
  }
}
