import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { MeurhInclusaodependenteService } from '../inclusaodependente.service';
import { IInclusaodependente } from './../models/inclusaodependente.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhInclusaodependenteNewController implements ng.IController {
    static $inject = [
        'entity',
        '$scope',
        '$rootScope',
        '$stateParams',
        '$state',
        'MeurhInclusaodependenteService',
        'NewToaster',
        'ModalConfirmService',
        '$q',
        '$transitions',
        'TiposDocumentosRequeridosService',
    ];

    public constructors: any = {};
    public action = 'insert';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: IInclusaodependente;
    public dataAtual = moment();
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
    public toastTimeout: number = 8000;
    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;

    // documentos anexos
    public mostrarAlerta: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public editanexo: boolean = true;
    public entityAnexo: IAnexo = {};
    public alterouanexo: boolean = false;
    public documentosValidados: boolean = false;

    public exibeBtnAddBeneficios: boolean = false;
    public gestaoBeneficioAvancadaAtiva: boolean;

    constructor(
        public entity: IInclusaodependente,
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & { nsjGlobals: any, },
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhInclusaodependenteService,
        private NewToaster: any,
        private ModalConfirmService: any,
        private $q: any,
        private $transitions: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
    ) {
        this.entityAnexo.casodeuso = 'INCLUSAODEPENDENTE';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
        this.onTiposDocumentosRequeridosFinished();

        document.querySelector('form')?.addEventListener('change', (e) => {
            this.formchanged = true;
        });

        this.watchTrabalhador();
        this.onSubmitSuccess();
        this.onSubmitError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
        this.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;
    }

    // início - casos de uso
    public submit(): void {
        const customText = this.textoModal('enviar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Inclusão de dependente', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                this.form.$submitted = true;

                const envioValid = this.validaEnvio();

                if (envioValid) {
                    this.allowRefresh = false;
                    this.alteraEntity();
                    this.entityService.save(this.entitySave, {
                        empresa: this.entity.trabalhador?.empresaobj.empresa,
                        estabelecimento: this.entity.trabalhador?.estabelecimentoobj.estabelecimento
                    });
                } else {
                    this.busy = false;
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Não foi possível enviar. Favor preencher todos os campos requeridos assinalados com asterisco.'
                    });
                }
            })
            .catch(function () {/**/ });
    }

    public buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_inclusaodependente', angular.extend(this.entityService.constructors));
            })
                .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_inclusaodependente', angular.extend(this.entityService.constructors));
        }
    }
    // fim - casos de uso


    // início - permissões
    public podeEnviar(): boolean {
        const envioValid = this.validaEnvio();

        return envioValid;
    }
    // fim - permissões


    // início - validações
    private validaEnvio(): boolean {
        const dadosGeraisValid = this.validaDadosGerais();
        const pensaoAlimenticiaValid = this.validaPensaoAlimenticia();
        const documentosanexosValid = this.validaDocumentosAnexos();

        return dadosGeraisValid && pensaoAlimenticiaValid && documentosanexosValid;
    }

    private validaDadosGerais(): boolean {
        const identificacaoValid = this.validaIdentificacao();
        const certidaoNascimentoValid = this.validaCertidaoNascimento();
        const dependenteIRValid = this.validaImpostoRenda();
        const beneficiarioSalarioFamilia = this.validaSalarioFamilia();

        return (
            identificacaoValid &&
            certidaoNascimentoValid &&
            dependenteIRValid &&
            beneficiarioSalarioFamilia
        );
    }

    private validaIdentificacao(): boolean {
        const dataInclusaoValid = this.validaDataInclusao();

        return (
            !!this.entity.trabalhador &&
            !!this.entity.nome &&
            !!this.entity.cpf &&
            !!this.entity.parentesco &&
            !!this.entity.sexo &&
            dataInclusaoValid
        );
    }

    private validaDataInclusao(): boolean {
        return (
            !!this.entity.datainclusao &&
            moment(this.entity.datainclusao).isAfter(moment(this.entity.trabalhador?.dataadmissao))
        );
    }

    private validaCertidaoNascimento(): boolean {
        return !!this.entity.datanascimento;
    }

    private validaImpostoRenda(): boolean {
        return (
            !this.entity.impostorenda ||
            (
                !!this.entity.databaixaimpostorenda &&
                !(moment(this.entity.databaixaimpostorenda).isBefore(this.dataAtual))
            )
        );
    }

    private validaSalarioFamilia(): boolean {
        const datasSalarioFamiliaValid = this.validaDatasSalarioFamilia();

        return (
            !this.entity.salariofamilia ||
            !!this.entity.incapacidadefisicamental ||
            datasSalarioFamiliaValid
        );
    }

    private validaDatasSalarioFamilia(): boolean {
        const dataBaixaCertidaoValid = !!this.entity.databaixacertidao && moment(this.entity.databaixacertidao).isAfter(this.dataAtual);
        const vencimentoEscolarValid = !!this.entity.vencimentoescolar || this.entity.idade < 7 || this.entity.idade > 14;
        const vencimentoVacinacaoValid = !!this.entity.vencimentovacinacao || (this.entity.idade >= 7 && this.entity.idade <= 14);

        return (
            dataBaixaCertidaoValid &&
            vencimentoEscolarValid &&
            vencimentoVacinacaoValid
        );
    }

    private validaPensaoAlimenticia(): boolean {
        const tipoRecebimentoValid = this.validaRecebimento();

        return tipoRecebimentoValid;
    }

    private validaRecebimento(): boolean {
        const dadosBancariosValid = this.validaDadosBancarios();
        const dadosPixValid = this.validaDadosPix();

        return (
            !this.entity.pensaoalimenticia ||
            (
                !!this.entity.tiporecebimento &&
                dadosBancariosValid &&
                dadosPixValid &&
                !!this.entity.percentualpensaoalimenticia
            )
        );
    }

    private validaDadosBancarios(): boolean {
        if (
            this.entity.tiporecebimento === '1' ||
            this.entity.tiporecebimento === '2' ||
            this.entity.tiporecebimento === '4' ||
            this.entity.tiporecebimento === '64'
        ) {
            return (
                !!this.entity.banco &&
                !!this.entity.agencia &&
                !!this.entity.numeroconta &&
                !!this.entity.numerocontadv
            );
        }
        return true;
    }

    private validaDadosPix(): boolean {
        if (
            this.entity.tiporecebimento === '128' ||
            this.entity.tiporecebimento === '256' ||
            this.entity.tiporecebimento === '512' ||
            this.entity.tiporecebimento === '1024'
        ) {
            return !!this.entity.chavepix;
        }
        return true;
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
        });
    }
    // fim - validações


    // início - observers
    private watchTrabalhador(): void {
        this.$scope.$watch('mrh_inclsdpdt_frm_nw_cntrllr.entity.trabalhador', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
            this.exibeBtnAddBeneficios = newValue ? true : false;
        }, true);
    }

    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesinclusaodependentes_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.entityService.constructors.solicitacao = args.entity.solicitacao;

            this.entityAnexo['solicitacao'] = args.entity.solicitacao;

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

            this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                this.$scope.$apply();
                if (
                    this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult) &&
                    !this.documentosValidados
                ) {
                    this.documentosValidados = true;
                }
            });

            this.NewToaster.pop({
                type: 'success',
                title: 'A inclusão de dependente foi enviada com sucesso!'
            });

            this.$state.go('meurh_inclusaodependente_show', { 'solicitacao': args.entity.solicitacao });

            this.busy = false;
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesinclusaodependentes_submit_error', (event: angular.IAngularEvent, args: any): void => {
            let mensagemErro = '';
            let tituloErro = args.entity.situacao === -1 ? 'Ocorreu um erro ao enviar a inclusão de dependente!' : 'Ocorreu um erro ao enviar a inclusão de dependente!';

            if (!!args.response.data && !!args.response.data.errors && args.response.data.errors.errors.length > 0) {
                args.response.data.errors.errors.forEach((element: any) => {
                    mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                });

            } else if (!!args.response.data && !!args.response.data.message) {
                mensagemErro = '&bull; ' + args.response.data.message + '<br>';
            }

            this.busy = false;

            this.NewToaster.pop({
                type: 'error',
                title: tituloErro,
                body: mensagemErro,
                bodyOutputType: 'trustedHtml'
            });
        });
    }

    private onTiposDocumentosRequeridosFinished(): void {
        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });
        });
    }
    // fim - observers


    // início - modal de descarte
    private refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                    .catch((error: any): void => {/* */ });
            }
        }
    }

    private exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoesinclusaodependentes_new' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                    .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
    // fim - modal de descarte


    // início - tratamentos de dados
    private alteraEntity(): void {
        this.entitySave = angular.copy(this.entity);

        this.entitySave.datanascimento = !!this.entitySave.datanascimento ? moment(this.entitySave.datanascimento).format('YYYY-MM-DD') : null;
        this.entitySave.datainclusao = !!this.entitySave.datainclusao ? moment(this.entitySave.datainclusao).format('YYYY-MM-DD') : null;
        this.entitySave.databaixaimpostorenda = !!this.entitySave.databaixaimpostorenda ? moment(this.entitySave.databaixaimpostorenda).format('YYYY-MM-DD') : null;
        this.entitySave.beneficiariopensao = !!this.entity.beneficiariopensao ? angular.copy(this.entity.beneficiariopensao.dependentetrabalhador) : null;
        this.entitySave.agencia = !!this.entity.agencia ? angular.copy(this.entity.agencia['angencia']) : null;
        this.entitySave.percentualpensaoalimenticia = !!this.entity.percentualpensaoalimenticia ? this.entity.percentualpensaoalimenticia * 100 : null;
        this.entitySave.percentualpensaoalimenticiafgts = !!this.entity.percentualpensaoalimenticiafgts ? this.entity.percentualpensaoalimenticiafgts * 100 : null;

        if (this.entitySave.beneficios) {
            this.entitySave.beneficios.forEach((element: any) => {
                element.lotacao = angular.copy(element.lotacao.lotacao);
                element.instituicao = element.instituicao ? angular.copy(element.instituicao.instituicao) : element.instituicao;
            });
        }

    }

    private textoModal(textType: 'enviar'): { title: string, confirmText: string, closeButton: string, confirmButton: string } {
        const customText = {
            'enviar': {
                title: 'Enviar inclusão de dependente',
                confirmText: 'Você tem certeza que deseja enviar?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar',
            }
        };

        return customText[textType];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = {
            'tiposolicitacao': this.tipoSolicitacaoEnum.INCLUSAODEPENDENTE
        };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
    // fim - tratamentos de dados
}
