import angular from 'angular';
import { MeurhVagasService } from '../../../../Vagas/vagas.service';

export class MeurhInclusaodependenteBeneficiosFormmodalController implements ng.IController {

    static $inject = [
        '$scope',
        '$rootScope',
        'NewToaster',
        'MeurhVagasService',
    ];

    public beneficio: any;
    public action: string;
    public objinclusao: any;
    public desativaValor: boolean = true;
    public desativaDesconto: boolean = true;
    public dataAtual = new Date();
    public anoAtual = this.dataAtual.getFullYear();
    public beneficiosVaga = [];
    public buscandoBeneficiosVaga: boolean = false;
    public beneficioDuplicado: boolean = false;
    public mesesArray = [
        {
            id: 1,
            mes: 'Janeiro'
        },
        {
            id: 2,
            mes: 'Fevereiro'
        },
        {
            id: 3,
            mes: 'Março',
        },
        {
            id: 4,
            mes: 'Abril',
        },
        {
            id: 5,
            mes: 'Maio',
        },
        {
            id: 6,
            mes: 'Junho',
        },
        {
            id: 7,
            mes: 'Julho',
        },
        {
            id: 8,
            mes: 'Agosto',
        },
        {
            id: 9,
            mes: 'Setembro',
        },
        {
            id: 10,
            mes: 'Outubro',
        },
        {
            id: 11,
            mes: 'Novembro',
        },
        {
            id: 12,
            mes: 'Dezembro',
        }];
    public anosArray = [
        this.anoAtual, (this.anoAtual + 1), (this.anoAtual + 2), (this.anoAtual + 3), (this.anoAtual + 4), (this.anoAtual + 5)
    ];

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService,
        public NewToaster: any,
        public MeurhVagasService: MeurhVagasService,
    ) {
    }

    async $onInit(): Promise<void> {
        this.objinclusao.desativaBotoesSalvar = false;

        if (this.valorExiste(this.beneficio.quantidade) && this.beneficio.quantidade !== 0) {
            this.beneficio.tipoperiodo = this.beneficio.tipoperiodo >= 0 ? this.beneficio.tipoperiodo.toString() : this.beneficio.tipoperiodo;
            this.beneficio.mesinicial = this.beneficio.mesinicial ? this.beneficio.mesinicial.toString() : this.beneficio.mesinicial;
            this.beneficio.anoinicial = this.beneficio.anoinicial ? this.beneficio.anoinicial.toString() : this.beneficio.anoinicial;
            this.beneficio.mesfinal = this.beneficio.mesfinal ? this.beneficio.mesfinal.toString() : this.beneficio.mesfinal;
            this.beneficio.anofinal = this.beneficio.anofinal ? this.beneficio.anofinal.toString() : this.beneficio.anofinal;
            this.beneficio.mes = this.beneficio.mes ? this.beneficio.mes.toString() : this.beneficio.mes;
        } else {
            this.beneficio.quantidade = 1;
            this.beneficio.lotacao = this.objinclusao.lotacao;
        }

        if (this.objinclusao.vaga) {
            this.buscandoBeneficiosVaga = true;
            await this.MeurhVagasService.get(this.objinclusao.vaga)
            .then((dadosVaga: any) => {

                this.beneficiosVaga = dadosVaga.beneficios.map((beneficio) => {
                    return beneficio.beneficio;
                });

                // editando ou visualizando benefício já adicionado
                if (this.beneficio?.beneficio?.beneficio && this.beneficiosVaga?.length > 0) {
                    this.beneficio.beneficio = this.beneficiosVaga.filter((beneficioVaga: any) => {
                        return beneficioVaga.beneficio === this.beneficio.beneficio.beneficio;
                    })[0];
                }
                this.buscandoBeneficiosVaga = false;
            })
            .catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível buscar os benefícios da vaga'
                });
                this.buscandoBeneficiosVaga = false;
            });
        }

        this.verificaValor();
    }

    getQuantidade(event: angular.IAngularEvent & { detail: any }): void {
        this.beneficio.quantidade = event.detail.value;
        this.beneficio.erroQuantidade = event.detail.error;
    }

    verificaValor(): void {
        this.beneficio.descricao = this.valorExiste(this.beneficio.beneficio) ? this.beneficio.beneficio.descricao : '';
        if (this.valorExiste(this.beneficio.beneficio) && this.beneficio.beneficio.tipovalor === 1) {
            this.desativaValor = false;
        } else {
            this.desativaValor = true;
            this.beneficio.valor = null;
        }
        this.verificaDesconto();
    }

    verificaDuplicidadeBeneficio(beneficioSelecionado: any) {

        let objBeneficioDuplicado;
        let beneficiosJaAdicionados = this.objinclusao.beneficios;

        if (this.action === 'insert' && beneficioSelecionado && beneficiosJaAdicionados.length > 0) {
            this.beneficioDuplicado = beneficiosJaAdicionados.some((beneficioAdicionado) => {
                return beneficioSelecionado.beneficio === beneficioAdicionado.beneficio.beneficio;
            });
        } else if (this.action === 'edit' && beneficioSelecionado && beneficiosJaAdicionados.length > 0) {
            objBeneficioDuplicado = beneficiosJaAdicionados.filter((beneficioAdicionado) => {
                return beneficioSelecionado.beneficio === beneficioAdicionado.beneficio.beneficio;
            })[0];

            if (!objBeneficioDuplicado && beneficioSelecionado) {
                this.beneficioDuplicado = false;
            } else if (objBeneficioDuplicado && beneficioSelecionado) {
                this.beneficioDuplicado = !(objBeneficioDuplicado.beneficio.beneficio === this.beneficio.beneficio.beneficio && objBeneficioDuplicado.counter === this.beneficio.counter);
            }
        }

        this.objinclusao.desativaBotoesSalvar = this.beneficioDuplicado;

        if (this.beneficioDuplicado && beneficioSelecionado) {
            this.NewToaster.pop({
                type: 'error',
                title: 'O benefício selecionado já foi adicionado ao dependente'
            });
        }

    }

    verificaDesconto(): void {
        if (this.valorExiste(this.beneficio.beneficio) && this.beneficio.beneficio.tipodesconto === 2) {
            this.desativaDesconto = false;
        } else {
            this.desativaDesconto = true;
            this.beneficio.desconto = null;
        }
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    getArrayAnosFinais(): Array<number> {
        if (this.beneficio.anoinicial) {
            var newAnosArray: Array<number> = [];
            this.anosArray.forEach((ano) => {
                if (ano >= this.beneficio.anoinicial) {
                    newAnosArray.push(ano);
                }
            });
            return newAnosArray;
        } else {
            return this.anosArray;
        }
    }

    getArrayAnosIniciais(): Array<number> {
        if (this.beneficio.anofinal) {
            var newAnosArray: Array<number> = [];
            this.anosArray.forEach((ano) => {
                if (ano <= this.beneficio.anofinal) {
                    newAnosArray.push(ano);
                }
            });
            return newAnosArray;
        } else {
            return this.anosArray;
        }
    }

    getArrayMesesIniciais(): { id: number; mes: string; }[] {
        if (this.beneficio.mesfinal && this.beneficio.anoinicial && this.beneficio.anofinal && this.beneficio.anofinal === this.beneficio.anoinicial) {
            var newMesesArray: { id: number; mes: string; }[] = [];
            this.mesesArray.forEach((mes) => {
                if (mes['id'] <= this.beneficio.mesfinal) {
                    newMesesArray.push(mes);
                }
            });
            return newMesesArray;
        } else {
            return this.mesesArray;
        }
    }

    getArrayMesesFinais(): { id: number; mes: string; }[] {
        if (this.beneficio.mesinicial && this.beneficio.anoinicial && this.beneficio.anofinal && this.beneficio.anofinal === this.beneficio.anoinicial) {
            var newMesesArray: { id: number; mes: string; }[] = [];
            this.mesesArray.forEach((mes) => {

                if (mes['id'] >= this.beneficio.mesinicial) {
                    newMesesArray.push(mes);
                }
            });
            return newMesesArray;
        } else {
            return this.mesesArray;
        }
    }

}
