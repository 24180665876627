import angular from 'angular';
import { StatusProcessoEnum } from '../../enums/statusprocesso';
import { MeurhAdmissaocompletaModalService } from '../../modal/admissaocompleta.modal.service';

export class MeurhAdmissoesValetransporteFormController implements ng.IController {

    static $inject = ['$scope', 'NewToaster', 'MeurhAdmissaocompletaModalService', 'ModalConfirmService'];

    public entity: any;
    public action: string;
    public idCount: number = 0;

    public statusProcessoEnum = StatusProcessoEnum;

    public fields: any = [
        {
            value: 'concessionariavt.nome',
            label: 'Concessionária',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'tarifaconcessionariavt.codigo',
            label: 'Vale',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'tarifaconcessionariavt.descricao',
            label: 'Descrição',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'quantidade',
            label: 'Quantidade',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'tarifaconcessionariavt.valor',
            label: 'Valor',
            type: 'moeda',
            style: 'default',
            copy: '',
        },
        {
            value: 'totaldiario',
            label: 'Total diário',
            type: 'moeda',
            style: 'default',
            copy: '',
        },
    ];

    constructor(
        public $scope: angular.IScope,
        public NewToaster: any,
        public MeurhAdmissaocompletaModalService: MeurhAdmissaocompletaModalService,
        public ModalConfirmService: any
    ) {
    }

    $onInit(): void {
        if (!this.entity.vts) {
            this.entity.vts = [];
        } else {
            if (this.entity.vts.length > 0) {
                this.entity.vts.forEach((element: any) => {
                    element['counter'] = this.idCount++;
                    element['totaldiario'] = element.tarifaconcessionariavt.valor * element.quantidade;
                });
            }
        }
    }

    abrirModal(vt: any = {}, action: string = ''): void {
        let parameters = {
            'componente' : 'Vale-transporte',
            'action' : action ? action : this.action ,
        };

        let modal = this.MeurhAdmissaocompletaModalService.open(vt, parameters);

        modal.result
            .then((response: any): void => {
                if (!this.valorExiste(response.counter)) {
                    response.counter = this.idCount;
                    this.idCount++;
                    this.entity.vts.push(angular.copy(response));
                    this.$scope.$applyAsync();
                } else {
                    let achouVt = false;
                    this.entity.vts.forEach((element: any, index: any) => {
                        if ((achouVt === false) && (vt.counter === element.counter)) {
                            this.entity.vts[index] = angular.copy(response);
                            achouVt = true;
                        }
                    });
                }
            })
            .catch((error: any): void => {/**/});
    }

    excluirVt(vt: any): void {
        let textos = {
            title: 'Excluir vale-transporte',
            subtitle: 'Após a exclusão não será mais possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir este vale-transporte?',
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir vale-transporte',
        };
		let confirm = this.ModalConfirmService.open(this.entity, 'Vale-transporte', false, textos);

        confirm.result.then((): void => {
            this.excluir(vt);
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    excluir(vt: any): void {
        let achouVt = false;
        this.entity.vts.forEach((element: any, index: any) => {
            if ((achouVt === false) && (vt.counter === element.counter)) {
                achouVt = true;
                this.entity.vts.splice(index, 1);
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
