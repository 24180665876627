import angular from 'angular';
import { NewToasterController } from './new.toaster.controller';
import { NewToasterComponent } from './new.toaster.component';
import { NewToaster } from './new.toaster.service';


export const NewToasterModule = angular.module('NewToasterModule', ['ui.router.state'])
    .controller('NewToasterController', NewToasterController)
    .service('NewToaster', NewToaster)
    .component('newToasterComponent', NewToasterComponent)
    .name;
