import angular from 'angular';
import moment from 'moment';
import { MeurhSolicitacoesFaltasService } from '../solicitacoesfaltas.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IFalta } from '../models/falta.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhSolicitacoesfaltasIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$location',
        '$transitions',
        'nsjRouting',
        'NewToaster',
        'MeurhSolicitacoesFaltasService',
        'ListagemService'
    ];

    public busy: boolean = false;
    public dataInicial: string | null = null;
    public dataFinal: string | null = null;
    public dataAtual: moment.Moment = moment();
    public maxoccurs: object = {};
    public tipo: string;
    public tipoInicial: string;
    public filters: any;
    public filter: any;
    public stateService: angular.ui.IStateService;
    public constructors: any;
    public entities: Array<IFalta>;
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_falta'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public options: object = {
        'situacao': 'Situação',
        'estabelecimento': 'Estabelecimento'
    };
    public selects: object = {
        'situacao': {
            '0': 'Enviada',
            '1': 'Efetuada',
            '2': 'Cancelada',
            '4': 'Recusada'
        }
    };
    public nomesMeses: Array<string> = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ];
    public fields: any = [
        {
            value: 'datas',
            label: 'Data(s)',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'mesdescontocalculo',
            label: 'mês para cálculo',
            type: 'integer',
            style: 'default',
            copy: '',
        },
        {
            value: 'anodescontocalculo',
            label: 'ano para cálculo',
            type: 'integer',
            style: 'default',
            copy: '',
        },
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            temPermissao: (arg: string) => boolean,
            session: any,
            nsjGlobals: any,
            tipo: any,
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: any,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: MeurhSolicitacoesFaltasService,
        public ListagemService: ListagemService
    ) {
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.tipo = $rootScope['tipo'] = $stateParams.trabalhador.tipo;
        this.tipoInicial = $stateParams.trabalhador.tipo;
        this.filters = entityService.filters = {};
        this.montaListagemVisibilidade();
        this.stateService = $state;
        this.constructors = entityService.constructors = [];
        this.entities = entityService.reload();

        if (!$rootScope.temPermissao('visualizar_falta')) {
            $state.go('dp', $stateParams);
        }

        $rootScope.$on('meurh_solicitacoesfaltas_list_finished', (event: angular.IAngularEvent, args: any): void => {
            entityService.entities.forEach((item: IFalta): void => {
                if (item.hasOwnProperty('datas') && typeof(item.datas) === 'string') {
                  item.datas = item.datas.replace(/[{}]/g, '').split(',');
                }
            });
        });

        for (let i in $stateParams) {
            if (['situacao', 'estabelecimento', ].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                entityService.filters[i] = $stateParams[i];
            } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
                entityService.constructors[i] = $stateParams[i];
            }
        }

        $scope.$on('meurh_solicitacoesfaltas_deleted', (event: angular.IAngularEvent): void => {
            entityService.reload();
        });

        $scope.$on('$destroy', (): void => {
            if (entityService.loading_deferred) {
                entityService.loading_deferred.resolve();
            }
        });

        $scope.$on('meurh_solicitacoesfaltas_submitted', (event: angular.IAngularEvent, args: any): void => {
            if (!args.autosave && args.entity.situacao !== -1) {
                entityService.reload();
            }
        });
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
    }

    escondeRascunhos(obj: IFalta): boolean {
        if (obj.situacao === -1) {
            return false;
        } else {
            return true;
        }
    }

    reload(): void {
        this.filterReload();
    }

    search(): Array<IFalta> {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }

        let filter = {
            search: this.entityService.filter,
            filters: angular.copy(this.entityService.filters)
        };

        return this.entityService.search(filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    finished(): boolean {
        return this.entityService.loadParams.finished;
    }

    generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    filterReload(): void {
        if (this.filters.hasOwnProperty('situacao') && this.filters.situacao === '') {
            delete this.filters.situacao;
        }

        this.search();
    }

    filtraTipo(): void {
        if (this.entityService.constructors.hasOwnProperty('trabalhador_tipo')  && (this.tipo === '' || this.tipo === '-1')) {
            delete this.entityService.constructors.trabalhador_tipo;
            this.entities = this.search();
        } else {
            this.entityService.constructors.trabalhador_tipo = this.tipo;
            this.entities = this.search();
        }
    }

    filterReloadCreatedAt(): void {
        if (
            this.dataInicial !== null &&
            this.dataFinal !== null &&
            this.dataInicial !== '' &&
            this.dataFinal !== '' &&
            this.dataInicial > this.dataFinal
        ) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial não pode ser posterior a data final'
            });
            return;
        }

        let created_at: Array<{condition: string, value: string}> = [];

        if (
            this.dataInicial === undefined ||
            (this.dataInicial !== null && this.dataInicial.length === 0)
        ) {
            this.dataInicial = null;
            this.constructors.datainicial = null;
        }

        if (this.dataInicial !== null) {
            created_at.push({
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
            });

            delete this.constructors.datainicial;
        }

        if (
            this.dataFinal === undefined ||
            (this.dataFinal !== null && this.dataFinal.length === 0)
        ) {
            this.dataFinal = null;
            this.constructors.datafinal = null;
        }

        if (this.dataFinal !== null) {
            created_at.push({
                condition: 'lte',
                value: this.dataFinal + ' 23:59:59'
            });

            delete this.constructors.datafinal;
        }

        this.constructors.created_at = created_at;
        this.entityService.constructors = this.constructors;
        this.entityService.reload();
    }
}
