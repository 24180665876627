import * as angular from 'angular';

import { MeurhSolicitacoesListController } from './index/index.controller';
import { MeurhSolicitacoesShowController } from './show/solicitacoes.show.controller';
import { MeurhSolicitacoesFormShowController } from './showform/solicitacoes.form.show.controller';
import { MeurhSolicitacoesDefaultShow } from './showform/solicitacoes.form.show.component';
import { MeurhSolicitacoes } from './services/solicitacoes.service';
import { MeurhVisualizarSolicitacaoService } from './services/visualizarsolicitacao.service';
import { descricaoSolicitacaoFilter } from './filter/descricao-solicitacao.filter';
import { solicitacoesRoutes } from './solicitacoes.routes';

export const MeurhSolicitacoesModule = angular.module('meurhSolicitacoesModule', ['ui.router.state'])
	.controller('MeurhSolicitacoesListController', MeurhSolicitacoesListController)
	.controller('MeurhSolicitacoesFormShowController', MeurhSolicitacoesFormShowController)
	.controller('MeurhSolicitacoesShowController', MeurhSolicitacoesShowController)
	.component('meurhSolicitacoesDefaultShow', MeurhSolicitacoesDefaultShow)
	.service('MeurhSolicitacoes', MeurhSolicitacoes)
	.service('MeurhVisualizarSolicitacaoService', MeurhVisualizarSolicitacaoService)
	.filter(descricaoSolicitacaoFilter)
	.config(solicitacoesRoutes)
	.name;
