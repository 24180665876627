import angular from 'angular';
import { MeurhRelatoriossolicitacoesController } from './relatoriossolicitacoes.controller';

export class MeurhRelatoriossolicitacoesComponent implements angular.IComponentOptions {
	static controller = MeurhRelatoriossolicitacoesController;
	static controllerAs = 'rltrslctc_ctrl';
	static template = require('!!html-loader!./relatoriossolicitacoes.html');

	static bindings = {
		tipo: '@',
		entityGuid: '<',
		situacao: '<'
	};
}
