import * as angular from 'angular';
import { MeurhAdmissoesestagiarioDadoscontatoFormController } from './dadoscontato.form.controller';

export class MeurhAdmissoesestagiarioDadoscontatoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioDadoscontatoFormComponent';
	static controller = MeurhAdmissoesestagiarioDadoscontatoFormController;
	static template = require('!!html-loader!./dadoscontato.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
