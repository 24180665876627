import angular = require('angular');
import moment = require('moment');
import { MeurhVagasModalService } from '../../modal/vagas.modal.service';

export class MeurhVagasValetransporteFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'MeurhVagasModalService'];

    public entity: any;
    public action: string;
    public fields: any = [
        {
            value: 'tarifaconcessionariavt.codigo',
            label: 'Código',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'tarifaconcessionariavt.descricao',
            label: 'Vale',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'quantidade',
            label: 'Quantidade',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'tarifaconcessionariavt.valor',
            label: 'Valor',
            type: 'moeda',
            style: 'default',
            copy: '',
        },
        {
            value: 'totaldiario',
            label: 'Total Diário',
            type: 'moeda',
            style: 'default',
            copy: '',
        }
    ];

    public idCount: number = 0; // identificador de cada item da lista de vales-transporte adicionados

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public MeurhVagasModalService: MeurhVagasModalService
    ) {

    }

    $onInit(): void {
        if (!this.entity.vts) {
            this.entity.vts = [];
        } else {
            if (this.entity.vts.length > 0) {
                this.entity.vts.forEach((element: any) => {
                    element['counter'] = this.idCount++;
                    element['totaldiario'] = element.tarifaconcessionariavt.valor * element.quantidade;
                });
            }
        }
    }

    abrirModal(vt: any = {}, action: string = ''): void {
        let parameters = {
            'componente' : 'Vale-transporte',
            'action' : action ? action : this.action,
        };

        let modal = this.MeurhVagasModalService.open(vt, parameters);

        modal.result
            .then((response: any): void => {
                if (!this.valorExiste(response.counter)) {
                    response.counter = this.idCount;
                    this.idCount++;
                    this.entity.vts.push(angular.copy(response));
                    this.$scope.$applyAsync();
                } else {
                    let achouVt = false;
                    this.entity.vts.forEach((element: any, index: any) => {
                        if ((achouVt === false) && (vt.counter === element.counter)) {
                            this.entity.vts[index] = angular.copy(response);
                            achouVt = true;
                        }
                    });
                }
            })
            .catch((error: any): void => {/**/});
    }

    excluirVt(vt: any): void {
        let achouVt = false;
        this.entity.vts.forEach((element: any, index: any) => {
            if ((achouVt === false) && (vt.counter === element.counter)) {
                achouVt = true;
                this.entity.vts.splice(index, 1);
            }
        });
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
