declare var nsjGlobals: any;
import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { MeurhAdmissaoestagiarioService } from '../admissaoestagiario.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoRecebimentobolsaEnum } from '../enums/tiporecebimentobolsa.enum';
import { SituacaoAdmissaoEnum } from '../enums/situacao';
import { IGestoresTrabalhador } from '../../Transferenciacolaboradores/models/transferenciacolaboradores.model';

export class MeurhAdmissaoestagiarioNewController implements ng.IController {
    static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhAdmissaoestagiarioService',
        'entity',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        '$rootScope',
        'ModalConfirmService'
    ];

    public constructors: any = {};
    public action = 'insert';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: any = {};
    public dataAtual = new Date();
    public toastController = {
        podeEnviar: false,
        podeEncaminhar: false
    };

    public matriculaAutomatica: boolean = false;
    public mostrarAlertaDocumentosAnexos: boolean = false;
    public mostraobrigatoriosencaminhar: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public validate: number = 0; // conta quantos erros há em documentos anexos
    public busyTipoDocumentos: boolean = true;
    public entityAnexo: IAnexo = {};
    public formAnexo: angular.IFormController;
    public editanexo: boolean = true;
    public adicionarAnexo: boolean = false;
    public actionAnexo: string = 'insert';
    public desativaportrabalhadortemporario: boolean = false;
    public documentosValidados: boolean = false;

    public clienteAna: boolean;
    public idsCards: Array<string> = [
        'card-dadosgerais',
        'card-docsanexos',
        'card-endereco',
        'card-dadoscontato',
        'card-dadospessoais',
        'card-documentacao',
        'card-outrasinfos',
        'card-observacoes',
        'card-beneficios',
        'card-dependentes',
        'card-valestransporte',
    ];

    private tipoSolicitacaoEnum = TipoSolicitacaoEnum;
    private situacaoAdmissaoEnum = SituacaoAdmissaoEnum;
    private tipoRecebimentobolsaEnum = TipoRecebimentobolsaEnum;

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhAdmissaoestagiarioService,
        public entity: any,
        public NewToaster: any,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public $rootScope: angular.IRootScopeService & {
            nsjGlobals: any,
            configuracoes: any,
            codigoHook: number | string,
            liberadoTela: (param: string) => boolean
        },
        public ModalConfirmService: any
    ) {
        this.entity = {};

        this.entityAnexo['casodeuso'] = 'ADMISSOESESTAGIARIO';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

        this.podeEnviarObserver();
        this.podeEncaminharObserver();
        this.onSubmitSuccess();
        this.onSubmitError();
        this.onTiposDocumentosListFinished();
        this.clienteAna = nsjGlobals.get('clienteana');
        this.entity.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;
    }

    $onInit(): void {
        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
        }
        let percAdiant = this.$rootScope.configuracoes.PERCENTUAL_AD_PADRAO_FUNCIONARIO !== null ? this.$rootScope.configuracoes.PERCENTUAL_AD_PADRAO_FUNCIONARIO / 100 : 0.4;

        this.entity.inicioperiodoaquisitivoferias = moment(this.dataAtual);
        this.entity.datavencimentoatestadomedico = moment(this.dataAtual).add(1, 'years');
        this.entity.quantidademediahorassemanais = 30;
        this.entity.numerodiasperiodo = 30;
        this.entity.numerohorasmensais = 150;
        this.entity.percentualadiantamento = percAdiant;
        this.entity.tiporegimetrabalhista = '1';
        this.entity.tipoatividade = '2';
        this.entity.pais = {
            nome: 'BRASIL',
            pais: '1058'
        };
        this.entity.paisnascimento = angular.copy(this.entity.pais);
        this.entity.paisnacionalidade = angular.copy(this.entity.pais);
        this.entity.categoriatrabalhador = {
            categoriatrabalhador: '901',
            descricao: 'Estagiário'
        };
        this.entity.tipocategoriatrabalhador = { 'tipocolaborador': '2' };
        this.entity.atividade = '2';
    }

    // início - use cases
    public submit(rascunho: boolean = false): void {
        const customText = this.getMensagensModal(rascunho ? 'rascunho' : 'enviar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                this.form.$submitted = true;

                if (
                    (rascunho && this.podeSalvarRascunho()) ||
                    (!this.entity.$$__submitting && this.validaEntityEnviar())
                ) {
                    this.alteraEntityEnviar(rascunho);
                    this.entityService.save(this.entitySave);
                } else {
                    if (!this.validaDocumentosAnexos() && !rascunho) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há documento(s) obrigatório(s) não informado(s)'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor verificar os campos sinalizados com erro.'
                        });
                    }

                    this.busy = false;
                }
            })
            .catch(() => {/**/ });
    }

    public encaminhar(opt?: string): void {
        const customText = this.getMensagensModal('encaminhar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                this.alteraEntityEnviar(true);

                this.entityService._save(this.entitySave, false)
                    .then(async (rescisaoResponse: ng.IHttpResponse<any>) => {
                        this.constructors.solicitacao = rescisaoResponse.data.solicitacao;

                        await this.entityService.encaminhar(this.constructors.solicitacao, opt)
                            .then((encaminharResponse: ng.IHttpResponse<any>) => {
                                const titleMessage = opt
                                    ? 'Código gerado com sucesso'
                                    : 'A admissão foi encaminhada com sucesso.';

                                const bodyMessage = opt
                                    ? 'O código da admissão foi copiado, você já pode colar onde quiser para mandar para o futuro colaborador.'
                                    : null;

                                this.NewToaster.pop({
                                    type: 'success',
                                    title: titleMessage,
                                    body: bodyMessage,
                                });

                                if (!!encaminharResponse.data.codigoverificacao) {
                                    navigator.clipboard.writeText(encaminharResponse.data.codigoverificacao);
                                }
                            })
                            .catch(() => {
                                this.NewToaster.pop({
                                    type: 'error',
                                    title: 'Ocorreu um erro ao encaminhar a admissão para o aplicativo'
                                });
                            })
                            .finally(async () => {
                                this.$state.go('meurh_admissaoestagiario_show', this.constructors);
                            });
                    })
                    .catch(() => {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro ao salvar a admissão antes de encaminhar para o aplicativo',
                        });
                        this.busy = false;
                    });
            })
            .catch(() => {/** */ });
    }

    public descartar(): void {
        const customText = this.getMensagensModal('descartar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result.then((): void => {
            this.busy = true;
            this.$state.go('meurh_admissaocompleta', {});
        })
            .catch((error: any): void => {
                if (
                    error !== 'backdrop click' &&
                    error !== 'cancelar' &&
                    error !== 'escape key press'
                ) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }
    // fim - use cases


    // início - controle de botões e campos
    public podeEnviar(): boolean {
        const podeEnviar = this.validaEntityEnviar();

        this.toastController.podeEnviar = podeEnviar;

        return podeEnviar;
    }

    public podeSalvarRascunho(): boolean {
        return (
            this.form.nome.$valid &&
            this.validaAmbienteTrabalho() &&
            this.validaDadosBancarios()
        );
    }

    public podeEncaminhar(): boolean {
        const podeEncaminhar = this.validaEncaminhar();

        this.toastController.podeEncaminhar = podeEncaminhar;

        return this.toastController.podeEncaminhar;
    }

    public mostrarObrigatoriosEnviar(): void {

        this.idsCards.forEach((idCard) => {
            this.verificaExpansaoCard(idCard);
        });

        this.form.$setPristine();
        this.form.$setSubmitted();

        this.mostrarAlertaDocumentosAnexos = true;
        this.mostraobrigatoriosencaminhar = false;
    }

    public mostrarObrigatoriosEncaminhar(): void {
        if (this.form) {
            this.form.$setPristine();
            this.form.matricula.$setDirty();
            this.form.nome.$setDirty();
            this.form.email.$setDirty();
            this.form.categoriatrabalhador.$setDirty();
            this.form.valorbolsa.$setDirty();
            this.form.cargo.$setDirty();
            this.form.nivelcargo.$setDirty();
            this.form.departamento.$setDirty();
            this.form.horario.$setDirty();
            this.form.lotacao.$setDirty();
            this.form.unidadebolsa.$setDirty();

            if (this.entity.vagaAtiva) {
                this.form.vaga.$setDirty();
            } else {
                this.form.estabelecimento.$setDirty();
            }
        }

        this.mostrarAlertaDocumentosAnexos = false;
        this.mostraobrigatoriosencaminhar = true;
    }
    // fim - controle de botões e campos


    // início - validações
    private validaEntityEnviar(): boolean {
        return (
            this.validaDadosGerais() &&
            this.validaEndereco() &&
            this.validaDadosContato() &&
            this.validaDadosPessoais() &&
            this.validaDocumentacao() &&
            this.validaDocumentosAnexos() &&
            this.validaOutrasInformacoes()
        );
    }

    private validaEncaminhar(): boolean {
        return (
            this.validaMatricula() &&
            this.form.nome.$valid &&
            !!this.entity.email &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaBolsa() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaDadosGerais(): boolean {
        return (
            this.validaIdentificacao() &&
            this.validaDadosVaga() &&
            this.validaAmbienteTrabalho() &&
            this.validaDadosEmpresa() &&
            this.validaContrato() &&
            this.validaPeriodos() &&
            this.validaBolsa() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaIdentificacao(): boolean {
        const emailValid = this.validaEmail();

        return (
            this.validaMatricula() &&
            this.form.nome.$valid &&
            this.form.cpf.$valid &&
            !!this.entity.datanascimento &&
            emailValid
        );
    }

    private validaMatricula(): boolean {
        return (
            this.matriculaAutomatica ||
            !!this.entity.matricula
        );
    }

    private validaEmail(): boolean {
        return (
            !!this.entity.emailcorporativo ||
            !!this.entity.email
        );
    }

    private validaDadosVaga(): boolean {
        return (
            !!this.entity.vagaAtiva === !!this.entity.vaga &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo
        );
    }

    private validaAmbienteTrabalho(): boolean {
        return (
            !this.entity.ambiente ||
            !!this.entity.condicaoambientetrabalho
        );
    }

    private validaDadosEmpresa(): boolean {
        return (
            !!this.entity.estabelecimento &&
            !!this.entity.departamento &&
            !!this.entity.lotacao
        );
    }

    private validaContrato(): boolean {
        return (
            !!this.entity.datainiciocontrato &&
            !!this.entity.datafimcontrato
        );
    }

    private validaPeriodos(): boolean {
        return !(
            !(!!this.entity.numerodiasperiodo) ||
            this.entity.numerodiasperiodo > 31 ||
            !(!!this.entity.numerohorasmensais) ||
            !(!!this.entity.quantidademediahorassemanais)
        );
    }

    private validaBolsa(): boolean {
        return (
            !!this.entity.valorbolsa &&
            !!this.entity.unidadebolsa
        );
    }

    private validaOutrasInformacoesGerais(): boolean {
        return (
            !!this.entity.categoriatrabalhador &&
            !!this.entity.atividade &&
            !!this.entity.horario
        );
    }

    private validaEndereco(): boolean {
        return (
            !!this.entity.cep &&
            !!this.entity.tipologradouro &&
            !!this.entity.logradouro &&
            !!this.entity.numero &&
            !!this.entity.municipio &&
            !!this.entity.pais
        );
    }

    private validaDadosContato(): boolean {
        return this.validaTelefones();
    }

    private validaTelefones(): boolean {
        return (
            this.validaTelefone() &&
            this.validaCelular()
        );
    }

    private validaTelefone(): boolean {
        return !(!!this.entity.telefone) || this.entity.telefone.length >= 8;
    }

    private validaCelular(): boolean {
        return !(!!this.entity.celular) || this.entity.celular.length >= 8;
    }

    private validaDadosPessoais(): boolean {
        return (
            this.validaNascimento() &&
            this.validaIdentificacaoDadosPessoais()
        );
    }

    private validaNascimento(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.paisnascimento) {
            if (this.entity.paisnascimento.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.datachegadapais && !!this.entity.temporesidenciaestrangeiro && !!this.entity.classificacaoestrangeiro;
            } else if (this.entity.paisnascimento.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.municipionascimento &&
            !!this.entity.paisnascimento &&
            !!this.entity.paisnacionalidade &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaIdentificacaoDadosPessoais(): boolean {

        let validacoesClienteAna = true;

        if (this.clienteAna && !this.entity.semfiliacao) {
            validacoesClienteAna = !!this.entity.nomemae || !!this.entity.nomepai;
        }

        return (
            !!this.entity.sexo &&
            !!this.entity.estadocivil &&
            !!this.entity.raca &&
            !!this.entity.grauinstrucao &&
            validacoesClienteAna
        );
    }

    private validaDocumentacao(): boolean {
        return (
            this.validaDocumentacaoObrigatoria() &&
            this.validaCtps()
        );
    }

    private validaDocumentacaoObrigatoria(): boolean {
        return this.validaNis();
    }

    private validaNis(): boolean {
        if (!!this.entity.nis) {
            var ftap: string = '3298765432';
            var total: number = 0;
            var resto: number = 0;
            var strResto: string = '';
            var numPIS: string = this.entity.nis;

            if (!numPIS) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            }

            for (var i = 0; i <= 9; i++) {
                var resultado: number = parseInt(numPIS.slice(i, i + 1), 10) * parseInt(ftap.slice(i, i + 1), 10);
                total = total + resultado;
            }

            resto = (total % 11);

            if (resto !== 0) {
                resto = 11 - resto;
            }

            if (resto === 10 || resto === 11) {
                strResto = resto + '';
                resto = parseInt(strResto.slice(1, 2), 10);
            }

            if (resto !== parseInt(numPIS.slice(10, 11), 10)) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            } else {
                this.form.nis.$error = { 'nisInvalido': false };
                return true;
            }
        } else {
            this.form.nis.$error = {};
            return true;
        }
    }

    private validaCtps(): boolean {
        return !(!!this.entity.numeroctps && !(!!this.entity.ufctps));
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === (
                    tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado
                )
            );
        });
    }

    private validaOutrasInformacoes(): boolean {
        return (
            this.validaInformacoesGerais() &&
            this.validaFerias() &&
            this.validaRecebimento() &&
            this.validaEstagio()
        );
    }

    private validaInformacoesGerais(): boolean {
        return (
            !!this.entity.datavencimentoatestadomedico &&
            !!this.form.percentualadiantamento.$error &&
            this.validaPercentualAdiantamento(this.entity.percentualadiantamento)
        );
    }

    private validaPercentualAdiantamento(percentual: number): boolean {
        const PERCENTUAL_MIN = 0;
        const PERCENTUAL_MAX = 0.4;

        return percentual >= PERCENTUAL_MIN && percentual <= PERCENTUAL_MAX;
    }

    private validaFerias(): boolean {
        return !!this.entity.inicioperiodoaquisitivoferias;
    }

    private validaRecebimento(): boolean {
        return (
            this.entity.tiporecebimentobolsa &&
            this.validaDadosBancarios() &&
            this.validaDadosPix()
        );
    }

    private validaDadosBancarios(): boolean {
        if (
            !this.entity.semdadosbancarios &&
            (
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_CORRENTE ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_POUPANCA ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_SALARIO ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_AGENCIA_CONTA
            )
        ) {

            let tipoContaPixValida: boolean = true;

            if (this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_AGENCIA_CONTA && !(!!this.entity.tipocontapix)) {
                tipoContaPixValida = false;
            }

            return (
                !!this.entity.banco &&
                (!!this.entity.agencia || !!this.entity.agencianumero) &&
                !!this.entity.numerocontasalario &&
                !!this.entity.numerocontasalariodv &&
                tipoContaPixValida
            );
        }
        return true;
    }

    private validaDadosPix(): boolean {
        if (
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CPF ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_EMAIL ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CELULAR ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CHAVE_ALEATORIA
        ) {
            return !!this.entity.chavepix;
        }
        return true;
    }

    private validaEstagio(): boolean {
        return (
            !!this.entity.nivelestagio &&
            !!this.entity.instituicaoensino
        );
    }

    /**
     * Recebe o identificador do card e verifica se o mesmo deverá ser expandido para mostrar campos obrigatórios não preenchidos ou não.
     * Os métodos open e close chamados são referentes aos métodos do componente accordion
     * @param idCard Identificador do card, definido no html da página
     */
     private verificaExpansaoCard(idCard: string) {

        let card: any = document.getElementById(idCard);

        switch (idCard) {
            case 'card-dadosgerais':

                !this.validaDadosGerais() ? card.open() : card.close();
                break;

            case 'card-docsanexos':

                !this.validaDocumentosAnexos() ? card.open() : card.close();
                break;

            case 'card-endereco':

                !this.validaEndereco() ? card.open() : card.close();
                break;

            case 'card-dadoscontato':

                !this.validaDadosContato() ? card.open() : card.close();
                break;

            case 'card-dadospessoais':

                !this.validaDadosPessoais() ? card.open() : card.close();
                break;

            case 'card-documentacao':

                !this.validaDocumentacao() ? card.open() : card.close();
                break;

            case 'card-outrasinfos':

                !this.validaOutrasInformacoes() ? card.open() : card.close();
                break;

            default:
                card.close();
                break;
        }

        this.$scope.$applyAsync();

    }

    // fim - validações


    // início - observers
    private podeEnviarObserver(): void {
        this.$scope.$watch('mrh_admssstgr_frm_nw_cntrllr.toastController.podeEnviar', function (newValue: any, oldValue: any) {
            if (newValue === true) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser enviada',
                    body: 'Todos os dados obrigatórios já foram informados.'
                });
            }
        }.bind(this), true);
    }

    private podeEncaminharObserver(): void {
        this.$scope.$watch('mrh_admssstgr_frm_nw_cntrllr.toastController.podeEncaminhar', function (newValue: any, oldValue: any) {
            if (newValue === true) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser encaminhada',
                    body: 'Já é possível encaminhar a admissão para o futuro colaborador informar os dados pelo app.'
                });
            }
        }.bind(this), true);
    }

    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
                this.entityAnexo['solicitacao'] = args.entity.solicitacao;
                this.constructors.solicitacao = args.entity.solicitacao;

                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

                if (this.tiposdocumentosrequeridos.length <= 0) {

                    if (args.entity.situacao === this.situacaoAdmissaoEnum.ENVIADA) {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A admissão foi enviada com sucesso.'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A admissão foi salva com sucesso',
                            body: 'Já é possível que outros usuários consigam visualizar e realizar ações na admissão aberta.'
                        });
                    }
                    this.$state.go('meurh_admissaoestagiario_show', this.constructors);
                } else {
                    this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                        this.$scope.$apply();
                        if (
                            this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult) &&
                            !this.documentosValidados
                        ) {
                            this.documentosValidados = true;

                            if (args.entity.situacao === this.situacaoAdmissaoEnum.ENVIADA) {
                                this.NewToaster.pop({
                                    type: 'success',
                                    title: 'A admissão foi enviada com sucesso.'
                                });
                            } else {
                                this.NewToaster.pop({
                                    type: 'success',
                                    title: 'A admissão foi salva com sucesso',
                                    body: 'Já é possível que outros usuários consigam visualizar e realizar ações na admissão aberta.'
                                });
                            }

                            this.$state.go('meurh_admissaoestagiario_show', this.constructors);
                        }
                    });
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Desculpe, ocorreu um erro',
                    body: message
                });

                this.busy = false;
                this.$state.go('meurh_admissaoestagiario_show', this.constructors);
            }
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submit_error', (event: angular.IAngularEvent, args: any): void => {
            let mensagemErro = '';
            const tituloErro = args.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
                ? 'Ocorreu um erro ao salvar a admissão'
                : 'Ocorreu um erro ao enviar a admissão';

            try {
                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {

                        // caso seja um array
                        if (Array.isArray(args.response.data.errors.children)) {
                            args.response.data.errors.children.forEach((element: any) => {
                                mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                            });
                        } else {
                            // caso seja um objeto
                            for (const campo in args.response.data.errors.children) {
                                if (args.response.data.errors.children[campo]['errors']) {
                                    args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                        mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                                    });
                                }
                            }
                        }

                    } else if (!!args.response.data.message) {
                        mensagemErro = args.response.data.message;
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml'
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: message
                });
            } finally {
                this.busy = false;
            }
        });
    }

    private onTiposDocumentosListFinished(): void {
        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });
        });
    }
    // fim - observers


    // início - tratamentos de dados
    private alteraEntityEnviar(rascunho: boolean = false): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = rascunho ? -1 : 0;

        this.entitySave.gestores = [];
        if (this.entity.gestores) {
            this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
                if (gestor.gestor) {
                    this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
                } else {
                    this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
                }
            });
        }
        this.entitySave.gestores = JSON.stringify(this.entitySave.gestores);

        this.entitySave.vaga = this.entitySave.vaga
            ? this.entitySave.vaga.vaga
            : null;
        this.entitySave.estabelecimento = this.entitySave.estabelecimento
            ? this.entitySave.estabelecimento.estabelecimento
            : null;
        this.entitySave.nivelcargo = this.entitySave.nivelcargo
            ? this.entitySave.nivelcargo.nivelcargo
            : null;
        this.entitySave.departamento = this.entitySave.departamento
            ? this.entitySave.departamento.departamento
            : null;
        this.entitySave.lotacao = this.entitySave.lotacao
            ? this.entitySave.lotacao.lotacao
            : null;
        this.entitySave.ambiente = !!this.entitySave.ambiente
            ? this.entitySave.ambiente.ambiente
            : null;
        this.entitySave.condicaoambientetrabalho = this.entitySave.condicaoambientetrabalho
            ? this.entitySave.condicaoambientetrabalho.condicaoambientetrabalho
            : null;
        this.entitySave.grupodeponto = this.entitySave.grupodeponto
            ? this.entitySave.grupodeponto.grupo
            : null;
        this.entitySave.horario = this.entitySave.horario
            ? this.entitySave.horario.horario
            : null;
        this.entitySave.categoriatrabalhador = this.entitySave.categoriatrabalhador
            ? this.entitySave.categoriatrabalhador.categoriatrabalhador
            : null;
        this.entitySave.municipionascimento = this.entitySave.municipionascimento
            ? this.entitySave.municipionascimento.ibge
            : null;
        this.entitySave.paisnascimento = this.entitySave.paisnascimento
            ? this.entitySave.paisnascimento.pais
            : null;
        this.entitySave.paisnacionalidade = this.entitySave.paisnacionalidade
            ? this.entitySave.paisnacionalidade.pais
            : null;
        this.entitySave.raca = this.entitySave.raca
            ? this.entitySave.raca.raca
            : null;
        this.entitySave.tipologradouro = this.entitySave.tipologradouro
            ? this.entitySave.tipologradouro.tipologradouro
            : null;
        this.entitySave.municipio = this.entitySave.municipio
            ? this.entitySave.municipio.ibge
            : null;
        this.entitySave.pais = this.entitySave.pais
            ? this.entitySave.pais.pais
            : null;
        this.entitySave.instituicaoensino = this.entitySave.instituicaoensino
            ? this.entitySave.instituicaoensino.instituicao
            : null;
        this.entitySave.instituicaointegradora = this.entitySave.instituicaointegradora
            ? this.entitySave.instituicaointegradora.instituicao
            : null;

        this.entitySave.percentualadiantamento *= 100;

        this.entitySave.datainiciocontrato = this.entitySave.datainiciocontrato
            ? moment(this.entitySave.datainiciocontrato).format('YYYY-MM-DD')
            : null;
        this.entitySave.inicioperiodoaquisitivoferias = this.entitySave.inicioperiodoaquisitivoferias
            ? moment(this.entitySave.inicioperiodoaquisitivoferias).format('YYYY-MM-DD')
            : null;
        this.entitySave.dataexpedicaorg = this.entitySave.dataexpedicaorg
            ? moment(this.entitySave.dataexpedicaorg).format('YYYY-MM-DD')
            : null;
        this.entitySave.dataexpedicaoctps = this.entitySave.dataexpedicaoctps
            ? moment(this.entitySave.dataexpedicaoctps).format('YYYY-MM-DD')
            : null;
        this.entitySave.datafimcontrato = this.entitySave.datafimcontrato
            ? moment(this.entitySave.datafimcontrato).format('YYYY-MM-DD')
            : null;
        this.entitySave.datanascimento = this.entitySave.datanascimento
            ? moment(this.entitySave.datanascimento).format('YYYY-MM-DD')
            : null;
        this.entitySave.unidadebolsa = this.entitySave.unidadebolsa
            ? this.entitySave.unidadebolsa
            : null;
        this.entitySave.agencianumero = this.entitySave.agencia
            ? this.entitySave.agencia.agencianumero
            : this.entitySave.agencianumero;
        this.entitySave.agencia = null;
        if (
            this.entitySave.tiporecebimentobolsa === undefined ||
            this.entitySave.tiporecebimentobolsa === null
        ) {
            delete this.entitySave.tiporecebimentobolsa;
            delete this.entitySave.tipocontapix;
        } else if (this.entitySave.tiporecebimentobolsa === '64') {
            this.entitySave.tipocontapix = parseInt(this.entitySave.tipocontapix, 10);
        }
        if (this.entitySave.banco) {
            this.entitySave.banco = this.entitySave.banco.banco
                ? this.entitySave.banco.banco
                : null;
        }

        if (this.entitySave.beneficios) {
            this.entitySave.beneficios.forEach((element: any) => {
                element.lotacao = angular.copy(element.lotacao.lotacao);
                element.instituicao = element.instituicao
                    ? angular.copy(element.instituicao.instituicao)
                    : element.instituicao;
                if (element.usobeneficio === 2) {
                    element.cpfdependente = element.dependenteSelecionado.cpf;
                }
            });

            this.entitySave.beneficios = JSON.stringify(this.entitySave.beneficios);
        }

        if (this.entitySave.dependentes) {
            this.entitySave.dependentes.forEach((element: any) => {
                element.datainclusao = element.datainclusao
                    ? element.datainclusao
                    : null;
                element.databaixaimpostorenda = element.databaixaimpostorenda
                    ? element.databaixaimpostorenda
                    : null;
                element.vencimentoescolar = element.vencimentoescolar
                    ? element.vencimentoescolar
                    : null;
                element.vencimentovacinacao = element.vencimentovacinacao
                    ? element.vencimentovacinacao
                    : null;
                element.agencia = element.agencia
                    ? angular.copy(element.agencia.agencia)
                    : element.agencia;

                element.percentualpensaoalimenticia *= 100;
                element.percentualpensaoalimenticiafgts *= 100;
            });

            this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
        }

        if (this.entitySave.vts) {
            this.entitySave.vts.forEach((element: any) => {
                element.tarifaconcessionariavt = angular.copy(element.tarifaconcessionariavt.tarifaconcessionariavt);
            });

            this.entitySave.vts = JSON.stringify(this.entitySave.vts);
        }

        this.entitySave.temporesidenciaestrangeiro = !!this.entitySave.temporesidenciaestrangeiro ? parseInt(this.entitySave.temporesidenciaestrangeiro, 10) : null;
        this.entitySave.classificacaoestrangeiro = !!this.entitySave.classificacaoestrangeiro ? this.entitySave.classificacaoestrangeiro : null;
    }

    private getMensagensModal(tipoMensagem: 'rascunho' | 'enviar' | 'encaminhar' | 'descartar'): object {
        const customText = {
            rascunho: {
                title: 'Salvar admissão',
                confirmText: 'Você tem certeza que deseja salvar a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, salvar',
            },
            enviar: {
                title: 'Enviar admissão',
                subtitle: this.entity.statusprocesso === 0
                    ? 'Depois de enviada a admissão, não será possível editá-la.'
                    : 'Depois de enviada a admissão, não será possível editá-la e nem o futuro colaborador concluir o informe dos dados.',
                confirmText: 'Você tem certeza que deseja enviar a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar',
            },
            descartar: {
                title: 'Descartar criação',
                subtitle: 'Após o descarte não será mais possível reverter a ação.',
                confirmText: 'Você tem certeza que deseja descartar a criação da admissão?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, descartar',
            },
            encaminhar: {
                title: 'Encaminhar admissão',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você encaminhar a admissão, os campos que o futuro colaborador pode preencher através do aplicativo ficarão bloqueados para edição até o momento que o mesmo termine de preencher os dados, ou até que seja necessário retomar a admissão.'
                },
                confirmText: 'Você tem certeza que deseja encaminhar a admissão para ' + this.entity.nome + ' informar os dados pelo aplicativo?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, encaminhar'
            }
        };

        return customText[tipoMensagem];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': this.tipoSolicitacaoEnum.ADMISSOESESTAGIARIO };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
    // fim - tratamentos de dados
}
