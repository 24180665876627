import angular from 'angular';
import { IApontamentoImportacao } from '../models/apontamentosimportacao.model';
import { ResultadoApontamentosImportacaoEnum } from '../enums/resultado';

export class ApontamentosResultadoModalService {
    static $inject = ['$uibModal'];

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService
    ) {
    }

    open(entity: IApontamentoImportacao): angular.ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./apontamentosimportacao.resultadomodal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                function ApontamentosResultadoModal(
                    entity: IApontamentoImportacao,
                    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                ): void {
                    let selfModal = this;
                    selfModal.entity = entity;
                    selfModal.resultadoEnum = ResultadoApontamentosImportacaoEnum;

                    this.downloadLog = (): void => {
                        let blob = new Blob([entity.log], {
                            type: 'application/json;charset=utf-8;'
                        });
                        let downloadLink = document.createElement('a');
                        downloadLink.setAttribute('download', 'log_importacao_apontamentos.txt');
                        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
                        downloadLink.click();
                    };

                    this.submit = (): void => {
                        $uibModalInstance.close(selfModal.entity);
                    };

                    this.close = (): void => {
                        $uibModalInstance.dismiss('cancelar');
                    };
                }
            ],
            controllerAs: '$ctrl',
            windowClass: 'nsj-modal-lg-no-height',
            resolve: {
                entity: (): IApontamentoImportacao => {
                    return entity;
                }
            }
        });
    }
}
