import * as angular from 'angular';
import { MeurhTransferenciaColaboradoresShowFormController } from './transferenciacolaboradores.showform.controller';

export class MeurhTransferenciaColaboradoresShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhTransferenciaColaboradoresShowFormComponent';
	static controller = MeurhTransferenciaColaboradoresShowFormController;
	static template = require('!!html-loader!./transferenciacolaboradores.showform.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '='
	};
}
