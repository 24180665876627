import angular from 'angular';
import { ITarifaVT } from '../models/tarifavt.model';
import { MeurhSolicitacoesalteracoesvtstarifasService } from '../solicitacoesalteracoesvtstarifas.service';

export class MeurhSolicitacoesalteracoesvtstarifasFormService {
    static $inject = ['MeurhSolicitacoesalteracoesvtstarifasService', '$uibModal'];

    constructor(
        public entityService: MeurhSolicitacoesalteracoesvtstarifasService,
        public $uibModal: any
    ) { }

    open(parameters: any, subentity: any, entities: Array<ITarifaVT> | null = null): any {
        return this.$uibModal.open({
            template: require('html-loader!./solicitacoesalteracoesvtstarifas.modal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                'NewToaster',
                function MeurhSolicitacoesalteracoesvtstarifasModal(
                    entity: ITarifaVT,
                    $uibModalInstance: any,
                    NewToaster: any
                ): void {
                    let selfModal = this;
                    let ids: any = [];

                    if (entities) {
                        entities.forEach((entity: ITarifaVT): void => {
                            ids.push(entity.tarifaconcessionariavt.tarifaconcessionariavt);
                        });
                    }

                    selfModal.entitiesexcluidas = ids;
                    selfModal.entity = entity;
                    selfModal.submitted = false;
                    selfModal.action = entity.solicitacaoalteracaovttarifa ? 'update' : 'insert';
                    selfModal.params = parameters;

                    selfModal.submit = (): void => {
                        selfModal.submitted = true;

                        if (selfModal.form.$valid) {
                            $uibModalInstance.close(selfModal.entity);
                        } else {
                            NewToaster.pop({
                                type: 'error',
                                title: 'Alguns campos do formulário apresentam erros'
                            });
                        }
                    };

                    selfModal.close = (): void => {
                        $uibModalInstance.dismiss('fechar');
                    };
                }
            ],
            controllerAs: 'mrh_slctcsltrcsvtstrfs_frm_dflt_cntrllr',
            windowClass: '',
            resolve: {
                entity: (): any => {
                    return (parameters.identifier ? this.entityService.get(parameters.solicitacaoalteracaovt, parameters.identifier) : angular.copy(subentity));
                }
            }
        });
    }
}
