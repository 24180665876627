import angular from 'angular';
import { NewToasterController } from './new.toaster.controller';


export class NewToasterComponent implements angular.IComponentOptions {
  static selector = 'newToasterComponent';
  static controller = NewToasterController;
  static template = require('!!html-loader!./new.toaster.html');
  static transclude = true;
}
