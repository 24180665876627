import * as angular from 'angular';
import { MeurhVagasShowFormController } from './vagas.showform.controller';

export class MeurhVagasShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhVagasShowFormComponent';
	static controller = MeurhVagasShowFormController;
	static template = require('!!html-loader!./vagas.showform.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '='
	};
}
