import angular = require('angular');
import { LoadParams } from '../../../shared/models/loadparams.model';
import { ISolicitacaoAdmissao } from './models/solicitacaoadmissao.model';

export class MeurhSolicitacoesadmissoesService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public loading_deferred_filter: any = null;
    public entity: ISolicitacaoAdmissao;
    public entities: Array<ISolicitacaoAdmissao> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = '';
    public filters: any = {};
    public loadParams: LoadParams;
    public loading_deferred: any = null;
    public filter: string = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IScope,
        public $q: angular.IQService
    ) {
        this.loadParams = new LoadParams();
        this.reload = this.reload.bind(this);
    }

    reload(): Array<ISolicitacaoAdmissao> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any): Array<ISolicitacaoAdmissao> {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;

            Object.keys(this.filters).forEach((k: any): void => {
                delete this.filters[k];
            });

            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');

                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('meurh_solicitacoesadmissoes_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((response: any) => {
                reject(response);
            });
        });
    }

    load(): Array<ISolicitacaoAdmissao> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any): void => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }

                        this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                        this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                    }

                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }

                    this.loaded = true;
                    this.loadParams.to_load--;
                })
                .catch((error): void => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally((): void => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }

                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: string): ISolicitacaoAdmissao | null {
        for (let i in this.entities) {
            if (this.entities[i]['solicitacao'] === identifier) {
                return this.entities[i];
            }
        }

        return null;
    }

    delete($identifier: any, force: boolean): void {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['solicitacao'];
        }

        if ($identifier) {
            if (force || confirm('Tem certeza que deseja deletar?')) {
                this.loading_deferred = this.$q.defer();

                this.$http.delete(
                    this.nsjRouting.generate('meurh_solicitacoesadmissoes_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_deleted', {
                        entity: this.entity,
                        response: response
                    });
                })
                .catch((response) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_delete_error', {
                        entity: this.entity,
                        response: response
                    });
                });
            }
        }
    }

    _save(entity: ISolicitacaoAdmissao, autosave: boolean): angular.IHttpPromise<any> {
        let method, url;

        if (entity['solicitacao']) {
            method = 'PUT';
            url = this.nsjRouting.generate('meurh_solicitacoesadmissoes_put', { 'id': entity['solicitacao'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_solicitacoesadmissoes_create', angular.extend(this.constructors), true);
        }

        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }

        let data = angular.copy(entity);

        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }

        this.loading_deferred = this.$q.defer();

        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
        .finally((): void => {
            if (!autosave) {
                entity['$$__submitting'] = false;
            }
        });
    }

    save(entity: ISolicitacaoAdmissao, autosave: boolean = false): void {
        this._save(entity, autosave)
            .then((response: any): void => {
                if (response.data.solicitacao) {
                    entity['solicitacao'] = response.data.solicitacao;
                }

                entity['$$_saved'] = true;

                this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response): void => {
                this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    salvar(entity: ISolicitacaoAdmissao, autosave: boolean = false): angular.IHttpPromise<any> {
        return new Promise((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this._save(entity, autosave)
                .then((response: any) => {
                    if (response.data.solicitacao) {
                        entity['solicitacao'] = response.data.solicitacao;
                    }
                    entity['$$_saved'] = true;
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_submitted', {
                        entity: entity,
                        response: response,
                        autosave: autosave
                    });
                    resolve(response);
                })
                .catch((response) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_submit_error', {
                        entity: entity,
                        response: response,
                        autosave: autosave
                    });
                    reject(response);
                });
        });
    }

    get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http.get(
                this.nsjRouting.generate('meurh_solicitacoesadmissoes_get', { 'id': identifier }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response: any): void => {
                this.$rootScope.$broadcast('meurh_solicitacoesadmissoes_loaded', response.data);
                resolve(response.data);
            })
            .catch(function (response: any): void {
                reject(response);
            });
        });
    }
}
