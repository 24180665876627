import ng from 'angular';
import * as angular from 'angular';
import { AnyARecord } from 'dns';
import { getDefaultSettings } from 'http2';
import { MeurhAnexosRotasEnumService } from '../anexos-rotas-enum.service';
import { MeurhAnexosService } from '../anexos.service';
import { IAnexo } from '../models/anexo.model';
import { IFile } from '../models/file.model';
import { MeurhVisualizarmodalService } from '../visualizarmodal/anexos.visualizarmodal.service';
import { NsjAuthInstance } from '../../../../core/authentication/nsj-autenticacao';

export class MeurhAnexoFormController implements ng.IController {
	static $inject = [
		'$scope',
		'$element',
		'$attrs',
		'NewToaster',
		'nsjRouting',
		'$rootScope',
		'$location',
		'$timeout',
		'MeurhAnexosService',
		'MeurhVisualizarmodalService',
		'MeurhAnexosRotasEnumService'
	];

	public mostrarAlerta: boolean;
	public adiciona: boolean = false;
	public busy: boolean;
	public editanexo: boolean;
	public alterouanexo: boolean = false;
	public busyanexo: boolean = false;
	public new: boolean;
	public action: string;
	public entity: IAnexo;
	public form: angular.IFormController;
	public constructors: any;
	public mimeType: string;
	public entityAnexoLista: Array<IAnexo> = [];
	public maxFiles: any;
	public fileSelecionado: boolean = false;
	public fileShow: IFile;
	public fileShowBlob: Blob;
	public viewDocument: boolean = true;
	public validate: number; // conta quantos erros há em documentos anexos
	public validateResult: boolean;
	public tipodocumentocolaborador: string;
	public fileAux: IFile;
	public arquivoExistente: boolean = false;
	public isImage: boolean = false;
	public maxFileSize: number = 3; // em mb
	public fileMaxSizeExceded: boolean = false;

	// utilizando a primeira rota da lista como DEFAULT para não quebrar o componente dropzone
	public rotasAnexoEnum = this.MeurhAnexosRotasEnumService.rotasAnexoEnum();
	public prefixoRotaAnexo: string = this.rotasAnexoEnum['FALTAS'];

	constructor(
		public $scope: angular.IScope & { dropzoneConfig: any, dropzoneMethods: any },
		public $element: any,
		public $attrs: any,
		public NewToaster: any,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService & { access_token: string },
		public $location: angular.ILocationService,
		public $timeout: angular.ITimeoutService,
		public MeurhAnexosService: MeurhAnexosService,
		public MeurhVisualizarmodalService: MeurhVisualizarmodalService,
		public MeurhAnexosRotasEnumService: MeurhAnexosRotasEnumService
	) {
		this.maxFiles = this.maxFiles ? this.maxFiles.toString() : '1';
		$scope.dropzoneMethods = {};
	}

	$onInit(): void {
		this.fileSelecionado = false;
		this.viewDocument = false;
		this.entityAnexoLista = [];
		this.preencheAnexo();
		this.validadeDropzone();

		this.$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				if (this.form && Object.keys(this.form).length !== 0) {
					this.form.$setDirty();
				}

				this.setRota();
			}
		}, true);

		if (this.entity.casodeuso !== undefined) {
			this.setRota();
		}

		this.$scope.dropzoneConfig = {
			'options': {
				'url': this.nsjRouting.generate(this.prefixoRotaAnexo + 'create', {
					id: this.entity.solicitacao,
					...((this.entity.estabelecimento ? true : false) && {
						estabelecimento: this.entity.estabelecimento
					})
				}, true),
				'paramName': 'conteudo',
				'params': {},
				'autoProcessQueue': false,
				'timeout': 0,
				'maxFiles': this.maxFiles,
				'maxFilesize': this.maxFileSize,
				'canceledmultiple': (): void => {
					this.NewToaster.pop({
						type: 'error',
						title: 'Não foi possível anexar os arquivos. ',
						body: this.maxFiles === '1' ? 'Somente é possível anexar um arquivo por vez.' : `Somente é possível anexar até ${this.maxFiles} arquivos por vez.`
					});
				},
				'addRemoveLinks': false,
				'dictDefaultMessage': `<i class="dropzoneupload${this.editanexo} fas fa-upload fa-3x"></i><br><br><b class="dropzoneupload${this.editanexo}">Arraste aqui o arquivo ou clique para adicionar</b><b class="dropzoneblock${this.editanexo}">Só é possível adicionar no modo edição</b>`,
				'withoutThumbnail': '<div class="dz-withoutthumbnail"><span><i class="fas fa-file-pdf fa-3x"></i><br><br><b>Arquivo pdf carregado</b></span></div>',
				'dictCancelUpload': 'Cancelar envio',
				'dictMaxFilesExceeded': 'Você não pode enviar mais documentos',
				'dictFileTooBig': 'O tamanho do arquivo é grande ({{filesize}}Mb). Tamanho máximo aceito: {{maxFilesize}}Mb.',
				'headers': { 'Authorization': NsjAuthInstance.nsjAuth.token },
				'clickable': this.verificaEdit(),
				'acceptedFiles': 'application/pdf,image/*'
			},
			'eventHandlers': {
				'addedfile': (file: IFile): void => {
					if (!file.type.match(/^application\/pdf/) && !file.type.match(/^image\//)) {
						this.$timeout((): void => {
							this.NewToaster.pop({
								type: 'error',
								title: 'Arquivo não suportado',
								body: 'Apenas arquivos PDF e imagens são permitidos.'
							});
							this.$scope.dropzoneMethods.removeAllFiles();
						});
					} else {
						this.$timeout((): void => {
							this.viewDocument = this.isImage = !(file.type.indexOf('image') >= 0) ? false : true;
							this.fileSelecionado = true;
							this.fileShow = file;
							if (file.id === undefined && file.name !== undefined) {
								this.alterouanexo = true;
							}
							this.fileMaxSizeExceded = ((file.size / 1000000) > this.maxFileSize) ? true : false;
							if (this.fileMaxSizeExceded === true) {
								this.validate += 1;
							}
						});
					}
				},
				'sending': (file: IFile, xhr: XMLHttpRequest, formData: FormData): void => {
					formData.append('filename', file.name);
					formData.append('tipodocumentocolaborador[tipodocumentocolaborador]', this.tipodocumentocolaborador);
					formData.append('solicitacao', this.entity.solicitacao!);

					if (this.entity.solicitacaohistorico) {
						formData.append('solicitacaohistorico', this.entity.solicitacaohistorico);
					}
				},
				'queuecomplete': (files: Array<IFile>, response: any): void => {
					this.validateResult = true;
					setTimeout(() => this.$rootScope.$broadcast('meurh_todas_solicitacoes_documentos_complete', {}), 3000);
				}
			}
		};
	}

	validadeDropzone(): boolean {
		this.$scope.$on('meurh_todas_solicitacoes_submitted', () => {
			if (!this.arquivoExistente) {
				if (this.fileAux) {
					this.MeurhAnexosService.deleteAnexo(
						{
							id: this.entity.solicitacao,
							solicitacaodocumento: this.fileAux.id,
							rotaAnexo: this.prefixoRotaAnexo,
							solicitacaohistorico: this.entity.solicitacaohistorico,
							...((this.entity.estabelecimento ? true : false) && {
								estabelecimento: this.entity.estabelecimento
							})
						}
					).then(() => {
						this.$scope.dropzoneMethods.processQueue();
					});
				} else {
					this.$scope.dropzoneMethods.processQueue();
				}
			} else {
				this.validateResult = this.$scope.dropzoneMethods.files.length > 0 ? false : true;
			}
		});

		return this.validateResult;
	}

	setRota(): void {
		// setando a rota correta quando o entity já está preenchido
		this.prefixoRotaAnexo = this.rotasAnexoEnum[this.entity.casodeuso!];
	}

	previewDocumento(): void {
		switch (this.action) {
			case 'insert':
				if (this.viewDocument) {
					let entityModal: any = { base64: this.fileShow.dataURL };
					let modal = this.MeurhVisualizarmodalService.open(entityModal);
					// tslint:disable-next-line:no-empty
					modal.result.then((): void => { }).catch((): void => { });
				} else {
					let file = new Blob([this.fileShow], { type: 'application/pdf' });
					let entityModal: any = { file: file };
					let modal = this.MeurhVisualizarmodalService.open(entityModal);
					// tslint:disable-next-line:no-empty
					modal.result.then((): void => { }).catch((): void => { });
				}

				break;
			case 'show':
				let modal = this.MeurhVisualizarmodalService.open(this.fileAux, this.action);
				// tslint:disable-next-line:no-empty
				modal.result.then((): void => { }).catch((): void => { });
				break;
			case 'update':
				if (this.viewDocument) {
					let entityModal: any = { base64: this.fileShow.dataURL };
					let modal = this.MeurhVisualizarmodalService.open(entityModal);
					// tslint:disable-next-line:no-empty
					modal.result.then((): void => { }).catch((): void => { });
				} else {
					let file = new Blob([this.fileShow], { type: 'application/pdf' });
					let entityModal: any = { file: file };
					let modal = this.MeurhVisualizarmodalService.open(entityModal);
					// tslint:disable-next-line:no-empty
					modal.result.then((): void => { }).catch((): void => { });
				}

				break;
		}
	}

	removeDocumento(): void {
		switch (this.action) {
			case 'insert':
				if (confirm('Você tem certeza que deseja excluir o documento?')) {
					this.$scope.dropzoneMethods.removeAllFiles();
					this.fileSelecionado = false;
					this.alterouanexo = true;
					if (this.validate > 0 && this.fileMaxSizeExceded === true) {
						this.validate--;
					}
					this.fileMaxSizeExceded = false;
				}

				break;
			case 'show':
				break;
			case 'update':
				if (confirm('Você tem certeza que deseja excluir o documento?')) {
					this.$scope.dropzoneMethods.removeAllFiles();
					this.fileSelecionado = false;
					this.arquivoExistente = false;
					this.alterouanexo = true;
					if (this.validate > 0 && this.fileMaxSizeExceded === true) {
						this.validate--;
					}
					this.fileMaxSizeExceded = false;
				}
				break;
		}
	}

	downloadDocumento(): void {
		switch (this.action) {
			case 'insert':
				if (this.viewDocument) {
					let a: any = document.createElement('a');
					a.href = this.fileShow.dataURL;
					a.download = this.fileShow.name;
					a.click();
				}

				break;
			case 'show':
			case 'update':
				let a: any = document.createElement('a');
				a.href = this.fileAux.url;
				a.download = this.fileAux.name;
				a.click();

				break;
		}
	}

	printDocumento(): void {
		switch (this.action) {
			case 'insert':
				if (this.viewDocument) {
					let myImage = this.fileShow.dataURL;
					let myWindow = window.open('', 'Image');
					myWindow!.document.write('<img src="' + myImage + '">');
					setTimeout(() => myWindow!.print(), 500);
				}

				break;
			case 'show':
				if (this.isImage) {
					let myImage = this.fileShow.dataURL;
					let myWindow = window.open('', 'Image');
					myWindow!.document.write('<img src="' + myImage + '">');
					setTimeout(() => myWindow!.print(), 500);
				}

				break;
			case 'update':
				if (this.isImage) {
					let myImage = this.fileAux.url;
					let myWindow = window.open('', 'File');
					myWindow!.document.write('<img src="' + myImage + '">');
					setTimeout(() => myWindow!.print(), 500);
				}
				break;
		}
	}

	preencheAnexo(): void {
		switch (this.action) {
			case 'show':
			case 'update':
				if (this.entityAnexoLista.length === 0) {
					this.busyanexo = true;
					this.constructors = {
						id: this.entity.solicitacao,
						...((this.entity.estabelecimento ? true : false) && {
							estabelecimento: this.entity.estabelecimento
						})
					};

					if (this.entity.casodeuso !== undefined) {
						this.setRota();
					}

					this.constructors.rotaAnexo = this.prefixoRotaAnexo;

					if (this.constructors.id) {
						this.MeurhAnexosService.getAnexoList(this.constructors).then((response: any): void => {
							this.entityAnexoLista = response;
							this.busyanexo = this.entityAnexoLista.length === 0 ? false : true;

							if (this.entityAnexoLista.length > 0) {
								this.entityAnexoLista.forEach((element: IAnexo, index: any): void => {
									if (element.tipodocumentocolaborador!.tipodocumentocolaborador === this.tipodocumentocolaborador) {
										/*this.MeurhAnexosService.getInfoAnexo(this.constructors, element).then((responseAnexo: any): void => {
										*/
										this.MeurhAnexosService.getAnexoDownload(this.constructors, element)
											.then((responseDownload: any): void => {
												let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
												this.fileShowBlob = isChrome ? new Blob([responseDownload], { type: element['mime'] }) : new Blob([responseDownload]);
												this.viewDocument = true;
												this.arquivoExistente = true;
												this.fileSelecionado = true;

												let arrayBufferView = new Uint8Array(responseDownload);
												let blob = new Blob([arrayBufferView], { type: element['mime'] });
												let urlCreator = window.URL || window.webkitURL;
												let fileUrl = urlCreator.createObjectURL(blob);

												let parts = [blob, new ArrayBuffer(0)];

												this.fileAux = new File(parts, 'downloadFile', {
													lastModified: new Date(0).getTime(),
													type: element['mime']
												});

												this.fileAux.url = fileUrl;
												this.fileAux.id = element.solicitacaodocumento;

												// this.fileAux.id = responseAnexo.
												this.$scope.dropzoneMethods.addFile(this.fileAux);

												/*
												if (index === this.entityAnexoLista.length - 1) {
													this.busyanexo = false;
												}
												*/
											})
											.then(() => {
												this.busyanexo = false;
											})
											.catch((e: any): void => {
												console.log(e);
												this.busyanexo = false;
											});
										/*}).catch((e: any): void => {
											console.log(e);
										});*/
									}
								});
								// tira o loading caso o dropzone esteja sem arquivo
								if (!this.entityAnexoLista.some(element => element.tipodocumentocolaborador!.tipodocumentocolaborador === this.tipodocumentocolaborador)) {
									this.busyanexo = false;
								}
							}
						}).catch((e: any): void => {
							console.log(e);
						});
					}
				}

				break;
			default:
				break;
		}
	}

	verificaEdit(): boolean {
		if (this.editanexo === true) {
			return true;
		} else {
			return false;
		}
	}

	private preenchimentoAutomatico(file: IFile): void {
		this.busy = true;

		// convertendo imagem para base64 string e enviando para o construtor do service
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (): void => {
			// garantindo que o arquivo convertido esteja em formato string
			let fileBase64 = reader.result as string;

			// removendo inicio da string da base64 para o uso da API de leitura de documentos
			let base64string: Array<string> = fileBase64.split(',');

			// definindo o objeto que será enviado na requisição, onde a segunda posição da array
			// é a string desejada para a API
			let constructors = {
				'id': null,
				'documento': base64string[1],
			};
			this.MeurhAnexosService.leituraDocumentos(constructors)
				.catch((e) => {
					this.NewToaster.pop({
						type: 'error',
						title: `${e.data.message}`
					});
				})
				.finally(() => this.busy = false);
		};
		reader.onerror = (error: any): void => {
			throw new Error(`Error: ${error}`);
		};
	}
}
