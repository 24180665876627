declare var nsj;
import angular from 'angular';

export class DepartamentoPessoalService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = {};
    public constructors: any = {};
    public avisosFilter: any = {};
    public pAdmissao: any = {};
    public busy: any = false;

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any,
    ) { }

    carregaTotalizadores(estabelecimento: any) {
        this.constructors.estabelecimento = estabelecimento;
        this.constructors.grupoempresarial = nsj.globals.getInstance().get('grupoempresarial');
        this.constructors.tenant = nsj.globals.getInstance().get('tenant');
        return this.$http.get(this.nsjRouting.generate('dashboard_dp_statistics', this.constructors, true), {});
    }

    carregaDobraFerias(estabelecimento: any) {
        this.constructors.estabelecimento = estabelecimento;
        return this.$http.get(this.nsjRouting.generate('dashboard_dobra_ferias', this.constructors, true), {});
    }

    carregaAvisos(estabelecimento: any) {
        this.busy = true;
        this.constructors.estabelecimento = estabelecimento;
        let arrTipos: any = [];
        angular.forEach(this.$rootScope.configuracoes[3], (v, k) => {
            if (v.tipo !== 7) {
                arrTipos.push(v.tipo);
            }
        });
        this.constructors.tipos = arrTipos.toString();
        return this.$http({
            method: 'GET',
            url: this.nsjRouting.generate('dashboard_alerts', angular.extend(this.constructors, this.avisosFilter), true)
        });

    }

    carregaTrabalhadores(constructors: any) {
        var filter = {
            key: '',
            filterfield: 'all'
        };
        var offset = '';

        return this.$http({
            method: 'GET',
            url: this.nsjRouting.generate('persona_trabalhadores_index', angular.extend(constructors, { 'offset': offset, 'filter': filter }), true)
        });
    }

    AdmitirFuncionario(id_funcionario: any, usuario: any) {
        this.pAdmissao.id_funcionario = id_funcionario;
        this.pAdmissao.logged_user = usuario;
        return this.$http.put(
            this.nsjRouting.generate('dashboard_admitir_funcionario', angular.extend(this.constructors, this.pAdmissao), true),
            {}
        );
    }
}
