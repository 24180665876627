import * as angular from 'angular';

import { MeurhSolicitacoesalteracoesvtsIndexController } from './index/solicitacoesalteracoesvts.index.controller';
import { MeurhSolicitacoesalteracoesvtsNewController } from './new/solicitacoesalteracoesvts.new.controller';
import { MeurhSolicitacoesalteracoesvtsShowController } from './show/solicitacoesalteracoesvts.show.controller';
import { MeurhSolicitacoesalteracoesvtsEditController } from './edit/solicitacoesalteracoesvts.edit.controller';
import { MeurhSolicitacoesalteracoesvtsFormController } from './form/solicitacoesalteracoesvts.form.controller';
import { MeurhSolicitacoesalteracoesvtsShowFormComponent } from './showform/solicitacoesalteracoesvts.show.form.component';
import { MeurhSolicitacoesalteracoesvtsFormComponent } from './form/solicitacoesalteracoesvts.form.component';
import { MeurhSolicitacoesalteracoesvtsService } from './solicitacoesalteracoesvts.service';
import  { MeurhSolicitacoesalteracoesvtsListRouting } from './solicitacoesalteracoesvts.routes';

export const MeurhSolicitacoesalteracoesvtsModule = angular.module('MeurhSolicitacoesalteracoesvtsModule', ['ui.router.state', ])
    .controller('MeurhSolicitacoesalteracoesvtsIndexController', MeurhSolicitacoesalteracoesvtsIndexController)
    .controller('MeurhSolicitacoesalteracoesvtsNewController', MeurhSolicitacoesalteracoesvtsNewController)
    .controller('MeurhSolicitacoesalteracoesvtsShowController', MeurhSolicitacoesalteracoesvtsShowController)
    .controller('MeurhSolicitacoesalteracoesvtsEditController', MeurhSolicitacoesalteracoesvtsEditController)
    .controller('MeurhSolicitacoesalteracoesvtsFormController', MeurhSolicitacoesalteracoesvtsFormController)
    .component('meurhSolicitacoesalteracoesvtsShowFormComponent', MeurhSolicitacoesalteracoesvtsShowFormComponent)
    .component('meurhSolicitacoesalteracoesvtsFormComponent', MeurhSolicitacoesalteracoesvtsFormComponent)
    .service('MeurhSolicitacoesalteracoesvtsService', MeurhSolicitacoesalteracoesvtsService)
    .config(MeurhSolicitacoesalteracoesvtsListRouting)
    .name;
