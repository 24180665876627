import * as angular from 'angular';
import { MeurhCreditosdescontosPassodoisController } from './creditosdescontos.passodois.controller';


export class MeurhCreditosdescontosPassodoisComponent implements angular.IComponentOptions {
  static selector = 'meurhCreditosdescontosPassodoisComponent';
  static controller = MeurhCreditosdescontosPassodoisController;
  static template = require('!!html-loader!./creditosdescontos.passodois.html');
  static transclude = true;
  static bindings = {
    passo: '=',
    entity: '=',
    entityDadosCriacao: '=',
    entitiesCreditosDescontos: '=',
    mensagensPassos: '=',
    passoUmAtivo: '<',
    colaboradorSelecionado: '=',
  };
}
