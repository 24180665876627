import angular = require('angular');
import { LoadParams } from '../../shared/models/loadparams.model';
import { ICargo } from './models/cargo.model';

export class CargosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: Array<ICargo> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams;
    public loading_deferred: any = null;
    public filter: any = '';
    public loading_deferred_filter: any;

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService
    ) {
        this.loadParams = new LoadParams();
    }

    reload(): Array<ICargo> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any): Array<ICargo> | angular.IHttpPromise<any> {
        let filters = {};
        let search = '';

        if (typeof (filter) !== 'undefined' && filter !== null) {
            search = filter.search ? filter.search : '';

            for (let fil in filter.filters) {
                if (typeof (filter.filters[fil]) !== 'object') {
                    this.filters[fil] = filter.filters[fil];
                } else if (typeof (filter.filters[fil]) === 'object' && filter.filters[fil].hasOwnProperty('startDate')) {
                    this.filters[fil] = {
                        inicio: {
                            condition: 'gte',
                            value: filter.filters[fil].startDate
                        },
                        fim: {
                            condition: 'lte',
                            value: filter.filters[fil].endDate
                        }
                    };
                } else if (filter.filters[fil].constructor === Array) {
                    for (let pos in filter.filters[fil]) {
                        if (filter.filters[fil][pos] === undefined || (typeof (filter.filters[fil][pos]) === 'object' && filter.filters[fil][pos].value === undefined) || (typeof (filter.filters[fil][pos]) === 'object' && filter.filters[fil][pos].value === null)) {
                            delete filter.filters[fil][pos];
                        }
                    }

                    this.filters[fil] = filter.filters[fil];
                } else if (typeof (filter.filters[fil]) === 'object' && filter.filters[fil].value !== undefined && filter.filters[fil].value !== null) {
                    this.filters[fil] = filter.filters[fil];
                }
            }

            if (filter.filterLookup && filter.filterLookup.tiposatividadesfilter) {
                this.filters.tiposatividadesfilter = filter.filterLookup.tiposatividadesfilter.tipoatividade;
            }
        } else if (filter === null) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, '');
        }

        if (filter.lookup) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, search);
        } else {
            this.filter = search;
            this.filters = filters;

            return this.reload();
        }
    }

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('cargos_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
            .then((response: any): void => {
                resolve(response.data);
            })
            .catch((response: any): void => {
                reject(response);
            });
        });

    }

    load(): Array<ICargo> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any): void => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }

                        this.after['cargo'] = this.entities[this.entities.length - 1]['cargo'];
                        this.after['cargo'] = this.entities[this.entities.length - 1]['cargo'];
                    }

                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('cargos_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }

                    this.loaded = true;
                    this.loadParams.to_load--;
                })
                .catch((error: any): void => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally((): void => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }

                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: string): ICargo | null {
        for (let i in this.entities) {
            if (this.entities[i]['cargo'] === identifier) {
                return this.entities[i];
            }
        }

        return null;
    }

    _save(entity: ICargo, autosave: boolean): angular.IHttpPromise<any> {
        let method, url;

        if (entity['cargo']) {
            method = 'PUT';
            url = this.nsjRouting.generate('cargos_put', { 'id': entity['cargo'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('cargos_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }

        let data = angular.copy(entity);

        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }

        this.loading_deferred = this.$q.defer();

        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
        .finally((): void => {
            if (!autosave) {
                entity['$$__submitting'] = false;
            }
        });
    }

    save(entity: ICargo, autosave: boolean = false): void {
        this._save(entity, autosave)
            .then((response: any): void => {
                if (response.data.cargo) {
                    entity['cargo'] = response.data.cargo;
                }

                entity['$$_saved'] = true;

                this.$rootScope.$broadcast('cargos_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any): void => {
                this.$rootScope.$broadcast('cargos_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http.get(
                this.nsjRouting.generate('cargos_get', { 'id': identifier }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response: any): void => {
                this.$rootScope.$broadcast('cargos_loaded', response.data);
                resolve(response.data);
            })
            .catch((response: any): void => {
                reject(response);
            });
        });
    }
}
