export enum TipoImportacaoEnum {
	FOLGA = 'folga',
	FOLGAFIXA = 'folgafixa',
	AFASTAMENTO = 'afastamento',
	LOTACAO = 'lotacao',
	HORARIO = 'horario',
	FERIADO = 'feriado'
}

export const ColunasArquivoImportacao = {
	folga: {
		colaborador: 'identificador',
		data: 'data',
		descricao: 'descricao',
		estabelecimento: 'estabelecimento',
	},
	lotacao: {
		colaborador: 'identificador',
		data: 'data_inicial',
		dataFinal: 'data_final',
		lotacao: TipoImportacaoEnum.LOTACAO,
		empresa: 'empresa',
	},
	horario: {
		colaborador: 'identificador',
		data: 'data_inicial',
		dataFinal: 'data_final',
		horario: TipoImportacaoEnum.HORARIO,
		empresa: 'empresa',
	}
};
