import angular from 'angular';
import moment from 'moment';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class AtestadosAVencerIndexController {
    static $inject = ['$rootScope', '$scope', 'nsjRouting', '$http', 'NewToaster', 'RelatoriosService', 'AtestadosAVencerService', 'ListagemService', '$state', '$stateParams'];

    public lookupTrabalhadores: any = false;
    public primeiroLookupTrabalhadoresCarregado: any = false;
    public busy: boolean;
    public trabalhadores: any;
    public carregandoRelatorio: boolean;
    public listagemPermissao: Array<string> = ['relatorios_atestadosavencer'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;
    public filters: any = {
        trabalhador: null,
        departamento: null,
        situacao: '00',
        data: moment().format('YYYY-MM-DD'),
        tipo: '-1',
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
    };

    constructor(
        public $rootScope: any,
        public $scope: any,
        public nsjRouting: any,
        public $http: any,
        public NewToaster: any,
        public RelatoriosService: any,
        public AtestadosAVencerService: any,
        public ListagemService: ListagemService,
        public $state: any,
        public $stateParams: any,
    ) {
        if (!$rootScope.temPermissao('relatorios_atestadosavencer')) {
            $state.go('dp', $stateParams);
        }

        this.montaListagemVisibilidade();

        $rootScope.$on('Trabalhadores_loading', (event: any, args: any) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event: any, args: any) => {
            this.lookupTrabalhadores = false;
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.trabalhador;
        this.$rootScope.$broadcast('trabalhador_refresh', this.montaConstructorColaboradores());
        this.$rootScope.$broadcast('departamento_refresh', this.montaConstructorDepartamentos());
        this.carregarRelatorio();
    }

    montaConstructorColaboradores() {
        return { 'status': '00', 'tipo': this.filters.tipo, 'tipo_excluir': 1, 'empresa': this.filters.empresa, 'estabelecimento': this.filters.estabelecimento, 'forcagestor': this.$rootScope.modoGestorPermissao('relatorios_atestadosavencer') };
    }

    montaConstructorDepartamentos() {
        return { 'empresa': this.filters.empresa, 'estabelecimento': this.filters.estabelecimento };
    }

    loadMore() {
        this.AtestadosAVencerService.loadMore();
    }

    carregarRelatorio() {
        let construtor = this.montarConstrutor();
        if (construtor.data != null && construtor.data !== '') {
            this.AtestadosAVencerService.constructors = construtor;
            this.busy = true;
            this.trabalhadores = this.AtestadosAVencerService.reload();
            this.busy = false;
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'O campo data é obrigatório para a pesquisa'
            });
        }

    }

    montarConstrutor(relatorio: any = false) {
        let construtor = angular.copy(this.filters);
        construtor.relatorio = relatorio;
        if (construtor.trabalhador === null) {
            delete construtor.trabalhador;
        } else if (construtor.trabalhador && typeof construtor.trabalhador === 'object') {
            construtor.trabalhador = construtor.trabalhador.trabalhador;
        }
        if (construtor.departamento === null) {
            delete construtor.departamento;
        } else if (construtor.departamento && typeof construtor.departamento === 'object') {
            construtor.departamento = construtor.departamento.departamento;
        }
        return construtor;
    }

    abrirRelatorio() {
        this.carregandoRelatorio = true;
        var construtor = this.montarConstrutor(true);
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        this.RelatoriosService.abrirRelatorio('relatoriosliberados_atestadosavencer', construtor, callback);
    }

    isBusy() {
        return this.AtestadosAVencerService.loadParams.busy;
    }

    finished() {
        return this.AtestadosAVencerService.loadParams.finished;
    }
}
