import angular from 'angular';
import moment from 'moment';
import { IVtadicional } from '../models/vtadicional.model';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';

export class MeurhSolicitacoesvtsadicionaisShowFormController implements angular.IController {

	static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$stateParams', '$rootScope', 'TrabalhadoresService', 'BuscaAvancadaService'];

  public tipo: string;
  public posteriordatafinal: boolean = false;
  public lookupTrabalhadores: boolean = false;
  public primeiroLookupTrabalhadoresCarregado: boolean = false;
  public form: angular.IFormController;
  public entity: IVtadicional;
  public filter: any;

  // busca avançada: variáveis
  public trabalhadorinput: string = '';
  public entitiesTrabalhadores: Array<ITrabalhador> = this.trabalhadoresService.reload();
  public constructorSearchList: any = {};

  constructor (
    public $scope: angular.IScope,
    public $element: any,
    public $attrs: any,
    public NewToaster: any,
    public $stateParams: angular.ui.IStateParamsService,
    public $rootScope: angular.IRootScopeService & {
      modoGestorPermissao: (arg: string) => boolean,
      liberadoTela: (arg: string) => boolean,
      session: any
    },
    public trabalhadoresService: TrabalhadoresService,
    public BuscaAvancadaService: any) {

    this.tipo = this.$stateParams.tipo;

    $rootScope.$on('Trabalhadores_loading', (event: angular.IAngularEvent, args: any) => {
        if (!this.primeiroLookupTrabalhadoresCarregado) {
            this.lookupTrabalhadores = true;
            this.primeiroLookupTrabalhadoresCarregado = true;
        }
    });

    $rootScope.$on('Trabalhadores_loaded', (event: angular.IAngularEvent, args: any) => {
        this.lookupTrabalhadores = false;
    });

    $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
      if (newValue !== oldValue) {
        if (newValue.datafinal !== null && newValue.datainicial !== null && newValue.datafinal !== '' && newValue.datainicial !== '') {
          if (moment(newValue.datainicial).isAfter(moment(newValue.datafinal))) {
            this.form.datainicial.$setValidity('posteriordatafinal', false);
          } else {
            this.form.datainicial.$setValidity('posteriordatafinal', true);
          }
        }

        if (newValue.conteudo <= 0) {
          this.form.conteudo.$setValidity('valorzero', false);
        } else {
          this.form.conteudo.$setValidity('valorzero', true);
        }

        this.form.$setDirty();
      }
    }, true);

    // busca avançada
    $rootScope.$on('persona_trabalhadores_list_finished', (event, args) => {
        this.entitiesTrabalhadores = [...args];
    });
  }

  carregandoLookups(): boolean {
      return this.lookupTrabalhadores;
  }

  forcaGestor(): boolean {
    return this.$rootScope.modoGestorPermissao('criar_vtadicional');
  }

  // iNÍCIO - BUSCA AVANÇADA
  buscaAvancada (): void {
    this.trabalhadoresService.constructors.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
    this.trabalhadoresService.constructors.tipo = this.tipo;
    delete this.trabalhadoresService.constructors.trabalhador;

    let columns = ['Nome', 'Tipo'];
    let values = ['nome', 'tipo'];
    let enumValues = [];
    enumValues['nome'] = '';
    enumValues['tipo'] = 'lookupTipoTrabalhador';
    this.trabalhadoresService.constructors['status'] = '00';
    var modal = this.BuscaAvancadaService.open(self, this.entity, this.trabalhadoresService, columns, values, 'Colaboradores', enumValues, 'trabalhador', true, true, false, null);
    // tslint:disable-next-line:no-empty
    modal.result.then(() => {}).catch(() => {});
  }

  evento(event: angular.IAngularEvent & {detail: any}): void {
    this.constructorSearchList = {
        'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
        'status': '00',
        'forcagestor': this.$rootScope.modoGestorPermissao('registrar_apontamento')
    };
    let filter = {};
    this.filter.search = event.detail.value;
    this.trabalhadoresService.constructors = this.constructorSearchList;
    this.trabalhadoresService.filter = event.detail;
    this.trabalhadoresService.search(filter);
  }

  eventoTipo(): void {
      this.constructorSearchList = {
          'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
          'tipo': this.tipo,
          'status': '00'
      };
      this.trabalhadoresService.constructors = this.constructorSearchList;
      this.entitiesTrabalhadores = this.trabalhadoresService.reload();
  }

  retornaListagemCombo(): Array<ITrabalhador> {
      return this.entitiesTrabalhadores;
  }

  comboBusy(): boolean {
      return this.trabalhadoresService.loadParams.busy;
  }

  selecionaTrabalhador(event: angular.IAngularEvent & {detail: any}): void {
      if (event) {
          this.entity.trabalhador = event.detail.value;
      }
  }

  limpaTrabalhador(): void {
      delete this.entity.trabalhador;
  }
  // fIM - BUSCA AVANÇADA
}


