import * as angular from 'angular';
import { MeurhSolicitacoesvtsadicionaisFormController } from './solicitacoesvtsadicionais.form.controller';

export class MeurhSolicitacoesvtsadicionaisFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesvtsadicionaisFormComponent';
	static controller = MeurhSolicitacoesvtsadicionaisFormController;
	static template = require('!!html-loader!./solicitacoesvtsadicionais.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
