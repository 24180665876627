import ng from 'angular';

export class NewToaster {
  static $inject = ['$rootScope'];

  constructor(private $rootScope: ng.IRootScopeService) { }

  public pop(params: {
    title: string;
    body: string;
    type: 'success' | 'error' | 'warning' | 'info';
    timeout?: number;
    bodyOutputType?: 'trustedHtml' | 'template' | 'templateWithData';
    clickHandler?: (...params: any) => {};
  }): void {
    this.$rootScope.$broadcast('new-toaster-pop', params);
  }
}
