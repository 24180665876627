import * as angular from 'angular';
import { MeurhPromocoesPassodoisController } from './promocoes.passodois.controller';

export class MeurhPromocoesPassodoisComponent implements angular.IComponentOptions {
	static selector = 'meurhPromocoesPassodoisComponent';
	static controller = MeurhPromocoesPassodoisController;
	static template = require('!!html-loader!./promocoes.passodois.html');
	static transclude = true;
	static bindings = {
		passo: '=',
		entity: '=',
		mensagensPassos: '=',
	};
}
