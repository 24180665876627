import angular from 'angular';
import { MeurhArquivosService } from '../../arquivos.service';

export class MeurhVisualizarArquivoModalController {

    static $inject = [
        '$scope',
        '$sce',
        '$uibModalInstance',
        'entity',
        'fileType',
        'MeurhArquivosService'
    ];

    public busy = false;
    public fileURL = '';
    public mediaType = '';

    constructor(
        public $scope: angular.IScope,
        public $sce: angular.ISCEService,
        public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        public entity: string,
        public fileType: string,
        public MeurhArquivosService: MeurhArquivosService,
    ) {
        if (this.validateFileType(this.fileType)) {
            this.busy = true;
            this.MeurhArquivosService.getConteudo(this.entity)
            .then((args) => {
                const file = new Blob([args.data], { type: this.mediaType });
                this.fileURL = URL.createObjectURL(file);
                this.fileURL = this.$sce.trustAsResourceUrl(this.fileURL);
            })
            .finally(() => this.busy = false);
        }
    }

    validateFileType(type: string) {
        switch (type) {
            case 'jpg':
                this.mediaType = 'image/jpg';
                return true;
            case 'jpeg':
                this.mediaType = 'image/jpeg';
                return true;
            case 'png':
                this.mediaType = 'image/png';
                return true;
            case 'pdf':
                this.mediaType = 'application/pdf';
                return true;
            default:
                return false;
        }
    }

    close() {
        this.$uibModalInstance.close();
    }
}
