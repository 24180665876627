import angular from 'angular';
import moment from 'moment';
import Papa from 'papaparse';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { IEventoImportacao, IEvento } from '../models/escala.model';
import { IErroImportacao, IDetalhamentoErro } from '../models/escala.erros.model';
import { ILotacao } from '../../../Lotacao/models/lotacao.model';
import { IHorario } from '../../Solicitacoesadmissoes/models/horario.model';
import { IEstabelecimento } from '../../../Estabelecimentos/models/estabelecimento.model';
import { MeurhEscalaSchedulerService } from '../escala.scheduler.service';
import { TipoImportacaoEnum, ColunasArquivoImportacao } from '../enums/importacao';
import { SchedulerStatic } from '../../../../shared/components/dhtmlx-scheduler/dhtmlxscheduler';

export class MeurhEscalaImportacaoController {

    static $inject = [
        '$scope',
        '$rootScope',
        'NewToaster',
        'MeurhEscalaSchedulerService',
    ];

    public lotacoes: Array<ILotacao>; // listagem das lotacoes do sistema
    public horarios: Array<IHorario>; // listagem dos horarios do sistema
    public empresas: Array<any>; // listagem dos empresas do sistema
    public estabelecimentos: Array<IEstabelecimento>; // listagem dos estabelecimentos do sistema

    public busy: boolean;
    public estaImportando: boolean;
    public schedulerEventos: SchedulerStatic; // instancia do calendario
    public erroImportacao: IErroImportacao;
    public importacaoEnum = TipoImportacaoEnum;
    public arquivoImportacao: FileList = {} as FileList;

    public listaDeEventos: Array<IEvento>; // lista dos eventos no calendario
    public listaDeEventosBkp: Array<IEvento>;
    public colaboradores: Array<ITrabalhador>;
    public datasLimites: {
        dataInicial: Date,
        dataFinal: Date
    };

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean,
            session: any
        },
        public NewToaster: any,
        public MeurhEscalaSchedulerService: MeurhEscalaSchedulerService,
	) {}

    importarEventos(tipo: string = this.importacaoEnum.FOLGA) {
        let reader = new FileReader();
        if (!(this.arquivoImportacao[0].type === 'text/csv')) {
            this.NewToaster.pop({
                type: 'error',
                title: 'O tipo do arquivo deve ser .csv'
            });
        } else {
            reader.readAsText(this.arquivoImportacao[0]);
            reader.onload = () => {
                let fileContent = this.setCabecalho(<string>reader.result, tipo);
                const arr = <Papa.ParseResult<IEventoImportacao>> Papa.parse(<string>fileContent, {
                    header: true,
                    skipEmptyLines: true,
                    delimiter: ';',
                });
                let eventosImportados: Array<IEventoImportacao> = arr.data; // listagem das linhas do arquivo de importacao
                this.statusImportacao(false, true);
                this.erroImportacao = this.validaImportacao(eventosImportados, tipo);

                // adiciona os dados do arquivo na entity do calendario se forem validos
                if (!this.erroImportacao.erros.length) {
                    this.statusImportacao(true);
                    // adiciona os dados do arquivo no calendario
                    this.montaEventosCalendario(eventosImportados, tipo);
                    this.renderizaEventos();
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'O arquivo foi importado com sucesso!'
                    });
                } else if (this.erroImportacao.origemErro === 'arquivo') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: this.erroImportacao.erros[0].descricao,
                    });
                } else {
                    this.statusImportacao(true);
                    this.montaEventosCalendario(this.erroImportacao.validos!, tipo);
                    this.renderizaEventos();
                    this.NewToaster.pop({
                        type: 'warning',
                        title: this.erroImportacao.validos!.length ?
                        'O arquivo foi importado parcialmente.' :
                        'O arquivo foi importado sem dados válidos.'
                    });
                }
                this.$scope.$applyAsync();
            };
        }
        this.resetInputFileValue();
    }

    statusImportacao(status: boolean, atualizaEntities?: boolean) {
        if (atualizaEntities) {
            this.schedulerEventos.clearAll();
            angular.copy(this.listaDeEventosBkp, this.listaDeEventos);
            this.renderizaEventos();
            this.resetErrosImportacao();
        }
        this.estaImportando = status;
        this.MeurhEscalaSchedulerService.habilitaCriacaoIndividualCalendario(this.schedulerEventos, status);
    }

    private renderizaEventos(): void {
        this.schedulerEventos.parse(this.listaDeEventos);
    }

    private resetErrosImportacao(): void {
        this.erroImportacao = {
            tipoImportacao: '',
            origemErro: '',
            erros: [],
            validos: undefined,
            total: undefined
        };
    }

    private setCabecalho(content: string, tipo: string) {
        if (!content.split('\n')[0].split(';').includes(ColunasArquivoImportacao[tipo].colaborador)) {
            let cabecalho = '';
            Object.keys(ColunasArquivoImportacao[tipo]).forEach((coluna) => {
                cabecalho += ColunasArquivoImportacao[tipo][coluna] + ';';
            });
            return cabecalho.slice(0, -1) + '\n' + content;
        }
        return content;
    }

    private resetInputFileValue(): void {
        // reinicia o 'value' do input file para ser possivel inserir um arquivo com mesmo nome
        const arquivoFolga = document.getElementById('arquivoFolga') as HTMLInputElement | null;
        if (arquivoFolga !== null) {
            arquivoFolga.value = '';
        }
        const arquivoLotacao = document.getElementById('arquivoLotacao') as HTMLInputElement | null;
        if (arquivoLotacao !== null) {
            arquivoLotacao.value = '';
        }
        const arquivoHorario = document.getElementById('arquivoHorario') as HTMLInputElement | null;
        if (arquivoHorario !== null) {
            arquivoHorario.value = '';
        }
    }

    private montaEventosCalendario(
        eventosLista: Array<IEventoImportacao>,
        tipo: string
    ) {
        eventosLista.forEach((eventoEntity: IEventoImportacao) => {
            this.colaboradores.forEach((colaborador: ITrabalhador) => {
                this.adicionaEventoCalendario(eventoEntity, colaborador, tipo);
            });
        });
    }

    private adicionaEventoCalendario(
        eventoEntity: IEventoImportacao,
        colaborador: ITrabalhador,
        tipo: string
    ) {
        if (eventoEntity.identificador && eventoEntity.identificador === colaborador.trabalhador) {
            let colunaData = ColunasArquivoImportacao[tipo].data;
            let colunaDataFim = ColunasArquivoImportacao[tipo].dataFinal;
            let evento: Partial<IEvento> = {
                start_date: moment(eventoEntity[colunaData], 'DD/MM/YYYY').toDate(),
                end_date: tipo === this.importacaoEnum.FOLGA ? moment(eventoEntity[colunaData], 'DD/MM/YYYY').toDate() :
                colunaDataFim && eventoEntity[colunaDataFim] ? moment(eventoEntity[colunaDataFim], 'DD/MM/YYYY').toDate() : moment(this.datasLimites.dataFinal, 'DD/MM/YYYY').toDate(),
                start_date_only: colunaDataFim && !eventoEntity[colunaDataFim],
                colaborador,
                evento: tipo,
                descricao: eventoEntity['descricao'],
                lotacao: tipo === this.importacaoEnum.LOTACAO ? eventoEntity[tipo] : undefined,
                horario: tipo === this.importacaoEnum.HORARIO ? eventoEntity[tipo] : undefined,
                empresa: eventoEntity['empresa'],
                estabelecimento: eventoEntity['estabelecimento'],
            };
            this.MeurhEscalaSchedulerService.adicionaEventoCalendario(this.schedulerEventos, evento, this.listaDeEventos, true);
        }
    }

    /* VALIDACAO DA IMPORTACAO - INICIO */
    private validaImportacao(eventosImportados: Array<IEventoImportacao>, tipo: string): IErroImportacao {
        let listaErrosArquivo: Array<IDetalhamentoErro> = [];
        if (!eventosImportados.length) {
            listaErrosArquivo.push({descricao: 'O arquivo é inválido.'});
            return {tipoImportacao: tipo, origemErro: 'arquivo', erros: listaErrosArquivo};
        }
        Object.keys(ColunasArquivoImportacao[tipo]).forEach((coluna) => {
            let nomeColuna = ColunasArquivoImportacao[tipo][coluna];
            if (
                !eventosImportados[0].hasOwnProperty(nomeColuna) &&
                !this.colunaOpcional(tipo, coluna)
            ) {
                listaErrosArquivo.push({descricao: `Ausência do nome da coluna de ${nomeColuna}.`});
            }
        });
        if (listaErrosArquivo.length) {
            return {tipoImportacao: tipo, origemErro: 'arquivo', erros: listaErrosArquivo};
        }
        return this.verificaDadosImportacao(eventosImportados, tipo, listaErrosArquivo);
    }

    private colunaOpcional(tipo: string, coluna: string) {
        return (
            (tipo === TipoImportacaoEnum.FOLGA && coluna === 'estabelecimento') ||
            (tipo === TipoImportacaoEnum.FOLGA && coluna === 'descricao') ||
            (tipo === TipoImportacaoEnum.LOTACAO || tipo === TipoImportacaoEnum.HORARIO) && coluna === 'empresa'
        );
    }

    private verificaDadosImportacao(eventosImportados: Array<IEventoImportacao>, tipo: string, listaErrosArquivo: Array<IDetalhamentoErro>): IErroImportacao {
        let arraySemDuplicadas: Array<any>;
        let colunaData: string = ColunasArquivoImportacao[tipo].data;
        let colunaDataFim: string = ColunasArquivoImportacao[tipo].dataFinal ? ColunasArquivoImportacao[tipo].dataFinal : null;
        let eventosValidos = eventosImportados.filter((eventoImportado: IEventoImportacao, index: number) => {
            let listaErrosLinha: Array<string> = [];
            let eventoImportadoCopy: IEventoImportacao = angular.copy(eventoImportado);
            if (index === 0) {
                arraySemDuplicadas = Array.from(new Map(eventosImportados.map(v => [JSON.stringify([v.identificador, moment(v[colunaData], 'DD/MM/YYYY')]), v])).values());
            }
            if (this.dataInvalida(eventoImportado, colunaData)) {
                listaErrosLinha.push('A data é inválida.');
            }
            if (colunaDataFim && this.dataInvalida(eventoImportado, colunaDataFim, true)) {
                listaErrosLinha.push('A data fim é inválida.');
            }

            let empresa = this.existeEmpresa(eventoImportado);
            if (!empresa && (tipo === this.importacaoEnum.LOTACAO || tipo === this.importacaoEnum.HORARIO)) {
                if (eventoImportado['empresa']) {
                    listaErrosLinha.push('A empresa não foi encontrada.');
                }
            }

            let estabelecimento = this.existeEstabelecimento(eventoImportado);
            if (!estabelecimento && tipo === this.importacaoEnum.FOLGA) {
                if (eventoImportado['estabelecimento']) {
                    listaErrosLinha.push('O estabelecimento não foi encontrado.');
                }
            }

            let colaboradorEncontrado = this.existeColaborador(eventoImportado, empresa?.empresa);
            if (!colaboradorEncontrado) {
                listaErrosLinha.push('O colaborador não foi encontrado.');
            } else {
                empresa = colaboradorEncontrado.empresaobj;
                estabelecimento = colaboradorEncontrado.estabelecimentoobj;
                if (this.datasColidemComExistentes(eventoImportado, tipo, colunaData, colunaDataFim)) {
                    if (tipo !== this.importacaoEnum.FOLGA) {
                        listaErrosLinha.push('O intervalo de datas colide com uma data já existente.');
                    } else {
                        listaErrosLinha.push('A data colide com uma folga ou afastamento já existente.');
                    }
                }
                if (this.datasDuplicadas(colaboradorEncontrado, eventoImportado, arraySemDuplicadas, colunaData)) {
                    listaErrosLinha.push('Data repetida para um mesmo colaborador.');
                }
                if (this.dataForaDoIntervalo(colaboradorEncontrado, eventoImportado, colunaData, colunaDataFim, true)) {
                    listaErrosLinha.push('A data antecede a admissão do colaborador.');
                }
                if (this.dataForaDoIntervalo(colaboradorEncontrado, eventoImportado, colunaData, colunaDataFim)) {
                    listaErrosLinha.push('A data sucede a rescisão do colaborador.');
                }
                if (colunaDataFim && this.intervaloInvalido(eventoImportado, colunaData, colunaDataFim)) {
                    listaErrosLinha.push('A data fim precede a data de início.');
                }
                if (colunaDataFim && this.datasDaImportacaoColidem(eventoImportado, arraySemDuplicadas, colunaData, colunaDataFim)) {
                    listaErrosLinha.push('Datas colidem para um mesmo colaborador.');
                }
            }

            let solicitacao: ILotacao | IHorario | undefined;
            if (tipo === this.importacaoEnum.LOTACAO) {
                solicitacao = this.existeSolicitacao(tipo, eventoImportado, estabelecimento?.estabelecimento);
                if (!solicitacao) {
                    listaErrosLinha.push('A lotação não foi encontrada.');
                }
            } else if (tipo === this.importacaoEnum.HORARIO) {
                solicitacao = this.existeSolicitacao(tipo, eventoImportado, empresa?.empresa);
                if (!solicitacao) {
                    listaErrosLinha.push('O horário não foi encontrado.');
                }
            }

            if (listaErrosLinha.length) {
                listaErrosLinha.forEach((erro) => {
                    listaErrosArquivo.push({
                        linha: (index + 1).toString(),
                        colaborador: colaboradorEncontrado,
                        data: eventoImportado[colunaData],
                        dataFim: colunaDataFim ? eventoImportado[colunaDataFim] : null,
                        solicitacao,
                        empresa,
                        estabelecimento,
                        importacao: eventoImportadoCopy,
                        descricao: erro
                    });
                });
                return false;
            }
            return true;
        });
        return {tipoImportacao: tipo, origemErro: listaErrosArquivo.length ? 'dados' : '', erros: listaErrosArquivo, validos: eventosValidos, total: eventosImportados.length};
    }

    private existeColaborador(eventoImportado: IEventoImportacao, empresa?: string) {
        if (eventoImportado.identificador === '') {
            return;
        }
        return this.MeurhEscalaSchedulerService.colaboradoresCalendario.find((colaborador) => {
            if (
                (!empresa || colaborador.colaboradorObj.empresaobj.empresa === empresa) &&
                eventoImportado.identificador === colaborador.colaboradorObj.codigo ||
                eventoImportado.identificador === colaborador.colaboradorObj.cpf ||
                eventoImportado.identificador === colaborador.colaboradorObj.trabalhador
            ) {
                eventoImportado.identificador = colaborador.colaboradorObj.trabalhador;
                return true;
            }
            return false;
        })?.colaboradorObj;
    }

    private existeSolicitacao(tipo: string, eventoImportado: IEventoImportacao, empresaEstabelecimento?: string) {
        if (tipo === this.importacaoEnum.LOTACAO) {
            return this.lotacoes.find((lotacao) => {
                if (
                    (empresaEstabelecimento && lotacao.estabelecimento === empresaEstabelecimento) &&
                    (lotacao.lotacao === eventoImportado.lotacao ||
                    lotacao.codigo === eventoImportado.lotacao)
                ) {
                    eventoImportado.lotacao = lotacao.lotacao;
                    return true;
                }
                return false;
            });
        } else if (tipo === this.importacaoEnum.HORARIO) {
            return this.horarios.find((horario) => {
                if (
                    (empresaEstabelecimento && horario.empresa === empresaEstabelecimento) &&
                    (horario.horario === eventoImportado.horario ||
                    horario.codigo === eventoImportado.horario)
                ) {
                    eventoImportado.horario = horario.horario;
                    return true;
                }
                return false;
            });
        }
        return;
    }

    private existeEmpresa(eventoImportado: IEventoImportacao) {
        return this.empresas.find((empresa) => (
            (empresa.empresa === eventoImportado.empresa) ||
            (empresa.codigo === eventoImportado.empresa) ||
            ((empresa.raizcnpj + empresa.ordemcnpj) === eventoImportado.empresa)
        ));
    }

    private existeEstabelecimento(eventoImportado: IEventoImportacao) {
        return this.estabelecimentos.find((estabelecimento) => (
            (estabelecimento.estabelecimento === eventoImportado.estabelecimento) ||
            (estabelecimento.codigo === eventoImportado.estabelecimento) ||
            ((estabelecimento.raizcnpj + estabelecimento.ordemcnpj) === eventoImportado.estabelecimento)));
    }

    private dataInvalida(eventoImportado: IEventoImportacao, colunaData: string, aceitaDataVazia: boolean = false): boolean {
        if (aceitaDataVazia && !eventoImportado[colunaData]) {
            return false;
        }
        return eventoImportado[colunaData] === '' || !(moment(eventoImportado[colunaData], 'DD/MM/YYYY', true).isValid() || moment(eventoImportado[colunaData], 'DD-MM-YYYY', true).isValid());
    }

    private intervaloInvalido(eventoImportado: IEventoImportacao, colunaData: string, colunaDataFim: string): boolean {
        if (eventoImportado[colunaDataFim]) {
            return moment(eventoImportado[colunaData], 'DD/MM/YYYY').isAfter(moment(eventoImportado[colunaDataFim], 'DD/MM/YYYY'));
        }
        return false;
    }

    private dataForaDoIntervalo(colaborador: ITrabalhador, eventoImportado: IEventoImportacao, colunaData: string, colunaDataFim: string, verificaAdmissao: boolean = false): boolean {
        let dataInicioImportada = moment(eventoImportado[colunaData], 'DD/MM/YYYY');
        let dataFimImportada = eventoImportado[colunaDataFim] ? moment(eventoImportado[colunaDataFim], 'DD/MM/YYYY') : null;
        if (verificaAdmissao) {
            if (moment(colaborador.dataadmissao).isAfter(dataInicioImportada)) {
                return true;
            }
            return false;
        }
        if (colaborador.datarescisao &&
            ((dataFimImportada && moment(colaborador.datarescisao).isSameOrBefore(dataFimImportada)) ||
            (!dataFimImportada && moment(colaborador.datarescisao).isSameOrBefore(dataInicioImportada)))
        ) {
            return true;
        }
        return false;
    }

    private datasDaImportacaoColidem(eventoImportado: IEventoImportacao, arraySemDuplicadas: Array<any>, colunaData: string, colunaDataFim: string): boolean {
        let dataInicioImportada = moment(eventoImportado[colunaData], 'DD/MM/YYYY');
        let dataFimImportada = eventoImportado[colunaDataFim] ? moment(eventoImportado[colunaDataFim], 'DD/MM/YYYY') : null;
        return arraySemDuplicadas.some((eventoPlanilha) => {
            if (eventoImportado.identificador === eventoPlanilha.identificador && eventoImportado[colunaData] !== eventoPlanilha[colunaData]) {
                let planilhaDataInicio = moment(eventoPlanilha[colunaData], 'DD/MM/YYYY');
                let planilhaDataFim = eventoPlanilha[colunaDataFim] ? moment(eventoPlanilha[colunaDataFim], 'DD/MM/YYYY') : null;
                if (planilhaDataInicio && planilhaDataFim) {
                    if ((dataInicioImportada && dataFimImportada) && (dataInicioImportada.isAfter(planilhaDataFim) || dataFimImportada.isBefore(planilhaDataInicio))) {
                        return false;
                    } else if (!dataFimImportada && dataInicioImportada.isAfter(planilhaDataFim)) {
                        return false;
                    }
                } else {
                    if ((dataInicioImportada && dataFimImportada) && dataFimImportada.isBefore(planilhaDataInicio)) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        });
    }

    private datasColidemComExistentes(eventoImportado: IEventoImportacao, tipo: string, colunaData: string, colunaDataFim?: string): boolean {
        let dataInicioImportada = moment(eventoImportado[colunaData], 'DD/MM/YYYY');
        if (tipo !== this.importacaoEnum.FOLGA) {
            let dataFimImportada = colunaDataFim && eventoImportado[colunaDataFim] ? moment(eventoImportado[colunaDataFim], 'DD/MM/YYYY') : null;
            return this.schedulerEventos.getEvents().some((eventoExistente: IEvento) => {
                if (eventoImportado.identificador === eventoExistente.colaborador.trabalhador && eventoExistente.evento === tipo) {
                    let existenteDataInicio = moment(eventoExistente.start_date, 'DD/MM/YYYY');
                    let existenteDataFim = moment(eventoExistente.end_date, 'DD/MM/YYYY');
                    if (!eventoExistente.start_date_only) {
                        if (dataInicioImportada.isAfter(existenteDataFim)) {
                            return false;
                        }
                    } else {
                        if ((dataInicioImportada && dataFimImportada) && dataInicioImportada.isSameOrAfter(existenteDataInicio)) {
                            return false;
                        } else if (!dataFimImportada && dataInicioImportada.isAfter(existenteDataInicio)) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            });
        }
        return this.schedulerEventos.getEvents().some((event: IEvento) => {
            if (eventoImportado.identificador === event.colaborador.trabalhador &&
                (event.evento === tipo || (
                    tipo === this.importacaoEnum.FOLGA &&
                    (event.evento === this.importacaoEnum.AFASTAMENTO || event.evento === this.importacaoEnum.FOLGAFIXA)
                )) &&
                dataInicioImportada.isBetween(moment(event.start_date, 'DD/MM/YYYY'), moment(event.end_date, 'DD/MM/YYYY'), 'days', '[]')
            ) {
                return true;
            }
            return false;
        });
    }

    private datasDuplicadas(colaborador: ITrabalhador, eventoImportado: IEventoImportacao, arraySemDuplicadas: Array<any>, colunaData: string): boolean {
        let existeDuplicado: boolean = false;
        arraySemDuplicadas.forEach((eventoUnico) => {
            if ((eventoUnico.identificador === colaborador.codigo ||
                eventoUnico.identificador === colaborador.cpf ||
                eventoUnico.identificador === colaborador.trabalhador) &&
                eventoUnico[colunaData] === eventoImportado[colunaData]
            ) {
                !eventoUnico.encontrado ? eventoUnico.encontrado = true : existeDuplicado = true;
            }
        });
        return existeDuplicado;
    }
    /* VALIDACAO DA IMPORTACAO - FIM */
}
