import angular from 'angular';

export const ContrachequesListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('relatorios_contracheques', {
                url: '/relatorios/contracheques/?trabalhador?estabelecimento',
                template: require('html-loader!./index/contracheques.index.html'),
                controller: 'ContrachequesIndexController',
                controllerAs: 'cntrchqs_cntrllr'
            });
    }
];
