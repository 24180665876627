import angular from 'angular';

export const MeurhSolicitacoesafastamentosretornoRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('meurh_solicitacoesafastamentosretorno_show', {
                url: '/solicitacoes/afastamentosretorno/:solicitacao',
                template: require('html-loader!./show/solicitacoesafastamentosretorno.show.html'),
                controller: 'MeurhSolicitacoesafastamentosretornoShowController',
                controllerAs: 'mrh_slctcsafstmntsrtrn_shw_cntrllr'
            });
    }
];
