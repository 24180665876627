import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { CargosService } from '../../../modules/Cargos/cargos.service';

export class AvisoFeriasFormController {
    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'nsjRouting', '$log', '$document', 'CargosService', 'ControleFeriasService', 'AvisoFeriasService', 'moment', 'NewToaster', '$filter', 'RelatoriosService'];

    public filterData: any = {};
    public search: any = this.ControleFeriasService.search;
    public entities: any = [];
    public minPrazoAvisoFerias: any;
    public service: any = this.ControleFeriasService;
    public minPrazoAvisoFeriasMonth: any;
    public loadMore: any = this.ControleFeriasService.loadMore;
    public menuOrdenacaoAtiva: string = 'nome';
    public lookupTrabalhadores: boolean = false;
    public lookupCargos: boolean = false;
    public configAdiantamento: any = this.$rootScope.configuracoes['ADIANTAMENTO_13_FERIAS'];
    public configMarcarFeriasAntes: any = this.$rootScope.configuracoes['MARCAR_FERIAS_ANTES_PERIODO'];
    public dataAtual: string;
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public primeiroLookupCargosCarregado: boolean = false;
    public ocultarsolicitacoes: boolean = false;
    public tooltip: string = '';
    public tooltipAdicionar: string = '';
    public date: string;
    public feriados: any = this.ControleFeriasService.getFeriados();
    public alertMessages = [];
    public saveIsLoad: boolean = false;
    public alert: boolean = false;
    public daysOpt = _.range(5, 31);
    public daysAbonoOpt = _.range(0, 11);
    public formData: any;
    public tipoInicial: any;
    public carregandoRelatorio: boolean;

    constructor(
        public $rootScope: angular.IRootScopeService & { temPermissao: any, configuracoes: any, session: any, modoGestorPermissao: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public nsjRouting: any,
        public $log: any,
        public $document: any,
        public CargosService: CargosService,
        public ControleFeriasService: any,
        public AvisoFeriasService: any,
        public moment: any,
        public NewToaster: any,
        public $filter: any,
        public RelatoriosService: any
    ) {
        if (!$rootScope.temPermissao('visualizar_ferias', 'criar_ferias')) {
            $state.go('dp', $stateParams);
        }

        $rootScope.$on('Trabalhadores_loading', (event, args) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event, args) => {
            this.lookupTrabalhadores = false;
        });

        $rootScope.$on('Cargos_loading', (event, args) => {
            if (!this.primeiroLookupCargosCarregado) {
                this.lookupCargos = true;
                this.primeiroLookupCargosCarregado = true;
            }
        });

        $rootScope.$on('Cargos_loaded', (event, args) => {
            this.lookupCargos = false;
        });

        if ($stateParams.tipo) {
            this.filterData.tipo = $stateParams.tipo;
            this.tipoInicial = $stateParams.tipo;
        } else {
            this.filterData.tipo = '-1';
            this.tipoInicial = '-1';
        }

        $rootScope.$on('estabelecimento_refreshed', (event, args) => {
            this.filterData.estabelecimento = null;
            if (args.length === 1) {
                this.filterData.estabelecimento = args[0];
            }
            this.filter();
        });

        $rootScope.$on('cargo_refreshed', (event, args) => {
            if (args.length === 1) {
                this.filterData.cargo = args[0];
            }
        });

        $rootScope.$on('avisoferias_submitted', (entity, response) => {
            if (response.response.data.solicitacao) {
                this.entities[response.iTrabalhador].ferias[response.period].solicitacao = this.formData[response.iTrabalhador].ferias[response.period].solicitacao = response.response.data.solicitacao;
            }
            this.saveIsLoad = false;
            if (this.alert) {
                this.showAlert('success');
                this.alert = false;
            }
        });

        $rootScope.$on('avisoferias_submit_error', (entity, response, iTrabalhador) => {
            this.saveIsLoad = false;
            NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao salvar a solicitação de férias!'
            });
        });

        this.date = moment(moment().hour(8).minute(0).second(0).toDate()).format('DD/MM/YYYY');
        this.dataAtual = moment().format('YYYY-MM-DD');
        this.minPrazoAvisoFeriasMonth = moment({ M: moment().add(30, 'days').month(), Y: moment().add(30, 'days').year() });
        this.minPrazoAvisoFerias = moment().add(30, 'days');

        ControleFeriasService.constructors = [];

        ControleFeriasService.constructors.order = {
            'nome': 'ASC'
        };

        this.alertMessages['maior5'] = 'Cada período de férias deve ter mais do que 5 dias.';
        this.alertMessages['min14'] = 'Deve existir um período com no mínimo 14 dias.';
        this.alertMessages['saldoDeveSerVazio'] = 'Ainda há saldo de férias, replaneje a duração de um ou mais períodos até que não haja mais saldo disponível.';
        this.alertMessages['saldoNegativo'] = 'O saldo não pode ser negativo.';
        this.alertMessages['folga'] = 'O gozo de férias não pode ter início no período de dois dias que antecede o dia de repouso semanal remunerado.';
        this.alertMessages['feriado'] = 'O gozo de férias não pode ter início no período de dois dias que antecede um feriado.';
        this.alertMessages['success'] = 'Salvo com sucesso.';
        this.alertMessages['erroInicioGozoAntesDoFimPeriodoAquisitivo'] = 'A data do Início das Férias deve ser após o Fim do Período Aquisitivo.';
        this.alertMessages['abonoMaior'] = 'O total de dias vendidos não deve ser maior que 10.';
        this.alertMessages['feriasMaior'] = 'A soma dos dias vendidos com os dias de férias não pode ser maior que o saldo de férias do funcionário.';
        this.alertMessages['dataAvisoInvalida'] = 'A data de aviso deve ser anterior a data de início de gozo.';
        this.alertMessages['dataAvisoVazia'] = 'A data de aviso é obrigatória.';
        this.alertMessages['dataVazia'] = 'A data do Início das Férias deve ser preenchida.';
        this.alertMessages['possuiPeriodosSobrepostos'] = 'Existem períodos de férias sobrepostos. ';
    }

    $onInit() {
        this.onDeleteSuccess();
        this.onDeleteError();
    }

    isBusy() {
        return this.ControleFeriasService.loadParams.busy;
    }

    isBusySave() {
        return this.ControleFeriasService.busySave;
    }

    refresh() {
        if (this.filterData.estabelecimento) {
            this.$rootScope.$broadcast('cargo_refresh', { estabelecimento: this.filterData.estabelecimento.estabelecimento });
            this.filterData.cargo = null;
        }
    }

    carregandoLookups() {
        return this.lookupTrabalhadores || this.lookupCargos;
    }

    filter(clean: any = null) {
        this.filterData.exibirsolicitacoes = !this.ocultarsolicitacoes;
        if (typeof this.$rootScope.session.entities.estabelecimento !== undefined) {
            this.filterData.estabelecimento = this.$rootScope.session.entities.estabelecimento;

            var aux = angular.copy(this.filterData);

            aux.order = this.ControleFeriasService.constructors.order ? this.ControleFeriasService.constructors.order : null;

            if (this.filterData.estabelecimento) {
                aux.estabelecimento = aux.estabelecimento.estabelecimento;

                if (this.filterData.cargo && !clean) {
                    aux.cargo = aux.cargo.cargo;
                } else {
                    aux.cargo = null;
                }

                if (this.filterData.trabalhador && !clean) {
                    aux.trabalhador = aux.trabalhador.trabalhador;
                } else {
                    aux.trabalhador = null;
                }
                this.ControleFeriasService.constructors = aux;
                this.ControleFeriasService.constructors.calendarioferias = this.$stateParams.calendarioferias;
                this.entities = this.ControleFeriasService.reload();
            }
            this.formData = [];
        }
    }

    ordenar(param: any) {
        this.menuOrdenacaoAtiva = param;

        this.ControleFeriasService.constructors.order = {
            [param]: 'ASC'
        };
        this.entities = this.ControleFeriasService.reload();
    }

    abrirRelatorio() {
        this.carregandoRelatorio = true;
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        var constructorAux = angular.copy(this.ControleFeriasService.constructors);
        constructorAux.offset = {};
        this.RelatoriosService.abrirRelatorio('meurh_solicitacoesferias_pdf', constructorAux, callback);
    }

    showAlert(validate: any) {
        switch (validate) {
            case 'maior5':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['maior5']
                });
                break;
            case 'min14':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['min14']
                });
                break;
            case 'saldoDeveSerVazio':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['saldoDeveSerVazio']
                });
                break;
            case 'folga':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['folga']
                });
                break;
            case 'feriado':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['feriado']
                });
                break;
            case 'erroInicioGozoAntesDoFimPeriodoAquisitivo':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['erroInicioGozoAntesDoFimPeriodoAquisitivo']
                });
                break;
            case 'success':
                this.NewToaster.pop({
                    type: 'success',
                    title: this.alertMessages['success']
                });
                break;
            case 'abonoMaior':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['abonoMaior']
                });
                break;
            case 'feriasMaior':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['feriasMaior']
                });
                break;
            case 'dataAvisoInvalida':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['dataAvisoInvalida']
                });
                break;
            case 'dataAvisoVazia':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['dataAvisoVazia']
                });
                break;
            case 'dataVazia':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['dataVazia']
                });
                break;
            case 'possuiPeriodosSobrepostos':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['possuiPeriodosSobrepostos']
                });
                break;
            case 'saldoNegativo':
                this.NewToaster.pop({
                    type: 'error',
                    title: this.alertMessages['saldoNegativo']
                });
                break;
            default:
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível salvar'
                });
        }
    }

    montaFolgas(t: any) {
        var folgas: any = [];
        if (t.repousodomingo) {
            folgas.push(0);
        }
        if (t.repousosegunda) {
            folgas.push(1);
        }
        if (t.repousoterca) {
            folgas.push(2);
        }
        if (t.repousoquarta) {
            folgas.push(3);
        }
        if (t.repousoquinta) {
            folgas.push(4);
        }
        if (t.repousosexta) {
            folgas.push(5);
        }
        if (t.repousosabado) {
            folgas.push(6);
        }
        return folgas;
    }

    filtraFerias() {
        if (this.ocultarsolicitacoes) {
            let full_count = this.entities.length > 0 ? this.entities[0]['full_count'] : 0;
            let entitiesAux = this.entities.filter(entity => entity['ferias'].length > 0);
            while (entitiesAux.length % 60 !== 0 && this.entities.length < full_count && !this.ControleFeriasService.busy) {
                entitiesAux = this.entities.filter(entity => entity['ferias'].length > 0);
                this.ControleFeriasService.loadMore();
            }
            return entitiesAux;
        } else {
            return this.entities;
        }
    }

    rangeDiasPeriodoFerias(iWorker: any, period: any) {
        let saldo = this.calculoSaldo(this.formData[iWorker], this.entities[iWorker].direito);
        if (saldo < 5 && !this.formData[iWorker].ferias[period].diasferiascoletivas) {
            return null;
        } else if (saldo >= 5 && !this.formData[iWorker].ferias[period].diasferiascoletivas) {
            return _.range(5, saldo + 1);
        } else {
            let range = (Object.keys(this.formData[iWorker].ferias).length > 1) ? _.range(5, this.formData[iWorker].ferias[period].diasferiascoletivas + saldo + 1) : _.range(5, 31);
            return range;
        }
    }

    rangeAbono(iWorker: any, period: any) {
        let saldo = this.calculoSaldo(this.formData[iWorker], this.entities[iWorker].direito);
        if (saldo < 1 && !this.formData[iWorker].ferias[period].diasvendidos) {
            return null;
        } else if (saldo >= 1 && !this.formData[iWorker].ferias[period].diasvendidos) {
            let range = saldo > 10 ? _.range(0, 11) : _.range(0, saldo + 1);
            return range;
        } else {
            return _.range(0, 11);
        }
    }

    montaConstrutorColaboradores() {
        return { 'status': '00', 'tipo_excluir': 1, 'tipo': this.filterData.tipo, 'forcagestor': this.$rootScope.modoGestorPermissao('criar_ferias') };
    }


    calculoSaldo(data: any, direito: any) {
        if (angular.isUndefined(data)) {
            return 0;
        }
        var sum = 0;
        angular.forEach(data.ferias, (v, k) => {
            if (typeof v.diasferiascoletivas !== 'undefined') {
                sum = isNaN(v.diasferiascoletivas) ? sum + 0 : sum + v.diasferiascoletivas;
            }
            if (typeof v.diasvendidos !== 'undefined') {
                sum = isNaN(v.diasvendidos) ? sum + 0 : sum + v.diasvendidos;
            }
        });
        return direito - sum;
    }

    /* --------------------------------------------------------------------------- */

    adicionarPeriodoFerias(iWorker: any) {
        if (!this.formData[iWorker].dividir_ferias) {
            this.formData[iWorker].dividir_ferias = this.entities[iWorker].ferias.length === 0 ? 1 : this.entities[iWorker].ferias.length;
            this.entities[iWorker].optionsDividirFerias = this.optionsDividirFerias(iWorker);
            this.entities[iWorker].disable_dividir_ferias = this.disableSelectDividirFerias(iWorker);
        }
        if (this.formData[iWorker].dividir_ferias < 3) {
            this.formData[iWorker].dividir_ferias++;
            this.entities[iWorker].dividir_ferias = this.formData[iWorker].dividir_ferias;
            this.submitData(iWorker, null, 'dividir_ferias');
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'As férias só podem ser divididas em no máximo 3 períodos!'
            });
        }
    }

    inicialiazaDividirFerias(iWorker: any) {
        this.entities[iWorker].ferias.length === 0 ? this.formData[iWorker].dividir_ferias = 1 : this.formData[iWorker].dividir_ferias = this.entities[iWorker].ferias.length;
        this.entities[iWorker].optionsDividirFerias = this.optionsDividirFerias(iWorker);
        this.entities[iWorker].disable_dividir_ferias = this.disableSelectDividirFerias(iWorker);
    }

    removerPeriodoFerias(iWorker: any) {
        if (this.formData[iWorker].dividir_ferias > 1) {
            this.formData[iWorker].dividir_ferias--;
            this.entities[iWorker].dividir_ferias = this.formData[iWorker].dividir_ferias;
            this.submitData(iWorker, null, 'dividir_ferias');
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'As férias devem ter no mínimo 1 período!'
            });
        }
    }

    adiantamentoDisabilitado(adto: any, iWorker: any) {
        var possuiAdiantamentosMarcados = this.possuiAdiantamentosMarcados(iWorker);
        if (!adto && possuiAdiantamentosMarcados) {
            return true;
        } else {
            return false;
        }
    }

    possuiAdiantamentosMarcados(iWorker: any) {
        var possuiPeriodosComAdiantamento = false;
        for (let i in this.formData[iWorker].ferias) {
            if (this.formData[iWorker].ferias[i].adto13nasferias === true) {
                possuiPeriodosComAdiantamento = true;
            }
        }
        return possuiPeriodosComAdiantamento;
    }

    /* SUBMETE O FORMULÁRIO */
    submitData(iWorker: any, period: any, field: any) {
        console.log(this.formData[iWorker]);
        field = field ? field : '';
        var error = false;

        var direito = this.formData[iWorker].diasvendidos ? this.entities[iWorker].direito - (this.entities[iWorker].direito / 3) : this.entities[iWorker].direito;

        if (!this.formData[iWorker].dividir_ferias) {
            this.inicialiazaDividirFerias(iWorker);
        }

        if (period !== null) {
            if (field === 'datainiciogozo') {
                var dataInicio = moment(this.formData[iWorker].ferias[period].datainiciogozo);
                this.calculateEnd(iWorker, period);
                this.formData[iWorker].ferias[period].dataaviso = moment(this.formData[iWorker].ferias[period].datainiciogozo).subtract(30, 'days').format('YYYY-MM-DD');
            }
            if (field === 'diasferiascoletivas') {
                if (!this.formData[iWorker].dividir_ferias) {
                    this.formData[iWorker].dividir_ferias = this.entities[iWorker].ferias.length === 0 ? 1 : this.entities[iWorker].ferias.length;
                    this.entities[iWorker].optionsDividirFerias = this.optionsDividirFerias(iWorker);
                    this.entities[iWorker].disable_dividir_ferias = this.disableSelectDividirFerias(iWorker);
                }
                this.calculateEnd(iWorker, period);
            }
        }

        if (field === 'dividir_ferias') {
            if (!this.formData[iWorker].dividir_ferias) {
                this.formData[iWorker].dividir_ferias = this.entities[iWorker].ferias.length;
                this.entities[iWorker].optionsDividirFerias = this.optionsDividirFerias(iWorker);
                this.entities[iWorker].disable_dividir_ferias = this.disableSelectDividirFerias(iWorker);
            }
            this.dividirFerias(iWorker, false);
            if (this.formData[iWorker].dividir_ferias === 1 && !this.entities[iWorker].ferias[0].confirmado && !this.entities[iWorker].ferias[0].calculado) {
                this.formData[iWorker].ferias[0].diasferiascoletivas = direito;
                this.calculateEnd(iWorker, 0);
            }
        }

        // if (field === 'dataaviso') {
        //     if(!this.configMarcarFeriasAntes && moment(this.formData[iWorker].ferias[period].dataaviso).isBefore(this.entities[iWorker].prazoMinimoInicio)){
        //         this.formData[iWorker].ferias[period].datainiciogozo = moment(this.entities[iWorker].prazoMinimoInicio).format('YYYY-MM-DD');
        //     } else {
        //         if(!this.configMarcarFeriasAntes && this.formData[iWorker].ferias[period].dataaviso!=undefined && moment(this.formData[iWorker].ferias[period].dataaviso).isBefore(moment(this.entities[iWorker].fimperiodoaquisitivoferias))){
        //             this.formData[iWorker].ferias[period].datainiciogozo = this.entities[iWorker].fimperiodoaquisitivoferias;
        //         }else{
        //             this.formData[iWorker].ferias[period].datainiciogozo = moment(this.formData[iWorker].ferias[period].dataaviso).add(30, 'days').format('YYYY-MM-DD');
        //         }
        //     }
        //     while( this.formData[iWorker].ferias[period].dataaviso!=undefined &&  this.diaInicioFeriasInvalido(iWorker,period)){
        //         this.formData[iWorker].ferias[period].datainiciogozo = moment.utc(this.formData[iWorker].ferias[period].datainiciogozo, 'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD');
        //     }
        //     this.calculateEnd(iWorker, period);
        // }

        if (field === 'adto13nasferias') {
            if (this.formData[iWorker].ferias[period].adto13nasferias === undefined) {
                this.formData[iWorker].ferias[period].adto13nasferias = true;
            }
        }



        var emptyDate = 0;

        angular.forEach(this.formData[iWorker].ferias, (v, k) => {
            if (!v.datainiciogozo) {
                emptyDate++;
            }
        });

        var result = this.validateVacation(iWorker, period);
        if (field !== 'dataaviso' && result !== 'dataAvisoVazia') {
            if (result === 'folga') {
                this.showAlert(result);
            } else if (result !== 'success') {
                if (emptyDate === 0) {
                    this.showAlert(result);
                }
            }
        } else {
            if (result === 'dataAvisoVazia') {
                this.showAlert(result);
            }
        }
    }

    constructorCargos() {
        var constructor: any = {};
        if (this.$rootScope.session.entities.estabelecimento) {
            constructor.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        }
        return constructor;
    }

    diaInicioFeriasInvalido(iWorker: any, period: any) {
        var folgas = this.montaFolgas(this.entities[iWorker]);
        var hasJornada = this.hasJornada(this.entities[iWorker]);
        var tipoHorarioEscala = this.entities[iWorker].tipohorario === 6 ? true : false;
        var folga = false;
        var feriado = false;
        var date = moment(this.formData[iWorker].ferias[period].datainiciogozo + ' 12:00', 'YYYY-MM-DD HH:mm');
        var weekday = date.weekday();
        if (hasJornada && !tipoHorarioEscala) {
            for (let folgaday of folgas) {
                let folgaless1day = folgaday - 1 < 0 ? 6 : folgaday - 1;
                let folgaless2day = folgaless1day - 1 < 0 ? 6 : folgaless1day - 1;
                if (weekday === folgaday || weekday === folgaless1day || weekday === folgaless2day) {
                    folga = true;
                    break;
                }
            }
        }

        if (folga) {
            return true;
        }
        for (let feriadodate of this.feriados) {
            var feriadoday = moment(feriadodate + ' 12:00', 'YYYY-MM-DD HH:mm');
            var feriadoless1day = moment(feriadodate + ' 12:00', 'YYYY-MM-DD HH:mm').subtract(1, 'days');
            var feriadoless2day = moment(feriadodate + ' 12:00', 'YYYY-MM-DD HH:mm').subtract(2, 'days');
            if (date.isSame(feriadoday)
                || date.isSame(feriadoless1day)
                || date.isSame(feriadoless2day)) {
                feriado = true;
                break;
            }
        }

        if (feriado) {
            return true;
        }

        return false;
    }

    hasJornada(worker: any) {
        // repouso em todos os dias significa que o trabalhador foi cadastrado sem jornada
        if (worker.repousodomingo &&
            worker.repousosegunda &&
            worker.repousoterca &&
            worker.repousoquarta &&
            worker.repousoquinta &&
            worker.repousosexta &&
            worker.repousosabado) {
            return false;
        }
        return true;
    }

    savePeriod(iWorker: any, period: any) {
        var validate;

        validate = this.validatePeriod(iWorker, period);
        return validate;
    }

    validateVacation(iWorker: any, period: any = null) {
        var validate: any, periodsErr: any = [];

        angular.forEach(this.formData[iWorker].ferias, (v, period) => {
            var current = this.savePeriod(iWorker, period);
            if (current !== 'success') {
                periodsErr.push(current);
            }
        });

        if (periodsErr.length > 0) {
            return periodsErr[0];
        }

        if (periodsErr.length === 0) {
            var all = this.validateAllPeriods(iWorker, period);
            validate = all;
        }

        return validate;
    }

    saveSuccess(iWorker: any, kferias: any) {
        this.saveIsLoad = true;
        this.alert = true;

        var validationresult = this.validateAllPeriods(iWorker);

        if (validationresult === 'success') {
            this.dividirFerias(iWorker, true);
            /* -------- SAVE --------- */
            angular.forEach(this.formData[iWorker].ferias, (v, period) => {
                if (period < this.formData[iWorker].dividir_ferias && !this.entities[iWorker].ferias[period].confirmado && !this.entities[iWorker].ferias[period].calculado) {
                    var data = this.setData(iWorker, period);
                    var update = false;
                    if (this.entities[iWorker].ferias[period].solicitacao) {
                        update = true;
                    }
                    var validate = this.savePeriod(iWorker, period);

                    if (validate === 'success') {
                        /* -------- SAVE --------- */
                        this.ControleFeriasService.constructors.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
                        this.ControleFeriasService.save(data, iWorker, period, update);
                    } else {
                        this.saveIsLoad = false;
                        this.showAlert(validate);
                        return false;
                    }
                }

            });
        } else {
            this.saveIsLoad = false;
            this.showAlert(validationresult);
        }
    }

    deletePeriod(iWorker: any, iperiod: any) {
        this.ControleFeriasService.delete(this.entities[iWorker].ferias[iperiod], false);
    }

    onDeleteSuccess() {
        this.$scope.$on('meurh_solicitacoesferias_deleted', (event, args) => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de Férias foi excluída com sucesso!'
            });
            this.filter();
        });
    }

    onDeleteError() {
        this.$scope.$on('meurh_solicitacoesferias_delete_error', (event, args) => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    });
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir a Solicitação de Férias!'
                    });
            }
        });
    }

    /* ADICIONA E REMOVE PERIODOS DE ACORDO COM O SELECT */
    dividirFerias(iWorker: any, force_delete: any) {

        var len = Object.keys(this.formData[iWorker].ferias).length;
        for (var i = 0; i < len; i++) {
            if (i >= this.formData[iWorker].dividir_ferias) {
                delete this.formData[iWorker].ferias[i];

                if (!this.entities[iWorker].ferias[i].solicitacao) {
                    this.entities[iWorker].ferias[i].datafimgozo = '';
                }
            }
        }

        /* -----REMOVE OS PERIODOS DO FUNCIONARIO -----*/
        if (force_delete) {
            var count = this.entities[iWorker].ferias.length - this.formData[iWorker].dividir_ferias;
            for (let i = 0; i < count; i++) {
                var objAux = this.entities[iWorker].ferias.pop();
                if (objAux.solicitacao) {
                    this.ControleFeriasService.delete(objAux.solicitacao, true);
                }
            }
        }

        for (let i = 0; i < this.formData[iWorker].dividir_ferias; i++) {
            // adiciona o ID no formData equivalente caso haja um aviso
            if (i in this.entities[iWorker].ferias && i in this.formData[iWorker].ferias) {
                this.formData[iWorker].ferias[i].solicitacao = this.entities[iWorker].ferias[i].solicitacao;
            }
            // adiciona um aviso na entidade
            if (!(i in this.entities[iWorker].ferias)) {
                this.entities[iWorker].ferias.push({});
            }


        }

        // if (this.formData[iWorker].dividir_ferias < this.entities[iWorker].ferias.length) {

        // } else {
        //     /* ----- ADICIONA OS PERIODOS DO FUNCIONARIO -----*/

        //         // if (i + 1 > this.entities[iWorker].ferias.length) {
        //         //     this.entities[iWorker].ferias.push({});
        //         // }
        // }


        // this.formData[iWorker].ferias = this.entities[iWorker].ferias;
    }

    /* ADICIONA PERIODOS DE ACORDO COM O BOTAO */
    /*
    this.adicionarPeriodoFerias = function (iWorker) {

        var len = Object.keys(this.formData[iWorker].ferias).length;
        for(var i = 0; i < len; i++){
            if(i >= this.formData[iWorker].dividir_ferias){
                delete this.formData[iWorker].ferias[i];

                if(!this.entities[iWorker].ferias[i].solicitacao){
                    this.entities[iWorker].ferias[i].datafimgozo = ';
                }
            }
        }

        for (var i = 0; i < this.formData[iWorker].ferias.length+1; i++) {
            //Adiciona o ID no formData equivalente caso haja um aviso
            if (i in this.entities[iWorker].ferias && i in this.formData[iWorker].ferias){
                this.formData[iWorker].ferias[i].solicitacao = this.entities[iWorker].ferias[i].solicitacao;
            }
            //adiciona um aviso na entidade
            if(!(i in this.entities[iWorker].ferias)){
                this.entities[iWorker].ferias.push({});
            }
        }
    }*/


    /* CALCULA A DATA DO FIM DO PERÍODO */
    calculateEnd(iTrabalhador: any, periodo_ferias: any) {
        console.log(this.formData[iTrabalhador]);
        if (this.formData[iTrabalhador].ferias[periodo_ferias].datainiciogozo) {
            var dataInicio = moment(this.formData[iTrabalhador].ferias[periodo_ferias].datainiciogozo);
            var diasferias = this.formData[iTrabalhador].ferias[periodo_ferias].diasferiascoletivas;
            diasferias = diasferias - 1;
            if (this.entities[iTrabalhador].ferias.length === 0) {
                this.entities[iTrabalhador].ferias[periodo_ferias] = {};
            }
            this.formData[iTrabalhador].ferias[periodo_ferias].datafimgozo = this.entities[iTrabalhador].ferias[periodo_ferias].datafimgozo = moment(this.formData[iTrabalhador].ferias[periodo_ferias].datainiciogozo).add(diasferias, 'days').format('YYYY-MM-DD');
        }
    }

    initVacationPeriods(entity: any, form: any) {
        if (!entity.dividir_ferias) {
            entity.dividir_ferias = 1;
            form.dividir_ferias = 1;
        }
    }

    mensagemPeriodoFeriasErro(iWorker: any, period: any) {
        if (this.formData[iWorker].ferias[period].erroSemPeriodo14) {
            return this.alertMessages['min14'];
        }
        if (this.formData[iWorker].ferias[period].erroPeriodoMenor5) {
            return this.alertMessages['maior5'];
        }
        if (this.formData[iWorker].ferias[period].erroSaldoNegativo) {
            return this.alertMessages['saldoNegativo'];
        }
        if (this.formData[iWorker].ferias[period].erroSaldoNaoVazio) {
            return this.alertMessages['saldoDeveSerVazio'];
        }
        return '';
    }

    mensagemAvisoErro(iWorker: any, period: any) {
        if (this.formData[iWorker].ferias[period].erroAvisoVazio) {
            return this.alertMessages['dataAvisoVazia'];
        }
        if (this.formData[iWorker].ferias[period].erroAvisoInvalido) {
            return this.alertMessages['dataAvisoInvalida'];
        }
        return '';
    }

    mensagemInicioGozoErro(iWorker: any, period: any) {
        if (this.formData[iWorker].ferias[period].erroPeriodoVazio) {
            return this.alertMessages['dataVazia'];
        }
        if (this.formData[iWorker].ferias[period].erroPeriodosSobrepostos) {
            return this.alertMessages['possuiPeriodosSobrepostos'];
        }
        return '';
    }

    finished() {
        return this.ControleFeriasService.loadParams.finished;
    }

    validateAllPeriods(iWorker: any, period: any = null) {
        var obj = { diasperiodos: 0, saldo: 0, direito: this.entities[iWorker].direito - (this.formData[iWorker].diasvendidos ? this.entities[iWorker].direito / 3 : 0), maior14: false };
        var totalDiasVendidos = 0;
        var totalDiasFerias = 0;
        var dataAvisoInvalida = false;
        var dataAvisoVazia = false;
        var possuiPeriodosSemData = false;
        var possuiPeriodosSobrepostos = false;
        for (let i = 0; i < Object.keys(this.formData[iWorker].ferias).length; i++) {
            for (let f = 0; f < Object.keys(this.formData[iWorker].ferias).length; f++) {
                if (i !== f &&
                    ((moment(this.formData[iWorker].ferias[i].datainiciogozo).isBetween(this.formData[iWorker].ferias[f].datainiciogozo, this.formData[iWorker].ferias[f].datafimgozo))
                        || (moment(this.formData[iWorker].ferias[i].datafimgozo).isBetween(this.formData[iWorker].ferias[f].datainiciogozo, this.formData[iWorker].ferias[f].datafimgozo))
                        || (moment(this.formData[iWorker].ferias[i].datainiciogozo).isSame(this.formData[iWorker].ferias[f].datainiciogozo))
                        || (moment(this.formData[iWorker].ferias[i].datainiciogozo).isSame(this.formData[iWorker].ferias[f].datafimgozo))
                        || (moment(this.formData[iWorker].ferias[i].datafimgozo).isSame(this.formData[iWorker].ferias[f].datainiciogozo))
                        || (moment(this.formData[iWorker].ferias[i].datafimgozo).isSame(this.formData[iWorker].ferias[f].datafimgozo)))) {
                    possuiPeriodosSobrepostos = true;
                }
            }
        }

        angular.forEach(this.formData[iWorker].ferias, (value, key) => {

            var periodo = value.diasferiascoletivas;
            var diasvendidos = value.diasvendidos;
            this.formData[iWorker].ferias[key].erroSemPeriodo14 = false;
            this.formData[iWorker].ferias[key].erroSaldoNegativo = false;
            this.formData[iWorker].ferias[key].erroSaldoNaoVazio = false;
            this.formData[iWorker].ferias[key].erroPeriodoMenor5 = false;

            this.formData[iWorker].ferias[key].erroAvisoVazio = false;
            this.formData[iWorker].ferias[key].erroAvisoInvalido = false;


            this.formData[iWorker].ferias[key].erroPeriodoVazio = false;
            this.formData[iWorker].ferias[key].erroPeriodosSobrepostos = false;


            if (!value.datainiciogozo) {
                this.formData[iWorker].ferias[key].erroPeriodoVazio = true;
                possuiPeriodosSemData = true;
            }

            if (periodo >= 14) {
                obj.maior14 = true;
            }

            if (periodo) {
                obj.diasperiodos = obj.diasperiodos + periodo;
            }

            if (diasvendidos) {
                obj.diasperiodos = obj.diasperiodos + diasvendidos;
            }

            if (this.formData[iWorker].ferias[key].dataaviso !== undefined && this.formData[iWorker].ferias[key].dataaviso !== '') {
                if (moment(this.formData[iWorker].ferias[key].dataaviso).isSameOrAfter(this.formData[iWorker].ferias[key].datainiciogozo)) {
                    this.formData[iWorker].ferias[key].erroAvisoInvalido = true;
                    dataAvisoInvalida = true;
                }
            } else {
                this.formData[iWorker].ferias[key].erroAvisoVazio = true;
                dataAvisoVazia = true;
            }

            totalDiasVendidos = totalDiasVendidos + value.diasvendidos;
            totalDiasFerias = totalDiasFerias + periodo;

        });

        obj.saldo = obj.direito - obj.diasperiodos;

        if (possuiPeriodosSobrepostos) {
            if (period) {
                this.formData[iWorker].ferias[period].erroPeriodosSobrepostos = true;
            }
            return 'possuiPeriodosSobrepostos';
        }

        if (possuiPeriodosSemData) {
            return 'dataVazia';
        }

        if (dataAvisoVazia) {
            return 'dataAvisoVazia';
        }

        if (dataAvisoInvalida) {
            return 'dataAvisoInvalida';
        }

        if (totalDiasVendidos > 10) {
            return 'abonoMaior';
        }
        if (totalDiasVendidos + totalDiasFerias > obj.direito) {
            return 'feriasMaior';
        }

        if (!obj.maior14 && obj.saldo < 14) {
            if (period) {
                this.formData[iWorker].ferias[period].erroSemPeriodo14 = true;
            }
            return 'min14';
        } else {
            if (obj.saldo < 0) {
                if (period) {
                    this.formData[iWorker].ferias[period].erroSaldoNegativo = true;
                }
                return 'saldoNegativo';
            } else {
                if (obj.saldo < 5 && obj.saldo > 0) {
                    if (Object.keys(this.formData[iWorker].ferias).length === 3) {
                        if (period) {
                            this.formData[iWorker].ferias[period].erroSaldoNaoVazio = true;
                        }
                        return 'saldoDeveSerVazio';
                    } else {
                        if (period) {
                            this.formData[iWorker].ferias[period].erroPeriodoMenor5 = true;
                        }
                        return 'maior5';
                    }
                } else {
                    return 'success';
                }
            }
        }
    }

    /* RETORNA OBJETO COM ERROS DO PERÍODO */
    validatePeriod(iWorker: any, period: any) {

        var obj: any = { diasperiodos: 0, saldo: 0, direito: this.entities[iWorker].direito - (this.formData[iWorker].diasvendidos ? this.entities[iWorker].direito / 3 : 0) };

        var folgas = this.montaFolgas(this.entities[iWorker]);

        var formWorker = this.formData[iWorker];
        var formPeriod = formWorker.ferias[period];

        var date;
        var weekday;

        if (formPeriod.datainiciogozo) {
            date = moment(formPeriod.datainiciogozo + ' 12:00', 'YYYY-MM-DD HH:mm');
            weekday = date.weekday();
        } else {
            date = null;
            weekday = null;
        }
        var hasJornada = this.hasJornada(this.entities[iWorker]);
        var tipoHorarioEscala = this.entities[iWorker].tipohorario === 6 ? true : false;

        if (date !== null) {
            for (let feriado of this.feriados) {
                var feriadoday = moment(feriado + ' 12:00', 'YYYY-MM-DD HH:mm');
                var feriadoless1day = moment(feriado + ' 12:00', 'YYYY-MM-DD HH:mm').subtract(1, 'days');
                var feriadoless2day = moment(feriado + ' 12:00', 'YYYY-MM-DD HH:mm').subtract(2, 'days');
                if (date.isSame(feriadoday)
                    || date.isSame(feriadoless1day)
                    || date.isSame(feriadoless2day)) {
                    obj.feriado = true;
                    break;
                }
            }

            if (hasJornada && !tipoHorarioEscala) {
                for (let folga of folgas) {
                    let folgaless1day = folga - 1 < 0 ? 6 : folga - 1;
                    let folgaless2day = folgaless1day - 1 < 0 ? 6 : folgaless1day - 1;
                    if (weekday === folga || weekday === folgaless1day || weekday === folgaless2day) {
                        obj.folga = true;
                        break;
                    }
                }
            }
        }

        if (formPeriod.diasferiascoletivas < 5) {
            obj.menorMin = true;
        }

        if (!this.configMarcarFeriasAntes && moment(formPeriod.datainiciogozo).isBefore(moment(this.entities[iWorker].fimperiodoaquisitivoferias))) {
            obj.erroInicioGozoAntesDoFimPeriodoAquisitivo = true;
        }

        /* PROVISÓRIO */
        var err = obj;

        if (err.feriado) {
            return 'feriado';
        } else {
            if (err.folga) {
                return 'folga';
            } else {
                if (err.menorMin) {
                    return 'maior5';
                } else {
                    if (err.erroInicioGozoAntesDoFimPeriodoAquisitivo) {
                        return 'erroInicioGozoAntesDoFimPeriodoAquisitivo';
                    } else {
                        return 'success';
                    }
                }
            }
        }
    }

    verificacaoDataRescisao(data: any) {
        if (data === null || data === '') {
            return false;
        }
        if (moment(data).isSameOrBefore(moment(this.dataAtual))) {
            return true;
        } else {
            return false;
        }
    }

    mensagemTooltipAdicionar(iWorker: any) {
        if (this.formData[iWorker]) {
            if (this.calculoSaldo(this.formData[iWorker], this.entities[iWorker].direito) < 1 || this.entities[iWorker].datarescisao !== null && this.verificacaoDataRescisao(this.entities[iWorker].datarescisao) || this.formData[iWorker].dividir_ferias === 3) {
                this.tooltipAdicionar = 'Saldo indisponível ou as férias já estão particionadas observando o limite de três períodos.';
            } else {
                this.tooltipAdicionar = 'Adicionar período de férias';
            }
        } else {
            this.tooltipAdicionar = 'Adicionar período de férias';
        }
    }

    dataInicioValidacao(iWorker: any, period: any) {
        let datafinal = moment(this.entities[iWorker].inicioperiodoaquisitivoferias).add('years', 2).subtract('days', 1);
        if (this.formData[iWorker].ferias[period].datainiciogozo !== '' && (moment(this.formData[iWorker].ferias[period].datainiciogozo).isAfter(moment(datafinal)) || (moment(this.formData[iWorker].ferias[period].datainiciogozo).isBefore(datafinal) && moment(datafinal).isBefore(moment(this.formData[iWorker].ferias[period].datafimgozo))))) {
            return true;
        }
        return false;
    }

    diasEmDobro(iWorker: any, period: any) {
        let datafinal = moment(this.entities[iWorker].inicioperiodoaquisitivoferias).add('years', 2).subtract('days', 1);
        if (moment(datafinal).isBefore(moment(this.formData[iWorker].ferias[period].datainiciogozo))) {
            this.tooltip = 'Ao marcar férias nesta data, as mesmas terão ' + this.formData[iWorker].ferias[period].diasferiascoletivas + ' dias pagos em dobro';
        } else {
            this.tooltip = 'Ao marcar férias nesta data, as mesmas terão ' + moment(this.formData[iWorker].ferias[period].datafimgozo).diff(moment(datafinal), 'days') + ' dias pagos em dobro';
        }
    }

    /* RETORNA UM OBJETO COM OS DADOS NECESSÁRIOS PARA SALVAR O AVISO */
    setData(iTrabalhador: any, periodo_ferias: any) {
        var aux: any = {
            trabalhador: this.entities[iTrabalhador].trabalhador,
            datainicioperiodoaquisitivo: this.entities[iTrabalhador].inicioperiodoaquisitivoferias,
            datafimperiodoaquisitivo: this.entities[iTrabalhador].fimperiodoaquisitivoferias,
            tipo: 1,
            faltas: 0
        };

        if (this.formData[iTrabalhador].ferias[periodo_ferias].diasvendidos > 0) {
            aux.temabonopecuniario = 1;
            aux.diasvendidos = this.formData[iTrabalhador].ferias[periodo_ferias].diasvendidos;
        } else {
            aux.temabonopecuniario = 0;
            aux.diasvendidos = 0;
        }

        if (this.formData[iTrabalhador].ferias[periodo_ferias].adto13nasferias === true) {
            aux.adto13nasferias = 1;
            aux.observacao = 'Adiantamento de 13o';
        } else {
            aux.adto13nasferias = 0;
            aux.observacao = '';
        }

        var dataInicio;

        if (periodo_ferias !== null) {
            dataInicio = moment(this.formData[iTrabalhador].ferias[periodo_ferias].datainiciogozo);
            var dataFim = moment(this.formData[iTrabalhador].ferias[periodo_ferias].datafimgozo);

            aux.datainiciogozo = dataInicio.format('YYYY-MM-DD');
            aux.datafimgozo = dataFim.format('YYYY-MM-DD');
            aux.solicitacao = this.formData[iTrabalhador].ferias[periodo_ferias].solicitacao;
            aux.diasferiascoletivas = this.formData[iTrabalhador].ferias[periodo_ferias].diasferiascoletivas;
            aux.dataaviso = this.formData[iTrabalhador].ferias[periodo_ferias].dataaviso;

        } else {
            dataInicio = moment(this.formData[iTrabalhador].ferias[0].datainiciogozo);
            aux.dataaviso = this.formData[iTrabalhador].ferias[periodo_ferias].dataaviso;
        }
        return aux;
    }

    // deprecated
    disableSubmitButton(iWorker: any) {
        if (this.entities[iWorker]) {
            return this.entities[iWorker].ferias.every(x => x.confirmado);
        } else {
            return false;
        }
    }

    prazoMinimo(iWorker: any) {
        var now = moment();
        var fimPeriodoAquisitivo = moment(this.entities[iWorker].fimperiodoaquisitivoferias + ' 12:00', 'YYYY-MM-DD HH:mm').utc().add(1, 'days');
        var base;
        if (now.isAfter(fimPeriodoAquisitivo)) {
            base = now;
        } else {
            base = fimPeriodoAquisitivo;
        }
        return [base, base];
    }

    optionsDividirFerias(iWorker: any) {
        let all = [
            { 'value': 1, 'label': 'Não' },
            { 'value': 2, 'label': '2 períodos' },
            { 'value': 3, 'label': '3 períodos' }
        ];
        let n = 0;
        for (var i = 0; i < this.entities[iWorker].ferias.length; i++) {
            if (this.entities[iWorker].ferias[i].confirmado || this.entities[iWorker].ferias[i].calculado) {
                n++;
            }
        }
        if (n > 0) {
            n--;
        }
        return all.slice(n);
    }

    disableSelectDividirFerias(iWorker: any) {
        let saldo = this.$filter('getSaldo')(this.entities[iWorker], this.entities[iWorker].direito);
        if (this.todasFeriasConfirmadas(iWorker) && saldo === 0) {
            return true;
        }
        return false;
    }

    todasFeriasConfirmadas(iWorker: any) {
        return this.entities[iWorker].ferias.every(x => x.confirmado) && this.entities[iWorker].ferias.length > 0;
    }
}
