import angular from 'angular';

export class AtestadosAVencerService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = [];
    public loaded: any = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loading_deferred: any = null;
    public filter: any = '';
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false
    };

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any,
    ) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('relatoriosliberados_atestadosavencer', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                        this.after['nome'] = this.entities[this.entities.length - 1]['nome'];
                        this.after['trabalhador'] = this.entities[this.entities.length - 1]['trabalhador'];
                    }
                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('relatoriosliberados_atestadosavencer_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error: any) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                    this.load();
                });
        }

        return this.entities;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }
}
