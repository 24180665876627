import ng from 'angular';
import moment from 'moment';
import { IRetornosAfastamento } from '../models/retornoAfastamento.model';
import { MeurhSolicitacoesafastamentosretornoService } from '../solicitacoesafastamentosretorno.service';
import { SituacaoEnum } from '../enums/situacao.enum';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';

export class MeurhSolicitacoesafastamentosretornoShowController implements ng.IController {
    static $inject = [
        'MeurhSolicitacoesafastamentosretornoService',
        'MeurhAcompanhamentoService',
        '$stateParams',
        '$rootScope',
        'NewToaster'
    ];

    public form: ng.IFormController;
    public busy: boolean;
    public entity: IRetornosAfastamento;
    public action: string = 'show';

    public estabelecimentoObj: any;

    public situacaoEnum = SituacaoEnum;

    constructor(
        private entityService: MeurhSolicitacoesafastamentosretornoService,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        private $stateParams: angular.ui.IStateParamsService,
        private $rootScope: angular.IRootScopeService,
        private NewToaster: any
    ) { }

    public async $onInit(): Promise<void> {
        await this.getAfastamentoRetornoIndividual();
        this.getNomeEstabelecimento();
        this.getNomeEmpresa();
        this.getDataRetornoInicial();
        this.getDiasPosAjuste();
        this.tratarDatas();

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();
    }

    private async getAfastamentoRetornoIndividual(): Promise<void> {
        this.busy = true;
        await this.entityService.getAfastamentoretornoIndividual(this.$stateParams['solicitacao'])
            .then((afastamentoretorno: ng.IHttpResponse<IRetornosAfastamento>) => {
                this.entity = afastamentoretorno.data;
            })
            .catch(() => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível encontrar o afastamento'
                });
            })
            .finally(() => this.busy = false);
    }

    private getNomeEstabelecimento(): void {
        this.$rootScope['nsjGlobals']['a']['estabelecimentosInfos'].forEach((estabelecimento: any) => {
            if (estabelecimento.id === this.entity.estabelecimento.estabelecimento) {
                this.entity.nomeEstabelecimento = estabelecimento.nomefantasia;
                this.estabelecimentoObj = estabelecimento;
            }
        });
    }

    private getNomeEmpresa(): void {
        this.estabelecimentoObj.grupoempresarial.empresas.forEach((empresa: any) => {
            if (empresa.id === this.entity.estabelecimento.empresa) {
                this.entity.nomeEmpresa = empresa.razaosocial;
            }
        });
    }

    private getDataRetornoInicial(): void {
        const dataRetornoInicial = moment(this.entity.afastamentotrabalhador.data).add(this.entity.afastamentotrabalhador.dias, 'd');
        this.entity.dataRetornoInicial = dataRetornoInicial.format('DD/MM/YYYY');
    }

    private getDiasPosAjuste(): void {
        this.entity.diasPosAjuste = moment(this.entity.dataretorno).diff(this.entity.afastamentotrabalhador.data, 'd');
    }

    private tratarDatas(): void {
        this.entity.dataretorno = moment(this.entity.dataretorno).format('DD/MM/YYYY');
        this.entity.afastamentotrabalhador.data = moment(this.entity.afastamentotrabalhador.data).format('DD/MM/YYYY');
    }
}
