import angular = require('angular');

export class DocumentoColaboradorModalService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: any) { }

	open(subentity: any): void {
		return this.$uibModal.open({
			template: require('html-loader!./anexos.visualizarmodal.html'),
			controller: [
				'entity',
				'$uibModalInstance',
				'$sce',
				function TrabalhadorModal(
					entity: any,
					$uibModalInstance: any,
					$sce: angular.ISCEService
				) {
					let selfModal = this;
					selfModal.entity = entity;
					selfModal.entity.urldocumento = (!selfModal.entity.possuibin) ?? $sce.trustAsResourceUrl(selfModal.entity.urldocumento);

					selfModal.close = (): void => {
						$uibModalInstance.dismiss('fechar');
					};
				}
			],
			controllerAs: '$ctrl',
			windowClass: 'nsj-modal-lg',
			resolve: {
				entity: () => {
					return subentity;
				}
			}
		});
	}
}
