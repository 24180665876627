import ng from 'angular';
import angular from 'angular';
import { StatusProcessoEnum } from '../../enums/statusprocesso';
import { MeurhAdmissaocompletaModalService } from '../../modal/admissaocompleta.modal.service';

export class MeurhAdmissoesDependentesFormController implements ng.IController {

    static $inject = ['$scope', 'NewToaster', 'MeurhAdmissaocompletaModalService', 'ModalConfirmService'];

    public entity: any;
    public action: string;
    public form: angular.IFormController;
    public idCount: number = 0;

    public statusProcessoEnum = StatusProcessoEnum;

    public fields: any = [
        {
            value: 'nome',
            label: 'Nome',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'cpf',
            label: 'CPF',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span>{{tableRowEntity.cpf | mask: "999.999.999-99"}}</span>
              `;
                return someHtmlVar;
            },

        },
        {
            value: 'datainclusao',
            label: 'Data inclusão',
            type: 'date',
            style: 'default',
            copy: '',
        },
        {
            value: 'sexo',
            label: 'Sexo',
            type: 'options',
            style: 'default',
            options: { 'Masculino': 'entity.sexo == 1', 'Feminino': 'entity.sexo == 2' },
            copy: '',
        },
        {
            value: 'impostorenda',
            label: 'Imposto de renda',
            type: 'options',
            style: 'default',
            options: { 'Sim': 'entity.impostorenda', 'Não': '!entity.impostorenda' },
            copy: '',
        },
        {
            value: 'salariofamilia',
            label: 'Salário família',
            type: 'options',
            style: 'default',
            options: { 'Sim': 'entity.salariofamilia', 'Não': '!entity.salariofamilia' },
            copy: '',
        },
    ];

    constructor(
        public $scope: angular.IScope,
        public NewToaster: any,
        public MeurhAdmissaocompletaModalService: MeurhAdmissaocompletaModalService,
        public ModalConfirmService: any
    ) {
    }

    $onInit(): void {
        if (!this.entity.dependentes) {
            this.entity.dependentes = [];
        } else {
                if (this.entity.dependentes.length > 0) {
                    this.entity.dependentes.forEach((element: any) => {
                        element['counter'] = this.idCount++;
                    });
                }
        }
    }

    abrirModal(dependente: any = {}, action: string = ''): void {
        let parameters = {
            'componente' : 'Dependente',
            'action' : action ? action : this.action ,
            'form' : this.form,
            'objadmissao' : this.entity,
            'desativacpf' : this.existeBeneficioParaDependente(dependente),
        };

        let modal = this.MeurhAdmissaocompletaModalService.open(dependente, parameters);

        modal.result
            .then((response: any): void => {
                if (!this.valorExiste(response.counter)) {
                    response.counter = this.idCount;
                    this.idCount++;
                    this.entity.dependentes.push(angular.copy(response));
                    this.$scope.$applyAsync();
                } else {
                    let achouDependente = false;
                    this.entity.dependentes.forEach((element: any, index: any) => {
                        if ((achouDependente === false) && (dependente.counter === element.counter)) {
                            this.entity.dependentes[index] = angular.copy(response);
                            achouDependente = true;
                        }
                    });
                }
            })
            .catch((error: any): void => {/**/});
    }

    excluirDependente(dependente: any): void {
        let textos = {
            title: 'Excluir dependente',
            subtitle: 'Após a exclusão não será mais possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir este dependente?',
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir dependente',
        };

        if (this.existeBeneficioParaDependente(dependente)) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Exclusão do dependente',
                body: 'Para excluir o dependente, é preciso primeiro eliminar o benefício associado a ele'
            });

            return;
        }

		let confirm = this.ModalConfirmService.open(this.entity, 'Dependente', false, textos);

        confirm.result.then((): void => {
            this.excluir(dependente);
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    excluir(dependente: any): void {
        let achouDependente = false;
        this.entity.dependentes.forEach((element: any, index: any) => {
            if ((achouDependente === false) && (dependente.counter === element.counter)) {
                achouDependente = true;
                this.entity.dependentes.splice(index, 1);
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    existeBeneficioParaDependente(dependente: any) {

        let dependenteBeneficio: boolean = false;

        if (this.entity.beneficios.length > 0 && dependente?.cpf != null) {

            dependenteBeneficio = this.entity.beneficios.some((beneficioLista) => {

                return dependente.cpf === beneficioLista.dependenteSelecionado?.cpf || dependente.cpf === beneficioLista.cpfdependente;

            });

        }

        return dependenteBeneficio;

    }

}
