import angular from 'angular';

export class MeurhAdmissoesValetransporteFormmodalController implements ng.IController {

    static $inject = [];

    public valetransporte: any;
    public action: string;

    constructor() {/**/}

    $onInit(): void {
        if (!this.valorExiste(this.valetransporte.quantidade)) {
            this.valetransporte.quantidade = 2;
        }
        this.getTarifaconcess();
     }

    getQuantidade(event: angular.IAngularEvent & { detail: any }): void {
        this.valetransporte.quantidade = event.detail.value;
        this.valetransporte.erroQuantidade = event.detail.error;
        if (this.valorExiste(this.valetransporte.tarifaconcessionariavt) && (!this.valorExiste(this.valetransporte.erroQuantidade) || this.valetransporte.erroQuantidade === false)) {
            this.calculaTotal();
        }
    }

    getTarifaconcess(): void {
        if (this.valorExiste(this.valetransporte.tarifaconcessionariavt)) {
            this.valetransporte['valor'] = this.valetransporte.tarifaconcessionariavt.valor;
        }
        if (this.valorExiste(this.valetransporte.quantidade) && this.valetransporte.quantidade > 0) {
            this.calculaTotal();
        }
    }

    calculaTotal(): void {
        this.valetransporte.totaldiario = this.valetransporte.valor * this.valetransporte.quantidade;
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
