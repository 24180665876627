import angular = require('angular');
import moment = require('moment');
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { ITitles } from '../models/titles.model';

export class MeurhCreditosdescontosEditController {

    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'NewToaster',
        'MeurhCreditosdescontosService', 'MeurhAcompanhamentoService', 'ModalConfirmService', '$q', '$transitions'];

    public busy: boolean = false;

    public passo: number = 2;
    public entity: Partial<ICreditoDesconto> = {};

    public titles: ITitles = {};

    public allowRefresh: boolean = false;
    public formchanged: boolean = true;
    public competencia = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_FOLHA, 'YYYY-MM-DD').format('MM/YYYY');

    constructor(
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: any,
        public MeurhCreditosdescontosService: any,
        public MeurhAcompanhamentoService: any,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any
    ) {
        this.busy = true;
        this.MeurhCreditosdescontosService.get(this.$stateParams.solicitacao);

        $scope.$on('meurh_solicitacoescreditodesconto_getloaded', (event: any, args: any) => {
            this.entity = args;
            MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
            MeurhAcompanhamentoService.loadParams.finished = false;
            MeurhAcompanhamentoService.loadParams.to_load = 3;
            MeurhAcompanhamentoService.load();
            this.alteraEntitySolicitacao();
            this.busy = false;
        });
    }

    $onInit() {
        this.onSalvarSuccess();
        this.onSalvarError();
        this.exitScreenDescartarModal();
		document.onkeydown = this.refreshDescartarModal;
		this.allowRefresh = true;
    }

    isBusy() {
        return this.busy;
    }

    alteraEntitySolicitacao() {
        switch (this.entity.situacao) {
            case -1:
                this.entity.situacaoDesc = 'Rascunho';
                break;
            case 0:
                this.entity.situacaoDesc = 'Enviada';
                break;
            case 1:
                this.entity.situacaoDesc = 'Aprovada';
                break;
            case 3:
                this.entity.situacaoDesc = 'Excluída';
                break;
        }
        this.entity.enviadoEm = this.entity.situacao === -1 ? 'Ainda não enviado' : moment(this.entity.lastupdate).format('DD/MM/YYYY');

        if (this.entity.tipoperiodo === 2  && this.entity.mesfinalperiodo && this.entity.anofinalperiodo) {
            this.entity.mes = this.entity.mesfinalperiodo.toString();
            this.entity.ano = this.entity.anofinalperiodo.toString();
        }

        this.titles.accordion = this.entity.evento.tipovalor === 0 ? 'Dados do crédito' : 'Dados do desconto';
        this.titles.header = this.entity.evento.tipovalor === 0 ? 'Crédito' : 'Desconto';

        this.entity.tipocalculo = this.entity.tipocalculo.toString();
        this.entity.tipoperiodo = this.entity.tipoperiodo.toString();

    }

    salvar() {
        this.allowRefresh = false;
        this.busy = true;
        this.constroiEntitySolicitacao();
    }

    constroiEntitySolicitacao() {
        let entitySolicitacaoEnviar: any = angular.copy(this.entity);
        if (entitySolicitacaoEnviar.evento && entitySolicitacaoEnviar.conteudo) {
            let eventounidade = entitySolicitacaoEnviar.evento.unidade === undefined ? entitySolicitacaoEnviar.evento.evento_unidade : entitySolicitacaoEnviar.evento.unidade;

            if ((eventounidade === 0 || eventounidade === 8 || eventounidade === 10) && typeof entitySolicitacaoEnviar.conteudo === 'string') {
                entitySolicitacaoEnviar.conteudo = parseFloat(entitySolicitacaoEnviar.conteudo.replace(':', '.'));
            }
        }

        entitySolicitacaoEnviar.constructors = {
            grupoempresarial: this.$rootScope.nsjGlobals.a.grupoempresarial,
            tenant: this.$rootScope.nsjGlobals.a.tenant,
        };
        entitySolicitacaoEnviar.constructors.grupoempresarial = this.$rootScope.nsjGlobals.a.grupoempresarial;
        entitySolicitacaoEnviar.constructors.tenant = this.$rootScope.nsjGlobals.a.tenant;

        entitySolicitacaoEnviar.competenciapagamento = this.competencia;

        if (entitySolicitacaoEnviar.tipoperiodo !== '2') {
            entitySolicitacaoEnviar.anofinalperiodo = null;
            entitySolicitacaoEnviar.mesfinalperiodo = null;
        } else {
            entitySolicitacaoEnviar.anofinalperiodo = entitySolicitacaoEnviar.ano;
            entitySolicitacaoEnviar.mesfinalperiodo = entitySolicitacaoEnviar.mes;
        }

        this.MeurhCreditosdescontosService.save(entitySolicitacaoEnviar);
    }

    exibeBotaoSalvar() {

        if (this.entity.tipoperiodo === '2') {

            if (!this.entity.ano || !this.entity.mes) {
                return false;
            }

            return !(moment(`${this.entity.mes}/${this.entity.ano}`, 'MM/YYYY').isSameOrBefore(moment(this.competencia, 'MM/YYYY')));
        } else {
            return true;
        }

    }

    onSalvarSuccess() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_submitted', (event: any, args: any) => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação de ' + (this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto') + ' foi editada com sucesso!'
            });
            this.$state.go('creditosdescontos_show', {'solicitacao': args.entity.solicitacao});
        });
    }

    onSalvarError() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_submit_error', (event: angular.IAngularEvent, args: any) => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else if (args.response.data && typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                if (args.response.data.message === 'Validation Failed') {
                    let message = args.response.data.errors.errors[0];

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de validação',
                        body: message,
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a solicitação.'
                });
            }
        });
    }

    buttonDescartarModal() {
		if (this.formchanged) {
			let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', false);
			confirm.result.then((): void => {
				this.allowRefresh = false;
				this.$state.go('creditosdescontos_show', {'solicitacao': this.$stateParams['solicitacao']});
			})
				.catch((error: any): void => {/* */ });
		} else {
            this.$state.go('creditosdescontos_show', {'solicitacao': this.$stateParams['solicitacao']});
		}
	}

	refreshDescartarModal = (e: any) => {
		e = e || window.event;
		if (this.formchanged && this.allowRefresh) {
			if (e.keyCode === 116) {
				e.preventDefault();
				let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
				confirm.result.then((): void => {
					this.allowRefresh = false;
					window.location.reload();
				})
					.catch((error: any): void => {/* */ });
			}
		}
	}

	exitScreenDescartarModal() {
		this.$transitions.onBefore({}, (trans: any) => {
			let d = this.$q.defer();
			if (trans._targetState._identifier !== 'meurh_solicitacoescreditodesconto_edit' && this.allowRefresh && this.formchanged) {
				let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
				confirm.result.then((): void => {
					this.allowRefresh = false;
					let modalConfirmed = true;
					if (modalConfirmed) {
						d.resolve();
					} else {
						d.reject();
					}
				})
					.catch(function () { /**/ });
			} else {
				d.resolve();
			}
			return d.promise;
		});
	}
}
