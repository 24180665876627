import angular from 'angular';
import { IEntity } from '../models/IEntity.model';
import { MeurhSolicitacoesadiantamentosavulsosService } from '../solicitacoesadiantamentosavulsos.service';

export class MeurhSolicitacoesadiantamentosavulsosFormNewController {
  static $inject = ['$scope', '$stateParams', '$state', 'MeurhSolicitacoesadiantamentosavulsosService', 'entity', 'NewToaster', 'ModalConfirmService', '$q', '$transitions'];
  public action: string = 'insert';
  public form;
  public constructors = {};
  public allowRefresh: boolean = false;
  public formchanged: boolean = false;
  constructor(public $scope: angular.IScope, public $stateParams: angular.ui.IStateParamsService, public $state: angular.ui.IStateService, public entityService: MeurhSolicitacoesadiantamentosavulsosService, public entity: IEntity & { $$__submitting: boolean }, public NewToaster: { pop: Function }, public ModalConfirmService: any, public $q: any, public $transitions: any) {

    for (var i in $stateParams) {
      if (i !== 'entity') {
        this.constructors[i] = $stateParams[i] ? $stateParams[i] : '';
      }
    }

    if (this.entity.anocompetencia === undefined) {
      this.entity.anocompetencia = (new Date()).getFullYear();
    }
    if (this.entity.mescompetencia === undefined) {
      this.entity.mescompetencia = (new Date().getMonth() + 1).toString();
    }

    this.$scope.$watch('mrh_slctcsdntmntsvlss_frm_nw_cntrllr.entity', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.formchanged = true;
      }
    }, true);
  }

  $onInit() {
    this.onSubmitSuccess();
    this.onSubmitError();
    this.exitScreenDescartarModal();
    document.onkeydown = this.refreshDescartarModal;
    this.allowRefresh = true;
  }

  onSubmitError() {
    this.onSubmitError = this.$scope.$on('meurh_solicitacoesadiantamentosavulsos_submit_error', (event, args) => {
      if (args.response.status === 409) {
        if (confirm(args.response.data.message)) {
          this.entity[''] = args.response.data.entity[''];
          this.entityService.save(this.entity, {
            empresa: this.entity.trabalhador.empresaobj.empresa,
            estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
          });
        }
      } else {
        if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
          if (args.response.data.message === 'Validation Failed') {
            let message = '';
            let children = args.response.data.errors.children;
            for (let child in children) {
              if (children[child].errors !== undefined) {
                for (let error in children[child].errors) {
                  if (children[child].errors[error] !== undefined) {
                    message += '<li>' + children[child].errors[error] + '</li>';
                  }
                }
              }
            }
            this.NewToaster.pop(
              {
                type: 'error',
                title: 'Erro de Validação',
                body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                bodyOutputType: 'trustedHtml'
              });
          } else {
            this.NewToaster.pop(
              {
                type: 'error',
                title: args.response.data.message
              });
          }
        } else {
          this.NewToaster.pop(
            {
              type: 'error',
              title: 'Ocorreu um erro ao enviar a Solicitação de Adiantamento Avulso!'
            });
        }
      }
    });
  }

  onSubmitSuccess() {
    this.onSubmitSuccess = this.$scope.$on('meurh_solicitacoesadiantamentosavulsos_submitted', (event, args) => {
      this.NewToaster.pop({
        type: 'success',
        title: 'Adiantamento avulso enviado',
        body: 'O adiantamento avulso foi enviado com sucesso.'
      });
      this.entityService.constructors.solicitacao = args.entity.solicitacao;
      this.$state.go('meurh_solicitacoesadiantamentosavulsos', angular.extend(this.entityService.constructors));
    });
  }

  submit() {
    if (this.form.$valid && !this.entity.$$__submitting) {
      this.allowRefresh = false;
      this.entityService.save(this.entity, {
        empresa: this.entity.trabalhador.empresaobj.empresa,
        estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
      });
    } else {
      this.NewToaster.pop({
        type: 'error',
        title: 'Alguns campos do formulário apresentam erros.'
      });
    }
  }

  buttonDescartarModal() {
      if (this.formchanged) {
        let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
        confirm.result.then((): void => {
            this.allowRefresh = false;
            this.$state.go('meurh_solicitacoesadiantamentosavulsos', angular.extend(this.entityService.constructors));
        })
        .catch((error: any): void => {/* */});
      } else {
        this.$state.go('meurh_solicitacoesadiantamentosavulsos', angular.extend(this.entityService.constructors));
      }
  }

  refreshDescartarModal = (e: any) =>  {
    e = e || window.event;
    if (this.formchanged && this.allowRefresh) {
      if (e.keyCode === 116) {
        e.preventDefault();
        let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
        confirm.result.then((): void => {
          this.allowRefresh = false;
          window.location.reload();
        })
        .catch((error: any): void => {/* */});
      }
    }
  }

  exitScreenDescartarModal() {
    this.$transitions.onBefore({}, (trans: any) => {
      let d = this.$q.defer();
      if (trans._targetState._identifier !== 'meurh_solicitacoesadiantamentosavulsos_new' &&  this.allowRefresh && this.formchanged) {
        let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
        confirm.result.then((): void => {
          this.allowRefresh = false;
          let modalConfirmed = true;
          if (modalConfirmed) {
            d.resolve();
          } else {
            d.reject();
          }
        })
        .catch(function () { /**/ });
      } else {
        d.resolve();
      }
      return d.promise;
    });
  }
}
