import angular from 'angular';
import { MeurhEscalaImportacaoController } from './escala.importacao.controller';

export class MeurhEscalaImportacaoComponent implements angular.IComponentOptions {
	static selector = 'meurhEscalaImportacaoComponent';
	static controller = MeurhEscalaImportacaoController;
	static template = require('!!html-loader!./escala.importacao.html');
	static transclude = true;
	static bindings = {
        busy: '=',
        lotacoes: '=',
        horarios: '=',
        empresas: '=',
        estabelecimentos: '=',
        estaImportando: '=',
        schedulerEventos: '=',
        erroImportacao: '=',
        listaDeEventos: '=',
        listaDeEventosBkp: '=',
        datasLimites: '=',
        colaboradores: '='
	};
}
