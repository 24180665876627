import * as angular from 'angular';
import { ColaboradorController } from './colaborador.controller';


export class ColaboradorComponent implements angular.IComponentOptions {
  static selector = 'colaboradorComponent';
  static controller = ColaboradorController;
  static template = require('!!html-loader!./colaborador.html');
  static transclude = true;
  static bindings = {
    entity: '<',
    form: '<',
    busy: '=',
    action: '<',
    identifier: '=',
    showTipoData: '<'
    };
}
