export class MeurhApontamentosGradeModalSalvarService {
    static $inject = ['$uibModal', '$http', '$q', 'nsjRouting'];

    public carregandoMovimentosTipoSempre: any;

    constructor(
        public $uibModal: any,
        public $http: any,
        public $q: any,
        public nsjRouting: any) { }

    open(formData: any, getRubrica: void, trabalhadores: any, movimentosTipoSempre: any) {
        return this.$uibModal.open({
            template: require('html-loader!./modal.salvar.html'),
            controller: 'MeurhApontamentosGradeModalSalvarController',
            resolve: {
                movimentosTipoSempre: () => {
                    if (movimentosTipoSempre) {
                        return movimentosTipoSempre;
                    }
                    this.carregandoMovimentosTipoSempre = true;
                    let url = this.nsjRouting.generate('apontamentos_movimentos_sempre', {}, true);
                    return this.$q((resolve: any, reject: any) => {
                        this.$http.get(url)
                            .then((response: any) => {
                                movimentosTipoSempre = response.data;
                                resolve(movimentosTipoSempre);
                            })
                            .catch((response: any) => {
                                // todo
                            }).finally(() => {
                                this.carregandoMovimentosTipoSempre = false;
                            });
                    });
                },
                formData: (): any => formData,
                getRubrica: (): any => getRubrica,
                trabalhadores: (): any => trabalhadores
            },
            controllerAs: 'self',
            windowClass: '',
        });
    }
}
