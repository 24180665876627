import angular from 'angular';
import { IAdicionarEscalaForm } from '../../../models/escala.new.model';

export class MeurhEscalaFolgaFormController {

    static $inject = ['$scope', '$rootScope', '$state'];

    public form: angular.IFormController;
    public entity: IAdicionarEscalaForm;
	public diasRequired: boolean = true;

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService,
		public $state: angular.ui.IStateService,
	) {
        $scope.$watch('$ctrl.entity.folga.diastrabalhados', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.setValidacaoDias(newValue, 'folgadt');
			}
		}, true);

        $scope.$watch('$ctrl.entity.folga.diasfolgados', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.setValidacaoDias(newValue, 'folgadf');
			}
		}, true);

		$scope.$watch('$ctrl.entity.folga.dias', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.dias.$setDirty();
				if (!this.temDiasMarcados(newValue)) {
					this.diasRequired = true;
				} else {
					this.diasRequired = false;
				}
			}
		}, true);
    }

	$onInit(): void {
		this.entity.folga = <IAdicionarEscalaForm['folga']> {
            dias: [
				{
					id: 'folga-dom',
					dia: 'Dom',
					marcado: false,
				}, {
					id: 'folga-seg',
					dia: 'Seg',
					marcado: false,
				}, {
					id: 'folga-ter',
					dia: 'Ter',
					marcado: false,
				}, {
					id: 'folga-qua',
					dia: 'Qua',
					marcado: false,
				}, {
					id: 'folga-qui',
					dia: 'Qui',
					marcado: false,
				}, {
					id: 'folga-sex',
					dia: 'Sex',
					marcado: false,
				}, {
					id: 'folga-sab',
					dia: 'Sáb',
					marcado: false,
				},
			]
        };
	}

    setDiasTrabalhados(event: angular.IAngularEvent & { detail: {value: number, error: boolean} }): void {
		this.entity.folga.diastrabalhados = event.detail.value;
    }

    setDiasFolgados(event: angular.IAngularEvent & { detail: {value: number, error: boolean} }): void {
		this.entity.folga.diasfolgados = event.detail.value;
    }

	setValidacaoDias(valor: any, campo: string): void {
		this.form[campo].$setDirty();
		if (valor <= 0 || isNaN(valor)) {
			this.form[campo].$setValidity('diasInvalido', false);
		} else {
			this.form[campo].$setValidity('diasInvalido', true);
		}
	}

	private temDiasMarcados(dias: IAdicionarEscalaForm['folga']['dias']): boolean {
		return dias.some((dia: any) => dia.marcado === true);
	}
}
