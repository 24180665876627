import angular = require('angular');
import moment = require('moment');
import { CalendarOption } from '../../../../../shared/components/nsj-calendar-options/calendaroption';
import { IFerias } from '../../models/ferias.model';
import { MeurhCalendarioFeriasModalService } from './calendarioferias.modal.service';
import { IFilter } from '../../../../../shared/models/filter.model';
import { ICalendarioFerias } from '../../CalendarioFerias/models/calendarioferias.model';
import { MeurhCalendarioFeriasService } from '../../CalendarioFerias/calendarioferias.service';

export class MeurhCalendarioFeriasModalController implements ng.IController {
	static $inject = ['NewToaster', '$uibModalInstance', 'entity', 'draftEntities', 'selectedEntity', 'MeurhCalendarioFeriasModalService', 'MeurhCalendarioFeriasService', '$location', 'nsjRouting', '$rootScope', '$scope', '$http'];

	public entities: Array<ICalendarioFerias>;
	public mesAtual: Date = new Date();
	public checkboxTodos: boolean = false;
	public carregouParte: boolean = false;
	public carregouTudo: boolean = false;
	public busy: boolean = false;
	public calendarOptions: CalendarOption = new CalendarOption();
	public currentColorObject: number = 0;
	public calendarView: string;
	public calendarEvents: Array<any> = [];
	public constructors: any;
	public filters: IFilter;

	constructor(
		public NewToaster: any,
		public $uibModalInstance: any,
		public entity: Array<ICalendarioFerias>,
		public draftEntities: Array<IFerias & { bloqueiadiasanteriores: moment.Moment, identificador: string }>,
		public selectedColaborador: string,
		public modalService: MeurhCalendarioFeriasModalService,
		public entityService: MeurhCalendarioFeriasService,
		public $location: any,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $scope: angular.IScope & { cores: any },
		public $http: angular.IHttpService
	) {
		this.busy = true;

		let colaborador: any = entity.filter((trabalhador) => trabalhador.trabalhador === selectedColaborador);

		this.entityService.filter = '';
		this.constructors = entityService.constructors = [];
		this.entities = entityService.reload();

		this.$scope.cores = [];

		$scope.$on('meurh_solicitacoesferias_calendario_list_finished', (event: angular.IAngularEvent, args: any): void => {
			this.entities = args.filter((entity: ICalendarioFerias) => entity.ferias.length > 0);

			if (colaborador) {
				colaborador.map((trabalhador) => this.entities.push(trabalhador));
			}

			this.getFotos();
			this.busy = false;
			this.carregouTudo = true;
			this.checkboxTodos = this.entities.length ? true : false;

			this.checkboxEntidades();
		});
	}

	atualizaEventosCalendario(entity: ICalendarioFerias, index?: number): void {
		if (!entity.selecionado) {
			this.calendarEvents = this.calendarEvents.filter((obj: any) => obj.object !== index);
			this.$scope.cores[index] = { 'background-color': 'white' };
			this.checkboxTodos = false;

			return;
		}

		this.adicionaEventoCalendario(entity, index);
	}

	adicionaEventoCalendario(entity: ICalendarioFerias, index: number): void {
		let trabalhador: any = this.calendarEvents.filter((event: any) => event.trabalhador === entity.trabalhador);

		if (trabalhador.length) {
			entity.selecionado = true;
			return;
		}

		let label = '';
		let startDate = '';
		let endDate = '';

		// ao chegar no final do array de cores, a cor atual é resetada para a primeira cor do array
		if (this.currentColorObject < this.calendarOptions.colors.length) {
			this.currentColorObject++;
		} else {
			this.currentColorObject = 0;
		}

		entity.ferias.forEach((ferias: Partial<IFerias>): void => {
			trabalhador = entity.trabalhador;
			label = entity.nome;
			startDate = ferias.datainiciogozo.replace(/-/g, '/');
			endDate = ferias.datafimgozo.replace(/-/g, '/');

			let evento = {
				object: index,
				trabalhador: trabalhador,
				bgColor: this.calendarOptions.colors[this.currentColorObject],
				label: label,
				startDate: new Date(startDate),
				endDate: new Date(endDate),
			};

			this.$scope.cores[index] = {
				'background-color': this.calendarView === 'mes' ? evento.bgColor : '#B4C876',
				'border-color': this.calendarView === 'mes' ? evento.bgColor : '#B4C876',
			};

			this.calendarEvents = [...this.calendarEvents, evento];
		});

		let draft = this.draftEntities.filter((draftEntity: any) => draftEntity.trabalhador === entity.trabalhador);

		draft.forEach((draft: any) => {
			trabalhador = draft.trabalhador;
			label = draft.nome;
			startDate = draft.datainiciogozo.replace(/-/g, '/');
			endDate = draft.datafimgozo.replace(/-/g, '/');

			let evento = {
				object: index,
				trabalhador: trabalhador,
				bgColor: this.calendarOptions.colors[this.currentColorObject],
				label: label,
				startDate: new Date(startDate),
				endDate: new Date(endDate),
			};

			this.$scope.cores[index] = {
				'background-color': this.calendarView === 'mes' ? evento.bgColor : '#B4C876',
				'border-color': this.calendarView === 'mes' ? evento.bgColor : '#B4C876',
			};

			this.calendarEvents = [...this.calendarEvents, evento];
		});

		if (!this.checkboxTodos) {
			let checkboxDesmarcados = this.entities.filter((entity) => entity.selecionado === false);
			this.checkboxTodos = !checkboxDesmarcados.length;
		}
	}

	removeEventoCalendario(index: number): void {
		this.calendarEvents = this.calendarEvents.filter((obj: any) => obj.object !== index);
		this.$scope.cores[index] = { 'background-color': 'white' };
	}

	removeDuplicados(array: Array<any>): Array<any> {
		let arr = array.reduce((r, i) =>
			!r.some((j: any) => JSON.stringify(i) === JSON.stringify(j)) ? [...r, i] : r, []);
		return arr;
	}

	returnCalendarEvents(): Array<any> {
		return this.calendarEvents;
	}

	getFotos(): void {
		this.entities.forEach((entity: ICalendarioFerias): void => {
			if (entity.foto) {
				this.$http.get(entity.foto, { responseType: 'arraybuffer' }).then((response: any) => {
					let mimeType = response.headers('Content-Type');
					var file = new Blob([response.data], { type: mimeType });
					var fileUrl = URL.createObjectURL(file);
					entity.foto_url = fileUrl;
					entity.semFoto = false;
				});
			} else {
				entity.semFoto = true;
			}
		});
	}

	checkboxEntidades(acao: boolean | null = null) {
		this.calendarEvents = [];

		angular.forEach(this.entities, (entity, index): void => {
			if (acao == null) {
				entity.selecionado = this.checkboxTodos ? true : false;
				entity.selecionado ? this.atualizaEventosCalendario(entity, index) : this.removeEventoCalendario(index);
			} else {
				entity.selecionado = acao;
			}
		});

		if (acao != null) {
			this.checkboxTodos = acao ? true : false;
		}
	}

	search(): Array<ICalendarioFerias> {
		if (this.entityService.filter !== '') {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
		} else {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
		}

		var filter = {
			search: this.entityService.filter,
			filters: angular.copy(this.entityService.filters)
		};

		this.busy = true;
		this.entities = [];

		return this.entityService.search(filter);
	}

	reload(): Array<ICalendarioFerias> {
		this.busy = true;
		this.entities = [];
		return this.entityService.reload();
	}

	loadMore(): void {
		this.busy = true;
		this.entityService.loadMore();
	}

	close(): void {
		this.$uibModalInstance.dismiss();
	}

	calendarViewChanged($event: any): void {
		this.calendarView = $event.detail.view;

		this.$scope.cores.forEach((cor: any, index: any, arr: any) => {
			let evento = this.calendarEvents.find(ev => ev.object === index);

			if (evento) {
				if (this.calendarView === 'mes') {
					this.$scope.cores[index] = {
						'background-color': evento.bgColor,
						'border-color': evento.bgColor,
					};
				} else {
					this.$scope.cores[index] = {
						'background-color': '#B4C876',
						'border-color': '#B4C876',
					};
				}
			} else {
				this.$scope.cores[index] = {
					'background-color': '#FFF',
					'border-color': '#C0C0C0',
				};
			}
		});
	}
}
