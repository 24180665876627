declare var nsjGlobals: any;
import angular from 'angular';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { MeurhAfastamentosService } from '../afastamentos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IAfastamento } from '../models/afastamento.model';

export class MeurhAfastamentosEditController implements angular.IController {
	static $inject = [
		'$scope',
		'$stateParams',
		'$state',
		'MeurhAfastamentosService',
		'entity',
		'NewToaster',
		'$rootScope',
		'TiposDocumentosRequeridosService',
		'ModalConfirmService',
		'$q',
		'$transitions'
	];

	public action: string = 'update';
	public editanexo: boolean = true;
	public alterouanexo: boolean = false;
	public form: angular.IFormController;
	public formAnexo: object = {};
	public entityAnexo: IAnexo = {
		casodeuso: 'AFASTAMENTO'
	};
	public actionAnexo: string = 'update';
	public busy: boolean = false;
	public busyTipoDocumentos: boolean = true;
	public tiposdocumentosrequeridos: Array<any>;
	public validate: number = 0; // conta quantos erros há em documentos anexos
	public entitySave: Partial<IAfastamento> = {};
	public allowRefresh: boolean = false;
	public formchanged: boolean = false;

	public clienteAna: boolean = nsjGlobals.get('clienteana');
	public afastamentoOrigemObj;

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhAfastamentosService,
		public entity: IAfastamento,
		public NewToaster: any,
		public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
		public ModalConfirmService: any,
		public $q: angular.IQService,
		public $transitions: any
	) {
		$scope.$watch('mrh_afstmts_frm_edit_cntrllr.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.$setDirty();
			}
		}, true);

		this.onSubmitSuccess();
		this.onSubmitError();
		this.exitScreenDescartarModal();
		document.onkeydown = this.refreshDescartarModal;
		this.allowRefresh = true;
	}


	$onInit(): void {
		this.entity.datas = this.entity.data;
		this.documentosAnexos();

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});
	}

	documentosAnexos(): void {
        const estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
        this.setEntityAnexo({
            solicitacao: this.entity.solicitacao,
            estabelecimento
        });
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.carregaTiposDocumentosRequeridos({
                empresa: this.entity.empresaobj.empresa,
                estabelecimento
            });
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
    }

    carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.AFASTAMENTO,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

	resetTiposDocumentosRequeridosService(): void {
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
		this.TiposDocumentosRequeridosService.entities = [];
	}

	setEntityAnexo(params: { solicitacao: string, estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
        this.entityAnexo['solicitacao'] = params.solicitacao;
    }

	submit(rascunho: boolean = false): void {
        if (this.form.$valid && !this.entity.$$__submitting) {
            this.entitySave = angular.copy(this.entity);
			if (rascunho === false) {
				this.entitySave.situacao = 0;
			}
			this.entitySave.trabalhador = this.entity.trabalhador.trabalhador;
			this.allowRefresh = true;

			if (this.clienteAna) {
				if (this.entity.afastamentoorigem) {
					this.afastamentoOrigemObj = angular.copy(this.entity.afastamentoorigem);
					this.entitySave.afastamentoorigem = this.entity.afastamentoorigem.afastamentotrabalhador;

					let combinacaoValidaMotivoAfastamentoOrigem: boolean = this.validaCombinacaoMotivoAfastamentoOrigem(this.entity.tipohistorico, this.afastamentoOrigemObj.tipohistorico.tipohistorico);

					if (!combinacaoValidaMotivoAfastamentoOrigem) {
						this.NewToaster.pop({
							type: 'error',
							title: 'O afastamento origem é de motivo diferente da prorrogação solicitada.'
						});

						this.busy = false;
						return;
					}

				} else {
					this.entitySave.afastamentoorigem = null;
				}
			}

			this.entityService.save(this.entitySave, {
				empresa: this.entity.empresaobj.empresa,
				estabelecimento: this.entity.estabelecimentoobj.estabelecimento
			});
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros.'
            });
        }
	}

    verificaObj(): boolean {
        if (
            this.valorExiste(this.entity.trabalhador) &&
            this.valorExiste(this.entity.datainicio) &&
            (this.valorExiste(this.entity.diasafastamento) && this.entity.diasafastamento !== 0) &&
            this.valorExiste(this.entity.descricao) &&
            this.valorExiste(this.entity.tipohistorico)
        ) {
            return false;
        } else {
            return true;
        }

    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});
			let updateSuccess = 'A Solicitação de Afastamento foi editada com sucesso.';
            if (this.tiposdocumentosrequeridos.length <= 0 || !this.alterouanexo) {
                this.NewToaster.pop({
                    type: 'success',
                    title: updateSuccess
                });
                this.$state.go('meurh_afastamentos_show', {'solicitacao': args.entity.solicitacao});
            } else {
                this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                    if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
                        this.NewToaster.pop({
                            type: 'success',
                            title: updateSuccess
                        });
                        this.$state.go('meurh_afastamentos_show', {'solicitacao': args.entity.solicitacao});
                    }
                });
            }
		});
	}

	onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
				if (
                    args.response.data &&
                    typeof (args.response.data.message) !== 'undefined' &&
                    args.response.data.message
                ) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;
                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
                                    if (children[child].errors.hasOwnProperty(error)) {
                                        message += '<li>' + children[child].errors[error] + '</li>';
                                    }
                                }
                            }
                        }
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
						type: 'error',
						title: 'Ocorreu um erro ao editar a Solicitação de Afastamento!'
					});
                }
            }
        });
	}

	formChange(): boolean {
        let existeDocumento = this.tiposdocumentosrequeridos.some((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === true;
        });
        if (this.valorExiste(this.entity.trabalhador) ||
            this.valorExiste(this.entity.datainicio) ||
            (this.valorExiste(this.entity.diasafastamento) && this.entity.diasafastamento !== 0) ||
            this.valorExiste(this.entity.descricao) ||
            this.valorExiste(this.entity.observacao) || existeDocumento) {
                this.formchanged = true;
        }
        return this.formchanged;
    }

	buttonDescartarModal(): void {
		if (this.formChange()) {
			let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', false);
			confirm.result.then((): void => {
				this.allowRefresh = false;
				this.$state.go('meurh_afastamentos_show',
					angular.extend({}, {
						'solicitacao': this.$stateParams['solicitacao']
					})
				);
			}).catch((error: any): void => {/**/});
		} else {
			this.$state.go('meurh_afastamentos_show',
				angular.extend({}, {
					'solicitacao': this.$stateParams['solicitacao']
				})
			);
		}
	}

	refreshDescartarModal = (e: any): void => {
		e = e || window.event;
		if (this.formChange() && this.allowRefresh) {
			if (e.keyCode === 116) {
				e.preventDefault();
				let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
				confirm.result.then((): void => {
					this.allowRefresh = false;
					window.location.reload();
				}).catch((error: any): void => {/**/});
			}
		}
	}

	exitScreenDescartarModal(): void {
		this.$transitions.onBefore({}, (trans: any) => {
			let d = this.$q.defer();
			if (
				trans._targetState._identifier !== 'meurh_solicitacoesafastamentos_edit' &&
				this.allowRefresh &&
				this.formChange()
			) {
				let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
				confirm.result.then((): void => {
					this.allowRefresh = false;
					let modalConfirmed = true;
					if (modalConfirmed) {
						d.resolve();
					} else {
						d.reject();
					}
				}).catch(function () {/**/});
			} else {
				d.resolve();
			}
			return d.promise;
		});
	}

	/**
	 * Método que verifica se a combinação entre motivo do afastamento e afastamento origem é válida
	 * @param tipoHistorico Tipo de histórico selecionado no motivo
	 * @param afastamentoOrigem O tipo de histórico do afastamento origem
	 * @returns Booleano que informa se combinação é válida
	 */
	validaCombinacaoMotivoAfastamentoOrigem(tipoHistorico: string, afastamentoOrigem: string) {

        if ((tipoHistorico === '002' && afastamentoOrigem === '001') || (tipoHistorico === '004' && afastamentoOrigem === '003')) {
            return true;
        } else {
            return false;
        }

    }

}
