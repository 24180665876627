import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesvtsService } from './solicitacoesalteracoesvts.service';

export const MeurhSolicitacoesalteracoesvtsListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        let resolve = {
            'entity': [
                'MeurhSolicitacoesalteracoesvtsService',
                '$stateParams',
                '$state',
                '$q',
                function (
                    entityService: MeurhSolicitacoesalteracoesvtsService,
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ): angular.ui.IStateParamsService | angular.IQService | {} {
                    if ($stateParams['entity']) {
                        return $stateParams['entity'];
                    }

                    if (!$stateParams['solicitacao']) {
                        return {};
                    }

                    return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
                        entityService.get($stateParams['solicitacao'])
                            .then((data: any): void => {
                                resolve(data);
                            })
                            .catch((error: any): void => {
                                if (error.status === 404) {
                                    if ($state.href('meurh_solicitacoesalteracoesvts_not_found', $stateParams)) {
                                        $state.go('meurh_solicitacoesalteracoesvts_not_found', $stateParams);
                                    } else {
                                        $state.go('not_found', $stateParams);
                                    }
                                }
                            });
                    });
                }
            ]
        };

        $stateProvider
            .state('meurh_solicitacoesalteracoesvts', {
                url: '/solicitacoes/alteracoes-vts?q?estabelecimento?tipo',
                template: require('html-loader!./index/solicitacoesalteracoesvts.index.html'),
                controller: 'Meurh\SolicitacoesalteracoesvtsIndexController',
                controllerAs: 'mrh_slctcsltrcsvts_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('meurh_solicitacoesalteracoesvts_new', {
                parent: 'meurh_solicitacoesalteracoesvts',
                url: '/new',
                resolve: resolve,
                template: require('html-loader!./new/solicitacoesalteracoesvts.new.html'),
                controller: 'MeurhSolicitacoesalteracoesvtsNewController',
                controllerAs: 'mrh_slctcsltrcsvts_frm_nw_cntrllr'
            })
            .state('meurh_solicitacoesalteracoesvts_show', {
                parent: 'meurh_solicitacoesalteracoesvts',
                url: '/:solicitacao?path',
                resolve: resolve,
                template: require('html-loader!./show/solicitacoesalteracoesvts.show.html'),
                controller: 'MeurhSolicitacoesalteracoesvtsShowController',
                controllerAs: 'mrh_slctcsltrcsvts_frm_shw_cntrllr'
            })
            .state('meurh_solicitacoesalteracoesvts_edit', {
                parent: 'meurh_solicitacoesalteracoesvts',
                url: '/:solicitacao/edit',
                resolve: resolve,
                template: require('html-loader!./edit/solicitacoesalteracoesvts.edit.html'),
                controller: 'MeurhSolicitacoesalteracoesvtsEditController',
                controllerAs: 'mrh_slctcsltrcsvts_frm_edit_cntrllr'
            });
    }
];
