import * as angular from 'angular';
import { MeurhSolicitacoesadmissoesFormController } from './solicitacoesadmissoes.form.controller';

export class MeurhSolicitacoesadmissoesFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesadmissoesFormComponent';
	static controller = MeurhSolicitacoesadmissoesFormController;
	static template = require('!!html-loader!./solicitacoesadmissoes.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<',
		salarioValido: '=',
	};
}
