import angular from 'angular';
import { MeurhAdmissoesDadoscontatoFormController } from './dadoscontato.form.controller';

export class MeurhAdmissoesDadoscontatoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesDadoscontatoFormComponent';
	static controller = MeurhAdmissoesDadoscontatoFormController;
	static template = require('!!html-loader!./dadoscontato.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
		emailobrigatorio: '='
	};
}
