import angular from 'angular';
import { MeurhMudancaHorarioService } from '../mudancahorario.service';
import { IMudancaHorario } from '../models/mudancahorario.model';

export class MeurhMudancaHorarioNewController {
    static $inject = ['$rootScope', '$scope', '$state', 'MeurhMudancaHorarioService', 'ModalConfirmService', 'NewToaster', 'entity', '$q', '$transitions'];

    public action: string = 'insert';
	public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: Partial<IMudancaHorario> = {};
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;

    constructor(
        public $rootScope: angular.IRootScopeService & { session: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public entityService: MeurhMudancaHorarioService,
        public ModalConfirmService: any,
        public NewToaster: any,
        public entity: IMudancaHorario,
        public $q: any,
        public $transitions: any
    ) {
        this.$scope.$watch('mrh_mdnchr_frm_nw_cntrllr.entity', (newValue, oldValue) => {
            if (newValue !== oldValue) {
              this.formchanged = true;
            }
          }, true);

        this.onSubmitSuccess();
        this.onSubmitError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    verificaObj(): boolean {
        if (this.valorExiste(this.entity.trabalhador) &&
			this.valorExiste(this.entity.data) &&
			this.valorExiste(this.entity.horario) &&
            this.form.$valid) {
            return false;
        } else {
            return true;
        }
    }

    createEntity(): void {
        this.entitySave.situacao = 0;
        this.entitySave.trabalhador = this.entity.trabalhador;
        this.entitySave.estabelecimento = this.entity.trabalhador!.estabelecimentoobj.estabelecimento;
        this.entitySave.data = this.entity.data;
        this.entitySave.horario = this.entity.horario;
    }

    salvar(): void {
        if (this.form.$valid && !this.entity.$$__submitting) {
            this.busy = true;
            this.allowRefresh = false;
            this.createEntity();
            this.entityService.save(this.entitySave, {
                empresa: this.entity.trabalhador!.empresaobj.empresa,
                estabelecimento: this.entity.trabalhador!.estabelecimentoobj.estabelecimento
            });
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros.'
            });
        }
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesmudancahorario_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A mudança de horário foi enviada com sucesso!'
            });
            this.$state.go('meurh_mudancahorario_show', {'solicitacao': args.entity.solicitacao});
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesmudancahorario_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else if (args.response.data && typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                if (args.response.data.message === 'Validation Failed') {
                    let message = args.response.data.errors.errors[0];

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de Validação',
                        body: message,
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a mudança de horário!'
                });
            }
        });
    }

    valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

    buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_mudancahorario', angular.extend(this.entityService.constructors));
            })
            .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_mudancahorario', angular.extend(this.entityService.constructors));
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoesmudancahorario_new' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
