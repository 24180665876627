import angular from 'angular';
import { MeurhAdmissoesDocumentosanexosFormController } from './documentosanexos.form.controller';

export class MeurhAdmissoesDocumentosanexosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesDocumentosanexosFormComponent';
	static controller = MeurhAdmissoesDocumentosanexosFormController;
	static template = require('!!html-loader!./documentosanexos.form.html');
	static transclude = true;
	static bindings = {
		busy: '=',
		busyTipoDocumentos: '=',
		entity: '=',
		entityanexo: '=',
		file: '=?',
		action: '=?',
		mostrarAlerta: '=',
		tiposdocumentosrequeridos: '=',
		editanexo: '=',
		alterouanexo: '='
	};
}
