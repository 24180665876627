import angular from 'angular';
import { MeurhAdmissoesBeneficiosFormmodalController } from './beneficios.formmodal.controller';

export class MeurhAdmissoesBeneficiosFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesBeneficiosFormmodalComponent';
	static controller = MeurhAdmissoesBeneficiosFormmodalController;
	static template = require('!!html-loader!./beneficios.formmodal.html');
	static transclude = true;
	static bindings = {
		beneficio: '=',
		form: '<',
		busy: '=',
		action: '<',
		objadmissao: '=',
	};
}
