import angular from 'angular';
import { IAdicionarEscalaForm } from '../../../models/escala.new.model';

export class MeurhEscalaLotacaoHorarioFormController {

    static $inject = ['$scope', '$rootScope', '$state'];

    public form: angular.IFormController;
    public entity: IAdicionarEscalaForm;

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService,
		public $state: angular.ui.IStateService,
	) {
    }

    $onInit(): void {
        this.entity.lotacaohorario = <IAdicionarEscalaForm['lotacaohorario']> {
            dias: [
                {
                    dia: 'Domingo',
                }, {
                    dia: 'Segunda-feira',
                }, {
                    dia: 'Terça-feira',
                }, {
                    dia: 'Quarta-feira',
                }, {
                    dia: 'Quinta-feira',
                }, {
                    dia: 'Sexta-feira',
                }, {
                    dia: 'Sábado',
                },
            ]
        };
    }
}
