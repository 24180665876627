import * as angular from 'angular';
import { MeurhPromocoesPassozeroController } from './promocoes.passozero.controller';

export class MeurhPromocoesPassozeroComponent implements angular.IComponentOptions {
	static selector = 'meurhPromocoesPassozeroComponent';
	static controller = MeurhPromocoesPassozeroController;
	static template = require('!!html-loader!./promocoes.passozero.html');
	static transclude = true;
	static bindings = {
		passo: '=',
		entity: '=',
		mensagensPassos: '=',
		colaboradorSelecionado: '=',
		entityAnexo: '=',
	};
}
