import angular from 'angular';
import { conversaoFormatoData } from '../../../../../utils/utils';
import { IFerias } from '../../models/ferias.model';

export class MeurhFeriasMarcacoesShowFormController implements angular.IController {

  static $inject = [
    '$scope',
    '$rootScope',
    '$element',
    '$attrs',
    'NewToaster'
  ];

  public form: any = {};
  public entity: IFerias;
  public bloquearCompraDias = this.$rootScope.configuracoes['BLOQUEAR_ABONO_PECUNIARIO_MRH'];

  constructor(
    public $scope: any,
    public $rootScope: angular.IRootScopeService & {
      liberadoTela: (arg: string) => boolean,
      configuracoes: any,
    },
    public $element: any,
    public $attrs: any,
    public NewToaster: any
  ) { }

  $onInit(): void {
    this.entity.datainicioperiodoaquisitivo = conversaoFormatoData(this.entity.datainicioperiodoaquisitivo);
    this.entity.datafimperiodoaquisitivo = conversaoFormatoData(this.entity.datafimperiodoaquisitivo);
    this.entity.datainiciogozo = conversaoFormatoData(this.entity.datainiciogozo);
    this.entity.datafimgozo = conversaoFormatoData(this.entity.datafimgozo);
  }
}
