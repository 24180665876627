import angular from 'angular';
import { IGestoresTrabalhador, ITransferenciaColaboradores } from '../models/transferenciacolaboradores.model';
import { MeurhTransferenciaColaboradoresService } from '../transferenciacolaboradores.service';

export class MeurhTransferenciaColaboradoresEditController {

    static $inject = ['$scope', '$stateParams', '$state', 'MeurhTransferenciaColaboradoresService', 'entity', 'NewToaster', '$rootScope', '$transitions', 'ModalConfirmService', '$q'];

    public action: string = 'edit';
	public form: angular.IFormController;
	public constructors: any = [];
	public busy: boolean = false;
	public entitySave: Partial<ITransferenciaColaboradores> = {};
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhTransferenciaColaboradoresService,
		public entity: ITransferenciaColaboradores,
		public NewToaster: any,
		public $rootScope: angular.IRootScopeService,
        public $transitions: any,
        public ModalConfirmService: any,
        public $q: any
	) {
		$scope.$watch('mrh_trnsfrncclbrdrs_frm_edt_cntrllr.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.$setDirty();
			}
		}, true);

        this.onSubmitSuccess();
        this.onSubmitError();
	}

    verificarVaga() : void {
        if (!this.entity.vaga) {
            this.entity.vaga = {'vaga' : null};
        }  else {
            this.entity.vaga = this.entity.vaga;
        }
    }

    verificaGestores(): void {
        this.entitySave.gestores = [];
        this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
            if (gestor.gestor) {
                this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
            } else {
                this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
            }
        });
    }

    verificaObj(): boolean {
        if ((this.valorExiste(this.entity.datainicio)) && (this.valorExiste(this.entity.vaga) ||
            (this.valorExiste(this.entity.estabelecimentodestino) && this.valorExiste(this.entity.departamento) && this.valorExiste(this.entity.lotacao)))) {
            return false;
        } else {
            return true;
        }
    }

    submit(): void {
        if (this.form.$valid && !this.entity.$$__submitting) {
			this.busy = true;
            this.verificarVaga();
            this.entitySave = angular.copy(this.entity);
            this.verificaGestores();
			this.entityService.save(this.entitySave, {
                empresa: this.entity.empresaobj.empresa,
                estabelecimento: this.entity.estabelecimentoobj.estabelecimento
            });
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros.'
            });
        }
	}

    onSubmitSuccess(): void {
		this.$scope.$on('meurh_solicitacoestransferenciasvagas_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A transferência foi editada com sucesso!'
            });
            this.$state.go('meurh_solicitacoestransferenciasvagas_show', {'solicitacao': args.entity.solicitacao});
		});
	}

	onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoestransferenciasvagas_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else if (args.response.data && typeof (args.response.data.message) !== 'undefined') {
                if (args.response.data.message === 'Validation Failed') {
                    let message = args.response.data.errors.errors[0];

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de Validação',
                        body: message,
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao editar a transferência!'
                });
            }
        });
	}


    buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoestransferenciasvagas_show',
				angular.extend({}, {
					'solicitacao': this.$stateParams['solicitacao']
				})
			);
            })
                .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_solicitacoestransferenciasvagas_show',
            angular.extend({}, {
                'solicitacao': this.$stateParams['solicitacao']
            })
        );
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                    .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoestransferenciasvagas_edit' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                    .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
