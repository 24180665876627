import angular = require('angular');
import { IControleFerias } from '../../ControleFerias/models/controleferias.model';

export class MeurhAvisoSaldoZeroModalService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: any) { }

	open(entities: Array<IControleFerias>): void {
		return this.$uibModal.open({
			template: require('html-loader!./avisosaldozero.modal.html'),
			controller: 'meurhAvisoSaldoZeroModalController',
			controllerAs: 'mrh_vssldzr_mdl_cntrllr',
			size: 'lg',
			resolve: {
				entities: async () => {
					return entities;
				},
			}
		});
	}
}
