import ng from 'angular';
import moment from 'moment';
import { IDadosModalRetornoAfastamento } from '../../../../Meurh/Solicitacoesafastamentosretorno/models/retornoAfastamento.model';

export class ModalAjustarRetornoController implements ng.IController {

    static $inject = ['entity', '$uibModalInstance'];

    public formData: ng.IFormController;
    public dataRetornoOriginalTratada: string;
    public dataAjusteRetorno: string;
    public dataAtual = moment();

    constructor(
        public entity: IDadosModalRetornoAfastamento,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    ) {
        this.dataRetornoOriginalTratada = this.conversaoData(this.entity.dataRetornoOriginal);
    }

    public confirmar(): void {
        this.$uibModalInstance.close(this.dataAjusteRetorno);
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }

    private conversaoData(data: string | moment.Moment): string {
        return moment(data).format('DD/MM/YYYY');
    }
}
