import angular = require('angular');
import moment = require('moment');

export class MeurhAdmissoesestagiarioObservacoesFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'Paises'];

    public entity: any;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public Paises: any,
    ) {

    }
}
