import angular from 'angular';
import moment from 'moment';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class RotatividadeIndexController {
    static $inject = ['$rootScope', '$scope', 'nsjRouting', '$http', 'NewToaster', 'RelatoriosService', 'RotatividadeService', '$state', '$stateParams', 'ListagemService'];

    public busy: any = false;
    public trabalhadores: any;
    public carregandoRelatorio: boolean;
    public modo_selecao: string;
    public competencia: any;
    public range: any = {
        datainicial: moment().startOf('month').format('YYYY-MM-DD'),
        datafinal: moment().endOf('month').format('YYYY-MM-DD')
    };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public filters: any = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
    };
    public listagemPermissao: Array<string> = ['relatorios_rotatividade'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor(
        public $rootScope: any,
        public $scope: any,
        public nsjRouting: any,
        public $http: any,
        public NewToaster: any,
        public RelatoriosService: any,
        public RotatividadeService: any,
        public $state: any,
        public $stateParams: any,
        public ListagemService: ListagemService
    ) {
        if (!$rootScope.temPermissao('relatorios_rotatividade')) {
            $state.go('dp', $stateParams);
        }

        this.montaListagemVisibilidade();
    }

    isBusy() {
        return this.RotatividadeService.loadParams.busy;
    }

    finished() {
        return this.RotatividadeService.loadParams.finished;
    }

    loadMore() {
        this.RotatividadeService.loadMore();
    }

    carregarRelatorio() {
        this.trabalhadores = [];
        if (this.validarData()) {
            let construtor = this.montarConstrutor();
            this.RotatividadeService.constructors = construtor;
            this.busy = true;
            this.trabalhadores = this.RotatividadeService.reload();
            this.busy = false;
        }
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        this.carregarRelatorio();
    }

    montarConstrutor(relatorio: any = false) {
        let construtor: any = {};
        construtor.empresa = this.filters.empresa;
        construtor.estabelecimento = this.filters.estabelecimento;
        construtor.relatorio = relatorio;
        if (this.modo_selecao === 'competencia') {
            if (this.competencia.mes === '00') {
                let data = moment({ year: this.competencia.ano });
                construtor.datainicial = data.startOf('year').format('YYYY-MM-DD');
                construtor.datafinal = data.endOf('year').format('YYYY-MM-DD');
            } else {
                let data = moment({ year: this.competencia.ano, month: (this.competencia.mes - 1) });
                construtor.datainicial = data.startOf('month').format('YYYY-MM-DD');
                construtor.datafinal = data.endOf('month').format('YYYY-MM-DD');
            }
        } else if (this.modo_selecao === 'range') {
            construtor.datainicial = angular.copy(this.range.datainicial);
            construtor.datafinal = angular.copy(this.range.datafinal);
        }
        return construtor;
    }

    abrirRelatorio() {
        this.carregandoRelatorio = true;
        let construtor: any = this.montarConstrutor(true);
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        this.RelatoriosService.abrirRelatorio('relatoriosliberados_rotatividade', construtor, callback);
    }

    validarData() {
        if (moment(this.range.datainicial) > moment(this.range.datafinal)) {
            return false;
        }
        if (!this.range.datainicial) {
            return false;
        }
        if (!this.range.datafinal) {
            return false;
        }
        return true;
    }
}
