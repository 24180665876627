import * as angular from 'angular';
import { ApuracoesPontoService } from './apuracoesponto.service';

export const PontoListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		let resolve = {
			'entity': [
				'ApuracoesPontoService',
				'$stateParams',
				'$state',
				'$q',
				function (
					entityService: ApuracoesPontoService,
					$stateParams: angular.ui.IStateParamsService,
					$state: angular.ui.IStateService,
					$q: angular.IQService
				): angular.ui.IStateParamsService | angular.IQService | {} {
					if ($stateParams['entity']) {
						return $stateParams['entity'];
					}

					if (!$stateParams['solicitacao']) {
						return {};
					}

					return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
						entityService.get($stateParams['solicitacao'])
							.then((data: unknown): void => {
								resolve(data);
							})
							.catch((error: any): void => {
								if (error.status === 404) {
									if ($state.href('ferias_marcacoes_not_found', $stateParams)) {
										$state.go('ferias_marcacoes_not_found', $stateParams);
									} else {
										$state.go('not_found', $stateParams);
									}
								}
							});
					});
				}
			]
		};

		$stateProvider
			.state('apuracoesponto', {
				url: '/ponto/apuracoes?q?situacao?estabelecimento',
				template: require('html-loader!./index/apuracoesponto.index.html'),
				controller: 'ApuracoesPontoIndexController',
				controllerAs: 'mrh_aprcspt_lst_cntrllr',
				reloadOnSearch: false
			});
	}
];
