import angular from 'angular';
import { LoadParams } from '../../../shared/models/loadparams.model';
import { IAfastamento } from './models/afastamento.model';

export class MeurhAfastamentosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entity: IAfastamento;
    public entities: Array<any> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams = new LoadParams();
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService) {}

    reload(): Array<IAfastamento> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any = undefined): Array<IAfastamento>  {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
             Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('meurh_solicitacoesafastamentos_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    load(): Array<IAfastamento> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                        this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                        this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                    }
                    if (data.length < 20) {
                    // if (data.length < 5) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }
                    this.loaded = true;

                    this.loadParams.to_load--;

                    if (this.loadParams.to_load === 0 || data.length <= 20) {
                    // if (this.loadParams.to_load === 0 || data.length <= 5) {
                        this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_loaded', this.entities);
                    }
                })
                .catch((error: any) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    delete($identifier: any, force: boolean): void {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['solicitacao'];
        }
        if ($identifier) {
            if (force || confirm('Tem certeza que deseja deletar?')) {
                this.loading_deferred = this.$q.defer();
                this.$http
                    .delete(this.nsjRouting.generate('meurh_solicitacoesafastamentos_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
                        { timeout: this.loading_deferred.promise })
                    .then((response: any) => {
                        this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_deleted', {
                            entity: this.entity,
                            response: response
                        });
                    })
                    .catch((response: any) => {
                        this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_delete_error', {
                            entity: this.entity,
                            response: response
                        });
                    });
            }
        }
    }

    _save(entity: any,
        params: { empresa: string, estabelecimento: string },
        autosave: boolean): angular.IHttpPromise<any> {
        let method: string, url: string;
        if (entity['solicitacao']) {
            method = 'PUT';
            url = this.nsjRouting.generate('meurh_solicitacoesafastamentos_put', {
                id: entity['solicitacao'],
                ...params
            }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_solicitacoesafastamentos_create',
                angular.extend({}, this.constructors, params),
            true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        let data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        }).finally(() => {
            if (!autosave) {
                entity['$$__submitting'] = false;
            }
        });
    }

    save(entity: any,
        params: { empresa: string, estabelecimento: string },
        autosave: boolean = false): void {
        this._save(entity, params, autosave)
            .then((response: any) => {
                if (response.data.solicitacao) {
                    entity['solicitacao'] = response.data.solicitacao;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any) => {
                this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('meurh_solicitacoesafastamentos_get', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    loadNoPagination(params: {
        datainicio: string,
        datafim: string,
        empresa: string,
		trabalhador_estabelecimento?: string
    }): any {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        const urlParams = {
			estabelecimento: '',
			...params
		};

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
           this.$http
                .get(this.nsjRouting.generate(
                    'persona_afastamentos_indexsempaginacao', urlParams, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any): void => {
                    this.$rootScope.$broadcast('meurh_solicitacoesafastamentos_sempaginacao_loaded', response.data);
                    this.loadParams.busy = false;

                    resolve(response.data);
                })
                .catch((response: any): void => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }
}
