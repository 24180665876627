// cores dos eventos do calendario
export enum EventoCorEnum {
	FOLGA = '#E4B8FF',
	FOLGAFIXA = '#00CC3A',
	AFASTAMENTO = '#FFC266',
	LOTACAO = '#00C1CC',
	HORARIO = '#88CBF7',
	FERIADO = '#6A187F'
}
export enum EventoImportadoCorEnum {
	FOLGA = '#CF80FF99',
	FOLGAFIXA = '#00CC3A99',
	AFASTAMENTO = '#FFC26699',
	LOTACAO = '#00C1CC99',
	HORARIO = '#88CBF799',
	FERIADO = '#6A187F99'
}
// cores dos textos dos eventos do calendario
export enum EventoCorTextoEnum {
	FOLGA = '#363638',
	FOLGAFIXA = '#363638',
	AFASTAMENTO = '#363638',
	LOTACAO = '#363638',
	HORARIO = '#363638',
	FERIADO = '#FFFFFF'
}
export enum EventoImportadoCorTextoEnum {
	FOLGA = '#363638',
	FOLGAFIXA = '#363638',
	AFASTAMENTO = '#363638',
	LOTACAO = '#363638',
	HORARIO = '#363638',
	FERIADO = '#FFFFFF'
}
