import angular from 'angular';

export const ConfiguracoesListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('configuracoes', {
            url: '/configuracoes',
            template: require('html-loader!./index/configuracoes.index.html'),
            controller: 'ConfiguracoesIndexController',
            controllerAs: 'cnfgrcs_cntrllr',
        });
	}
];
