import angular from 'angular';

export const DepartamentoPessoalListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('dp', {
                url: '/',
                template: require('html-loader!./index/departamentopessoal.index.html'),
                controller: 'DepartamentoPessoalIndexController',
                controllerAs: 'dprtmnt_pssl_cntrllr'
            });
	}
];
