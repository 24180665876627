import angular from 'angular';

export const RotatividadeListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('relatorios_rotatividade', {
                url: '/relatorios/rotatividade',
                template: require('html-loader!./index/rotatividade.index.html'),
                controller: 'RotatividadeIndexController',
                controllerAs: 'rotatividade_ctrl'
            });
    }
];
