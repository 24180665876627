import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { IWorkflowAcao } from '../../models/workflow.acao.model';

export class WorkflowOldModalService {
    static $inject = [
        '$uibModal'
    ];

    constructor(
        private $uibModal: any,
    ) { }

    public open(acao: IWorkflowAcao, trabalhador: ITrabalhador): angular.ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./workflow.old.html'),
            controller: 'WorkflowOldModalController',
            controllerAs: 'confirm',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                acao: () => acao,
                trabalhador: () => trabalhador
            }
        });
    }
}
