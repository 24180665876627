import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesenderecosService } from './solicitacoesalteracoesenderecos.service';

export const MeurhSolicitacoesalteracoesenderecosListRouting = [
    '$stateProvider',
    function($stateProvider: angular.ui.IStateProvider): void {
        let resolve = {
            'entity': [
                'MeurhSolicitacoesalteracoesenderecosService',
                '$stateParams',
                '$state',
                '$q',
                function(
                    entityService: MeurhSolicitacoesalteracoesenderecosService,
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ): angular.ui.IStateParamsService | angular.IQService | {} {
                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
                            entityService.get($stateParams['solicitacao'])
                                .then((data: any): void => {
                                    resolve(data);
                                })
                                .catch((error: any): void => {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_solicitacoesalteracoesenderecos_not_found', $stateParams)) {
                                            $state.go('meurh_solicitacoesalteracoesenderecos_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }]
        };

        $stateProvider
            .state('meurh_solicitacoesalteracoesenderecos', {
                url: '/solicitacoes/alteracoesenderecos?q?estabelecimento?tipo',
                template: require('html-loader!./index/solicitacoesalteracoesenderecos.index.html'),
                controller: 'Meurh\SolicitacoesalteracoesenderecosIndexController',
                controllerAs: 'mrh_slctcsltrcsndrc_lst_cntrllr',
                reloadOnSearch: false
            })

            .state('meurh_solicitacoesalteracoesenderecos_new', {
                parent: 'meurh_solicitacoesalteracoesenderecos',
                url: '/new',
                resolve: resolve,
                template: require('html-loader!./new/solicitacoesalteracoesenderecos.new.html'),
                controller: 'MeurhSolicitacoesalteracoesenderecosNewController',
                controllerAs: 'mrh_slctcsltrcsndrc_frm_nw_cntrllr'
            })
            .state('meurh_solicitacoesalteracoesenderecos_show', {
                parent: 'meurh_solicitacoesalteracoesenderecos',
                url: '/show/:solicitacao?path',
                resolve: resolve,
                template: require('html-loader!./show/solicitacoesalteracoesenderecos.show.html'),
                controller: 'MeurhSolicitacoesalteracoesenderecosShowController',
                controllerAs: 'mrh_slctcsltrcsndrc_frm_shw_cntrllr'
            })
            .state('meurh_solicitacoesalteracoesenderecos_edit', {
                parent: 'meurh_solicitacoesalteracoesenderecos',
                url: '/edit/:solicitacao',
                resolve: resolve,
                template: require('html-loader!./edit/solicitacoesalteracoesenderecos.edit.html'),
                controller: 'MeurhSolicitacoesalteracoesenderecosEditController',
                controllerAs: 'mrh_slctcsltrcsndrc_frm_edit_cntrllr'
            });
    }];
