
import angular from 'angular';

import { FiltrosAtivosController } from './filtros.ativos.controller';
import { FiltrosAtivosComponent } from './filtros.ativos.component';

export const MeurhFiltrosAtivosModule = angular.module('MeurhFiltrosAtivosModule', ['ui.router.state'])
    .controller('FiltrosAtivosController', FiltrosAtivosController)
    .component('filtrosAtivosComponent', FiltrosAtivosComponent)
    .name;
