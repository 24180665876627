import angular from 'angular';
import { MeurhAdmissoesDependentesFormmodalController } from './dependentes.formmodal.controller';

export class MeurhAdmissoesDependentesFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesDependentesFormmodalComponent';
	static controller = MeurhAdmissoesDependentesFormmodalController;
	static template = require('!!html-loader!./dependentes.formmodal.html');
	static transclude = true;
	static bindings = {
		dependente: '=',
		form: '=',
		busy: '=',
		action: '<',
		objadmissao: '=',
	};
}
