
import html2canvas from 'html2canvas';
import moment from 'moment';
import { ListagemService } from '../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../core/usuario/models/usuario-model';

export class SimulacaoRescisaoIndexController {
    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'nsjRouting', '$http', 'NewToaster', 'ListagemService'];

    public nomesMeses: any = new Array('Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro');
    public tipo_aviso_previo: string = '0';
    public tiporescisao: string = '02';
    public exibir_resultado: boolean = false;
    public exibir_informacoes_trabalhador: boolean = false;
    public habilitar_filtros: boolean = false;
    public loading: boolean = false;
    public habilitar_periodo_aviso_previo: boolean = false;
    public avisoindenizado: boolean = true;
    public diasaviso: number = 0;
    public dataAtual: any = new Date();
    public datarescisao: any = moment().format('YYYY-MM-DD');
    public lookupTrabalhadores: boolean = false;
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public form: angular.IFormController;
    public trabalhador: any;
    public update: boolean;
    public entity: any;
    public listagemPermissao: Array<string> = ['simular_rescisao', 'visualizar_salarios'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public empresa: string = this.$rootScope.session.entities.empresa.empresa;
    public estabelecimento: string = this.$rootScope.session.entities.estabelecimento.estabelecimento;
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;

    constructor(
        public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean, session: any, nsjGlobals: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public nsjRouting: any,
        public $http: angular.IHttpService,
        public NewToaster: any,
        public ListagemService: ListagemService,
    ) {
        this.montaListagemVisibilidade();

        if (!$rootScope.temPermissao('simular_rescisao') || !$rootScope.temPermissao('visualizar_salarios')) {
            $state.go('dp', $stateParams);
        }

        $rootScope.$on('Trabalhadores_loading', (event: any, args: any) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event: any, args: any) => {
            this.lookupTrabalhadores = false;
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.empresa ? this.ListagemService.montaEstabelecimentos(this.empresa, this.empresas, this.listagemPermissao) : [];
        this.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        this.trabalhador = null;
        this.$rootScope.$broadcast('trabalhador_refresh', this.trabalhadoresConstructor());
    }

    trabalhadoresConstructor() {
        return {
            status: '00',
            tipo: 0,
            forcagestor: this.forcaGestor(),
            estabelecimento: this.estabelecimento,
            empresa: this.empresa
        };
    }

    simular() {
        this.exibir_resultado = false;
        if (this.datarescisao === '' || !(typeof this.datarescisao === 'string')) {
            this.NewToaster.pop(
                {
                    type: 'error',
                    title: 'Data de rescisão inválida'
                });
        } else if (this.form.$valid) {
            if (moment(this.datarescisao).isSameOrAfter(moment(this.trabalhador.datarescisao))) {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'A data de rescisão simulada não pode ser posterior a data de rescisão cadastrada, que é o dia: ' + moment(this.trabalhador.datarescisao).format('DD/MM/YYYY')
                    });
            } else {
                this.update = true;
                this.generateUrl().then((response) => {
                    this.entity = response.data;
                    this.update = false;

                    if (this.entity && this.entity.trabalhadores === null) {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Funcionário sem direito a férias'
                            });
                    } else {
                        this.exibir_resultado = true;
                    }

                }).catch((error) => {
                    this.update = false;
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: error.data.message
                        });
                });
            }
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos apresentam erros'
            });
        }
    }

    carregar_dados() {
        this.exibir_informacoes_trabalhador = false;
        if (!this.trabalhador) {
            this.habilitar_filtros = false;
        } else {
            this.habilitar_filtros = true;
            this.exibir_informacoes_trabalhador = true;
        }
    }

    verificar_aviso_previo() {
        this.habilitar_periodo_aviso_previo = false;
        if (this.tipo_aviso_previo !== '02') {
            this.habilitar_periodo_aviso_previo = true;
        }
    }

    forcaGestor() {
        return this.$rootScope.modoGestorPermissao('simular_rescisao');
    }

    generateUrl() {

        if (this.avisoindenizado === false) { // avisoindenizado = true
            this.diasaviso = 30;
        }

        return this.$http.get(this.nsjRouting.generate('simulacoes_simularrescisao', {
            'estabelecimento': this.trabalhador.estabelecimentoobj.estabelecimento,
            'trabalhador': this.trabalhador.trabalhador,
            'datarescisao': moment(this.datarescisao).format('YYYY-MM-DD'),
            'tiporescisao': this.tiporescisao,
            'avisoindenizado': this.avisoindenizado ? '1' : '0',
            'diasaviso': this.diasaviso
        }, true));
    }

    calculaTotalizador(trabalhador: any) {
        return this.toNumberString(parseFloat(trabalhador.totalliquido) + parseFloat(trabalhador.totalfgtsrecolher));
    }

    toNumberString(num: any) {
        if (Number.isInteger(num)) {
            return num + '.00';
        } else {
            return num.toString();
        }
    }

    hide(ele: any) {
        var ele = document.querySelector(ele);
        ele.style.display = 'none';
        setTimeout(() => {
            ele.style.display = 'block';
        }, 1000);
        return false;
    }

    screenShot() {
        var w: any = document.getElementById('w');
        var h: any = document.getElementById('h');
        var winW = window.outerWidth;
        var winH = window.outerHeight;
        var width = parseFloat((w.value * winW * .01).toString());
        var height = parseFloat((h.value * winH * .01).toString());
        html2canvas(document.body, {
            onrendered: (canvas: any) => {
                document.body.appendChild(canvas);
                canvas.id = 'ctx';
                var ctx: any = document.getElementById('ctx');
                var img = ctx.toDataURL('image/png');
                window.open(img);
            },
            width: width,
            height: height
        });
        return false;
    }


    imprimirSimulacaoRescisao() {
        window.print();
    }
}
