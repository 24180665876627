import angular from 'angular';

import { AvisoFeriasFormController } from './form/avisoferias.form.controller';
import { AvisoFeriasService } from './avisoferias.service';
import { AvisoFeriasListRouting } from './avisoferias.routes';

export const AvisoFeriasModule = angular.module('AvisoFeriasModule', ['ui.router.state', ])
    .controller('AvisoFeriasFormController', AvisoFeriasFormController)
    .service('AvisoFeriasService', AvisoFeriasService)
    .config(AvisoFeriasListRouting)
    .name;
