import angular from 'angular';

export class MeurhVisualizarFotoModalService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: ng.ui.bootstrap.IModalService) {
	}

	open(entity: string): angular.ui.bootstrap.IModalInstanceService {
		return this.$uibModal.open({
			template: require('html-loader!./visualizarfoto.modal.html'),
			controller: 'MeurhVisualizarFotoModalController',
			controllerAs: 'mrh_vslzr_ft_mdl_cntrllr',
			windowClass: 'nsj-modal-lg-no-height',
			backdrop: 'static',
			resolve: {
				entity: (): string => entity,
			}
		});
	}
}
