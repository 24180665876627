
import angular from 'angular';

import { ApontamentosImportacaoIndexController } from './index/apontamentosimportacao.index.controller';

import { ApontamentosImportacaoService } from './apontamentosimportacao.service';
import { ApontamentosImportacaoModalService } from './modal.importar/apontamentosimportacao.importarmodal.service';
import { ApontamentosResultadoModalService } from './modal.resultado/apontamentosimportacao.resultadomodal.service';

import { ApontamentosImportacaoListRouting } from './apontamentosimportacao.routes';

export const ApontamentosImportacaoModule = angular.module('ApontamentosImportacaoModule', ['ui.router.state', ])
    .controller('ApontamentosImportacaoIndexController', ApontamentosImportacaoIndexController)
    .service('ApontamentosImportacaoService', ApontamentosImportacaoService)
    .service('ApontamentosImportacaoModalService', ApontamentosImportacaoModalService)
    .service('ApontamentosResultadoModalService', ApontamentosResultadoModalService)
    .config(ApontamentosImportacaoListRouting)
    .name;
