import { IGestoresTrabalhador } from '../../../../Meurh/Transferenciacolaboradores/models/transferenciacolaboradores.model';

export class ModalGestorInternoService {
    static $inject = [
        '$uibModal'
    ];

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
    ) { }

    public open(gestores: IGestoresTrabalhador[], permissoes: string[] | null = null, idTrabalhador?: string | null): angular.ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modalGestorInterno.html'),
            controller: 'ModalGestorInternoController',
            controllerAs: '$ctrl',
            backdrop: 'static',
            resolve: {
                entities: () => gestores,
                permissoes: () => permissoes,
                idTrabalhador: () => idTrabalhador,
            }
        });
    }
}
