import angular from 'angular';
import { IAdicionarEscalaForm } from '../../../models/escala.new.model';

export class MeurhEscalaDadosIniciaisFormController {

    static $inject = ['$scope', '$rootScope', '$state'];

    public form: angular.IFormController;
    public entity: IAdicionarEscalaForm;
    public tipoEscalaRequired: boolean = true;

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
        },
		public $state: angular.ui.IStateService,
	) {
        $scope.$watch('$ctrl.entity.selecionados', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue && this.entity.selecionados) {
				this.form.tipoescala.$setDirty();
				if (!this.temTipoEscalaSelecionado()) {
                    this.tipoEscalaRequired = true;
				} else {
                    this.tipoEscalaRequired = false;
				}
			}
		}, true);
    }

    $onInit(): void {
		this.entity.selecionados = {
            folga: false,
            lotacao: false,
            horario: false
        };
	}

    private temTipoEscalaSelecionado(): boolean {
        let tipoEscalas: Array<boolean> = Object.values(this.entity.selecionados);
		return tipoEscalas.some((escala: boolean) => escala === true);
	}
}
