
import * as angular from 'angular';

import { MeurhInclusaodependenteListRouting } from './inclusaodependente.routes';

import { MeurhInclusaodependenteService } from './inclusaodependente.service';
import { DependentesService } from './dependentes.service';
import { MeurhInclusaodependenteModalService } from './modal/inclusaodependente.modal.service';

import { MeurhInclusaodependenteNewController } from './new/inclusaodependente.new.controller';
import { MeurhInclusaodependenteDadosgeraisFormController } from './form/dadosgerais/dadosgerais.form.controller';
import { MeurhInclusaodependenteOutrasinformacoesFormController } from './form/outrasinformacoes/outrasinformacoes.form.controller';
import { MeurhInclusaodependenteIndexController } from './index/inclusaodependente.index.controller';
import { MeurhInclusaodependenteShowController } from './show/inclusaodependente.show.controller';
import { MeurhInclusaodependenteDocumentosanexosFormController } from './form/documentosanexos/documentosanexos.form.controller';
import { MeurhInclusaodependenteBeneficiosFormController } from './form/beneficios/beneficios.form.controller';
import { MeurhInclusaodependenteBeneficiosFormmodalController } from './form/beneficios/formmodal/beneficios.formmodal.controller';

import { MeurhInclusaodependenteDadosgeraisFormComponent } from './form/dadosgerais/dadosgerais.form.component';
import { MeurhInclusaodependenteOutrasinformacoesFormComponent } from './form/outrasinformacoes/outrasinformacoes.form.component';
import { MeurhInclusaodependenteDocumentosanexosFormComponent } from './form/documentosanexos/documentosanexos.form.component';
import { MeurhInclusaodependenteBeneficiosFormComponent } from './form/beneficios/beneficios.form.component';
import { MeurhInclusaodependenteBeneficiosFormmodalComponent } from './form/beneficios/formmodal/beneficios.formmodal.component';

export const MeurhInclusaodependenteModule = angular.module('MeurhInclusaodependenteModule', ['ui.router.state', ])
    .controller('MeurhInclusaodependenteNewController', MeurhInclusaodependenteNewController)
    .controller('MeurhInclusaodependenteDadosgeraisFormController', MeurhInclusaodependenteDadosgeraisFormController)
    .controller('MeurhInclusaodependenteOutrasinformacoesFormController', MeurhInclusaodependenteOutrasinformacoesFormController)
    .controller('MeurhInclusaodependenteIndexController', MeurhInclusaodependenteIndexController)
    .controller('MeurhInclusaodependenteShowController', MeurhInclusaodependenteShowController)
    .controller('MeurhInclusaodependenteDocumentosanexosFormController', MeurhInclusaodependenteDocumentosanexosFormController)
    .controller('MeurhInclusaodependenteBeneficiosFormController', MeurhInclusaodependenteBeneficiosFormController)
    .controller('MeurhInclusaodependenteBeneficiosFormmodalController', MeurhInclusaodependenteBeneficiosFormmodalController)
    .component('meurhInclusaodependenteDadosgeraisFormComponent', MeurhInclusaodependenteDadosgeraisFormComponent)
    .component('meurhInclusaodependenteOutrasinformacoesFormComponent', MeurhInclusaodependenteOutrasinformacoesFormComponent)
    .component('meurhInclusaodependenteDocumentosanexosFormComponent', MeurhInclusaodependenteDocumentosanexosFormComponent)
    .component('meurhInclusaodependenteBeneficiosFormComponent', MeurhInclusaodependenteBeneficiosFormComponent)
    .component('meurhInclusaodependenteBeneficiosFormmodalComponent', MeurhInclusaodependenteBeneficiosFormmodalComponent)
    .service('MeurhInclusaodependenteService', MeurhInclusaodependenteService)
    .service('DependentesService', DependentesService)
    .service('MeurhInclusaodependenteModalService', MeurhInclusaodependenteModalService)
    .config(MeurhInclusaodependenteListRouting)
    .name;
