import ng from 'angular';
import moment from 'moment';
import { IDadosModalRetornoAfastamento } from '../../../../Meurh/Solicitacoesafastamentosretorno/models/retornoAfastamento.model';
import { SituacaoEnum } from '../../../enums/situacao.enum';

export class ModalVisualizarAjustarRetornoController implements ng.IController {

    static $inject = ['entity', '$uibModalInstance'];

    public formData: ng.IFormController;
    public dataNovoAfastamentoretornoTratada: string;
    public situacaoEnum = SituacaoEnum;

    public antesDepois: string;

    constructor(
        public entity: IDadosModalRetornoAfastamento,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    ) {
        this.antesDepois = this.verificarAntesDepois();
        this.dataNovoAfastamentoretornoTratada = this.conversaoData(this.entity.dataNovoRetorno!);
    }

    public confirmar(): void {
        this.$uibModalInstance.close();
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }

    private conversaoData(data: string | moment.Moment): string {
        return moment(data).format('DD/MM/YYYY');
    }

    private verificarAntesDepois(): string {
        if (this.entity.dataNovoRetorno!.diff(this.entity.dataRetornoOriginal) < 0) {
            return 'antes';
        }

        return 'depois';
    }
}
