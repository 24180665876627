import angular = require('angular');
import { MeurhSolicitacoes } from './services/solicitacoes.service';
import { MeurhVisualizarSolicitacaoService } from './services/visualizarsolicitacao.service';

export const solicitacoesRoutes = [
	'$stateProvider',
	($stateProvider: angular.ui.IStateProvider): void => {
		let resolve = {
			'entity': [
				'MeurhSolicitacoes',
				'$stateParams',
				'$state',
				'$q',
				'MeurhVisualizarSolicitacaoService',
				(
					MeurhSolicitacoes: MeurhSolicitacoes,
					$stateParams: angular.ui.IStateParamsService,
					$state: angular.ui.IStateService,
					$q: angular.IQService,
					MeurhVisualizarSolicitacaoService: MeurhVisualizarSolicitacaoService
				): angular.ui.IStateParamsService | angular.IQService | {} => {
					if ($stateParams['entity']) {
						return $stateParams['entity'];
					}

					if (!$stateParams['solicitacao']) {
						return {};
					}

					return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
						MeurhVisualizarSolicitacaoService.preencherPropriedadesSolicitacao($stateParams)
							.then(function (entity: any) {
								resolve(entity);
							})
							.catch(function (error: any) {
								if (error.status === 404) {
									if ($state.href('meurh_solicitacoes_not_found', $stateParams)) {
										$state.go('meurh_solicitacoes_not_found', $stateParams);
									} else {
										$state.go('not_found', $stateParams);
									}
								}
							});
					});
				}
			],
			'PreviousState': [
				'$state',
				($state: angular.ui.IStateService): object => {
					const currentStateData = {
						Name: $state.current.name,
						Params: angular.copy($state.params),
						URL: $state.href($state.current.name, $state.params)
					};

					return currentStateData;
				}
			]
		};

		$stateProvider
			.state('meurh_solicitacoes', {
				url: '/solicitacoes?q?estabelecimento?situacao?trabalhador?tipo',
				template: require('html-loader!./index/index.html'),
				controller: 'Meurh\SolicitacoesListController',
				resolve: resolve,
				controllerAs: 'mrh_slctcs_lst_cntrllr',
				reloadOnSearch: false
			})
			.state('meurh_solicitacoes_show', {
				url: '/solicitacoes?/:solicitacao/:tiposolicitacao',
				resolve: resolve,
				template: require('html-loader!./show/solicitacoes.show.html'),
				controller: 'Meurh\SolicitacoesShowController',
				controllerAs: 'mrh_slctcs_frm_shw_cntrllr',
			});
	}
];
