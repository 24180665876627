export class ModalShowRelatorioService {
  static $inject = [
    '$uibModal'
  ];

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
  ) { }

  public open(fileURL: string): angular.ui.bootstrap.IModalInstanceService {
    return this.$uibModal.open({
      template: require('html-loader!./modalShowRelatorio.html'),
      controller: 'ModalShowRelatorioController',
      controllerAs: '$ctrl',
      animation: true,
      keyboard: true,
      backdrop: true,
      resolve: {
        fileURL: () => fileURL,
      }
    });
  }
}
