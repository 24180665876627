
declare var nsjGlobals: any;
import angular from 'angular';
import { IAfastamento } from '../models/afastamento.model';
import { MeurhAfastamentosService } from '../afastamentos.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';

export class MeurhAfastamentosNewController implements angular.IController {
	static $inject = [
        '$scope',
        '$state',
        'MeurhAfastamentosService',
        'entity',
        'NewToaster',
        '$rootScope',
        'TiposDocumentosRequeridosService',
        'ModalConfirmService',
        '$q',
        '$transitions'
    ];

    public action = 'insert';
	public form: angular.IFormController;
    public entitySave: Partial<IAfastamento> = {};
    public busy: boolean = false;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
	public tiposdocumentosrequeridos: Array<any>;
	public validate: number = 0; // conta quantos erros há em documentos anexos
	public busyTipoDocumentos: boolean = true;
	public entityAnexo: IAnexo = {
        casodeuso: 'AFASTAMENTO'
    };
	public formAnexo: object = {};
	public editanexo: boolean = true;

    public afastamentoOrigemObj;

    public clienteAna: boolean = nsjGlobals.get('clienteana');

    constructor(
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
        public entityService: MeurhAfastamentosService,
        public entity: IAfastamento,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public ModalConfirmService: any,
        public $q: angular.IQService,
        public $transitions: any
    ) {
        this.documentosAnexos();

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});

        this.onSubmitSuccess();
        this.onSubmitError();
        this.onSubmitSuccess();
        this.onSubmitError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    documentosAnexos(): void {
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.$scope.$watch('mrh_afstmts_frm_nw_cntrllr.entity.trabalhador', (newValue: any, oldValue: any) => {
                if (newValue && newValue !== oldValue) {
                    const params = {
                        empresa: newValue.empresaobj.empresa,
                        estabelecimento: newValue.estabelecimentoobj.estabelecimento
                    };
                    this.setEntityAnexo({ estabelecimento: params.estabelecimento });
                    this.carregaTiposDocumentosRequeridos(params);
                }
            }, true);
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
    }

    carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.AFASTAMENTO,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

	resetTiposDocumentosRequeridosService(): void {
		this.TiposDocumentosRequeridosService.entities = [];
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
	}

    setEntityAnexo(params: { estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
    }

    createEntity(rascunho: boolean): void {
        this.entitySave.datainicio = this.entity.datainicio;
        this.entitySave.diasafastamento = this.entity.diasafastamento;
        this.entitySave.dataretorno = this.entity.dataretorno;
        this.entitySave.descricao = this.entity.descricao;
        this.entitySave.tipohistorico = this.entity.tipohistorico;
        this.entitySave.trabalhador = this.entity.trabalhador.trabalhador;
        if (this.entity.observacao) {
            this.entitySave.observacao = this.entity.observacao;
        }
        this.entitySave.tiposolicitacao = TipoSolicitacaoEnum.AFASTAMENTO;
        if (rascunho === true) {
            this.entitySave.situacao = -1;
        } else {
            this.entitySave.situacao = 0;
        }

        if (this.clienteAna) {
            if (this.entity.afastamentoorigem) {
                this.afastamentoOrigemObj = angular.copy(this.entity.afastamentoorigem);
                this.entitySave.afastamentoorigem = this.entity.afastamentoorigem.afastamentotrabalhador;
            } else {
                this.entitySave.afastamentoorigem = null;
            }
        }

    }

    salvar(rascunho: boolean = false): void {
        let podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
                tipodocumentorequerido.fileSelecionado
            );
        });

        if (this.form.$valid && !this.entity.$$__submitting && podeSalvar) {
            this.busy = true;
            this.allowRefresh = false;
            this.createEntity(rascunho);

            if (this.clienteAna && this.entity.afastamentoorigem) {

                let combinacaoValidaMotivoAfastamentoOrigem: boolean = this.validaCombinacaoMotivoAfastamentoOrigem(this.entity.tipohistorico, this.afastamentoOrigemObj.tipohistorico.tipohistorico);

                if (!combinacaoValidaMotivoAfastamentoOrigem) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'O afastamento origem é de motivo diferente da prorrogação solicitada.'
                    });

                    this.busy = false;
                    return;
                }

            }

            this.entityService.save(this.entitySave, {
                empresa: this.entity.trabalhador.empresaobj.empresa,
                estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
            });
        } else {
            if (!podeSalvar) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Há documento(s) obrigatório(s) não informado(s)'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos do formulário apresentam erros.'
                });
            }
        }
    }

    verificaObj(): boolean {

        let anexosObrigatoriosEnviados = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
                tipodocumentorequerido.fileSelecionado
            );
        });

        if (
            this.valorExiste(this.entity.trabalhador) &&
            this.valorExiste(this.entity.datainicio) &&
            (this.valorExiste(this.entity.diasafastamento) && this.entity.diasafastamento !== 0) &&
            this.valorExiste(this.entity.descricao) &&
            this.valorExiste(this.entity.tipohistorico) &&
            this.form.$valid &&
            anexosObrigatoriosEnviados
        ) {
            return false;
        } else {
            return true;
        }
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.entityService.constructors.solicitacao = args.entity.solicitacao;
            this.entityAnexo['solicitacao'] = args.entity.solicitacao;

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});
            if (this.tiposdocumentosrequeridos.length <= 0) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A Solicitação de Afastamento foi enviada com sucesso!'
                });
                this.$state.go('meurh_afastamentos_show', {'solicitacao': args.entity.solicitacao});
            } else {
                this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                    this.$scope.$apply();
                    if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A Solicitação de Afastamento foi enviada com sucesso!'
                        });
                        this.$state.go('meurh_afastamentos_show', {'solicitacao': args.entity.solicitacao});
                    }
                });
            }

        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesafastamentos_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
                if (
                    args.response.data &&
                    typeof (args.response.data.message) !== 'undefined' &&
                    args.response.data.message
                ) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;
                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
                                    if (children[child].errors.hasOwnProperty(error)) {
                                        message += '<li>' + children[child].errors[error] + '</li>';
                                    }
                                }
                            }
                        }
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao enviar a Solicitação de Afastamento!'
                    });
                }
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    formChange(): boolean {
        let existeDocumento = this.tiposdocumentosrequeridos?.some((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === true;
        });
        if (
            this.valorExiste(this.entity.trabalhador) ||
            this.valorExiste(this.entity.datainicio) ||
            (this.valorExiste(this.entity.diasafastamento) && this.entity.diasafastamento !== 0) ||
            this.valorExiste(this.entity.descricao) ||
            this.valorExiste(this.entity.observacao) || existeDocumento
        ) {
            this.formchanged = true;
        }
        return this.formchanged;
    }

    buttonDescartarModal(): void {
        if (this.formChange()) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_afastamentos', angular.extend(this.entityService.constructors));
            }).catch((error: any): void => {/**/});
        } else {
            this.$state.go('meurh_afastamentos', angular.extend(this.entityService.constructors));
        }
    }

    refreshDescartarModal = (e: any): void => {
        e = e || window.event;
        if (this.formChange() && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                }).catch((error: any): void => {/**/});
            }
        }
    }

    exitScreenDescartarModal(): void {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (
                trans._targetState._identifier !== 'meurh_solicitacoesafastamentos_new' &&
                this.allowRefresh &&
                this.formChange()
            ) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                }).catch(function () {/**/});
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }

    /**
     * Método que verifica se a combinação entre motivo do afastamento e afastamento origem é válida
     * @param tipoHistorico Tipo de histórico selecionado no motivo
     * @param afastamentoOrigem O tipo de histórico do afastamento origem
     * @returns Booleano que informa se combinação é válida
     */
    validaCombinacaoMotivoAfastamentoOrigem(tipoHistorico: string, afastamentoOrigem: string) {

        if ((tipoHistorico === '002' && afastamentoOrigem === '001') || (tipoHistorico === '004' && afastamentoOrigem === '003')) {
            return true;
        } else {
            return false;
        }

    }

}
