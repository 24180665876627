import angular from 'angular';
import Papa from 'papaparse';
import { IDetalhamentoErro } from '../models/escala.erros.model';
import { TipoImportacaoEnum, ColunasArquivoImportacao } from '../enums/importacao';

export class MeurhEscalaErrosModalController {
  static $inject = ['$uibModalInstance', 'entity', 'tipoImportacao'];
  public entityCopy: Array<IDetalhamentoErro>;

  public fields = [
    {
        value: 'linha',
        label: 'Linha',
        type: 'string',
        style: 'title',
        copy: '',
    },
    {
        value: 'colaborador.nome',
        label: 'Colaborador',
        type: 'string',
        style: 'default',
        copy: '',
    },
    {
        value: 'data',
        label: 'Data',
        type: 'string',
        style: 'default',
        copy: '',
        customLabelHtml: () => {
          let someHtmlVar = `
          <span>${this.tipoImportacao === TipoImportacaoEnum.FOLGA ? 'Data' : 'Data inicial'}</span>
        `;
          return someHtmlVar;
      },
    },
    ...Object.values({ ...(this.tipoImportacao !== TipoImportacaoEnum.FOLGA && [{
        value: 'dataFim',
        label: 'Data final',
        type: 'string',
        style: 'default',
        copy: '',
      }, {
        value: 'solicitacao.nome',
        label: 'Solicitação',
        type: 'string',
        style: 'default',
        copy: '',
        customLabelHtml: () => {
          let someHtmlVar = `
            <span class="capitalize">${this.tipoImportacao}</span>
          `;
          return someHtmlVar;
        },
      }, {
        value: 'empresa.razaosocial',
        label: 'Empresa',
        type: 'string',
        style: 'default',
        copy: '',
      }]
    )}),
    ...Object.values({ ...(this.tipoImportacao === TipoImportacaoEnum.FOLGA && [{
      value: 'estabelecimento.nomefantasia',
      label: 'Estabelecimento',
      type: 'string',
      style: 'default',
      copy: '',
    }]
  )}),
    {
        value: 'descricao',
        label: 'Descrição',
        type: 'string',
        style: 'default',
        copy: '',
    },
  ];

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public entity: Array<IDetalhamentoErro>,
    public tipoImportacao: string
  ) {
    this.entityCopy = angular.copy(this.entity);
  }

  download(): void {
    // constroi o csv com as colunas de identificador, data, solicitacao (se precisar de guid) e descricao do erro
    let data = this.entityCopy.map((event) => {
      return {
        identificador: event.importacao?.identificador,
        [ColunasArquivoImportacao[this.tipoImportacao].data]: event.data,
        ...((this.tipoImportacao !== TipoImportacaoEnum.FOLGA) && {
          [ColunasArquivoImportacao[this.tipoImportacao].dataFinal]: event.dataFim,
          [this.tipoImportacao]: event.importacao?.[this.tipoImportacao],
          empresa: event.importacao?.empresa,
        }),
        ...((this.tipoImportacao === TipoImportacaoEnum.FOLGA && event.importacao?.estabelecimento) && {
          estabelecimento: event.importacao?.estabelecimento,
        }),
        ...((this.tipoImportacao === TipoImportacaoEnum.FOLGA && event.importacao?.descricao) && {
          descricao: event.importacao?.descricao,
      }),
        descricao_erro: event.descricao
      };
    });
    let csv = Papa.unparse(data, { delimiter: ';' });
    let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'erros.csv');
    downloadLink.setAttribute('href', window.URL.createObjectURL(csvData));
    downloadLink.click();
  }

  close(): void {
    this.$uibModalInstance.dismiss('fechar');
  }
}
