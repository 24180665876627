import angular from 'angular';

import { PaginaInicialIndexController } from './index/paginainicial.index.controller';

import { PaginaInicialListRouting } from './paginainicial.routes';

export const PaginaInicialModule = angular.module('PaginaInicialModule', ['ui.router.state', ])
    .controller('PaginaInicialIndexController', PaginaInicialIndexController)
    .config(PaginaInicialListRouting)
    .name;
