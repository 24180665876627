import * as angular from 'angular';
import { MeurhVagasService } from './vagas.service';

export const MeurhVagasListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {

    let resolve = {
        'entity': [
            'MeurhVagasService',
            '$stateParams',
            '$state',
            '$rootScope',
            '$q',
            function (
                entityService: MeurhVagasService,
                $stateParams: angular.ui.IStateParamsService,
                $state: angular.ui.IStateService,
                $rootScope: angular.IRootScopeService,
                $q: angular.IQService
            ): angular.ui.IStateParamsService | angular.IQService | {} {

                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['vaga']) {
                        return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                            $rootScope.$broadcast('meurh_vagas_get_loading', {});
                            entityService.get($stateParams['vaga'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_vagas_not_found', $stateParams)) {
                                            $state.go('meurh_vagas_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }
        ]
    };

    $stateProvider
        .state('meurh_vagas', {
            url: '/vagas?q?estabelecimento',
            template: require('html-loader!./index/vagas.index.html'),
            controller: 'Meurh\VagasIndexController',
            controllerAs: 'mrh_vgs_lst_cntrllr',
            reloadOnSearch: false
        })
        .state('meurh_vagas_new', {
            parent: 'meurh_vagas',
            url: '/new',
            resolve: resolve,
            template: require('html-loader!./new/vagas.new.html'),
            controller: 'MeurhVagasNewController',
            controllerAs: 'mrh_vgs_frm_nw_cntrllr'
        })
        .state('meurh_vagas_show', {
            parent: 'meurh_vagas',
            url: '/show/:vaga',
            resolve: resolve,
            template: require('html-loader!./show/vagas.show.html'),
            controller: 'MeurhVagasShowController',
            controllerAs: 'mrh_vgs_frm_shw_cntrllr'
        })
        .state('meurh_vagas_edit', {
            parent: 'meurh_vagas',
            url: '/edit/:vaga',
            resolve: resolve,
            template: require('html-loader!./edit/vagas.edit.html'),
            controller: 'Meurh\VagasEditController',
            controllerAs: 'mrh_vgs_frm_edit_cntrllr'
        });
    }
];
