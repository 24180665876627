import * as angular from 'angular';
import { MeurhSolicitacoesadmissoesService } from './solicitacoesadmissoes.service';

export const MeurhSolicitacoesadmissoesListRouting = [
    '$stateProvider',
    function($stateProvider: angular.ui.IStateProvider): void {
        let resolve = {
            'entity': [
                'MeurhSolicitacoesadmissoes',
                '$stateParams',
                '$state',
                '$q',
                function(
                    entityService: MeurhSolicitacoesadmissoesService,
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ): angular.ui.IStateParamsService | angular.IQService | {} {
                    if ($stateParams['entity']) {
                        return $stateParams['entity'];
                    }

                    if (!$stateParams['solicitacao']) {
                        return {};
                    }

                    return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
                        entityService.get($stateParams['solicitacao'])
                            .then((data: any): void => {
                                resolve(data);
                            })
                            .catch((error: any): void => {
                                if (error.status === 404) {
                                    if ($state.href('meurh_solicitacoesadmissoes_not_found', $stateParams)) {
                                        $state.go('meurh_solicitacoesadmissoes_not_found', $stateParams);
                                    } else {
                                        $state.go('not_found', $stateParams);
                                    }
                                }
                            });
                    });
                }
            ]
        };

        $stateProvider
            .state('meurh_solicitacoesadmissoes', {
                url: '/solicitacoes/admissoes?q?estabelecimento',
                template: require('html-loader!./index/solicitacoesadmissoes.index.html'),
                controller: 'Meurh\SolicitacoesadmissoesIndexController',
                controllerAs: 'mrh_slctcsdmsss_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('meurh_solicitacoesadmissoes_new', {
                // parent: 'meurh_solicitacoesadmissoes',
                url: '/admissaopreliminar/new',
                resolve: resolve,
                template: require('html-loader!./new/solicitacoesadmissoes.new.html'),
                controller: 'MeurhSolicitacoesadmissoesNewController',
                controllerAs: 'mrh_slctcsdmsss_frm_nw_cntrllr'
            })
            .state('meurh_solicitacoesadmissoes_show', {
                // parent: 'meurh_solicitacoesadmissoes',
                url: '/admissaopreliminar/show/:solicitacao?path',
                resolve: resolve,
                template: require('html-loader!./show/solicitacoesadmissoes.show.html'),
                controller: 'MeurhSolicitacoesadmissoesShowController',
                controllerAs: 'mrh_slctcsdmsss_frm_shw_cntrllr'
            })
            .state('meurh_solicitacoesadmissoes_edit', {
                // parent: 'meurh_solicitacoesadmissoes',
                url: '/admissaopreliminar/edit/:solicitacao',
                resolve: resolve,
                template: require('html-loader!./edit/solicitacoesadmissoes.edit.html'),
                controller: 'Meurh\SolicitacoesadmissoesEditController',
                controllerAs: 'mrh_slctcsdmsss_frm_edt_cntrllr'
            });
    }
];
