import * as angular from 'angular';

import { MeurhInformesrendimentosListController } from './index/informerendimentos.index';
import { MeurhInformesrendimentosService } from './informesrendimentos.service';
import { MeurhInformesrendimentosRouting } from './informesrendimentos.routes';

export const MeurhInformesrendimentosModule = angular.module('meurhInformesrendimentosModule', ['ui.router.state'])
    .controller('MeurhInformesrendimentosListController', MeurhInformesrendimentosListController)
    .service('MeurhInformesrendimentosService', MeurhInformesrendimentosService)
    .config(MeurhInformesrendimentosRouting)
    .name;
