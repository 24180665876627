import angular = require('angular');
import moment = require('moment');
import { IInclusaodependente } from '../../models/inclusaodependente.model';

export class MeurhInclusaodependenteOutrasinformacoesFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'Paises'];

    public entity: IInclusaodependente;
    public form: angular.IFormController;
    public constructor_: any = {};
    public action: any;

    public agenciaNumero: any = 'numero';

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public Paises: any,
    ) {

    }

    $onInit(): void {
        this.constructor_ = this.verificacaoBanco();

        // no show, o nome do campo referente ao número da agência é diferente do da listagem no new/edit
        if (this.action === 'show') {
            this.agenciaNumero = 'agencianumero';
        } else {
            this.agenciaNumero = 'numero';
            if (this.action === 'update' && this.entity.agencia) {
                this.entity.agencia.numero = this.entity.agencia.agencianumero;
            }
        }
    }

    preencheCampo(): void {
        if (this.entity.tiporecebimento === '128') { // cpf
            this.entity.chavepix = this.entity.cpf;
        } else if (this.entity.tiporecebimento === '256') { // email
            this.entity.chavepix = this.entity.email;
        } else if (this.entity.tiporecebimento === '512') { // celular
            this.entity.chavepix = this.entity.dddcel + this.entity.celular;
        } else {
            this.entity.chavepix = '';
        }
    }

    verificacaoBanco(): { banco: string } {
        if (this.entity.banco) {
            if (this.entity.banco.banco) {
                return { 'banco': this.entity.banco.banco };
            }
        }
        return { 'banco': null };
    }

    alteraAgenciaPorBanco(): void {
        if (this.entity.banco) {
            this.$rootScope.$broadcast('agencia_refresh', { banco: this.entity.banco.banco });
            this.entity.agencia = null;
        }
    }

    changeBanco(): void {
        this.alteraAgenciaPorBanco();
        this.constructor_ = this.verificacaoBanco();
    }

    limpaCamposEhBeneficiariopensao(): void {
        if (this.entity.pensaoalimenticia === false ) {
            delete this.entity.tiporecebimento;
            delete this.entity.banco;
            delete this.entity.chavepix;
            delete this.entity.agencia;
            delete this.entity.numerocontasalario;
            delete this.entity.numerocontasalariodv;
            delete this.entity.percentualpensaoalimenticia;
            delete this.entity.percentualpensaoalimenticiafgts;
        }
    }
}
