export const MeurhHorariosListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {

        let resolve = {
            'entity': ['Horarios', '$stateParams', '$state', '$q', (entityService: any, $stateParams: any, $state: any, $q: any) => {
                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['horario']) {
                        return $q((resolve: any, reject: any) => {
                            entityService.get($stateParams['horario'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('horarios_not_found', $stateParams)) {
                                            $state.go('horarios_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }]
        };

        $stateProvider
            .state('horarios', {
                url: '/horarios?q?empresa',
                template: require('html-loader!./index/quadrohorarios.index.html'),
                controller: 'MeurhQuadroHorariosIndexController',
                controllerAs: 'hrrs_lst_cntrllr',
                reloadOnSearch: false
            });
    }
];

