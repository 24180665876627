import angular from 'angular';
import { ISolicitacaoAlteracaoEndereco } from '../models/solicitacaoalteracaoendereco.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { MeurhSolicitacoesalteracoesenderecosService } from '../solicitacoesalteracoesenderecos.service';
import { MeurhAnexosService } from '../../Anexos/anexos.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhSolicitacoesalteracoesenderecosNewController {
    static $inject = [
        '$scope',
        '$state',
        'MeurhSolicitacoesalteracoesenderecosService',
        'entity',
        'NewToaster',
        '$rootScope',
        'MeurhAnexosService',
        'TiposDocumentosRequeridosService',
        'ModalConfirmService',
        '$q',
        '$transitions'
    ];

    public action: string = 'insert';
    public entityAnexo: IAnexo = {
        casodeuso: 'ALTERACOESENDERECOS'
    };
    public form: angular.IFormController;
    public formAnexo: object = {};
    public editanexo: boolean = true;
    public busy: boolean = false;
    public busyTipoDocumentos: boolean = true;
    public tiposdocumentosrequeridos: any = [];
    public validate: number = 0; // conta quantos erros há em documentos anexos
    public allowRefresh: boolean = false;

    constructor(
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesenderecosService,
        public entity: ISolicitacaoAlteracaoEndereco,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
        public MeurhAnexosService: MeurhAnexosService,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public ModalConfirmService: any,
        public $q: angular.IQService,
        public $transitions: any
    ) {}

    $onInit() {
        this.onSubmitSuccess();
        this.onSubmitError();
        this.documentosAnexos();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });
        });
    }

    submit(): void {
        let podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
                tipodocumentorequerido.fileSelecionado
            );
        });

        if (this.form.$valid && !this.entity.$$__submitting && podeSalvar) {
            this.busy = true;
            this.allowRefresh = false;
            this.entityService.constructors.situacao = 0;
            this.entity.situacao = 0;
            this.entityService.save(this.entity, {
                empresa: this.entity.trabalhador.empresaobj.empresa,
                estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
            });
        } else {
            if (!podeSalvar) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Há documento(s) obrigatório(s) não informado(s)'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos do formulário apresentam erros'
                });
            }
        }
    }

    limpaForm(): void {
        this.entity.bairro = '';
        this.entity.cep = '';
        this.entity.codigo = '';
        this.entity.complemento = '';
        this.entity.logradouro = '';
        this.entity.numero = '';
        delete this.entity.municipioresidencia;
        delete this.entity.paisresidencia;
        delete this.entity.tipologradouro;
    }

    setEditAnexo(value: boolean): void {
        this.editanexo = value;
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesenderecos_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.entityService.constructors.solicitacao = args.entity.solicitacao;
            this.entityAnexo['solicitacao'] = args.entity.solicitacao;

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});
            if (this.tiposdocumentosrequeridos.length <= 0) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A Solicitação de Alteração de Endereço foi enviada com sucesso!'
                });
                this.$state.go('meurh_solicitacoesalteracoesenderecos_show', { 'solicitacao': args.entity.solicitacao });
            } else {
                this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                    this.$scope.$apply();
                    if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A Solicitação de Alteração de Endereço foi enviada com sucesso!'
                        });
                        this.$state.go('meurh_solicitacoesalteracoesenderecos_show', { 'solicitacao': args.entity.solicitacao });
                    }
                });
            }
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesenderecos_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity, {
                        empresa: this.entity.trabalhador.empresaobj.empresa,
                        estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
                    });
                }
            } else {
                if (
                    args.response.data &&
                    typeof (args.response.data.message) !== 'undefined' &&
                    args.response.data.message
                ) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;
                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
                                    if (children[child].errors.hasOwnProperty(error)) {
                                        message += '<li>' + children[child].errors[error] + '</li>';
                                    }
                                }
                            }
                        }
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao enviar a Solicitação de Alteração de Endereço!'
                    });
                }
            }
        });
    }

    documentosAnexos(): void {
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.$scope.$watch('mrh_slctcsltrcsndrc_frm_nw_cntrllr.entity.trabalhador', (newValue: any, oldValue: any) => {
                if (newValue && newValue !== oldValue) {
                    const params = {
                        empresa: newValue.empresaobj.empresa,
                        estabelecimento: newValue.estabelecimentoobj.estabelecimento
                    };
                    this.setEntityAnexo({ estabelecimento: params.estabelecimento });
                    this.carregaTiposDocumentosRequeridos(params);
                }
            }, true);
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
    }

    carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.ALTERACAO_DADOS_CADASTRAIS,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

    resetTiposDocumentosRequeridosService(): void {
        this.tiposdocumentosrequeridos = [];
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    setEntityAnexo(params: { estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    buttonDescartarModal(): void {
        if (this.valorExiste(this.entity.trabalhador)) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoesalteracoesenderecos', angular.extend(this.entityService.constructors));
            }).catch((error: any): void => {/**/});
        } else {
            this.$state.go('meurh_solicitacoesalteracoesenderecos', angular.extend(this.entityService.constructors));
        }
    }

    refreshDescartarModal = (e: any): void => {
        e = e || window.event;
        if (this.valorExiste(this.entity.trabalhador) && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                }).catch((error: any): void => {/**/});
            }
        }
    }

    exitScreenDescartarModal(): void {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (
                trans._targetState._identifier !== 'meurh_solicitacoesalteracoesenderecos_new' &&
                this.allowRefresh && this.valorExiste(this.entity.trabalhador)
            ) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                }).catch(function () {/**/});
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
