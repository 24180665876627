import ng from 'angular';

import { MeurhRelatoriossolicitacoesService } from '../relatoriossolicitacoes.service';

import { ModalShowRelatorioService } from '../components/modalShowRelatorio/modalShowRelatorio.service';
import { ModalConfirmRelatoriosService } from '../components/modalConfirm/modalConfirmRelatorios.service';

import { IRelatorio } from '../models/relatorio.model';
import { IRelatorioData } from '../models/relatoriodata.model';
import { IModalConfirmRelatorios } from '../models/modalConfirmRelatorios.model';
import { SituacaoEnum } from '../enums/situacaoEnum';

export class MeurhRelatoriossolicitacoesController implements ng.IController {
    static $inject = [
        '$rootScope',
        'NewToaster',
        'MeurhRelatoriossolicitacoesService',
        'ModalShowRelatorioService',
        'ModalConfirmRelatoriosService'
    ];

    public relatorios: IRelatorio[] = [];
    public relatoriosSelecionados: IRelatorio[] = [];

    public tipo: string;
    public entityGuid: string;
    public situacao: number;

    public isBusy: boolean = true;
    public checkAll: boolean = false;

    private rotas: {
        get: string;
        download: string;
        delete: string;
    };

    private situacaoEnum = SituacaoEnum;

    constructor(
        public $rootScope: angular.IRootScopeService & { temPermissao: (param: string) => boolean },
        private NewToaster: any,
        private entityService: MeurhRelatoriossolicitacoesService,
        private ModalShowRelatorioService: ModalShowRelatorioService,
        private ModalConfirmRelatoriosService: ModalConfirmRelatoriosService
    ) { }

    public $onInit(): void {
        this.getRoutes();
        this.getRelatorios();
    }

    public handleCheckAll(): void {
        this.checkAll = !this.checkAll;

        if (this.checkAll) {
            this.relatorios.forEach((relatorio: IRelatorio) => {
                const relatorioGuid = relatorio;

                if (!this.relatoriosSelecionados.includes(relatorioGuid)) {
                    this.relatoriosSelecionados.push(relatorioGuid);
                }
            });
        } else {
            this.relatoriosSelecionados = [];
        }
    }

    public handleCheckRelatorio(relatorio: IRelatorio): void {
        const indice: number = this.relatoriosSelecionados.indexOf(relatorio);

        if (indice !== -1) {
            this.relatoriosSelecionados.splice(indice, 1);
            return;
        }

        this.relatoriosSelecionados.push(relatorio);
    }

    public isRelatorioChecked(relatorio: IRelatorio): boolean {
        return this.relatoriosSelecionados.includes(relatorio);
    }

    public canDelete(): boolean {
        return (
            this.$rootScope.temPermissao('excluir_relatoriosolicitacao') &&
            this.situacao !== this.situacaoEnum.CANCELADA &&
            this.situacao !== this.situacaoEnum.EXCLUIDA &&
            this.situacao !== this.situacaoEnum.RECUSADA
        );
    }

    public openRelatorios(openType: 'openModal' | 'downloadZip' | 'downloadDocument'): void {
        this.isBusy = true;

        let extensao: string = 'pdf';
        let nomeArquivo: string = 'Relatórios agrupados';
        let relatoriosSelecionadosGuidList: string[] = [];
        let downloadRelatoriosConstructors: IRelatorioData = { format: 'pdf', solicitacaorelatorio: '' };

        this.relatoriosSelecionados.map((relatorio: IRelatorio) => {
            relatoriosSelecionadosGuidList.push(relatorio.solicitacaorelatorio);
        });

        downloadRelatoriosConstructors.solicitacaorelatorio = relatoriosSelecionadosGuidList.join(',');

        if (openType === 'downloadZip' && this.relatoriosSelecionados.length > 1) {
            downloadRelatoriosConstructors.format = 'zip';
            extensao = 'zip';
        } else if (this.relatoriosSelecionados.length === 1) {
            extensao = this.relatoriosSelecionados[0].extensao;
            if (this.relatoriosSelecionados[0].tiporelatorio) {
                nomeArquivo = this.relatoriosSelecionados[0].tiporelatorio.nome;
            }
        }

        nomeArquivo = `${nomeArquivo}.${extensao}`;

        this.entityService.downloadRelatorios(this.entityGuid, this.rotas.download, downloadRelatoriosConstructors)
            .then((response: ng.IHttpResponse<any>): void => {
                if (openType === 'openModal') {
                    this.showRelatorios(response);
                    return;
                }

                this.downloadRelatorios(response, nomeArquivo);
            })
            .catch((): void => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao obter as informações sobre os relatórios selecionados'
                });
            })
            .finally((): void => {
                this.isBusy = false;
            });
    }

    public deleteRelatorioConfirm(): void {
        try {
            if (this.relatoriosSelecionados.length === 0 || this.relatoriosSelecionados.length > 1) {
                throw new Error('Erro de seleção. Selecione um único relatório para exclusão.');
            }

            const relatorioGuid: string = this.relatoriosSelecionados[0].solicitacaorelatorio;
            const modalTexts: IModalConfirmRelatorios = this.getModalTexts('delete');

            this.ModalConfirmRelatoriosService.open(modalTexts).result
                .then(async () => {
                    const constructors = { solicitacaorelatorio: relatorioGuid };

                    await this.deleteRelatorio(constructors);
                })
                .catch(() => {/** */ });
        } catch (error) {
            this.NewToaster.pop({
                type: 'error',
                title: error.message
            });
        }
    }

    public podeVisualizar(): boolean {
        return (
            this.relatoriosSelecionados.length > 0 &&
            this.relatoriosSelecionados.every(relatorio => relatorio.extensao === 'pdf')
        );
    }

    public podeBaixarZip(): boolean {
        return this.relatoriosSelecionados.length > 1;
    }

    public podeBaixarPDF(): boolean {
        return (
            this.relatoriosSelecionados.length > 0 &&
            (this.relatoriosSelecionados.length === 1 || this.relatoriosSelecionados.every(relatorio => relatorio.extensao === 'pdf'))
        );
    }

    private async deleteRelatorio(constructors: { solicitacaorelatorio: string; }): Promise<void> {
        this.isBusy = true;
        this.entityService.deleteRelatorio(this.entityGuid, this.rotas.delete, constructors)
            .then(async (response: ng.IHttpResponse<any>) => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Relatório excluído com sucesso'
                });
                await this.getRelatorios();
            })
            .catch(() => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao excluir o relatório'
                });
            })
            .finally(() => this.isBusy = false);
    }

    private async getRelatorios(): Promise<void> {
        this.isBusy = true;
        this.entityService.getRelatorios(this.entityGuid, this.rotas.get)
            .then((response: ng.IHttpResponse<IRelatorio[]>) => {
                this.relatorios = response.data;
            })
            .catch((error: any): void => {
                this.NewToaster.pop({
                    type: 'error',
                    title: error.data.message
                });
            })
            .finally((): void => {
                this.isBusy = false;
            });
    }


    private showRelatorios(response: ng.IHttpResponse<any>): void {
        const file = new Blob([response.data], { type: response.data.type });
        const fileURL = URL.createObjectURL(file);

        this.ModalShowRelatorioService.open(fileURL).result
            .then(() => {/** */ })
            .catch(() => {/** */ });
    }

    private downloadRelatorios(response: ng.IHttpResponse<any>, nomeArquivo: string): void {
        const blob = response.data;
        const fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
        let link = document.createElement('a');

        document.body.appendChild(link);

        link.style.display = 'none';
        link.href = fileURL;
        link.download = nomeArquivo;
        link.click();

        window.URL.revokeObjectURL(fileURL);
    }

    private getRoutes(): void {
        const rotas = {
            rescisao: {
                get: 'meurh_solicitacoesrescisoes_relatorios_list',
                download: 'meurh_solicitacoesrescisoes_relatorios_download_lote',
                delete: 'meurh_solicitacoesrescisoes_relatorios_delete'
            },
            admissao: {
                get: 'meurh_solicitacoesadmissoescompleta_relatorios_list',
                download: 'meurh_solicitacoesadmissoescompleta_relatorios_download_lote',
                delete: 'meurh_solicitacoesadmissoescompleta_relatorios_delete'
            },
            admissaoEstagiario: {
                get: 'meurh_solicitacoesadmissoesestagiario_relatorios_list',
                download: 'meurh_solicitacoesadmissoesestagiario_relatorios_download_lote',
                delete: 'meurh_solicitacoesadmissoesestagiario_relatorios_delete'
            }
        };

        this.rotas = rotas[this.tipo];
    }

    private getModalTexts(modalType: 'delete'): IModalConfirmRelatorios {
        const types = {
            delete: {
                title: 'Excluir relatórios',
                description: 'Tem certeza que deseja excluir o relatório selecionado?',
                buttons: {
                    confirm: 'Sim, excluir',
                    close: 'Cancelar'
                }
            }
        };

        return types[modalType];
    }
}
