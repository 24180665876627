import angular from 'angular';
import 'angular-filter';
import { EstabelecimentosHabilitadosService } from '../estabelecimentoshabilitados.service';
import { EstabelecimentosHabilitadosModalService } from '../modalBuscaColaboradores/estabelecimentoshabilitados.modalbuscarcolaboradores.service';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';
import { ITrabalhador } from '../../../../Trabalhadores/models/trabalhador.model';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { Usuario } from '../../../../../core/usuario/models/usuario-model';
import { ModalConfirmService } from '../../../../../shared/components/modal-confirm/modal.confirm.service';
import { ObrasService } from '../../../../Obras/obras.service';


type ColaboradoresType = {
    nome: string;
    cargo: string;
    cpf: string;
    departamento: string;
    lotacaoobj: {
        nome: string;
    };
    trabalhador: string;
    tipoalteracao: string;
    nsr: number;
};

type AlteracaoColaborador = {
    trabalhador: {
      trabalhador: string;
    };
    tipoalteracao: string;
    cpf: string;
    nome: string;
    nomeresponsavel: string;
    cpfresponsavel: string;
    nsr?: number;
};

export class EstabelecimentosHabilitadosNewController {
    static $inject = ['$scope', '$rootScope', '$state', '$stateParams', '$location', 'EstabelecimentosHabilitadosService', 'EstabelecimentosHabilitadosModalService', 'TrabalhadoresService', 'ListagemService', 'NewToaster', 'ModalConfirmService', 'ObrasService'];

    public busy: boolean = false;
    public busyFilter: boolean = false;
    public entities: Array<ColaboradoresType> = [];
    public entitiesCopy: Array<ColaboradoresType> = [];
    public entity: any;
    public filters: any = {};
    public empresas: Array<any> = [];
    public estabelecimentos: Array<any> = [];
    public obras: Array<any> = [];
    public colaboradoresList: Array<ITrabalhador> = [];
    public isColaboradoresAdicionados: boolean = false;
    public todosColaboradoresAdicionados: boolean = false;
    public busca: any;

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean, session: any, nsjGlobals: any, filterData: any , usuario: Usuario },
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public estabelecimentosHabilitadosService: EstabelecimentosHabilitadosService,
        public estabelecimentosHabilitadosModalService: EstabelecimentosHabilitadosModalService,
        public trabalhadoresService: TrabalhadoresService,
        public listagemService: ListagemService,
        public NewToaster: any,
        public modalConfirmService: ModalConfirmService,
        public obrasService: ObrasService,
        public form: angular.IFormController
    ) {
        this.busy = true;
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.busca = '';
        this.obras = this.obrasService.load();

        this.empresas = this.listagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, []);
        this.estabelecimentos = this.listagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, []);

        $scope.$on('ponto_alteracoesentidades_inserir_sucesso', (event: angular.IAngularEvent, args: any, response: any) => {
			this.busy = false;
            this.NewToaster.pop({
				type: 'success',
				title: 'O estabelecimento foi incluído com sucesso.',
			});
            this.form.$submitted = true;

            this.$state.go('estabelecimentoshabilitados');
        });

        $scope.$on('ponto_alteracoesentidades_inserir_erro', (event: angular.IAngularEvent, args: any, response: any) => {
			this.busy = false;
            this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao tentar incluir o estabelecimento.',
                body: args.data.message
			});
        });

        $scope.$on('ponto_alteracoescolaboradores_inserir_lote_erro', (event: angular.IAngularEvent, args: any, response: any) => {
			this.busy = false;
            this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao tentar adicionar o(s) colaborador(es).',
			});
        });

        const constructors = {
            'incluidoponto': '0',
            'desligado': '0'
        };

        this.estabelecimentosHabilitadosService.getListTrabalhadoresPonto(constructors);
        this.$rootScope.$on('ponto_trabalhadores_list_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.entities = [...args];
            this.entitiesCopy = [...this.entities];

            this.busy = false;
        });

    }

    filtraEmpresaEstabelecimento() {
        this.estabelecimentos = this.filters.empresa ? this.listagemService.montaEstabelecimentos(this.filters.empresa, this.empresas) : [];
    }

    filtraObra() {
        this.obrasService.constructors.estabelecimento = this.filters.estabelecimento;
        this.obras = this.obrasService.load();
    }

    salvar() {
        this.busyFilter = true;
        let constructors: any = {};
        constructors['estabelecimento'] = this.filters.estabelecimento;
        constructors['obra'] = this.filters.obra;

        const texto: any = {
            confirmText: 'Essa ação poderá não ser desfeita totalmente, você tem certeza que deseja incluir esse estabelecimento com os dados fornecidos?',
            closeButton: 'Cancelar',
            confirmButton: 'Incluir estabelecimento',
        };

        this.form.$submitted = true;

        const cpfValido = !this.form.cpf.$error.cpfInvalid && !this.form.cpf.$error.mask && this.entity.cpf;

        if (this.filters.empresa && this.filters.estabelecimento && cpfValido && this.entity.nome) {
            const confirm = this.modalConfirmService.open({}, 0, false, texto);
            confirm.result.then((_) => {

                this.busy = true;
                const alteracoescolaboradores: AlteracaoColaborador[] = this.entities.map(entity => {
                    const alteracaoColaborador: AlteracaoColaborador = {
                        trabalhador: {
                            trabalhador: entity.trabalhador
                        },
                        tipoalteracao: entity.tipoalteracao,
                        cpf: entity.cpf,
                        nome: entity.nome,
                        nomeresponsavel: this.entity.nome,
                        cpfresponsavel: this.entity.cpf
                    };

                    if (entity.tipoalteracao === 'E' || entity.tipoalteracao === 'A') {
                        alteracaoColaborador.nsr = entity.nsr;
                    } else {
                        alteracaoColaborador.tipoalteracao = 'I';
                    }

                    return alteracaoColaborador;
                });

                const jsonObject = {
                    alteracoescolaboradores
                };
                const alteracaoColaboradorJson = JSON.stringify(jsonObject);

                const alteracaoEstabelecimento = {
                    obra: this.filters.obra,
                    tipoidentificadorempregador: '2', // 1 - CNPJ | 2 - CPF
                    identificadorempregador: this.entity.cpf,
                    nomeresponsavel: this.entity.nome,
                    cpfresponsavel: this.entity.cpf
                };

                if (this.isColaboradoresAdicionados) {
                    this.estabelecimentosHabilitadosService.inserirAlteracoesColaboradores(constructors, alteracaoColaboradorJson);
                }

                this.estabelecimentosHabilitadosService.inserirAlteracoesEstabelecimento(constructors, alteracaoEstabelecimento);
            });
        }
    }

    adicionarTodosColaboradores() {
        if (!this.todosColaboradoresAdicionados) {
            this.isColaboradoresAdicionados = true;
            this.todosColaboradoresAdicionados = true;
            this.entities = this.entitiesCopy.slice();
            if (this.entities.length === 0) {
                this.NewToaster.pop({
                    type: 'alert',
                    title: 'Nenhum colaborador encontrado.',
                });
            }
        }
    }

    async adicionarColaboradoresPersonalizados(entities: any) {
        this.isColaboradoresAdicionados = false;
        this.todosColaboradoresAdicionados = false;
        let colaboradores = await this.estabelecimentosHabilitadosModalService.open(entities).result;

        if (!this.isColaboradoresAdicionados) {
            this.entities = colaboradores;
        } else {
            this.entities.push(...colaboradores);
        }
        this.isColaboradoresAdicionados = true;

        if (this.entities.length === this.entitiesCopy.length) {
            this.todosColaboradoresAdicionados = false;
        }
    }

    removeColaborador(index: number) {
        this.todosColaboradoresAdicionados = false;
        this.entities.splice(index, 1);
        if (this.entities.length === 0) {
            this.isColaboradoresAdicionados = false;
        }
    }

    cancelar() {
        this.$state.go('estabelecimentoshabilitados');
    }
}
