import angular from 'angular';
import moment from 'moment';
import { MeurhSolicitacoesadiantamentosavulsosService } from '../solicitacoesadiantamentosavulsos.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhSolicitacoesadiantamentosavulsosListController {
  static $inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$location',
    'nsjRouting',
    'NewToaster',
    'MeurhSolicitacoesadiantamentosavulsosService',
    'ListagemService'
  ];

  public busy: boolean = false;
  public service;
  public dataAtual;
  public constructors;
  public maxoccurs;
  public reload;
  public entities;
  public fields;
  public dataInicial: string | null = null;
  public dataFinal: string | null = null;
  public filterSituacao = '';
  public filters;
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;
  public listagemPermissao: Array<string> = ['visualizar_adiantamento_avulso'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
  public options = { 'situacao': 'Situação', 'estabelecimento': 'Estabelecimento' };
  public selects = { 'situacao': { '0': 'Enviada', '1': 'Efetuada', '2': 'Cancelada', '3': 'Excluída', '4': 'Recusada' } };

  constructor(
    public $rootScope: angular.IRootScopeService & {
      temPermissao: (arg: string) => boolean,
      temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
      session: any,
      nsjGlobals: any
    },
    public $scope: angular.IScope,
    public $state: angular.ui.IStateService,
    public $stateParams: angular.ui.IStateParamsService,
    public $location: angular.ILocationService,
    public nsjRouting: any,
    public NewToaster: { pop: Function },
    public entityService: MeurhSolicitacoesadiantamentosavulsosService,
    public ListagemService: ListagemService,
  ) {
    this.dataAtual = moment();
    this.service = this.entityService;
    if (!$rootScope.temPermissao('visualizar_adiantamento_avulso')) {
      $state.go('dp', $stateParams);
      this.entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
    }
    this.filters = this.entityService.filters = {};
    this.montaListagemVisibilidade();
    this.constructors = this.entityService.constructors = {};

    this.constructors.situacao = this.filterSituacao;
    if (this.constructors.situacao === '') {
      delete this.constructors.situacao;
    }

    this.maxoccurs = {};
    for (var i in $stateParams) {
      if (['situacao', 'estabelecimento'].indexOf(i) > -1 && $stateParams[i] !== undefined) {
        this.entityService.filters[i] = $stateParams[i];
      } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
        this.entityService.constructors[i] = $stateParams[i];
      }
    }
    this.entities = this.entityService.reload();
    this.fields = [];

    $scope.$on('meurh_solicitacoesadiantamentosavulsos_deleted', (event) => {
      this.entityService.reload();
    });
    $scope.$on('$destroy', () => {
      if (this.entityService.loadParams.loading_deferred) {
        this.entityService.loadParams.loading_deferred.resolve();
      }
    });

    $scope.$on('meurh_solicitacoesadiantamentosavulsos_submitted', (event, args) => {
      if (!args.autosave) {
        this.entityService.reload();
      }
    });

  }

  montaListagemVisibilidade() {
    this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
    this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
    this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
    this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
  }

  mudaEstabelecimentos() {
    this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
    this.filters.estabelecimento = '';
    this.filtraEmpresaEstabelecimento();
  }

  filtraEmpresaEstabelecimento() {
    this.entityService.constructors['empresa'] = this.filters.empresa;
    this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
    this.filterReload();
  }

  search(filter: { search: string, filters: object }) {
    if (filter.search !== '') {
      this.$location.path(this.$location.path()).search(angular.extend({}, filter.filters, { 'q': filter.search }));
    } else {
      this.$location.path(this.$location.path()).search(angular.extend({}, filter.filters));
    }
    return this.entityService.search(filter);
  }

  finished() {
    return this.entityService.loadParams.finished;
  }

  loadMore() {
    this.entityService.loadMore();
  }

  generateRoute(route: string, params: object) {
    return this.nsjRouting.generate(route, params, true);
  }

  isBusy() {
    return this.entityService.loadParams.busy;
  }

  filterReload() {
    this.constructors.situacao = this.filterSituacao;
    if (this.constructors.situacao === '') {
      delete this.constructors.situacao;
    }

    let created_at: Array<{condition: string, value: string}> = [];
    if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
      this.dataInicial = null;
      this.constructors.datainicial = null;
    }
    let filtroDataInvalido = false;
    if (this.dataInicial !== null && this.dataFinal !== null && this.dataInicial !== '' && this.dataFinal !== '' && this.dataInicial > this.dataFinal) {

      this.NewToaster.pop({
        type: 'error',
        title: 'A data inicial não pode ser posterior a data final'
      });
      filtroDataInvalido = true;
    }
    if (!filtroDataInvalido) {
      if (this.dataInicial !== null) {
        created_at.push(
          {
            condition: 'gte',
            value: this.dataInicial + ' 00:00:00'
          }
        );
        delete this.constructors.datainicial;
      }
      if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
        this.dataFinal = null;
        this.constructors.datafinal = null;
      }
      if (this.dataFinal !== null) {
        created_at.push(
          {
            condition: 'lte',
            value: this.dataFinal + ' 23:59:59'
          }
        );
        delete this.constructors.datafinal;
      }
      this.constructors.created_at = created_at;

      this.entityService.reload();
    }
  }
}
