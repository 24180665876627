
import * as angular from 'angular';

import { MeurhVagasIndexController } from './index/vagas.index.controller';
import { MeurhVagasNewController } from './new/vagas.new.controller';
import { MeurhVagasShowController } from './show/vagas.show.controller';
import { MeurhVagasEditController } from './edit/vagas.edit.controller';
import { MeurhVagasFormController } from './form/vagas.form.controller';
import { MeurhVagasBeneficiosFormController } from './form/beneficios/beneficios.form.controller';
import { MeurhVagasValetransporteFormController } from './form/valetransporte/valetransporte.form.controller';
import { MeurhVagasBeneficiosFormmodalController } from './form/beneficios/formmodal/beneficios.formmodal.controller';
import { MeurhVagasValetransporteFormmodalController } from './form/valetransporte/formmodal/valetransporte.formmodal.controller';

import { MeurhVagasFormComponent } from './form/vagas.form.component';
import { MeurhVagasShowFormComponent} from './showform/vagas.showform.component';
import { MeurhVagasBeneficiosFormComponent } from './form/beneficios/beneficios.form.component';
import { MeurhVagasValetransporteFormComponent } from './form/valetransporte/valetransporte.form.component';
import { MeurhVagasBeneficiosFormmodalComponent } from './form/beneficios/formmodal/beneficios.formmodal.component';
import { MeurhVagasValetransporteFormmodalComponent } from './form/valetransporte/formmodal/valetransporte.formmodal.component';

import { MeurhVagasService } from './vagas.service';
import { MeurhVagasModalService } from './modal/vagas.modal.service';

import { MeurhVagasListRouting } from './vagas.routes';

export const MeurhVagasModule = angular.module('MeurhVagasModule', ['ui.router.state', ])
    .controller('MeurhVagasIndexController', MeurhVagasIndexController)
    .controller('MeurhVagasNewController', MeurhVagasNewController)
    .controller('MeurhVagasFormController', MeurhVagasFormController)
    .controller('MeurhVagasShowController', MeurhVagasShowController)
    .controller('MeurhVagasEditController', MeurhVagasEditController)
    .controller('MeurhVagasBeneficiosFormController', MeurhVagasBeneficiosFormController)
    .controller('MeurhVagasValetransporteFormController', MeurhVagasValetransporteFormController)
    .controller('MeurhVagasBeneficiosFormmodalController', MeurhVagasBeneficiosFormmodalController)
    .controller('MeurhVagasValetransporteFormmodalController', MeurhVagasValetransporteFormmodalController)
    .component('meurhVagasShowFormComponent', MeurhVagasShowFormComponent)
    .component('meurhVagasFormComponent', MeurhVagasFormComponent)
    .component('meurhVagasBeneficiosFormComponent', MeurhVagasBeneficiosFormComponent)
    .component('meurhVagasValetransporteFormComponent', MeurhVagasValetransporteFormComponent)
    .component('meurhVagasBeneficiosFormmodalComponent', MeurhVagasBeneficiosFormmodalComponent)
    .component('meurhVagasValetransporteFormmodalComponent', MeurhVagasValetransporteFormmodalComponent)
    .service('MeurhVagasService', MeurhVagasService)
    .service('MeurhVagasModalService', MeurhVagasModalService)
    .config(MeurhVagasListRouting)
    .name;
