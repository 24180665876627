import angular from 'angular';

// controllers
import { MeurhRelatoriossolicitacoesController } from './index/relatoriossolicitacoes.controller';
import { ModalShowRelatorioController } from './components/modalShowRelatorio/modalShowRelatorio.controller';
import { ModalConfirmRelatoriosController } from './components/modalConfirm/modalConfirmRelatorios.controller';

// services
import { MeurhRelatoriossolicitacoesService } from './relatoriossolicitacoes.service';
import { ModalShowRelatorioService } from './components/modalShowRelatorio/modalShowRelatorio.service';
import { ModalConfirmRelatoriosService } from './components/modalConfirm/modalConfirmRelatorios.service';

// components
import { MeurhRelatoriossolicitacoesComponent } from './index/relatoriossolicitacoes.component';

export const MeurhRelatoriossolicitacoesModule = angular.module('MeurhRelatoriossolicitacoesModule', ['ui.router.state'])
    .controller('MeurhRelatoriossolicitacoesController', MeurhRelatoriossolicitacoesController)
    .controller('ModalShowRelatorioController', ModalShowRelatorioController)
    .controller('ModalConfirmRelatoriosController', ModalConfirmRelatoriosController)
    .service('MeurhRelatoriossolicitacoesService', MeurhRelatoriossolicitacoesService)
    .service('ModalShowRelatorioService', ModalShowRelatorioService)
    .service('ModalConfirmRelatoriosService', ModalConfirmRelatoriosService)
    .component('meurhRelatoriossolicitacoesComponent', MeurhRelatoriossolicitacoesComponent)
    .name;
