import angular from 'angular';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class CargosSalariosIndexController {
    static $inject = ['$rootScope', '$scope', 'nsjRouting', '$http', 'NewToaster', 'RelatoriosService', 'CargosSalariosService', 'ListagemService', '$state'];

    public trabalhadores: any = [];
    public lookupTrabalhadores: any = false;
    public primeiroLookupTrabalhadoresCarregado: any = false;
    public busy: boolean;
    public carregandoRelatorio: boolean;
    public todosSelecionados: any;
    public checkboxTodos: any;
    public listagemPermissao: Array<string> = ['relatorios_cargossalarios'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;
    public filters: any = {
        tipo: '-1',
        trabalhador: null,
        situacao: '00',
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
    };
    public tipoTrabalhador: any = [
        'Funcionário',
        'Contribuinte',
        'Estagiário'
    ];

    constructor(
        public $rootScope: any,
        public $scope: any,
        public nsjRouting: any,
        public $http: any,
        public NewToaster: any,
        public RelatoriosService: any,
        public CargosSalariosService: any,
        public ListagemService: ListagemService,
        public $state: any,
    ) {
        this.montaListagemVisibilidade();

        $rootScope.$on('Trabalhadores_loading', (event: any, args: any) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event: any, args: any) => {
            this.lookupTrabalhadores = false;
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filters.trabalhador;
        this.carregarRelatorio();
    }

    carregandoLookups() {
        return this.lookupTrabalhadores;
    }

    carregarRelatorio() {
        this.setTodosSelects(false);
        let construtor = this.montarConstrutor();
        this.CargosSalariosService.constructors = construtor;
        this.busy = true;
        this.trabalhadores = this.CargosSalariosService.reload();
        this.busy = false;
        this.$rootScope.$broadcast('trabalhador_refresh', this.montaConstructorColaboradores());
    }

    getTipoTrabalhador(tipo: any) {
        return !isNaN(tipo) ? this.tipoTrabalhador[parseInt(tipo, 10)] : tipo;
    }

    montarConstrutor(relatorio: any = 0, usarSelects: any = false) {
        let construtor: any = angular.copy(this.filters);
        construtor.relatorio = relatorio;
        if (usarSelects) {
            delete construtor.trabalhador;
            construtor.entities = this.montarArrayTrabalhadoresSelecionados();
        } else if (construtor.trabalhador && typeof construtor.trabalhador === 'object' && !usarSelects) {
            construtor.trabalhador = construtor.trabalhador.trabalhador;
        } else {
            delete construtor.trabalhador;
        }
        return construtor;
    }

    abrirRelatorio() {
        this.carregandoRelatorio = true;
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        let config = {
            constructors: this.montarConstrutor(1, this.peloMenosUmSelecionado()),
            fileName: 'cargossalarios.pdf',
            useNewToaster: true
        };
        this.RelatoriosService.baixarRelatorioFopPost('relatoriosliberados_cargossalariosfop', config, callback);
    }

    montaConstructorColaboradores() {
        return { 'status': '01', 'tipo_excluir': 1, 'empresa': this.filters.empresa, 'estabelecimento': this.filters.estabelecimento, 'tipo': this.filters.tipo, 'forcagestor': this.$rootScope.modoGestorPermissao('relatorios_cargossalarios') };
    }

    montarArrayTrabalhadoresSelecionados() {
        let arr: any = [];
        for (let i = 0; i < this.trabalhadores.length; i++) {
            if (this.trabalhadores[i].selected) {
                arr.push(this.trabalhadores[i].trabalhador);
            }
        }
        return arr;
    }

    peloMenosUmSelecionado() {
        return this.trabalhadores.some(x => x.selected);
    }

    setTodosSelects(to: any) {
        this.todosSelecionados = to;
        for (let i = 0; i < this.trabalhadores.length; i++) {
            this.trabalhadores[i].selected = to;
        }
    }

    atualizarSelect() {
        let todosSelecionados = true;
        for (let i = 0; i < this.trabalhadores.length; i++) {
            if (!this.trabalhadores[i].selected) {
                todosSelecionados = false;
            }
        }
        this.todosSelecionados = todosSelecionados;
    }

    isBusy() {
        return this.CargosSalariosService.loadParams.busy;
    }

    finished() {
        return this.CargosSalariosService.loadParams.finished;
    }

    checkboxEntidades(acao: any = null) {
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (acao === null) {
                entity.check = this.checkboxTodos ? true : false;
            } else {
                entity.check = acao;
            }
        });
        if (acao !== null) {
            this.checkboxTodos = acao ? true : false;
        }
    }

    quantidadeFuncionariosSelecionados() {
        let qtd = 0;
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (entity.check) {
                qtd++;
            }
        });
        return qtd;
    }


    todosFuncionariosSelecionados() {
        let selecionado = true;
        if (this.trabalhadores.length === 0) {
            selecionado = false;
        }
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (entity.check === null || entity.check === false) {
                selecionado = false;
            }
        });
        return selecionado;
    }

    nenhumFuncionarioSelecionado() {
        let nenhumSelecionado = true;
        angular.forEach(this.trabalhadores, (entity, index) => {
            if (entity.check) {
                nenhumSelecionado = false;
            }
        });
        return nenhumSelecionado;
    }

    loadMore(): void {
        this.CargosSalariosService.loadMore();
    }

}
