import angular from 'angular';

import { AtestadosAVencerIndexController } from './index/atestadosavencer.index.controller';

import { AtestadosAVencerListRouting } from './atestadosavencer.routes';

import { AtestadosAVencerService } from './atestadosavencer.service';

export const AtestadosAVencerModule = angular.module('AtestadosAVencerModule', ['ui.router.state', ])
    .controller('AtestadosAVencerIndexController', AtestadosAVencerIndexController )
    .service('AtestadosAVencerService', AtestadosAVencerService)
    .config(AtestadosAVencerListRouting)
    .name;
