import { Empresa, Estabelecimento, Grupoempresarial } from '../../core/usuario/models/usuario-model';
import angular from 'angular';

export class ListagemService {

    static $inject = ['$rootScope'];

    constructor(public $rootScope: angular.IRootScopeService & { nsjGlobals: any }) {
    }

    montaEmpresas(grupoEmpresarialCodigo: string, permissao: Array<string> | null = null): Array<Empresa> {
        let gruposEmpresariais: Array<Grupoempresarial> = this.$rootScope.nsjGlobals.a.permissoesagrupadas.gruposempresariais;
        let grpEmpSelecionado = gruposEmpresariais.find((grpEmpresarial) => grpEmpresarial.codigo === grupoEmpresarialCodigo);
        if (grpEmpSelecionado) {
            if (permissao) {
                return grpEmpSelecionado.empresas.filter(empresa => {
                    return !permissao.some(perm => !empresa.permissoes.hasOwnProperty(perm));
                });
            }
            return grpEmpSelecionado.empresas;
        }
        return [];
    }

    montaEstabelecimentos(empresaId: string, empresas: Array<Empresa> | null = null, permissao: Array<string> | null = null): Array<Estabelecimento> {
        if (!empresas) {
            empresas = this.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial);
        }
        let empresaSelecionada = empresas.find((empresa) => empresa.id === empresaId);
        if (empresaSelecionada && empresaSelecionada.estabelecimentos) {
            if (permissao) {
                return empresaSelecionada.estabelecimentos.filter(estabelecimento => {
                    return !permissao.some(perm => !estabelecimento.permissoes.hasOwnProperty(perm));
                });
            }
            return empresaSelecionada.estabelecimentos;
        }
        return [];
    }
}
