import angular from 'angular';
import { MeurhSolicitacoesalteracoesenderecosService } from '../solicitacoesalteracoesenderecos.service';
import { ISolicitacaoAlteracaoEndereco } from '../models/solicitacaoalteracaoendereco.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhSolicitacoesalteracoesenderecosShowController implements angular.IController {
    static $inject = [
        'ModalConfirmService',
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesalteracoesenderecosService',
        'entity',
        'NewToaster',
        '$rootScope',
        'MeurhAcompanhamentoService',
        'WorkflowService',
        'MeurhSolicitacoes',
        'TiposDocumentosRequeridosService'
    ];

    public action: string = 'show';
    public edit: boolean = false;
    public editanexo: boolean = false;
    public form: angular.IFormController;
    public formAnexo: object = {};
    public entityAnexo: IAnexo = {
        casodeuso: 'ALTERACOESENDERECOS'
    };
    public actionAnexo: string = 'insert';
    public trabalhador: string;
    public busy: boolean = false;
    public busyTipoDocumentos: boolean = true;
    public tiposdocumentosrequeridos: any;
    public path: number = this.$stateParams.path;

    constructor(
        public ModalConfirmService: any,
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesenderecosService,
        public entity: ISolicitacaoAlteracaoEndereco,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
        public MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        public WorkflowService: WorkflowService,
        public $MeurhSolicitacoes: any,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService
    ) {
        $rootScope.$on('meurh_solicitacoesalteracoesenderecos_loaded', (event: angular.IAngularEvent, args: any) => {
            this.trabalhador = args.trabalhador.trabalhador;
        });
    }

    $onInit(): void {
        this.carregaAcompanhamento();
        this.entityService.get(this.$stateParams['solicitacao']);
        this.documentosAnexos();

        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any) => {
            this.$state.reload();
        });

        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesalteracoesenderecos_');

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                tipodocumentorequerido.file = '';
                return tipodocumentorequerido;
            });
        });
    }

    goToEdit(): void {
        this.busy = true;
        this.$state.go('meurh_solicitacoesalteracoesenderecos_edit', angular.extend({}, {
            'solicitacao': this.entity.solicitacao,
            'estabelecimento': this.$stateParams['estabelecimento']
        }));
    }

    cancelar(entity: ISolicitacaoAlteracaoEndereco): void {
        let customText = {
            title: 'Cancelar envio',
            subtitle: 'Ao cancelar, não será possível desfazer e todas as informações serão perdidas.',
            confirmText: 'Tem certeza que deseja cancelar o envio?',
            closeButton: 'Voltar',
            confirmButton: 'Cancelar envio',
        };
        let confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de alteração de endereço', customText);
        confirm.result.then((entity: ISolicitacaoAlteracaoEndereco) => {
            this.busy = true;
            this.$MeurhSolicitacoes.cancelar(entity)
                .then((response: any) => {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A Solicitação de Alteração de Endereço foi cancelada com sucesso'
                    });
				    this.$rootScope.$broadcast('meurh_solicitacoesalteracoesenderecos_cancelada');
                    this.$state.go('meurh_solicitacoesalteracoesenderecos');
                }).catch((response: any) => {
                    if (typeof (response.message) !== 'undefined' && response.message) {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: response.message
                            });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro ao cancelar.'
                        });
                    }
                }).finally((response: any) => {
                    this.busy = false;
                });
        }).catch((error: any) => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    documentosAnexos(): void {
        const estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
        this.setEntityAnexo({
            solicitacao: this.entity.solicitacao,
            estabelecimento
        });
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.carregaTiposDocumentosRequeridos({
                empresa: this.entity.empresaobj.empresa,
                estabelecimento
            });
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
    }

    carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.ALTERACAO_DADOS_CADASTRAIS,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

    carregaAcompanhamento(): void {
        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.loadParams.finished = false;
        this.MeurhAcompanhamentoService.loadParams.to_load = 3;
        this.MeurhAcompanhamentoService.load();
    }

    resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    setEntityAnexo(params: { solicitacao: string, estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
        this.entityAnexo['solicitacao'] = params.solicitacao;
    }
}
