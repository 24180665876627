import angular from 'angular';
import { IApontamentoImportacaoArquivo } from '../models/apontamentosimportacao.model';
import { OperacaoApontamentosImportacaoEnum } from '../enums/operacao';

export class ApontamentosImportacaoModalService {
    static $inject = ['$uibModal'];

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService
    ) {
    }

    open(entity: IApontamentoImportacaoArquivo): angular.ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./apontamentosimportacao.importarmodal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                '$scope',
                'NewToaster',
                function ApontamentosImportacaoModal(
                    entity: IApontamentoImportacaoArquivo,
                    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                    $scope: angular.IScope,
                    NewToaster: any
                ): void {
                    let selfModal = this;
                    selfModal.isFileValid = false;
                    selfModal.entity = entity;
                    selfModal.operacaoEnum = OperacaoApontamentosImportacaoEnum;
                    selfModal.NewToaster = NewToaster;

                    this.fileTypeValidation = (): void => {
                        let allowedExtensions = /(\.csv)$/i;
                        if (!allowedExtensions.exec(selfModal.entity.file[0].name)) {
                            selfModal.NewToaster.pop({
                                type: 'error',
                                title: 'Tipo de arquivo inválido',
                                body: 'Apenas arquivo do tipo CSV é permitido',
                            });
                            selfModal.isFileValid = false;
                            selfModal.entity.file = [];
                        } else {
                            selfModal.isFileValid = true;
                        }
                        $scope.$applyAsync();
                    };

                    this.formatBytes = (bytes: number, decimals: number = 2): string => {
                        if (bytes === 0) {
                            return '0 Bytes';
                        }

                        const k = 1024;
                        const dm = decimals < 0 ? 0 : decimals;
                        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                        const i = Math.floor(Math.log(bytes) / Math.log(k));

                        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
                    };

                    this.submit = (): void => {
                        $uibModalInstance.close(selfModal.entity);
                    };

                    this.close = (): void => {
                        $uibModalInstance.dismiss('cancelar');
                    };
                }
            ],
            controllerAs: '$ctrl',
            windowClass: 'nsj-modal-lg-no-height',
            resolve: {
                entity: (): IApontamentoImportacaoArquivo => {
                    return entity;
                }
            }
        });
    }
}
