import angular from 'angular';
import { MeurhEscalaColaboradoresFormController } from './colaboradores.form.controller';

export class MeurhEscalaColaboradoresFormComponent implements angular.IComponentOptions {
	static selector = 'meurhEscalaColaboradoresFormComponent';
	static controller = MeurhEscalaColaboradoresFormController;
	static template = require('!!html-loader!./colaboradores.form.html');
	static transclude = true;
	static bindings = {
		empresa: '<',
		estabelecimento: '<',
		entity: '=',
		disabled: '<'
	};
}
