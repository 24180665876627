import * as angular from 'angular';
import { ApuracaoPontoController } from './apuracaoponto.controller';

export class ApuracaoPontoComponent implements angular.IComponentOptions {
	static selector = 'apuracaoPontoComponent';
	static controller = ApuracaoPontoController;
	static template = require('!!html-loader!./apuracaoponto.html');
	static transclude = true;
	static bindings = {
		entities: '=',
		apuracoesPontoService: '=',
		busy: '=',
	};
}
