declare var nsjGlobals: any;
import ng from 'angular';
import moment from 'moment';
import { StatusProcessoEnum } from '../../enums/statusprocesso';

export class MeurhAdmissoesDocumentacaoFormController implements ng.IController {

    static $inject = [];

    public uFArray = [
        'AC', 'AL', 'AM', 'AP', 'BA', 'CE',
        'DF', 'ES', 'GO', 'MA', 'MG', 'MS',
        'MT', 'PA', 'PB', 'PE', 'PI', 'PR',
        'RJ', 'RN', 'RO', 'RR', 'RS', 'SC',
        'SE', 'SP', 'TO'
    ];
    public categoriaCnh = [
        'A', 'B', 'C', 'D', 'E',
        'AB', 'AC', 'AD', 'AE'
    ];

    public entity: any;
    public form: angular.IFormController;
    public action: string;

    public ctpsPreenchida: boolean = false;
    public cnhPreenchida: boolean = false;

    // validação de datas
    public anoMinimo: moment.Moment;
    public anoMaximo: moment.Moment;
    public dataAtual: moment.Moment = moment();

    public statusProcessoEnum = StatusProcessoEnum;

    public clienteAna: boolean;

    constructor() {
        this.bloqueiaAnoMinimo();
        this.bloqueiaAnoMaximo();

        this.clienteAna = nsjGlobals.get('clienteana');
    }

    public sinalizaObrigatorio(documentoInformado: boolean, campoInformado: boolean, campoInvalido: boolean= false): boolean {
        return (
            documentoInformado &&
            !campoInformado &&
            !campoInvalido &&
            this.form && (this.form.$submitted || this.form.$dirty)
        );
    }

    public preenchePix(): void {
        if (this.entity.tiporecebimentosalario === '128') { // cpf
            this.entity.chavepix = this.entity.cpf;
        }
    }

    public verificaCtps(): void {
        this.ctpsPreenchida = !!this.entity.numeroctps;
    }

    public verificaCnh(): void {
        this.cnhPreenchida = !!this.entity.numerocnh;
    }

    // inicio - tratamento de datas
    private bloqueiaAnoMinimo(): void {
        this.anoMinimo = moment().subtract(120, 'years'); // > 120 anos
    }

    private bloqueiaAnoMaximo(): void {
        this.anoMaximo = moment().add(120, 'years'); // < 120 anos
    }
    // fim - tratamento de datas
}
