import angular = require('angular');
import { IAnexo } from '../../Anexos/models/anexo.model';
import { ISolicitacaoAdmissao } from '../models/solicitacaoadmissao.model';
import { MeurhSolicitacoesadmissoesService } from '../solicitacoesadmissoes.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';

export class MeurhSolicitacoesadmissoesEditController implements ng.IController {
    static $inject = ['$scope', '$stateParams', '$state', 'MeurhSolicitacoesadmissoes', 'entity', 'NewToaster', 'ModalConfirmService', '$sce', '$http', '$q', 'nsjRouting', '$rootScope', '$window', 'MeurhSolicitacoes', 'TiposDocumentosRequeridosService'];

    public action: string = 'retrieve';
    public reload = this.entityService.reload;
    public busy: boolean = false;
    public busyTipoDocumentos: boolean = true;
    public edit: boolean = false;
	public editanexo: boolean = true;
	public alterouanexo: boolean = false;
    public adicionarAnexo: boolean = false;
    public actionUpdate: string = 'update';
    public form: angular.IFormController;
    public idCount: number = 0;
    public formAnexo: object = {};
    public entityAnexo: IAnexo = {};
    public actionAnexo: string = 'update';
    public constructors: any = [];
    public entityAnexoLista: any = [];
    public mimeType: string;
    public filelist: any = [];
    public salarioValido: boolean = true;
    public LABELS: any = {
        '0': 'label-warning',
        '1': 'label-success',
        '2': 'label-danger'
    };
    public SITUACOES: any = {
        '0': 'Enviada',
        '1': 'Efetuada',
        '2': 'Cancelada',
        '4': 'Recusada'
    };

    public solicitacao: any;
	public tiposdocumentosrequeridos: Array<any>;
	public validate: number = 0; // conta quantos erros há em documentos anexos

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesadmissoesService,
        public entity: ISolicitacaoAdmissao,
        public NewToaster: any,
        public ModalConfirmService: any,
        public $sce: angular.ISCEService,
        public $http: angular.IHttpService,
        public $q: angular.IQService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $window: angular.IWindowService,
        public $MeurhSolicitacoes: any,
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService
    ) {
        this.entityAnexo['casodeuso'] = 'ADMISSOES';
        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        /*
        this.busy = true;
        this.entityService.get(this.$stateParams.solicitacao);
        $scope.$on('meurh_solicitacoesadmissoes_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.entity = args;
            this.entityAnexo['solicitacao'] = this.entity.solicitacao;
            this.busy = false;
        });
        */
    }

    $onInit(): void {
        this.onDeleteSuccess();
        this.onDeleteError();
        this.onSubmitSuccess();
        this.onSubmitAnexoSuccess();

        this.resetTiposDocumentosRequeridosService();
		this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});
    }

	resetTiposDocumentosRequeridosService(): void {
        this.tiposdocumentosrequeridos = [];
		this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.ADMISSAO_PRELIMINAR };
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
		this.TiposDocumentosRequeridosService.entities = [];
	}

    submit(rascunho: boolean = false): void {
        let podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
			return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
		});

        if (this.form.$valid && !this.entity.$$__submitting && podeSalvar) {
            if (rascunho === false && this.entity.situacao === -1) {
                this.entity.situacao = 0;
            }

            if (this.entity.solicitacoesadmissoesdocumentos) {
                let solicitacoesadmissoesdocumentos = this.entity.solicitacoesadmissoesdocumentos;
                delete this.entity.solicitacoesadmissoesdocumentos;
            }

            this.entityService.salvar(this.entity)
                .then((response: any): void => {
                    this.entityAnexo['solicitacaohistorico'] = response.data.solicitacaohistorico;
                    this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {'solicitacaohistorico': response.data.solicitacaohistorico, 'filelist': this.filelist});
                })
                .catch((error: any): void => {

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao salvar Solicitação de Admissão.'
                    });
                });
        } else {
            if (!podeSalvar) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Há documento(s) obrigatório(s) não informado(s)'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos do formulário apresentam erros'
                });
            }
        }
    }

    descartar(): void {

        if ( this.form.cargo.$dirty === true ||
        this.form.cpf.$dirty === true ||
        this.form.dataadmissao.$dirty === true ||
        this.form.datanascimento.$dirty === true ||
        this.form.departamento.$dirty === true ||
        this.form.horario.$dirty === true ||
        this.form.nome.$dirty === true ) {
            let textos = {
                title: 'Descartar Admissão Preliminar',
                subtitle: 'Após o descarte não será mais possível reverter a ação.',
                confirmText: 'Você tem certeza que deseja descartar as alterações no formulário da Admissão Preliminar',
                confirmTextNum: '',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, descartar alterações',
            };
            let confirm = this.ModalConfirmService.open(this.entity, 'Admissão Preliminar', false, textos);

            confirm.result.then((): void => {
                this.busy = true;
                this.$state.go('meurh_solicitacoesadmissoes_show', {'solicitacao': this.entity.solicitacao});
            })
            .catch((error: any): void => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
        } else {
            this.busy = true;
            this.$state.go('meurh_solicitacoesadmissoes_show', {'solicitacao': this.entity.solicitacao});

        }
	}

    setAdicionarAnexo(value: boolean): void {
        this.adicionarAnexo = value;
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = true;
            if (this.tiposdocumentosrequeridos.length <= 0 || !this.alterouanexo) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Sucesso ao editar Solicitação de Admissão Preliminar!'
                });
                this.busy = false;
                this.$state.go('meurh_solicitacoesadmissoes_show', {'solicitacao': this.entity.solicitacao});
            } else {
                this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                    this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
                    if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'Sucesso ao editar Solicitação de Admissão Preliminar!'
                        });
                        this.busy = false;
                        this.$state.go('meurh_solicitacoesadmissoes_show', {'solicitacao': this.entity.solicitacao});
                    }
                });
            }
        });
    }

    onSubmitAnexoSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesdocumentos_create_success', (event: angular.IAngularEvent, args: any): void => {
            this.setAdicionarAnexo(false);
        });
    }

    downloadDocumento(documento: any): angular.IHttpPromise<any> {
        this.constructors = [];
        this.constructors.id = documento.solicitacaodocumento;

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http.get(
                    this.nsjRouting.generate('meurh_solicitacoesdocumentos_download', angular.extend({ }, this.constructors, { }), true),
                    { responseType: 'arraybuffer'}
                )
                .then((response: any): void => {
                    var nomeArquivo = '';
                    var blob = new Blob([response.data]);
                    var fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(blob);
                    var a = document.createElement('a');

                    nomeArquivo = documento.tipodocumentocolaborador.descricao + '.' + documento.extensao;

                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.href = fileURL;
                    a.download = nomeArquivo;
                    a.click();
                    this.$window.URL.revokeObjectURL(fileURL);

                    resolve(response.data);
                })
                .catch((response: any): void => {
                    reject(response);
                });
        });
    }

    delete(force: boolean): void {
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação excluída com sucesso!'
            });

            this.$state.go('meurh_solicitacoesadmissoes', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_delete_error', (event: angular.IAngularEvent, args: any): void => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao excluir Solicitação.'
                });
            }
        });
    }

    getLabels(situacao: string): string {
        return this.LABELS[situacao];
    }

    getSituacao(situacao: string): string {
        return this.SITUACOES[situacao];
    }
}
