import angular = require('angular');

export class MeurhConfirmarEnvioModalController implements ng.IController {
  static $inject = ['$uibModalInstance'];

  constructor(
    public $uibModalInstance: any,
  ) {
  }

  ok() {
    this.$uibModalInstance.close(true);
  }

  close() {
    this.$uibModalInstance.close(false);
  }
}
