import * as angular from 'angular';
import { MeurhPromocoesPassotresController } from './promocoes.passotres.controller';

export class MeurhPromocoesPassotresComponent implements angular.IComponentOptions {
	static selector = 'meurhPromocoesPassotresComponent';
	static controller = MeurhPromocoesPassotresController;
	static template = require('!!html-loader!./promocoes.passotres.html');
	static transclude = true;
	static bindings = {
		passo: '=',
		entity: '=',
		entitySolicitacao: '=',
		mensagensPassos: '=',
	};
}
