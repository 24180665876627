import angular from 'angular';

import { ModalConfirmController } from './modal.confirm.controller';
import { ModalDescartarController } from './modal.descartar/modal.descartar.controller';
import { ModalCancelarController } from './modal.cancelar/modal.cancelar.controller';
import { ModalConfirmService } from './modal.confirm.service';

export const ModalConfirmModule = angular.module('ModalConfirmModule', ['ui.router.state'])
    .controller('ModalConfirmController', ModalConfirmController)
    .controller('ModalDescartarController', ModalDescartarController)
    .controller('ModalCancelarController', ModalCancelarController)
    .service('ModalConfirmService', ModalConfirmService)
    .name;
