import angular = require('angular');
import moment = require('moment');
import { IPromocao } from '../models/promocao.model';
import { TrabalhadoresService } from 'modules/Trabalhadores/trabalhadores.service';
import { MeurhSolicitacoespromocoesService } from '../promocoes.service';

export class MeurhPromocoesWizardController implements ng.IController {
    static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'TrabalhadoresService', '$sce', 'MeurhSolicitacoespromocoesService'];

    public busy: boolean = false;
    public dataAtual: string;
    public entity: IPromocao;
    public entitySolicitacao: any = {};
    public trabalhador: string = this.$stateParams['trabalhador'];
    public colaboradorSelecionado: any = {};
    public passoZeroAtivo: string = this.$stateParams['passozeroativo'] ? this.$stateParams['passozeroativo'] : '0';
    public passo: number;
    public mensagensPassos: any = {};

    constructor(
        public $rootScope: angular.IRootScopeService,
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: angular.ui.bootstrap.ITransitionService,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: TrabalhadoresService,
        public $sce: angular.ISCEService,
        public MeurhSolicitacoespromocoesService: MeurhSolicitacoespromocoesService,
    ) {
        if (!this.valorExiste(this.dataAtual)) {
            this.dataAtual = moment().format('YYYY-MM-DD');
        }
    }

    $onInit(): void {
        this.passo = this.passoZeroAtivo === '1' ? 0 : 1;
    }

    isBusy(): boolean {
        return this.busy;
    }

    valorExiste(valor: string): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
