import * as angular from 'angular';
import { ApontamentosTrabalhadoresService } from './apontamentostrabalhadores.service';
import { ApontamentosTrabalhadoresFormController } from './form/apontamentostrabalhadores.form.controller';
import { ApontamentosTrabalhadoresModalController } from './modal/modal.controller';
import { ApontamentosTrabalhadoresModalService } from './modal/modal.service';
import { ApontamentosTrabalhadoresFormComponent } from './form/apontamentostrabalhadores.form.component';

export const ApontamentosTrabalhadoresModule = angular.module('ApontamentosTrabalhadoresModule', ['ui.router.state'])
    .controller('ApontamentosTrabalhadoresFormController', ApontamentosTrabalhadoresFormController)
    .controller('ApontamentosTrabalhadoresModalController', ApontamentosTrabalhadoresModalController)
    .service('ApontamentosTrabalhadoresService', ApontamentosTrabalhadoresService)
    .service('ApontamentosTrabalhadoresModalService', ApontamentosTrabalhadoresModalService)
    .component('apontamentosTrabalhadoresFormComponent', ApontamentosTrabalhadoresFormComponent)
    .name;
