import angular = require('angular');
import { LoadParams } from '../../../shared/models/loadparams.model';
// import { IAdmissaoEstagiario } from './models/vtadicional.model';

export class MeurhAdmissaoestagiarioInstituicoesService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    // a IAdmissaoEstagiario
    public entity: any;
    public entities: Array<any> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams = new LoadParams();
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService) {}

    // a IAdmissaoEstagiario
    reload(): Array<any> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    // c IAdmissaoEstagiario
    search(filter: any = undefined): Array<any>  {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    // v IAdmissaoEstagiario
    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('instituicoes_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    // v IAdmissaoEstagiario
    load(): Array<any> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                        this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                        this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                    }
                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoesestagiario_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }
                    this.loaded = true;

                    this.loadParams.to_load--;

                    if (this.loadParams.to_load === 0 || data.length <= 20) {
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoesestagiario_loaded', this.entities);
                    }
                })
                .catch((error: any) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    // v IAdmissaoEstagiario
    find(identifier: string): any | null {
        for (var i in this.entities) {
            if (this.entities[i]['solicitacao'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('meurh_solicitacoesadmissoesestagiario_get', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoesestagiario_get_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    getFoto(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('meurh_solicitacoesadmissoesestagiario_foto', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoesestagiario_get_foto_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoesestagiario_get_foto_error', response.data);
                });
        });
    }

}
