import angular from 'angular';
import moment from 'moment';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IVtadicional } from '../models/vtadicional.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhSolicitacoesvtsadicionaisFormController implements angular.IController {
	static $inject = [
    '$scope',
    '$element',
    '$attrs',
    'NewToaster',
    '$stateParams',
    '$rootScope',
    'ListagemService'
  ];

  public tipo: string = this.$stateParams.tipo;
  public action: string;
  public busy: boolean;
  public posteriordatafinal: boolean = false;
	public form: angular.IFormController;
  public entity: IVtadicional;
  public filter: any;

  public filters = {
    empresa: this.$rootScope.session.entities.empresa.empresa,
    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
  };
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;
  public listagemPermissao: Array<string> = ['criar_vtadicional'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

  public constructorLookupColaborador;

  constructor (
    public $scope: angular.IScope,
		public $element: any,
		public $attrs: any,
		public NewToaster: any,
		public $stateParams: angular.ui.IStateParamsService,
		public $rootScope: angular.IRootScopeService & {
      temPermissao: (arg: string) => boolean,
      modoGestorPermissao: (arg: string) => boolean,
      temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
      liberadoTela: (arg: string) => boolean,
      session: any,
      nsjGlobals: any,
    },
    public ListagemService: ListagemService
  ) {
    $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
      if (newValue !== oldValue) {

        // se mudou o tipo conteúdo, apago o valor do conteúdo
        let mudouTipoConteudo: boolean = newValue.tipoconteudo !== oldValue.tipoconteudo;

        if (mudouTipoConteudo) {
          newValue.conteudo = null;
        }

        this.form.conteudo.$setValidity('onlyintegers', true);

        if (newValue.datafinal !== null && newValue.datainicial !== null && newValue.datafinal !== '' && newValue.datainicial !== '') {
          if (moment(newValue.datainicial).isAfter(moment(newValue.datafinal))) {
            this.form.datainicial.$setValidity('posteriordatafinal', false);
          } else {
            this.form.datainicial.$setValidity('posteriordatafinal', true);
          }
        }

        if (newValue.conteudo <= 0 && !mudouTipoConteudo) {
          this.form.conteudo.$setValidity('valorzero', false);
        } else {
          this.form.conteudo.$setValidity('valorzero', true);
        }

        // se o tipo de conteúdo for quantidade de dias
        if (newValue.tipoconteudo === 0 && newValue.conteudo) {
          if (Number.isInteger(newValue.conteudo)) {
            this.form.conteudo.$setValidity('onlyintegers', true);
          } else {
            this.form.conteudo.$setValidity('onlyintegers', false);
          }
        } else {
          this.form.conteudo.$setValidity('onlyintegers', true);
        }

        this.form.$setDirty();
      }
    }, true);

  }

  $onInit(): void {
    this.montaListagemVisibilidade();

    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };

  }

  montaListagemVisibilidade(): void {
    if (this.action === 'update') {
      this.filters.empresa = this.entity.estabelecimentoobj.empresa;
      this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
    }
    this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
    this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
  }

  mudaEstabelecimentos(): void {
    this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
    this.filters.estabelecimento = '';
    this.filtraEmpresaEstabelecimento();
  }

  filtraEmpresaEstabelecimento(): void {
    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };
  }

  forcaGestor(): boolean {
    return this.$rootScope.modoGestorPermissao('criar_vtadicional');
  }

}
