import ng from 'angular';
import { IWorkflowAcao } from '../../models/workflow.acao.model';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';

export class WorkflowOldModalController implements ng.IController {

    static $inject = [
        'acao',
        'trabalhador',
        '$uibModalInstance'
    ];

    constructor(
        public acao: IWorkflowAcao,
        public trabalhador: ITrabalhador,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    ) {
        this.dadosIniciais();
    }

    // início - ações do modal
    public confirmar(): void {
        if (!(this.acao.requiredcomment && this.acao.comentario === '')) {
            this.$uibModalInstance.close(this.acao);
        }
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }
    // fim - ações do modal


    // início - definição de dados iniciais
    private dadosIniciais(): void {
        if (this.acao.modal) {
            this.tratarTextosModal();
        }
    }
    // fim - definição de dados iniciais


    // início - tratamento de dados
    private tratarTextosModal(): void {
        let textos: string[] = [];

        // verifica qual o tipo do modal para o tratamento dos textos
        if (this.acao.modal!.comentario) {
            textos = this.acao.modal!.comentario!.textos_abaixo;
        } else if (this.acao.modal!.confirmacao) {
            textos = this.acao.modal!.confirmacao!.textos;
        }

        this.tratarNomeColaborador(textos);
    }

    private tratarNomeColaborador(textos: string[]): void {
        // corrige a string '{{nome_colaborador}}' vinda do backend
        textos.forEach((texto: string, index: number) => {
            const textoModificado: string = texto.replace('{{nome_colaborador}}', this.trabalhador?.nome);
            textos[index] = textoModificado;
        });
    }
    // fim - tratamento de dados
}
