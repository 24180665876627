import angular = require('angular');
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { IDadosInit } from '../models/dadosInit.model';
import { IMensagensPassos } from '../models/mensagensPassos.model';

export class MeurhCreditosdescontosWizardController {

    static $inject = ['$stateParams'];

    public busy: boolean = false;

    public entity: any = {};
    public entityDadosCriacao: IDadosInit = {
        dataFolhaAdiantamentoSalarial: '',
        dataFolhaPagamento: ''
    };

    public passo: number = 2;
    public passoUmAtivo: string = this.$stateParams['passoumativo'] ? this.$stateParams['passoumativo'] : '0';

    public trabalhador: string = this.$stateParams['trabalhador'];

    public mensagensPassos: IMensagensPassos = {};

    public entitiesCreditosDescontos: Array<Partial<ICreditoDesconto>> = [];
    public selecionadosCreditosDescontos: Array<Partial<ICreditoDesconto>> = [];

    constructor(
        public $stateParams: angular.ui.IStateParamsService,
    ) {
    }

    $onInit() {
        if (this.passoUmAtivo === '1') {
            this.passo = 1;
        }
    }

    isBusy() {
        return this.busy;
    }

}
