import { IRendimento } from '../../models/rendimentos.model';

export class MeurhRendimentosModalService {
    static $inject = ['$uibModal'];

    constructor(public $uibModal: ng.ui.bootstrap.IModalService) {}

    open(entity: { rendimentos: Array<IRendimento>, total: string }) {
        return this.$uibModal.open({
            template: require('html-loader!./rendimentos.modal.html'),
            controller: 'MeurhRendimentosModalController',
            controllerAs: 'mrh_rndmnts_mdl_cntrllr',
            windowClass: 'nsj-modal-lg-no-height',
            resolve: {
                entity: () => {
                    return entity;
                }
            }
        });
    }
}
