import angular from 'angular';
import { TrabalhadoresService } from './trabalhadores.service';

export class TrabalhadoresNewService extends TrabalhadoresService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q', '$filter', ];

    public constructors: any = {};

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService,
        public $filter: any,
    ) {
        super($http, nsjRouting, $rootScope, $q, $filter);
    }

    search(filter?: any): angular.IHttpPromise<any> {
        let search = '';

        if (typeof filter !== 'undefined' && filter != null) {
            search = filter.search ? filter.search : '';

            Object.keys(this.filters).forEach((k): void => {
                delete this.filters[k];
            });

            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');

                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        } else if (filter == null) {
            return this._load(this.constructors, {}, '');
        }

        return this._load(this.constructors, {}, search);

    }

}
