import angular from 'angular';
import { MeurhAdmissoesDependentesFormController } from './dependentes.form.controller';

export class MeurhAdmissoesDependentesFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesDependentesFormComponent';
	static controller = MeurhAdmissoesDependentesFormController;
	static template = require('!!html-loader!./dependentes.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '=',
		busy: '=',
		action: '<',
	};
}
