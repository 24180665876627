import angular = require('angular');
// import { ITarifaVT } from '../models/tarifavt.model';
import { MeurhVagasService } from '../vagas.service';

export class MeurhVagasModalService {
    static $inject = ['MeurhVagasService', '$uibModal'];

    constructor(
        public entityService: MeurhVagasService,
        public $uibModal: any
    ) { }

    open(entity: any, parameters: any): any {
        return this.$uibModal.open({
            template: require('html-loader!./vagas.modal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                'NewToaster',
                function MeurhVagasModal(
                    entity: any,
                    $uibModalInstance: any,
                    NewToaster: any
                ): void {
                    let selfModal = this;

                    selfModal.NewToaster = NewToaster;
                    selfModal.parameters = parameters;
                    selfModal.entity = entity;
                    selfModal.entitycopy = angular.copy(entity);

                    selfModal.submit = (): void => {

                        if (selfModal.parameters.componente === 'Benefício') {
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.beneficio) &&
                                selfModal.valorExiste(selfModal.entitycopy.tipoperiodo) &&
                                selfModal.valorExiste(selfModal.entitycopy.quantidade) &&
                                selfModal.validaTipoperiodo()
                            ) {
                                selfModal.entity = angular.copy(selfModal.entitycopy);
                                $uibModalInstance.close(selfModal.entity);
                            } else {
                                selfModal.erroForm();
                            }
                        } else if (selfModal.parameters.componente === 'Vale-transporte') {
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.concessionaria) &&
                                selfModal.valorExiste(selfModal.entitycopy.tarifaconcessionariavt) &&
                                selfModal.valorExiste(selfModal.entitycopy.quantidade)
                            ) {
                                selfModal.entity = angular.copy(selfModal.entitycopy);
                                $uibModalInstance.close(selfModal.entity);
                            } else {
                                selfModal.erroForm();
                            }
                        }
                    };

                    selfModal.validaTipoperiodo = (): boolean => {
                        if (selfModal.entitycopy.tipoperiodo === 1) { // todo mes x
                            if (selfModal.valorExiste(selfModal.entitycopy.tipoperiodomes)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else if (selfModal.entitycopy.tipoperiodo === 2) { // entre mes e ano
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.tipoperiodomesinicio) &&
                                selfModal.valorExiste(selfModal.entitycopy.tipoperiodoanoinicio) &&
                                selfModal.valorExiste(selfModal.entitycopy.tipoperiodoanofinal) &&
                                selfModal.valorExiste(selfModal.entitycopy.tipoperiodomesfinal)
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.close = (): void => {
                        $uibModalInstance.dismiss('fechar');
                    };

                    selfModal.valorExiste = (valor: any): boolean => {
                        return valor !== '' && valor !== undefined && valor !== null ? true : false;
                    };

                    selfModal.erroForm = (): void => {
                        selfModal.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário de ' + selfModal.parameters.componente + ' apresentam erros',
                            body: 'Favor verificar os campos obrigatórios, assinalados com *.',
                        });
                    };
                }
            ],
            controllerAs: '$ctrl',
            windowClass: 'nsj-modal-lg-no-height',
            resolve: {
                entity: (): any => {
                    return entity;
                }
            }
        });
    }
}
