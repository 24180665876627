import * as angular from 'angular';
import configJson from '../../config/config.json';

import { app } from '../app.module';
import { NsjCore } from '../core/nsj-core';
import { Usuarios } from '../shared/services/usuarios.service';

class BootstrapAngular {
  constructor() {
    const USA_GRUPO_EMPRESARIAL = true;
    const USA_ESTABELECIMENTO = true;
    NsjCore.bootstrap(configJson, this.bootstrap, USA_GRUPO_EMPRESARIAL, USA_ESTABELECIMENTO);
  }

  private bootstrap(uri = 'null') {
    /*
    TODO: Remover e injetar o instância de do usuário carregado pelo core .
    header Nasajon-ui necessita de um service com uma function getProfile retornando dados do profile em uma promisse
    */
    angular.module(app).service('Usuarios', Usuarios);

    angular.element(document).ready(function(){
      angular.bootstrap(document, [app]);
    });

    //Caso exista uma uri, redirecino para a página definida.
    if (uri != 'null') {
      angular.module(app).run(['$location', function ($location: angular.ILocationService){
        $location.url(uri);
      }]);
    }
  }
}

new BootstrapAngular();