import angular = require('angular');
import { IAnexo } from '../../Anexos/models/anexo.model';
import { ISolicitacaoAdmissao } from '../models/solicitacaoadmissao.model';
import { MeurhSolicitacoesadmissoesService } from '../solicitacoesadmissoes.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhSolicitacoesadmissoesShowController implements ng.IController {
    static $inject = ['$scope', '$stateParams', '$state', 'MeurhSolicitacoesadmissoes', 'entity', 'NewToaster', 'ModalConfirmService', '$sce', '$http', '$q', 'nsjRouting', '$rootScope', '$window', 'MeurhSolicitacoes', 'TiposDocumentosRequeridosService'];

    public action: string = 'show';
    public reload = this.entityService.reload;
    public busy: boolean = false;
    public busyTipoDocumentos: boolean = true;
    public edit: boolean = false;
    public editanexo: boolean = false;
    public adicionarAnexo: boolean = false;
    public actionUpdate: string = 'update';
    public form: angular.IFormController;
    public idCount: number = 0;
    public formAnexo: object = {};
    public entityAnexo: IAnexo = {};
    public actionAnexo: string = 'insert';
    public constructors: any = [];
    public entityAnexoLista: any = [];
    public mimeType: string;
    public filelist: any = [];
    public salarioValido: boolean = true;
    public tiposdocumentosrequeridos: Array<any>;
    public LABELS: any = {
        '0': 'label-warning',
        '1': 'label-success',
        '2': 'label-danger',
        '4': 'label-danger'
    };
    public SITUACOES: any = {
        '0': 'Enviada',
        '1': 'Efetuada',
        '2': 'Cancelada',
        '4': 'Recusada'
    };
    public path: number = this.$stateParams.path;

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesadmissoesService,
        public entity: ISolicitacaoAdmissao,
        public NewToaster: any,
        public ModalConfirmService: any,
        public $sce: angular.ISCEService,
        public $http: angular.IHttpService,
        public $q: angular.IQService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $window: angular.IWindowService,
        public $MeurhSolicitacoes: any,
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService
    ) { }

    $onInit(): void {

        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo['casodeuso'] = 'ADMISSOES';

        this.resetTiposDocumentosRequeridosService();
		this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				tipodocumentorequerido.file = '';
				return tipodocumentorequerido;
			});
		});
    }

    resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = {'tiposolicitacao': TipoSolicitacaoEnum.ADMISSAO_PRELIMINAR};
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    editar(value: boolean): void {
        this.$state.go('meurh_solicitacoesadmissoes_edit', {'solicitacao': this.entity.solicitacao});
    }

    setAdicionarAnexo(value: boolean): void {
        this.adicionarAnexo = value;
    }

    downloadDocumento(documento: any): angular.IHttpPromise<any> {
        this.constructors = [];
        this.constructors.id = documento.solicitacaodocumento;

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http.get(
                    this.nsjRouting.generate('meurh_solicitacoesdocumentos_download', angular.extend({ }, this.constructors, { }), true),
                    { responseType: 'arraybuffer'}
                )
                .then((response: any): void => {
                    var nomeArquivo = '';
                    var blob = new Blob([response.data]);
                    var fileURL = (this.$window.URL || this.$window.webkitURL).createObjectURL(blob);
                    var a = document.createElement('a');

                    nomeArquivo = documento.tipodocumentocolaborador.descricao + '.' + documento.extensao;

                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.href = fileURL;
                    a.download = nomeArquivo;
                    a.click();
                    this.$window.URL.revokeObjectURL(fileURL);

                    resolve(response.data);
                })
                .catch((response: any): void => {
                    reject(response);
                });
        });
    }

    cancelar(entity: ISolicitacaoAdmissao): void  {
        let confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de admissão preliminar');

        confirm.result.
            then((entity: ISolicitacaoAdmissao): void => {
                this.busy = true;

                this.$MeurhSolicitacoes.cancelar(entity)
                    .then((response: any): void => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A solicitação de admissão preliminar cancelada com sucesso!'
                        });

                        this.reload();
                        this.$state.go('meurh_admissaocompleta', angular.extend(this.entityService.constructors));
                    })
                    .catch((response: any): void => {
                        if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                            this.NewToaster.pop({
                                type: 'error',
                                title: response.data.message
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Ocorreu um erro ao cancelar a solicitação admissão preliminar.'
                            });
                        }
                    })
                    .finally((): void => {
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    getLabels(situacao: string): string {
        return this.LABELS[situacao];
    }

    getSituacao(situacao: string): string {
        return this.SITUACOES[situacao];
    }
}
