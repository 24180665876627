import angular = require('angular');

export class MeurhAdmissoesestagiarioFotoFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', 'ModalConfirmService'];

    public entity: any;
    public action: string;
	public form: angular.IFormController;
    public fotoSelecionada: boolean = false;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any, estabelecimentoSelecionado: any },
        public ModalConfirmService: any,
    ) {
    }

    $onInit() {
        var inputElement = angular.element(document.getElementById('foto'));
        inputElement[0].addEventListener('change', this.selecionaFoto.bind(this), false);

        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_get_foto_loaded', (event: angular.IAngularEvent, args: any): void => {
            if (this.valorExiste(args)) {
                this.entity.foto = args;
                this.exibeThumb(this.entity.foto);
            }
        });
    }

    exibeThumb(fotoDoBanco: any) {
        let foto = angular.element(document.querySelector('#fotoThumb'));
        foto.attr('src', fotoDoBanco);

        const blob = new Blob([fotoDoBanco]);
        this.entity.foto = blob;
    }

    selecionaFoto($event: any) {
        this.fotoSelecionada = true;
        let fileReader = new FileReader();

        fileReader.onload = (e: any) => {
            setTimeout(() => {

                let foto = angular.element(document.querySelector('#fotoThumb'));
                foto.attr('src', e.target.result);

                let height = foto.height();
                let width = foto.width();

                const blob = new Blob([e.target.result]);
                this.entity.foto = blob;

                this.$scope.$apply();
            }, 500);

        };

        let file = $event.target.files[0];

        if (file) {
            var fileArray = file.name.split('.');
            var fileType = fileArray[fileArray.length - 1].toLowerCase();
            if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
                fileReader.readAsDataURL(file);
            } else {
                this.tipoDeArquivoIncompativel();
                this.limparFoto();
            }
        }

    }

    tipoDeArquivoIncompativel(): void {
        let textos = {
            title: 'Não é possível fazer o upload',
            subtitle: 'Apenas arquivos jpg/jpeg e png podem ser enviados.',
            confirmText: '',
            confirmTextNum: '',
            closeButton: 'Fechar',
            confirmButton: 'Ok',
        };
		let confirm = this.ModalConfirmService.open(this.entity, 'Foto', false, textos);
	}

    limparFoto(): void {
        this.fotoSelecionada = false;
        this.entity.foto = null;

        let foto = angular.element(document.querySelector('#fotoThumb'));
        foto.attr('src', 'https://s3.sa-east-1.amazonaws.com/imagens.nasajon/geral/avatar-3x4.svg');

        var fotoInput = <HTMLInputElement>document.getElementById('foto');
        fotoInput.value = '';
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

}

