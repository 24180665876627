import * as angular from 'angular';

import { MeurhSolicitacoesalteracoesvtstarifasFormController } from './form/solicitacoesalteracoesvtstarifas.form';
import { MeurhSolicitacoesalteracoesvtstarifasService } from './solicitacoesalteracoesvtstarifas.service';
import { MeurhSolicitacoesalteracoesvtstarifasFormService } from './modal/solicitacoesalteracoesvtstarifas.modal.service';
import { MeurhSolicitacoesalteracoesvtstarifasFormComponent } from './form/solicitacoesalteracoesvtstarifas.form.component';


export const MeurhSolicitacoesalteracoesvtstarifasModule = angular.module('MeurhSolicitacoesalteracoesvtstarifasModule', ['ui.router.state'])
    .controller('MeurhSolicitacoesalteracoesvtstarifasFormController', MeurhSolicitacoesalteracoesvtstarifasFormController)
    .service('MeurhSolicitacoesalteracoesvtstarifasService', MeurhSolicitacoesalteracoesvtstarifasService)
    .service('MeurhSolicitacoesalteracoesvtstarifasFormService', MeurhSolicitacoesalteracoesvtstarifasFormService)
    .component('meurhSolicitacoesalteracoesvtstarifasFormComponent', MeurhSolicitacoesalteracoesvtstarifasFormComponent)
    .name;
