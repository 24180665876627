import * as angular from 'angular';
import { MeurhInclusaodependenteOutrasinformacoesFormController } from './outrasinformacoes.form.controller';

export class MeurhInclusaodependenteOutrasinformacoesFormComponent implements angular.IComponentOptions {
	static selector = 'meurhInclusaodependenteOutrasinformacoesFormComponent';
	static controller = MeurhInclusaodependenteOutrasinformacoesFormController;
	static template = require('!!html-loader!./outrasinformacoes.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
