import angular from 'angular';
import { MeurhAdmissoesDadosgeraisFormController } from './dadosgerais.form.controller';

export class MeurhAdmissoesDadosgeraisFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesDadosgeraisFormComponent';
	static controller = MeurhAdmissoesDadosgeraisFormController;
	static template = require('!!html-loader!./dadosgerais.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
		desativaportrabalhadortemporario: '=',
		warning: '=?',
		mostraobrigatoriosencaminhar: '='
	};
}
