export class ModalGestorExternoService {
    static $inject = [
        '$uibModal'
    ];

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
    ) { }

    public open(): angular.ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modalGestorExterno.html'),
            controller: 'ModalGestorExternoController',
            controllerAs: '$ctrl',
            backdrop: 'static',
            resolve: {}
        });
    }
}
