import angular from 'angular';

export const CargosSalariosListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('relatorios_cargossalarios', {
                url: '/relatorios/cargossalarios',
                template: require('html-loader!./index/cargos-salarios.index.html'),
                controller: 'CargosSalariosIndexController',
                controllerAs: '$ctrl'
            });
    }
];
