import * as angular from 'angular';
import { MeurhSolicitacoesfaltasShowFormController } from './solicitacoesfaltas.showform.controller';

export class MeurhSolicitacoesfaltasShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesfaltasShowFormComponent';
	static controller = MeurhSolicitacoesfaltasShowFormController;
	static template = require('!!html-loader!./solicitacoesfaltas.showform.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
