import angular = require('angular');

export class PersonaTiposdocumentoscolaboradoresService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];
    public entities: any = [];
    public loaded: any = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(public $http: angular.IHttpService, public nsjRouting: any, public $rootScope: angular.IScope, public $q: angular.IQService) {

    }

    reload () {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search (filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load (constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                    method: 'GET',
                    url: this.nsjRouting.generate('persona_tiposdocumentoscolaboradores_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                    timeout: this.loading_deferred.promise
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    async load () {
        if (!this.loadParams.busy && !this.loadParams.finished) {
            this.loadParams.busy = true;

            await this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    this.entities = data;
                    this.loadParams.finished = true;
                    this.loaded = true;
                    this.$rootScope.$broadcast('persona_tiposdocumentoscolaboradores_list_finished', this.entities);
                })
                .catch((error) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                });
        }

        return this.entities;
    }

    loadMore () {
        this.loadParams.to_load = 3;
        this.load();
    }

    find (identifier: any) {
        for (var i in this.entities) {
            if (this.entities[i]['tipodocumentocolaborador'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    _save (entity: any, autosave: any) {
        var method, url;
        if (entity['tipodocumentocolaborador']) {
            method = 'PUT';
            url = this.nsjRouting.generate('persona_tiposdocumentoscolaboradores_put', { 'id': entity['tipodocumentocolaborador'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('persona_tiposdocumentoscolaboradores_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        var data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
                method: method,
                url: url,
                data: data,
                timeout: this.loading_deferred.promise
            })
            .finally(() => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    save (entity: any, autosave: any) {

        this
            ._save(entity, autosave)
            .then((response: any) => {
                if (response.data.tipodocumentocolaborador) {
                    entity['tipodocumentocolaborador'] = response.data.tipodocumentocolaborador;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('persona_tiposdocumentoscolaboradores_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any) => {
                this.$rootScope.$broadcast('persona_tiposdocumentoscolaboradores_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get (identifier: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http
                .get(this.nsjRouting.generate('persona_tiposdocumentoscolaboradores_get', { 'id': identifier }, true), { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('persona_tiposdocumentoscolaboradores_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }
}
