import angular from 'angular';
import moment from 'moment';

export class MeurhApontamentosGradeIndexController {
    static $inject = ['$uibModal', '$rootScope', '$filter', 'ApontamentosRubricasponto',
        'ApontamentosMovimentosService', 'MeurhApontamentosService', 'ApontamentosTrabalhadoresService',
        'MeurhApontamentosMovimentosAuxService', 'FaltastrabalhadoresService', 'NewToaster', '$scope', '$stateParams', '$state',
        'Trabalhadoresmovimentos', '$timeout', 'nsjRouting', '$http', '$q', 'RelatoriosService', 'ClientSession', 'MeurhApontamentosGradeModalSalvarService'];

    public SITUACAO_RASCUNHO = -1;
    public SITUACAO_PENDENTE = 0;
    public SITUACAO_APROVADO = 1;

    public configRubricas: boolean = this.$rootScope.temConfiguracao('LIBERAR_FILTRO_MOVIMENTO_GRADE');
    public setFilterRubricas: boolean = false;
    public filterRubricas: any = [];
    public isBusy: boolean;
    public form: angular.IFormController;
    public formData: any = [];
    public trabalhadores: any = [];
    public rubricas: any = [];
    public apontamento: any;
    public periodoapuracao: any = {};
    public existeRubrica: boolean = true;
    public somatoriosRubricas: any = [];
    public apontamentoCarregado: boolean = false;
    public menuGroupAtivo: any = '';
    public tipo: any = this.$stateParams.tipo;
    public tipoInicial: any = this.$stateParams.tipo;
    public isBlocked: boolean = false;
    public anoInicio: any = moment().year();
    public anoFim = parseInt(this.anoInicio, 10) - 11;
    public tooltips = {
        '-1': 'Rascunho',
        '0': 'Pendente, aguardando aprovação.',
        '1': 'Aprovado!',
        '2': 'Rejeitado'
    };
    public lookupTrabalhadores: boolean = false;
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public isEmpty: boolean = true;
    public estabelecimentoSelecionado: any;
    public filterData: any = {};
    public parametrosSessao: any = null;
    public carregandoRelatorio: boolean = false;
    public fimPrazoEnvio: any;
    public mesesFiltrados: any;
    public submitting: boolean = false;
    public month: any;
    public totalItems: any;
    public currentPage: any;
    public itemsPerPage: any;
    public entities: any;
    public menuOrdenacaoAtiva: any;
    public watchFormDataCounter: any;
    public movimentosTipoSempre: any = [];
    public meses: any = [{
        value: 1,
        label: 'Janeiro'
    }, {
        value: 2,
        label: 'Fevereiro'
    }, {
        value: 3,
        label: 'Março'
    }, {
        value: 4,
        label: 'Abril'
    }, {
        value: 5,
        label: 'Maio'
    }, {
        value: 6,
        label: 'Junho'
    }, {
        value: 7,
        label: 'Julho'
    }, {
        value: 8,
        label: 'Agosto'
    }, {
        value: 9,
        label: 'Setembro'
    }, {
        value: 10,
        label: 'Outubro'
    }, {
        value: 11,
        label: 'Novembro'
    }, {
        value: 12,
        label: 'Dezembro'
    }];

    constructor(
        public $uibModal: any,
        public $rootScope: any,
        public $filter: any,
        public ApontamentosRubricasponto: any,
        public ApontamentosMovimentos: any,
        public MeurhApontamentosService: any,
        public ApontamentosTrabalhadoresService: any,
        public MeurhApontamentosMovimentosAuxService: any,
        public FaltastrabalhadoresService: any,
        public NewToaster: any,
        public $scope: any,
        public $stateParams: any,
        public $state: any,
        public Trabalhadoresmovimentos: any,
        public $timeout: any,
        public nsjRouting: any,
        public $http: any,
        public $q: any,
        public RelatoriosService: any,
        public ClientSession: any,
        public MeurhApontamentosGradeModalSalvarService: any
    ) {
        if (!$rootScope.temPermissao('registrar_apontamento_grade')) {
            $state.go('dp', $stateParams);
        }

        ClientSession.load();

        MeurhApontamentosService.constructors = [];

        this.estabelecimentoSelecionado = $rootScope.nsjGlobals.a.estabelecimentosInfos.filter((estabelecimento: any) => {
            return estabelecimento.id === $rootScope.nsjGlobals.a.estabelecimento;
        })[0];

        $rootScope.$on('Trabalhadores_loading', (event: any, args: any) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });
        $rootScope.$on('Trabalhadores_loaded', (event: any, args: any) => {
            this.lookupTrabalhadores = false;
        });
        $rootScope.$on('estabelecimento_refreshed', (event: any, args: any) => {
            this.filterData.estabelecimento = null;
            if (args.length === 1) {
                this.filterData.estabelecimento = args[0];
            }
            this.loadApontamento();
        });
        $rootScope.$on('trabalhador_refreshed', (event: any, args: any) => {
            if (args.length === 1) {
                this.filterData.trabalhador = args[0];
            }
        });

        $scope.$watchGroup([() => this.filterData.mesreferencia, () => this.filterData.anoreferencia], (newValues: any, oldValues: any) => {
            if (newValues !== oldValues) {
                this.setFimPrazoEnvio();
            }
        });
        $scope.$watchGroup([() => Trabalhadoresmovimentos.loadParams.busy, () => this.MeurhApontamentosService.busy, () => ApontamentosRubricasponto.loadParams.busy], (newValues: any, oldValues: any) => {
            if (newValues !== oldValues && (newValues.every((busy: any) => busy === false))) {
                this.isBusy = false;
            } else {
                this.isBusy = true;
            }
        });

        $scope.$watch(() => this.formData, (newValue: any, oldValue: any) => {
            this.watchFormDataCounter++;
            $timeout(() => {
                if (newValue !== oldValue) {
                    this.filterTotaisTrabalhador();
                    this.rubricas.forEach((rubrica: any) => {
                        this.formData.forEach((linha: any) => {
                            if (this.valorExiste(oldValue)) {
                                this.isEmpty = linha.movimentos[rubrica.evento] === undefined ? this.isEmpty : false;
                            }

                            let erro = false;
                            if (this.valorExiste(rubrica.evento.evento_limiteerro) && rubrica.evento.evento_limiteerro !== 0 && rubrica.evento.evento_limiteerro !== '0') {
                                if (linha.movimentos[rubrica.evento] > rubrica.evento.evento_limiteerro) {
                                    erro = true;
                                }
                            }
                            let alerta = false;
                            if (this.valorExiste(rubrica.evento.evento_limitealerta) && rubrica.evento.evento_limitealerta !== 0 && rubrica.evento.evento_limitealerta !== '0') {
                                if (linha.movimentos[rubrica.evento] > rubrica.evento.evento_limitealerta) {
                                    alerta = true;
                                }
                            }
                            if (erro === true) {
                                linha.movimentos[rubrica.evento] = undefined;
                                NewToaster.pop({
                                    type: 'error',
                                    title: 'O valor digitado foi superior ao limite de alerta da ' + rubrica.nome + '.'
                                });
                            } else if (alerta === true) {
                                NewToaster.pop({
                                    type: 'warning',
                                    title: 'Atenção: O valor digitado é superior ao limite de alerta da ' + rubrica.nome + '.'
                                });
                            }
                        });
                    });
                }
            }, 1500);
        }, true);
    }

    $onInit() {
        this.carregaClientSession();
        this.onSubmitError();
        this.onSubmitSuccess();
    }

    handleFilterRubricas() {
        this.setRubricasVisibility(this.rubricas);
        this.setRubricasVisibility(this.somatoriosRubricas);
        this.setFilterRubricas = true;
    }

    setRubricasVisibility(listaDeRubricas: any) {
        listaDeRubricas.forEach((rubrica: any) => rubrica.show = !this.configRubricas);
        if (this.configRubricas) {
            listaDeRubricas.forEach((rubrica: any) => {
                if (this.filterRubricas.length) {
                    this.filterRubricas.forEach((filteredRubrica: any) => {
                        if (!rubrica.show && (filteredRubrica.evento === rubrica.evento)) {
                            rubrica.show = true;
                        }
                    });
                } else if (!rubrica.show) {
                    rubrica.show = true;
                }
            });
        }
    }

    filterTotaisTrabalhador() {
        let tempSomatoriosRubricas: any = [];
        this.rubricas.forEach((rubrica: any) => {
            let somatorioHoras: any = 0;
            let somatorioMinutos: any = 0;
            let somatorioTotal: any = 0;
            this.trabalhadores.forEach((trabalhador: any) => {
                if (trabalhador.show === true) {
                    this.formData.forEach((linha: any) => {
                        if (trabalhador.trabalhador === linha.trabalhador) {
                            if (rubrica.dynamictype === 'number-only-short' ||
                            rubrica.dynamictype === 'number' ||
                            rubrica.dynamictype === 'currency') {
                                if (linha.movimentos[rubrica.evento] !== undefined) {
                                    somatorioTotal = Number(somatorioTotal) + Number(linha.movimentos[rubrica.evento]);
                                    // limita em duas casas decimais caso não seja inteiro
                                    somatorioTotal = Number.isInteger(somatorioTotal) && rubrica.dynamictype === 'number' ? somatorioTotal : somatorioTotal.toFixed(2);
                                }
                            } else if (rubrica.dynamictype === 'number-text-time') {
                                if (linha.movimentos[rubrica.evento] !== undefined && linha.movimentos[rubrica.evento].toString().length === 4) {
                                    let horas: any = linha.movimentos[rubrica.evento].toString().substring(0, 2);
                                    let minutos: any = linha.movimentos[rubrica.evento].toString().substring(2);
                                    somatorioHoras = Number(somatorioHoras) + Number(horas);
                                    somatorioMinutos = Number(somatorioMinutos) + Number(minutos);
                                }
                            } else if (rubrica.dynamictype === 'interval') {
                                if (linha.movimentos[rubrica.evento] !== undefined) {
                                    let arrayTempo: any = linha.movimentos[rubrica.evento].toString().split('.');
                                    let horas: any = arrayTempo[0];
                                    let minutosDecimal: any = linha.movimentos[rubrica.evento] - horas;
                                    let minutosTempo: any = minutosDecimal * 60;
                                    let arrayMinutos: any = minutosTempo.toString().split('.');
                                    let minutos: any = 0;
                                    if (minutosTempo - arrayMinutos[0] > 0.5) {
                                        minutos = Number(arrayMinutos[0]) + 1;
                                    } else {
                                        minutos = arrayMinutos[0];
                                    }
                                    somatorioHoras = Number(somatorioHoras) + Number(horas);
                                    somatorioMinutos = Number(somatorioMinutos) + Number(minutos);
                                }
                            }
                        }
                    });
                }
            });
            if (rubrica.dynamictype === 'number-text-time' || rubrica.dynamictype === 'interval') {
                if (somatorioMinutos !== 0 || somatorioHoras !== 0) {
                    if (somatorioMinutos >= 60) {
                        let minutosHoras = somatorioMinutos / 60;
                        let minutosResto = somatorioMinutos % 60;
                        somatorioHoras = somatorioHoras + minutosHoras;
                        somatorioMinutos = minutosResto;
                    }
                }
                somatorioTotal = '';
                somatorioTotal = (Number(somatorioHoras) === 0 ? '00' : (Number(somatorioHoras) > 0 && Number(somatorioHoras) < 10) ? '0' + somatorioHoras : parseInt(somatorioHoras, 10)) + ':' + (Number(somatorioMinutos) === 0 ? '00' : (Number(somatorioMinutos) > 0 && Number(somatorioMinutos) < 10) ? '0' + somatorioMinutos : somatorioMinutos);
            }
            tempSomatoriosRubricas.push({ 'evento': rubrica.evento, 'somatorio': somatorioTotal });
        });
        if (tempSomatoriosRubricas.length !== 0) {
            this.somatoriosRubricas = [];
            this.somatoriosRubricas = angular.copy(tempSomatoriosRubricas);
            this.setRubricasVisibility(this.somatoriosRubricas);
            this.$scope.$applyAsync();
        }
    }

    carregaClientSession() {
        this.$rootScope.$on('meurh_clientsession_session_loaded_service', (event: any, args: any) => {
            if (!angular.isUndefined(args.empresa)) {
                this.parametrosSessao = args;
            }
            if (this.apontamento === undefined) {
                this.loadApontamento();
            }
        });
    }

    ultimoDiaMes(mes: any, ano: any) {
        let result = 0;
        switch (mes) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                result = 31;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                result = 30;
                break;
            case 2:
                if ((ano % 4) === 0) {
                    result = 29;
                } else {
                    result = 28;
                }
                break;
        }

        return result;
    }

    setHourLength() {
        let nsjIntervals: any = document.getElementsByTagName('nsj-interval');
        nsjIntervals.forEach(element => element.setAttribute('hour-length', '3'));
    }

    filterYear() {
        if (this.filterData.anoreferencia === null) {
            this.filterData.anoreferencia = this.anoInicio;
        }
    }

    filterMonth() {
        let anoAtual = (new Date()).getFullYear();
        let ultimoMesDisponivel = (new Date()).getMonth() + 1;
        this.filterData.anoreferencia = this.filterData.anoreferencia ? this.filterData.anoreferencia : moment().year();
        if ((this.filterData.anoreferencia === anoAtual && anoAtual === this.anoInicio) || (!(this.filterData.anoreferencia === anoAtual && anoAtual === this.anoInicio) && (this.filterData.anoreferencia === this.anoInicio))) {
            this.mesesFiltrados = [];
            let i = 0;
            if (this.showNextMonth()) {
                ultimoMesDisponivel++;
            }
            if (ultimoMesDisponivel > 12 && (this.filterData.anoreferencia === anoAtual && anoAtual === this.anoInicio)) {
                this.anoInicio = moment().add(1, 'years').year();
                ultimoMesDisponivel = 1;
                this.filterData.anoreferencia = parseInt(this.filterData.anoreferencia, 10) + 1;
            } else {
                if (ultimoMesDisponivel > 12) {
                    ultimoMesDisponivel = 12;
                }
            }
            for (i; i < ultimoMesDisponivel; i++) {
                this.mesesFiltrados.push(this.meses[i]);
            }
            // mudando o mês selecionado caso a seleção atual não esteja presente na nova lista gerada
            if (this.filterData.mesreferencia > ultimoMesDisponivel) {
                this.filterData.mesreferencia = ultimoMesDisponivel;
            }
        } else {
            this.mesesFiltrados = this.meses;
        }
        if (!this.filterData.mesreferencia) {
            this.filterData.mesreferencia = ultimoMesDisponivel;
        }
    }

    verificacaoConstructor() {
        let constructor = {};
        constructor['status'] = '00';
        if (this.$rootScope.session.entities.estabelecimento) {
            if (this.$rootScope.session.entities.estabelecimento.estabelecimento) {
                constructor['estabelecimento'] = this.$rootScope.session.entities.estabelecimento.estabelecimento;
            }
        }
        if (this.tipo) {
            constructor['tipo'] = this.tipo;
        }

        constructor['forcagestor'] = this.$rootScope.modoGestorPermissao('registrar_apontamento_grade');
        return constructor;
    }

    getDataFechamentoFolha(mes: any, ano: any) {
        // se fechamento é para o próximo mês, incremento o mês e ano se for o caso
        if (this.$rootScope.configuracoes['FOLHA_FECHA_PROXIMO_MES'] === '1') {
            mes = mes + 1;
            if (mes === 13) {
                mes = 1;
                ano = ano + 1;
            }
        }
        let diaFechamento = this.$rootScope.configuracoes['FECHAMENTO_FOLHA'];
        let ultimoDiaMes: any = this.ultimoDiaMes(mes, ano);
        if (parseInt(diaFechamento, 10) > parseInt(ultimoDiaMes, 10)) {
            diaFechamento = ultimoDiaMes.toString();
        }
        let data = diaFechamento + '-' + mes + '-' + ano;
        let datafinal = moment(data, 'DD-MM-YYYY').startOf('day');

        return datafinal;
    }

    showNextMonth() {
        let datafinal = this.getDataFechamentoFolha(moment().month() + 1, moment().year());
        return moment().startOf('day').isAfter(datafinal);
    }

    setFimPrazoEnvio() {
        let datafinal = this.getDataFechamentoFolha(this.filterData.mesreferencia, this.filterData.anoreferencia);
        this.fimPrazoEnvio = moment().startOf('day').isAfter(datafinal);
    }

    apotamentosMovimentosBusy() {
        return this.ApontamentosMovimentos.busy;
    }

    redirectRelatorio(apontamento: any) {
        this.carregandoRelatorio = true;
        let constructor = { 'id': apontamento };
        let callback = () => {
            this.carregandoRelatorio = false;
        };
        this.RelatoriosService.abrirRelatorio('apontamentos_apontamentos_relatorio', constructor, callback);
    }

    selectDtype(rubrica: any) {
        let tipo = rubrica.evento_unidade;
        switch (tipo) {
            case 0:
            case 8:
            case 10:
                return 'interval';
            // return 'number-text-time';
            case 1:
                return 'number';
            case 2:
                return 'currency';
            default:
                return 'number-only-short';
        }
    }

    ordenar(param: any) {
        this.menuOrdenacaoAtiva = param;
        this.ApontamentosMovimentos.filter.filterfield = param;
        this.ApontamentosMovimentos.constructors.order = 'ASC';
        this.entities = this.ApontamentosMovimentos.reload();
    }

    refresh() {
        if (this.filterData.estabelecimento) {
            this.$rootScope.$broadcast('trabalhador_refresh', { estabelecimento: this.filterData.estabelecimento.estabelecimento });
            this.filterData.trabalhador = null;
        }
    }

    filterStatus(entity: any) {
        if ((this.menuGroupAtivo === 'n_preenchidos' && angular.equals(entity.movimentos, [])) || this.menuGroupAtivo === '') {
            return true;
        } else if ((this.menuGroupAtivo === 'preenchidos' && !angular.equals(entity.movimentos, [])) || this.menuGroupAtivo === '') {
            return true;
        }

    }

    loading(mostrar_toaster: any) {
        if (mostrar_toaster) {
            this.NewToaster.pop({
                type: 'info',
                title: 'Aguarde. Armazenando informações'
            });
        }
        this.apontamentoCarregado = false;
    }

    loadDone() {
        this.apontamentoCarregado = true;
        return new Promise((resolve, reject) => {
            resolve('OK');
        });
    }

    forcaGestor() {
        return this.$rootScope.modoGestorPermissao('registrar_apontamento_grade');
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    // inicio - paginacao
    setPaginationVariables(totalItems: number, currentPage: number, itemsPerPage: number) {
        this.totalItems = totalItems;
        this.currentPage = currentPage;
        this.itemsPerPage = itemsPerPage;
    }

    paginate() {
        return (value: any) => {
            let begin: number, end: number, index: number;
            begin = (this.currentPage - 1) * this.itemsPerPage;
            end = begin + this.itemsPerPage;
            index = this.trabalhadores.filter((colab: any, index: number) =>  {
                colab.originalIndex = index;
                return colab.show;
            }).indexOf(value);
            return (begin <= index && index < end);
        };
    }
    // fim - paginacao

    loadApontamento() {
        if (this.parametrosSessao !== null) {
            this.loading(false);
            this.formData = [];
            this.trabalhadores = [];
            this.rubricas = [];
            this.apontamento = [];
            this.periodoapuracao = {};
            this.ApontamentosRubricasponto.constructors['estabelecimento'] = this.parametrosSessao.estabelecimento.estabelecimento;
            this.MeurhApontamentosService.constructors['estabelecimento'] = this.parametrosSessao.estabelecimento.estabelecimento;
            this.MeurhApontamentosService.constructors['apontamento'] = this.MeurhApontamentosService.constructors['apontamento'] ? this.MeurhApontamentosService.constructors['apontamento'] : null;
            this.filterData.mesreferencia = this.filterData.mesreferencia ? this.filterData.mesreferencia : moment().month() + 1;
            this.filterData.anoreferencia = this.filterData.anoreferencia ? this.filterData.anoreferencia : moment().year();
            this.MeurhApontamentosService.constructors['mesreferencia'] = this.ApontamentosMovimentos.constructors['mesreferencia'] = parseInt(this.filterData.mesreferencia, 10);
            this.MeurhApontamentosService.constructors['anoreferencia'] = this.ApontamentosMovimentos.constructors['anoreferencia'] = parseInt(this.filterData.anoreferencia, 10);
            this.MeurhApontamentosService.busy = true;
            this.MeurhApontamentosService._load(this.MeurhApontamentosService.constructors, null, null).then((response) => {
                this.MeurhApontamentosService.busy = false;
                this.apontamento = response.pop();
                if (this.apontamento === undefined) {
                    this.apontamento = {};
                }
                this.MeurhApontamentosService.constructors.apontamento = this.apontamento.apontamento ? this.apontamento.apontamento : null;
                this.MeurhApontamentosService.situacao = this.apontamento.situacao;
                this.loadTrabalhadores();
            });
            this.ApontamentosRubricasponto.loadParams.busy = true;
            this.ApontamentosRubricasponto._load(this.ApontamentosRubricasponto.constructors, {}, {}).then((response: any) => {
                this.ApontamentosRubricasponto.loadParams.busy = false;
                this.rubricas = response;
                if (this.rubricas.length === 0) {
                    this.existeRubrica = false;
                }
                for (let i = 0; i < this.rubricas.length; i++) {
                    this.rubricas[i].dynamictype = this.selectDtype(this.rubricas[i]);
                }
                this.setRubricasVisibility(this.rubricas);
            });
        }
    }

    loadTrabalhadores() {
        this.Trabalhadoresmovimentos.constructors['estabelecimento'] = this.parametrosSessao.estabelecimento.estabelecimento;
        this.Trabalhadoresmovimentos.constructors['mesreferencia'] = this.filterData.mesreferencia;
        this.Trabalhadoresmovimentos.constructors['anoreferencia'] = this.filterData.anoreferencia;
        this.Trabalhadoresmovimentos.constructors['apontamento'] = this.apontamento.apontamento ? this.apontamento.apontamento : null;
        this.Trabalhadoresmovimentos.loadParams.busy = true;
        this.Trabalhadoresmovimentos._load(this.Trabalhadoresmovimentos.constructors, {}, {
            filters: {
                datarescisao: null
            }
        }).then((response: any) => {
            this.Trabalhadoresmovimentos.loadParams.busy = false;
            this.trabalhadores = response;
            this.setPaginationVariables(this.trabalhadores.length, 1, 100);
            angular.forEach(this.trabalhadores, (value, key) => {
                this.trabalhadores[key].numerohorasmensais = Math.trunc(this.trabalhadores[key].numerohorasmensais);
                angular.forEach(this.trabalhadores[key].movimentos, (movValue, movKey) => {
                    this.trabalhadores[key].movimentos[movKey]['tooltip'] = this.tooltips[movValue.situacao];
                });
                this.trabalhadores[key].show = true;

                // inicia o formData com as rubricas e valores existentes
                this.formData.push({ 'movimentos': {}, 'trabalhador': {} });
                this.rubricas.forEach((rubrica: any) => {
                    this.formData[key]['movimentos'][rubrica.evento] = undefined;
                    this.formData[key]['trabalhador'] = this.trabalhadores[key].trabalhador;
                    if (this.trabalhadores[key].movimentos[rubrica.evento]) {
                        this.formData[key]['movimentos'][rubrica.evento] = this.trabalhadores[key].movimentos[rubrica.evento].conteudo;
                    }
                });
            });
            this.filterGrade();
            this.apontamentoCarregado = true;
        }).catch((response: any) => {
            this.loadDone().then((resolve) => {
                this.apontamentoCarregado = false;
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Houve um erro ao recuperar os dados do movimento.'
                });
            });
        });
    }

    salvaApontamento() {
        let modal = this.MeurhApontamentosGradeModalSalvarService.open(this.formData, this.getRubrica, this.trabalhadores, this.movimentosTipoSempre);
        modal.result.then(() => {
            this.salvarApontamento(this.SITUACAO_PENDENTE);
        }).catch(() => {/* */ });
    }

    formatFaltas(falta: any) {
        let aux: any = [];
        angular.forEach(falta, (value, key) => {
            aux.push({
                data: value
            });
        });
        return aux;
    }

    salvarApontamento(situacao: any) {
        if (this.form && this.form.$invalid) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Existem rubricas com valores inválidos.'
            });
            return;
        }
        this.loading(true);
        /*
        * Valores possíveis para situação
        * 0 - Rascunho
        * 1 - Enviado
        */
        let entity: any = {};
        entity.mesreferencia = this.filterData.mesreferencia;
        entity.anoreferencia = this.filterData.anoreferencia;
        entity.observacoes = this.apontamento.observacoes;
        entity.situacao = situacao;
        entity.apontamentostrabalhadores = [];
        entity.estabelecimento = this.parametrosSessao.estabelecimento.estabelecimento;
        angular.forEach(this.trabalhadores, (value, key) => {
            let movimentosTrabalhadores: any;
            movimentosTrabalhadores = this.formatMovimentos(key, situacao);
            if (movimentosTrabalhadores.length > 0) {
                entity.apontamentostrabalhadores.push({
                    apontamentotrabalhador: value.apontamentotrabalhador,
                    trabalhador: {
                        trabalhador: value.trabalhador
                    },
                    movimentos: movimentosTrabalhadores,
                });
            }
        });
        entity.apontamento = this.MeurhApontamentosService.constructors.apontamento;
        this.submitting = true;
        this.MeurhApontamentosService.save(entity, false);
    }

    onSubmitSuccess() {
        this.$rootScope.$on('apontamentos_apontamentos_submitted', (event: any, args: any) => {
            this.submitting = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Sucesso ao inserir um novo movimento!'
            });
            this.MeurhApontamentosService.constructors.apontamento = args.response.data.apontamento;
            this.loadApontamento();
        });
    }

    onSubmitError() {
        this.$rootScope.$on('apontamentos_apontamentos_submit_error', (event: any, args: any) => {
            this.submitting = false;
            this.apontamentoCarregado = true;
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    }
                );
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao inserir os movimentos!'
                    }
                );
            }
        });
    }

    getRubrica(guid: any) {
        for (let i = 0; i < this.rubricas.length; i++) {
            if (this.rubricas[i].evento === guid) {
                return this.rubricas[i];
            }
        }
        return null;
    }

    formatMovimentos(index: any, situacao: any) {
        let ret: any = [];
        angular.forEach(this.formData[index].movimentos, (value, key) => {
            if (typeof value !== undefined && value > 0) {
                let movimento: any = this.trabalhadores[index]['movimentos'][key] || {};
                if (!movimento.movimento) {
                    movimento.evento = this.getRubrica(key);
                    movimento.situacao = this.SITUACAO_RASCUNHO;
                }
                if (movimento.evento.unidade === 0 || movimento.evento.unidade === 8 || movimento.evento.unidade === 10 ||
                    movimento.evento.evento_unidade === 0 || movimento.evento.evento_unidade === 8 || movimento.evento.evento_unidade === 10) {
                    movimento.conteudo = Number(value).toFixed(2);
                } else {
                    movimento.conteudo = value;
                }

                ret.push(movimento);
            }
        });
        return ret;
    }

    finishedLoading() {
        if (this.apontamento !== null && this.apontamento !== undefined && this.apontamento.situacao === 1) {
            this.NewToaster.pop({
                timeout: 5000,
                type: 'warning',
                title: 'Este movimento já foi enviado e não pode mais sofrer alterações por esta plataforma.'
            });
            this.isBlocked = true;
        } else if (this.fimPrazoEnvio) {
            this.NewToaster.pop({
                timeout: 5000,
                type: 'info',
                title: 'O prazo para envio deste movimento se esgotou e por isso ele não pode mais sofrer alterações.'
            });
            this.isBlocked = false;
        } else {
            this.isBlocked = false;
        }
    }

    filterGrade(): void {
        let filterTipo: string | null = this.tipo ? this.tipo : null;
        let filterLotacao: string | null = this.filterData.lotacao ? this.filterData.lotacao.lotacao : null;
        let filterTrabalhador: string | null = this.filterData.trabalhador ? this.filterData.trabalhador.trabalhador : null;
        let showTotal: number = 0;

        for (let i = 0; i < this.trabalhadores.length; i++) {
            if (
                (!filterLotacao || filterLotacao === this.trabalhadores[i].lotacao.lotacao) &&
                (!filterTrabalhador || filterTrabalhador === this.trabalhadores[i].trabalhador) &&
                (!filterTipo || (filterTipo === this.trabalhadores[i].tipo.toString() || filterTipo === '-1'))
            ) {
                this.trabalhadores[i].show = true;
                showTotal += 1;
            } else {
                this.trabalhadores[i].show = false;
            }
        }
        this.setPaginationVariables(showTotal, 1, 100);
        this.paginate();
        this.filterTotaisTrabalhador();
    }
}
