import ng from 'angular';

import { TipoColaboradorEnum } from '../../../../../shared/enums/TipoColaboradorEnum';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IGestoresTrabalhador } from '../../../../Meurh/Transferenciacolaboradores/models/transferenciacolaboradores.model';
import { ITrabalhador } from '../../../models/trabalhador.model';
import { TrabalhadoresService } from '../../../trabalhadores.service';

export class ModalGestorInternoController implements ng.IController {

    static $inject = [
        'entities',
        'permissoes',
        'idTrabalhador',
        'NewToaster',
        '$scope',
        '$rootScope',
        '$uibModalInstance',
        'TrabalhadoresService',
        'ListagemService'
    ];

    public busy: boolean = true;

    public trabalhadores: ITrabalhador[];
    public gestoresSelecionados: ITrabalhador[] = [];
    public empresas: Empresa[];
    public estabelecimentos: Estabelecimento[];
    public listagemPermissao: string[] | null;

    public trabalhadoresFieldsConfig = [
        {
            value: 'nome',
            label: 'Nome'
        },
        {
            value: 'tipoNome',
            label: 'Tipo'
        },
        {
            value: 'empresaobj.razaosocial',
            label: 'Empresa'
        },
        {
            value: 'estabelecimentoobj.nomefantasia',
            label: 'Estabelecimento'
        }
    ];

    public nomeParaBuscar: string = '';
    public tipoTrabalhador: string = '-1';
    public empresaSelecionada: string;
    public estabelecimentoSelecionado: string;

    private tipoColaboradorEnum = TipoColaboradorEnum;

    constructor(
        public entities: IGestoresTrabalhador[],
        public permissoes: string[] | null,
        public idTrabalhador: string,
        public NewToaster: any,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService & { session: any, nsjGlobals: any },
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        private TrabalhadoresService: TrabalhadoresService,
        private ListagemService: ListagemService,
    ) {

        this.montaListagemVisibilidade();

        this.getGestoresElegiveis({
            tipo: this.tipoTrabalhador,
            nomeDigitado: this.nomeParaBuscar,
            estabelecimento: this.estabelecimentoSelecionado,
            empresa: this.empresaSelecionada
        });
    }

    // início - ações modal
    public confirmar(): void {
        this.$uibModalInstance.close(this.gestoresSelecionados);
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }
    // fim - ações modal


    // início - controle do componente
    public controleSelecaoGestores(): void {
        if (this.gestoresSelecionados.length > 0) {
            this.gestoresSelecionados = [];
            return;
        }

        this.gestoresSelecionados = ng.copy(this.trabalhadores);
    }

    public bloqueiaEstabelecimentos(): void {

        if (!this.empresaSelecionada) {
            this.estabelecimentoSelecionado = '';
        }
        this.estabelecimentoSelecionado = '';
        this.estabelecimentos = this.empresaSelecionada ? this.ListagemService.montaEstabelecimentos(this.empresaSelecionada, this.empresas, this.listagemPermissao) : [];
    }

    // início - filtragem
    public filtrar(): void {

        this.getGestoresElegiveis({
            tipo: this.tipoTrabalhador,
            nomeDigitado: this.nomeParaBuscar,
            estabelecimento: this.estabelecimentoSelecionado,
            empresa: this.empresaSelecionada
        });

        this.gestoresSelecionados = [];
    }
    // fim - filtragem

    // início - tratamentos
    private tratamentoTrabalhadores(): ITrabalhador[] {
        const listaSemTrabalhadoresIguais = this.trabalhadores.filter(trabalhador => {
            return !this.entities.some(
                entity => entity.gestor === trabalhador.trabalhador
            );
        });

        const tratamento = listaSemTrabalhadoresIguais.map(trabalhador => {
            trabalhador.tipoNome = this.nomearTipoTrabalhador(trabalhador.tipo);
            return trabalhador;
        });

        return tratamento;
    }

    private nomearTipoTrabalhador(tipoTrabalhador: number): string {
        if (tipoTrabalhador === this.tipoColaboradorEnum.FUNCIONARIO) {
            return 'Funcionário';
        }

        if (tipoTrabalhador === this.tipoColaboradorEnum.CONTRIBUINTE) {
            return 'Contribuinte';
        }

        return '-';
    }
    // fim - tratamentos

    private montaListagemVisibilidade(): void {
        this.listagemPermissao = this.permissoes;
        this.empresaSelecionada = this.$rootScope.session.entities.empresa.empresa;
        this.estabelecimentoSelecionado = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.empresaSelecionada, this.empresas, this.listagemPermissao);
    }

    private getGestoresElegiveis(filtrosModal: {tipo: string, estabelecimento?: string, empresa?: string, nomeDigitado?: string}) {
        this.busy = true;
        let parametrosService: any = {};

        if (filtrosModal.estabelecimento) {
            parametrosService.estabelecimento = filtrosModal.estabelecimento;
        } else {
            parametrosService.estabelecimento = '';
        }

        if (filtrosModal.empresa) {
            parametrosService.empresa = filtrosModal.empresa;
        } else {
            parametrosService.empresa = '';
        }

        if (this.idTrabalhador) {
            parametrosService.trabalhador = this.idTrabalhador;
        }

        parametrosService.tipo = filtrosModal.tipo;
        parametrosService.filter = filtrosModal.nomeDigitado ? filtrosModal.nomeDigitado : '';

        this.TrabalhadoresService.getGestoresElegiveis(parametrosService).then((listaGestores: any) => {
            this.trabalhadores = listaGestores;
            this.trabalhadores = this.tratamentoTrabalhadores();
        }).catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível buscar os gestores'
            });
        }).finally(() => {
            this.busy = false;
        });
    }
}
