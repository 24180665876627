import * as angular from 'angular';
import { MeurhApontamentosService } from './apontamentos.service';

export const MeurhApontamentosListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        var resolve = {
            'entity': ['MeurhApontamentosService', '$stateParams', '$state', '$q', '$rootScope', function (entityService: MeurhApontamentosService, $stateParams: any, $state: any, $q: any, $rootScope: any) {
                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['apontamento']) {
                        return $q(function (resolve: any, reject: any) {
                            entityService.get($rootScope.nsjGlobals.a.estabelecimento, $stateParams['apontamento'])
                                .then(function (data: any) {
                                    resolve(data);
                                })
                                .catch(function (error: any) {
                                    if (error.status === 404) {
                                        if ($state.href('apontamentos_apontamentos_not_found', $stateParams)) {
                                            $state.go('apontamentos_apontamentos_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }]
        };

        $stateProvider
            .state('apontamentos_apontamentos', {
                url: '/movimentos/registrargrupo?q?mesreferencia?anoreferencia?situacao?tipo',
                template: require('html-loader!./index/apontamentos.index.html'),
                controller: 'MeurhApontamentosIndexController',
                controllerAs: 'pntmnts_pntmnts_lst_cntrllr'
            })
            .state('apontamentos_apontamentos_new', {
                parent: 'apontamentos_apontamentos',
                url: '/new',
                resolve: resolve,
                template: require('html-loader!./new/apontamentos.new.html'),
                controller: 'MeurhApontamentosNewController',
                controllerAs: 'pntmnts_pntmnts_frm_nw_cntrllr'
            })
            .state('apontamentos_apontamentos_edit', {
                parent: 'apontamentos_apontamentos',
                url: '/:apontamento/edit',
                resolve: resolve,
                template: require('html-loader!./edit/apontamentos.edit.html'),
                controller: 'MeurhApontamentosEditController',
                controllerAs: 'pntmnts_pntmnts_frm_cntrllr',
                reloadOnSearch: false
            });
    }
];
