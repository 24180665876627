import ng from 'angular';
import angular from 'angular';
import { IViaCepResponse } from '../../models/viaCepResponse.model';
import { ITipoLogradouro } from '../../../../Ns/Tiposlogradouros/models/tiposlogradouros.model';
import { IMunicipio } from '../../../../Municipios/models/municipio.model';
import { MeurhAdmissaoestagiarioService } from '../../admissaoestagiario.service';
import { NsTiposlogradouros } from '../../../../Ns/Tiposlogradouros/tiposlogradouros.service';
import { Municipios } from '../../../../Municipios/municipios.service';

export class MeurhAdmissoesestagiarioEnderecoFormController implements ng.IController {

    static $inject = [
        '$scope',
        '$rootScope',
        'NewToaster',
        'MeurhAdmissaoestagiarioService',
        'NsTiposlogradouros',
        'Municipios'
    ];

    public entity: any;
    public form: angular.IFormController;

    public busy: boolean;

    private toastTimeout: number = 8000;

    constructor(
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & { session: any },
        private NewToaster: any,
        private MeurhAdmissaoestagiarioService: MeurhAdmissaoestagiarioService,
        private NsTiposlogradouros: NsTiposlogradouros,
        private Municipios: Municipios,
    ) { }

    // início - autopreenchimento
    public getEndereco(): void {
        if (this.entity.cep) {
            this.busy = true;
            this.MeurhAdmissaoestagiarioService.getEndereco(this.entity.cep)
                .then(async (endereco: IViaCepResponse) => {
                    if (!endereco.erro) {
                        const logradouro = endereco.logradouro.split(' ');
                        const tiposlogradourosFilter = {
                            filterfield: 'all',
                            key: logradouro[0]
                        };

                        const municipiosFilter = {
                            filterfield: 'all',
                            key: endereco.localidade
                        };

                        this.entity.logradouro = logradouro.slice(1).join(' ');
                        this.entity.bairro = endereco.bairro;
                        this.entity.cidade = endereco.localidade;

                        if (/^\d+$/.test(endereco.complemento)) {
                            this.entity.numero = endereco.complemento;
                            this.entity.complemento = null;
                        } else {
                            this.entity.complemento = endereco.complemento;
                            this.entity.numero = null;
                        }

                        await this.NsTiposlogradouros._load({}, null, tiposlogradourosFilter)
                            .then((tiposlogradouros: ITipoLogradouro[]) => {
                                this.entity.tipologradouro = tiposlogradouros[0];
                            });

                        await this.Municipios._load({}, null, municipiosFilter)
                            .then((municipios: IMunicipio[]) => {
                                this.entity.municipio = municipios[0];
                            });
                    } else {
                        this.entity.tipologradouro = null;
                        this.entity.logradouro = null;
                        this.entity.complemento = null;
                        this.entity.bairro = null;
                        this.entity.municipio = null;
                        this.entity.cidade = null;
                        this.entity.cep = null;

                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Desculpe, não foi possível realizar o autopreenchimento de endereço',
                            body: 'O CEP informado não existe',
                            timeout: this.toastTimeout
                        });
                    }
                })
                .catch((error) => {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Desculpe, não foi possível consultar o CEP',
                        timeout: this.toastTimeout
                    });
                })
                .finally(() => {
                    this.busy = false;
                    this.$scope.$applyAsync();
                });
        }
    }

    public preencheCidade(): void {
        this.entity.cidade = !!this.entity.municipio
            ? this.entity.municipio.nome
            : null;
    }
    // fim - autopreenchimento


    // início - verificações
    public verificaEstabelecimento(): { estabelecimento: string } {
        return this.entity.estabelecimento
            ? { 'estabelecimento': this.entity.estabelecimento }
            : { 'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento };
    }
    // fim - verificações

    alteraPais() {
        if (!this.entity.pais || this.entity.pais.pais === '1058') {
            this.entity.municipio = null;
            this.entity.cidade = null;
        } else if (this.entity.pais.pais !== '1058') {
            this.entity.municipio = {
                ibge: '9999999',
                nome: 'EXTERIOR - EX',
                uf: 'EX'
            };

            this.entity.cidade = 'EXTERIOR';
        }
    }
}
