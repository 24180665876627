import angular from 'angular';

export class WorkflowService {
  static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

  public loading_deferred_filter: any = null;
  public entities: any[] = [];
  public loaded: boolean = false;
  public constructors: any = {};
  public loadParams: any = {
    to_load: 3,
    busy: false,
    finished: false,
  };
  public loading_deferred: any = null;

  constructor(public $http: angular.IHttpService, public nsjRouting: any, public $rootScope: angular.IScope, public $q: angular.IQService) {
  }

  _load(constructors: any) {
    this.loading_deferred = this.$q.defer();

    return this.$q((resolve, reject) => {
      this.$http({
        method: 'GET',
        url: this.nsjRouting.generate(constructors.rotaWorkflow + 'listar_acoes_workflow', { 'id': constructors.id }, true),
        timeout: this.loading_deferred.promise
      })
        .then(function (response: any) {
          resolve(response.data);
        });
    });

  }

  config(id: any, rota: any) {
    this.constructors.id = id;
    this.constructors.rotaWorkflow = rota;
  }

  loadIni(id: any, rota: any) {
    this.config(id, rota);

    this.loadParams.finished = false;
    this.loadParams.to_load = 3;
    this.load();
  }

  /*
  * constructors:
  * id
  * rotaWorkflow
  * */
  load() {
    this.entities = [];
    if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
      this.loadParams.busy = true;

      this._load(this.constructors)
        .then((data: any) => {
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              this.entities.push(data[i]);
            }
          }
          if (data.length < 20) {
            this.loadParams.finished = true;
            this.constructors._acoes = this.entities;
            this.$rootScope.$broadcast('workflow_acoes', this.constructors);
          } else {
            this.loadParams.finished = false;
          }
          this.loaded = true;

          this.loadParams.to_load--;
        })
        .catch((error) => {
          if (error.xhrStatus !== 'abort') {
            this.loadParams.finished = true;
          }
        })
        .finally(() => {
          if (this.loaded || this.loadParams.finished) {
            this.loadParams.busy = false;
          }
          this.load();
        });
    }

    return this.entities;
  }

  afterReloadWorkflow() {
    this.$rootScope.$broadcast('workflow_reloaded_after', {});
  }

  /* Executar Ação do Workflow */
  executarAcao(acao: any): any {
    this.loading_deferred = this.$q.defer();

    let method = 'post';
    let data: any = {};
    Object.assign(data, acao);

    return this.$q((resolve: any, reject: any) => {
      let url = this.nsjRouting.generate(this.constructors.rotaWorkflow + 'executar_acao_workflow', { 'id': acao.id }, true);
      this.$http({
        method: method,
        url: url,
        data: angular.copy(data),
        timeout: this.loading_deferred.promise
      })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((response: any) => {
          reject(response);
        })
        .finally(() => {
          this.afterReloadWorkflow();
        });
    });
  }
}
