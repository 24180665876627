import * as angular from 'angular';
import { MeurhPromocoesPassoumController } from './promocoes.passoum.controller';

export class MeurhPromocoesPassoumComponent implements angular.IComponentOptions {
	static selector = 'meurhPromocoesPassoumComponent';
	static controller = MeurhPromocoesPassoumController;
	static template = require('!!html-loader!./promocoes.passoum.html');
	static transclude = true;
	static bindings = {
		passo: '=',
		entity: '=',
		mensagensPassos: '=',
		passoZeroAtivo: '=',
		colaboradorSelecionado: '=',
	};
}
