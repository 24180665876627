import { IDetalhamentoErro } from '../models/escala.erros.model';

export class MeurhEscalaErrosModalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: ng.ui.bootstrap.IModalService) {
  }

  open(entity: Array<IDetalhamentoErro>, tipoImportacao: string): angular.ui.bootstrap.IModalInstanceService {
    return this.$uibModal.open({
      template: require('html-loader!./escala.errosmodal.html'),
      controller: 'MeurhEscalaErrosModalController',
      controllerAs: 'mrh_scl_rrs_mdl_cntrllr',
      windowClass: 'nsj-modal-lg-no-height',
      resolve: {
        entity: (): Array<IDetalhamentoErro> => entity,
        tipoImportacao: (): string => tipoImportacao
      }
    });
  }
}
