import angular from 'angular';
import { ITarifaVT } from '../models/tarifavt.model';

export class MeurhSolicitacoesalteracoesvtstarifasFormController implements angular.IController {
	static $inject = ['$scope', '$element', '$attrs', 'NewToaster'];

	public form: angular.IFormController;
	public entity: ITarifaVT;
	public usoDiario: number | null;

	constructor(
		public $scope: angular.IScope,
		public $element: any,
		public $attrs: any,
		public NewToaster: any
	) {
		$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.$setDirty();
				if (newValue.quantidade <= 0 || newValue.quantidade > 100 || isNaN(newValue.quantidade)) {
					this.form.quantidade.$setValidity('quantidadeInvalida', false);
				} else {
					this.form.quantidade.$setValidity('quantidadeInvalida', true);
				}
			}
		}, true);
	}

	calculaUsoDiario(event?: angular.IAngularEvent & { detail: {value: number, error: boolean} }): void {
		if (event) {
			this.entity.quantidade = event.detail.value;
		}

		if (this.valorExiste(this.entity.quantidade) &&
            this.valorExiste(this.entity.tarifaconcessionariavt) &&
			this.entity.quantidade > 0
		) {
			this.usoDiario = Number(this.entity.tarifaconcessionariavt.valor) * this.entity.quantidade;
        } else if (this.valorExiste(this.usoDiario)) {
            this.usoDiario = null;
        }
    }

	valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
