import angular from 'angular';

export const ExportacaoArquivoAejListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		$stateProvider
			.state('exportacaoarquivoaej', {
				url: '/ponto/exportacaoarquivoaej',
				template: require('html-loader!./index/exportacaoarquivoaej.index.html'),
				controller: 'ExportacaoArquivoAejIndexController',
				controllerAs: 'mrh_exportarqaej_lst_cntrllr',
				reloadOnSearch: false
			});
	}
];
