import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesdadossalariaisFormController } from './solicitacoesalteracoesdadossalariais.form.controller';

export class MeurhSolicitacoesalteracoesdadossalariaisFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesalteracoesdadossalariaisFormComponent';
	static controller = MeurhSolicitacoesalteracoesdadossalariaisFormController;
	static template = require('!!html-loader!./solicitacoesalteracoesdadossalariais.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
