import angular from 'angular';

export class EstabelecimentosHabilitadosModalEditarResponsavelService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: any, public form: angular.IFormController) { }

  open(entity: any, index: number) {

    return this.$uibModal.open({
      template: require('html-loader!./estabelecimentoshabilitados.modaleditarresponsavel.html'),
      controller: ['$uibModalInstance', '$rootScope', '$sce', '$compile', '$scope', function ModalEditarResponsavel($uibModalInstance: any, $rootScope: any, $sce: any, $compile: any, $scope: any, form: any) {
        var selfModal = this;
        selfModal.isLoading = false;
        selfModal.isValid = false;
        selfModal.responsavel = {};

        selfModal.responsavel.nome = entity.nomeresponsavel;
        selfModal.responsavel.cpf = entity.cpfresponsavel;
        selfModal.colaborador = entity.nome;

        selfModal.alterou = () => {
            if ((selfModal.responsavel.nome !== entity.nomeresponsavel || selfModal.responsavel.cpf !== entity.cpfresponsavel) && (!selfModal.form.cpf.$error.cpfInvalid && !selfModal.form.cpf.$error.mask)) {
                selfModal.isValid = true;
            } else {
                selfModal.isValid = false;
            }
        };

        selfModal.editar = () => {
            entity = selfModal.responsavel;
            $uibModalInstance.close(entity);
        };

        selfModal.close = () => {
            $uibModalInstance.dismiss('fechar');
        };
      }],
      controllerAs: '$ctrl',
      backdrop: 'static',
      windowClass: 'nsj-modal-lg-no-height',
      resolve: {
        entity: function () {
          return angular.copy(entity);
        }
      }
    });
  }
}
