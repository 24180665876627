import angular = require('angular');
import moment = require('moment');
import { ConcessionariasVtsService } from 'modules/Concessionariasvts/concessionariasvts.service';

export class MeurhVagasValetransporteFormmodalController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'ConcessionariasVtsService', 'MeurhVagasModalService'];

    public valetransporte: any;
    public action: string;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public ConcessionariasVtsService: ConcessionariasVtsService
    ) {

    }

    $onInit(): void {
        if (this.action === 'edit' && this.valetransporte.tarifaconcessionariavt) {
            this.ConcessionariasVtsService.get(this.valetransporte.tarifaconcessionariavt.concessionariavt)
                .then((response: any): void => {
                    this.valetransporte.concessionaria = response;
                }).catch((e: any): void => {
                    console.log(e);
                });
        }

        if (!this.valetransporte.quantidade) {
            this.valetransporte.quantidade = 2;
        }

        if (this.action === 'show') {
            this.calculaTotal();
        }
    }

    getQuantidade(event: angular.IAngularEvent & { detail: any }): void {
        this.valetransporte.quantidade = event.detail.value;
        this.valetransporte.erroQuantidade = event.detail.error;
        if (this.valorExiste(this.valetransporte.tarifaconcessionariavt) && (!this.valorExiste(this.valetransporte.erroQuantidade) || this.valetransporte.erroQuantidade === false)) {
            this.calculaTotal();
        }
    }

    getTarifaconcess() {
        if (this.valorExiste(this.valetransporte.quantidade) &&
            this.valorExiste(this.valetransporte.tarifaconcessionariavt) && this.valetransporte.quantidade > 0) {
            this.calculaTotal();
        } else {
            if (this.valorExiste(this.valetransporte.totaldiario)) {
                delete this.valetransporte.totaldiario;
            }
        }
    }

    calculaTotal() {
        this.valetransporte.totaldiario = this.valetransporte.tarifaconcessionariavt.valor * this.valetransporte.quantidade;
    }

    verificaConcessionaria() {
        if (this.valetransporte.concessionaria) {
            if (this.valetransporte.concessionaria.concessionariavt) {
                return { 'concessionariavt': this.valetransporte.concessionaria.concessionariavt };
            }
        }
        return {};
    }

    alteraConcessionaria(): void {
        if (this.valetransporte.concessionaria) {
            this.$rootScope.$broadcast('tarifaconcessionariavt_refresh', { concessionariavt: this.valetransporte.concessionaria.concessionariavt  });
            this.valetransporte.tarifaconcessionariavt = null;
        }
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
