import angular from 'angular';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { MeurhEscalaSchedulerService } from '../escala.scheduler.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { SchedulerStatic } from '../../../../shared/components/dhtmlx-scheduler/dhtmlxscheduler';
import { IFiltroListagemEscala } from '../models/escala.filtros.model';
import { ITrabalhadorListagemScheduler } from '../models/escala.scheduler.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhEscalaFiltrosController {

    static $inject = [
        '$scope',
        '$rootScope',
        'MeurhEscalaSchedulerService',
        'ListagemService'
    ];

    public estaFiltrando: boolean = false;
    public listagemFiltroColaboradores: Array<ITrabalhador> = [];
    public schedulerEventos: SchedulerStatic; // instancia do calendario
    public colaboradores: Array<ITrabalhador>;
    public filtros: IFiltroListagemEscala = {
        colaborador: {
            nome: 'Colaboradores',
            valor: []
        },
        departamento: {
            nome: 'Departamentos',
            valor: null
        },
        cargo: {
            nome: 'Cargos',
            valor: null
        },
        lotacao: {
            nome: 'Lotações',
            valor: null
        },
        horario: {
            nome: 'Horários',
            valor: null
        },
        grupodeponto: {
            nome: 'Grupos de ponto',
            valor: null
        }
    };

    public filtrosVisibilidade: { empresa: string, estabelecimento: string };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_calendario'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any
        },
        public MeurhEscalaSchedulerService: MeurhEscalaSchedulerService,
        public ListagemService: ListagemService,
	) {
        // verifica filtro
        this.$scope.$watch('$ctrl.filtros', (newValues: Array<ITrabalhador>, oldValues: Array<ITrabalhador>): void => {
            if (newValues !== oldValues) {
                this.filtraListagem();
			}
        }, true);

        this.$scope.$watch('$ctrl.schedulerEventos.serverList("colaboradoresAtuais")', (newValues: Array<boolean>, oldValues: Array<boolean>): void => {
			if (newValues !== oldValues && !this.MeurhEscalaSchedulerService.filtroListagemAtivo) {
                this.listagemFiltroColaboradores = this.schedulerEventos.serverList('colaboradoresAtuais').map(colab => colab.colaboradorObj);
			}
		}, true);
    }

    $onInit(): void {
        this.montaListagemVisibilidade();
    }

    montaListagemVisibilidade(): void {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filtrosVisibilidade.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filtrosVisibilidade.empresa ? this.ListagemService.montaEstabelecimentos(this.filtrosVisibilidade.empresa, this.empresas, this.listagemPermissao) : [];
        this.filtrosVisibilidade.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.limpaFiltros();
        this.$rootScope.$broadcast('departamento_refresh', this.filtrosVisibilidade);
        this.$rootScope.$broadcast('cargo_refresh', this.filtrosVisibilidade);
        this.$rootScope.$broadcast('horario_refresh', this.filtrosVisibilidade);
        this.$rootScope.$broadcast('lotacao_refresh', this.filtrosVisibilidade);
        this.$rootScope.$broadcast('grupo_refresh', this.filtrosVisibilidade);
        this.$rootScope.$broadcast('colaboradores_refresh');
    }

    limpaFiltros(): void {
        Object.keys(this.filtros).forEach((key) => {
            Array.isArray(this.filtros[key].valor) ? this.filtros[key].valor = [] : this.filtros[key].valor = null;
        });
    }

    filtraListagem(): void {
		let listagemFiltrada: Array<ITrabalhadorListagemScheduler> = [...this.MeurhEscalaSchedulerService.colaboradoresCalendario];

        if (Object.values(this.filtros).every(x => x.valor === null || (Array.isArray(x.valor) && x.valor.length === 0))) {
            this.estaFiltrando = this.MeurhEscalaSchedulerService.filtroListagemAtivo = false;
            // a dataAuxiliar é usada para forcar a atualizacao da view atual
            // logo apos é usado o setCurrentView para disparar o evento 'onBeforeViewChange'
            let dataAuxiliar = this.schedulerEventos.date.add(this.schedulerEventos.getState().min_date, 1, 'minute');
            this.schedulerEventos.setCurrentView(dataAuxiliar);
			return;
        }
        this.estaFiltrando = this.MeurhEscalaSchedulerService.filtroListagemAtivo = true;

        if (this.filtros.colaborador.valor.length > 0) {
            listagemFiltrada = listagemFiltrada.filter((colaborador) => {
                return this.filtros.colaborador.valor!.some((colab: ITrabalhador) => colab!.trabalhador === colaborador.colaboradorObj.trabalhador);
            });
        }
        if (this.filtros.departamento.valor) {
            listagemFiltrada = listagemFiltrada.filter((colaborador) => {
                return this.filtros.departamento.valor!.departamento === colaborador.colaboradorObj.departamentoobj.departamento;
            });
        }
        if (this.filtros.cargo.valor) {
            listagemFiltrada = listagemFiltrada.filter((colaborador) => {
                return this.filtros.cargo.valor!.cargo === colaborador.colaboradorObj.cargoobj.cargo;
            });
        }
        if (this.filtros.lotacao.valor) {
            listagemFiltrada = listagemFiltrada.filter((colaborador) => {
                return this.filtros.lotacao.valor!.lotacao === colaborador.colaboradorObj.lotacaoobj.lotacao;
            });
        }
        if (this.filtros.horario.valor) {
            listagemFiltrada = listagemFiltrada.filter((colaborador) => {
                return this.filtros.horario.valor!.horario === colaborador.colaboradorObj.horario.horario;
            });
        }
        if (this.filtros.grupodeponto.valor) {
            listagemFiltrada = listagemFiltrada.filter((colaborador) => {
                return this.filtros.grupodeponto.valor!.grupo === colaborador.colaboradorObj.grupoobj.grupo;
            });
        }

        this.schedulerEventos.updateCollection('colaboradoresAtuais', listagemFiltrada);
	}
}
