import { IFiltroEventoEscala } from '../models/escala.filtros.model';

export class MeurhEscalaVisualizacaoModalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: ng.ui.bootstrap.IModalService) {
  }

  open(entity: IFiltroEventoEscala): angular.ui.bootstrap.IModalInstanceService {
    return this.$uibModal.open({
      template: require('html-loader!./escala.visualizacaomodal.html'),
      controller: 'MeurhEscalaVisualizacaoModalController',
      controllerAs: 'mrh_scl_vslzc_mdl_cntrllr',
      windowClass: 'nsj-modal-lg-no-height',
      resolve: {
        entity: (): IFiltroEventoEscala => {
            return entity;
        }
      }
    });
  }
}
