import angular from 'angular';
import { IAnexo } from './models/anexo.model';
import { LoadParams } from '../../../shared/models/loadparams.model';

export class MeurhAnexosService {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

	public loading_deferred_filter: any = null;
	public entity: IAnexo = {};
	public entities: Array<IAnexo> = [];
	public loaded: boolean = false;
	public constructors: any = {};
	public after: any = '';
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter: string = '';
	public entityAnexoLista: any = [];
	public mimeType: string;

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IScope,
		public $q: angular.IQService
	) {
		this.loadParams = new LoadParams();
	}

	/* REQUISIÇÃO LISTAR
	* constructors:
	* id
	* rotaAnexo
	* */
	getAnexoList(constructors: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http.get(
				this.nsjRouting.generate(constructors.rotaAnexo + 'list', angular.extend({ }, constructors, { }), true),
				{ timeout: this.loading_deferred.promise }
			)
			.then((response: any): void => {
				this.mimeType = response.headers('Content-Type');
				resolve(response.data);
			})
			.catch((response: any): void => {
				reject(response);
			});
		});
	}

	/* REQUISIÇÃO REMOVER
	* constructors:
	* id
	* solicitacaodocumento
	* rotaAnexo
	* */
	deleteAnexo(constructors: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		let method = 'DELETE';

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			let url = this.nsjRouting.generate(constructors.rotaAnexo + 'delete', angular.extend(constructors), true);

			this.$http({
				method: method,
				url: url
			})
			.then((response: any): void => {
				this.$rootScope.$broadcast('meurh_solicitacoesdocumentos_delete_success', response.data);
				resolve(response.data);
			})
			.catch((response: any): void => {
				reject(response);
			});
		});
	}

	/* REMOVER TODOS OS ANEXOS DE UMA SOLICITACAO */
	deleteAllAnexos(constructors: any): void {
		this.getAnexoList(constructors)
			.then((response: any): void => {
				let entityAnexoList = response;

				if (entityAnexoList.length !== 0) {
					entityAnexoList.forEach((element: any, index: any): void => {
						element['id'] = element.solicitacaodocumento;
						element['trabalhador'] = constructors.trabalhador;

						this.deleteAnexo(element)
							.then((response: any): void => {
								if (index === entityAnexoList.length - 1) {
									this.$rootScope.$broadcast('meurh_solicitacaodocumento_deleted_All', response.data);
								}
							}).catch((e: any): void => {
								console.log(e);
							});
					});
				} else {
					this.$rootScope.$broadcast('meurh_solicitacaodocumento_deleted_All', response.data);
				}
			}).catch((e: any): void => {
				console.log(e);
			});
	}

	/* EXIBIR
	* constructors:
	* id
	* rotaAnexo
	* */
	getInfoAnexo(constructors: any, anexo: IAnexo): angular.IHttpPromise<any> {
		constructors.id = anexo.solicitacaodocumento;
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http.get(
				this.nsjRouting.generate(constructors.rotaAnexo + 'get', angular.extend({ }, constructors, { }), true),
				{ timeout: this.loading_deferred.promise }
			)
			.then((response: any): void => {
				resolve(response.data);
			})
			.catch((response: any): void => {
				reject(response);
			});
		});
	}

	/* DOWNLOAD
	* constructors:
	* id
	* rotaAnexo
	* */
	getAnexoDownload(constructors: any, anexo: IAnexo): angular.IHttpPromise<any> {
		constructors.solicitacaodocumento = anexo.solicitacaodocumento;
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http.get(
				this.nsjRouting.generate(constructors.rotaAnexo + 'download', angular.extend({ }, constructors, { }), true),
				{ responseType: 'arraybuffer', timeout: this.loading_deferred.promise }
			)
			.then((response: any): void => {
				resolve(response.data);
			})
			.catch((response: any): void => {
				reject(response);
			});
		});
	}

	leituraDocumentos(constructors: {}): angular.IHttpPromise<any> {
		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http.post(
				this.nsjRouting.generate('leituradocumentos_ler_documento', {}, true),
				constructors
			)
			.then((response: any): void => {
				this.$rootScope.$broadcast('meurh_leituradocumentos_finalizada', response.data);
			})
			.catch((response: any): void => {
				reject(response);
			});
		});
	}
}
