import angular from 'angular';

export class FormController {
  static $inject = ['$scope', '$rootScope'];
  constructor(
    public $scope: angular.IScope,
    public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
  ) {
  }
}
