import angular from 'angular';
import { IApontamentoTrabalhador } from '../models/apontamentostrabalhadores.model';

export class ApontamentosTrabalhadoresModalController {
    static $inject = ['entity', 'periodoapuracao', 'trabalhadoresguids', '$uibModalInstance', '$rootScope', 'NewToaster', '$scope', 'moment', 'tipo'];

    public submitted = false;
    public form: angular.IFormController;

    constructor(
        public entity: IApontamentoTrabalhador,
        public periodoapuracao: any,
        public trabalhadoresguids: any,
        public $uibModalInstance: any,
        public $rootScope: any,
        public NewToaster: any,
        public $scope: any,
        public moment: any,
        public tipo: any
    ) {
        $scope.$on('periodoapuracao_loaded', (event: any, args: any) => {
            let inicio, fim;
            inicio = moment(periodoapuracao.datainicial, 'YYYY-MM-DD');
            fim = moment(periodoapuracao.datafinal, 'YYYY-MM-DD');
            entity.periodoapuracao = {};
            entity.periodoapuracao.datainicial = inicio;
            if (fim > $rootScope.currentDate) {
                entity.periodoapuracao.datafinal = $rootScope.currentDate;
            } else {
                entity.periodoapuracao.datafinal = fim;
            }
        });
    }

    submit() {
        let sem_erro_limite_movimento = true;
        if (this.entity.movimentos !== undefined && this.entity.movimentos !== null) {
            this.entity.movimentos.forEach(i => {
                if (
                    i.evento.evento_unidade &&
                    (i.evento.evento_unidade !== 0 && i.evento.evento_unidade !== 1 && i.evento.evento_unidade !== 2
                    && i.evento.evento_unidade !== 8 && i.evento.evento_unidade !== 10) &&
                    !this.entity.movimentos.some(this.unidadeEvento)
                ) {
                    sem_erro_limite_movimento = true;
                } else if (
                    (i.conteudo === null || i.conteudo === '' || i.evento === null) &&
                    (!i.evento.evento_unidade || (i.evento.evento_unidade === 0 || i.evento.evento_unidade === 1 ||
                    i.evento.evento_unidade === 2 || i.evento.evento_unidade === 8 || i.evento.evento_unidade === 10))
                ) {
                    sem_erro_limite_movimento = false;
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro no cadastramento da rubrica.',
                        body: 'Não é possível cadastrar um apontamento com uma rubrica vazia.'
                    });
                }
            });

            if (this.entity.movimentos.some(this.lotacoesVazias)) {
                sem_erro_limite_movimento = false;
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Erro no cadastramento da rubrica.',
                    body: 'Não é possível cadastrar um apontamento com uma rubrica sem lotação.'
                });
            }
            if (!(this.entity.trabalhador !== undefined)) {
                sem_erro_limite_movimento = false;
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Erro no cadastramento da rubrica.',
                    body: 'Não é possível cadastrar um apontamento com o colaborador vazio.'
                });
            }
            if (this.entity.movimentos.some(this.validarValorRubricas)) {
                sem_erro_limite_movimento = false;
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível adicionar uma rubrica cujo valor excede o limite.'
                });
            }
        } else {
            sem_erro_limite_movimento = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'É preciso adicionar pelo menos uma rubrica'
            });
        }

        if (sem_erro_limite_movimento) {
            this.submitted = true;
            if (this.form.$valid) {
                this.entity.$__loaded = true;
                this.$uibModalInstance.close(this.entity);
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos do formulário apresentam erros'
                });
            }
        }
    }

    close() {
        this.$uibModalInstance.dismiss('fechar');
    }

    rubricasVazias(elemento: any, index: any, array: any) {
        return (elemento.conteudo === 0 || elemento.conteudo === null) ? true : false;
    }

    eventosVazios(elemento: any, index: any, array: any) {
        return (elemento.evento == null) ? true : false;
    }

    lotacoesVazias(elemento: any, index: any, array: any) {
        return (elemento.lotacao == null) ? true : false;
    }

    unidadeEvento(elemento: any, index: any, array: any) {
        return (elemento.evento.evento_unidade === 0 || elemento.evento.evento_unidade === 1 || elemento.evento.evento_unidade === 2 ||
            elemento.evento.evento_unidade === 8 || elemento.evento.evento_unidade === 10) ? true : false;
    }

    // essa função é usada no Array.prototype.some
    validarValorRubricas(elemento: any, index: any, array: any) {
        let valorRubrica = elemento.conteudo;
        let limiteerro = elemento.evento.limiteerro;
        return limiteerro > 0 ? valorRubrica > limiteerro : false;
    }

    validarTrabalhador() {
        if (this.trabalhadoresguids.includes(this.entity.trabalhador ? this.entity.trabalhador.trabalhador : '')) {
            this.entity.trabalhador = null;
            this.NewToaster.pop({
                type: 'error',
                title: 'Trabalhador já possui apontamento',
                body: 'Não é possível cadastrar mais de um apontamento para o mesmo trabalhador'
            });
        }
    }
}
