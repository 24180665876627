import angular from 'angular';
import { MeurhEscalaColaboradoresFormModalService } from './modal/colaboradores.modal.service';
import { ITrabalhador } from '../../../../../Trabalhadores/models/trabalhador.model';

export class MeurhEscalaColaboradoresFormController {

    static $inject = ['$scope', '$rootScope', '$state', 'MeurhEscalaColaboradoresFormModalService'];

    public entity: Array<ITrabalhador>;
    public disabled: boolean;
    public empresa: string;
    public estabelecimento: string;

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService,
		public $state: angular.ui.IStateService,
        public ColaboradoresModalService: MeurhEscalaColaboradoresFormModalService,
	) {
    }

    abrirModalColaboradores(): void {
        this.ColaboradoresModalService.open(
            this.entity,
            this.empresa,
            this.estabelecimento
        ).result.then((data: Array<ITrabalhador>) => {
            if (data) {
                this.entity = data;
            }
        })
        .catch((error: any): void => {/**/});
    }

    remove(colaborador: ITrabalhador): void {
        const index = this.entity.indexOf(colaborador);
        this.entity.splice(index, 1);
    }
}
