import { IModalConfirmRelatorios } from '../../models/modalConfirmRelatorios.model';

export class ModalConfirmRelatoriosController {
  static $inject = [
    'texts',
    '$uibModalInstance',
  ];

  constructor(
    public texts: IModalConfirmRelatorios,
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  ) { }
}
