import angular from 'angular';
import { EstabelecimentosHabilitadosService } from '../estabelecimentoshabilitados.service';
import { EstabelecimentosHabilitadosModalService } from '../modalBuscaColaboradores/estabelecimentoshabilitados.modalbuscarcolaboradores.service';
import { EstabelecimentosHabilitadosModalEditarResponsavelService } from './modalEditarResponsavel/estabelecimentoshabilitados.modaleditarresponsavel.service';
import { TrabalhadoresPontoService } from '../../trabalhadoresponto.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { Usuario } from '../../../../../core/usuario/models/usuario-model';

type AlteracaoColaborador = {
    trabalhador: {
      trabalhador: string;
    };
    tipoalteracao: string;
    cpf: string;
    nome: string;
    cpfresponsavel: string;
    nomeresponsavel: string;
    nsr?: number;
    removido?: boolean;
    incluidoponto: boolean;
};

export class EstabelecimentosHabilitadosEditController {
    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        '$location',
        'EstabelecimentosHabilitadosService',
        'ListagemService',
        'EstabelecimentosHabilitadosModalService',
        'EstabelecimentosHabilitadosModalEditarResponsavelService',
        'TrabalhadoresPontoService',
        'NewToaster'
    ];

    public busy: boolean = false;
    public filters: any = {};
    public entity: any = {};
    public entities: Array<AlteracaoColaborador> = [];
    public arrayBusca: Array<AlteracaoColaborador> = [];
    public form: angular.IFormController;
    public empresas: Array<any> = [];
    public estabelecimentos: Array<any> = [];
    public todosColaboradoresAdicionados: boolean = false;
    public isValid: boolean = false;
    public cpfMasked: string = '';
    public colaboradoresPersonalizados: Array<AlteracaoColaborador> = [];
    public modalColaboradoresPersonalizadosOpen = false;
    public listaTodosIncluidoPonto = false;

    public constructors: any = {
        'incluidoponto': '1',
        'desligado': '0',
        'tipoalteracao[0]': 'A',
        'tipoalteracao[1]': 'I'
    };

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any,
            filterData: any,
            usuario: Usuario
        },
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public estabelecimentosHabilitadosService: EstabelecimentosHabilitadosService,
        public listagemService: ListagemService,
        public estabelecimentosHabilitadosModalService: EstabelecimentosHabilitadosModalService,
        public estabelecimentosHabilitadosModalEditarResponsavelService: EstabelecimentosHabilitadosModalEditarResponsavelService,
        public TrabalhadoresPontoService: TrabalhadoresPontoService,
        public NewToaster: any
    ) {
        this.busy = true;
        this.estabelecimentosHabilitadosService.get(this.$stateParams.id);

        this.$scope.$on('ponto_alteracoesentidades_get_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.entity = args;

            this.constructors.estabelecimento = this.entity.estabelecimento.estabelecimento,
            this.carregaTrabalhadoresPonto();
        });

        this.$scope.$on('ponto_trabalhadores_list_loaded', (event: angular.IAngularEvent, args: Array<AlteracaoColaborador>) => {
            if (this.modalColaboradoresPersonalizadosOpen) {
                return;
            }

            this.entities = [...args, ...this.colaboradoresPersonalizados];
            this.busy = false;
        });

        this.$scope.$on('ponto_trabalhadores_list_all_finished', (event: angular.IAngularEvent, args: Array<AlteracaoColaborador>) => {
            if (this.modalColaboradoresPersonalizadosOpen) {
                return;
            }
            this._adicionarColaboradores([...args, ...this.colaboradoresPersonalizados]);
            if (this.listaTodosIncluidoPonto) {
                this.listaTodosIncluidoPonto = false;
                this.busy = true;
                this.carregaTrabalhadoresPonto(true, {
                    'incluidoponto': '0',
                    'desligado': '0'
                });
                return;
            }
            this.isValid = true;
            this.busy = false;
        });

        this.$scope.$on('ponto_trabalhadores_get_erro', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao tentar buscar os colaboradores.',
			});
        });

        this.$scope.$on('ponto_alteracoescolaboradores_inserir_lote_erro', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao tentar adicionar o(s) colaborador(es).',
			});
        });

        this.$scope.$on('ponto_alteracoescolaboradores_inserir_sucesso', (event: angular.IAngularEvent, args: any, response: any) => {
			this.busy = false;
            this.NewToaster.pop({
				type: 'success',
				title: 'O estabelecimento foi editado com sucesso.',
			});

            this.$state.go('estabelecimentoshabilitados');
        });
    }

    carregaTrabalhadoresPonto(carregaTodos: boolean = false, constructors?: {}) {
        this.TrabalhadoresPontoService.constructors = constructors ? constructors : this.constructors;
        this.TrabalhadoresPontoService.reload(carregaTodos);
    }

    adicionarTodosColaboradores() {
        this.busy = true;
        this.todosColaboradoresAdicionados = true;
        this.listaTodosIncluidoPonto = true;
        this.entities = [];
        this.carregaTrabalhadoresPonto(true);
    }

    adicionarColaboradoresPersonalizados() {
        this.todosColaboradoresAdicionados = false;
        this.modalColaboradoresPersonalizadosOpen = true;
        this.estabelecimentosHabilitadosModalService.open([]).result.then((colaboradores) => {
            this.colaboradoresPersonalizados = [...colaboradores];
            this._adicionarColaboradores(colaboradores);
            this.isValid = true;
        }).catch(() => {/**/}).finally(() => this.modalColaboradoresPersonalizadosOpen = false);
    }

    _adicionarColaboradores(colaboradores: any) {
        colaboradores.forEach(entity => {
            const trabalhadorInserido = this.entities.find((trabalhador) => trabalhador.trabalhador === entity.trabalhador);

            if (!trabalhadorInserido) {
                entity.cpfresponsavel = this.entity.cpfresponsavel;
                entity.nomeresponsavel = this.entity.nomeresponsavel;
                this.entities.push(entity);
            }
        });
        this.busy = false;
    }

    async editar(entity: any, id: string) {
        const entityIndex = this.entities.findIndex((item: any) => item.trabalhador === id);
        const { nome, cpf } = await this.estabelecimentosHabilitadosModalEditarResponsavelService.open(entity, entityIndex).result;

        const { nomeresponsavel, cpfresponsavel } = this.entities[entityIndex];
        const hasChanges = nome !== nomeresponsavel || cpf !== cpfresponsavel;
        if (hasChanges) {
            this._atualizarEntidadeAlteracao(this.entities[entityIndex], nome, cpf);
            this.isValid = true;
        } else {
            this.isValid = false;
        }
    }

    _atualizarEntidadeAlteracao(entidade: any, nome: string, cpf: string) {
        entidade.nomeresponsavel = nome;
        entidade.cpfresponsavel = cpf;
        entidade.tipoalteracao = 'A';
    }

    removeColaborador(id: string) {
        this.todosColaboradoresAdicionados = false;

        const entityIndex = this.entities.findIndex((item: any) => item.trabalhador === id);

        if (entityIndex !== -1) {
            const entity = this.entities[entityIndex];

            if (entity.incluidoponto) {
                this._marcarComoRemovido(entity);
                this.isValid = true;
            } else {
                this.entities.splice(entityIndex, 1);
            }
        }

    }

    _marcarComoRemovido(entity: any) {
        entity.removido = true;
        entity.tipoalteracao = 'E';
    }

    salvar() {
        this.busy = true;
        let constructors: any = {};
        constructors['estabelecimento'] = this.entity.estabelecimento.estabelecimento;
        constructors['obra'] = this.entity.obra;

        const alteracoescolaboradores: AlteracaoColaborador[] = this.entities
            .filter((entity: any) => entity.tipoalteracao === 'A' || entity.tipoalteracao === 'E' || entity.nsr === null || !entity.incluidoponto)
            .map((entity: any) => {
                const alteracaoColaborador: any = {
                    trabalhador: {
                        trabalhador: entity.trabalhador,
                    },
                    tipoalteracao: entity.tipoalteracao,
                    nome: entity.nome,
                    cpf: entity.cpf,
                    nomeresponsavel: entity.nomeresponsavel,
                    cpfresponsavel: entity.cpfresponsavel
                };

                if (!entity.nsr || !entity.incluidoponto) {
                    alteracaoColaborador.tipoalteracao = 'I';
                } else if (entity.tipoalteracao === 'E' || entity.tipoalteracao === 'A') {
                    alteracaoColaborador.nsr = entity.nsr;
                }

                return alteracaoColaborador;
            });

        const jsonObject = {
            alteracoescolaboradores
        };
        const alteracaoColaboradorJson = JSON.stringify(jsonObject);

        this.estabelecimentosHabilitadosService.inserirAlteracoesColaboradores(constructors, alteracaoColaboradorJson);
    }

    cancelar() {
        this.$state.go('estabelecimentoshabilitados');
    }

    _removerFlagDeRemocao(entities: any) {
        entities.forEach(_ => {
            const colaboradorRemovido = this.entities.find((entity) => entity.removido === true);
            if (colaboradorRemovido) {
                delete colaboradorRemovido.removido;
            }
        });
    }

    loadMore() {
        this.TrabalhadoresPontoService.loadMore();
    }
}
