import angular from 'angular';
import { MeurhVisualizarArquivoModalController } from './modal/visualizararquivo/visualizararquivo.modal.controller';
import { MeurhArquivosService } from './arquivos.service';
import { MeurhVisualizarArquivoModalService } from './modal/visualizararquivo/visualizararquivo.modal.service';

export const MeurhArquivosModule = angular.module('MeurhArquivosModule', ['ui.router.state'])
    .controller('MeurhVisualizarArquivoModalController', MeurhVisualizarArquivoModalController)
    .service('MeurhArquivosService', MeurhArquivosService)
    .service('MeurhVisualizarArquivoModalService', MeurhVisualizarArquivoModalService)
    .name;
