import angular from 'angular';

export class AvisoFeriasService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entity: any;
    public entities: Array<any> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService
    ) { }

    reload(): Array<any> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k) => { delete this.filters[k]; });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('avisoferias_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                        this.after['trabalhador'] = this.entities[this.entities.length - 1]['trabalhador'];
                        this.after['avisoferiastrabalhador'] = this.entities[this.entities.length - 1]['avisoferiastrabalhador'];
                    }
                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('avisoferias_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                    this.load();
                });
        }

        return this.entities;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: any) {
        for (let i in this.entities) {
            if (this.entities[i]['avisoferiastrabalhador'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    delete($identifier: any, force: any) {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['avisoferiastrabalhador'];
        }
        if ($identifier) {
            if (force || confirm('Tem certeza que deseja deletar?')) {
                this.loading_deferred = this.$q.defer();
                this.$http
                    .delete(this.nsjRouting.generate('avisoferias_delete', angular.extend(this.constructors, { 'id': $identifier }), true), { timeout: this.loading_deferred.promise })
                    .then((response) => {
                        this.$rootScope.$broadcast('avisoferias_deleted', {
                            entity: this.entity,
                            response: response
                        });
                    })
                    .catch((response) => {
                        this.$rootScope.$broadcast('avisoferias_delete_error', {
                            entity: this.entity,
                            response: response
                        });
                    });
            }
        }
    }

    _save(entity: any, autosave: any) {
        let method, url;
        if (entity['avisoferiastrabalhador']) {
            method = 'PUT';
            url = this.nsjRouting.generate('avisoferias_put', { 'id': entity['avisoferiastrabalhador'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('avisoferias_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        let data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
            .finally(() => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    save(entity: any, autosave: any) {

        this
            ._save(entity, autosave)
            .then((response: any) => {
                if (response.data.avisoferiastrabalhador) {
                    entity['avisoferiastrabalhador'] = response.data.avisoferiastrabalhador;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('avisoferias_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response) => {
                this.$rootScope.$broadcast('avisoferias_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    rejeitar(entity: any) {
        this.loading_deferred = this.$q.defer();
        entity['$$__submitting'] = true;
        return this.$q((resolve, reject) => {
            this.$http({
                method: 'post',
                url: this.nsjRouting.generate('avisoferias_rejeitar', { 'id': entity.avisoferiastrabalhador }, true),
                data: angular.copy(entity),
                timeout: this.loading_deferred.promise
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                })
                .finally(() => {
                    entity['$$__submitting'] = false;
                });
        });
    }

    get(identifier: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve, reject) => {
            this.$http
                .get(this.nsjRouting.generate('avisoferias_get', { 'id': identifier }, true), { timeout: this.loading_deferred.promise })
                .then((response) => {
                    this.$rootScope.$broadcast('avisoferias_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    }
}
