
import angular from 'angular';

import { WorkflowDefault } from './index/workflow.component';

import { WorkflowListController } from './index/workflow.index.controller';
import { WorkflowOldModalController } from './modais/oldModal/workflow.old.controller';
import { WorkflowDefaultModalController } from './modais/defaultModal/workflow.default.controller';
import { WorkflowCommentModalController } from './modais/commentModal/workflow.comment.controller';
import { WorkflowDynamicModalController } from './modais/dynamicModal/workflow.dynamic.controller';

import { WorkflowService } from './workflow.service';
import { WorkflowOldModalService } from './modais/oldModal/workflow.old.service';
import { WorkflowDefaultModalService } from './modais/defaultModal/workflow.default.service';
import { WorkflowCommentModalService } from './modais/commentModal/workflow.comment.service';
import { WorkflowDynamicModalService } from './modais/dynamicModal/workflow.dynamic.service';

export const WorkflowModule = angular.module('WorkflowModule', ['ui.router.state'])
    .component('workflowDefault', WorkflowDefault)
    .controller('WorkflowListController', WorkflowListController)
    .controller('WorkflowOldModalController', WorkflowOldModalController)
    .controller('WorkflowDefaultModalController', WorkflowDefaultModalController)
    .controller('WorkflowCommentModalController', WorkflowCommentModalController)
    .controller('WorkflowDynamicModalController', WorkflowDynamicModalController)
    .service('WorkflowService', WorkflowService)
    .service('WorkflowOldModalService', WorkflowOldModalService)
    .service('WorkflowDefaultModalService', WorkflowDefaultModalService)
    .service('WorkflowCommentModalService', WorkflowCommentModalService)
    .service('WorkflowDynamicModalService', WorkflowDynamicModalService)
    .name;
