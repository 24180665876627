import * as angular from 'angular';
import { MeurhFeriasService } from './ferias.service';

export const MeurhFeriasListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		let resolve = {
			'entity': [
				'MeurhFeriasService',
				'$stateParams',
				'$state',
				'$q',
				function (
					entityService: MeurhFeriasService,
					$stateParams: angular.ui.IStateParamsService,
					$state: angular.ui.IStateService,
					$q: angular.IQService
				): angular.ui.IStateParamsService | angular.IQService | {} {
					if ($stateParams['entity']) {
						return $stateParams['entity'];
					}

					if (!$stateParams['solicitacao']) {
						return {};
					}

					return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
						entityService.get($stateParams['solicitacao'])
							.then((data: unknown): void => {
								resolve(data);
							})
							.catch((error: any): void => {
								if (error.status === 404) {
									if ($state.href('ferias_marcacoes_not_found', $stateParams)) {
										$state.go('ferias_marcacoes_not_found', $stateParams);
									} else {
										$state.go('not_found', $stateParams);
									}
								}
							});
					});
				}
			]
		};

		$stateProvider
			.state('ferias_marcacoes', {
				url: '/ferias/marcacoes?q?situacao?estabelecimento',
				template: require('html-loader!./FeriasMarcacoes/index/feriasmarcacoes.index.html'),
				controller: 'Meurh\FeriasmarcacoesIndexController',
				controllerAs: 'mrh_frsmrcs_lst_cntrllr',
				reloadOnSearch: false
			})
			.state('ferias_marcacoes_show', {
				parent: 'ferias_marcacoes',
				url: '/:solicitacao?path',
				resolve: resolve,
				template: require('html-loader!./FeriasMarcacoes/show/feriasmarcacoes.show.html'),
				controller: 'Meurh\FeriasmarcacoesShowController',
				controllerAs: 'mrh_frsmrcs_shw_cntrllr',
			})
			.state('ferias_marcacoes_new', {
				parent: 'ferias_marcacoes',
				url: '/new',
				params: {
					trabalhador: {},
				},
				resolve: resolve,
				template: require('html-loader!./FeriasMarcacoes/new/feriasmarcacoes.new.html'),
				controller: 'Meurh\FeriasmarcacoesNewController',
				controllerAs: 'mrh_frsmrcs_nw_cntrllr',
			})
			.state('ferias_marcacoes_edit', {
				parent: 'ferias_marcacoes',
				url: '/:solicitacao/edit',
				resolve: resolve,
				template: require('html-loader!./FeriasMarcacoes/edit/feriasmarcacoes.edit.html'),
				controller: 'Meurh\FeriasmarcacoesEditController',
				controllerAs: 'mrh_frsmrcs_dt_cntrllr',
			})
			.state('ferias_marcar_em_grade', {
				url: '/ferias/marcaremgrade?q?situacao?estabelecimento',
				template: require('html-loader!./FeriasMarcarEmGrade/index/feriasmarcaremgrade.index.html'),
				controller: 'Meurh\FeriasmarcaremgradeIndexController',
				controllerAs: 'mrh_frsmrcrmgrd_lst_cntrllr',
				reloadOnSearch: false
			})
			.state('ferias_calendario_ferias', {
				url: '/ferias/calendarioferias?q?situacao?estabelecimento',
				template: require('html-loader!./CalendarioFerias/calendarioferias.index.html'),
				controller: 'Meurh\CalendarioFeriasController',
				controllerAs: 'mrh_clndr_frs_cntrllr',
				reloadOnSearch: false
			})
      .state('controleferias_relatorio', {
        url: '/ferias/controleferias?tipo',
        template: require('html-loader!./ControleFerias/controleferias.html'),
        controller: 'ControleFeriasController',
        controllerAs: 'cntrl_frs_ctrl',
        reloadOnSearch: false
    });
	}
];
