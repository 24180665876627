import angular from 'angular';
import { MeurhAdmissoesEnderecoFormController } from './endereco.form.controller';

export class MeurhAdmissoesEnderecoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesEnderecoFormComponent';
	static controller = MeurhAdmissoesEnderecoFormController;
	static template = require('!!html-loader!./endereco.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
