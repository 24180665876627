import angular from 'angular';
import moment from 'moment';
import { MeurhMudancaHorarioService } from '../mudancahorario.service';
import { MeurhMudancaHorarioModalService } from '../modal/mudancahorario.modal.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IMudancaHorario } from '../models/mudancahorario.model';
import { IHorarioAtual } from '../models/horarioatual.model';
import { IHorarioDetalhado } from '../models/horariodetalhado.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhMudancaHorarioFormController {
    static $inject = [
        '$rootScope',
        '$scope',
        'MeurhMudancaHorarioService',
        'MeurhMudancaHorarioModalService',
        'ListagemService',
        'NewToaster',
        '$http',
        'nsjRouting'
    ];

    public entity: IMudancaHorario;
    public horarioAtual: IHorarioAtual | null;
    public horarioDetalhadoAtual: IHorarioDetalhado | null;
    public horarioDetalhadoNovo: IHorarioDetalhado | null;
    public action: string;
	public form: angular.IFormController;
    public busy: boolean;
    public busyHorarioNovo: boolean = false;
    public datasAnterioresDesabilitadas: moment.Moment | null;

    public filters = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
    };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['criar_mudancahorario'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    public constructorLookupColaborador;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public $scope: angular.IScope,
        public entityService: MeurhMudancaHorarioService,
        public MeurhMudancaHorarioModalService: MeurhMudancaHorarioModalService,
        public ListagemService: ListagemService,
        public NewToaster: any,
        public $http: any,
        public nsjRouting: any
    ) {
        this.$scope.$watch('$ctrl.horarioAtual', (newValue: IHorarioAtual, oldValue: IHorarioAtual): void => {
            if (newValue !== oldValue) {
                this.datasAnterioresDesabilitadas = moment(this.horarioAtual?.datainicial).add(1, 'd');
            }
        }, true);

        this.$scope.$on('persona_trabalhadores_horarioatual_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.horarioAtual = args;
            this.entityService.getHorarioDetalhado(this.horarioAtual!.horario, {
                empresa: this.action === 'insert' ?
                    this.entity.trabalhador!.empresaobj.empresa :
                    this.entity.empresaobj.empresa,
                estabelecimento: this.action === 'insert' ?
                    this.entity.trabalhador!.estabelecimentoobj.estabelecimento :
                    this.entity.estabelecimentoobj.estabelecimento
            });
        });

        this.$scope.$on('persona_trabalhadores_horarioatual_error', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao carregar o horário atual do colaborador!'
            });
            this.busy = false;
        });

        this.$scope.$on('persona_trabalhadores_horariodetalhado_loaded', (event: angular.IAngularEvent, args: any): void => {
            if (this.entity.trabalhador) {
                if (args.tipo && args.tipo === 'novo') {
                    this.horarioDetalhadoNovo = args.data;
                    this.busyHorarioNovo = false;
                } else {
                    this.horarioDetalhadoAtual = args.data;
                    this.busy = false;
                }
            }
        });

        this.$scope.$on('persona_trabalhadores_horariodetalhado_error', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao carregar a jornada atual do colaborador!'
            });
            this.busy = false;
        });

    }

    $onInit(): void {
        if (this.action !== 'insert') {
            this.entityService.getHorarioAtual(this.entity.trabalhador!.trabalhador);
            this.atualizaHorarioDetalhadoNovo();
            this.busy = true;
        }
        if (this.action !== 'show') {
            this.constructorLookupColaborador = {
                'empresa': this.filters.empresa,
                'estabelecimento': this.filters.estabelecimento,
                'status': '00',
                'forcagestor': this.$rootScope.modoGestorPermissao('criar_mudancahorario')
            };
        }
        this.montaListagemVisibilidade();
    }

    montaListagemVisibilidade(): void {
        if (this.action === 'show') {
            this.filters.empresa = this.entity.estabelecimentoobj.empresa;
            this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
        }
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.constructorLookupColaborador = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            'forcagestor': this.$rootScope.modoGestorPermissao('criar_mudancahorario')
        };

    }

    visualizarHorario(): void {
        let modal = this.MeurhMudancaHorarioModalService.open(this.horarioDetalhadoAtual!);
        modal.result.then((response: any): void => {/**/}).catch((error: any): void => {/**/});
    }

    atualizaHorarioDetalhadoNovo(): void {
        if (this.entity.horario) {
            this.verificaHorarioNovo();
            this.entityService.getHorarioDetalhado(this.entity.horario.horario, {
                empresa: this.action === 'insert' ?
                    this.entity.trabalhador!.empresaobj.empresa :
                    this.entity.empresaobj.empresa,
                estabelecimento: this.action === 'insert' ?
                    this.entity.trabalhador!.estabelecimentoobj.estabelecimento :
                    this.entity.estabelecimentoobj.estabelecimento
            }, 'novo');
            this.busyHorarioNovo = true;
        } else {
            this.horarioDetalhadoNovo = null;
            this.busyHorarioNovo = false;
        }
    }

    verificaHorarioNovo(): void {
        if (this.action !== 'show') {
            if (this.horarioAtual?.horario === this.entity.horario?.horario) {
                this.form.horario.$setValidity('mesmohorario', false);
            } else {
                this.form.horario.$setValidity('mesmohorario', true);
            }
        }
    }

    limpaCamposHorarioNovo(): void {
        this.entity.data = null;
        this.entity.horario = null;
        this.atualizaHorarioDetalhadoNovo();
    }

    carregaColaboradorHorarios() {
        const params = {
            empresa: this.entity.trabalhador!.empresaobj.empresa,
            estabelecimento: this.entity.trabalhador!.estabelecimentoobj.estabelecimento
        };
        this.$rootScope.$broadcast('horario_refresh', params);
        this.entityService.getHorarioAtual(this.entity.trabalhador!.trabalhador);
        this.busy = true;
        this.$http.get(
            this.nsjRouting.generate('meurh_solicitacoesmudancahorario_possuimudancahorario', {
                trabalhador: this.entity.trabalhador!.trabalhador,
                ...params
            }, true)
        )
        .then((response: any): void => {
            this.entity.solicitacaoaberta = response.data;
            if (this.entity.solicitacaoaberta.length > 0) {
                this.entity.solicitacaoaberta[0].created_at = moment(this.entity.solicitacaoaberta[0].created_at).format('DD-MM-YYYY HH:mm');
                if (this.entity.solicitacao === this.entity.solicitacaoaberta[0].solicitacao) {
                    this.entity.solicitacaoaberta = false;
                }
            }
            this.entity.$$__submitting = false;
        });
    }

    onChangeTrabalhador(trabalhadorSelecionado: any): void {

        if (trabalhadorSelecionado) {
            this.carregaColaboradorHorarios();
            this.limpaCamposHorarioNovo();
        } else {
            this.horarioAtual = null;
            this.horarioDetalhadoAtual = null;
            this.entity.solicitacaoaberta = false;
            this.limpaCamposHorarioNovo();
        }

    }

}
