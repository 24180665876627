import angular from 'angular';

export class RubricasPontoOverLoadService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = [];
    public loaded: any = false;
    public finished: any = false;
    public constructors: any = {};
    public after: any = {};
    public to_load: any = 3;
    public busy: any = false;
    public filters: any = {};
    public loading_deferred: any = null;
    public filter: any = '';
    public loading_deferred_filter: any;

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }
        return this.load();
    }


    search(filter: any) {
        let filters = {};
        let search = '';
        if (typeof (filter) !== 'undefined' && filter != null) {
            search = filter.search ? filter.search : '';
            for (let fil in filter.filters) {
                if (typeof (filter.filters[fil]) !== 'object') {
                    filters[fil] = filter.filters[fil];
                } else if (typeof (filter.filters[fil]) === 'object' && filter.filters[fil].hasOwnProperty('startDate')) {
                    filters[fil] = {
                        inicio: { condition: 'gte', value: filter.filters[fil].startDate },
                        fim: { condition: 'lte', value: filter.filters[fil].endDate }
                    };
                } else if (filter.filters[fil].constructor === Array) {
                    for (let pos in filter.filters[fil]) {
                        if (filter.filters[fil][pos] === undefined ||
                            (typeof (filter.filters[fil][pos]) === 'object' && filter.filters[fil][pos].value === undefined) ||
                            (typeof (filter.filters[fil][pos]) === 'object' && filter.filters[fil][pos].value === null)) {
                            delete filter.filters[fil][pos];
                        }
                    }
                    filters[fil] = filter.filters[fil];
                } else if (typeof (filter.filters[fil]) === 'object' && filter.filters[fil].value !== undefined && filter.filters[fil].value !== null) {
                    filters[fil] = filter.filters[fil];
                }
            }
            if (filter.filterLookup && filter.filterLookup.tiposatividadesfilter) {
                this.filters.tiposatividadesfilter = filter.filterLookup.tiposatividadesfilter.tipoatividade;
            }
        } else if (filter == null) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, '');
        }

        if (filter.lookup) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, search);
        } else {
            this.filter = search;
            this.filters = filters;
            return this.reload();
        }
    }

    load() {
        if (!this.busy && !this.finished && this.to_load > 0) {
            this.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                    }
                    this.finished = true;
                    this.$rootScope.$broadcast('apontamentos_apontamentos_list_finished', this.entities);
                    this.loaded = true;

                    this.to_load--;

                })
                .catch((error: any) => {
                    this.finished = true;
                })
                .finally(() => {
                    this.busy = false;
                });
        }

        return this.entities;
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('apontamentos_rubricasponto_indexrubricasapontamento', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    var aux: any = [];
                    for (var i = 0; i < response.data.length; i++) {

                        if (constructors.concatCodigoNome) {
                            response.data[i].codigo_nome = response.data[i].codigo + ' - ' + response.data[i].nome;
                        }

                        aux.push(response.data[i]);
                    }
                    resolve(aux);
                })
                .catch((response) => {
                    reject(response);
                });

        });

    }
}

