import angular from 'angular';

export class TagInputController {

    static $inject = [
        '$scope',
        '$element',
        'NewToaster'
    ];

    public tagContainer: any = angular.element(this.$element).find('.tag-container')[0];
    public input: any = angular.element(this.$element).find('.tag-container input')[0];

    public list: Array<any> = [];
    public tags: Array<any> = [];
    public disabled: boolean = false;
    public allowUnlistedItems: boolean = true;
    public placeholder: string;
    public field: string;
    public gestorinput: boolean = false;

    public inputList: any;
    public inputSelected: any;

    private toastTimeout: number = 8000;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        private NewToaster: any,
    ) {
        $scope.$watch('$ctrl.disabled', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue) {
                if (this.disabled === false) {
                    this.tagContainer.removeAttribute('disabled');
                    this.input.removeAttribute('disabled');
                }
                this.adicionaTags();
            }
        }, true);
        $scope.$watch('$ctrl.tags', (newValue: any, oldValue: any): void => {
            this.adicionaTags();
        }, true);
        $scope.$watch('$ctrl.list', (newValue: any, oldValue: any): void => {
            this.atualizaListagem();
        }, true);
    }

    $onInit(): void {
        this.inputList = this.list;

        // coloca um dado que não está presente na lista de sugestão
        this.input.addEventListener('keyup', (e: any) => {
            if (e.key === 'Enter' && e.target.value && this.allowUnlistedItems) {
                if (!this.gestorinput || this.isValidEmail(e.target.value)) {
                    e.target.value.split(',').forEach((data: string) => {
                        let obj = {
                            [this.field]: data
                        };
                        this.tags.push(obj);
                    });

                    this.input.value = '';
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'E-mail do gestor externo inválido',
                        body: 'Utilize um email válido',
                        timeout: this.toastTimeout
                    });
                }
                this.$scope.$apply();
            }
        });

        // detecção do evento de remoção de uma tag
        this.tagContainer.addEventListener('click', (e: any) => {
            const target = e.target as HTMLTextAreaElement;
            if (target.tagName === 'I' && (target.parentElement && target.parentElement.className === 'tag')) {
                const tagLabel = target.getAttribute('data-item');
                const index = this.tags.findIndex((obj: any): boolean => {
                    return obj[this.field] === tagLabel;
                });
                this.tags = [...this.tags.slice(0, index), ...this.tags.slice(index + 1)];
            }
        });
    }

    criaTag(label: any): HTMLDivElement {
        const div = document.createElement('div');
        div.setAttribute('class', 'tag');

        const span = document.createElement('span');
        span.innerHTML = label;

        div.appendChild(span);

        if (!this.disabled) {
            const closeIcon = document.createElement('i');
            closeIcon.setAttribute('class', 'fa fa-times');
            closeIcon.setAttribute('data-item', label);
            div.appendChild(closeIcon);
        }

        return div;
    }

    limpaTags(): Promise<void> {
        return new Promise((resolve, reject) => {
            angular.element(this.$element).find('.tag').remove('.tag');
            this.inputList = this.list;
            this.atualizaListagem();
            resolve();
        });
    }

    adicionaTags(): void {
        this.limpaTags().then(() => {
            this.tags.slice().reverse().forEach((data: any): void => {
                this.tagContainer.prepend(this.criaTag(data[this.field]));
            });
        });
        this.atualizaListagem();
    }

    selecionaDaLista(data: any): void {
        this.tags.push(data);
        this.inputSelected = null;
    }

    atualizaListagem(): void {
        if (this.list) {
            this.inputList = this.list.filter((listObj: any): boolean => {
                return !this.tags.some((tagsObj: any) => {
                    return listObj[this.field] === tagsObj[this.field];
                });
            });
            this.$scope.$applyAsync();
        }
    }

    private isValidEmail(email: string): boolean {
        const emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }
}
