import * as angular from 'angular';
import { MeurhCreditosdescontosPassoquatroController } from './creditosdescontos.passoquatro.controller';


export class MeurhCreditosdescontosPassoquatroComponent implements angular.IComponentOptions {
  static selector = 'meurhCreditosdescontosPassoquatroComponent';
  static controller = MeurhCreditosdescontosPassoquatroController;
  static template = require('!!html-loader!./creditosdescontos.passoquatro.html');
  static transclude = true;
  static bindings = {
    passo: '=',
    entity: '=',
    selecionadosCreditosDescontos: '=',
    entitiesCreditosDescontos: '=',
    passoUmAtivo: '<',
  };
}
