import * as angular from 'angular';
import { MeurhCreditosdescontosFormController } from './creditosdescontos.form.controller';


export class MeurhCreditosdescontosFormComponent implements angular.IComponentOptions {
  static selector = 'meurhCreditosdescontosFormComponent';
  static controller = MeurhCreditosdescontosFormController;
  static template = require('!!html-loader!./creditosdescontos.form.html');
  static transclude = true;
  static bindings = {
    isEdit: '<',
    entity: '=',
    titles: '=',
  };
}
