import angular = require('angular');
import { MeurhSolicitacoes } from '../services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';

export class MeurhSolicitacoesShowController implements ng.IController {
	static $inject = ['$scope', '$rootScope', '$stateParams', '$state', 'MeurhSolicitacoes', 'entity', 'NewToaster', '$injector', 'ClientSession', 'ModalConfirmService', 'WorkflowService'];

	public busy: boolean;
	public reload: any;
	public service: MeurhSolicitacoes;
	public acao: string = 'fechar';
	public action: string = 'retrieve';
	public parametrosSession: any;
	public SITUACOESCLASSES: any = {
		'0': 'label-warning',
		'1': 'label-success',
		'2': 'label-danger',
		'4': 'label-danger'
	};
	public SITUACOES: any = {
		'0': 'Aberta',
		'1': 'Efetuada',
		'2': 'Cancelada',
		'4': 'Recusada'
	};
	public TIPOS_SOLICITACOES: any = [
		'Admissão Preliminar',
		'Rescisão',
		'Adiantamento Avulso',
		'VT Adicional',
		'Alteração de VT',
		'Alteração de endereço',
		'Falta',
		'Férias',
		// 'Salário sob demanda',
		'Afastamento',
		'Promoção',
		'Créditos e Descontos',
		'Admissão completa',
		'Transferência de colaborador'
	];
	public TIPOS_SOLICITACOES_ROUTE: any = [
		'meurh_solicitacoesadmissoes_',
		'meurh_solicitacoesrescisoes_',
		'meurh_solicitacoesadiantamentosavulsos_',
		'meurh_solicitacoesvtsadicionais_',
		'meurh_solicitacoesalteracoesvts_',
		'meurh_solicitacoesalteracoesenderecos_',
		'meurh_solicitacoesfaltas_',
		'meurh_solicitacoesferias_',
		// 'Salário sob demanda',
		'meurh_solicitacoesafastamentos_',
		'meurh_solicitacoespromocoes_',
		'meurh_solicitacoescreditodesconto_',
		'meurh_solicitacoesadmissoescompleta_',
		'meurh_solicitacoestransferenciasvagas_'
	];

	constructor(
		public $scope: angular.IScope,
		public $rootScope: angular.IRootScopeService,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public MeurhSolicitacoes: MeurhSolicitacoes,
		public entity: any,
		public NewToaster: any,
		public $injector: any,
		public ClientSession: any,
		public ModalConfirmService: any,
		public WorkflowService: WorkflowService,
	) {
		this.ClientSession.load();

		if (typeof this.entity.trabalhador !== 'object') {
			this.entity.trabalhador = this.entity.trabalhadorobj;
		}

		this.service = this.$injector.get('MeurhSolicitacoes');
		this.service.constructors = {};

		this.carregaClientSession();

		$scope.$on('meurh_solicitacoes_deleted', (): void => {
			this.handleDeleteSuccess();
		});

		$scope.$on('meurh_solicitacoes_delete_error', (): void => {
			this.handleDeleteError();
		});

		this.handleRejeitarSuccess = this.handleRejeitarSuccess.bind(this);
		this.handleRejeitarError = this.handleRejeitarError.bind(this);
	}

	$onInit(): void {
		this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any) => {
            this.$state.reload();
        });

        this.WorkflowService.config(this.entity.solicitacao, this.TIPOS_SOLICITACOES_ROUTE[this.entity.tiposolicitacao]);
	}

	carregaClientSession(): void {
		this.$rootScope.$on('meurh_clientsession_session_loaded_service', (event: angular.IAngularEvent, args: any): void => {
			if (!angular.isUndefined(args.empresa)) {
				this.parametrosSession = args;
				this.service.constructors.estabelecimento = this.parametrosSession.estabelecimento.estabelecimento;
			}
		});
	}

	getSituacao(situacao: any): string {
		return this.SITUACOES[situacao];
	}

	getSituacaoClasses(situacao: any): string {
		return this.SITUACOESCLASSES[situacao];
	}

	getTipoSolicitacao(tiposolicitacao: any): string {
		return this.TIPOS_SOLICITACOES[tiposolicitacao].toLocaleLowerCase();
	}

	rejeitar(): void {
		let confirmTextMessage: string;
		if (this.entity.trabalhador) {
			confirmTextMessage = 'Tem certeza que deseja fechar a solicitação de ' + this.entity.tiposolicitacaoNome.toLowerCase() + ' do colaborador ' + this.entity.trabalhador.nome;
		} else {
			confirmTextMessage = 'Tem certeza que deseja fechar a solicitação de ' + this.entity.tiposolicitacaoNome.toLowerCase();
		}
		let customText = {
			title: 'Fechar ' + this.entity.tiposolicitacaoNome.toLowerCase(),
			confirmText: confirmTextMessage,
			closeButton: 'Cancelar',
			confirmButton: 'Sim, fechar solicitação',
		};
		let confirm = this.ModalConfirmService.open(this.entity, this.entity.tiposolicitacaoNome.toLowerCase(), false, customText);
        confirm.result
			.then(() => {
				this.busy = true;
				this.service.fechar(this.entity)
					.then(this.handleRejeitarSuccess)
					.catch(this.handleRejeitarError);
			})
			.catch(function () {/**/});
	}

	excluir(): void {
		let confirmTextMessage: string;
		if (this.entity.trabalhador) {
			confirmTextMessage = 'Tem certeza que deseja excluir a solicitação de ' + this.entity.tiposolicitacaoNome.toLowerCase() + ' do colaborador ' + this.entity.trabalhador.nome;
		} else {
			confirmTextMessage = 'Tem certeza que deseja excluir a solicitação de ' + this.entity.tiposolicitacaoNome.toLowerCase();
		}
		let customText = {
			title: 'Excluir ' + this.entity.tiposolicitacaoNome.toLowerCase(),
			confirmText: confirmTextMessage,
			closeButton: 'Cancelar',
			confirmButton: 'Sim, excluir solicitação',
		};
		let confirm = this.ModalConfirmService.open(this.entity, this.entity.tiposolicitacaoNome.toLowerCase(), true, customText);
        confirm.result
            .then(() => {
				this.busy = true;
				this.service.delete(this.entity, true);
			})
			.catch(function () {/**/});
	}

	handleRejeitarError(error: any): void {
		this.busy = false;

		if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
			let acao = 'fechar';

			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao ' + acao + ' a solicitação'
			});
		}
	}

	handleRejeitarSuccess(): void {
		let m = 'fechada';

		this.NewToaster.pop({
			type: 'success',
			title: 'Solicitação ' + m + ' com sucesso'
		});

		this.busy = false;
		this.$state.go('meurh_solicitacoes', this.$stateParams);
	}

	handleDeleteError(): void {
		this.NewToaster.pop({
			type: 'error',
			title: 'Ocorreu um erro ao excluir solicitação'
		});

		this.busy = false;
	}

	handleDeleteSuccess(): void {
		this.NewToaster.pop({
			type: 'success',
			title: 'Solicitação excluída com sucesso'
		});

		this.busy = false;
		this.$state.go('meurh_solicitacoes', this.$stateParams);
	}
}
