import angular from 'angular';
import { MeurhInclusaodependenteBeneficiosFormmodalController } from './beneficios.formmodal.controller';

export class MeurhInclusaodependenteBeneficiosFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhInclusaodependenteBeneficiosFormmodalComponent';
	static controller = MeurhInclusaodependenteBeneficiosFormmodalController;
	static template = require('!!html-loader!./beneficios.formmodal.html');
	static transclude = true;
	static bindings = {
		beneficio: '=',
		form: '<',
		busy: '=',
		action: '<',
		objinclusao: '=',
	};
}
