import angular = require('angular');
import moment = require('moment');
export class MeurhMarcacoesAnterioresModalController implements ng.IController {
  static $inject = ['$uibModalInstance', 'marcacoesAnteriores', ];
  public showMarcacoes: boolean = false;
  public marcacoesAnterioresFormatadas: any = [];
  public fields = [
		{
			value: 'periodoaquisitivo',
			label: 'Período aquisitivo',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'created_at',
			label: 'Criada em',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'iniciogozo',
			label: 'Início de gozo',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'diasvendidos',
			label: 'Comprar dias?',
			type: 'integer',
			style: 'title',
			copy: '',
		},
		{
			value: 'adto13nasferias',
			label: 'Adiantar 13º?',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'situacao',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
      customCollumnHtml: () => {
        let someHtmlVar = `
        <div ng-if="!tableRowEntity.situacao && tableRowEntity.situacao !== 0 && tableRowEntity.situacao !== -1">
        <span>-</span>
        </div>
        <div ng-if="tableRowEntity.situacao === -1" class="label label-default">
          <span>Rascunho</span>
        </div>
        <div ng-if="tableRowEntity.situacao === 0" class="label label-warning">
          <span>Enviada</span>
        </div>
        <div ng-if="tableRowEntity.situacao === 1" class="label label-success">
          <span>Efetuada</span>
        </div>
        <div ng-if="tableRowEntity.situacao === 2" class="label label-danger">
          <span>Fechada</span>
        </div>
        `;
        return someHtmlVar;
      },
		}
	];

  constructor(
    public $uibModalInstance: any,
    public marcacoesAnteriores: any,
  ) {
    this.marcacoesAnteriores.forEach((marcacao: any) => {

      this.marcacoesAnterioresFormatadas.push({
        'periodoaquisitivo': `${moment(marcacao.datainicioperiodoaquisitivo).format('DD/MM/YYYY')} a ${moment(marcacao.datafimperiodoaquisitivo).format('DD/MM/YYYY')}`,
        'created_at': moment(marcacao.created_at).format('DD/MM/YYYY'),
        'iniciogozo': moment(marcacao.datainiciogozo).format('DD/MM/YYYY'),
        'diasvendidos': marcacao.diasvendidos ? marcacao.diasvendidos : 'Não',
        'adto13nasferias': marcacao.adto13nasferias ? 'Sim' : 'Não',
        'situacao': marcacao.situacao
      });

    });
  }

  toggleShowMarcacoes() {
    this.showMarcacoes = !this.showMarcacoes;
  }

  enviarSolicitacoes() {
    let enviarSolicitacoes = true;

    this.$uibModalInstance.close(enviarSolicitacoes);
  }

  anular() {
    let enviarSolicitacoes = false;

    this.$uibModalInstance.close(enviarSolicitacoes);
  }
}
