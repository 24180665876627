import angular from 'angular';
import { MeurhVagasService } from '../vagas.service';
import { IVagas } from '../models/vagas.model';

export class MeurhVagasEditController implements angular.IController {
    static $inject = ['$scope', '$stateParams', '$state', 'MeurhVagasService', 'entity', 'NewToaster', '$rootScope'];

    public action: string = 'edit';
    public form: angular.IFormController;
    public constructors: any = [];
    public busy: boolean = false;
    public entitySave: Partial<IVagas> = {};

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhVagasService,
        public entity: IVagas,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService,
    ) {
        $scope.$watch('mrh_vgs_frm_edit_cntrllr.entity', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);

        this.onSubmitSuccess();
        this.onSubmitError();
    }

    verificaObj(): boolean {
        if (this.valorExiste(this.entity.codigo) &&
            this.valorExiste(this.entity.descricao)
        ) {
            return false;
        } else {
            return true;
        }
    }

    submit(): void {
        if (this.form.$valid && !this.entity.$$__submitting) {
            this.busy = true;
            if (this.entity.gestores) {
                this.alteraGestores();
            }
            this.entitySave = angular.copy(this.entity);
            this.entityService.save(this.entitySave);
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros.'
            });
        }
    }

    alteraGestores(): void {
        this.entity.gestores = this.entity.gestores.map((gestor: any) => {
            if (gestor.trabalhador) {
                return {
                    gestor: gestor.trabalhador,
                    identificacaonasajongestor: null
                };
            } else {
                return {
                    gestor: null,
                    identificacaonasajongestor: gestor.nome
                };
            }
        });
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_vagas_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Vaga foi editada com sucesso!'
            });
            this.$state.go('meurh_vagas_show', { 'vaga': args.entity.vaga });
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_vagas_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                if (args.response.data.message === 'Validation Failed') {
                    let message = args.response.data.errors.errors[0];

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de Validação',
                        body: message,
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao editar a Vaga!'
                });
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
