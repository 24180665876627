import ng from 'angular';
import angular from 'angular';
import moment from 'moment';

export class MeurhAdmissoesestagiarioDependentesFormmodalController implements ng.IController {

    static $inject = [
        '$scope',
        '$element',
        '$attrs',
        'NewToaster',
        '$rootScope',
        '$sce',
        'MeurhAdmissaoestagiarioModalService'
    ];

    public dependente: any;
    public action: string;
    public uFArray = [
        'AC', 'AL', 'AM', 'AP', 'BA',
        'CE', 'DF', 'ES', 'GO', 'MA',
        'MG', 'MS', 'MT', 'PA', 'PB',
        'PE', 'PI', 'PR', 'RJ', 'RN',
        'RO', 'RR', 'RS', 'SC', 'SE',
        'SP', 'TO'
    ];
    public form: angular.IFormController;
    public constructor_: any = {};
    public tiporecebimentoHabilitado: boolean = false;
    public objadmissao: any;

    // inicio - tratamento de datas
    public anoMinimo: moment.Moment;
    // fim - tratamento de datas

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
    ) {

    }

    $onInit(): void {
        this.bloqueiaAnoMinimo();
        this.constructor_ = this.verificacaoBanco();

        if (this.dependente.matricula === undefined) {
            this.dependente.matricula = ' ';
        }
        if (this.valorExiste(this.dependente.nome)) {
            this.dependente.parentesco = this.dependente.parentesco ? this.dependente.parentesco.toString() : this.dependente.parentesco;
            this.dependente.sexo = this.dependente.sexo ? this.dependente.sexo.toString() : this.dependente.sexo;
            this.dependente.tiporecebimento = this.dependente.tiporecebimento ? this.dependente.tiporecebimento.toString() : this.dependente.tiporecebimento;
            this.calculaIdade();
        } else if (this.objadmissao) {
            this.dependente.datainclusao = this.objadmissao.dataadmissao;
        }
    }

    calculaIdade(): void {
        if (this.valorExiste(this.dependente.datanascimento)) {
            this.dependente['idade'] = moment().diff(this.dependente.datanascimento, 'years');
        }
    }

    preencheCampo(): void {
        if (this.dependente.tiporecebimento === '128') { // cpf
            this.dependente.chavepix = this.dependente.cpf;
        } else {
            this.dependente.chavepix = '';
        }
    }

    preenchePix(): void {
        if (this.dependente.tiporecebimento === '128') { // cpf
            this.dependente.chavepix = this.dependente.cpf;
        }
    }

    verificaBeneficiariopensaoalimenticia(): void {
        if (this.dependente.pensaoalimenticia === true) {
            this.tiporecebimentoHabilitado = true;
        } else {
            this.dependente.tiporecebimento = '';
            this.tiporecebimentoHabilitado = false;

        }
    }

    verificacaoBanco(): { banco: string | null } {
        if (this.dependente.banco) {
            if (this.dependente.banco.banco) {
                return { 'banco': this.dependente.banco.banco };
            }
        }
        return { 'banco': null };
    }

    public vencimentoEscolarObrigatorio(): boolean {
        return (
            (this.dependente.idade !== undefined && this.dependente.idade !== null) &&
            (this.dependente.idade >= 7 && this.dependente.idade <= 14) &&
            this.dependente.salariofamilia &&
            !this.dependente.incapacidadefisicamental
        );
    }

    public vencimentoVacinacaoObrigatorio(): boolean {
        return (
            (this.dependente.idade !== undefined && this.dependente.idade !== null) &&
            (this.dependente.idade < 7 || this.dependente.idade > 14) &&
            this.dependente.salariofamilia &&
            !this.dependente.incapacidadefisicamental
        );
    }

    alteraAgenciaPorBanco(): void {
        if (this.dependente.banco) {
            this.$rootScope.$broadcast('agencia_refresh', { banco: this.dependente.banco.banco });
            this.dependente.agencia = null;
        }
    }

    changeBanco(): void {
        this.alteraAgenciaPorBanco();
        this.constructor_ = this.verificacaoBanco();
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    public limpaIncapacidadeFisicaMental(): void {
        if (this.dependente.parentesco?.toString() !== '3') {
            this.dependente.incapacidadefisicamental = false;
        }
    }

    public limpaCamposSalFamiliaComIncapacidadeFisicaMental(): void {
        if (!this.dependente.incapacidadefisicamental) {
            this.dependente.atestadoparanaofrequentarescola = false;
        }
    }
    // inicio - tratamento de datas
    private bloqueiaAnoMinimo(): void {
        this.anoMinimo = moment().subtract(120, 'years'); // > 120 anos
    }
    // fim - tratamento de datas
}
