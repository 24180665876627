import * as angular from 'angular';
import { MeurhSolicitacoesfaltasFormController } from './solicitacoesfaltas.form.controller';

export class MeurhSolicitacoesfaltasFormComponent implements angular.IComponentOptions {
  static selector = 'meurhSolicitacoesfaltasFormComponent';
  static controller = MeurhSolicitacoesfaltasFormController;
  static template = require('!!html-loader!./solicitacoesfaltas.form.html');
  static transclude = true;
  static bindings = {
    entity: '<',
    form: '<',
    busy: '=',
    action: '<'
  };
}
