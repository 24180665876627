import angular from 'angular';
import { MeurhSolicitacoesalteracoesdadossalariaisDocumentosanexosFormController } from './documentosanexos.form.controller';

export class MeurhSolicitacoesalteracoesdadossalariaisDocumentosanexosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesalteracoesdadossalariaisDocumentosanexosFormComponent';
	static controller = MeurhSolicitacoesalteracoesdadossalariaisDocumentosanexosFormController;
	static template = require('!!html-loader!./documentosanexos.form.html');
	static transclude = true;
	static bindings = {
		busy: '=',
		busyTipoDocumentos: '=',
		entityanexo: '=',
		file: '=?',
		action: '=?',
		mostrarAlerta: '=',
		tiposdocumentosrequeridos: '=',
		editanexo: '=',
		alterouanexo: '=',
		fileSelecionado: '=?',
		validateResult: '=?',
	};
}
