import angular from 'angular';
import { IAlteracaoFoto } from '../models/alteracaofoto.model';
import { MeurhAlteracaoFotoService } from '../alteracaofoto.service';
import { MeurhVisualizarFotoModalService } from './modal/visualizarfoto/visualizarfoto.modal.service';

export class MeurhAlteracaoFotoFormController {

    static $inject = [
        '$scope',
        'MeurhAlteracaoFotoService',
        'MeurhVisualizarFotoModalService'
    ];

    public entity: IAlteracaoFoto;
    public action: string;
    public form: angular.IFormController;
    public busy: boolean;
    public busySolicitacaoAberta = false;

    public fileList: FileList;
    public url = '';
    public fileValidationObj = {
        isValid: false,
        isDirt: false,
        message: 'Selecione um arquivo válido.'
    };

    constructor (
		public $scope: angular.IScope,
        public MeurhAlteracaoFotoService: MeurhAlteracaoFotoService,
        public VisualizarFotoModalService: MeurhVisualizarFotoModalService,
	) {
        $scope.$on('meurh_solicitacoesalteracoesfoto_list_finished', (event: angular.IAngularEvent, args: IAlteracaoFoto | []): void => {
            if (this.busySolicitacaoAberta) {
                this.busySolicitacaoAberta = false;
                const existeSolicitacaoAberta = args && args.length ? true : false;
                this.form['trabalhador'].$setValidity('existeSolicitacaoAberta', !existeSolicitacaoAberta);
                this.setFileDirt(false);
                this.busy = false;
            }
        });
        $scope.$watchCollection('$ctrl.fileList', (newValue: FileList, oldValue: FileList) => {
			if (newValue !== oldValue) {
				this.entity.conteudo = newValue.length ? newValue[0] : undefined;
			}
		});
        $scope.$watch('$ctrl.form["fileInput"]', (newValue: angular.IFormController, oldValue: angular.IFormController) => {
            if (newValue !== oldValue && newValue) {
                this.form['fileInput'].$setValidity('arquivoValido', this.fileValidationObj.isValid);
                if (this.fileValidationObj.isDirt) {
                    this.setFileDirt(true);
                }
			}
		});
    }

    openPhoto(photo: string) {
        this.VisualizarFotoModalService.open(photo);
    }

    validation() {
        if (this.entity.trabalhadorobj) {
            this.busy = true;
            this.MeurhAlteracaoFotoService.constructors = {
                trabalhador: this.entity.trabalhadorobj.trabalhador,
                situacao: '0'
            };
            this.busySolicitacaoAberta = true;
            this.MeurhAlteracaoFotoService.reload();
        } else {
            this.form['trabalhador'].$setValidity('existeSolicitacaoAberta', true);
        }
    }

    removeFile(msg: string = 'Selecione um arquivo válido.') {
        this.fileList = {} as FileList;
        this.fileValidationObj.isValid = false;
        this.fileValidationObj.message = msg;
        this.resetInputFileValue();
    }

    fileValidation() {
        this.setFileDirt(true);
        if (!this.isFileTypeValid()) {
            this.removeFile('O arquivo deve ser no formato jpg ou jpeg.');
            return;
        }
        this.fileSizesValidation();
    }

    private isFileTypeValid() {
        let allowedExtensions = /(\.(jpg|jpeg))$/i;
        if (!allowedExtensions.exec(this.fileList[0].name)) {
            return false;
        }
        return true;
    }

    private fileSizesValidation() {
        const img = new Image();
        const objectUrl = URL.createObjectURL(this.fileList[0]);
        img.src = objectUrl;
        img.onload = () => {
            const imgSize = this.fileList[0].size / 1024 / 1024;
            const imgWidth = img.naturalWidth;
            const imgHeight = img.naturalHeight;
            if ((imgWidth < 240) || (imgHeight < 320)) {
                this.removeFile('O arquivo deve ter as dimensões mínimas de 240x320px.');
            } else if (imgSize > 3) {
                this.removeFile('O arquivo deve ter o tamanho máximo de 3 MB.');
            } else {
                this.url = objectUrl;
                this.fileValidationObj.isValid = true;
            }
            this.$scope.$applyAsync();
        };
    }

    private setFileDirt(isDirt: boolean) {
        isDirt ? this.form['fileInput']?.$setDirty() : this.form['fileInput']?.$setPristine();
        this.fileValidationObj.isDirt = isDirt;
    }

    private resetInputFileValue() {
        // reinicia o 'value' do input file para ser possivel inserir um arquivo com mesmo nome
        const fileInput = document.getElementById('fileInput') as HTMLInputElement | null;
        if (fileInput !== null) {
            fileInput.value = '';
        }
    }
}
