import angular from 'angular';
import { MeurhEscalaLotacaoHorarioFormController } from './lotacaohorario.form.controller';

export class MeurhEscalaLotacaoHorarioFormComponent implements angular.IComponentOptions {
	static selector = 'meurhEscalaLotacaoHorarioFormComponent';
	static controller = MeurhEscalaLotacaoHorarioFormController;
	static template = require('!!html-loader!./lotacaohorario.form.html');
	static transclude = true;
	static bindings = {
        form: '<',
		entity: '=',
	};
}
