import * as angular from 'angular';
import { MeurhSolicitacoesvtsadicionaisShowFormController } from './solicitacoesvtsadicionais.showform.controller';

export class MeurhSolicitacoesvtsadicionaisShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesvtsadicionaisShowFormComponent';
	static controller = MeurhSolicitacoesvtsadicionaisShowFormController;
	static template = require('!!html-loader!./solicitacoesvtsadicionais.showform.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
