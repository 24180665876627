import { MeurhAdmissaocompletaModalService } from '../../modal/admissaocompleta.modal.service';

export class MeurhAdmissoesValetransportepersonalizadoFormController implements ng.IController {

    static $inject = ['MeurhAdmissaocompletaModalService'];

    public entity: any;
    public action: string;
    public fields: any = [
        {
            value: 'datainicio',
            label: 'Início',
            type: 'date',
            style: 'title',
            copy: '',
        },
        {
            value: 'datafim',
            label: 'Fim',
            type: 'date',
            style: 'title',
            copy: '',
        },
        {
            value: 'tipo',
            label: 'Tipo',
            type: 'string',
            style: 'default',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span>{{(tableRowEntity.tipo==1) ? 'Dias Úteis' : 'Valor Recebido Tarifas'}}</span>
              `;
                return someHtmlVar;
            },
        },
        {
            value: 'conteudo',
            label: 'Conteúdo',
            type: 'moeda',
            style: 'default',
            copy: '',
        },
        {
            value: 'motivo',
            label: 'Motivo',
            type: 'string',
            style: 'default',
            copy: '',
        },
    ];

    constructor(
        public MeurhAdmissaocompletaModalService: MeurhAdmissaocompletaModalService
    ) {
    }

    $onInit(): void {
       if (this.entity.vtspersonalizados === undefined || this.entity.vtspersonalizados === null) {
           this.entity.vtspersonalizados = [];
       }
    }

    abrirModal(entity: any = {}, action: string = ''): void {
        let parameters = {
            'componente' : 'VT Personalizado',
            'action' : action ? action : this.action ,
        };

        let modal = this.MeurhAdmissaocompletaModalService.open(entity, parameters);

        modal.result
            .then((response: any): void => {
                this.entity.vtspersonalizados.push(response);
            })
            .catch((error: any): void => {
                console.log(error);
            });
    }
}
