import angular from 'angular';

import { ConfiguracoesListRouting  } from './configuracoes.routes';

import { ConfiguracoesService } from './configuracoes.service';
import { ConfiguracoesPortariaModalService } from './modal.portaria/configuracoes.portariamodal.service';

import { ConfiguracoesIndexController } from './index/configuracoes.index.controller';
import { ConfiguracoesPortariaModalController } from './modal.portaria/configuracoes.portariamodal.controller';

export const ConfiguracoesModule = angular.module('ConfiguracoesModule', ['ui.router.state', ])
    .controller('ConfiguracoesIndexController', ConfiguracoesIndexController)
    .controller('ConfiguracoesPortariaModalController', ConfiguracoesPortariaModalController)
    .service('ConfiguracoesService', ConfiguracoesService)
    .service('ConfiguracoesPortariaModalService', ConfiguracoesPortariaModalService)
    .config(ConfiguracoesListRouting)
    .name;
