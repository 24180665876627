export class ModalDescartarController {
    static $inject = ['entity', '$uibModalInstance', 'tipo', 'gerarTextos'];

    public textos: any = this.gerarTextos;

    constructor(
        public entity: any,
        public $uibModalInstance: any,
        public tipo: any,
        public gerarTextos: any,
    ) { }

    confirmar() {
        this.$uibModalInstance.close(this.entity);
    }
    close() {
        this.$uibModalInstance.dismiss();
    }
}
