
import angular from 'angular';

import { TagInputController } from './tag.input.controller';
import { TagInputComponent } from './tag.input.component';

export const MeurhTagInputModule = angular.module('MeurhTagInputModule', ['ui.router.state'])
    .controller('TagInputController', TagInputController)
    .component('tagInputComponent', TagInputComponent)
    .name;
