import ng from 'angular';
import { IAnexo } from '../../../Anexos/models/anexo.model';

export class MeurhInclusaodependenteDocumentosanexosFormController implements ng.IController {

    public entityanexo: IAnexo;
    public formAnexo: angular.IFormController;
    public busy: boolean;
    public tiposdocumentosrequeridos: Array<any>;
    public alterouanexo: boolean;
    public validate: number = 0; // conta quantos erros há em documentos anexos
}
