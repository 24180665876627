import { FormController } from './solicitacoesadiantamentosavulsos.form.controller';

export class MeurhSolicitacoesadiantamentosavulsosDefault implements angular.IComponentOptions {
  static template = require('html-loader!./solicitacoesadiantamentosavulsos.form.html');
  static controller = FormController;
  static bindings = {
    entity: '<',
    form: '<',
    busy: '=',
    action: '<'
  };
}
