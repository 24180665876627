import angular from 'angular';
import { IAlteracaoFoto } from '../models/alteracaofoto.model';
import { MeurhAlteracaoFotoService } from '../alteracaofoto.service';
import { ModalConfirmService } from '../../../../shared/components/modal-confirm/modal.confirm.service';

export class MeurhAlteracaoFotoNewController {

    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        'NewToaster',
        'MeurhAlteracaoFotoService',
        'ModalConfirmService'
    ];

    public entity: IAlteracaoFoto = {} as IAlteracaoFoto;
    public form: angular.IFormController;
    public action = 'insert';
    public busy = false;

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            session: any,
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean
        },
		public $state: angular.ui.IStateService,
        public NewToaster: { pop: Function },
        public MeurhAlteracaoFotoService: MeurhAlteracaoFotoService,
        public ModalConfirmService: ModalConfirmService,
	) {
        if (
            !this.$rootScope.temPermissao('criar_alteracaofoto') ||
            !this.$rootScope.liberadoTela('LIBERAR_MEURH_SOLICITACAO_ALT_FOTO')
        ) {
            $state.go('dp');
        }

        this.onSubmitSuccess();
        this.onSubmitError();
    }

    salvar() {
        this.form.$submitted = true;
        if (this.form.$invalid) {
            return;
        }
        let confirm = this.modal();
        confirm.result.then(() => {
            this.busy = true;
            this.fileToBlobObject(this.entity.conteudo as File).then((conteudo) => {
                this.MeurhAlteracaoFotoService.save({
                    conteudo
                }, {
                    empresa: this.$rootScope.session.entities.empresa.empresa,
                    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
                    trabalhador: this.entity.trabalhadorobj.trabalhador
                });
            });
        }).catch(() => {/**/});
    }

    private modal() {
        return this.ModalConfirmService.open(this.entity, 'Nova alteração de foto', false, {
            title: 'Enviar alteração',
            confirmText: `Você tem certeza que deseja enviar a alteração
                da foto do colaborador ${this.entity.trabalhadorobj.nome}?`,
            closeButton: 'Fechar',
            closeButtonIcon: 'invisible',
            confirmButton: 'Enviar alteração',
            confirmButtonIcon: 'invisible',
        });
    }

    private fileToBlobObject(file: File) {
        return new Promise<Blob>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(new Blob([new Uint8Array(reader.result as ArrayBuffer)], { type: file.type }));
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    }

    private onSubmitSuccess() {
		this.$scope.$on('meurh_solicitacoesalteracoesfoto_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de alteração de foto foi enviada com sucesso!'
            });
            this.$state.go('meurh_alteracaofoto_show', { 'solicitacao': args.entity.solicitacao });
		});
	}

	private onSubmitError() {
		this.$scope.$on('meurh_solicitacoesalteracoesfoto_submit_error', (event: angular.IAngularEvent, args: any): void => {
			this.busy = false;
            if (
                args.response.data &&
                typeof (args.response.data.message) !== 'undefined' &&
                args.response.data.message
            ) {
                if (args.response.data.message === 'Validation Failed') {
                    let message = '';
                    let children = args.response.data.errors.children;
                    for (let child in children) {
                        if (children[child].errors !== undefined) {
                            for (let error in children[child].errors) {
                                if (children[child].errors.hasOwnProperty(error)) {
                                    message += '<li>' + children[child].errors[error] + '</li>';
                                }
                            }
                        }
                    }
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de validação',
                        body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a Solicitação de alteração de foto!'
                });
            }
		});
	}
}
