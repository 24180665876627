import angular from 'angular';
declare var nsj;

export class ApontamentosTrabalhadoresModalService {
    static $inject = ['ApontamentosTrabalhadoresService', 'NewToaster', '$state', '$stateParams', '$window', '$uibModal', '$rootScope', 'nsjRouting', '$http'];

    constructor(
        public entityService: any,
        public toasterentityService: any,
        public $state: any,
        public $stateParams: any,
        public $window: any,
        public $uibModal: any,
        public $rootScope: any,
        public nsjRouting: any,
        public $http: any
    ) { }

    open(parameters: any, subentity: any, mesreferencia: any, anoreferencia: any, apontamentostrabalhadores: any, tipo: any) {
        return this.$uibModal.open({
            template: require('html-loader!./modal.html'),
            controller: 'ApontamentosTrabalhadoresModalController',
            controllerAs: '$ctrl',
            resolve: {
                entity: () => {
                    return angular.copy(subentity);
                },
                periodoapuracao: () => {
                    let constructor = {
                        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
                        mesreferencia: mesreferencia,
                        anoreferencia: anoreferencia,
                        grupoempresarial: nsj.globals.getInstance().get('grupoempresarial'),
                        tenant: nsj.globals.getInstance().get('tenant')
                    };
                    let url = this.nsjRouting.generate('faltastrabalhadores_periodoapuracao', constructor, true);
                    let periodoapuracao: any = {};
                    this.$http.get(url).then((response: any) => {
                        periodoapuracao.datainicial = response.data.datainicial;
                        periodoapuracao.datafinal = response.data.datafinal;
                        this.$rootScope.$broadcast('periodoapuracao_loaded', response.data);
                    }).catch((error: any) => {
                        periodoapuracao.datainicial = undefined;
                        periodoapuracao.datafinal = undefined;
                    });
                    return periodoapuracao;
                },
                trabalhadoresguids: () => {
                    let guids: any = [];
                    if (apontamentostrabalhadores) {
                        for (let i = 0; i < apontamentostrabalhadores.length; i++) {
                            guids.push(apontamentostrabalhadores[i].trabalhador.trabalhador);
                        }
                    }
                    if (subentity.trabalhador) {
                        if (guids.indexOf(subentity.trabalhador.trabalhador) > -1) {
                            guids.splice(guids.indexOf(subentity.trabalhador.trabalhador), 1);
                        }
                    }
                    return guids;
                },
                tipo: (): any => tipo
            },
            windowClass: 'nsj-modal-apontamentos-trabalhadores',
        });
    }
}
