export class CalendarOption {
    public appColor: string = '#30C01E';
    public colors: Array<string> = [
      '#FF0000',
      '#00ED71',
      '#3D00AA',
      '#8800D9',
      '#FF4800',
      '#00C302',
      '#00A6ED',
      '#00E35C',
      '#FF6E00',
      '#D9008F',
      '#DBB900',
      '#FF9000',
      '#0040C1',
      '#0068ED',
      '#FFAE00',
      '#D9001F',
    ];
}
