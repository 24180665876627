
import * as angular from 'angular';

import { MeurhFeriasmarcacoesIndexController } from './FeriasMarcacoes/index/feriasmarcacoes.index';
import { MeurhFeriasmarcacoesShowController } from './FeriasMarcacoes/show/feriasmarcacoes.show.controller';
import { MeurhFeriasmarcacoesNewController } from './FeriasMarcacoes/new/feriasmarcacoes.new.controller';
import { MeurhFeriasmarcacoesEditController } from './FeriasMarcacoes/edit/feriasmarcacoes.edit.controller';
import { MeurhFeriasMarcacoesShowFormComponent } from './FeriasMarcacoes/showform/feriasmarcacoes.show.form.component';
import { MeurhFeriasMarcacoesNewEditFormComponent } from './FeriasMarcacoes/neweditform/feriasmarcacoes.newedit.form.component';
import { MeurhFeriasmarcaremgradeIndexController } from './FeriasMarcarEmGrade/index/feriasmarcaremgrade.index.controller';
import { MeurhAvisoSaldoZeroModalController } from './FeriasMarcarEmGrade/avisosaldozeromodal/avisosaldozero.modal.controller';
import { MeurhCalendarioFeriasModalController } from './FeriasMarcarEmGrade/calendarioferiasmodal/calendarioferias.modal.controller';
import { MeurhCalendarioFeriasController } from './CalendarioFerias/calendarioferias.controller';
import { MeurhMarcacoesAnterioresModalController } from './FeriasMarcacoes/marcacoesanterioresmodal/marcacoesanteriores.modal.controller';
import { MeurhConfirmarEnvioModalController } from './FeriasMarcacoes/confirmarenviomodal/confirmarenvio.modal.controller';
import { MeurhConfirmarEnvioModalService } from './FeriasMarcacoes/confirmarenviomodal/confirmarenvio.modal.service';
import { MeurhAvisoSaldoZeroModalService } from './FeriasMarcarEmGrade/avisosaldozeromodal/avisosaldozero.modal.service';
import { ToasterHtmlDirective } from './FeriasMarcarEmGrade/index/toasterHtml.directive';
import { MeurhMarcacoesAnterioresModalService } from './FeriasMarcacoes/marcacoesanterioresmodal/marcacoesanteriores.modal.service';
import { MeurhCalendarioFeriasModalService } from './FeriasMarcarEmGrade/calendarioferiasmodal/calendarioferias.modal.service';
import { MeurhFeriasmarcaremgradePassodoisComponent } from './FeriasMarcarEmGrade/passodois/feriasmarcaremgrade.passodois.component';
import { MeurhFeriasmarcaremgradePassotresComponent } from './FeriasMarcarEmGrade/passotres/feriasmarcaremgrade.passotres.component';
import { MeurhFeriasListRouting } from './ferias.routes';
import { MeurhFeriasService } from './ferias.service';
import { MeurhCalendarioFeriasService } from './CalendarioFerias/calendarioferias.service';
import { ColaboradoresmodalService } from './CalendarioFerias/calendarioferiasmobile/calendarioferiasmobile.modal';
import { MeurhEnvioFeriasModalService } from './FeriasMarcarEmGrade/enviosmodal/envios.modal.service';
import { ControleFeriasService } from './ControleFerias/controleferias.service';

export const MeurhFeriasModule = angular.module('MeurhFeriasModule', ['ui.router.state'])
    .controller('MeurhFeriasmarcacoesIndexController', MeurhFeriasmarcacoesIndexController)
    .controller('MeurhFeriasmarcacoesShowController', MeurhFeriasmarcacoesShowController)
    .controller('MeurhFeriasmarcacoesNewController', MeurhFeriasmarcacoesNewController)
    .controller('MeurhFeriasmarcacoesEditController', MeurhFeriasmarcacoesEditController)
    .controller('MeurhFeriasmarcaremgradeIndexController', MeurhFeriasmarcaremgradeIndexController)
    .controller('meurhCalendarioFeriasModalController', MeurhCalendarioFeriasModalController)
    .controller('MeurhCalendarioFeriasController', MeurhCalendarioFeriasController)
    .controller('meurhMarcacoesAnterioresModalController', MeurhMarcacoesAnterioresModalController)
    .controller('meurhAvisoSaldoZeroModalController', MeurhAvisoSaldoZeroModalController)
    .controller('meurhConfirmarEnvioModalController', MeurhConfirmarEnvioModalController)
    .service('MeurhFeriasService', MeurhFeriasService)
    .service('MeurhCalendarioFeriasModalService', MeurhCalendarioFeriasModalService)
    .service('MeurhMarcacoesAnterioresModalService', MeurhMarcacoesAnterioresModalService)
    .service('MeurhAvisoSaldoZeroModalService', MeurhAvisoSaldoZeroModalService)
    .service('MeurhConfirmarEnvioModalService', MeurhConfirmarEnvioModalService)
    .service('MeurhCalendarioFeriasService', MeurhCalendarioFeriasService)
    .service('ColaboradoresmodalService', ColaboradoresmodalService)
    .service('MeurhEnvioFeriasModalService', MeurhEnvioFeriasModalService)
    .service('ControleFeriasService', ControleFeriasService)
    .component('meurhFeriasmarcaremgradePassodoisComponent', MeurhFeriasmarcaremgradePassodoisComponent)
    .component('meurhFeriasmarcaremgradePassotresComponent', MeurhFeriasmarcaremgradePassotresComponent)
    .component('meurhFeriasMarcacoesShowFormComponent', MeurhFeriasMarcacoesShowFormComponent)
    .component('meurhFeriasMarcacoesNewEditFormComponent', MeurhFeriasMarcacoesNewEditFormComponent)
    .directive('toasterHtmlDirective', ToasterHtmlDirective.Factory())
    .config(MeurhFeriasListRouting)
    .name;
