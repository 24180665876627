export class ModalConfirmService {
    static $inject = ['$uibModal', '$rootScope'];

    constructor(
        public $uibModal: any,
        public $rootScope: any
    ) { }

    open(cnfEntity: any, tipo: any, excluir: boolean = false, textoPersonalizado: any = null) {
        return this.$uibModal.open({
            template: require('html-loader!./modal.confirm.html'),
            controller: 'ModalConfirmController',
            controllerAs: 'confirm',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                entity: function () {
                    return cnfEntity;
                },
                tipo: function () {
                    return tipo;
                },
                excluir: function () {
                    return excluir;
                },
                textoPersonalizado: function () {
                    return textoPersonalizado;
                }
            }
        });
    }

    openCancelar(cnfEntity: any, tipo: any, textoPersonalizado: any = null) {
        return this.$uibModal.open({
            template: require('html-loader!./modal.cancelar/modal.cancelar.html'),
            controller: 'ModalCancelarController',
            controllerAs: 'confirm',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                entity: function () {
                    return cnfEntity;
                },
                tipo: function () {
                    return tipo;
                },
                textoPersonalizado: function () {
                    return textoPersonalizado;
                }
            }
        });
    }

    OpenDescartar(cnfEntity: any, tipo: any, toast: any, modulo: string = 'default') {
        return this.$uibModal.open({
            template: require('html-loader!./modal.descartar/modal.descartar.html'),
            controller: 'ModalDescartarController',
            controllerAs: 'confirm',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                entity: function () {
                    return cnfEntity;
                },
                tipo: function () {
                    return tipo;
                },
                gerarTextos: () => {
                    return this.gerarTextos(tipo, toast, modulo);
                }
            }
        });
    }

    // determinando textos de modal personalizados para cada módulo, mantendo um default para generalizar com as demais solicitações
    gerarTextos(tipo: any, toast: any, modulo: any) {
        let textos = {
            rescisao: {
                title: `Descartar alterações da rescisão`,
                subtitle: !toast ? '' : 'Todas as informações alteradas serão perdidas.',
                confirmText: `Você tem certeza que deseja descartar as alterações da rescisão?`,
                closeButton: 'Cancelar',
                confirmButton: 'Sim, descartar',
            },
            default: {
                title: 'Descartar ' + tipo,
                subtitle: !toast ? '' : 'Ao descartar, todas as informações preenchidas serão perdidas.',
                confirmText: `Tem certeza que deseja descartar a ${tipo}?`,
                confirmTextNum: '',
                closeButton: 'Voltar',
                confirmButton: `Descartar ${tipo}`,
            }
        };
        return textos[modulo];
    }
}
