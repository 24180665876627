
import * as angular from 'angular';
import { MeurhSolicitacoesadiantamentosavulsosFormController } from './edit/solicitacoesadiantamentosavulsos.edit.controller';
import { MeurhSolicitacoesadiantamentosavulsosDefault } from './form/solicitacoesadiantamentosavulsos.form.component';
import { MeurhSolicitacoesadiantamentosavulsosListController } from './index/solicitacoesadiantamentosavulsos.index.controller';
import { MeurhSolicitacoesadiantamentosavulsosFormNewController } from './new/solicitacoesadiantamentosavulsos.new.controller';
import { MeurhSolicitacoesadiantamentosavulsosFormShowController } from './show/solicitacoesadiantamentosavulsos.show.controller';
import { MeurhSolicitacoesadiantamentosavulsosDefaultShow } from './showform/solicitacoesadiantamentosavulsos.showform.component';
import { AdiantamentosAvulsosRouting } from './solicitacoesadiantamentosavulsos.routes';
import { MeurhSolicitacoesadiantamentosavulsosService } from './solicitacoesadiantamentosavulsos.service';

export const MeurhSolicitacoesadiantamentosavulsosModule = angular.module('MeurhSolicitacoesadiantamentosavulsosModule', ['ui.router.state'])
  .controller('MeurhSolicitacoesadiantamentosavulsosListController', MeurhSolicitacoesadiantamentosavulsosListController)
  .controller('MeurhSolicitacoesadiantamentosavulsosFormShowController', MeurhSolicitacoesadiantamentosavulsosFormShowController)
  .controller('MeurhSolicitacoesadiantamentosavulsosFormController', MeurhSolicitacoesadiantamentosavulsosFormController)
  .controller('MeurhSolicitacoesadiantamentosavulsosFormNewController', MeurhSolicitacoesadiantamentosavulsosFormNewController)
  .component('meurhSolicitacoesadiantamentosavulsosDefault', MeurhSolicitacoesadiantamentosavulsosDefault)
  .component('meurhSolicitacoesadiantamentosavulsosDefaultShow', MeurhSolicitacoesadiantamentosavulsosDefaultShow)
  .service('MeurhSolicitacoesadiantamentosavulsosService', MeurhSolicitacoesadiantamentosavulsosService)
  .config(AdiantamentosAvulsosRouting)
  .name;
