import * as angular from 'angular';
import { MeurhAdmissoesestagiarioDadosgeraisFormController } from './dadosgerais.form.controller';

export class MeurhAdmissoesestagiarioDadosgeraisFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioDadosgeraisFormComponent';
	static controller = MeurhAdmissoesestagiarioDadosgeraisFormController;
	static template = require('!!html-loader!./dadosgerais.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
		desativaportrabalhadortemporario: '=',
		mostraobrigatoriosencaminhar: '='
	};
}
