import ng from 'angular';
import { ModalGestorInternoService } from './modalGestorInterno/modalGestorInterno.service';
import { ModalGestorExternoService } from './modalGestorExterno/modalGestorExterno.service';
import { IGestoresTrabalhador, ITransferenciaColaboradores } from '../../../Meurh/Transferenciacolaboradores/models/transferenciacolaboradores.model';
import { ITrabalhador } from '../../models/trabalhador.model';

export class MeurhGestoresTrabalhadoresController implements ng.IController {
  static $inject = [
    'ModalGestorInternoService',
    'ModalGestorExternoService'
  ];

  public listaGestores: Array<any> = [];
  public entity: ITransferenciaColaboradores;
  public action: string;
  public exibeAdicionar: boolean;
  public exibeRemover: boolean = true;
  public permissoes: string[] | null;

  constructor(
    private ModalGestorInternoService: ModalGestorInternoService,
    private ModalGestorExternoService: ModalGestorExternoService
  ) { }

  public selecionarGestorInterno(): void {

    let idTrabalhador = this.entity.trabalhador ? this.entity.trabalhador.trabalhador : null;

    const confirm = this.ModalGestorInternoService.open(this.entity.gestores, this.permissoes, idTrabalhador);

    confirm.result
      .then((gestoresSelecionados: ITrabalhador[]) => {
        gestoresSelecionados.forEach((gestor: ITrabalhador) => {
          this.entity.gestores.unshift({
            nome: gestor.nome,
            email: gestor.identificacaonasajon || '',
            tipoGestor: 'Interno',
            gestor: gestor.trabalhador
          });
        });
      })
      .catch(() => {/** */ });
  }

  public selecionarGestorExterno(): void {
    const confirm = this.ModalGestorExternoService.open();

    confirm.result
      .then((gestor: IGestoresTrabalhador) => {
        this.entity.gestores.unshift({ nome: '', email: gestor.identificacaonasajongestor, tipoGestor: 'Externo', gestor: '' });
      })
      .catch(() => {/** */ });
  }

  public removerGestor(indexGestorEscolhido: any): void {
    this.entity.gestores.splice(indexGestorEscolhido, 1);
  }
}
