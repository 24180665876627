export class ModalCardsController {
    static $inject = ['$uibModalInstance', 'TrabalhadoresService', 'modalValues'];

    public values: any;

    constructor(
        public $uibModalInstance: any,
        public TrabalhadoresService: any,
        public modalValues: any,
    ) {
        this.values = this.modalValues;
    }

    ok() {
        this.$uibModalInstance.close();
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    loadMore() {
        this.TrabalhadoresService.loadParams.to_load = 3;
        this.values.trabalhadores = this.TrabalhadoresService.load();
    }

    finished() {
        return this.TrabalhadoresService.loadParams.finished;
    }

    busy() {
        return this.TrabalhadoresService.loadParams.busy;
    }
}
