import angular from 'angular';
import { LoadParams } from '../../../shared/models/loadparams.model';

export class MeurhEscalaService {

    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public loadParams: LoadParams = new LoadParams();
    public loading_deferred: any = null;

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService
    ) {
    }

	salvarEscala(entity: any, tipo: number): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('meurh_mudancastrabalhadores_escala', {}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
                response.dataLength = entity.length;
				this.$rootScope.$broadcast('mudancastrabalhadores_escala_submitted', response, tipo);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('mudancastrabalhadores_escala_submit_error', response, tipo);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

    salvarEmLote(entity: Array<any>, tipo: number): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('meurh_mudancastrabalhadores_inserir_mudancas_lote', {}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
                response.dataLength = entity.length;
				this.$rootScope.$broadcast('mudancastrabalhadores_lote_submitted', response, tipo);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('mudancastrabalhadores_lote_submit_error', response, tipo);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

    excluirEmLote(entity: Array<Partial<any>>, tipo: number): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
        entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'DELETE',
				url: this.nsjRouting.generate('meurh_mudancastrabalhadores_exclusao_em_lote', {}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
                response.dataLength = entity.length;
				this.$rootScope.$broadcast('mudancastrabalhadores_lote_deleted', response, tipo);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('mudancastrabalhadores_lote_delete_error', response, tipo);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

    listarSemPaginacao(params: {
		excluida: number,
		tipo: number,
		empresa: string,
        datainicial?: string,
		trabalhador_estabelecimento?: string
	}): any {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        const urlParams = {
			estabelecimento: '',
			...params
		};

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
           this.$http
                .get(this.nsjRouting.generate(
                    'meurh_mudancastrabalhadores_indexsempaginacao', urlParams, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any): void => {
                    this.$rootScope.$broadcast('mudancastrabalhadores_sempaginacao_loaded', response.data, urlParams.tipo);
                    this.loadParams.busy = false;

                    resolve(response.data);
                })
                .catch((response: any): void => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }
}
