import { IModalConfirmRelatorios } from '../../models/modalConfirmRelatorios.model';

export class ModalConfirmRelatoriosService {
  static $inject = [
    '$uibModal'
  ];

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
  ) { }

  public open(texts: IModalConfirmRelatorios): angular.ui.bootstrap.IModalInstanceService {
    return this.$uibModal.open({
      template: require('html-loader!./modalConfirmRelatorios.html'),
      controller: 'ModalConfirmRelatoriosController',
      controllerAs: '$ctrl',
      animation: true,
      keyboard: true,
      backdrop: true,
      resolve: {
        texts: () => texts,
      }
    });
  }
}
