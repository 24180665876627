import angular from 'angular';

export class MeurhApontamentosMovimentosAuxService {
    static $inject = ['$http', 'nsjRouting'];

    constructor(
        public $http: any,
        public nsjRouting: any) { }

    zerado(data: any) {
        return this.$http({
            method: 'POST',
            url: this.nsjRouting.generate('apontamentos_movimentos_zerado', angular.extend({ 'estabelecimento': data.estabelecimento }, { 'id': 1 }), true),
            data: data
        });
    }
    repeteAnterior(data: any) {
        return this.$http({
            method: 'POST',
            url: this.nsjRouting.generate('apontamentos_movimentos_repeteanterior', angular.extend({ 'estabelecimento': data.estabelecimento }, { 'id': 1 }), true),
            data: data
        });
    }
}

