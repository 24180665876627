import * as angular from 'angular';
import { MeurhAdmissoesestagiarioEnderecoFormController } from './endereco.form.controller';

export class MeurhAdmissoesestagiarioEnderecoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioEnderecoFormComponent';
	static controller = MeurhAdmissoesestagiarioEnderecoFormController;
	static template = require('!!html-loader!./endereco.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
