import angular from 'angular';
import { EstabelecimentosHabilitadosService } from '../estabelecimentoshabilitados.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { ObrasService } from '../../../../Obras/obras.service';
import { IAlteracaoEntidade } from '../../models/alteracaoentidade.model';

type IEstabelecimentosHabilitadosFilters = {
    empresa: string;
    estabelecimento: string;
    obra?: string;
};

export class EstabelecimentosHabilitadosIndexController {
    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        '$location',
        'EstabelecimentosHabilitadosService',
        'ListagemService',
        'ObrasService'
    ];

    public entities: Array<IAlteracaoEntidade> = [];
    public entity: any;
    public busy: boolean = false;
    public empresas: Array<any> = [];
    public estabelecimentos: Array<any> = [];
    public obras: Array<any> = [];
    public filters: IEstabelecimentosHabilitadosFilters = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
    };

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
			temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            session: any,
            configuracoes: any,
            nsjGlobals: any
        },
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public estabelecimentosHabilitadosService: EstabelecimentosHabilitadosService,
        public ListagemService: ListagemService,
        public obrasService: ObrasService
    ) {
        if (!this.$rootScope.liberadoTela('LIBERAR_PONTO_EXPORTACAO_AFD')) {
            $state.go('dp');
        }
        this.busy = true;
        this.montaListagemVisibilidade();
        this.obras = this.obrasService.reload();
        this.estabelecimentosHabilitadosService.reload();

        this.$scope.$on('ponto_alteracoesentidades_list_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.entities = args;
            this.busy = false;
        });
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, []);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, []);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, []) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        this.filters.obra = '';
        if (this.filters.estabelecimento) {
            this.filtraObra();
        }
        this.filterReload();
    }

    filtraObra() {
        this.obrasService.constructors.estabelecimento = this.filters.estabelecimento;
        this.obras = this.obrasService.reload();
    }

    filterReload() {
        this.busy = true;
        let constructors = {
            empresa: this.filters.empresa,
            estabelecimento: this.filters.estabelecimento,
            ...(this.filters.obra && { obra: this.filters.obra })
        };

        // if (this.filters.datainicio) {
        //     constructors['datahoraregistro[0]'] = {
        //         condition: 'gte',
        //         value: this.filters.datainicio
        //     };
        // }

        // if (this.filters.datafim) {
        //     constructors['datahoraregistro[1]'] = {
        //         condition: 'lte',
        //         value: this.filters.datafim
        //     };

        // }

        this.estabelecimentosHabilitadosService.constructors = constructors;
        this.entities = this.estabelecimentosHabilitadosService.reload();
    }

    editar(id: string) {
        this.$state.go('estabelecimentoshabilitados_edit', { 'id': id });
    }

    removeEntity(index: number) {
        this.entities.splice(index, 1);
    }
}
