import angular = require('angular');
import moment = require('moment');
import { FolhasdePontoService } from '../folhasdeponto.service';

export class MeurhFolhasdepontoVisualizarpdfmodalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: any) { }

  open(entidade: any) {

    return this.$uibModal.open({
      template: require('html-loader!./folhasdeponto.visualizarpdfmodal.html'),
      controller: ['entity', '$uibModalInstance', '$sce', '$compile', '$scope', 'FolhasdePontoService', function MeurhFolhasdepontoVisualizarpdfmodal(entity: any, $uibModalInstance: any, $sce: any, $compile: any, $scope: any, FolhasdePontoService: FolhasdePontoService) {
        var selfModal = this;
        selfModal.entidade = entidade;
        selfModal.$sce = $sce;
        selfModal.busy = true;
        selfModal.FolhasdePontoService = FolhasdePontoService;
        selfModal.file = null;
        selfModal.fileURL = '';

        selfModal.file = selfModal.FolhasdePontoService.visualizarPdf(selfModal.entidade);

        $scope.$on('ponto_apuracoesfolhas_get_pdf_iniciado', (event: any, args: any) => {
			      selfModal.busy = false;
            var file = new Blob([args.data], { type: 'application/pdf' });
            selfModal.fileURL = URL.createObjectURL(file);
            selfModal.fileURL = selfModal.trustUrl(selfModal.fileURL);
        });

        selfModal.trustUrl = (subentityURL: any) => {
            let url = selfModal.$sce.trustAsResourceUrl(subentityURL);
            return url;
        };


        selfModal.close = () => {
          $uibModalInstance.dismiss('fechar');
        };
      }],
      controllerAs: '$ctrl',
      windowClass: 'nsj-modal-lg',
      resolve: {
        entity: function () {
          return angular.copy(entidade);
        }
      }
    });
  }
}
