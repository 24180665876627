import angular from 'angular';
import { IAlteracaoFoto } from '../models/alteracaofoto.model';
import { Usuario } from '../../../../core/usuario/models/usuario-model';
import { MeurhAlteracaoFotoService } from '../alteracaofoto.service';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { ModalConfirmService } from '../../../../shared/components/modal-confirm/modal.confirm.service';

type IAlteracaoFotoFilters = {
	trabalhador: { trabalhador: string } | null;
	situacao: string | null;
    tipoColaborador: string | null;
    origem: string | null;
};

export class MeurhAlteracaoFotoIndexController {

    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        'NewToaster',
        'MeurhAlteracaoFotoService',
        'MeurhSolicitacoes',
        'ModalConfirmService'
    ];

    public entities: IAlteracaoFoto[] = [];
    public filters: IAlteracaoFotoFilters = {
        trabalhador: null,
        situacao: '',
        tipoColaborador: null,
        origem: null
    };
    public busy = false;

    public fields = [
        {
            value: 'trabalhadorobj.codigo',
            label: 'Matrícula',
            type: 'string',
            style: 'title',
            copy: '',
        }, {
            value: 'trabalhadorobj.nome',
            label: 'Colaborador',
            type: 'string',
            style: 'title',
            copy: '',
        }, {
            value: 'created_at',
            label: 'Enviado em',
            type: 'date',
            style: 'title',
            copy: '',
        }, {
            value: 'situacao',
            label: 'Situação',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
				let someHtmlVar = `
                    <label
                        ng-if="!tableRowEntity.wkf_estado"
                        class="label {{tableRowEntity.situacao | situacaoSolicitacaoClassEnum}}"
                    >
                        {{tableRowEntity.situacao | situacaoSolicitacaoEnum}}
                    </label>
                    <label
                        ng-if="tableRowEntity.wkf_estado"
                        class="label {{tableRowEntity.situacao | situacaoSolicitacaoClassEnum}}"
                    >
                        {{ tableRowEntity.wkf_estado }}
                    </label>
              	`;
			  return someHtmlVar;
            },
        }, {
            value: 'solicitacao',
			label: 'Ações',
			type: 'div',
			style: 'title',
			copy: '',
            customCollumnScope: {
				visualizarAlteracao: (entity: IAlteracaoFoto): void => {
                    this.$state.go('meurh_alteracaofoto_show', {
                        solicitacao: entity.solicitacao
                    });
                },
				cancelarAlteracao: (entity: IAlteracaoFoto): void => {
					this.modalCancelar(entity);
                },
            },
			customCollumnHtml: () => {
				let someHtmlVar = `
					<div class="menuEllipsisWrapper content-center">
						<nsj-button-dropdown
							border="none"
							split="false"
							size="sm"
							icon="fas fa-ellipsis-v"
							dropdown-direction="right"
							class="menuEllipsis"
                            ng-if="tableRowEntity.situacao === 0 &&
                                (tableRowEntity.created_by.email === '${this.$rootScope.usuario.email}')"
						>
							<item-dropdown
								item-label="Visualizar"
								ng-click="customCollumnScope.visualizarAlteracao(tableRowEntity)"
							></item-dropdown>
							<item-dropdown
								item-label="Cancelar alteração"
								ng-click="customCollumnScope.cancelarAlteracao(tableRowEntity)"
							></item-dropdown>
						</nsj-button-dropdown>
                        <a
                            class="btn btn-tertiary btn-sm"
                            ng-click="customCollumnScope.visualizarAlteracao(tableRowEntity)"
                            ng-if="!(tableRowEntity.situacao === 0 &&
                                (tableRowEntity.created_by.email === '${this.$rootScope.usuario.email}'))"
                        >
                            Visualizar
                        </a>
					</div>
              	`;
			  return someHtmlVar;
            },
        }
    ];

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            usuario: Usuario,
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean
        },
		public $state: angular.ui.IStateService,
        public NewToaster: { pop: Function },
        public MeurhAlteracaoFotoService: MeurhAlteracaoFotoService,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public ModalConfirmService: ModalConfirmService,
	) {
        if (
            !this.$rootScope.temPermissao('visualizar_alteracaofoto') ||
            !this.$rootScope.liberadoTela('LIBERAR_MEURH_SOLICITACAO_ALT_FOTO')
        ) {
            $state.go('dp');
        }

        this.busy = true;
        this.filterReload();
        $scope.$on('meurh_solicitacoesalteracoesfoto_loaded', (): void => {
            this.busy = false;
        });
    }

    filterReload() {
        this.busy = true;
        const constructor = {};
        if (!this.valorExiste(this.filters.trabalhador)) {
			this.filters.trabalhador = null;
        } else {
            constructor['trabalhador'] = this.filters.trabalhador?.trabalhador;
        }

        if (!this.valorExiste(this.filters.tipoColaborador)) {
			this.filters.tipoColaborador = null;
        } else {
            constructor['trabalhador_tipo'] = this.filters.tipoColaborador;
        }

		if (!this.valorExiste(this.filters.situacao)) {
			this.filters.situacao = null;
        } else {
            constructor['situacao'] = this.filters.situacao;
        }

        if (!this.valorExiste(this.filters.origem)) {
			this.filters.origem = null;
        } else {
            constructor['origem'] = this.filters.origem;
        }

		this.MeurhAlteracaoFotoService.constructors = constructor;
        this.entities = this.MeurhAlteracaoFotoService.reload();
    }

    loadMore(): void {
        this.MeurhAlteracaoFotoService.loadMore();
    }

    modalCancelar(entity: IAlteracaoFoto): void {
        const customText = {
            title: 'Cancelar',
            subtitle: 'Você está prestes a cancelar a solicitação de alteração de foto de',
            confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
            confirmTextNum: entity.trabalhadorobj.nome,
            closeButton: 'Voltar',
            confirmButton: 'Cancelar solicitação',
        };
        const confirm = this.ModalConfirmService.openCancelar(entity, 'Solicitação de alteração de foto', customText);
        confirm.result.then((entity: IAlteracaoFoto) => {
            this.cancelar(entity);
        }).catch(() => {/**/});
    }

    cancelar(entity: IAlteracaoFoto) {
        this.busy = true;
        this.MeurhSolicitacoes.cancelar(entity).then(() => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de alteração de foto foi cancelada com sucesso.'
            });
            this.filterReload();
        }).catch((response: any) => {
            let error: string;
            if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                error = response.data.message;
            } else {
                error = 'Ocorreu um erro ao cancelar solicitação de foto.';
            }
            this.NewToaster.pop({
                type: 'error',
                title: error
            });
        }).finally(() => {
            this.busy = false;
        });
    }

    private valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}
}
