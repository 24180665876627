
import * as angular from 'angular';

import { MeurhSolicitacoesadmissoesIndexController } from './index/solicitacoesadmissoes.index.controller';
import { MeurhSolicitacoesadmissoesShowController } from './show/solicitacoesadmissoes.show.controller';
import { MeurhSolicitacoesadmissoesShowFormController } from './showform/solicitacoesadmissoes.show.form';
import { MeurhSolicitacoesadmissoesNewController } from './new/solicitacoesadmissoes.new.controller';
import { MeurhSolicitacoesadmissoesFormController } from './form/solicitacoesadmissoes.form.controller';
import { MeurhSolicitacoesadmissoesEditController } from './edit/solicitacoesadmissoes.edit.controller';
import { MeurhSolicitacoesadmissoesShowFormComponent } from './showform/solicitacoesadmissoes.show.form.component';
import { MeurhSolicitacoesadmissoesFormComponent } from './form/solicitacoesadmissoes.form.component';
import { MeurhSolicitacoesadmissoesService } from './solicitacoesadmissoes.service';
import { MeurhSolicitacoesadmissoesListRouting } from './solicitacoesadmissoes.routes';

export const MeurhSolicitacoesadmissoesModule = angular.module('MeurhSolicitacoesadmissoesModule', ['ui.router.state', ])
    .controller('MeurhSolicitacoesadmissoesIndexController', MeurhSolicitacoesadmissoesIndexController)
    .controller('MeurhSolicitacoesadmissoesShowController', MeurhSolicitacoesadmissoesShowController)
    .controller('MeurhSolicitacoesadmissoesShowFormController', MeurhSolicitacoesadmissoesShowFormController)
    .controller('MeurhSolicitacoesadmissoesNewController', MeurhSolicitacoesadmissoesNewController)
    .controller('MeurhSolicitacoesadmissoesFormController', MeurhSolicitacoesadmissoesFormController)
    .controller('MeurhSolicitacoesadmissoesEditController', MeurhSolicitacoesadmissoesEditController)
    .component('meurhSolicitacoesadmissoesShowFormComponent', MeurhSolicitacoesadmissoesShowFormComponent)
    .component('meurhSolicitacoesadmissoesFormComponent', MeurhSolicitacoesadmissoesFormComponent)
    .service('MeurhSolicitacoesadmissoes', MeurhSolicitacoesadmissoesService)
    .config(MeurhSolicitacoesadmissoesListRouting)
    .name;
