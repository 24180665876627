import * as angular from 'angular';
import { MeurhTransferenciaColaboradoresLoteFormController } from './transferenciacolaboradoreslote.form.controller';

export class MeurhTransferenciaColaboradoresLoteFormComponent implements angular.IComponentOptions {
	static selector = 'meurhTransferenciaColaboradoresLoteFormComponent';
	static controller = MeurhTransferenciaColaboradoresLoteFormController;
	static template = require('!!html-loader!./transferenciacolaboradoreslote.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<?',
		busy: '=?'
	};
}
