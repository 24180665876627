import angular from 'angular';
import { TrabalhadoresPontoService } from '../../trabalhadoresponto.service';

export class EstabelecimentosHabilitadosModalService {
  static $inject = ['$uibModal', '$rootScope', 'TrabalhadoresPontoService'];

  constructor(
    public $uibModal: ng.ui.bootstrap.IModalService,
    public $rootScope: angular.IRootScopeService,
    public TrabalhadoresPontoService: TrabalhadoresPontoService
  ) {}

  open(colaboradores: any) {

    return this.$uibModal.open({
      template: require('html-loader!./estabelecimentoshabilitados.modalbuscarcolaboradores.html'),
      controller: [
        '$uibModalInstance',
        '$scope',
        'TrabalhadoresPontoService',
        function Modalbuscarcolaboradores(
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
          $scope: angular.IScope,
          TrabalhadoresPontoService: TrabalhadoresPontoService
        ) {
        var selfModal = this;
        selfModal.isLoading = false;
        selfModal.entitiesSelecionadas = [];
        selfModal.filtro = null;
        selfModal.trabalhadoresPontoService = TrabalhadoresPontoService;
        const pontoServiceBackup = {
          loadParams: angular.copy(TrabalhadoresPontoService.loadParams),
          constructors: angular.copy(TrabalhadoresPontoService.constructors),
          entities: angular.copy(TrabalhadoresPontoService.entities),
          after: angular.copy(TrabalhadoresPontoService.after),
        };

        if (colaboradores.length > 0) {
          selfModal.entities = colaboradores;
        } else {
          selfModal.isLoading = true;

          TrabalhadoresPontoService.constructors = {
            'incluidoponto': '0',
            'desligado': '0',
          };

          TrabalhadoresPontoService.reload();
          $scope.$on('ponto_trabalhadores_list_loaded', (event: angular.IAngularEvent, args: any): void => {
            selfModal.entities = [...args];
            selfModal.isLoading = false;
          });
        }

        selfModal.filtrarDados = (filtro: string) => {
          selfModal.isLoading = true;
          selfModal.constructors.filter = filtro;

          TrabalhadoresPontoService.reload();
          $scope.$on('ponto_trabalhadores_list_loaded', (event: angular.IAngularEvent, args: any): void => {
            selfModal.entities = [...args];
            selfModal.isLoading = false;
          });
        };

        selfModal.checkboxEntidades = (acao: any = null) => {
          selfModal.entities.forEach((entity): void => {
            if (acao === null) {
              entity.selecionado = selfModal.checkboxTodos ? true : false;
            } else {
              entity.selecionado = acao;
            }
          });

          if (acao !== null) {
            selfModal.checkboxTodos = acao ? true : false;
          }
          selfModal.checkboxNenhum = selfModal.checkboxTodos ? false : true;
        };

        selfModal.adicionarRemoverListaSelecionados = (entities: any[]) => {
          entities.forEach(entity => {
            if (entity.selecionado) {
              selfModal.colaboradorSelecionado = true;
              let naoSelecionado = !selfModal.entitiesSelecionadas.some(
                trabalhador => trabalhador.trabalhador === entity.trabalhador
              );

              if (naoSelecionado) {
                selfModal.entitiesSelecionadas.push(entity);
              }
            } else {
              selfModal.colaboradorSelecionado = false;
              let index = selfModal.entitiesSelecionadas.findIndex(
                trabalhador => trabalhador.trabalhador === entity.trabalhador
              );

              if (index > -1) {
                selfModal.entitiesSelecionadas.splice(index, 1);
                selfModal.checkAll = false;
              }
            }
          });
        };

        selfModal.todosFuncionariosSelecionados = () => {
          let selecionado = true;

          if (this.entities.length === 0) {
            selecionado = false;
          }

          selfModal.entities.forEach((entity): void => {
            if (!entity.selecionado) {
              selecionado = false;
            }
          });

          selfModal.checkboxTodos = selecionado;
        };

        selfModal.nenhumFuncionarioSelecionado = () => {
          let nenhumSelecionado = true;
          selfModal.colaboradorSelecionado = false;

          selfModal.entities.forEach((entity): void => {
            if (entity.selecionado) {
              selfModal.colaboradorSelecionado = true;
              nenhumSelecionado = false;
            }
          });

          selfModal.checkboxNenhum = nenhumSelecionado;
        };

        selfModal.loadMore = () => {
          TrabalhadoresPontoService.loadMore();
        };

        selfModal.salvar = () => {
          selfModal.applyPontoServiceBackup();
          $uibModalInstance.close(selfModal.entitiesSelecionadas);
          colaboradores = selfModal.entitiesSelecionadas;
          colaboradores.forEach(colaborador => {
            colaborador.selecionado = false;
          });
        };

        selfModal.close = () => {
          selfModal.applyPontoServiceBackup();
          $uibModalInstance.dismiss('fechar');
          selfModal.colaboradorSelecionado = false;
          colaboradores.forEach(colaborador => {
            colaborador.selecionado = false;
          });
        };

        selfModal.applyPontoServiceBackup = () => {
          TrabalhadoresPontoService.loadParams = pontoServiceBackup.loadParams;
          TrabalhadoresPontoService.constructors = pontoServiceBackup.constructors;
          TrabalhadoresPontoService.entities = [...pontoServiceBackup.entities];
          TrabalhadoresPontoService.after = pontoServiceBackup.after;
        };
      }],
      controllerAs: '$ctrl',
      backdrop: 'static',
      windowClass: 'nsj-modal-lg-no-height',
      resolve: {
        entity: function () {
          return angular.copy(colaboradores);
        }
      }
    });
  }
}
