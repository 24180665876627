import angular = require('angular');
export class MeurhMarcacoesAnterioresModalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: any) {
  }

  open(marcacoesAnteriores: any) {
    return this.$uibModal.open({
      template: require('html-loader!./marcacoesanteriores.modal.html'),
      controller: 'meurhMarcacoesAnterioresModalController',
      controllerAs: 'mrh_mrccsntrrs_mdl_cntrllr',
      size: 'lg',
      resolve: {
        marcacoesAnteriores: async () => {
          return marcacoesAnteriores;
        },
      }
    });
  }
}
