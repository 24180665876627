
import angular from 'angular';

import { SimulacaoFeriasIndexController } from './index/simulacaoferias.index.controller';
import { SimulacaoFeriasListRouting } from './simulacaoferias.routes';

export const SimulacaoFeriasModule = angular.module('SimulacaoFeriasModule', ['ui.router.state'])
    .controller('SimulacaoFeriasIndexController', SimulacaoFeriasIndexController)
    .config(SimulacaoFeriasListRouting)
    .name;
