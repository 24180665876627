import angular from 'angular';

export const PaginaInicialListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('inicial', {
                url: '/pagina-inicial',
                template: require('html-loader!./index/paginainicial.index.html'),
                controller: 'PaginaInicialIndexController',
                controllerAs: 'pgn_incl_cntrllr'
            });
	}
];
