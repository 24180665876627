import angular from 'angular';
import { FiltrosAtivosController } from './filtros.ativos.controller';

export class FiltrosAtivosComponent implements angular.IComponentOptions {
  static selector = 'filtrosAtivosComponent';
  static controller = FiltrosAtivosController;
  static template = require('!!html-loader!./filtros.ativos.html');
  static transclude = true;
  static bindings = {
    tags: '=',
    name: '@',
  };
}
