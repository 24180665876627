import * as angular from 'angular';
import { MeurhAnexoFormController } from './anexos.form.controller';

export class MeurhAnexoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAnexoFormComponent';
	static controller = MeurhAnexoFormController;
	static template = require('!!html-loader!./anexos.form.html');
	static transclude = true;
	static bindings = {
		mostrarAlerta: '<',
		entity: '=',
		form: '<',
		busy: '=?',
		editanexo: '=?',
		alterouanexo: '=?',
		maxFiles: '<?',
		documentname: '=?',
		obrigatorio: '=?',
		fileSelecionado: '=?',
		validate: '=?',
		validateResult: '=?',
		tipodocumentocolaborador: '=?',
		file: '=?',
		action: '=?'
	};
}
