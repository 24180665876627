import angular from 'angular';
import moment from 'moment';
import { ListagemService } from '../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../core/usuario/models/usuario-model';

export class SimulacaoFeriasIndexController {
    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'nsjRouting', '$http', 'NewToaster', 'moment', 'ListagemService'];

    public nomesMeses: any = new Array('Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro');
    public temabonopecuniario: boolean = false;
    public temadiantamento13: boolean = false;
    public dias_abono_pecuniario: string = '0';
    public dias_ferias: number = 30;
    public dataAtual: any = new Date();
    public pagaradicionaisferias: boolean = true;
    public mesAtual: any;
    public exibir_resultado: boolean = false;
    public exibir_informacoes_trabalhador: boolean = false;
    public habilitar_filtros: boolean = false;
    public loading: boolean = false;
    public saldo_ferias: number = 0;
    public lookupTrabalhadores: boolean = false;
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public form: angular.IFormController;
    public update: boolean;
    public trabalhador: any;
    public datainiciogozo: any;
    public dias_direito: any;
    public entity: any;
    public datafimgozo: any;
    public data_minima: moment.Moment;
    public data_limite_inicio_gozo: Date;
    public mes_limite_inicio_gozo: any;
    public data_fim_gozo_tratado: string;
    public datainicioperiodo: any;
    public datafimperiodo: any;
    public quantidade_divisoes_ferias: any;
    public listagemPermissao: Array<string> = ['simular_ferias', 'visualizar_salarios'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public empresa: string = this.$rootScope.session.entities.empresa.empresa;
    public estabelecimento: string = this.$rootScope.session.entities.estabelecimento.estabelecimento;
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;

    constructor(
        public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean, session: any, nsjGlobals: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public nsjRouting: any,
        public $http: angular.IHttpService,
        public NewToaster: any,
        public moment: any,
        public ListagemService: ListagemService,
    ) {
        this.montaListagemVisibilidade();

        if (!$rootScope.temPermissao('simular_ferias') || !$rootScope.temPermissao('visualizar_salarios')) {
            $state.go('dp', $stateParams);
        }

        this.mesAtual = moment({ M: this.dataAtual.getMonth(), Y: this.dataAtual.getFullYear() });

        $rootScope.$on('Trabalhadores_loading', (event, args) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event, args) => {
            this.lookupTrabalhadores = false;
        });

        $scope.$watch('$ctrl.dias_ferias', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
                if (this.dias_ferias === null || this.dias_ferias === undefined || this.dias_ferias <= 0 || this.dias_direito < this.dias_ferias) {
                    this.form.dias_ferias.$setValidity('valorInvalido', false);
                } else {
                    this.form.dias_ferias.$setValidity('valorInvalido', true);
                }
            }
        }, true);
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.empresa ? this.ListagemService.montaEstabelecimentos(this.empresa, this.empresas, this.listagemPermissao) : [];
        this.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        this.trabalhador = null;
        this.carregar_dados();
        this.$rootScope.$broadcast('trabalhador_refresh', this.trabalhadoresConstructor());
    }

    trabalhadoresConstructor() {
        return {
            status: '00',
            tipo: 0,
            forcagestor: this.forcaGestor(),
            estabelecimento: this.estabelecimento,
            empresa: this.empresa
        };
    }

    carregandoLookups() {
        return this.lookupTrabalhadores;
    }

    maximoDias() {
        return this.dias_direito ? this.dias_direito : 30;
    }

    simular() {
        if (this.datainiciogozo === '' || !(typeof this.datainiciogozo === 'string')) {
            this.NewToaster.pop(
                {
                    type: 'error',
                    title: 'Data de início de gozo inválida'
                });
        } else if (this.trabalhador.categoriatrabalhador === '111') {
            this.NewToaster.pop(
                {
                    type: 'error',
                    title: 'Não é possível gerar simulação de férias para funcionários intermitentes'
                });
        } else {
            this.exibir_resultado = false;
            if (this.form.$valid) {
                this.update = true;
                this.generateUrl().then((response) => {
                    this.entity = response.data;
                    this.update = false;

                    if (this.entity.trabalhadores == null) {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Funcionário sem direito a férias'
                            });
                    } else {
                        this.exibir_resultado = true;
                    }

                }).catch((error) => {
                    this.update = false;
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: error.data.message
                        });
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos apresentam erros'
                });
            }
        }
    }

    carregar_dados() {
        this.exibir_resultado = false;
        this.loading = true;
        this.exibir_informacoes_trabalhador = false;
        this.habilitar_filtros = false;
        this.entity = null;
        this.datainiciogozo = null;
        this.datafimgozo = null;
        this.data_minima = moment();
        this.mesAtual = this.moment({ M: this.data_minima.month(), Y: this.data_minima.year() });
        if (this.trabalhador) {
            this.getFimPeriodoAquisitivoFerias().then((response) => {
                this.datainicioperiodo = this.trabalhador.inicioperiodoaquisitivoferias;
                this.datafimperiodo = response.data;
                if (moment(this.datafimperiodo).isAfter(this.data_minima.format('YYYY-MM-DD'))) {
                    this.data_minima = moment(this.datafimperiodo);
                    this.mesAtual = this.moment({ d: this.data_minima.date(), M: this.data_minima.month(), Y: this.data_minima.year() });
                }

                this.getSaldoEDivisoesDeFerias().then((response: any) => {
                    this.saldo_ferias = response.data.saldo;
                    this.dias_direito = response.data.saldo;
                    this.dias_ferias = response.data.saldo;
                    this.quantidade_divisoes_ferias = response.data.quantidade_divisoes;
                    this.exibir_informacoes_trabalhador = true;
                    this.loading = false;

                    if (response.data.saldo === 0) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Funcionário já tirou todos os dias de direito de férias no Período Aquisitivo vigente'
                        });
                    } else {
                        this.habilitar_filtros = true;
                    }
                });

                this.determinar_limite_inicio_gozo();

            });
        }
    }

    forcaGestor() {
        return this.$rootScope.modoGestorPermissao('simular_ferias');
    }

    determinar_limite_inicio_gozo() {
        this.data_limite_inicio_gozo = new Date();
        this.mes_limite_inicio_gozo = this.moment({ M: this.data_limite_inicio_gozo.getMonth(), Y: this.data_limite_inicio_gozo.getFullYear() });
    }

    preencher_data_fim_gozo() {
        var dataInicio = moment(this.datainiciogozo);
        this.datafimgozo = this.datainiciogozo ? dataInicio.add(this.dias_ferias - 1, 'days').format('DD/MM/YYYY') : null;
    }

    atualizar_dias_ferias() {
        this.dias_ferias = this.saldo_ferias - parseInt(this.dias_abono_pecuniario, 10);
    }

    generateUrl() {
        this.temabonopecuniario = (parseInt(this.dias_abono_pecuniario, 10) > 0);
        var dataInicio = moment(this.datainiciogozo);
        this.data_fim_gozo_tratado = dataInicio.add(this.dias_ferias - 1, 'days').format('YYYY-MM-DD');

        return this.$http.get(this.nsjRouting.generate('simulacoes_simular', {
            'trabalhador': this.trabalhador.trabalhador,
            'temabonopecuniario': this.temabonopecuniario,
            'qtddiasabonopecuniario': parseInt(this.dias_abono_pecuniario, 10),
            'temadiantamento13': this.temadiantamento13 ? '1' : '0',
            'datainiciogozo': this.datainiciogozo,
            'datafimgozo': this.data_fim_gozo_tratado,
            'estabelecimento': this.trabalhador.estabelecimentoobj.estabelecimento,
            'datainicioperiodo': this.datainicioperiodo,
            'datafimperiodo': this.datafimperiodo,
            'pagaradicionaisferias': this.pagaradicionaisferias
        }, true));
    }

    getFimPeriodoAquisitivoFerias() {
        return this.$http.get(this.nsjRouting.generate('simulacoes_datafimperiodoaquisitivo', {
            'id_trabalhador': this.trabalhador.trabalhador,
            'data_inicio_periodo': this.trabalhador.inicioperiodoaquisitivoferias
        }, true));
    }

    getSaldoEDivisoesDeFerias() {
        return this.$http.get(this.nsjRouting.generate('simulacoes_saldoedivisoesdeferias', {
            'id_trabalhador': this.trabalhador.trabalhador,
            'data_inicio_periodo': this.trabalhador.inicioperiodoaquisitivoferias
        }, true));
    }

}
