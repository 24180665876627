import angular from 'angular';
import { MeurhPromocoesShowFormController } from './promocoes.form.controller';

export class MeurhPromocoesShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhPromocoesShowFormComponent';
	static controller = MeurhPromocoesShowFormController;
	static template = require('!!html-loader!./promocoes.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
	};
}
