import angular from 'angular';

export class ConfiguracoesService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = {};
    public loaded: any = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loading_deferred: any = null;
    public filter: any = '';
    public loadParams: any = {
        to_load: 3,
        busy: false,
        busySave: false,
        finished: false
    };

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any,
    ) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities = {};
        this.loadParams.busy = false;

        return this.load();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('configuracoes_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    angular.copy(data, this.entities);
                    this.loadParams.finished = true;
                    this.$rootScope.$broadcast('configuracoes_list_finished', this.entities);
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error: any) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                });
        }

        return this.entities;
    }

    _save(entity: any, autosave: any) {
        let method: any, url: any;
        method = 'PUT';
        url = this.nsjRouting.generate('configuracoes_put_bulk', {}, true);
        var data = angular.copy(entity);
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        });
    }

    save(entity: any, autosave: any) {
        this.loadParams.busySave = true;
        this._save(entity, autosave)
            .then((response: any) => {
                this.$rootScope.$broadcast('configuracoes_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any) => {
                this.$rootScope.$broadcast('configuracoes_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            }).finally(() => {
                this.loadParams.busySave = false;
            });
    }
}
