import angular from 'angular';
import { MeurhAcompanhamentoService } from '../../../Meurh/Acompanhamento/acompanhamento.service';
import { IAnexo } from '../../../Meurh/Anexos/models/anexo.model';
import { IFalta } from '../models/falta.model';
import { MeurhSolicitacoesFaltasService } from '../solicitacoesfaltas.service';
import { MeurhAnexosService } from '../../../Meurh/Anexos/anexos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';

export class MeurhSolicitacoesfaltasEditController implements angular.IController {
	static $inject = [
		'$scope',
		'$stateParams',
		'$state',
		'MeurhSolicitacoesFaltasService',
		'entity',
		'NewToaster',
		'$rootScope',
		'MeurhAcompanhamentoService',
		'MeurhAnexosService',
		'TiposDocumentosRequeridosService',
		'ModalConfirmService',
		'$q',
		'$transitions'
	];

	public action: string = 'update';
	public editanexo: boolean = true;
	public alterouanexo: boolean = false;
	public editouAnexo: boolean = false;
	public form: angular.IFormController;
	public formAnexo: object = {};
	public entityAnexo: IAnexo = {
        casodeuso: 'FALTAS'
    };
	public actionAnexo: string = 'update';
	public busy: boolean = false;
	public busyTipoDocumentos: boolean = true;
	public tiposdocumentosrequeridos: Array<any>;
	public validate: number = 0; // conta quantos erros há em documentos anexos
	public allowRefresh: boolean = false;
    public formchanged: boolean = false;

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhSolicitacoesFaltasService,
		public entity: IFalta,
		public NewToaster: any,
		public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
		public MeurhAcompanhamentoService: MeurhAcompanhamentoService,
		public MeurhAnexosService: MeurhAnexosService,
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public ModalConfirmService: any,
        public $q: angular.IQService,
        public $transitions: any
	) {
		$scope.$watch('mrh_slctcsflts_frm_cntrllr.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.$setDirty();
			}
		}, true);

		this.$scope.$watch('mrh_slctcsflts_frm_edit_cntrllr.entity', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
        }, true);

		this.onSubmitSuccess();
		this.onSubmitError();
		this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
	}


	$onInit(): void {
		this.entity.datas = this.entity.data;
		this.carregaAcompanhamento();
		this.entityService.get(this.$stateParams['solicitacao']);
		this.documentosAnexos();

		if (this.entity.justificada !== null) {
			this.entity.justificadaSelect = this.entity.justificada ? '1' : '0';
		}

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});
	}

	documentosAnexos(): void {
        const estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
        this.setEntityAnexo({
            solicitacao: this.entity.solicitacao,
            estabelecimento
        });
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.carregaTiposDocumentosRequeridos({
                empresa: this.entity.empresaobj.empresa,
                estabelecimento
            });
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
	}

	carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.FALTA,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
	}

	carregaAcompanhamento(): void {
        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.loadParams.finished = false;
        this.MeurhAcompanhamentoService.loadParams.to_load = 3;
        this.MeurhAcompanhamentoService.load();
    }

	resetTiposDocumentosRequeridosService(): void {
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
		this.TiposDocumentosRequeridosService.entities = [];
	}

	setEntityAnexo(params: { solicitacao: string, estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
        this.entityAnexo['solicitacao'] = params.solicitacao;
    }

	goToShow(): void {
		this.busy = true;
		this.$state.go('meurh_solicitacoesfaltas_show', angular.extend({}, {
			'solicitacao': this.$stateParams['solicitacao'],
			'estabelecimento': this.$stateParams['estabelecimento']
		}));
	}

	submit(): void {
		if (this.entity.datas === '' || this.entity.datas === undefined || this.entity.datas.length === 0) {
			this.form.datas.$setValidity('required', false);
		}

		let podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
			return (
				tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
				tipodocumentorequerido.fileSelecionado
			);
		});

		if (this.form.$valid && !this.entity.$$__submitting && podeSalvar) {
			let oldDate = this.entity.datas;
			this.entity.datas = [this.entity.datas];
			this.busy = true;
			this.allowRefresh = false;
			this.entityService.save(this.entity, {
                empresa: this.entity.empresaobj.empresa,
                estabelecimento: this.entity.estabelecimentoobj.estabelecimento
            });
			this.entity.datas = oldDate;
		} else {
			if (!podeSalvar) {
				this.NewToaster.pop({
					type: 'error',
					title: 'Há documento(s) obrigatório(s) não informado(s)'
				});
			} else {
				this.NewToaster.pop({
					type: 'error',
					title: 'Ocorreu um erro ao enviar a Solicitação de Falta!',
					body: 'Por favor, verifique os campos destacados em vermelho.'
				});
			}
		}
	}

	formInvalid(): boolean {
		let datasVazias =  (this.entity.datas === undefined || this.entity.datas === null || this.entity.datas === '') ? true : false;
		return !this.form.$valid || datasVazias;
	}

	onSubmitSuccess(): void {
		this.$scope.$on('meurh_solicitacoesfaltas_submitted', (event: angular.IAngularEvent, args: any): void => {
			this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;

			this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', { 'solicitacaohistorico': args.response.data.solicitacaohistorico });
			const insertSuccess = 'A Solicitação de Falta foi enviada com sucesso!';
			const updateSuccess = 'A Solicitação de Falta foi editada com sucesso!';
			if (this.tiposdocumentosrequeridos.length <= 0 || !this.alterouanexo) {
				this.NewToaster.pop({
					type: 'success',
					title: args.response.config.method === 'PUT' ? updateSuccess : insertSuccess
				});
				if (args.response.config.method !== 'PUT') {
					this.entityService.constructors.solicitacao = args.entity.solicitacao;
				}
				this.$state.go('meurh_solicitacoesfaltas_show', angular.extend({}, {
						'solicitacao': this.$stateParams['solicitacao'],
						'estabelecimento': this.$stateParams['estabelecimento']
					})
				);
			} else {
				this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
					this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
					if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
						this.NewToaster.pop({
							type: 'success',
							title: args.response.config.method === 'PUT' ? updateSuccess : insertSuccess
						});
						if (args.response.config.method !== 'PUT') {
							this.entityService.constructors.solicitacao = args.entity.solicitacao;
						}
						this.$state.go('meurh_solicitacoesfaltas_show', angular.extend({}, {
								'solicitacao': this.$stateParams['solicitacao'],
								'estabelecimento': this.$stateParams['estabelecimento']
							})
						);
					}
				});
			}
		});
	}

	onSubmitError(): void {
		this.$scope.$on('meurh_solicitacoesfaltas_submit_error', (event: angular.IAngularEvent, args: any): void => {
			if (args.response.status === 409) {
				if (confirm(args.response.data.message)) {
					this.entity[''] = args.response.data.entity[''];
					this.entityService.save(this.entity, {
						empresa: this.entity.empresaobj.empresa,
						estabelecimento: this.entity.estabelecimentoobj.estabelecimento
					});
				}
			} else {
				if (
					args.response.data &&
					typeof (args.response.data.message) !== 'undefined' &&
					args.response.data.message
				) {
					if (args.response.data.message === 'Validation Failed') {
						let message = '';
						let children = args.response.data.errors.children;
						for (let child in children) {
							if (children[child].errors !== undefined) {
								for (let error in children[child].errors) {
									if (children[child].errors.hasOwnProperty(error)) {
										message += '<li>' + children[child].errors[error] + '</li>';
									}
								}
							}
						}
						this.NewToaster.pop({
							type: 'error',
							title: 'Erro de Validação',
							body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
							bodyOutputType: 'trustedHtml'
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: args.response.data.message
						});
					}
				} else {
					this.NewToaster.pop({
						type: 'error',
						title: args.response.config.method === 'PUT' ? 'Ocorreu um erro ao editar a Solicitação de Falta!' : 'Ocorreu um erro ao enviar a Solicitação de Falta!',
						body: 'Por favor, verifique os campos em vermelho.'
					});
				}
			}
		});
	}

	buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.goToShow();
            }).catch((error: any): void => {/**/});
        } else {
            this.goToShow();
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                }).catch((error: any): void => {/**/});
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (
				trans._targetState._identifier !== 'meurh_meurh_solicitacoesfaltas_edit' &&
				this.allowRefresh &&
				this.formchanged
			) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                }).catch(function () {/**/});
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
