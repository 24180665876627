import angular from 'angular';

import { DepartamentoPessoalService } from './departamentopessoal.service';
import { DepartamentoPessoalIndexController } from './index/departamentopessoal.index.controller';
import { DepartamentoPessoalListRouting } from './departamentopessoal.routes';
import { ModalCardsService } from './modalcards/modalcards.service';
import { ModalCardsController } from './modalcards/modalcards.controller';

export const DepartamentoPessoalModule = angular.module('DepartamentoPessoalModule', ['ui.router.state', ])
    .controller('DepartamentoPessoalIndexController', DepartamentoPessoalIndexController)
    .controller('ModalCardsController', ModalCardsController)
    .service('DepartamentoPessoalService', DepartamentoPessoalService)
    .service('ModalCardsService', ModalCardsService)
    .config(DepartamentoPessoalListRouting)
    .name;
