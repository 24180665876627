import angular from 'angular';
import { MeurhApontamentosFormController } from './apontamentos.form.controller';

export class MeurhApontamentosFormComponent implements angular.IComponentOptions {
    static selector = 'meurhApontamentosFormComponent';
    static controller = MeurhApontamentosFormController;
    static template = require('!!html-loader!./apontamentos.form.html');
    static transclude = true;
    static bindings = {
        entity: '<',
        form: '<',
        busy: '=',
        action: '<'
    };
}
