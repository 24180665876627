import angular from 'angular';

import { TrabalhadoresListController } from './index/trabalhadores.index.controller';
import { TrabalhadoresFormController } from './edit/trabalhadores.edit';
import { MeurhRendimentosModalController } from './modal/rendimentos/rendimentos.modal.controller';
import { ModalGestorInternoController } from './components/gestoresTrabalhadores/modalGestorInterno/modalGestorInterno.controller';
import { ModalGestorExternoController } from './components/gestoresTrabalhadores/modalGestorExterno/modalGestorExterno.controller';
import { ModalAjustarRetornoController } from './components/afastamento/modalAjustarRetorno/modalAjustarRetorno.controller';
import { ModalVisualizarAjustarRetornoController } from './components/afastamento/modalVisualizarAjusteRetorno/modalVisualizarAjustarRetorno.controller';

import { TrabalhadoresService } from './trabalhadores.service';
import { MeurhRendimentosModalService } from './modal/rendimentos/rendimentos.modal.service';
import { ModalGestorExternoService } from './components/gestoresTrabalhadores/modalGestorExterno/modalGestorExterno.service';
import { ModalGestorInternoService } from './components/gestoresTrabalhadores/modalGestorInterno/modalGestorInterno.service';
import { TrabalhadoresNewService } from './trabalhadores-new.service';
import { ModalAjustarRetornoService } from './components/afastamento/modalAjustarRetorno/modalAjustarRetorno.service';
import { ModalVisualizarAjustarRetornoService } from './components/afastamento/modalVisualizarAjusteRetorno/modalVisualizarAjustarRetorno.service';

import { MeurhGestoresTrabalhadoresComponent } from './components/gestoresTrabalhadores/gestoresTrabalhadores.component';

import { TrabalhadoresListRouting } from './trabalhadores.routes';

export const TrabalhadoresModule = angular.module('TrabalhadoresModule', ['ui.router.state'])
    .controller('TrabalhadoresListController', TrabalhadoresListController)
    .controller('TrabalhadoresFormController', TrabalhadoresFormController)
    .controller('MeurhRendimentosModalController', MeurhRendimentosModalController)
    .controller('ModalGestorInternoController', ModalGestorInternoController)
    .controller('ModalGestorExternoController', ModalGestorExternoController)
    .controller('ModalAjustarRetornoController', ModalAjustarRetornoController)
    .controller('ModalVisualizarAjustarRetornoController', ModalVisualizarAjustarRetornoController)
    .service('TrabalhadoresService', TrabalhadoresService)
    .service('MeurhRendimentosModalService', MeurhRendimentosModalService)
    .service('ModalGestorInternoService', ModalGestorInternoService)
    .service('ModalGestorExternoService', ModalGestorExternoService)
    .service('ModalAjustarRetornoService', ModalAjustarRetornoService)
    .service('TrabalhadoresNewService', TrabalhadoresNewService)
    .service('ModalVisualizarAjustarRetornoService', ModalVisualizarAjustarRetornoService)
    .component('meurhGestoresTrabalhadoresComponent', MeurhGestoresTrabalhadoresComponent)
    .config(TrabalhadoresListRouting)
    .name;
