import angular from 'angular';
import moment from 'moment';
import { MeurhAcompanhamentoService } from '../../../Meurh/Acompanhamento/acompanhamento.service';
import { MeurhSolicitacoes } from '../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { MeurhSolicitacoespromocoesService } from '../promocoes.service';
import { ISolicitacao } from '../../../Meurh/Solicitacoes/models/solicitacao.model';

export class MeurhPromocoesShowController implements angular.IController {
    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'NewToaster', 'MeurhSolicitacoespromocoesService', 'MeurhAcompanhamentoService', 'WorkflowService', 'ModalConfirmService', 'MeurhSolicitacoes'];

    public busy: boolean = false;
    public entity: any = {};
    public path: string = this.$stateParams.path;

    // documentos anexos
    public tiposdocumentosrequeridos: Array<any>;
    public anexosCarregados: boolean = false;

    constructor(
        public $rootScope: angular.IRootScopeService & { modoGestorPermissao: (arg: string) => boolean, temPermissao: (arg: string) => boolean, nsjGlobals: any, usuario: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: any,
        public MeurhSolicitacoespromocoesService: MeurhSolicitacoespromocoesService,
        public MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        public WorkflowService: WorkflowService,
        public ModalConfirmService: any,
        public MeurhSolicitacoes: MeurhSolicitacoes,
    ) {
        this.busy = true;
        this.MeurhSolicitacoespromocoesService.get(this.$stateParams.solicitacao);
        $scope.$on('meurh_solicitacoespromocoes_getloaded', (event: angular.IAngularEvent, args: any): void => {
            this.entity = args;
            if (this.$stateParams['showMode'] === 'full') {
                this.carregaAcompanhamento();
            }
            this.alteraEntitySolicitacao();
            this.busy = false;
        });
    }

    $onInit(): void {
        this.onSalvarSuccess();
        this.onSalvarError();
        this.onExcluirSuccess();
        this.onExcluirError();
        this.afterReloadWorkflow();
        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoespromocoes_');

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', (event: angular.IAngularEvent, args: any): void => {
            this.tiposdocumentosrequeridos = args;
            this.anexosCarregados = true;
        });
    }

    afterReloadWorkflow(): void {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
          this.$state.reload();
        });
    }

    carregaAcompanhamento(): void {
        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.loadParams.finished = false;
        this.MeurhAcompanhamentoService.loadParams.to_load = 3;
        this.MeurhAcompanhamentoService.load();
    }

    isBusy(): boolean {
        return this.busy;
    }

    alteraEntitySolicitacao(): void {
        this.entity.dataFormatted = moment(this.entity.data).format('DD/MM/YYYY');
        this.entity.percentualCampo = parseFloat(this.entity.percentual) / 100;
        this.entity.aumentosalarial = (this.entity.salarioantigo * this.entity.percentual) / 100;
    }

    salvar(): void {
        this.busy = true;
        this.constroiEntitySolicitacao();
    }

    constroiEntitySolicitacao(): void {
        let entitySolicitacaoEnviar: any = {};
        entitySolicitacaoEnviar.solicitacao = this.entity.solicitacao;
        entitySolicitacaoEnviar.situacao = 0;
        entitySolicitacaoEnviar.trabalhador = {};
        entitySolicitacaoEnviar.trabalhador.trabalhador = this.entity.trabalhador.trabalhador;
        entitySolicitacaoEnviar.estabelecimento = this.entity.estabelecimento.estabelecimento === undefined ? this.entity.estabelecimento : this.entity.estabelecimento.estabelecimento;
        entitySolicitacaoEnviar.data = this.entity.data;
        entitySolicitacaoEnviar.tipopromocao = this.entity.tipopromocao;
        entitySolicitacaoEnviar.descricaopromocao = this.entity.descricaopromocao;
        entitySolicitacaoEnviar.cargo = this.entity.cargo.cargo;
        entitySolicitacaoEnviar.nivelcargo = this.entity.nivelcargo.nivelcargo;
        entitySolicitacaoEnviar.salario = this.entity.salario;
        entitySolicitacaoEnviar.bateponto = this.entity.bateponto;
        entitySolicitacaoEnviar.tipoaumento = this.entity.tipoaumento;
        entitySolicitacaoEnviar.percentual = this.entity.percentual;
        entitySolicitacaoEnviar.valoradiconalsalario = this.entity.aumentosalarial;
        entitySolicitacaoEnviar.unidadesalarionovo = this.entity.unidadesalarionovo;
        entitySolicitacaoEnviar.unidadesalarioantigo = this.entity.unidadesalarioantigo;
        entitySolicitacaoEnviar.descricaounidadesalarionovo = this.entity.descricaounidadesalarionovo;
        entitySolicitacaoEnviar.descricaounidadesalarioantigo = this.entity.descricaounidadesalarioantigo;
        entitySolicitacaoEnviar.mudouhorario = false; // a ser implementado.
        // constructors
        entitySolicitacaoEnviar.constructors = {};
        entitySolicitacaoEnviar.constructors.grupoempresarial = this.$rootScope.nsjGlobals.a.grupoempresarial;
        entitySolicitacaoEnviar.constructors.tenant = this.$rootScope.nsjGlobals.a.tenant;

        this.MeurhSolicitacoespromocoesService.save(entitySolicitacaoEnviar, {
            empresa: this.entity.empresaobj.empresa,
            estabelecimento: this.entity.estabelecimentoobj.estabelecimento
        });
    }

    onSalvarSuccess(): void {
        this.$scope.$on('meurh_solicitacoespromocoes_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação de promoção enviada com sucesso.'
            });
            let route = this.$stateParams['showMode'] === 'full' ? 'promocoes_show' : 'promocoes_showbuiltin';
            this.$state.go(route, {'solicitacao': args.entity.solicitacao}, {reload: true});
        });
    }

    onSalvarError(): void {
        this.$scope.$on('meurh_solicitacoespromocoes_submit_error', (): void => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao enviar a solicitação de promoção.'
            });
        });
    }

    editar(): void {
        this.$state.go('promocoes', { 'trabalhador': this.entity.trabalhador.trabalhador, 'solicitacao': this.entity.solicitacao, 'passozeroativo': 0 });
    }

    excluir(): void {
        let customText = {
            title: 'Excluir',
            subtitle: 'Após a exclusão, o registro deixará de existir até para consultas e não será possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir a solicitação de promoção de ',
            confirmTextNum: this.entity.trabalhador.nome,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir',
        };
        let confirm = this.ModalConfirmService.open(this.entity, 'solicitação de promoção', true, customText);
        confirm.result
            .then(() => {
                this.busy = true;
                this.MeurhSolicitacoespromocoesService.delete(this.entity.solicitacao, true);
            })
            .catch(function () {/**/});
    }

    onExcluirSuccess(): void {
        this.$scope.$on('meurh_solicitacoespromocoes_deleted', (): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação de promoção excluída com sucesso.'
            });
            if (this.$stateParams['showMode'] === 'full') {
                this.$state.go('trabalhadores_show', {'trabalhador': this.entity.trabalhador.trabalhador});
            } else {
                this.$state.go('promocoes_index', {}, {reload: true});
            }
        });
    }

    onExcluirError(): void {
        this.$scope.$on('meurh_solicitacoespromocoes_delete_error', (): void => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao excluir a solicitação de promoção.'
            });
        });
    }

    cancelar(): void {
        let confirm = this.ModalConfirmService.openCancelar(this.entity, 'solicitação de promoção');
        confirm.result
            .then((entity: ISolicitacao): void => {
                this.busy = true;

                this.MeurhSolicitacoes.cancelar(entity)
                    .then((): void => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A solicitação de promoção foi cancelada com sucesso!'
                        });
                        this.$state.go('promocoes_index', {}, {reload: true});
                    })
                    .catch((response: any): void => {
                        if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                            this.NewToaster.pop({
                                type: 'error',
                                title: response.data.message
                            });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Ocorreu um erro ao cancelar a solicitação de promoção!',
                                body: 'Por favor, verifique os campos em vermelho.'
                            });
                        }
                    })
                    .finally((): void => {
                        this.busy = false;
                    });
            })
            .catch((error: any): void => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    // verifica se todos os anexos obrigatórios foram enviados
    documentosAnexosValidos(): boolean {

        if (!this.anexosCarregados) {
            return false;
        }

        if (!this.tiposdocumentosrequeridos) {
            return true;
        }

        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
        });
    }

}
