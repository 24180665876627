import angular = require('angular');
import { CargosService } from 'modules/Cargos/cargos.service';
import moment = require('moment');
import { ISolicitacaoAdmissao } from '../models/solicitacaoadmissao.model';

export class MeurhSolicitacoesadmissoesFormController implements ng.IController {
    /*
     * @todo validar data de nascimento
     * @todo desenvolver o then e o catch da função showDocumento e remover o next-line:no-empty
     */
    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'CargosService'];

    public idCount: number = 0;
    public entity: ISolicitacaoAdmissao;
    public form: angular.IFormController;
    public lookupTrabalhadores: boolean;
    public primeiroLookupTrabalhadoresCarregado: boolean;
    public lookupPersonaDepartamentos: boolean;
    public primeiroLookupPersonaDepartamentosCarregado: boolean;
    public lookupCargos: boolean;
    public primeiroLookupCargosCarregado: boolean;
    public lookupHorarios: boolean;
    public primeiroLookupHorariosCarregado: boolean;
    public anoPermitido: moment.Moment = moment().subtract(14, 'years');
    public dataNascimentoInvalida: boolean;
    public dataAdmissaoInvalida: boolean;
    public estabelecimento: string;
    public dataAtual: string;
    public empresa: string;
    public carregaHorarios: boolean = false;
    public piso: number | string = 0;
    public mensagemPiso: string = '';
    public salarioValido: boolean;
    public parametrosSession: any;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public CargosService: CargosService
    ) {
        this.onCtrlEntity();
        this.lookupTrabalhadores = false;
        this.primeiroLookupTrabalhadoresCarregado = false;
        this.dataNascimentoInvalida = false;
        this.dataAdmissaoInvalida = false;
        this.anoPermitido = moment().subtract(14, 'years');

        this.carregaClientSession();

        this.dataAtual = $rootScope.configuracoes['PRAZO_ADMISSAO'];

        if (this.dataAtual === '' || this.dataAtual == null) {
            this.dataAtual = moment().format('YYYY-MM-DD');
        }

        $rootScope.$on('Trabalhadores_loading', (event: angular.IAngularEvent, args: any): void => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        this.$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
            if ((newValue !== oldValue) && this.form) {
                let anoPermitido = moment().subtract(14, 'years');

                if (newValue.datanascimento > anoPermitido.format('YYYY-MM-DD')) {
                    this.form.datanascimento.$setValidity('dataNascimentoInvalida', false);
                } else {
                    this.form.datanascimento.$setValidity('dataNascimentoInvalida', true);
                }

                let dataAtual = $rootScope.configuracoes['PRAZO_ADMISSAO'];

                if (dataAtual === '' || dataAtual == null) {
                    dataAtual = moment().format('YYYY-MM-DD');
                }

                if (newValue.dataadmissao < dataAtual) {
                    this.form.dataadmissao.$setValidity('dataAdmissaoInvalida', false);
                } else {
                    this.form.dataadmissao.$setValidity('dataAdmissaoInvalida', true);
                }

                this.form.$setDirty();
            }
        }, true);

        $rootScope.$on('Trabalhadores_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.lookupTrabalhadores = false;
        });

        this.lookupPersonaDepartamentos = false;
        this.primeiroLookupPersonaDepartamentosCarregado = false;
        $rootScope.$on('PersonaDepartamentos_loading', (event: angular.IAngularEvent, args: any): void => {
            if (!this.primeiroLookupPersonaDepartamentosCarregado) {
                this.lookupPersonaDepartamentos = true;
                this.primeiroLookupPersonaDepartamentosCarregado = true;
            }
        });

        $rootScope.$on('PersonaDepartamentos_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.lookupPersonaDepartamentos = false;
        });

        this.lookupCargos = false;
        this.primeiroLookupCargosCarregado = false;
        $rootScope.$on('Cargos_loading', (event: angular.IAngularEvent, args: any): void => {
            if (!this.primeiroLookupCargosCarregado) {
                this.lookupCargos = true;
                this.primeiroLookupCargosCarregado = true;
            }
        });

        $rootScope.$on('Cargos_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.lookupCargos = false;
        });

        this.lookupHorarios = false;
        this.primeiroLookupHorariosCarregado = false;
        $rootScope.$on('Horarios_loading', (event: angular.IAngularEvent, args: any): void => {
            if (!this.primeiroLookupHorariosCarregado) {
                this.lookupHorarios = true;
                this.primeiroLookupHorariosCarregado = true;
            }
        });

        $rootScope.$on('Horarios_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.lookupHorarios = false;
        });
    }

    $onInit(): void {
        if (this.entity.situacao !== -1) {
            this.empresa = this.$rootScope.session.entities.empresa.empresa;
            this.carregaHorarios = true;
        }

        this.verificaCargoParaPiso();
    }

    verificaPiso(): void {
        this.entity.cargo.pisominimo = (this.entity.cargo.pisominimo === '' || this.entity.cargo.pisominimo === null || this.entity.cargo.pisominimo === undefined) ? 0 : this.entity.cargo.pisominimo;
        this.entity.cargo.sindicato.pisosalarial = (this.entity.cargo.sindicato.pisosalarial === '' || this.entity.cargo.sindicato.pisosalarial === null || this.entity.cargo.sindicato.pisosalarial === undefined) ? 0 : this.entity.cargo.sindicato.pisosalarial;

        if (this.entity.cargo.pisominimo > this.entity.cargo.sindicato.pisosalarial) {
            this.piso = this.entity.cargo.pisominimo;
            this.mensagemPiso = 'O salário não pode ser menor que o piso do Cargo: ';
        } else {
            this.piso = this.entity.cargo.sindicato.pisosalarial;
            this.mensagemPiso = 'O salário não pode ser menor que o piso Sindical: ';
        }
    }

    verificaEstabelecimento() {
        this.estabelecimento = this.estabelecimento ? this.estabelecimento : this.entity.estabelecimento;
        return this.estabelecimento;
    }

    verificaCargoParaPiso(): void {
        if (this.entity.cargo !== null && this.entity.cargo !== undefined) {
            if (this.entity.cargo.pisominimo) {
                this.verificaPiso();
            } else {
                this.CargosService.get(this.entity.cargo.cargo)
                    .then((response: any): void => {
                        let cargoCompleto = response;

                        this.entity.cargo.pisominimo = cargoCompleto.pisominimo;
                        this.entity.cargo.sindicato = {};
                        this.entity.cargo.sindicato.pisosalarial = cargoCompleto.sindicato ? cargoCompleto.sindicato.pisosalarial : null;

                        this.verificaPiso();
                    })
                    .catch((e: any): void => {
                        console.log(e);
                    });
            }
        }
    }

    carregaClientSession(): void {
        this.$rootScope.$on('meurh_clientsession_session_loaded_service', (event: angular.IAngularEvent, args: any): void => {
            if (!angular.isUndefined(args.empresa)) {
                this.parametrosSession = args;
                this.empresa = this.parametrosSession.empresa.empresa;
                this.estabelecimento = this.parametrosSession.estabelecimento.estabelecimento;
                this.carregaHorarios = true;
            }
        });
    }

    verificacaoCargo(): { cargo: string } | {} {
        if (this.entity.cargo) {
            if (this.entity.cargo.cargo) {
                return { 'cargo': this.entity.cargo.cargo };
            }
        }

        return {};
    }

    carregandoLookups(): boolean {
        return this.lookupTrabalhadores || this.lookupPersonaDepartamentos || this.lookupCargos || this.lookupHorarios;
    }

    prazoAdmissao(): string {
        return this.$rootScope.configuracoes['PRAZO_ADMISSAO'];
    }

    alteraNivelcargo(): void {
        if (this.entity.nivelcargo) {
            this.entity.salariofixo = parseFloat(this.entity.nivelcargo.valorsalario);
        }
    }

    alteraCargo(): void {
        if (this.entity.cargo) {
            this.$rootScope.$broadcast('nivelcargo_refresh', { cargo: this.entity.cargo.cargo });
            this.entity.nivelcargo = null;
        }

        this.verificaCargoParaPiso();
    }

    onCtrlEntity(): void {
        this.$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
            if ((newValue !== oldValue) && this.form) {
                this.form.$setDirty();
            }
        }, true);
    }

    // meurhSolicitacoesadmissoesdocumentosForm() {
    //     var modal = this.MeurhSolicitacoesadmissoesdocumentosFormService.open({}, {});
    //     modal.result.then((subentity: any) => {
    //         subentity.$id = this.idCount++;
    //         if (this.entity.solicitacoesadmissoesdocumentos === undefined) {
    //             this.entity.solicitacoesadmissoesdocumentos = [subentity];
    //         } else {
    //             this.entity.solicitacoesadmissoesdocumentos.push(subentity);
    //         }
    //     })
    //         .catch((error: any) => {
    //             if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
    //                 this.NewToaster.pop({
    //                     type: 'error',
    //                     title: error
    //                 });
    //             }
    //         });
    // }

    // previewDocumento(documento: any) {
    //     var modal = this.MeurhSolicitacoesadmissoesdocumentosVisualizarService.open(documento);
    //     // tslint:disable-next-line:no-empty
    //     modal.result.then(function () { }).catch(function () { });
    // }

    trustUrl(subentityURL: any): string {
        let url = this.$sce.trustAsResourceUrl(subentityURL);
        return url;
    }

    getSalariofixo(event: angular.IAngularEvent & { detail: any }) {
        this.entity.salariofixo = event.detail.value;
        this.entity.erroSalariofixo = event.detail.error;
        this.salarioValido = !event.detail.error;
    }
}
