import * as angular from 'angular';
import { MeurhCreditosdescontosPassotresController } from './creditosdescontos.passotres.controller';


export class MeurhCreditosdescontosPassotresComponent implements angular.IComponentOptions {
  static selector = 'meurhCreditosdescontosPassotresComponent';
  static controller = MeurhCreditosdescontosPassotresController;
  static template = require('!!html-loader!./creditosdescontos.passotres.html');
  static transclude = true;
  static bindings = {
    passo: '=',
    entity: '=',
    entityDadosCriacao: '=',
    mensagensPassos: '=',
    entitiesCreditosDescontos: '=',
    selecionadosCreditosDescontos: '=',
    passoUmAtivo: '<',
    colaboradorSelecionado: '=',
  };
}
