export class MeurhEnvioFeriasModalService {
    static $inject = ['$uibModal'];

    constructor(
        public $uibModal: any
    ) { }

    open(entities: any): any {
        return this.$uibModal.open({
            template: require('html-loader!./envios.modal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                'NewToaster',
                function MeurhEnviosFeriasModal(
                    entities: any,
                    $uibModalInstance: any,
                    NewToaster: any
                ): void {
                    let selfModal = this;

                    selfModal.NewToaster = NewToaster;
                    selfModal.entities = entities;
                    selfModal.numSuccess = entities.filter((element: any) => element.erro === false).length;
                    if (selfModal.numSuccess > 0) {
                        selfModal.infoText = 'Apenas ' + selfModal.numSuccess + ' de ' + selfModal.entities.length + ' férias foram marcadas com sucesso.';
                    } else {
                        selfModal.infoText = 'Nenhuma das férias foram marcadas com sucesso.';
                    }

                    selfModal.entities.forEach(element => {
                        element['rowcustomclass'] = element.erro ? 'linha-vermelha' : '';
                    });

                    selfModal.fields = [
                        {
                            value: 'nome',
                            label: 'Colaborador',
                            type: 'string',
                            style: 'title',
                            copy: '',
                        },
                        {
                            value: 'saldo',
                            label: 'Saldo disponível',
                            type: 'integer',
                            style: 'default',
                            copy: '',
                        },
                        {
                            value: 'saldoTotal',
                            label: 'Saldo total',
                            type: 'integer',
                            style: 'default',
                            copy: ''
                        },
                        {
                            value: 'datalimitegozo',
                            label: 'Limite para a dobra',
                            type: 'date',
                            style: 'title',
                            copy: '',
                        },
                        {
                            value: 'descricaoerro',
                            label: 'Erro(s)',
                            type: 'string',
                            style: 'title',
                            copy: '',
                        },
                        {
                            value: 'erro',
                            label: 'Situação',
                            type: 'string',
                            style: 'title',
                            copy: '',
                            customCollumnHtml: (): string => {
                                let someHtmlVar = `
                                <span ng-if="!tableRowEntity.erro">
                                    <i style="color: #30C01E;" class="fas fa-check-circle"></i>
                                </span>
                                <span ng-if="tableRowEntity.erro">
                                    <i style="color: #C82333;" class="fas fa-times-circle"></i>
                                </span>`;
                                return someHtmlVar;
                            },
                        },
                    ];

                    selfModal.submit = (): void => {
                        $uibModalInstance.close(selfModal.entities);
                    };

                    selfModal.close = (): void => {
                        $uibModalInstance.dismiss(selfModal.infoText);
                    };

                    selfModal.getRowCustomClass = (entity): string => {
                        return entity.rowcustomclass;
                    };

                }
            ],
            controllerAs: '$ctrl',
            windowClass: 'nsj-modal-lg-no-height',
            backdrop: 'static',
            resolve: {
                entity: (): any => {
                    return entities;
                }
            }
        });
    }
}
