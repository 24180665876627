import { IRendimento } from '../../models/rendimentos.model';

export class MeurhRendimentosModalController {
    static $inject = ['$uibModalInstance', 'entity'];

    constructor(
        public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        public entity: { rendimentos: Array<IRendimento>, total: string }
    ) {}

    close(): void {
        this.$uibModalInstance.close();
    }
}
