export class ModalConfirmController {
    static $inject = ['entity', '$uibModalInstance', 'tipo', 'excluir', 'textoPersonalizado'];

    public textnum = this.entity ? (this.entity.trabalhador ? this.entity.trabalhador.nome : '') : null;

    // texto default para solicitações diversas
    public textos: any = {
        title: 'Cancelar',
        subtitle: 'Você está prestes a cancelar a ' + this.tipo + ' de',
        confirmText: 'Após o cancelamento, não será possível desfazer. Deseja continuar?',
        confirmTextNum: this.textnum,
        closeButton: 'Voltar',
        confirmButton: 'Cancelar solicitação',
    };

    constructor(
        public entity: any,
        public $uibModalInstance: any,
        public tipo: any,
        public excluir: any,
        public textoPersonalizado: any
    ) {
        if (excluir) {
            this.textos = {
                title: 'Excluir',
                confirmText: 'Você tem certeza que deseja excluir a ' + tipo,
                confirmTextNum: '#' + entity.codigo,
                closeButton: 'Cancelar',
                confirmButton: 'Sim, excluir',
            };
        }

        // texto personalizado
        if (textoPersonalizado) {
            this.textos = textoPersonalizado;
        }
    }

    confirmar() {
        this.$uibModalInstance.close(this.entity);
    }

    close() {
        this.$uibModalInstance.dismiss('cancelar');
    }
}
