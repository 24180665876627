export class ApontamentosModalConfirmacaoService {
    static $inject = ['$uibModal', '$http', '$q', 'nsjRouting'];

    constructor(public $uibModal: any, public $http: any, public $q: any, public nsjRouting: any) { }

    open(entity: any, movimentosTipoSempre: any, semMovimentos: any, SITUACAO_PENDENTE: any, busy: any): any {
        return this.$uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('html-loader!./modal.confirmacao.html'),
            controller: 'ApontamentosModalConfirmacaoController',
            resolve: {
                movimentosTipoSempre: () => {
                    if (movimentosTipoSempre) {
                        return movimentosTipoSempre;
                    }
                    busy = true;
                    let url = this.nsjRouting.generate('apontamentos_movimentos_sempre', {}, true);
                    return this.$q((resolve: any, reject: any) => {
                        this.$http.get(url)
                            .then((response: any) => {
                                movimentosTipoSempre = response.data;
                                resolve(movimentosTipoSempre);
                            })
                            .catch((response: any) => {
                                // todo
                            }).finally(() => {
                                busy = false;
                            });
                    });
                },

                entity: (): any => entity,
                semMovimentos: (): any => semMovimentos,
                SITUACAO_PENDENTE: (): any => SITUACAO_PENDENTE,
                busy: (): any => busy,

            },
            controllerAs: '$ctrl',
            size: 'md'
        });
    }
}
