
import angular from 'angular';
import moment from 'moment';
import { MeurhSolicitacoesalteracoesvtsService } from '../solicitacoesalteracoesvts.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { ISolicitacaoAlteracaoVT } from '../models/solicitacaoalteracaovt.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhSolicitacoesalteracoesvtsIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$location',
        '$transitions',
        'nsjRouting',
        'NewToaster',
        'MeurhSolicitacoesalteracoesvtsService',
        'ListagemService'
    ];

    public busy: boolean = false;
    public filters: any = {};
    public situacao: string = '';
    public filter: any = {};
    public tipo: string;
    public tipoInicial: string;
    public dataInicial: string | null = null;
    public dataFinal: string | null = null;
    public dataAtual: string | moment.Moment;
    public constructors: any;
    public maxoccurs: object = {};
    public entities: Array<ISolicitacaoAlteracaoVT> = [];
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_alteracaovt'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public options = {
        'situacao': 'Situação',
        'estabelecimento': 'Estabelecimento'
    };
    public selects = {
        'situacao': {
            '0': 'Enviada',
            '1': 'Efetuada',
            '2': 'Cancelada',
            '3': 'Excluída',
            '4': 'Recusada'
        }
    };
    public fields = [
        {
            value: 'trabalhador.nome',
            label: 'Nome',
            type: 'string',
            style: 'default',
            copy: '',
        },

        {
            value: 'codigo',
            label: 'Código',
            type: 'integer',
            style: 'default',
            copy: '',
        },

        {
            value: 'situacao',
            label: 'Situação',
            type: 'options',
            style: 'label',
            copy: '',
            options: { 'Aberta': 'entity.situacao === "0"', 'Efetuada': 'entity.situacao === "1"', 'Cancelada': 'entity.situacao === "2"', 'Excluída': 'entity.situacao === "3"', 'Recusada': 'entity.situacao === "4"', },
            label_class: '{\'label-warning\': entity.situacao === 0, \'label-success\': entity.situacao === 1, \'label-danger\': entity.situacao === 2,}',
        },

        {
            value: 'created_at',
            label: 'Data de solicitação',
            type: 'datetime',
            style: 'default',
            copy: '',
        }
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: MeurhSolicitacoesalteracoesvtsService,
        public ListagemService: ListagemService,
    ) {
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = entityService.filters = {};
        this.montaListagemVisibilidade();
        this.filter.search = $stateParams['q'];
        this.tipo = $stateParams['tipo'];
        this.tipoInicial = $stateParams['tipo'];
        this.dataAtual = moment();
        this.constructors = entityService.constructors = [];

        if (!$rootScope.temPermissao('visualizar_alteracaovt')) {
            $state.go('dp', $stateParams);
        }

        if ($stateParams['tipo'] !== null && $stateParams['tipo'] !== '') {
            entityService.constructors['tipo'] = $stateParams['tipo'];
        }

        for (var i in $stateParams) {
            if (['situacao', 'estabelecimento'].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                entityService.filters[i] = $stateParams[i];
            } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
                entityService.constructors[i] = $stateParams[i];
            }
        }

        this.filterReload();

        $scope.$on('meurh_solicitacoesalteracoesvts_deleted', (event: angular.IAngularEvent): void => {
            entityService.reload();
        });


        $scope.$on('$destroy', (): void => {
            if (entityService.loading_deferred) {
                entityService.loading_deferred.resolve();
            }
        });

        $rootScope.$on('meurh_solicitacoesalteracoesvts_submitted', (event: angular.IAngularEvent, args: any): void => {
            if (!args.autosave) {
                entityService.reload();
            }
        });
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
    }

    reload(): void {
        this.filterReload();
    }

    search(): Array<ISolicitacaoAlteracaoVT> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }

        return this.entityService.search(this.filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    filterReload(): void {
        if (this.situacao === '') {
            delete this.constructors.situacao;
        } else {
            this.constructors.situacao = this.situacao;
        }

        if (
            this.dataInicial !== null &&
            this.dataFinal !== null &&
            this.dataInicial !== '' &&
            this.dataFinal !== '' &&
            this.dataInicial > this.dataFinal
        ) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial não pode ser posterior a data final'
            });
            return;
        }

        let created_at: Array<{condition: string, value: string}> = [];
        if (
            this.dataInicial === undefined ||
            (this.dataInicial !== null && this.dataInicial.length === 0)
        ) {
            this.dataInicial = null;
            this.constructors.datainicial = null;
        }
        if (this.dataInicial !== null) {
            created_at.push({
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
            });
            delete this.constructors.datainicial;
        }
        if (
            this.dataFinal === undefined ||
            (this.dataFinal !== null && this.dataFinal.length === 0)
        ) {
            this.dataFinal = null;
            this.constructors.datafinal = null;
        }
        if (this.dataFinal !== null) {
            created_at.push({
                condition: 'lte',
                value: this.dataFinal + ' 23:59:00'
            });
            delete this.constructors.datafinal;
        }

        this.constructors.created_at = created_at;
        this.entityService.constructors = this.constructors;
        this.entities = this.entityService.reload();
    }

    filtraTipo(): void {
        if (this.entityService.constructors.hasOwnProperty('trabalhador_tipo') && (this.tipo === '' || this.tipo === '-1')) {
            delete this.entityService.constructors.trabalhador_tipo;
            this.entities = this.entityService.search();
        } else {
            this.entityService.constructors.trabalhador_tipo = this.tipo;
            this.entities = this.entityService.search();
        }
    }

    generateRoute(route: string, params: any): void {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }
}
