import * as angular from 'angular';
import { MeurhAdmissoesestagiarioFotoFormController } from './foto.form.controller';

export class MeurhAdmissoesestagiarioFotoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioFotoFormComponent';
	static controller = MeurhAdmissoesestagiarioFotoFormController;
	static template = require('!!html-loader!./foto.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
