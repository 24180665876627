import angular from 'angular';
import { MeurhEscalaFolgaFormController } from './folga.form.controller';

export class MeurhEscalaFolgaFormComponent implements angular.IComponentOptions {
	static selector = 'meurhEscalaFolgaFormComponent';
	static controller = MeurhEscalaFolgaFormController;
	static template = require('!!html-loader!./folga.form.html');
	static transclude = true;
	static bindings = {
        form: '<',
		entity: '=',
	};
}
