import angular from 'angular';
import moment from 'moment';
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { ITitles } from '../models/titles.model';

export class MeurhCreditosdescontosShowbuiltinController {

    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', '$location', 'NewToaster', 'MeurhCreditosdescontosService', 'ModalConfirmService'];

    public busy: boolean = false;
    public entity: Partial<ICreditoDesconto> = {};
    public titles: ITitles = {};
    public competencia = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_FOLHA, 'YYYY-MM-DD').format('MM/YYYY');
    public exibeBotaoSalvar: boolean = true;

    constructor(
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public NewToaster: any,
        public entityService: any,
        public ModalConfirmService: any
    ) {
        $scope.$on('meurh_solicitacoescreditodesconto_getloaded', (event: any, args: any) => {
            this.entity = args;
            this.alteraEntity();
            if (this.entity.situacao === -1) {
                if (this.entity.tipoperiodo === '2') {

                    if (!this.entity.ano || !this.entity.mes) {
                        this.exibeBotaoSalvar = false;
                    } else {
                        this.exibeBotaoSalvar = !(moment(`${this.entity.mes}/${this.entity.ano}`, 'MM/YYYY').isSameOrBefore(moment(this.competencia, 'MM/YYYY')));
                    }

                } else {
                    let competenciaDaSolicitacao = this.entity.mesreferencia + '/' + this.entity.anoreferencia;
                    this.exibeBotaoSalvar = !(moment(competenciaDaSolicitacao, 'MM/YYYY').isBefore(moment(this.competencia, 'MM/YYYY')));
                }

            }
            this.busy = false;
        });
    }

    $onInit() {
        if (this.entity.solicitacao === undefined) {
            this.busy = true;
            this.entityService.get(this.$stateParams.solicitacao);
        } else {
            this.busy = false;
        }

        this.onSalvarSuccess();
        this.onSalvarError();
        this.onExcluirSuccess();
        this.onExcluirError();
    }

    isBusy() {
        return this.busy;
    }

    loadMore() {
        this.entityService.loadMore();
    }

    alteraEntity() {
        this.entity.tipoperiodo = this.entity.tipoperiodo.toString();
        this.entity.tipocalculo = this.entity.tipocalculo.toString();
        this.entity.enviadoEm = this.entity.situacao === -1 ? 'Ainda não enviado' : moment(this.entity.lastupdate).format('DD/MM/YYYY');
        switch (this.entity.situacao) {
            case -1:
                this.entity.situacaoDesc = 'Rascunho';
                break;
            case 0:
                this.entity.situacaoDesc = 'Enviada';
                break;
            case 1:
                this.entity.situacaoDesc = 'Aprovada';
                break;
            case 3:
                this.entity.situacaoDesc = 'Excluída';
                break;
        }
        if (this.entity.tipoperiodo === '2' && this.entity.mesfinalperiodo && this.entity.anofinalperiodo) {
            this.entity.mes = this.entity.mesfinalperiodo.toString();
            this.entity.ano = this.entity.anofinalperiodo.toString();
        }

        this.titles.accordion = this.entity.evento.tipovalor === 0 ? 'Dados do crédito' : 'Dados do desconto';
    }

    salvar() {
        this.busy = true;
        this.constroiEntitySolicitacao();
    }

    constroiEntitySolicitacao() {
        let entityEnviar = angular.copy(this.entity);
        if (entityEnviar.evento && entityEnviar.conteudo) {
            let eventounidade = entityEnviar.evento.unidade === undefined ? entityEnviar.evento.evento_unidade : entityEnviar.evento.unidade;

            if ((eventounidade === 0 || eventounidade === 8 || eventounidade === 10) && typeof entityEnviar.conteudo === 'string') {
                entityEnviar.conteudo = parseFloat(entityEnviar.conteudo.replace(':', '.'));
            } else if (typeof entityEnviar.conteudo === 'string') {
                entityEnviar.conteudo = parseFloat(entityEnviar.conteudo);
            }
        }

        entityEnviar.situacao = 0;
        entityEnviar.constructors = {
            grupoempresarial: this.$rootScope.nsjGlobals.a.grupoempresarial,
            tenant: this.$rootScope.nsjGlobals.a.tenant,
        };
        this.entityService.save(entityEnviar);
    }

    onSalvarSuccess() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_submitted', (event: any, args: any) => {
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação de ' + (this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto') + ' foi enviada com sucesso!'
            });
            this.entity.situacao = 0;
            this.busy = false;
            this.$state.go('creditosdescontos_showbuiltin', {'solicitacao': args.entity.solicitacao});
        });
    }

    onSalvarError() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_submit_error', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao enviar a solicitação.'
            });
        });
    }

    editar() {
        this.$state.go('creditosdescontos_editbuiltin', {'trabalhador': this.entity.trabalhador.trabalhador
            , 'solicitacao': this.entity.solicitacao});
    }

    excluir() {
        let tipoText = this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto';
        let customText = {
            title: 'Excluir',
            subtitle: 'Após a exclusão, o registro deixará de existir até para consultas e não será possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir a solicitação de ' + tipoText + ' de ',
            confirmTextNum: this.entity.trabalhador.nome,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir',
        };
        let confirm = this.ModalConfirmService.open(this.entity, 'créditos e descontos', false, customText);
        confirm.result
        .then((entity: any): void => {
            this.busy = true;
            this.entityService.delete(this.entity.solicitacao, true);
        })
        .catch(function () {/**/});
    }

    onExcluirSuccess() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_deleted', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação excluída com sucesso.'
            });
            this.$state.go('creditosdescontos_index', {});
        });
    }

    onExcluirError() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_delete_error', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao excluir a solicitação.'
            });
        });
    }
}
