import angular = require('angular');
import { IControleFerias } from 'modules/Avisoferias/models/controleferias.model';
import { IFerias } from '../../models/ferias.model';

export class MeurhCalendarioFeriasModalService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: any) { }

	open(entities: Array<IControleFerias>, draftEntities: Array<IFerias>, selectedEntity?: string): void {
		return this.$uibModal.open({
			template: require('html-loader!./calendarioferias.modal.html'),
			controller: 'meurhCalendarioFeriasModalController',
			controllerAs: 'mrh_clndrsfrs_mdl_cntrllr',
			windowClass: 'modal-calendar',
			size: 'lg',
			resolve: {
				entity: async () => {
					return entities;
				},
				draftEntities: () => {
					return draftEntities;
				},
				selectedEntity: () => {
					return selectedEntity;
				}
			}
		});
	}
}
