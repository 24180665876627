import angular from 'angular';
import moment from 'moment';
import { ITransferenciaColaboradores } from '../models/transferenciacolaboradores.model';

export class MeurhTransferenciaColaboradoresShowFormController implements angular.IController {
	static $inject = ['$scope', '$rootScope'];

	public form: angular.IFormController;
	public entity: ITransferenciaColaboradores;

	public datainicio: Date;
	public datafim: Date;
	public vaga: any;
	public ativa: boolean;
	public ativaCampos: boolean;
	public estabelecimentodestino: string;
	public departamento: any;
	public lotacao: string;
	public grupodeponto: string;
	public action: string = 'show';

	constructor(
		public $scope: angular.IScope,
		public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
	) {
		$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if (this.form && (newValue !== oldValue)) {
				this.form.$setDirty();
			}
			this.datainicio = this.editaData(this.entity.datainicio);
			if (this.entity.datafim) {
				this.datafim = this.editaData(this.entity.datafim);
			}
			this.vaga = this.entity.vaga !== null ? this.entity.vaga.codigo  + ' - ' + this.entity.vaga.descricao : '';
			this.ativa = this.entity.vaga !== null ? true : false;
			this.ativaCampos = this.entity.vaga !== null ? false : true;
			this.estabelecimentodestino =  this.entity.estabelecimentodestino !== null ? this.entity.estabelecimentodestino.nomefantasia : '';
			this.departamento = this.entity.departamento !==  null ? this.entity.departamento.nome : '';
			this.lotacao = this.entity.lotacao !==  null ? this.entity.lotacao.nome : '';
			this.grupodeponto = this.entity.grupodeponto !==  null ? this.entity.grupodeponto.nome : '';
		}, true);
	}

	$onInit(): void {
		this.verificaGestores();
	}

	editaData(data: string) {
		return moment(data, 'YYYY-MM-DD').toDate();
	}
	verificaGestores(): void {
		this.entity.gestores.map(gestor => {
			if (gestor.identificacaonasajongestor) {
				gestor.nome = '';
				gestor.tipoGestor = 'Externo';
				gestor.email = gestor.identificacaonasajongestor;
			} else {
				gestor.nome = gestor.gestor!['nome'];
				gestor.tipoGestor = 'Interno';
				gestor.email = gestor.gestor!['email'];
				gestor.gestor = gestor.gestor!['trabalhador'];
			}
		});
	}
}
