
import angular from 'angular';
import { IVagas } from '../models/vagas.model';
import { MeurhVagasService } from '../vagas.service';

export class MeurhVagasNewController implements angular.IController {
    static $inject = ['$rootScope', '$scope', '$stateParams', '$state', 'MeurhVagasService', 'entity', 'NewToaster'];

    public constructors: any = {};
    public action: string = 'insert';
    public form: angular.IFormController;
    public entitySave: Partial<IVagas> = {};
    public busy: boolean = false;

    constructor(
        public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, session: any, nsjGlobals: any, filterData: any },
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhVagasService,
        public entity: IVagas,
        public NewToaster: any) {

        this.entity.unidadesalariofixo = 4;

        this.onSubmitSuccess();
        this.onSubmitError();
    }

    createEntity(): void {
        this.entitySave.ativa = this.entity.ativa;
        this.entitySave.salariofixo = this.entity.salariofixo;
        this.entitySave.unidadesalariofixo = this.entity.unidadesalariofixo;
        this.entitySave.codigo = this.entity.codigo;
        this.entitySave.descricao = this.entity.descricao;
        this.entitySave.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.entitySave.departamento = this.entity.departamento;
        this.entitySave.lotacao = this.entity.lotacao;
        this.entitySave.ambiente = this.entity.ambiente;
        this.entitySave.condicaoambientetrabalho = this.entity.condicaoambientetrabalho;
        // this.entitySave.cargo = this.entity.cargo;
        this.entitySave.nivelcargo = this.entity.nivelcargo;
        this.entitySave.sindicato = this.entity.sindicato;
        this.entitySave.horario = this.entity.horario;
        this.entitySave.grupodeponto = this.entity.grupodeponto;
        this.entitySave.beneficios = this.entity.beneficios;
        this.entitySave.vts = this.entity.vts;

        if (this.entity.gestores) {
            this.alteraGestores();
        }

        if (this.entity.unidadesalariofixo === 5 || this.entity.unidadesalariofixo === 6) {
            this.entitySave.descricaosalariovariavel = this.entity.descricaosalariovariavel;
        }
    }

    alteraGestores(): void {
        this.entitySave.gestores = this.entity.gestores.map((gestor: any) => {
            if (gestor.trabalhador) {
                return {
                    gestor: gestor.trabalhador,
                    identificacaonasajongestor: null
                };
            } else {
                return {
                    gestor: null,
                    identificacaonasajongestor: gestor.nome
                };
            }
        });
    }

    verificaObj(): boolean {
        if (this.valorExiste(this.entity.codigo) &&
            this.valorExiste(this.entity.descricao)
        ) {
            return false;
        } else {
            return true;
        }
    }

    salvar(): void {
        if (this.form.$valid && !this.entity.$$__submitting) {
            this.busy = true;
            this.createEntity();
            this.entityService.save(this.entitySave);
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros.'
            });
        }
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_vagas_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Vaga foi criada com sucesso!'
            });
            this.$state.go('meurh_vagas_show', { 'vaga': args.entity.vaga });
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_vagas_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.status === 409) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                if (args.response.data.message === 'Validation Failed') {
                    let message = args.response.data.errors.errors[0];

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de Validação',
                        body: message,
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao criar a Vaga!'
                });
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
