import moment = require('moment');
import { ISolicitacao } from '../models/solicitacao.model';

export module descricaoSolicitacaoFilter {
	export function descricaoSolicitacao($filter: any): (entity: ISolicitacao) => string {
		function formatarData(data: string): string {
			data = $filter('asDate')(data);
			data = $filter('date')(data, 'dd/MM/yyyy');

			return data;
		}

		function formatarDataMoment(data: string): string {
			data = moment(data + ' 12:00', 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY');

			return data;
		}

		function formatarMultiplasDatas(datas: Array<string> | string): Array<string> | string {
			if (!Array.isArray(datas)) {
				datas = datas.replace(/[{}]/g, '').split(',');
			}

			datas = datas.map((value: string, index: number): string => {
				return (index > 0) ? ' ' + moment(value).format('DD/MM/YYYY') : moment(value).format('DD/MM/YYYY');
			});

			return datas;
		}

		return function (entity: ISolicitacao): string {
			let descricao;

			switch (entity.tiposolicitacao) {
				case 0: // admissão preliminar
				case 12: // admissão completa
					descricao = $filter('mask')(entity.cpf, '999.999.999-99') + ' - ' + formatarData(entity.dataadmissao);
					break;
				case 1: // rescisão
					descricao = formatarData(entity.datarescisao) + ' - ' + entity.justificativa;
					break;
				case 2: // adiantamento avulso
					descricao = entity.mescompetencia + '/' + entity.anocompetencia + ' - ' + $filter('currency')(entity.valor);
					break;
				case 3: // vt adicional
					if (entity.tipoconteudo === 0) {
						descricao = 'Período: ' + '(' + formatarData(entity.datainicial) + ' - ' + formatarData(entity.datafinal) + ') : ' + entity.conteudo + ' dia(s) útil(eis)';
					} else if (entity.tipoconteudo === 1) {
						descricao = 'Período: ' + '(' + formatarData(entity.datainicial) + ' - ' + formatarData(entity.datafinal) + ') : ' + $filter('currency')(entity.conteudo) + ' real(is)';
					}

					break;
				case 4: // alteração de vt
					descricao = entity.motivo;
					break;
				case 5: // alteração de endereço
					descricao = entity.justificativa;
					break;
				case 6: // falta
					let tipo = entity.justificada ? ('Justificada - ' + entity.justificativa) : 'Injustificada';
					descricao = formatarMultiplasDatas(entity.datas) + ' - ' + tipo;
					break;
				case 7: // férias
					descricao = formatarDataMoment(entity.datainiciogozo) + ' - ' + formatarDataMoment(entity.datafimgozo);
					break;
				/*
				case 8: // salário sob demanda
					descricao = $filter('currency')(entity.valor);
					break;
				*/
				case 8: // afastamento
					descricao = entity.descricao;
					break;
				case 9: // promoções
					descricao = 'Tipo de Promoção: ';

					switch (entity.tipopromocao) {
						case 0:
							descricao += ' Mudança de Cargo ';
							break;
						case 1:
							descricao += ' Aumento de Nível do Cargo ';
							break;
						case 2:
							descricao += ' Aumento Salarial ';
							break;
					}

					descricao += ' | Data da Efetivação: ' + formatarData(entity.data);
					descricao += ' | Detalhamento: ';

					switch (entity.tipopromocao) {
						case 0:
							descricao += ' Cargo Antigo: ' + entity.cargoantigo.nome + ' Nível Antigo: ' + entity.nivelcargoantigo.codigo + ' para Cargo Novo: ' + entity.cargo.nome + ' Nível Novo: ' + entity.nivelcargo.codigo;
							break;
						case 1:
							descricao += ' Cargo Atual: ' + entity.cargoantigo.nome + ' Nível Antigo: ' + entity.nivelcargoantigo.codigo + ' para Nível Novo: ' + entity.nivelcargo.codigo;
							break;
						case 2:
							descricao += ' Salário Antigo: ' + $filter('currency')(entity.salarioantigo) + ' para Salário Novo: ' + $filter('currency')(entity.salario);
							break;
					}

					break;
				case 13: // transferencia de colaboradores
					descricao = entity.vaga.codigo + ' - ' + entity.vaga.descricao;
					break;
			}

			return descricao;
		};
	}

	descricaoSolicitacao.$inject = ['$filter'];
}
