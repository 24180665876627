import angular = require('angular');
import { MeurhVagasService } from '../vagas.service';
import { IVagas } from '../models/vagas.model';

export class MeurhVagasIndexController implements ng.IController {
    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'MeurhVagasService', '$location', 'NewToaster', 'nsjRouting'];

    public entities: Array<IVagas>;
    public filter: any = {};
    public filters: any = {};
    public constructors: any = {};
    public filterLotacao: any;
    public busy: boolean = false;

    constructor(
            public $rootScope: angular.IRootScopeService & { tipo: any, temPermissao: (arg: string) => boolean },
            public $scope: angular.IScope,
            public $state: angular.ui.IStateService,
            public $stateParams: angular.ui.IStateParamsService,
            public entityService: MeurhVagasService,
            public $location: angular.ILocationService,
            public NewToaster: any,
            public nsjRouting: any) {

        this.entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = this.entityService.filters = {};
        this.filter.search = $stateParams['q'];
        this.constructors = entityService.constructors = [];
        this.entities = this.entityService.reload();

        this.$rootScope.$on('meurh_vagas_get_loading', (event: any, args: any) => {
            this.busy = true;
        });

        this.$rootScope.$on('meurh_vagas_list_finished', (event: any, args: any) => {
            this.busy = false;
        });

        this.$rootScope.$on('meurh_vagas_loaded', (event: any, args: any) => {
            this.busy = false;
        });

        this.$scope.$on('meurh_vagas_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.entities = [];
            this.entities = this.entityService.reload();
        });

        this.$scope.$on('meurh_vagas_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.entities = [];
            this.entities = this.entityService.reload();
        });
    }

    search(): Array<IVagas> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }
        return this.entityService.search(this.filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    filterReload(): Array<IVagas> {
        delete this.constructors.lotacao;
        if (this.filterLotacao) {
            this.constructors.lotacao = this.filterLotacao.lotacao;
        }
        this.entityService.constructors = this.constructors;
        return this.entityService.reload();
    }

    reload(): void {
        this.filterReload();
    }

    generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }
}
