import ng from 'angular';
import { IWorkflowAcao } from '../../models/workflow.acao.model';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';

export class WorkflowDefaultModalController implements ng.IController {

    static $inject = [
        'acao',
        'trabalhador',
        '$uibModalInstance'
    ];

    constructor(
        public acao: IWorkflowAcao,
        public trabalhador: ITrabalhador,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    ) {
        this.tratarTextosModal();
    }

    public confirmar(): void {
        this.$uibModalInstance.close();
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }

    private tratarTextosModal(): void {
        this.tratarNomeColaborador(this.acao.modal!.confirmacao!.textos);
    }

    private tratarNomeColaborador(textos: string[]): void {
        textos.forEach((texto: string, index: number) => {
            const textoModificado: string = texto.replace('{{nome_colaborador}}', this.trabalhador?.nome);
            textos[index] = textoModificado;
        });
    }
}
