
import * as angular from 'angular';

import { MeurhTransferenciaColaboradoresIndexController } from './index/transferenciacolaboradores.index.controller';
import { MeurhTransferenciaColaboradoresNewController } from './new/transferenciacolaboradores.new.controller';
import { MeurhTransferenciaColaboradoresShowController } from './show/transferenciacolaboradores.show.controller';
/*
import { MeurhVagasEditController } from './edit/vagas.edit.controller';
*/
import { MeurhTransferenciaColaboradoresShowFormController } from './showform/transferenciacolaboradores.showform.controller';
import { MeurhTransferenciaColaboradoresLoteFormController } from './form/lote/transferenciacolaboradoreslote.form.controller';
import { MeurhTransferenciaColaboradoresFormController } from './form/individual/transferenciacolaboradores.form.controller';
import { MeurhTransferenciaColaboradoresEditController } from './edit/transferenciacolaboradores.edit.controller';

import { MeurhTransferenciaColaboradoresShowFormComponent } from './showform/transferenciacolaboradores.showform.component';
import { MeurhTransferenciaColaboradoresLoteFormComponent } from './form/lote/transferenciacolaboradoreslote.form.component';
import { MeurhTransferenciaColaboradoresFormComponent } from './form/individual/transferenciacolaboradores.form.component';

import { MeurhTransferenciaColaboradoresService } from './transferenciacolaboradores.service';
import { MeurhImportacaoTransferenciaService } from './importacaotransferencia.service';

import { MeurhTransferenciaColaboradoresListRouting } from './transferenciacolaboradores.routes';

export const MeurhTransferenciaColaboradoresModule = angular.module('MeurhTransferenciaColaboradoresModule', ['ui.router.state', ])
    .controller('MeurhTransferenciaColaboradoresIndexController', MeurhTransferenciaColaboradoresIndexController)
    .controller('MeurhTransferenciaColaboradoresNewController', MeurhTransferenciaColaboradoresNewController)
    .controller('MeurhTransferenciaColaboradoresShowController', MeurhTransferenciaColaboradoresShowController)
    .controller('MeurhTransferenciaColaboradoresShowFormController', MeurhTransferenciaColaboradoresShowFormController)
    .controller('MeurhTransferenciaColaboradoresLoteFormController', MeurhTransferenciaColaboradoresLoteFormController)
    .controller('MeurhTransferenciaColaboradoresFormController', MeurhTransferenciaColaboradoresFormController)
    .controller('MeurhTransferenciaColaboradoresEditController', MeurhTransferenciaColaboradoresEditController)
    .component('meurhTransferenciaColaboradoresShowFormComponent', MeurhTransferenciaColaboradoresShowFormComponent)
    .component('meurhTransferenciaColaboradoresLoteFormComponent', MeurhTransferenciaColaboradoresLoteFormComponent)
    .component('meurhTransferenciaColaboradoresFormComponent', MeurhTransferenciaColaboradoresFormComponent)
    .service('MeurhImportacaoTransferenciaService', MeurhImportacaoTransferenciaService)
    .service('MeurhTransferenciaColaboradoresService', MeurhTransferenciaColaboradoresService)
    .config(MeurhTransferenciaColaboradoresListRouting)
    .name;
