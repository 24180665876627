import angular from 'angular';

import { MeurhApontamentosGradeIndexController } from './index/apontamentosgrade.index.controller';
import { MeurhApontamentosGradeModalSalvarController } from './modal.salvar/modal.salvar.controller';

import { MeurhApontamentosGradeModalSalvarService } from './modal.salvar/modal.salvar.service';
import { MeurhApontamentosMovimentosAuxService } from './apontamentosmovimentoaux.service';
import { MeurhApontamentosService } from '../Apontamentos/apontamentos.service';

import { MeurhApontamentosGradeListRouting } from './apontamentosgrade.route';

export const MeurhApontamentosGradeModule = angular.module('MeurhApontamentosGradeModule', ['ui.router.state', ])
    .controller('MeurhApontamentosGradeIndexController', MeurhApontamentosGradeIndexController)
    .controller('MeurhApontamentosGradeModalSalvarController', MeurhApontamentosGradeModalSalvarController)
    .service('MeurhApontamentosGradeModalSalvarService', MeurhApontamentosGradeModalSalvarService)
    .service('MeurhApontamentosMovimentosAuxService', MeurhApontamentosMovimentosAuxService)
    .service('MeurhApontamentosService', MeurhApontamentosService)
    .config(MeurhApontamentosGradeListRouting)
    .name;
