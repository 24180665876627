import angular from 'angular';

export const EstabelecimentosHabilitadosRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		$stateProvider
			.state('estabelecimentoshabilitados', {
				url: '/ponto/estabelecimentoshabilitados',
				template: require('html-loader!./index/estabelecimentoshabilitados.index.html'),
				controller: 'EstabelecimentosHabilitadosIndexController',
				controllerAs: 'mrh_estahbl_idx_cntrllr',
				reloadOnSearch: false
			})
			.state('estabelecimentoshabilitados_new', {
				url: '/ponto/estabelecimentoshabilitados/new',
				template: require('html-loader!./new/estabelecimentoshabilitados.new.html'),
				controller: 'EstabelecimentosHabilitadosNewController',
				controllerAs: 'mrh_estahbl_new_cntrllr',
				reloadOnSearch: false
			})
            .state('estabelecimentoshabilitados_edit', {
                url: '/ponto/estabelecimentoshabilitados/edit/?id',
                template: require('html-loader!./edit/estabelecimentoshabilitados.edit.html'),
                controller: 'EstabelecimentosHabilitadosEditController',
                controllerAs: 'mrh_estahbl_edt_cntrllr'
            });
	}
];
