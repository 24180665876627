import * as angular from 'angular';
import { MeurhAdmissoesestagiarioValetransporteFormController } from './valetransporte.form.controller';

export class MeurhAdmissoesestagiarioValetransporteFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioValetransporteFormComponent';
	static controller = MeurhAdmissoesestagiarioValetransporteFormController;
	static template = require('!!html-loader!./valetransporte.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
