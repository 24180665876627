import angular = require('angular');
import { IControleFerias } from 'modules/Avisoferias/models/controleferias.model';
import moment = require('moment');
import { MeurhAvisoSaldoZeroModalService } from './avisosaldozero.modal.service';

export class MeurhAvisoSaldoZeroModalController implements ng.IController {
	static $inject = ['$uibModalInstance', 'MeurhAvisoSaldoZeroModalService', '$scope', 'entities'];

	public showMarcacoes: boolean = false;
	public checked: number;
	public unchecked: number;
	public colaboradoresComSaldoZero: Array<IControleFerias>;
	public fieldsConfig: any = [
		{
			value: 'nome',
			label: 'Colaborador',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'periodoaquisitivoformatado',
			label: 'Período Aquisitivo',
			type: 'string',
			style: 'title',
		}
	];

	constructor(
		public $uibModalInstance: any,
		public MeurhAvisoSaldoZeroModalService: MeurhAvisoSaldoZeroModalService,
		public $scope: angular.IScope,
		public entities: Array<IControleFerias>,
	) {
		$scope.$watch('$ctrl.entities', (): void => {
			this.colaboradoresComSaldoZero = this.entities.filter((entity: IControleFerias) => entity.disableCheckbox);
			this.unchecked = this.colaboradoresComSaldoZero.length;
			this.formataPeriodoAquisitivo();
		});
	}

	formataPeriodoAquisitivo(): void {
		this.colaboradoresComSaldoZero.forEach((colaborador: IControleFerias): void => {
			colaborador['periodoaquisitivoformatado'] = `${moment(colaborador.inicioperiodoaquisitivoferias).format('DD/MM/YYYY')} a ${moment(colaborador.fimperiodoaquisitivoferias).format('DD/MM/YYYY')}`;
		});
	}

	close(): void {
		this.$uibModalInstance.close();
	}
}
