import angular from 'angular';
import moment from 'moment';
import { MeurhInclusaodependenteService } from '../inclusaodependente.service';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { IInclusaodependente } from '../models/inclusaodependente.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhInclusaodependenteIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'MeurhInclusaodependenteService',
        '$location',
        'TrabalhadoresService',
        'BuscaAvancadaService',
        'ListagemService'
    ];

    public entities: Array<IInclusaodependente>;
    public filter: any = {};
    public filters: any = {};
    public tipo: string = this.$stateParams['tipo'] ? this.$stateParams['tipo'] : '-1';
    public dataAtual: moment.Moment = moment();
    public datainclusao?: string = '';
    public situacao: string = '';
    public constructors: any = {};
    public busy: boolean = false;

    // busca avançada - variáveis
    public trabalhadorInput: string = '';
    public entitiesTrabalhadores: Array<ITrabalhador>;

    public entity: any = { trabalhador: null };

    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_dependente'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            session: any,
            nsjGlobals: any,
            tipo: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public entityService: MeurhInclusaodependenteService,
        public $location: angular.ILocationService,
        public TrabalhadoresService: TrabalhadoresService,
        public BuscaAvancadaService: any,
        public ListagemService: ListagemService,
    ) {

        this.entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = this.entityService.filters = {};
        this.montaListagemVisibilidade();
        this.filter.search = $stateParams['q'];
        this.constructors = entityService.constructors = [];
        this.entities = this.filterReload();
        this.carregaTrabalhadores();

        $rootScope.$on('meurh_solicitacoesinclusaodependentes_list_finished', (event: any, args: any) => {
            this.busy = false;

        });
        $rootScope.$on('meurh_solicitacoesinclusaodependentes_loaded', (event: any, args: any) => {
            this.busy = false;

        });
        this.$scope.$on('meurh_solicitacoesinclusaodependentes_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });

        this.$scope.$on('meurh_solicitacoesinclusaodependentes_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });

        this.$scope.$on('meurh_solicitacoes_cancelada', (event: angular.IAngularEvent, args: any): void => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });

        this.$rootScope.$on('meurh_solicitacoesinclusaodependentes_get_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
        });

        // busca avançada \/
        $rootScope.$on('persona_trabalhadores_list_finished', (event: angular.IAngularEvent, args: any) => {
            this.entitiesTrabalhadores = [...args];
        });
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.limpaNsjSearch('colaboradorSearchFilter');
        this.carregaTrabalhadores();
        this.filterReload();
    }

    carregaTrabalhadores(): void {
        let constructor = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            ...(this.tipo && { 'tipo': this.tipo }),
        };
        this.TrabalhadoresService.constructors = constructor;
        this.TrabalhadoresService.filter = '';
        this.TrabalhadoresService.reload();
    }

    search(): Array<IInclusaodependente> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }
        return this.entityService.search(this.filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    limparFiltros(): void {
        this.situacao = '';
        this.datainclusao = undefined;
        delete this.constructors.created_at;
        delete this.constructors.solicitacao;
        delete this.constructors.situacao;
        delete this.constructors.trabalhador;
        delete this.constructors.datainclusao;
        this.entityService.constructors = this.constructors;
        this.limpaTrabalhador();
    }

    filterReload(): Array<IInclusaodependente> {
        delete this.constructors.created_at;
        delete this.constructors.solicitacao;

        delete this.constructors.situacao;
        if (this.situacao !== '') {
            this.constructors.situacao = this.situacao;
        }
        delete this.constructors.trabalhador;
        if (this.valorExiste(this.entity) && this.valorExiste(this.entity.trabalhador)) {
            this.constructors.trabalhador = angular.copy(this.entity.trabalhador.trabalhador);
        }

        delete this.constructors.datainclusao;
        if (this.valorExiste(this.datainclusao)) {
            this.constructors.datainclusao = this.datainclusao + ' 00:00:00';
        }

        this.entityService.constructors = this.constructors;
        return this.entityService.reload();
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    goToShow(entity: IInclusaodependente): void {
        this.busy = true;
        this.$state.go('meurh_inclusaodependente_show', angular.extend({}, {
            'solicitacao': entity.solicitacao,
        })).then(() => this.busy = false);
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    // inicio - BUSCA AVANÇADA
    buscaAvancada(): void {
        if (this.tipo) {
            this.TrabalhadoresService.constructors.tipo = this.tipo;
        }
        delete this.TrabalhadoresService.constructors.trabalhador;

        let columns = ['Nome', 'Tipo'];
        let values = ['nome', 'tipo'];
        let enumValues = [];
        enumValues['nome'] = '';
        enumValues['tipo'] = 'lookupTipoTrabalhador';
        this.TrabalhadoresService.constructors['status'] = '00';
        let modal = this.BuscaAvancadaService.open(this, this.entity, this.TrabalhadoresService, columns, values, 'Colaboradores', enumValues, 'trabalhador', false, true, true, () => this.filterReload());
        modal.result.then(() => {/**/ }).catch(() => {/**/ });
    }

    evento(event: angular.IAngularEvent & { detail: any }): void {
        let constructor = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            'forcagestor': this.$rootScope.modoGestorPermissao('visualizar_dependente'),
            ...(this.tipo && { 'tipo': this.tipo }),
        };
        let filter: { search?: string } = {};
        filter.search = event.detail.value;
        this.TrabalhadoresService.constructors = constructor;
        this.TrabalhadoresService.filter = event.detail;
        this.TrabalhadoresService.search(filter);
    }

    retornaListagemCombo(): Array<ITrabalhador> {
        return this.entitiesTrabalhadores;
    }

    comboBusy(): boolean {
        return this.TrabalhadoresService.loadParams.busy;
    }

    selecionaTrabalhador(event: any): void {
        if (event) {
            this.entity.trabalhador = event.detail.value;
            this.filterReload();
        }
    }

    limpaTrabalhador(): void {
        this.entity = { trabalhador: null };
        this.filterReload();
    }

    limpaNsjSearch(id: string): void {
        document.querySelectorAll(`#${id} .componentPosition .customIcon`)[0]?.dispatchEvent(new Event('click'));
        document.querySelectorAll(`#${id} .componentPosition .customIconMobile`)[0]?.dispatchEvent(new Event('click'));
    }
    // fim - BUSCA AVANÇADA
}
