import angular from 'angular';
import { MeurhAlteracaoFotoFormController } from './alteracaofoto.form.controller';

export class MeurhAlteracaoFotoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAlteracaoFotoFormComponent';
	static controller = MeurhAlteracaoFotoFormController;
	static template = require('!!html-loader!./alteracaofoto.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
        form: '<?',
		busy: '=?',
		action: '<?'
	};
}
