import angular, { IPromise } from 'angular';
import { LoadParams } from '../../../shared/models/loadparams.model';
import { IViaCepResponse } from './models/viaCepResponse.model';
// import { IAdmissaoCompleta } from './models/vtadicional.model';

export class MeurhAdmissaocompletaService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    // a IAdmissaoCompleta
    public entity: any;
    public entities: Array<any> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams = new LoadParams();
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService) {}

    // a IAdmissaoCompleta
    reload(): Array<any> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    // c IAdmissaoCompleta
    search(filter: any = undefined): Array<any>  {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    // v IAdmissaoCompleta
    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('meurh_solicitacoesadmissoesunificadas_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    // v IAdmissaoCompleta
    load(): Array<any> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                        this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                        this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                    }
                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }
                    this.loaded = true;

                    this.loadParams.to_load--;

                    if (this.loadParams.to_load === 0 || data.length <= 20) {
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_loaded', this.entities);
                    }
                })
                .catch((error: any) => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoespreliminar', this.entities);
                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    // v IAdmissaoCompleta
    find(identifier: string): any | null {
        for (var i in this.entities) {
            if (this.entities[i]['solicitacao'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    delete($identifier: any, force: boolean): void {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['solicitacao'];
        }
        if ($identifier) {
            if (force || confirm('Tem certeza que deseja deletar?')) {
                this.loading_deferred = this.$q.defer();
                this.$http
                    .delete(this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
                        { timeout: this.loading_deferred.promise })
                    .then((response: any) => {
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_deleted', {
                            entity: this.entity,
                            response: response
                        });
                    })
                    .catch((response: any) => {
                        this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_delete_error', {
                            entity: this.entity,
                            response: response
                        });
                    });
            }
        }
    }

    // v IAdmissaoCompleta
    // está sendo utilizado método POST para a edição neste caso
    // por conta do envio da foto do trabalhador, que não poderia ser enviada
    // em base64, e por isso foi necessário enviar o formulário no formato
    // formData ao invés de JSON, e o formData não está sendo aceito pelo método PUT.
    _save(entity: any, autosave: boolean): angular.IHttpPromise<any> {
        var method, url;
        if (entity['solicitacao']) {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_put', { 'id': entity['solicitacao'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_create', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        var data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();

        let formData = new FormData();
        for ( let key in data ) {
            if (data.hasOwnProperty(key) && data[key] != null) {
                formData.append(key, data[key]);
            }
        }
        return this.$http({
            method: method,
            url: url,
            data: formData,
            timeout: this.loading_deferred.promise,
            headers: {'Content-Type': undefined}
        })
            .finally(() => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    // v IAdmissaoCompleta
    save(entity: any, autosave: boolean = false): void {

        this._save(entity, autosave)
            .then((response: any) => {
                if (response.data.solicitacao) {
                    entity['solicitacao'] = response.data.solicitacao;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any) => {
                this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    // v IAdmissaoCompleta
    fechar(entity: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();
        entity['$$__submitting'] = true;
        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'post',
                url: this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_fechar', { 'id': entity.solicitacao }, true),
                data: angular.copy(entity),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                }).finally(() => {
                    entity['$$__submitting'] = false;
                });
        });
    }

    get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_get', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_get_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    getFoto(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get(this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_foto', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_get_foto_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_get_foto_error', response.data);
                    reject(response.data);
                });
        });
    }

    encaminhar(identifier: string, opt?: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .put(
                    this.nsjRouting.generate(
                        'meurh_solicitacoesadmissoescompleta_encaminhar',
                        {
                            'id': identifier,
                            'opt': opt
                        },
                        true
                    ),
                    {
                        timeout: this.loading_deferred.promise
                    }
                )
                .then((response: any) => {
                    if (opt === 'link') {
                        this.$rootScope.$broadcast(
                            'meurh_solicitacoesadmissoescompleta_encaminhar_link_success',
                            response.data
                        );
                    } else {
                        this.$rootScope.$broadcast(
                            'meurh_solicitacoesadmissoescompleta_encaminhar_success',
                            response.data
                        );
                    }

                    resolve(response.data);
                })
                .catch((response: any) => {
                    if (opt === 'link') {
                        this.$rootScope.$broadcast(
                            'meurh_solicitacoesadmissoescompleta_encaminhar_link_error',
                            response.data
                        );
                    } else {
                        this.$rootScope.$broadcast(
                            'meurh_solicitacoesadmissoescompleta_encaminhar_error',
                            response.data
                        );
                    }

                    reject(response);
                });
        });
    }

    retomar(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .put(this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_retomar', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_retomar_success', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_retomar_error', response.data);
                });
        });
    }

    enviaPreliminar(entity: any, newEntity?: boolean): void {
        entity['$$__submitting'] = true;
        let url = newEntity ? this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_create_preliminar', { 'id': entity.solicitacao }, true) : this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_put_preliminar', { 'id': entity.solicitacao }, true);
        let autosave: boolean = false;
        let data = angular.copy(entity);
        let formData = new FormData();

        this.loading_deferred = this.$q.defer();

        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }

        for (let key in data) {
            if (data.hasOwnProperty(key) && data[key] != null) {
                formData.append(key, data[key]);
            }
        }

        this.$http({
            method: 'POST',
            url: url,
            data: formData,
            timeout: this.loading_deferred.promise,
            headers: {'Content-Type': undefined}
        }).then((response: any) => {
            if (response.data.solicitacao) {
                entity['solicitacao'] = response.data.solicitacao;
            }
            entity['$$_saved'] = true;
            this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_preliminar_submitted', {
                entity: entity,
                response: response,
                autosave: autosave
            });
        }).catch((response: any) => {
            this.$rootScope.$broadcast('meurh_solicitacoesadmissoescompleta_preliminar_submit_error', {
                entity: entity,
                response: response,
                autosave: autosave
            });
        });
    }

    public verificarCpfAdmissaoEfetuada(entity: any): any {
        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .post(this.nsjRouting.generate(
                    'meurh_admissoesefetuadastrabalhadores_verificar',
                    {
                        'estabelecimento': entity.estabelecimento.estabelecimento
                    },
                    true
                ),
                {
                    solicitacao: entity.solicitacao,
                    tiposolicitacao: 12,
                    cpf: entity.cpf,
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public getEndereco(cep: string): IPromise<IViaCepResponse> {
        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .get<IViaCepResponse>(`https://viacep.com.br/ws/${cep}/json/`)
                .then((response: angular.IHttpResponse<IViaCepResponse>) => {
                    resolve(response.data);
                })
                .catch((response: angular.IHttpResponse<any>) => {
                    reject(response);
                });
        });
    }

    retificarAdmissao(identifier: string) {
        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http
                .post(
                    this.nsjRouting.generate('meurh_solicitacoesadmissoescompleta_retificar', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    getEstadosWorkflow(): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('meurh_solicitacoesadmissoesunificadas_estados_workflow', angular.extend({}, this.constructors, { 'offset': {}, 'filter': '' }, {}), true),
                timeout: this.loading_deferred.promise
            })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((response: any) => {
                reject(response);
            });
        });

    }

}
