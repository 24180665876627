export class MeurhAdmissoesValetransportepersonalizadoFormmodalController implements ng.IController {

    static $inject = [];

    public valetransportepersonalizado: any = {};
    public action: string;

    constructor() {/**/}

    $onInit(): void {

       if (this.valetransportepersonalizado !== {}) {
           this.valetransportepersonalizado.tipo = this.valetransportepersonalizado.tipo ? this.valetransportepersonalizado.tipo.toString() : this.valetransportepersonalizado.tipo;
       }
    }
}
