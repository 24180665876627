import angular = require('angular');
import { IFerias } from './models/ferias.model';
import { LoadParams } from '../../../shared/models/loadparams.model';

export class MeurhFeriasService {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

	public entity: IFerias;
	public entities: Array<IFerias> = [];
	public loaded: boolean = false;
	public constructors: any = {};
	public after: any = {};
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter: any = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $q: angular.IQService,
	) {
		this.loadParams = new LoadParams();
	}

	reload(): Array<IFerias> {
		if (this.loading_deferred) {
			this.loading_deferred.resolve();
		}

		this.loadParams.finished = false;
		this.loadParams.to_load = 3;
		this.after = {};
		this.loaded = false;
		this.entities.length = 0;
		this.loadParams.busy = false;

		return this.load();
	}

	search(filter: any): Array<IFerias> {
		if (typeof filter !== 'undefined') {
			this.filter = filter.search;
			Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });

			for (var fil in filter.filters) {
				if (fil.indexOf('.') > -1) {
					var filterSplit = fil.split('.');
					this.filters[filterSplit[0]] = {};
					this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
				} else {
					this.filters[fil] = filter.filters[fil];
				}
			}
		}

		return this.reload();
	}

	_load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('meurh_solicitacoesferias_busca_padrao', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	load(): Array<IFerias> {
		if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
			this.loadParams.busy = true;

			this._load(this.constructors, this.after, this.filter)
				.then((data: any): void => {
					if (data.length > 0) {
						for (var i = 0; i < data.length; i++) {
							this.entities.push(data[i]);
						}

						this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
						this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
					}

					if (data.length < 20) {
						this.loadParams.finished = true;
						this.$rootScope.$broadcast('meurh_solicitacoesferias_busca_padrao_list_finished', this.entities);
					} else {
						this.loadParams.finished = false;
					}

					this.loaded = true;
					this.loadParams.to_load--;
				})
				.catch((error: any): void => {
					if (error.xhrStatus !== 'abort') {
						this.loadParams.finished = true;
					}
				})
				.finally((): void => {
					if (this.loaded || this.loadParams.finished) {
						this.loadParams.busy = false;
					}

					this.load();
				});
		}

		this.$rootScope.$broadcast('meurh_solicitacoesferias_busca_padrao_list_finished', this.entities);
		return this.entities;
	}

	loadMore(): void {
		this.loadParams.to_load = 3;
		this.load();
	}

	find(identifier: any): IFerias | null {
		for (var i in this.entities) {
			if (this.entities[i]['solicitacao'] === identifier) {
				return this.entities[i];
			}
		}

		return null;
	}

	delete($identifier: any, force: boolean = false): void {
		if (typeof ($identifier) === 'object') {
			$identifier = $identifier['solicitacao'];
		}

		if ($identifier) {
			if (force) {
				this.loading_deferred = this.$q.defer();
				this.$http.delete(
					this.nsjRouting.generate('meurh_solicitacoesferias_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
					{ timeout: this.loading_deferred.promise }
				)
				.then((response: any) => {
					this.$rootScope.$broadcast('meurh_solicitacoesferias_deleted', {
						entity: this.entity,
						response: response
					});
				})
				.catch(function (response: any) {
					this.$rootScope.$broadcast('meurh_solicitacoesferias_delete_error', {
						entity: this.entity,
						response: response
					});
				});
			}
		}
	}

	_save(entity: any,
		params: { empresa: string, estabelecimento: string },
		autosave: any): angular.IHttpPromise<any> {
		let method: string, url: string;

		if (entity['solicitacao']) {
			method = 'PUT';
			url = this.nsjRouting.generate('meurh_solicitacoesferias_put', {
				id: entity['solicitacao'],
				...params
			}, true);
		} else {
			method = 'POST';
			url = this.nsjRouting.generate('meurh_solicitacoesferias_create',
				angular.extend({}, this.constructors, params)
			, true);
		}

		if (!autosave) {
			autosave = false;
			entity['$$__submitting'] = true;
		}

		let data = angular.copy(entity);

		if (data.hasOwnProperty('$$__submitting')) {
			delete data['$$__submitting'];
		}

		this.loading_deferred = this.$q.defer();

		return this.$http({
			method: method,
			url: url,
			data: data,
			timeout: this.loading_deferred.promise
		}).finally((): void => {
			if (!autosave) {
				entity['$$__submitting'] = false;
			}
		});
	}

	save(entity: IFerias,
		params: { empresa: string, estabelecimento: string },
		autosave: boolean = false): void {
		this._save(entity, params, autosave)
			.then((response: any): void => {
				if (response.data.id) {
					entity['solicitacao'] = response.data.id;
				}
				entity['$$_saved'] = true;
				this.$rootScope.$broadcast('meurh_solicitacoesferias_submitted', {
					entity: entity,
					response: response,
					autosave: autosave
				});
			})
			.catch((response: any): void => {
				this.$rootScope.$broadcast('meurh_solicitacoesferias_submit_error', {
					entity: entity,
					response: response,
					autosave: autosave
				});
			});
	}

	fechar(entity: IFerias): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('meurh_solicitacoesferias_fechar', { 'id': entity.solicitacao }, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

	get(identifier: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http.get(
				this.nsjRouting.generate('meurh_solicitacoesferias_get', { 'id': identifier }, true),
				{ timeout: this.loading_deferred.promise }
			)
			.then((response: any) => {
				this.$rootScope.$broadcast('meurh_solicitacoesferias_loaded', response.data);
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	getFeriados(estabelecimento?: string, retornartodos: 0 | 1 = 0): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http.get(
				this.nsjRouting.generate('meurh_solicitacoesferias_feriados', {
					...(estabelecimento && { estabelecimento }),
					retornartodos
				}, true),
				{ timeout: this.loading_deferred.promise }
			)
			.then((response: any) => {
				this.$rootScope.$broadcast('meurh_solicitacoesferias_feriados_loaded', response.data);
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	getHorarios(identifier: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http.get(
				this.nsjRouting.generate('meurh_solicitacoesferias_horarios', { 'trabalhador': identifier }, true),
				{ timeout: this.loading_deferred.promise }
			)
			.then((response: any) => {
				response.data.trabalhador = identifier;
				this.$rootScope.$broadcast('meurh_solicitacoesferias_horarios_loaded', response.data);
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}
}
