export enum TipoRecebimentobolsaEnum {
    NAO_POSSUI_TIPO = '0',
    CONTA_CORRENTE = '1',
    CONTA_POUPANCA = '2',
    CONTA_SALARIO = '4',
    DINHEIRO = '8',
    CHEQUE = '16',
    ORDEM_PAGAMENTO = '32',
    PIX_AGENCIA_CONTA = '64',
    PIX_CPF = '128',
    PIX_EMAIL = '256',
    PIX_CELULAR = '512',
    PIX_CHAVE_ALEATORIA = '1024'
}
