import angular from 'angular';
import { IVagas } from '../models/vagas.model';
import { CargosService } from '../../../Cargos/cargos.service';
import { HorarioDetalhadoModalService } from '../../Admissao/modais/modal-horario-detalhado/horario-detalhado.modal.service';
import { HorariosService } from '../../../Horarios/horarios.service';

export class MeurhVagasShowFormController implements angular.IController {
	static $inject = [
		'$scope',
		'$rootScope',
		'CargosService',
		'NewToaster',
		'HorarioDetalhadoModalService',
		'HorariosService',
	];

	public form: angular.IFormController;
	public entity: IVagas;

	constructor(
		public $scope: angular.IScope,
		public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, session: any },
		public CargosService: CargosService,
		public NewToaster: any,
		public HorarioDetalhadoModalService: HorarioDetalhadoModalService,
		public HorariosService: HorariosService,
	) {
		$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if (this.form && (newValue !== oldValue)) {
				this.form.$setDirty();
			}
		}, true);
	}

	async $onInit(): Promise<void> {
		if (!!this.entity.nivelcargo?.cargo) {
			this.CargosService.get(this.entity.nivelcargo.cargo)
				.then((response: any): void => {
					this.entity.cargo = response;
				}).catch((e: any): void => {
					console.log(e);
				});
		}
		this.verificaGestores();

		if (this.entity.horario) {

			await this.HorariosService.get(this.entity.horario.horario, true).then((horarioBuscado) => {
			  this.entity.horario = horarioBuscado;
			})
			.catch((error) => {
			  this.NewToaster.pop({
				type: 'error',
				title: 'Não foi possível carregar o horário'
			  });
			});

		}

	}

	verificaGestores(): void {
		if (!this.entity.gestores) {
			this.entity.gestores = [];
		} else {
			this.entity.gestores = this.entity.gestores.map((gestor: any) => {
				if (gestor.gestor.trabalhador) {
					return gestor.gestor;
				} else {
					return {
						nome: gestor.identificacaonasajongestor
					};
				}
			});
		}
	}

	public abrirModalHorarioDetalhado() {

		let modal = this.HorarioDetalhadoModalService.open(this.entity.horario, {
			empresa: this.$rootScope.session.entities.empresa.empresa,
			estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
			action: 'show'
		});

		modal.result.catch((error: any): void => {/**/});
	}

}
