import angular from 'angular';
import { MeurhAcompanhamentoService } from '../../../Meurh/Acompanhamento/acompanhamento.service';
import { MeurhSolicitacoes } from '../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { IFalta } from '../models/falta.model';
import { MeurhSolicitacoesFaltasService } from '../solicitacoesfaltas.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhSolicitacoesfaltasShowController implements angular.IController {
	static $inject = [
		'ModalConfirmService',
		'$scope',
		'$stateParams',
		'$state',
		'MeurhSolicitacoesFaltasService',
		'entity',
		'NewToaster',
		'$rootScope',
		'MeurhAcompanhamentoService',
		'WorkflowService',
		'MeurhSolicitacoes',
		'TiposDocumentosRequeridosService'
	];

	public action: string = 'show';
	public edit: boolean = false;
	public editanexo: boolean = false;
	public adicionarAnexo: boolean = false;
	public form: angular.IFormController;
	public formAnexo: object = {};
	public entityAnexo: IAnexo = {
		casodeuso: 'FALTAS'
	};
	public tiposdocumentosrequeridos: Array<any>;
	public actionAnexo: string = 'insert';
	public trabalhador: string;
	public busy: boolean = false;
	public busyTipoDocumentos: boolean = true;
    public path: number = this.$stateParams.path;

	constructor(
		public ModalConfirmService: any,
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhSolicitacoesFaltasService,
		public entity: IFalta,
		public NewToaster: any,
		public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
		public MeurhAcompanhamentoService: MeurhAcompanhamentoService,
		public WorkflowService: WorkflowService,
		public MeurhSolicitacoes: MeurhSolicitacoes,
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService
	) {
		$rootScope.$on('meurh_solicitacoesfaltas_loaded', (event: any, args: any) => {
			this.trabalhador = args.trabalhador.trabalhador;
		});
	}

	$onInit(): void {
		this.carregaAcompanhamento();
        this.entityService.get(this.$stateParams['solicitacao']);
        this.documentosAnexos();

		this.afterReloadWorkflow();
		this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesfaltas_');

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				tipodocumentorequerido.file = '';
				return tipodocumentorequerido;
			});
		});
	}

	documentosAnexos(): void {
        const estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
        this.setEntityAnexo({
            solicitacao: this.entity.solicitacao,
            estabelecimento
        });
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.carregaTiposDocumentosRequeridos({
                empresa: this.entity.empresaobj.empresa,
                estabelecimento
            });
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
    }

    carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.FALTA,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

    carregaAcompanhamento(): void {
        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.loadParams.finished = false;
        this.MeurhAcompanhamentoService.loadParams.to_load = 3;
        this.MeurhAcompanhamentoService.load();
    }

	resetTiposDocumentosRequeridosService() {
		this.TiposDocumentosRequeridosService.entities = [];
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
	}

	setEntityAnexo(params: { solicitacao: string, estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
        this.entityAnexo['solicitacao'] = params.solicitacao;
    }

	afterReloadWorkflow(): void {
		this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
			this.$state.reload();
		});
	}

	formInvalid(): boolean {
		let datasVazias = (this.entity.datas === undefined || this.entity.datas === null || this.entity.datas === '') ? true : false;

		return !this.form.$valid || datasVazias;
	}

	goToEdit(): void {
		this.busy = true;
		this.$state.go('meurh_solicitacoesfaltas_edit', angular.extend({}, {
			'solicitacao': this.entity.solicitacao,
			'estabelecimento': this.$stateParams['estabelecimento']
		}));
	}

	setEditAnexo(value: boolean): void {
		this.editanexo = value;
	}

	setEdit(value: boolean): void {
		this.entity.datas = value ? (this.entity.datas.length > 0 ? this.entity.datas[0] : '') : (Array.isArray(this.entity.datas) ? this.entity.datas : [this.entity.datas]);
		this.edit = value;
	}

	cancelar(entity: ISolicitacao): void {
		let confirm = this.ModalConfirmService.openCancelar(entity, 'solicitação de falta');
		confirm.result.then((entity: ISolicitacao): void => {
			this.busy = true;
			this.MeurhSolicitacoes.cancelar(entity)
				.then((response: any): void => {
					this.NewToaster.pop({
						type: 'success',
						title: 'A solicitação de falta foi cancelada com sucesso!'
					});
					this.entityService.reload();
					this.$state.go('meurh_solicitacoesfaltas', angular.extend(this.entityService.constructors));
				})
				.catch((response: any): void => {
					if (typeof (response.data.message) !== 'undefined' && response.data.message) {
						this.NewToaster.pop({
							type: 'error',
							title: response.data.message
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: 'Ocorreu um erro ao cancelar a solicitação de falta!',
							body: 'Por favor, verifique os campos em vermelho.'
						});
					}
				})
				.finally((): void => {
					this.busy = false;
				});
		}).catch((error: any): void => {
			if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
				this.NewToaster.pop({
					type: 'error',
					title: error
				});
			}
		});
	}
}
