import angular = require('angular');
import moment = require('moment');

export class MeurhCreditosdescontosAlertaduplicidadeService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: any) { }

  open(modalEntityCreditosDescontos: any, entitySemelhante: any) {

    return this.$uibModal.open({
      template: require('html-loader!./creditosdescontos.alertaduplicidademodal.html'),
      controller: ['entity', '$uibModalInstance', '$sce', '$compile', '$scope', function MeurhCreditosdescontosAlertaduplicidadeModal(entity: any, $uibModalInstance: any, $sce: any, $compile: any, $scope: any) {
        var selfModal = this;
        selfModal.modalEntityCreditosDescontos = modalEntityCreditosDescontos;
        selfModal.entitySemelhante = entitySemelhante;
        selfModal.showDetalhes = false;

        selfModal.$onInit = () => {
          selfModal.alteraEntitySemelhante();
        };

        selfModal.verDetalhes = () => {
          selfModal.showDetalhes = selfModal.showDetalhes === true ? false : true;
        };

        selfModal.alteraEntitySemelhante = () => {
          // folha creditada com competência
          selfModal.entitySemelhante.folhacreditadadata = (selfModal.entitySemelhante.tipocalculo === 0 ? 'Adiantamento salarial' : 'Pagamento') + ' da competência ' +
            (selfModal.entitySemelhante.mesreferencia + '/' + selfModal.entitySemelhante.anoreferencia);

          // competência da entidade semelhante e competência da data fim periodo
          let competenciapagamentoCompleta = selfModal.entitySemelhante.mesreferencia + '/' + selfModal.entitySemelhante.anoreferencia;
          selfModal.entitySemelhante.competenciafinalperiodoCompleta = selfModal.entitySemelhante.mesfinalperiodo + '/' + selfModal.entitySemelhante.anofinalperiodo;

          // texto período
          switch (selfModal.entitySemelhante.tipoperiodo) {
            case 0:
              selfModal.entitySemelhante.periodoDesc = 'Somente uma vez em ' + competenciapagamentoCompleta;
              break;
            case 1:
              selfModal.entitySemelhante.periodoDesc = 'De ' + competenciapagamentoCompleta + ' em diante ';
              break;
            default:
              selfModal.entitySemelhante.periodoDesc = 'De ' + competenciapagamentoCompleta + ' a ' + selfModal.entitySemelhante.competenciafinalperiodoCompleta;
          }
        };

        selfModal.close = () => {
          $uibModalInstance.dismiss('fechar');
        };

        selfModal.adicionar = () => {
          $uibModalInstance.close(true);
        };

        selfModal.cancelar = () => {
          $uibModalInstance.close(false);
        };
      }],
      controllerAs: '$ctrl',
      windowClass: 'nsj-modal-lg',
      resolve: {
        entity: function () {
          return angular.copy(modalEntityCreditosDescontos);
        }
      }
    });
  }
}
