import angular from 'angular';

import { CargosSalariosIndexController } from './index/cargosalarios.index.controller';

import { CargosSalariosListRouting } from './cargosalarios.routes';

import { CargosSalariosService } from './cargossalarios.service';

export const CargosSalariosModule = angular.module('CargosSalariosModule', ['ui.router.state', ])
    .controller('CargosSalariosIndexController', CargosSalariosIndexController )
    .service('CargosSalariosService', CargosSalariosService)
    .config(CargosSalariosListRouting)
    .name;
