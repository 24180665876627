
import angular from 'angular';

import { MeurhSolicitacoesrescisoesService } from './solicitacoesrescisoes.service';
import { MeurhMotivosrescisoesService } from './motivosrescicoes.service';
import { MeurhRescisoesModalConfirmService } from './modal/modalrescisao.confirm.service';

import { MeurhSolicitacoesrescisoesListRouting } from './solicitacoesrescisoes.routes';
import { MeurhSolicitacoesrescisoesIndexController } from './index/solicitacoesrescisoes.index.controller';
import { MeurhSolicitacoesrescisoesShowController } from './show/solicitacoesrescisoes.show.controller';
import { MeurhSolicitacoesrecisoesShowFormController } from './form/dadosrescisao/showform/solicitacoesrescisoes.showform.controller';
import { MeurhSolicitacoesrecisoesEditController } from './edit/solicitacoesrescisoes.edit.controller';
import { MeurhSolicitacoesrescisoesNewController } from './new/solicitacoesrescisoes.new.controller';
import { MeurhSolicitacoesrecisoesFormController } from './form/dadosrescisao/solicitacoesrescisoes.form.controller';
import { MeurhRescisoesDocumentosanexosFormController } from './form/documentosanexos/documentosanexosrescisoes.form.controller';
import { MeurhRescisoesModalConfirmController } from './modal/modalrescisao.confirm.controller';

import { MeurhSolicitacoesrecisoesShowFormComponent } from './form/dadosrescisao/showform/solicitacoesrescisoes.showform.component';
import { MeurhSolicitacoesrecisoesFormComponent } from './form/dadosrescisao/solicitacoesrescisoes.form.component';
import { MeurhRescisoesDocumentosanexosFormComponent } from './form/documentosanexos/documentosanexosrescisoes.form.component';

export const MeurhSolicitacoesrescisoesModule = angular.module('MeurhSolicitacoesrescisoesModule', ['ui.router.state'])
    .controller('MeurhSolicitacoesrescisoesIndexController', MeurhSolicitacoesrescisoesIndexController)
    .controller('MeurhSolicitacoesrescisoesShowController', MeurhSolicitacoesrescisoesShowController)
    .controller('MeurhSolicitacoesrecisoesShowFormController', MeurhSolicitacoesrecisoesShowFormController)
    .controller('MeurhSolicitacoesrecisoesEditController', MeurhSolicitacoesrecisoesEditController)
    .controller('MeurhSolicitacoesrescisoesNewController', MeurhSolicitacoesrescisoesNewController)
    .controller('MeurhSolicitacoesrecisoesFormController', MeurhSolicitacoesrecisoesFormController)
    .controller('MeurhRescisoesDocumentosanexosFormController', MeurhRescisoesDocumentosanexosFormController)
    .controller('MeurhRescisoesModalConfirmController', MeurhRescisoesModalConfirmController)
    .component('meurhSolicitacoesrecisoesShowFormComponent', MeurhSolicitacoesrecisoesShowFormComponent)
    .component('meurhSolicitacoesrecisoesFormComponent', MeurhSolicitacoesrecisoesFormComponent)
    .component('meurhRescisoesDocumentosanexosFormComponent', MeurhRescisoesDocumentosanexosFormComponent)
    .service('MeurhSolicitacoesrescisoesService', MeurhSolicitacoesrescisoesService)
    .service('MeurhMotivosrescisoesService', MeurhMotivosrescisoesService)
    .service('MeurhRescisoesModalConfirmService', MeurhRescisoesModalConfirmService)
    .config(MeurhSolicitacoesrescisoesListRouting)
    .name;
