import angular = require('angular');
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { IAlteracaoDadosSalariaisVisualizacao } from '../models/alteracaodadossalariais.model';
import { MeurhSolicitacoesalteracoesdadossalariaisService } from '../solicitacoesalteracoesdadossalariais.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhSolicitacoesalteracoesdadossalariaisShowController implements ng.IController {
	static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesalteracoesdadossalariaisService',
        'entity',
        'NewToaster',
        'ModalConfirmService',
        'WorkflowService',
        'MeurhSolicitacoes',
        'MeurhAcompanhamentoService',
        'TiposDocumentosRequeridosService',
    ];

    public action: string = 'show';
    public busy: boolean = false;
    public path: number = this.$stateParams.path;

    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;

    // documentos anexos
    public tiposdocumentosrequeridos: Array<any>;
    public entityAnexo: IAnexo = {};
    public editanexo: boolean = false;
    public busyTipoDocumentos: boolean = true;

    constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesdadossalariaisService,
        public entity: IAlteracaoDadosSalariaisVisualizacao,
        public NewToaster: any,
        public ModalConfirmService: any,
        public WorkflowService: WorkflowService,
        public MeurhSolicitacoes: MeurhSolicitacoes,
        public MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
    ) {

        $scope.$on('meurh_solicitacoes_canceled', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

    }

    $onInit(): void {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesalteracoestiporecebimento_');

        this.carregaAcompanhamento();
        this.entity.tiporecebimento = this.entity.tiporecebimento.toString();
        this.entity.tipocontapix = this.entity.tiporecebimento === '64' ? this.entity.tipocontapix.toString() : null;

        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo.casodeuso = 'ALTERACAODADOSRECEBIMENTO';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
        this.onTiposDocumentosRequeridosFinished();

    }

    carregaAcompanhamento(): void {
        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.loadParams.finished = false;
        this.MeurhAcompanhamentoService.loadParams.to_load = 3;
        this.MeurhAcompanhamentoService.load();
    }

    private onTiposDocumentosRequeridosFinished(): void {
        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                tipodocumentorequerido.file = '';
                return tipodocumentorequerido;
            });
        });
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': this.tipoSolicitacaoEnum.ALTERACAODADOSSALARIAIS };
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

}
