import angular from 'angular';

export class MeurhVisualizarmodalService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: any) { }

	open(subentity: any, action: string = 'insert'): any {
		if (action === 'insert' && subentity.base64) {
			let file = subentity.base64.split(';base64,');
			let blob = this.b64toBlob(file[1], file[0].replace('data:', ''));
			subentity.url = URL.createObjectURL(blob);
		} else if (action === 'insert') {
			subentity.url = URL.createObjectURL(subentity.file);

		} else {
			var file = new Blob([subentity], { type: subentity.type });
			subentity.url = URL.createObjectURL(file);
		}


		return this.$uibModal.open({
			template: require('html-loader!./anexos.visualizarmodal.html'),
			controller: [
				'entity',
				'$uibModalInstance',
				'$sce',
				function MeurhAnexosModal(
					entity: any,
					$uibModalInstance: any,
					$sce: angular.ISCEService
				) {
					let selfModal = this;
					selfModal.entity = entity;
					selfModal.entity.url = $sce.trustAsResourceUrl(subentity.url);

					fetch(subentity.url).then(response => response.blob()).then(blob => {
						blob.type === 'application/pdf' ? selfModal.entity.fileType = 'pdf' : selfModal.entity.fileType = 'image';
					})
					.catch(error => {
						console.error('Erro:', error);
						selfModal.entity.fileType = 'error';
					});

					selfModal.close = (): void => {
						$uibModalInstance.dismiss('fechar');
					};
				}
			],
			size: 'lg',
			controllerAs: '$ctrl',
			// windowClass: 'nsj-modal-lg',
			resolve: {
				entity: () => {
					return angular.copy(subentity);
				}
			}
		});
	}

	private b64toBlob (b64Data: string, contentType: any, sliceSize: any = 512) {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, {type: contentType});
		return blob;
	}
}
