import * as angular from 'angular';
import { AvisoFeriasService } from './avisoferias.service';

export const AvisoFeriasListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        var resolve = {
            'entity': ['AvisoFeriasService', '$stateParams', '$state', '$q', (entityService: any, $stateParams: any, $state: any, $q: any) => {
                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q((resolve: any, reject: any) => {
                            entityService.get($stateParams['status'], $stateParams['estabelecimento'], $stateParams['solicitacao'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('avisoferias_not_found', $stateParams)) {
                                            $state.go('avisoferias_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }]
        };

        $stateProvider
            .state('avisoferias_new', {
                url: '/avisoferias/new?tipo',
                resolve: resolve,
                params: { q: { dynamic: true }, calendarioferias: false },
                template: require('html-loader!./form/avisoferias.form.html'),
                controller: 'AvisoFeriasFormController',
                controllerAs: 'vsfrs_frm_cntrllr',
                reloadOnSearch: true
            });
    }
];
