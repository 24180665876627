import angular from 'angular';
import moment from 'moment';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { ISolicitacaoAlteracaoVT } from '../models/solicitacaoalteracaovt.model';

export class MeurhSolicitacoesalteracoesvtsFormController implements angular.IController {
    static $inject = [
        'MeurhSolicitacoesalteracoesvtstarifasFormService',
        '$scope',
        'NewToaster',
        '$stateParams',
        '$rootScope',
        '$http',
        'nsjRouting',
        'ListagemService',
    ];

    public action: string;
    public tipo: string;
    public busy: boolean = false;
    public idCount: number = 0;
    public form: angular.IFormController;
    public entity: ISolicitacaoAlteracaoVT;

    public filters = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
    };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['criar_alteracaovt'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    public constructorLookupColaborador;

    constructor(
        public MeurhSolicitacoesalteracoesvtstarifasFormService: any,
        public $scope: angular.IScope,
        public NewToaster: any,
        public $stateParams: angular.ui.IStateParamsService,
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public ListagemService: ListagemService,
    ) {
        this.tipo = $stateParams.tipo;

        $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);

    }

    $onInit(): void {
        this.montaListagemVisibilidade();

        this.constructorLookupColaborador = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            'forcagestor': this.forcaGestor(),
            ...(this.tipo && { 'tipo': this.tipo }),
        };

    }

    montaListagemVisibilidade(): void {
        if (this.action === 'update') {
            this.filters.empresa = this.entity.estabelecimento.empresa;
            this.filters.estabelecimento = this.entity.estabelecimento.estabelecimento;
        }
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.constructorLookupColaborador = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            'forcagestor': this.forcaGestor(),
            ...(this.tipo && { 'tipo': this.tipo }),
        };
    }

    forcaGestor(): boolean {
        return this.$rootScope.modoGestorPermissao('criar_alteracaovt');
    }

    meurhSolicitacoesalteracoesvtstarifasForm(entities: Array<ISolicitacaoAlteracaoVT>): void {
        let modal = this.MeurhSolicitacoesalteracoesvtstarifasFormService.open({
            empresa: this.action === 'update' ? this.entity.estabelecimento.empresa : this.entity.trabalhador.empresaobj.empresa,
            estabelecimento: this.action === 'update' ? this.entity.estabelecimento.estabelecimento : this.entity.trabalhador.estabelecimentoobj.estabelecimento
        }, {}, entities);
        modal.result
            .then((subentity: any): void => {
                subentity.$id = this.idCount++;
                if (this.entity.solicitacoesalteracoesvtstarifas === undefined) {
                    this.entity.solicitacoesalteracoesvtstarifas = [subentity];
                } else {
                    this.entity.solicitacoesalteracoesvtstarifas.push(subentity);
                }
            })
            .catch((error: any): void => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    selectTrabalhador(): void {
        if (this.entity !== undefined && (this.entity.trabalhador !== null && this.entity.trabalhador !== undefined)) {
            this.entity.$$__submitting = true;
            this.$http.get(this.nsjRouting.generate('meurh_solicitacoesalteracoesvts_possuialteracaovt', {
                    trabalhador: this.entity.trabalhador.trabalhador,
                    empresa: this.entity.trabalhador.empresaobj.empresa,
                    estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
                }, true))
            .then((response): void => {
                this.entity.solicitacaoaberta = response.data;
                if (this.entity.solicitacaoaberta.length > 0) {
                    this.entity.solicitacaoaberta[0].created_at = moment(this.entity.solicitacaoaberta[0].created_at).format('DD-MM-YYYY HH:mm');
                    if (this.entity.solicitacao === this.entity.solicitacaoaberta[0].solicitacao) {
                        this.entity.solicitacaoaberta = false;
                    }
                    this.entity.solicitacoesalteracoesvtstarifas = [];
                }
                this.entity.$$__submitting = false;
                if (this.entity.solicitacao === undefined && this.entity.solicitacaoaberta === false) {
                    this.getVtAtual();
                }
            })
            .catch((response: any): void => {/**/});
        }
    }

    getVtAtual(): void {
        if (this.entity.trabalhador !== null) {
            this.entity.$$__submitting = true;
            this.busy = true;
            this.$http.get(this.nsjRouting.generate('persona_trabalhadores_vt', angular.extend({}, {
                    trabalhador: this.entity.trabalhador.trabalhador,
                    empresa: this.entity.trabalhador.empresaobj.empresa,
                    estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
                }, {}), true))
            .then((response: any): void => {
                if (response.data) {
                    this.entity.solicitacoesalteracoesvtstarifas = response.data;
                } else {
                    this.entity.solicitacoesalteracoesvtstarifas = [];
                }
                this.entity.$$__submitting = false;
            })
            .catch((response: any): void => {/**/})
            .finally(() => this.busy = false);
        }
    }

    onChangeTrabalhador(trabalhadorSelecionado: any): void {
        if (trabalhadorSelecionado) {
            this.selectTrabalhador();
        } else {
            this.entity.solicitacaoaberta = false;
            this.entity.solicitacoesalteracoesvtstarifas = [];
        }
    }

}
