import angular from 'angular';
import { StatusProcessoEnum } from '../../enums/statusprocesso';

export class MeurhAdmissoesDadoscontatoFormController implements ng.IController {

    static $inject = [];

    public entity: any;
    public form: angular.IFormController;
    public invalidoTelefone: boolean = false;
    public invalidoCelular: boolean = false;
    public invalidoCelularEmergencia: boolean = false;

    public statusProcessoEnum = StatusProcessoEnum;

    constructor() {/**/}

    preenchePix(): void {
        if (this.entity.tiporecebimentosalario === '256') { // email
            this.entity.chavepix = this.entity.email;
        } else if (this.entity.tiporecebimentosalario === '512' && this.valorExiste(this.entity.dddcel) && this.valorExiste(this.entity.celular)) { // celular
            this.entity.chavepix = this.entity.dddcel + this.entity.celular;
        }
    }

    verificaTamanhotelefone(telefone: any, movel: int = 0): void {
        if (telefone && telefone.length < 8) {
            if (movel === 0) {
                this.invalidoTelefone = true;
            }
            if (movel === 1) {
                this.invalidoCelular = true;
            }
            if (movel === 2) {
                this.invalidoCelularEmergencia = true;
            }

        } else {
            if (movel === 0) {
                this.invalidoTelefone = false;
            }
            if (movel === 1) {
                this.invalidoCelular = false;
            }
            if (movel === 2) {
                this.invalidoCelularEmergencia = false;
            }
        }
    }


    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
