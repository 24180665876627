import * as angular from 'angular';
import { MeurhCreditosdescontosPassoumController } from './creditosdescontos.passoum.controller';


export class MeurhCreditosdescontosPassoumComponent implements angular.IComponentOptions {
  static selector = 'meurhCreditosdescontosPassoumComponent';
  static controller = MeurhCreditosdescontosPassoumController;
  static template = require('!!html-loader!./creditosdescontos.passoum.html');
  static transclude = true;
  static bindings = {
    passo: '=',
    colaboradorSelecionado: '=',
    entity: '=',
    entityDadosCriacao: '=',
    mensagensPassos: '=',
  };
}
