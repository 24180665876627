import { IDadosModalRetornoAfastamento } from '../../../../Meurh/Solicitacoesafastamentosretorno/models/retornoAfastamento.model';

export class ModalVisualizarAjustarRetornoService {
    static $inject = ['$uibModal'];

    constructor(private $uibModal: ng.ui.bootstrap.IModalService) { }

    public open(retornoAfastamento: IDadosModalRetornoAfastamento): angular.ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modalVisualizarAjustarRetorno.html'),
            controller: 'ModalVisualizarAjustarRetornoController',
            controllerAs: '$ctrl',
            backdrop: 'static',
            resolve: {
                entity: () => retornoAfastamento
            }
        });
    }
}
