import angular from 'angular';
import { MeurhSolicitacoespromocoesService } from '../promocoes.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IPromocao } from '../models/promocao.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhPromocoesListController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$location',
        'MeurhSolicitacoespromocoesService',
        'ListagemService'
    ];

    public busy: boolean = false;
    public entities: Array<IPromocao>;
    public filters: any = {};
    public filterSituacao: string = '';
    public filterStatus: string = '00';
    public dataInicial: string | null = null;
    public dataFinal: string | null = null;
    public constructors: any = this.entityService.constructors = [];
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_promocoes'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean
            session: any,
            nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public entityService: MeurhSolicitacoespromocoesService,
        public ListagemService: ListagemService
    ) {
        this.busy = true;
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        entityService.filters = {};
        this.montaListagemVisibilidade();
        this.entityService.constructors['forcagestor'] = $rootScope.modoGestorPermissao('visualizar_promocoes');

        $scope.$on('meurh_solicitacoespromocoes_list_finished', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
        });
        $scope.$on('meurh_solicitacoespromocoes_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
        });
    }

    $onInit(): void {
        this.filterReload();
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    search() {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }
        let filter = { search: this.entityService.filter, filters: angular.copy(this.entityService.filters) };
        return this.entityService.search(filter);
    }

    filterReload(): void {
        this.busy = true;

        if (this.constructors.hasOwnProperty('situacao')) {
            delete this.constructors.situacao;
        }

        if (this.filterSituacao !== '') {
            this.constructors.situacao = this.filterSituacao;
        }

        if (this.constructors.hasOwnProperty('status')) {
            delete this.constructors.status;
        }

        if (this.filterStatus !== '') {
            this.constructors.status = this.filterStatus;
        }

        let data = new Array;

        if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
            this.dataInicial = null;
            this.constructors.datainicial = null;
        }

        if (this.dataInicial !== null) {
            data.push({
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
            });

            delete this.constructors.datainicial;
        }

        if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
            this.dataFinal = null;
            this.constructors.datafinal = null;
        }

        if (this.dataFinal !== null) {
            data.push({
                condition: 'lte',
                value: this.dataFinal + ' 23:59:00'
            });

            delete this.constructors.datafinal;
        }

        this.constructors.data = data;
        this.constructors['forcagestor'] = this.$rootScope.modoGestorPermissao('visualizar_promocoes');
        this.entityService.constructors = this.constructors;

        this.entities = this.entityService.reload();
    }
}
