import ng from 'angular';
import { IGestoresTrabalhador } from '../../../../Meurh/Transferenciacolaboradores/models/transferenciacolaboradores.model';

export class ModalGestorExternoController implements ng.IController {

    static $inject = [
        '$uibModalInstance',
    ];

    public gestor: IGestoresTrabalhador;

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    ) { }

    // início - ações modal
    public confirmar(): void {
        this.$uibModalInstance.close(this.gestor);
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }
    // fim - ações modal
}
