import * as angular from 'angular';
import { MeurhVagasBeneficiosFormController } from './beneficios.form.controller';

export class MeurhVagasBeneficiosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhVagasBeneficiosFormComponent';
	static controller = MeurhVagasBeneficiosFormController;
	static template = require('!!html-loader!./beneficios.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
