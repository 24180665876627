import angular from 'angular';
import moment from 'moment';
import { IFalta } from '../models/falta.model';

export class MeurhSolicitacoesfaltasShowFormController implements angular.IController {
	static $inject = ['$scope', '$rootScope'];

	public form: angular.IFormController;
	public entity: IFalta;

	constructor(
		public $scope: angular.IScope,
		public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
	) {
		$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.$setDirty();
			}
		}, true);
	}

	$onInit(): void {
		this.formatarDatas();
	}

	formatarDatas(): void {
		if (typeof (this.entity.datas) === 'string') {
			this.entity.datas = this.entity.datas.replace(/[{}]/g, '').split(',');
		}

		this.entity.datas = this.entity.datas.map((value: string | moment.Moment): moment.Moment => {
			if (typeof value === 'string') {
				return moment(value);
			}

			return value;
		});
	}
}
