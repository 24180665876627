import angular from 'angular';
import moment from 'moment';
import Papa from 'papaparse';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import {
    IMudancaTrabalhadorItem,
    IAfastamentosListagem,
    IFolgasListagem,
    IEvento,
    IEventoExclusao,
    IEventoEnvio,
    IFeriadosListagem
} from '../models/escala.model';
import { IConfiguracaoScheduler, ITrabalhadorListagemScheduler } from '../models/escala.scheduler.model';
import { IErroImportacao } from '../models/escala.erros.model';
import { IFolga } from '../../../Folgas/models/folgas.model';
import { ILotacao } from '../../../Lotacao/models/lotacao.model';
import { IHorario } from '../../Solicitacoesadmissoes/models/horario.model';
import { IEstabelecimento } from '../../../Estabelecimentos/models/estabelecimento.model';
import { MeurhEscalaService } from '../escala.service';
import { MeurhEscalaVisualizacaoModalService } from '../modal.visualizacao/escala.visualizacaomodal.service';
import { MeurhEscalaErrosModalService } from '../modal.erros/escala.errosmodal.service';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { FolgasService } from '../../../Folgas/folgas.service';
import { LotacoesService } from '../../../Lotacao/lotacao.service';
import { HorariosService } from '../../../Horarios/horarios.service';
import { MeurhAfastamentosService } from '../../Afastamentos/afastamentos.service';
import { ApuracoesPontoService } from '../../Ponto/ApuracoesPonto/apuracoesponto.service';
import { EmpresasService } from '../../../Empresas/empresas.service';
import { EstabelecimentosService } from '../../../Estabelecimentos/estabelecimentos.service';
import { MeurhEscalaSchedulerService } from '../escala.scheduler.service';
import { TipoImportacaoEnum, ColunasArquivoImportacao } from '../enums/importacao';
import { TipoMudancaEnum } from '../enums/mudancatrabalhadores';
import { Scheduler, SchedulerStatic } from '../../../../shared/components/dhtmlx-scheduler/dhtmlxscheduler';

export class MeurhEscalaController {

    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        'NewToaster',
        'MeurhEscalaService',
        'MeurhEscalaVisualizacaoModalService',
        'MeurhEscalaErrosModalService',
        'TrabalhadoresService',
        'FolgasService',
        'LotacoesService',
        'HorariosService',
        'MeurhAfastamentosService',
        'ApuracoesPontoService',
        'EmpresasService',
        'EstabelecimentosService',
        'MeurhEscalaSchedulerService',
        'ModalConfirmService'
    ];

    public busy: boolean = false;
    public filaExclusao: number = 0;
    public colaboradores: Array<ITrabalhador> = []; // listagem dos colaboradores do sistema
    public lotacoes: Array<ILotacao> = []; // listagem das lotacoes do sistema
    public horarios: Array<IHorario> = []; // listagem dos horarios do sistema
    public empresas: Array<any> = []; // listagem dos empresas do sistema
    public estabelecimentos: Array<IEstabelecimento> = []; // listagem dos estabelecimentos do sistema
    public listaDeEventos: Array<IEvento> = []; // lista dos eventos no calendario
    public listaDeEventosBkp: Array<IEvento> = []; // backup da lista dos eventos ja existentes no calendario
    public listaImportados: Array<IEvento> = [];
    public estaImportando: boolean = false;
    public schedulerEventos: SchedulerStatic; // instancia do calendario
    public erroImportacao: IErroImportacao;
    public datasLimites = {
        dataInicial: new Date(new Date().getFullYear() - 1, 0, 1),
        dataFinal: new Date(new Date().getFullYear() + 1, 11, 31, 23, 59, 59),
    };
    public busyLists = {
        trabalhadores: false,
        lotacoes: false,
        horarios: false,
        empresas: false,
        estabelecimentos: false,
    };
    public busyEvents = {
        eventosFolgas: false,
        eventosFolgasFixas: false,
        eventosAfastamentos: false,
        eventosFeriados: false,
        eventosLotacoes: false,
        eventosHorarios: false,
    };
    public filtrosVisibilidade = {
        empresa: this.$rootScope.session.entities.empresa.empresa as string,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento as string
    };

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
            temPermissao: (arg: string) => boolean,
            session: any
        },
		public $state: angular.ui.IStateService,
        public NewToaster: any,
        public MeurhEscalaService: MeurhEscalaService,
        public ConfiguraVisualizacaoModalService: MeurhEscalaVisualizacaoModalService,
        public ErrosModalService: MeurhEscalaErrosModalService,
        public TrabalhadoresService: TrabalhadoresService,
        public FolgasService: FolgasService,
        public LotacoesService: LotacoesService,
        public HorariosService: HorariosService,
        public MeurhAfastamentosService: MeurhAfastamentosService,
        public ApuracoesPontoService: ApuracoesPontoService,
        public EmpresasService: EmpresasService,
        public EstabelecimentosService: EstabelecimentosService,
        public MeurhEscalaSchedulerService: MeurhEscalaSchedulerService,
        public ModalConfirmService: any
	) {
        if (
            !this.$rootScope.temPermissao('visualizar_calendario') ||
            !this.$rootScope.liberadoTela('LIBERAR_CALENDARIO')
        ) {
            $state.go('dp');
        }

        this.carregaColaboradores({ status: '00', ...this.filtrosVisibilidade });
        this.onResponseSuccess();
        this.onResponseError();

        this.$scope.$on('colaboradores_refresh', (): void => {
            this.statusImportacao(false, true);
            this.schedulerEventos.destructor();
            this.schedulerEventos = Scheduler.getSchedulerInstance();
            this.carregaColaboradores({ status: '00', ...this.filtrosVisibilidade });
        });

        // listagem de colaboradores do sistema
        this.$scope.$on('persona_trabalhadores_sempaginacao_loaded', (event: angular.IAngularEvent, args: Array<ITrabalhador>): void => {
            this.colaboradores = args;
            this.busyLists.trabalhadores = false;
            if (this.colaboradores.length) {
                this.carregaListagens();
            } else {
                this.busy = false;
            }
        });

        // listagem de lotacoes do sistema
        this.$scope.$on('lotacoes_list_finished', (event: angular.IAngularEvent, args: Array<any>): void => {
            this.lotacoes = args;
            this.busyLists.lotacoes = false;
        });

        // listagem de horarios do sistema
        this.$scope.$on('horarios_list_finished', (event: angular.IAngularEvent, args: Array<any>): void => {
            this.horarios = args;
            this.busyLists.horarios = false;
        });

        // listagem de empresas do sistema
        this.$scope.$on('empresas_list_finished', (event: angular.IAngularEvent, args: Array<any>): void => {
            this.empresas = args;
            this.busyLists.empresas = false;
        });

        // listagem de estabelecimentos do sistema
        this.$scope.$on('estabelecimentos_list_finished', (event: angular.IAngularEvent, args: Array<any>): void => {
            this.estabelecimentos = args;
            this.busyLists.estabelecimentos = false;
        });

        // eventos de folgas existentes
        this.$scope.$on('folgas_sempaginacao_loaded', (event: angular.IAngularEvent, args: Array<IFolgasListagem>): void => {
            let folgasLista: Array<IFolgasListagem> = Object.values(args);
            this.montaEventosCalendario(folgasLista, TipoImportacaoEnum.FOLGA);
            this.busyEvents.eventosFolgas = false;
        });

        // eventos de folgas fixas existentes
        this.$scope.$on('folgas_fixas_loaded', (event, args: Array<IFolgasListagem>): void => {
            let folgasFixasLista: Array<IFolgasListagem> = Object.values(args);
            this.montaEventosCalendario(folgasFixasLista, TipoImportacaoEnum.FOLGAFIXA);
            this.busyEvents.eventosFolgasFixas = false;
        });

        // eventos de feriados existentes
        this.$scope.$on('persona_trabalhadores_feriados_loaded', (event, args: Array<any>): void => {
            let feriadosLista: Array<IFeriadosListagem> = Object.values(args);
            this.montaEventosCalendario(feriadosLista, TipoImportacaoEnum.FERIADO);
            this.busyEvents.eventosFeriados = false;
        });

        // eventos de afastamentos existentes
        this.$scope.$on('meurh_solicitacoesafastamentos_sempaginacao_loaded', (event: angular.IAngularEvent, args: Array<IAfastamentosListagem>): void => {
            let afastamentosLista: Array<IAfastamentosListagem> = Object.values(args);
            this.montaEventosCalendario(afastamentosLista, TipoImportacaoEnum.AFASTAMENTO);
            this.busyEvents.eventosAfastamentos = false;
        });

        // eventos de lotacao/horarios existentes
        this.$scope.$on('mudancastrabalhadores_sempaginacao_loaded', (event: angular.IAngularEvent, args: Array<IMudancaTrabalhadorItem>, tipo: number): void => {
            let eventosLista: Array<IMudancaTrabalhadorItem> = this.verificaMudancaUnica(Object.values(args));
            if (tipo === TipoMudancaEnum.LOTACAO) {
                this.montaEventosCalendario(eventosLista, TipoImportacaoEnum.LOTACAO);
                this.busyEvents.eventosLotacoes = false;
            } else if (tipo === TipoMudancaEnum.HORARIO) {
                this.montaEventosCalendario(eventosLista, TipoImportacaoEnum.HORARIO);
                this.busyEvents.eventosHorarios = false;
            }
        });

        this.$scope.$on('calendario_salva_evento', (event: angular.IAngularEvent, args: IEvento): void => {
            this.salvar({
                eventos: [this.montaObjetoRequisicao(args, 'salvar')],
                tipoEventos: args.evento
            });
        });

        this.$scope.$on('calendario_exclui_evento', (event: angular.IAngularEvent, args: IEvento): void => {
            this.excluir({
                [args.evento]: [this.montaObjetoRequisicao(args, 'excluir')]
            });
        });

        this.$scope.$on('calendario_carregaEventos', (event: angular.IAngularEvent, args: Date): void => {
            this.busy = true;
            this.carregaEventos(args);
        });

        // inicia o calendario quando todas as listagens estiverem prontas
        this.$scope.$watchGroup(['mrh_scl_cntrllr.busyLists.trabalhadores',
        'mrh_scl_cntrllr.busyLists.lotacoes',
        'mrh_scl_cntrllr.busyLists.horarios',
        'mrh_scl_cntrllr.busyLists.empresas',
        'mrh_scl_cntrllr.busyLists.estabelecimentos'], (newValues: Array<boolean>, oldValues: Array<boolean>): void => {
			if (newValues !== oldValues && (newValues.every((busy: boolean) => busy === false))) {
                let configCalendario: IConfiguracaoScheduler = {
                    colaboradores: this.colaboradores,
                    lotacoes: this.lotacoes,
                    horarios: this.horarios,
                    dataInicial: this.datasLimites.dataInicial,
                    dataFinal: this.datasLimites.dataFinal
                };
                this.MeurhEscalaSchedulerService.configuraCalendario(this.schedulerEventos, configCalendario);
                this.schedulerEventos.init('schedulerEventos', new Date(), 'mes_timeline');
                this.carregaEventos(new Date());
			}
		});

        this.$scope.$watchGroup(['mrh_scl_cntrllr.busyEvents.eventosFolgas',
        'mrh_scl_cntrllr.busyEvents.eventosFolgasFixas',
        'mrh_scl_cntrllr.busyEvents.eventosLotacoes',
        'mrh_scl_cntrllr.busyEvents.eventosHorarios',
        'mrh_scl_cntrllr.busyEvents.eventosAfastamentos',
        'mrh_scl_cntrllr.busyEvents.eventosFeriados'], (newValues: Array<boolean>, oldValues: Array<boolean>): void => {
			if (newValues !== oldValues && (newValues.every((busy: boolean) => busy === false))) {
                if (this.estaImportando) {
                    this.listaImportados.map((ev, index) => {
                        ev.id = (this.listaDeEventos.length + 1) + index;
                    });
                    this.listaDeEventos = [...this.listaDeEventos, ...this.listaImportados];
                }
                this.renderizaEventos();
                this.busy = false;
			}
		});
    }

    $onInit() {
        this.schedulerEventos = Scheduler.getSchedulerInstance();
    }

    salvar(eventos?: IEventoEnvio): void {
        let eventosEnviar: IEventoEnvio = eventos ? eventos : this.montaEnvio();
        this.busy = true;
        if (eventosEnviar.tipoEventos === TipoImportacaoEnum.FOLGA) {
            this.FolgasService.salvarEmLote(eventosEnviar.eventos as Array<IFolga>);
        } else if (eventosEnviar.tipoEventos === TipoImportacaoEnum.LOTACAO ||
            eventosEnviar.tipoEventos === TipoImportacaoEnum.HORARIO) {
            const tipo = eventosEnviar.tipoEventos === TipoImportacaoEnum.LOTACAO ? TipoMudancaEnum.LOTACAO : TipoMudancaEnum.HORARIO;
            this.MeurhEscalaService.salvarEmLote(eventosEnviar.eventos, tipo);
        }
    }

    excluir(eventos: Partial<IEventoExclusao>): void {
        this.busy = true;
        if (eventos.folga && eventos.folga.length) {
            this.filaExclusao++;
            this.FolgasService.excluirEmLote(eventos.folga);
        }
        if (eventos.lotacao && eventos.lotacao.length) {
            this.filaExclusao++;
            this.MeurhEscalaService.excluirEmLote(eventos.lotacao, TipoMudancaEnum.LOTACAO);
        }
        if (eventos.horario && eventos.horario.length) {
            this.filaExclusao++;
            this.MeurhEscalaService.excluirEmLote(eventos.horario, TipoMudancaEnum.HORARIO);
        }
    }

    excluirEmLote(): void {
        let colaboradoresVisiveis = this.schedulerEventos.serverList('colaboradoresAtuais');
        let eventosVisiveis: Array<IEvento> = this.schedulerEventos['get_visible_events']();
        let eventosExcluir: IEventoExclusao = this.montaExclusao(colaboradoresVisiveis, eventosVisiveis);

        if (!eventosExcluir.folga.length && !eventosExcluir.lotacao.length && !eventosExcluir.horario.length) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não há folgas para serem excluidas.' // quando ativar as outras exclusoes em lote, modificar
            });
            return;
        }
        let customText = this.montaMsgModalExclusao(eventosExcluir, colaboradoresVisiveis.length);
        let confirm = this.ModalConfirmService.open(eventosExcluir, 'Calendário', false, customText);
        confirm.result
        .then(() => {
            this.excluir(eventosExcluir);
        })
        .catch(function () {/**/});
    }

    statusImportacao(status: boolean, atualizaEntities?: boolean) {
        if (atualizaEntities) {
            this.schedulerEventos.clearAll();
            angular.copy(this.listaDeEventosBkp, this.listaDeEventos);
            this.renderizaEventos();
            this.resetErrosImportacao();
        }
        this.estaImportando = status;
        this.MeurhEscalaSchedulerService.habilitaCriacaoIndividualCalendario(this.schedulerEventos, status);
    }

    abrirModalErros(): void {
        this.ErrosModalService.open(this.erroImportacao.erros, this.erroImportacao.tipoImportacao).result.then(() => {/**/})
        .catch((error: any): void => {/**/});
    }

    downloadErros(): void {
        // constroi o csv com as colunas de identificador, data, solicitacao (se precisar de guid) e descricao do erro
        let data = this.erroImportacao.erros.map((event) => {
          return {
            identificador: event.importacao?.identificador,
            [ColunasArquivoImportacao[this.erroImportacao.tipoImportacao].data]: event.data,
            ...((this.erroImportacao.tipoImportacao !== TipoImportacaoEnum.FOLGA) && {
                [ColunasArquivoImportacao[this.erroImportacao.tipoImportacao].dataFinal]: event.dataFim,
                [this.erroImportacao.tipoImportacao]: event.importacao?.[this.erroImportacao.tipoImportacao],
                empresa: event.importacao?.empresa,
            }),
            ...((this.erroImportacao.tipoImportacao === TipoImportacaoEnum.FOLGA && event.importacao?.estabelecimento) && {
                estabelecimento: event.importacao?.estabelecimento,
            }),
            ...((this.erroImportacao.tipoImportacao === TipoImportacaoEnum.FOLGA && event.importacao?.descricao) && {
                descricao: event.importacao?.descricao,
            }),
            descricao_erro: event.descricao
        };
        });
        let csv = Papa.unparse(data, { delimiter: ';' });
        let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('download', 'erros.csv');
        downloadLink.setAttribute('href', window.URL.createObjectURL(csvData));
        downloadLink.click();
    }

    msgCardAvisoErros(): string {
        let palavras: Array<string> = ['Dos', 'o(s)', 'importado(s)', 'evento(s)'];
        if (this.erroImportacao.tipoImportacao === TipoImportacaoEnum.FOLGA) {
            palavras = ['Das', 'a(s)', 'importada(s)', 'folga(s)'];
        } else if (this.erroImportacao.tipoImportacao === TipoImportacaoEnum.LOTACAO) {
            palavras = ['Das', 'a(s)', 'importada(s)', 'lotação(ões)'];
        } else if (this.erroImportacao.tipoImportacao === TipoImportacaoEnum.HORARIO) {
            palavras = ['Dos', 'o(s)', 'importado(s)', 'horário(s)'];
        }
        return `${palavras[0]} ${this.erroImportacao.total} ${palavras[3]} no arquivo, há ${this.erroImportacao.erros.length} erro(s)! Você pode iniciar uma nova importação após ajustá-lo(s), ou, ignorá-lo(s) e salvar apenas ${palavras[1]} ${palavras[3]} ${palavras[2]}.`;
    }

    private resetErrosImportacao(): void {
        this.erroImportacao = {
            tipoImportacao: '',
            origemErro: '',
            erros: [],
            validos: undefined,
            total: undefined
        };
    }

    private carregaColaboradores(filter?: {status: string, empresa: string, estabelecimento: string}): void {
        this.busy = this.busyLists.trabalhadores = true;
        this.MeurhEscalaSchedulerService.reload();
        this.TrabalhadoresService.loadNoPagination(filter);
    }

    private carregaListagens(): void {
        this.LotacoesService.constructors.empresa = this.HorariosService.constructors.empresa = this.filtrosVisibilidade.empresa;
        this.LotacoesService.constructors.estabelecimento = this.HorariosService.constructors.estabelecimento = this.filtrosVisibilidade.estabelecimento;
        this.LotacoesService.reload(false);
        this.HorariosService.reload(false);
        this.EstabelecimentosService.reload();
        this.EmpresasService.reload();

        Object.keys(this.busyLists).forEach((i) => {
            if (i !== 'trabalhadores') {
                this.busyLists[i] = true;
            }
        });
    }

    private carregaEventos(dataAtual: Date): void {
        this.listaImportados = this.estaImportando ? this.listaDeEventos.filter(ev => ev.importacao) : [];
        this.schedulerEventos.clearAll();
        this.listaDeEventos = [];

        const params = {
            empresa: this.filtrosVisibilidade.empresa,
            ...((this.filtrosVisibilidade.estabelecimento !== '') && {
                trabalhador_estabelecimento: this.filtrosVisibilidade.estabelecimento,
            }),
        };

        const dataMesAnterior = new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1, 20);
        const dataMesPosterior = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 10);
        let datainicio = moment(dataMesAnterior);
        let datafim = moment(dataMesPosterior);
        if (datafim.isAfter(moment(this.datasLimites.dataFinal))) {
            datafim = moment(this.datasLimites.dataFinal);
        }
        const intervaloDeDatas = {
            datainicio: datainicio.format('YYYY-MM-DD'),
            datafim: datafim.format('YYYY-MM-DD')
        };

        this.FolgasService.loadNoPagination({...intervaloDeDatas, ...params});
        this.FolgasService.getFolgasFixas({...intervaloDeDatas, ...params});
        this.MeurhAfastamentosService.loadNoPagination({...intervaloDeDatas, ...params});
        this.TrabalhadoresService.getFeriadosTrabalhadores({...intervaloDeDatas, ...params});
        this.MeurhEscalaService.listarSemPaginacao({
            datainicial: intervaloDeDatas.datainicio,
            tipo: TipoMudancaEnum.LOTACAO,
            excluida: 0,
            ...params
        });
        this.MeurhEscalaService.listarSemPaginacao({
            datainicial: intervaloDeDatas.datainicio,
            tipo: TipoMudancaEnum.HORARIO,
            excluida: 0,
            ...params
        });

        Object.keys(this.busyEvents).forEach((i) =>  this.busyEvents[i] = true);
    }

    private onResponseSuccess(): void {
        this.$scope.$on('folgas_lote_submitted', (event, args) => this.responseSuccess(event, args, TipoImportacaoEnum.FOLGA, 'save'));
        this.$scope.$on('folgas_lote_deleted', (event, args) => {
            this.filaExclusao--;
            if (this.filaExclusao === 0) {
                this.responseSuccess(event, args, TipoImportacaoEnum.FOLGA, 'delete');
            }
        });
        this.$scope.$on('mudancastrabalhadores_lote_submitted', (event, args, tipo) =>  {
            const importacao = tipo === TipoMudancaEnum.LOTACAO ? TipoImportacaoEnum.LOTACAO : TipoImportacaoEnum.HORARIO;
            this.responseSuccess(event, args, importacao, 'save');
        });
        this.$scope.$on('mudancastrabalhadores_lote_deleted', (event, args, tipo) => {
            const importacao = tipo === TipoMudancaEnum.LOTACAO ? TipoImportacaoEnum.LOTACAO : TipoImportacaoEnum.HORARIO;
            this.filaExclusao--;
            if (this.filaExclusao === 0) {
                this.responseSuccess(event, args, importacao, 'delete');
            }
        });
    }

    private responseSuccess(event: angular.IAngularEvent, args: any, eventType: string, requestType: string): void {
        this.statusImportacao(false);
        let message = '';
        if (eventType === TipoImportacaoEnum.FOLGA) {
            message = (requestType === 'save') ? 'As folgas foram salvas com sucesso!' : 'As folgas foram excluídas com sucesso!';
            if (args.dataLength === 1) {
                message = (requestType === 'save') ? 'A folga foi salva com sucesso!' : 'A folga foi excluída com sucesso!';
            }
        } else if (eventType === TipoImportacaoEnum.LOTACAO) {
            message = (requestType === 'save') ? 'As lotações foram salvas com sucesso!' : 'As lotações foram excluídas com sucesso!';
            if (args.dataLength === 1) {
                message = (requestType === 'save') ? 'A lotação foi salva com sucesso!' : 'A lotação foi excluída com sucesso!';
            }
        } else if (eventType === TipoImportacaoEnum.HORARIO) {
            message = (requestType === 'save') ? 'Os horários foram salvos com sucesso!' : 'Os horários foram excluídos com sucesso!';
            if (args.dataLength === 1) {
                message = (requestType === 'save') ? 'O horário foi salvo com sucesso!' : 'O horário foi excluído com sucesso!';
            }
        }
        this.NewToaster.pop({
            type: 'success',
            title: message
        });
        this.resetErrosImportacao();
        this.carregaEventos(this.schedulerEventos.getState().date);
    }

    private onResponseError(): void {
        this.$scope.$on('folgas_lote_submit_error', (event, args) => this.responseError(event, args, TipoImportacaoEnum.FOLGA, 'save'));
        this.$scope.$on('folgas_lote_delete_error', (event, args) => this.responseError(event, args, TipoImportacaoEnum.FOLGA, 'delete'));
        this.$scope.$on('mudancastrabalhadores_lote_submit_error', (event, args, tipo) =>  {
            const importacao = tipo === TipoMudancaEnum.LOTACAO ? TipoImportacaoEnum.LOTACAO : TipoImportacaoEnum.HORARIO;
            this.responseError(event, args, importacao, 'save');
        });
        this.$scope.$on('mudancastrabalhadores_lote_delete_error', (event, args, tipo) => {
            const importacao = tipo === TipoMudancaEnum.LOTACAO ? TipoImportacaoEnum.LOTACAO : TipoImportacaoEnum.HORARIO;
            this.responseError(event, args, importacao, 'delete');
        });
    }

    private responseError(event: angular.IAngularEvent, args: any, eventType: string, requestType: string): void {
        this.statusImportacao(false);
        this.busy = false;
        if ((args.response && args.response.data) && typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
            if (args.response.data.message === 'Validation Failed') {
                let message = args.response.data.errors.errors[0];
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Erro de validação',
                    body: message,
                    bodyOutputType: 'trustedHtml'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            }
        } else {
            let message = '';
            if (eventType === TipoImportacaoEnum.FOLGA) {
                message = (requestType === 'save') ? 'Ocorreu um erro ao salvar a(s) folga(s)!' : 'Ocorreu um erro ao excluir a(s) folga(s)!';
            } else if (eventType === TipoImportacaoEnum.LOTACAO) {
                message = (requestType === 'save') ? 'Ocorreu um erro ao salvar a(s) lotação(ões)!' : 'Ocorreu um erro ao excluir a(s) lotação(ões)!';
            } else if (eventType === TipoImportacaoEnum.HORARIO) {
                message = (requestType === 'save') ? 'Ocorreu um erro ao salvar o(s) horário(s)!' : 'Ocorreu um erro ao excluir o(s) horário(s)!';
            }
            this.NewToaster.pop({
                type: 'error',
                title: message
            });
        }
        this.$state.reload();
    }

    private verificaMudancaUnica(eventosLista: Array<IMudancaTrabalhadorItem>) {
        this.colaboradores.forEach((colaborador: ITrabalhador) => {
            let eventosDoColaborador = eventosLista.filter(evento => evento.trabalhador.trabalhador === colaborador.trabalhador);
            if (eventosDoColaborador.length === 1) {
                eventosLista.find(evento => evento.trabalhador.trabalhador === colaborador.trabalhador)!.mudancaUnica = true;
            }
        });
        return eventosLista;
    }

    private montaEnvio(): IEventoEnvio {
        let importados: IEventoEnvio = {
            eventos: [],
            tipoEventos: ''
        };
        let eventosCalendario: Array<IEvento> = this.schedulerEventos.getEvents();
        let indexFinal = eventosCalendario.length;
        for (let index = this.listaDeEventosBkp.length; index < indexFinal; index++) {
            importados.eventos.push(this.montaObjetoRequisicao(eventosCalendario[index], 'salvar'));
        }
        importados.tipoEventos = eventosCalendario[indexFinal - 1].evento;
        return importados;
    }

    private montaExclusao(colaboradoresVisiveis: Array<ITrabalhadorListagemScheduler>, eventosVisiveis: Array<IEvento>): any {
        let eventosExcluir: IEventoExclusao = {
            folga: [],
            lotacao: [],
            horario: [],
        };
        colaboradoresVisiveis.forEach((colaborador: ITrabalhadorListagemScheduler) => {
            eventosVisiveis.forEach((event: IEvento) => {
                let colideDataAdmissao = this.dataForaDoIntervaloExclusao(colaborador.colaboradorObj, event, true);
                let colideDataRescisao = this.dataForaDoIntervaloExclusao(colaborador.colaboradorObj, event);
                if (colaborador.colaboradorObj.trabalhador === event.colaborador.trabalhador && !colideDataAdmissao && !colideDataRescisao) {
                    if (event.evento === TipoImportacaoEnum.FOLGA) {
                        eventosExcluir.folga.push(this.montaObjetoRequisicao(event, 'excluir'));
                    } /* else if (event.evento === TipoImportacaoEnum.LOTACAO && event.start_date_only && !event.readonly_event) {
                        eventosExcluir.lotacao.push(this.montaObjetoRequisicao(event, 'excluir'));
                    } else if (event.evento === TipoImportacaoEnum.HORARIO && event.start_date_only && !event.readonly_event) {
                        eventosExcluir.horario.push(this.montaObjetoRequisicao(event, 'excluir'));
                    } */
                }
            });
        });
        return eventosExcluir;
    }

    private montaObjetoRequisicao(evento: IEvento, tipoRequisicao: string): any {
        if (evento.evento === TipoImportacaoEnum.FOLGA) {
            return {
                datafolga: moment(evento.start_date).format('YYYY-MM-DD'),
                descricao: evento.descricao,
                trabalhador: evento.colaborador.trabalhador,
                empresa: evento.empresa,
                estabelecimento: evento.estabelecimento,
                ...((tipoRequisicao === 'salvar' ? true : false) && {
                    justificativa: '',
                })
            };
        } else if (evento.evento === TipoImportacaoEnum.LOTACAO || evento.evento === TipoImportacaoEnum.HORARIO) {
            return {
                datainicial: moment(evento.start_date).format('YYYY-MM-DD'),
                datafinal: !evento.start_date_only ? moment(evento.end_date).format('YYYY-MM-DD') : null,
                tipo: evento.evento === TipoImportacaoEnum.LOTACAO ? TipoMudancaEnum.LOTACAO : TipoMudancaEnum.HORARIO,
                pk_entidade: evento.evento === TipoImportacaoEnum.LOTACAO ? evento.lotacao : evento.horario,
                trabalhador: evento.colaborador.trabalhador,
                empresa: evento.empresa,
                estabelecimento: evento.estabelecimento,
                ...((tipoRequisicao === 'excluir' ? true : false) && {
                    mudancatrabalhador: evento.mudancatrabalhador,
                }),
            };
        }
    }

    private renderizaEventos(): void {
        this.schedulerEventos.parse(this.listaDeEventos);
    }

    private montaEventosCalendario(
        eventosLista: Array<IMudancaTrabalhadorItem | IFolgasListagem | IAfastamentosListagem | IFeriadosListagem>,
        tipo: string
    ) {
        eventosLista.forEach((eventoEntity) => {
            this.colaboradores.forEach((colaborador) => {
                this.adicionaEventoCalendario(eventoEntity, colaborador, tipo);
            });
        });
        angular.copy(this.listaDeEventos, this.listaDeEventosBkp);
    }

    private adicionaEventoCalendario(
        eventoEntity: IMudancaTrabalhadorItem | IFolgasListagem | IAfastamentosListagem | IFeriadosListagem,
        colaborador: ITrabalhador,
        tipo: string
    ) {
        if ((<IFolgasListagem> eventoEntity).folgas && (<IFolgasListagem> eventoEntity).trabalhador === colaborador.trabalhador) {
            (<IFolgasListagem> eventoEntity).folgas.forEach((data) => {
                let evento: Partial<IEvento> = {
                    start_date: moment(data.datafolga).toDate(),
                    end_date: moment(data.datafolga).toDate(),
                    colaborador,
                    evento: tipo,
                    descricao: data.descricao
                };
                this.MeurhEscalaSchedulerService.adicionaEventoCalendario(this.schedulerEventos, evento, this.listaDeEventos);
            });
        } else if ((<IAfastamentosListagem> eventoEntity).afastamentos && (<IAfastamentosListagem> eventoEntity).trabalhador === colaborador.trabalhador) {
            (<IAfastamentosListagem> eventoEntity).afastamentos.forEach((data) => {
                let evento: Partial<IEvento> = {
                    start_date: moment(data.data).toDate(),
                    end_date: moment(data.data).add(data.dias - 1, 'day').toDate(),
                    colaborador,
                    evento: tipo,
                    afastamento: data.tipohistorico.descricao,
                };
                this.MeurhEscalaSchedulerService.adicionaEventoCalendario(this.schedulerEventos, evento, this.listaDeEventos);
            });
        } else if ((<IFeriadosListagem> eventoEntity).feriados && (<IFeriadosListagem> eventoEntity).trabalhador === colaborador.trabalhador) {
            (<IFeriadosListagem> eventoEntity).feriados.forEach((data) => {
                let evento: Partial<IEvento> = {
                    start_date: moment(data.dataferiado).toDate(),
                    end_date: moment(data.dataferiado).toDate(),
                    colaborador,
                    evento: tipo,
                };
                this.MeurhEscalaSchedulerService.adicionaEventoCalendario(this.schedulerEventos, evento, this.listaDeEventos);
            });
        } else if (((<IMudancaTrabalhadorItem> eventoEntity).lotacao && (<IMudancaTrabalhadorItem> eventoEntity).lotacao.lotacao) &&
        (<IMudancaTrabalhadorItem> eventoEntity).trabalhador.trabalhador === colaborador.trabalhador) {
            let dataFim = (<IMudancaTrabalhadorItem> eventoEntity).datafinal ? moment((<IMudancaTrabalhadorItem> eventoEntity).datafinal).toDate() : moment(this.datasLimites.dataFinal, 'DD/MM/YYYY').toDate();
            let evento: Partial<IEvento> = {
                start_date: moment((<IMudancaTrabalhadorItem> eventoEntity).datainicial).toDate(),
                end_date: dataFim,
                start_date_only: !(<IMudancaTrabalhadorItem> eventoEntity).datafinal,
                readonly_event: (<IMudancaTrabalhadorItem> eventoEntity).mudancaUnica,
                colaborador,
                evento: tipo,
                lotacao: (<IMudancaTrabalhadorItem> eventoEntity).lotacao.lotacao,
                mudancatrabalhador: (<IMudancaTrabalhadorItem> eventoEntity).mudancatrabalhador
            };
            this.MeurhEscalaSchedulerService.adicionaEventoCalendario(this.schedulerEventos, evento, this.listaDeEventos);
        } else if (((<IMudancaTrabalhadorItem> eventoEntity).horario && (<IMudancaTrabalhadorItem> eventoEntity).horario.horario) &&
        (<IMudancaTrabalhadorItem> eventoEntity).trabalhador.trabalhador === colaborador.trabalhador) {
            let dataFim = (<IMudancaTrabalhadorItem> eventoEntity).datafinal ? moment((<IMudancaTrabalhadorItem> eventoEntity).datafinal).toDate() : moment(this.datasLimites.dataFinal, 'DD/MM/YYYY').toDate();
            let evento: Partial<IEvento> = {
                start_date: moment((<IMudancaTrabalhadorItem> eventoEntity).datainicial).toDate(),
                end_date: dataFim,
                start_date_only: !(<IMudancaTrabalhadorItem> eventoEntity).datafinal,
                readonly_event: (<IMudancaTrabalhadorItem> eventoEntity).mudancaUnica,
                colaborador,
                evento: tipo,
                horario: (<IMudancaTrabalhadorItem> eventoEntity).horario.horario,
                mudancatrabalhador: (<IMudancaTrabalhadorItem> eventoEntity).mudancatrabalhador
            };
            this.MeurhEscalaSchedulerService.adicionaEventoCalendario(this.schedulerEventos, evento, this.listaDeEventos);
        }
    }

    private montaMsgModalExclusao(eventosExcluidos: IEventoExclusao, qntColaboradores: number): object {
        let confirmText: string, eventosFrase: string =  '';
        let resumoEventosExcluidos: Array<string> = [];
        Object.entries(eventosExcluidos).forEach((propriedade: [string, Array<Partial<IFolga|IMudancaTrabalhadorItem>>]) => {
            if (propriedade[1].length) {
                if (propriedade[0] === TipoImportacaoEnum.FOLGA) {
                    resumoEventosExcluidos.push(`${propriedade[1].length} folga(s)`);
                } else if (propriedade[0] === TipoImportacaoEnum.LOTACAO) {
                    resumoEventosExcluidos.push(`${propriedade[1].length} lotação(ões)`);
                } else if (propriedade[0] === TipoImportacaoEnum.HORARIO) {
                    resumoEventosExcluidos.push(`${propriedade[1].length} horário(s)`);
                }
            }
        });
        resumoEventosExcluidos.forEach((resumoEvento, index) => {
            if (index === 0) {
                eventosFrase = resumoEvento;
            } else if (index + 1 === resumoEventosExcluidos.length) {
                eventosFrase += ' e de ' + resumoEvento;
            } else {
                eventosFrase += ', ' + resumoEvento;
            }
        });

        confirmText = `Você tem certeza que deseja realizar a exclusão de ${eventosFrase} para ${qntColaboradores} colaborador(es) de acordo com as informações na tela?`;

        return {
            title: 'Excluir',
            subtitle: 'Após a exclusão não será possível reverter a ação.',
            confirmText,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir',
        };
    }

    private dataForaDoIntervaloExclusao(colaborador: ITrabalhador, ev: IEvento, verificaAdmissao: boolean = false): boolean {
        if (verificaAdmissao) {
            if (moment(colaborador.dataadmissao).isAfter(ev.start_date)) {
                return true;
            }
            return false;
        }
        if (colaborador.datarescisao &&
            ((!ev.start_date_only && moment(colaborador.datarescisao).isSameOrBefore(ev.end_date)) ||
            (ev.start_date_only && moment(colaborador.datarescisao).isSameOrBefore(ev.start_date)))
        ) {
            return true;
        }
        return false;
    }
}
