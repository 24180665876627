import angular = require('angular');

export class MeurhSolicitacoesFormShowController implements ng.IController {
	static $inject = ['$scope'];

	public form: angular.IFormController;

	constructor(
		public $scope: angular.IScope
	) {
		$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
				this.form.$setDirty();
			}
		}, true);
	}
}
