import angular from 'angular';
import { ITrabalhador } from '../../../../../../Trabalhadores/models/trabalhador.model';
import { TrabalhadoresService } from '../../../../../../Trabalhadores/trabalhadores.service';

export class MeurhEscalaColaboradoresFormModalController {

    static $inject = [
        '$scope',
        '$rootScope',
        '$uibModalInstance',
        'colaboradoresSelecionados',
        'TrabalhadoresService',
        'empresa',
        'estabelecimento',
    ];

    public entities: Array<ITrabalhador>;
    public checkboxTodosColaboradores: boolean = false;
    public colaboradoresSelecionadosId: Array<string> = this.colaboradoresSelecionados.map(colaborador => colaborador.trabalhador);
    public filtros: any = {
        lotacao: null,
        horario: null,
        search: null
    };

    public fields = [
        {
            value: 'selected',
            label: '',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnScope: {
                selecionaColaborador: (entity: ITrabalhador): void => {
                    this.selecionaColaborador(entity);
                    this.setTodosColaboradoresCheckbox();
                },
                estaSelecionado: (entity: ITrabalhador): boolean => {
                    return this.colaboradoresSelecionadosId.indexOf(entity.trabalhador) > -1;
                },
            },
            customCollumnHtml: () => {
                let someHtmlVar = `
                    <div class="custom-checkbox text-center">
                        <label>
                            <input
                                type="checkbox"
                                id="{{ tableRowEntity.trabalhador }}"
                                name="{{ tableRowEntity.trabalhador }}"
                                ng-value="tableRowEntity.trabalhador"
                                ng-checked="customCollumnScope.estaSelecionado(tableRowEntity)"
                                ng-click="customCollumnScope.selecionaColaborador(tableRowEntity)"
                            />
                            <span class="checkmark false"></span>
                        </label>
                    </div>
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'nome',
            label: 'Nome',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'cargo',
            label: 'Cargo',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'lotacaoobj.nome',
            label: 'Lotação',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'horario.nome',
            label: 'Horário',
            type: 'string',
            style: 'title',
            copy: '',
        },
    ];

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService,
        public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        public colaboradoresSelecionados: Array<ITrabalhador>,
        public TrabalhadoresService: TrabalhadoresService,
        public empresa: string,
        public estabelecimento: string
    ) {
        this.search();
        this.colaboradoresSelecionados = [...colaboradoresSelecionados];

        $scope.$on('persona_trabalhadores_list_loaded', (event: angular.IAngularEvent, args: Array<ITrabalhador>): void => {
            this.entities = args.map((colaborador: ITrabalhador) => {
                colaborador.selected = false;
                return colaborador;
            });
            this.setTodosColaboradoresCheckbox();
        });
    }

    selecionaColaborador(entity: ITrabalhador): void {
        const index = this.colaboradoresSelecionadosId.findIndex((colaboradorId) => colaboradorId === entity.trabalhador);
        if (index > -1) {
            this.colaboradoresSelecionadosId.splice(index, 1);
            this.colaboradoresSelecionados.splice(index, 1);
            return;
        }
        this.colaboradoresSelecionadosId = [...this.colaboradoresSelecionadosId, entity.trabalhador];
        this.colaboradoresSelecionados = [...this.colaboradoresSelecionados, entity];
    }

    selecionaTodosColaboradores(): void {
        this.colaboradoresSelecionadosId = this.checkboxTodosColaboradores ? this.entities.map(colaborador => colaborador.trabalhador) : [];
        this.colaboradoresSelecionados = this.checkboxTodosColaboradores ? [...this.entities] : [];
    }

    setTodosColaboradoresCheckbox(): void {
        if (this.entities.length && (this.entities.length === this.colaboradoresSelecionadosId.length)) {
            this.checkboxTodosColaboradores = this.entities.every(colab => {
                if (this.colaboradoresSelecionadosId.includes(colab.trabalhador)) {
                    return true;
                }
                return false;
            });
            return;
        }
        this.checkboxTodosColaboradores = false;
    }

    search(event?: angular.IAngularEvent & { detail: any }): void {
        if (event) {
            this.filtros.search = event.detail.search;
        }

        const constructor = {
            empresa: this.empresa,
            estabelecimento: this.estabelecimento,
            ...(this.filtros.lotacao && {
                lotacao: this.filtros.lotacao.lotacao
            }),
            ...(this.filtros.horario && {
                horario: this.filtros.horario.horario
            }),
            status: '00'
        };
        this.TrabalhadoresService.constructors = constructor;
        this.TrabalhadoresService.search({ search: this.filtros.search });
    }

    loadMore(): void {
        this.TrabalhadoresService.loadMore();
    }

    close(data?: Array<ITrabalhador>): void {
        this.$uibModalInstance.close(data);
    }
}
