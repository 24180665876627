import angular from 'angular';
import { LoadParams } from '../../../../shared/models/loadparams.model';
import { ISolicitacao } from '../models/solicitacao.model';
import { IAdmissaoUnificada } from '../../AdmissaoCompleta/models/admissao.model';

export class MeurhSolicitacoes {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

	public entities: Array<ISolicitacao> = [];
	public loaded: boolean = false;
	public constructors: any = {};
	public after: any = {};
	public filters: any = {};
	public number_to_load: number = 3;
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter: any = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $q: angular.IQService
	) {
		this.loadParams = new LoadParams();
		this.fechar = this.fechar.bind(this);
		this.delete = this.delete.bind(this);
	}

	reload(): Array<ISolicitacao> {
		if (this.loading_deferred) {
			this.loading_deferred.resolve();
		}

		this.loadParams.finished = false;
		this.loadParams.to_load = this.number_to_load;
		this.after = {};
		this.loaded = false;
		this.entities.length = 0;
		this.loadParams.busy = false;

		return this.load();
	}

	search(filter: any): Array<ISolicitacao> {
		if (typeof filter !== 'undefined') {
			this.filter = filter.search;

			Object.keys(this.filters).forEach((k: any): void => {
				delete this.filters[k];
			});

			for (let fil in filter.filters) {
				if (fil.indexOf('.') > -1) {
					let filterSplit = fil.split('.');

					this.filters[filterSplit[0]] = {};
					this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
				} else {
					this.filters[fil] = filter.filters[fil];
				}
			}
		}

		return this.reload();
	}

	_load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('meurh_solicitacoes_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
				timeout: this.loading_deferred.promise
			})
				.then((response: any): void => {
					resolve(response.data);
				})
				.catch((response: any): void => {
					reject(response);
				});
		});
	}

	load(): Array<ISolicitacao> {
		if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
			this.loadParams.busy = true;

			this._load(this.constructors, this.after, this.filter)
				.then((data: any): void => {
					if (data.length > 0) {
						for (let i = 0; i < data.length; i++) {
							this.entities.push(data[i]);
						}

						this.after['codigo'] = this.entities[this.entities.length - 1]['codigo'];
						this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
					}

					this.$rootScope.$broadcast('meurh_solicitacoes_list_hasdata', this.entities);
					if (data.length < 20) {
						this.loadParams.finished = true;
						this.$rootScope.$broadcast('meurh_solicitacoes_list_finished', this.entities);
					} else {
						this.loadParams.finished = false;
					}

					this.loaded = true;
					this.loadParams.to_load--;
				})
				.catch((error: any): void => {
					if (error.xhrStatus !== 'abort') {
						this.loadParams.finished = true;
					}
				})
				.finally((): void => {
					if (this.loaded || this.loadParams.finished) {
						this.loadParams.busy = false;
					}

					this.load();
				});
		}

		return this.entities;
	}

	loadMore(): void {
		this.loadParams.to_load = this.number_to_load;
		this.load();
	}

	find(identifier: string): ISolicitacao | null {
		for (let i in this.entities) {
			if (this.entities[i]['solicitacao'] === identifier) {
				return this.entities[i];
			}
		}

		return null;
	}

	delete($identifier: any, force: boolean): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		$identifier['$$__submitting'] = true;

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http({
				method: 'DELETE',
				url: this.nsjRouting.generate('meurh_solicitacoes_delete', angular.extend(this.constructors, { 'id': $identifier.solicitacao }), true),
				data: angular.copy($identifier),
				timeout: this.loading_deferred.promise,
				headers: {
					'Content-Type': 'application/json'
				}
			})
				.then((response: any): void => {
					this.$rootScope.$broadcast('meurh_solicitacoes_deleted', {
						entity: $identifier,
						response: response
					});

					resolve(response.data);
				})
				.catch((response: any): void => {
					reject(response);
				}).finally((): void => {
					$identifier['$$__submitting'] = false;
				});
		});
	}

	_save(entity: ISolicitacao, autosave: boolean): angular.IHttpPromise<any> {
		let method, url;

		if (entity['solicitacao']) {
			method = 'PUT';
			url = this.nsjRouting.generate('meurh_solicitacoes_put', { 'id': entity['solicitacao'] }, true);
		} else {
			method = 'POST';
			url = this.nsjRouting.generate('meurh_solicitacoes_create', angular.extend(this.constructors), true);
		}

		if (!autosave) {
			autosave = false;
			entity['$$__submitting'] = true;
		}

		let data = angular.copy(entity);

		if (data.hasOwnProperty('$$__submitting')) {
			delete data['$$__submitting'];
		}

		this.loading_deferred = this.$q.defer();

		return this.$http({
			method: method,
			url: url,
			data: data,
			timeout: this.loading_deferred.promise
		})
			.finally((): void => {
				if (!autosave) {
					entity['$$__submitting'] = false;
				}
			});
	}

	save(entity: ISolicitacao, autosave: boolean = false): void {
		this._save(entity, autosave)
			.then((response: any): void => {
				if (response.data.solicitacao) {
					entity['solicitacao'] = response.data.solicitacao;
				}

				entity['$$_saved'] = true;

				this.$rootScope.$broadcast('meurh_solicitacoes_submitted', {
					entity: entity,
					response: response,
					autosave: autosave
				});
			})
			.catch((response: any): void => {
				this.$rootScope.$broadcast('meurh_solicitacoes_submit_error', {
					entity: entity,
					response: response,
					autosave: autosave
				});
			});
	}

	fechar(entity: ISolicitacao): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('meurh_solicitacoes_fechar', { 'id': entity.solicitacao }, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
				.then((response: any): void => {
					resolve(response.data);
				})
				.catch((response: any): void => {
					reject(response);
				}).finally((): void => {
					entity['$$__submitting'] = false;
				});
		});
	}

	cancelar(entity: ISolicitacao | IAdmissaoUnificada): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('meurh_solicitacoes_cancelar', { 'id': entity.solicitacao }, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
				.then((response: any): void => {
					resolve(response.data);

					this.$rootScope.$broadcast('meurh_solicitacoes_cancelada', {
						entity: entity,
						response: response,
					});
				})
				.catch((response: any): void => {
					reject(response);
				}).finally((): void => {
					entity['$$__submitting'] = false;
				});
		});
	}

	get(identifier: string): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			this.$http.get(
				this.nsjRouting.generate('meurh_solicitacoes_get', { 'id': identifier }, true),
				{ timeout: this.loading_deferred.promise }
			)
				.then((response: any): void => {
					this.$rootScope.$broadcast('meurh_solicitacoes_loaded', response.data);
					resolve(response.data);
				})
				.catch((response: any): void => {
					reject(response);
				});
		});
	}
}
