import angular from 'angular';

import { MeurhApontamentosIndexController } from './index/apontamentos.index.controller';
import { MeurhApontamentosNewController } from './new/apontamentos.new.controller';
import { MeurhApontamentosEditController } from './edit/apontamentos.edit.controller';

import { MeurhApontamentosFormController } from './form/apontamentos.form.controller';
import { MeurhApontamentosFormComponent } from './form/apontamentos.form.component';

import { ApontamentosModalConfirmacaoController } from './modal.confirmacao/modal.confirmacao.controller';
import { ApontamentosModalConfirmacaoService } from './modal.confirmacao/modal.confirmacao.service';

import { MeurhApontamentosListRouting } from './apontamentos.route';
import { MeurhApontamentosService } from './apontamentos.service';

export const MeurhApontamentosModule = angular.module('MeurhApontamentosModule', ['ui.router.state', ])
    .controller('MeurhApontamentosIndexController', MeurhApontamentosIndexController)
    .controller('MeurhApontamentosNewController', MeurhApontamentosNewController)
    .controller('MeurhApontamentosEditController', MeurhApontamentosEditController)
    .controller('MeurhApontamentosFormController', MeurhApontamentosFormController)
    .controller('ApontamentosModalConfirmacaoController', ApontamentosModalConfirmacaoController)
    .component('meurhApontamentosFormComponent', MeurhApontamentosFormComponent)
    .service('MeurhApontamentosService', MeurhApontamentosService)
    .service('ApontamentosModalConfirmacaoService', ApontamentosModalConfirmacaoService)
    .config(MeurhApontamentosListRouting)
    .name;
