import angular from 'angular';

import { ContrachequesIndexController } from './index/contracheques.index.controller';

import { ContrachequesListRouting } from './contracheques.routes';

export const ContrachequesModule = angular.module('ContrachequesModule', ['ui.router.state', ])
    .controller('ContrachequesIndexController', ContrachequesIndexController )
    .config(ContrachequesListRouting)
    .name;
