import * as angular from 'angular';
import { MeurhFeriasmarcaremgradePassotresController } from './feriasmarcaremgrade.passotres.controller';

export class MeurhFeriasmarcaremgradePassotresComponent implements angular.IComponentOptions {
	static selector = 'meurhFeriasmarcaremgradePassotresComponent';
	static controller = MeurhFeriasmarcaremgradePassotresController;
	static template = require('!!html-loader!./feriasmarcaremgrade.passotres.html');
	static transclude = true;
	static bindings = {
		passo: '=',
		entitiesSolicitacoes: '=',
		solicitacoesRascunhoDeletadas: '=',
	};
}
