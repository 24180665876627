export class MeurhVisualizarArquivoModalService {
	static $inject = ['$uibModal'];

	constructor(private $uibModal: ng.ui.bootstrap.IModalService) {
	}

	open(entity: string, fileType: string) {
		return this.$uibModal.open({
			template: require('html-loader!./visualizararquivo.modal.html'),
			controller: 'MeurhVisualizarArquivoModalController',
			controllerAs: 'mrh_vslzr_rqv_mdl_cntrllr',
			windowClass: 'nsj-modal-lg-no-height',
			backdrop: 'static',
			resolve: {
				entity: (): string => entity,
                fileType: (): string => fileType
			}
		});
	}
}
