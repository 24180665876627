import angular from 'angular';

import { RotatividadeIndexController } from './index/rotatividade.index.controller';

import { RotatividadeListRouting } from './rotatividade.routes';

import { RotatividadeService } from './rotatividade.service';

export const RotatividadeModule = angular.module('RotatividadeModule', ['ui.router.state', ])
    .controller('RotatividadeIndexController', RotatividadeIndexController )
    .service('RotatividadeService', RotatividadeService)
    .config(RotatividadeListRouting)
    .name;
