import angular = require('angular');
import moment = require('moment');
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { ITitles } from '../models/titles.model';

export class MeurhCreditosdescontosShowController {

    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'NewToaster',
        'MeurhCreditosdescontosService', 'MeurhAcompanhamentoService', 'ModalConfirmService'];

    public busy: boolean = false;

    public passo: number = 2;
    public entity: Partial<ICreditoDesconto> = {};

    public titles: ITitles = {};
    public path: number = this.$stateParams.path;
    public competencia = moment(this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_FOLHA, 'YYYY-MM-DD').format('MM/YYYY');
    public exibeBotaoSalvar: boolean = true;

    constructor(
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: any,
        public MeurhCreditosdescontosService: any,
        public MeurhAcompanhamentoService: any,
        public ModalConfirmService: any
    ) {
        this.busy = true;
        this.MeurhCreditosdescontosService.get(this.$stateParams.solicitacao);

        $scope.$on('meurh_solicitacoescreditodesconto_getloaded', (event: any, args: any) => {
            this.entity = args;
            MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
            MeurhAcompanhamentoService.loadParams.finished = false;
            MeurhAcompanhamentoService.loadParams.to_load = 3;
            MeurhAcompanhamentoService.load();
            this.alteraEntitySolicitacao();
            if (this.entity.situacao === -1) {
                if (this.entity.tipoperiodo === '2') {

                    if (!this.entity.ano || !this.entity.mes) {
                        this.exibeBotaoSalvar = false;
                    } else {
                        this.exibeBotaoSalvar = !(moment(`${this.entity.mes}/${this.entity.ano}`, 'MM/YYYY').isSameOrBefore(moment(this.competencia, 'MM/YYYY')));
                    }

                } else {
                    let competenciaDaSolicitacao = this.entity.mesreferencia + '/' + this.entity.anoreferencia;
                    this.exibeBotaoSalvar = !(moment(competenciaDaSolicitacao, 'MM/YYYY').isBefore(moment(this.competencia, 'MM/YYYY')));
                }
            }
            this.busy = false;
        });

    }

    $onInit() {

        this.onSalvarSuccess();
        this.onSalvarError();
        this.onExcluirSuccess();
        this.onExcluirError();
    }

    isBusy() {
        return this.busy;
    }

    alteraEntitySolicitacao() {
        switch (this.entity.situacao) {
            case -1:
                this.entity.situacaoDesc = 'Rascunho';
                break;
            case 0:
                this.entity.situacaoDesc = 'Enviada';
                break;
            case 1:
                this.entity.situacaoDesc = 'Aprovada';
                break;
            case 3:
                this.entity.situacaoDesc = 'Excluída';
                break;
        }
        this.entity.enviadoEm = this.entity.situacao === -1 ? 'Ainda não enviado' : moment(this.entity.lastupdate).format('DD/MM/YYYY');

        if (this.entity.tipoperiodo === 2 && this.entity.mesfinalperiodo && this.entity.anofinalperiodo) {
            this.entity.mes = this.entity.mesfinalperiodo.toString();
            this.entity.ano = this.entity.anofinalperiodo.toString();
        }

        this.titles.accordion = this.entity.evento.tipovalor === 0 ? 'Dados do crédito' : 'Dados do desconto';
        this.titles.header = this.entity.evento.tipovalor === 0 ? 'Crédito' : 'Desconto';

        this.entity.tipocalculo = this.entity.tipocalculo.toString();
        this.entity.tipoperiodo = this.entity.tipoperiodo.toString();

    }

    salvar() {
        this.busy = true;
        this.constroiEntitySolicitacao();
    }

    constroiEntitySolicitacao() {
        let entitySolicitacaoEnviar = angular.copy(this.entity);
        entitySolicitacaoEnviar.situacao = 0;
        entitySolicitacaoEnviar.constructors = {
            grupoempresarial: this.$rootScope.nsjGlobals.a.grupoempresarial,
            tenant: this.$rootScope.nsjGlobals.a.tenant,
        };
        this.MeurhCreditosdescontosService.save(entitySolicitacaoEnviar);
    }

    onSalvarSuccess() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_submitted', (event: any, args: any) => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação de ' + (this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto') + ' foi enviada com sucesso!'
            });
            this.entity.situacao = 0;
        });
    }

    onSalvarError() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_submit_error', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao enviar a solicitação de ' + (this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto') + '.'
            });
        });
    }

    editar() {
        this.$state.go('creditosdescontos_edit', { 'solicitacao': this.entity.solicitacao });
    }

    excluir() {
        let tipoText = this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto';
        let customText = {
            title: 'Excluir ' + tipoText,
            confirmText: 'Tem certeza que deseja excluir o ' + tipoText + ' de ',
            confirmTextNum: this.entity.trabalhador.nome,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir ' + tipoText,
        };
        let confirm = this.ModalConfirmService.open(this.entity, 'créditos e descontos', false, customText);
        confirm.result
            .then((entity: any): void => {
                this.busy = true;
                this.MeurhCreditosdescontosService.delete(this.entity.solicitacao, true);
            })
            .catch(function () {/**/ });
    }

    onExcluirSuccess() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_deleted', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação de ' + (this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto') + ' excluída com sucesso.'
            });
            this.$state.go('trabalhadores_show', { 'trabalhador': this.entity.trabalhador.trabalhador });
        });
    }

    onExcluirError() {
        this.$scope.$on('meurh_solicitacoescreditodesconto_delete_error', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao excluir a solicitação de ' + (this.entity.evento.tipovalor === 0 ? 'crédito' : 'desconto') + '.'
            });
        });
    }

}
