import angular from 'angular';
import { MeurhInclusaodependenteModalService } from '../../modal/inclusaodependente.modal.service';
import { MeurhVagasService } from '../../../Vagas/vagas.service';

export class MeurhInclusaodependenteBeneficiosFormController implements ng.IController {

    static $inject = [
        '$scope',
        'NewToaster',
        'MeurhInclusaodependenteModalService',
        'ModalConfirmService',
        'MeurhVagasService',
    ];

    public entity: any;
    public action: string;
    public exibeBtnAdicionar: boolean;
    public idCount: number = 0;

    public fields: any = [
        {
            value: 'beneficio.descricao',
            label: 'Benefício',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'quantidade',
            label: 'Quantidade',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'lotacao.nome',
            label: 'Lotação',
            type: 'string',
            style: 'default',
            copy: '',
        },
    ];

    constructor(
        public $scope: angular.IScope,
        public NewToaster: any,
        public MeurhInclusaodependenteModalService: MeurhInclusaodependenteModalService,
        public ModalConfirmService: any,
        public MeurhVagasService: MeurhVagasService,
    ) {}

    $onInit(): void {
        if (!this.entity.beneficios) {
            this.entity.beneficios = [];
        } else {
            if (this.entity.beneficios.length > 0) {
                this.entity.beneficios.forEach((beneficio: any) => {
                    beneficio['counter'] = this.idCount++;
                });
            }
        }
    }

    async abrirModal(beneficio: any = {}, action: string = ''): Promise<void> {
        let parameters = {
            'componente' : 'Benefício',
            'action' : action ? action : this.action ,
            'objinclusao' : this.entity,
            'desativausobeneficio': false,
        };

        let modal = this.MeurhInclusaodependenteModalService.open(beneficio, parameters);

        modal.result
            .then((response: any): void => {
                if (!this.valorExiste(response.counter)) {
                    response.counter = this.idCount;
                    this.idCount++;
                    this.entity.beneficios.push(angular.copy(response));
                    this.$scope.$applyAsync();
                } else {
                    let achouBeneficio = false;
                    this.entity.beneficios.forEach((element: any, index: any) => {
                        if ((achouBeneficio === false) && (beneficio.counter === element.counter)) {
                            this.entity.beneficios[index] = angular.copy(response);
                            achouBeneficio = true;
                        }
                    });
                }
            })
            .catch((error: any): void => {/**/});
    }

    excluirBeneficio(beneficio: any): void {
        let textos = {
            title: 'Excluir benefício',
            subtitle: 'Após a exclusão não será mais possível reverter a ação.',
            confirmText: 'Você tem certeza que deseja excluir este benefício?',
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, excluir benefício',
        };
		let confirm = this.ModalConfirmService.open(this.entity, 'Benefício', false, textos);

        confirm.result.then((): void => {
            this.excluir(beneficio);
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    excluir(beneficio: any): void {
        let achouBeneficio = false;
        this.entity.beneficios.forEach((element: any, index: any) => {
            if ((achouBeneficio === false) && (beneficio.counter === element.counter)) {
                achouBeneficio = true;
                this.entity.beneficios.splice(index, 1);
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

}
