import * as angular from 'angular';
import { MeurhInclusaodependenteDadosgeraisFormController } from './dadosgerais.form.controller';

export class MeurhInclusaodependenteDadosgeraisFormComponent implements angular.IComponentOptions {
	static selector = 'meurhInclusaodependenteDadosgeraisFormComponent';
	static controller = MeurhInclusaodependenteDadosgeraisFormController;
	static template = require('!!html-loader!./dadosgerais.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
		desativaportrabalhadortemporario: '=',
	};
}
