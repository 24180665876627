import * as angular from 'angular';

import { MeurhAnexoFormComponent } from './form/anexos.form.component';
import { MeurhAnexosService } from './anexos.service';
import { MeurhVisualizarmodalService } from './visualizarmodal/anexos.visualizarmodal.service';
import { MeurhAnexosRotasEnumService } from './anexos-rotas-enum.service';
import { DocumentoColaboradorModalService } from './visualizarmodaldocumentoscolaboradores/anexos.visualizarmodal.service';

export const MeurhAnexosModule =
	angular.module('meurhAnexosModule', ['ui.router.state'])
		.component('meurhAnexoFormComponent', MeurhAnexoFormComponent)
		.service('MeurhVisualizarmodalService', MeurhVisualizarmodalService)
		.service('DocumentoColaboradorModalService', DocumentoColaboradorModalService)
		.service('MeurhAnexosService', MeurhAnexosService)
		.service('MeurhAnexosRotasEnumService', MeurhAnexosRotasEnumService)
		.name;
