import angular from 'angular';
import { ISolicitacaoAlteracaoVT } from '../models/solicitacaoalteracaovt.model';
import { MeurhSolicitacoesalteracoesvtsService } from '../solicitacoesalteracoesvts.service';

export class MeurhSolicitacoesalteracoesvtsNewController implements angular.IController {
    static $inject = ['$scope', '$state', 'MeurhSolicitacoesalteracoesvtsService', 'entity', 'NewToaster', '$stateParams', 'ModalConfirmService', '$q', '$transitions'];

    public action: string = 'insert';
    public tipo: string;
    public form: angular.IFormController;
    public busy: boolean = false;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
    constructor(
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesvtsService,
        public entity: ISolicitacaoAlteracaoVT,
        public NewToaster: any,
        public $stateParams: angular.ui.IStateParamsService,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any
    ) {
        this.tipo = $stateParams['tipo'];

        this.$scope.$watch('mrh_slctcsltrcsvts_frm_nw_cntrllr.entity', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
        }, true);

        this.onSubmitSuccess();
        this.onSubmitError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    submit(): void {
        if (this.form.$valid && !this.entity.$$__submitting) {
            if (this.entity.solicitacoesalteracoesvtstarifas !== undefined && this.entity.solicitacoesalteracoesvtstarifas.length > 0) {
                if (this.entity.solicitacoesalteracoesvtstarifas.some((tarifa: any) => (tarifa.quantidade === null || tarifa.quantidade === 0))) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Tarifa com quantidade inválida'
                    });
                } else {
                    this.allowRefresh = false;
                    this.entityService.save(this.entity, {
                        empresa: this.entity.trabalhador.empresaobj.empresa,
                        estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível enviar uma solicitação sem tarifas'
                });
            }
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros'
            });
        }
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de Alteração de VT foi enviada com sucesso!'
            });

            this.entityService.constructors.solicitacao = args.entity.solicitacao;
            this.$state.go('meurh_solicitacoesalteracoesvts_show', this.entityService.constructors);
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity, {
                        empresa: this.entity.trabalhador.empresaobj.empresa,
                        estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
                    });
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = args.response.data.errors.errors[0];
                        /*
                        let children = args.response.data.errors.children;

                        children.forEach((child: any): void => {
                            if (children[child].errors !== undefined) {
                                children[child].errors.forEach((error: any): void => {
                                    message += '<li>' + children[child].errors[error] + '</li>';
                                });
                            }
                        });
                        */
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: message,
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao enviar a Solicitação'
                    });
                }
            }
        });
    }

    buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoesalteracoesvts', angular.extend(this.entityService.constructors));
            })
            .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_solicitacoesalteracoesvts', angular.extend(this.entityService.constructors));
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoesalteracoesvts_new' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
