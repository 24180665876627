import angular from 'angular';
import { MeurhEscalaDadosIniciaisFormController } from './dadosiniciais.form.controller';

export class MeurhEscalaDadosIniciaisFormComponent implements angular.IComponentOptions {
	static selector = 'meurhEscalaDadosIniciaisFormComponent';
	static controller = MeurhEscalaDadosIniciaisFormController;
	static template = require('!!html-loader!./dadosiniciais.form.html');
	static transclude = true;
	static bindings = {
        form: '<',
		entity: '=',
	};
}
