import angular = require('angular');
import moment = require('moment');

export class MeurhAdmissoesestagiarioValetransportepersonalizadoFormmodalController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'MeurhAdmissaoestagiarioModalService'];

    public valetransportepersonalizado: any = {};
    public action: string;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
    ) {

    }

    $onInit(): void {

       if (this.valetransportepersonalizado !== {}) {
           this.valetransportepersonalizado.tipo = this.valetransportepersonalizado.tipo ? this.valetransportepersonalizado.tipo.toString() : this.valetransportepersonalizado.tipo;
       }
    }
}
