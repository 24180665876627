import angular from 'angular';
import { IPasta, IArquivo } from './arquivos.model';

export class MeurhArquivosService {
    static $inject = ['$http', '$rootScope', '$q', 'nsjRouting'];

    public loading_deferred: angular.IDeferred<unknown>;

    constructor(
        public $http: angular.IHttpService,
        public $rootScope: angular.IScope,
        public $q: angular.IQService,
        public nsjRouting: any,
    ) { }

    getPastas() {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<IPasta[]>, reject) => {
            this.$http.get<IPasta[]>(
                this.nsjRouting.generate('meurh_tiposarquivos_index', {}, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_tiposarquivos_index_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getArquivos(tipoarquivo: string, trabalhador: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<IArquivo[]>, reject) => {
            this.$http.get<IArquivo[]>(
                this.nsjRouting.generate('meurh_arquivostrabalhadores_index', {
                    tipoarquivo,
                    trabalhador
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_arquivostrabalhadores_index_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getConteudo(id: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<angular.IHttpResponse<File>>, reject) => {
			this.$http.get<File>(
				this.nsjRouting.generate('meurh_arquivostrabalhadores_get_arquivo', {
                    id
                }, true), {
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response) => {
				this.$rootScope.$broadcast('meurh_arquivostrabalhadores_get_arquivo_loaded', response);
				resolve(response);
			}).catch((response) => {
                reject(response);
            });
		});
    }

    downloadArquivos(arquivos: {arquivotrabalhador: string}[]) {
        this.loading_deferred = this.$q.defer();
		return this.$q((resolve: angular.IQResolveReject<File>, reject) => {
			this.$http.post<File>(
				this.nsjRouting.generate('meurh_arquivostrabalhadores_downloadarquivoslote', {}, true), {
                    entities: angular.copy(arquivos)
                }, {
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response) => {
				this.$rootScope.$broadcast('meurh_arquivostrabalhadores_downloadarquivoslote_loaded', response);
				resolve(response.data);
			})
			.catch((response) => {
				reject(response);
			});
		});
    }

    liberarArquivo(id: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve: angular.IQResolveReject<void>, reject) => {
            this.$http.post<void>(
                this.nsjRouting.generate('meurh_arquivostrabalhadores_liberar', {
                    id
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_arquivostrabalhadores_liberar_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }
}
