import angular from 'angular';

export class MeurhQuadroHorariosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entities: any = [];
    public loaded: any = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public full_count: any = null;
    public loadParams: any = {
        to_load: 3,
        busy: false,
        finished: false,
    };
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any,
    ) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k) => { delete this.filters[k]; });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('quadrohorarios_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });

        });

    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        if (data[0].hasOwnProperty('full_count')) {
                            this.full_count = data[0].full_count;
                        }
                        for (let i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                    }
                    if (this.entities.length === this.full_count) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('quadrohorarios_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }
                    this.loaded = true;

                    this.loadParams.to_load--;
                    this.loadParams.busy = false;

                })
            .catch((error: any) => {/* */});
        }

        return this.entities;
    }

    loadMore() {
        let ultimo_elemento = this.entities[this.entities.length - 1];
        this.after.trabalhador = ultimo_elemento.trabalhador.trabalhador;
        if (this.constructors.order !== null) {
            if (this.constructors.order.hasOwnProperty('nome')) {
                this.after.nome = ultimo_elemento.trabalhador.nome;
            }
            if (this.constructors.order.hasOwnProperty('codigo')) {
                this.after.codigo = ultimo_elemento.trabalhador.codigo;
            }
            if (this.constructors.order.hasOwnProperty('cargo')) {
                this.after.cargo = ultimo_elemento.cargo.nome;
            }
        }
        this.loadParams.to_load = 3;
        this.entities = this.load();
    }
}
