import angular from 'angular';
import { IRelatorio } from './models/relatorio.model';
import { IRelatorioData } from './models/relatoriodata.model';

export class MeurhRelatoriossolicitacoesService {
    static $inject = [
        'nsjRouting',
        '$http',
        '$q'
    ];

    constructor(
        private nsjRouting: any,
        private $http: angular.IHttpService,
        private $q: angular.IQService,
    ) { }

    public getRelatorios(identifier: string, route: string): angular.IHttpPromise<IRelatorio[]> {

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate(
                    route,
                    { 'id': identifier },
                    true
                )
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public downloadRelatorios(indentifier: string, route: string, constructors: IRelatorioData = { format: '', solicitacaorelatorio: '' }): angular.IHttpPromise<any> {
        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate(
                    route,
                    angular.extend(constructors, { 'id': indentifier }),
                    true
                ),
                responseType: 'blob'
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    public deleteRelatorio(indentifier: string, route: string, constructors: { solicitacaorelatorio: string; }): angular.IHttpPromise<any> {
        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'DELETE',
                url: this.nsjRouting.generate(
                    route,
                    angular.extend(constructors, { 'id': indentifier }),
                    true
                ),
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }
}
