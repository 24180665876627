import angular from 'angular';
import { MeurhSolicitacoesrecisoesShowFormController } from './solicitacoesrescisoes.showform.controller';

export class MeurhSolicitacoesrecisoesShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesrecisoesShowFormComponent';
	static controller = MeurhSolicitacoesrecisoesShowFormController;
	static template = require('!!html-loader!./solicitacoesrescisoes.showform.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '=',
		busy: '=',
		action: '<'
	};
}
