import * as angular from 'angular';
import { MeurhVagasFormController } from './vagas.form.controller';

export class MeurhVagasFormComponent implements angular.IComponentOptions {
	static selector = 'meurhVagasFormComponent';
	static controller = MeurhVagasFormController;
	static template = require('!!html-loader!./vagas.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<?',
		busy: '=?',
		action: '<?'
	};
}
