export class ToasterHtmlDirective implements ng.IDirective {
  static restrict = 'A';
  static Factory() {
      const directive = (MeurhAvisoSaldoZeroModalService: any, $compile: any) => {
          return new ToasterHtmlDirective(MeurhAvisoSaldoZeroModalService, $compile);
      };
      directive.$inject = ['MeurhAvisoSaldoZeroModalService', '$compile'];
      return directive;
  }

  constructor(public MeurhAvisoSaldoZeroModalService: any, public $compile: any) { }

  link = (scope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
    const TOTAL = scope.directiveData.entities.length;
    const UNCHECKED = scope.directiveData.entities.filter((entity: any) => entity.disableCheckbox).length;

    let template = `<span>${UNCHECKED} dos ${TOTAL} ` + (UNCHECKED === 1 ? 'colaborador foi ignorado, pois não possui saldo disponível em seu período aquisitivo em aberto.' : 'colaboradores foram ignorados, pois não possuem saldo disponível em seus períodos aquisitivos em aberto.') + ' <a style="text-decoration: underline; color: inherit; z-index=999" ng-click="showModalSaldoZero()"><b>Ver mais detalhes</b></a></span>';

    let directiveScope = scope.$new();

    element.append(this.$compile(template)(directiveScope, (cloned: any, scope: any) => { element.append(cloned); }));

    scope.showModalSaldoZero = (): void => {
      this.MeurhAvisoSaldoZeroModalService.open(scope.directiveData.entities);
    };
  }
}
