import {NsjAuthInstance} from '@core/authentication/nsj-autenticacao';
import {ErrorPageRoutes} from '@core/components/error-page.routes';
import {configInstance} from '@core/configuracao/nsj-config';
import {Catch} from "@core/decorators/catch";
import {httpClient} from '@core/services/http-client';

import {NsjRoutingInstance} from '@core/routing/nsj-rotas';
import {ServerError} from "@core/services/server-error";

import {Estabelecimento, Grupoempresarial, Organizacao, Trabalhador, Usuario} from '@core/usuario/models/usuario-model';
import {NsjUsuarioInstance} from '@core/usuario/nsj-usuario';

class InicializacaoService {

    constructor(private config: any, private bootstrap: Function, private usaGrupoEmpresarial: boolean = true, private usaEstabelecimento: boolean = false) {

    }

    async run() {
        ErrorPageRoutes.initRouter();
        configInstance.iniciarConfig(this.config);
        NsjAuthInstance.iniciarAuth()
            .then(() => NsjRoutingInstance.carregarRotas())
            .then(() => NsjUsuarioInstance.carregarUsuario())
            .then(() => this.bootstrapApp())
    }

    @Catch('object', (error, ctx) => {
        console.error({error, ctx});
        ServerError.showErrorPage(error.message);
    })

    async bootstrapApp() {

        const usuario: Usuario = NsjUsuarioInstance.Usuario;
        let tenantCodigo: string = '';
        let grupoEmpresarialCodigo: string = '';

        let tenant: Organizacao
        let grupoempresarial: Grupoempresarial; // grupoempresarialobj
        let estabelecimento: any;
        let globals: any = {};

        let trabalhador: boolean | Trabalhador = false;

        if (usuario.trabalhador && usuario.trabalhador.length > 0) {
            trabalhador = usuario.trabalhador[0];
        }

        if (!usuario) {
            throw new Error('Erro ao carregar usuário.')
        }

        if(usuario.tenant_url_bloqueada){
          throw new Error('Serviço suspenso, entre em contato com a Nasajon.')
        }

        //Busco se usuário possui algum tenant
        tenant = usuario.organizacoes.find((tenant) => {
            return tenant;
        });

        //Caso o usuário não possua tenant, retorno erro.
        if (!tenant) {
            throw new Error('Usuário não possui tenant');
        }

        //Caso utilize Grupo Empresarial na URL base, busco o primeiro grupo do tenant selecionado 
        if (this.usaGrupoEmpresarial) {

          grupoempresarial = tenant.gruposempresariais.find((grupoempresarial) => { // grupoempresarialobj
                return grupoempresarial;
            });

            //Caso o usuário não possua Grupo Empresarial, retorno erro.
            if (!grupoempresarial) { // grupoempresarialobj
                throw new Error('Usuário não possui Grupo Empresarial');
            }

            tenantCodigo = tenant.codigo;
            grupoEmpresarialCodigo = grupoempresarial.codigo; // grupoempresarialobj
        }

        //   Busco dados de tenant e grupo empresarial presentes na url
        const indexUrl = window.location.href.indexOf('://') + 3;
        const indexUrlParams = window.location.href.indexOf('?');
        let urlSemProtocolo = (indexUrlParams > -1) ? window.location.href.substring(indexUrl, indexUrlParams) : window.location.href.substring(indexUrl);
        let baseTag = document.getElementsByTagName('base')[0];

        //Se o último caractere for uma barra, eu removo
        if (urlSemProtocolo[urlSemProtocolo.length - 1] == '/') {
            urlSemProtocolo = urlSemProtocolo.substr(0, urlSemProtocolo.length - 1);
        }

        const dadosUrl = urlSemProtocolo.split('/');
      if (this.usaEstabelecimento) {
        const urlPaginaInicial = window.location.href.substring(0, indexUrl) + dadosUrl[0] + '/';

        //  até tratar urls no localhost
        if (!dadosUrl[0].includes('localhost')) {
          usuario.organizacoes.forEach((element: any) => {
            let domain = dadosUrl[0].split('.');
            domain[0] = element.codigo;
            //  domain = domain.join('.');
            element.url = window.location.href.substring(0, indexUrl) + domain.join('.');
            return element;
          });
        }

        // verifico se existe tenant na url, considerando que dadosUrl[0] é o host.
        let domain = dadosUrl[0].split('.');
        let tenantUrl = !domain[0].includes('localhost') ? domain[0] : (dadosUrl.length > 1) ? dadosUrl[1] : null;
        let grupoempresarial = (dadosUrl.length > 1) ? dadosUrl[1] : null;
        let estabelecimento = (dadosUrl.length > 1) ? dadosUrl[2] : null;
        // caso tenant não exista no corpo da url e existam parametros de url, busco nos parametros
        if ((tenantUrl === null || grupoempresarial === null || estabelecimento === null) && indexUrlParams > -1) {
          const params = window.location.href.substring(indexUrlParams + 1).split('&');
          const arrParams = params.map((paramText) => {
            const arrChaveValor = paramText.split('=');

            return {
              nome: arrChaveValor[0],
              valor: arrChaveValor.length > 1 ? arrChaveValor[1] : ''
            };
          });
          const paramTenant = arrParams.find((param) => {
            return (param.nome === 'tenant');
          });

          if (paramTenant) {
            tenantUrl = paramTenant.valor;
          }

          const paramGrupoempresarial = arrParams.find((param) => {
            return (param.nome === 'grupoempresarial');
          });

          if (paramGrupoempresarial) {
            grupoempresarial = paramGrupoempresarial.valor;
          }

          const paramEstabelecimento = arrParams.find((param) => {
            return (param.nome === 'estabelecimento');
          });

          if (paramEstabelecimento) {
            estabelecimento = paramEstabelecimento.valor;
          }

        }

        // caso não tenha o tenant/grupoempresarial/estabelecimento nem no corpo quanto nos parâmetros da URL, pego as informações do primeiro tenant
        tenantUrl = tenantUrl === null ? usuario.organizacoes[0].codigo : tenantUrl;
        // caso encontre tenant da url no perfil do usuário, seto esses valores para colocar na base da url
        const tenantCompleto = usuario.organizacoes.find((tenant: any) => {
          return tenant.codigo === tenantUrl;
        });

        if (tenantCompleto) {
          var grupos_filtrados = tenantCompleto.gruposempresariais.filter(function (grupoempresarial: any) {
            grupoempresarial.empresas = grupoempresarial.empresas.filter(function (empresa: any) {
              return empresa.estabelecimentos.length > 0;
            });
            return (grupoempresarial.empresas.length > 0);
          });

          tenantCompleto.gruposempresariais = grupos_filtrados;

          if (typeof tenantCompleto.gruposempresariais !== 'undefined' && tenantCompleto.gruposempresariais.length > 0) {
            // grupoempresarial = grupoempresarial === null ? (trabalhador ? this.getGrupoempresarialUsuario(trabalhador, tenantCompleto.gruposempresariais)?.codigo : tenantCompleto.gruposempresariais[0].codigo) : grupoempresarial;
            // estabelecimento = estabelecimento === null ? (trabalhador ? this.getEstabelecimentoUsuario(trabalhador, this.getGrupoempresarialUsuario(trabalhador, tenantCompleto.gruposempresariais)).id : tenantCompleto.gruposempresariais[0].empresas[0].estabelecimentos[0].id) : estabelecimento;
            grupoempresarial = grupoempresarial === null ? tenantCompleto.gruposempresariais[0].codigo : grupoempresarial; // revertido da tarefa 87929
            estabelecimento = estabelecimento === null ? tenantCompleto.gruposempresariais[0].empresas[0].estabelecimentos[0].id : estabelecimento;
          } else {
            throw new Error(`O usuário ${usuario.email} não possui permissão para acessar nenhum estabelecimento. Você será redirecionado para a página de login.`);
          }
        }

        if (typeof estabelecimento === 'undefined' || estabelecimento === null) {
          throw new Error(`O usuário ${usuario.email} não possui permissão para acessar nenhum estabelecimento. Você será redirecionado para a página de login.`);
        }
        // seto a base da url do sistema
        baseTag.href = baseTag.href + grupoempresarial + '/' + estabelecimento + '/';

        usuario.logout_url = NsjAuthInstance.nsjAuth.createLogoutUrl({
          redirectUri: baseTag.href
        });

        const url = NsjRoutingInstance.Routing.generate('app_globals_globals', {
          tenant: tenantCompleto.codigo,
          grupoempresarial: grupoempresarial,
          estabelecimento: estabelecimento
        }, true)

        globals = await (await httpClient.get<any>(url, true)).parsedBody;
        // código temporário para tornar compatível com a nomenclatura usada no nsj-rotas
        globals['grupoEmpresarialCodigo'] = globals.grupoempresarial;
        globals['tenantCodigo'] = globals.tenant;

      } else {

        //Verifico se existe tenant e grupo empresarial na url, considerando que dadosUrl[0] é o host.
        let tenantUrl = (dadosUrl.length > 1) ? dadosUrl[1] : null;
        let grupoEmpresarialUrl = (dadosUrl.length > 2) ? dadosUrl[2] : null;

        //Caso tenant/grupo empresarial não existam no corpo da url e existam parametros de url, busco nos parametros
        if ((tenantUrl == null && grupoEmpresarialUrl == null) && indexUrlParams > -1) {
            const params = window.location.href.substring(indexUrlParams + 1).split('&');
            const arrParams = params.map((paramText) => {
                const arrChaveValor = paramText.split('=');

                return {
                    nome: arrChaveValor[0],
                    valor: arrChaveValor.length > 1 ? arrChaveValor[1] : ''
                };
            });

            const paramTenant = arrParams.find((param) => {
                return (param.nome == 'tenant');
            });

            if (paramTenant) {
                tenantUrl = paramTenant.valor;

                const paramGrupoEmpresarial = arrParams.find((param) => {
                    return (param.nome == 'grupoempresarial');
                });

                if (paramGrupoEmpresarial) {
                    grupoEmpresarialUrl = paramGrupoEmpresarial.valor;
                }
            }
        }

        //Caso encontro tenant e grupo empresarial da url no perfil do usuário, seto esses valores para colocar na base da url
        const tenantCompleto = usuario.organizacoes.find((tenant) => {
            return tenant.codigo == tenantUrl
        });

        if (tenantCompleto) {
            const grupoEmpresarialCompleto = tenantCompleto.gruposempresariais.find((grupo) => {
                return grupo.codigo == grupoEmpresarialUrl;
            });

            if (grupoEmpresarialCompleto) {
                tenantCodigo = tenantCompleto.codigo;
                grupoEmpresarialCodigo = grupoEmpresarialCompleto.codigo;
            }
        }

        //Busco a tag Base
        let baseTag = document.getElementsByTagName('base')[0];

        //Seto a base da url do sistema
        baseTag.href = baseTag.href + (this.usaGrupoEmpresarial ? tenantCodigo + '/' + grupoEmpresarialCodigo + '/' : tenantCodigo + '/');

        //Adiciono Url de redirecionamento no logout do sistema. Quando deslogar, vai pra página inicial
        usuario.logout_url = NsjAuthInstance.nsjAuth.createLogoutUrl({
            redirectUri: baseTag.href
        });

        globals = {tenantCodigo, grupoEmpresarialCodigo};
      }

      configInstance.setConfig('globals', globals);

      //Pegar o parâmetro redirect_uri da url para iniciar a aplicação direto na página definida
      let redirect_uri = decodeURI(getParameterByName('redirect_uri'));
      this.bootstrap(redirect_uri);
    }

    getGrupoempresarialUsuario(trabalhador: Trabalhador, gruposempresariais: Grupoempresarial[]): Grupoempresarial {
      return gruposempresariais.find((grupoempresarial => grupoempresarial.id === trabalhador.grupoempresarial));
    }

    getEstabelecimentoUsuario(trabalhador: Trabalhador, grupoempresarial: Grupoempresarial) {
      // let estabelecimento: Estabelecimento = null;
      // grupoempresarial.empresas.map(empresa => {
      //   estabelecimento = empresa.estabelecimentos.find(estabelecimento => estabelecimento.id === trabalhador.estabelecimento);
      // });

      let estabelecimento = grupoempresarial.empresas.find(empresa => {
        return empresa.estabelecimentos.find(estabelecimento => estabelecimento.id === trabalhador.estabelecimento);
      });

      return estabelecimento;
    }
}

function getParameterByName(name: String) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export const NsjInicializacao = InicializacaoService;
