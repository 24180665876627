import * as angular from 'angular';
import { MeurhAdmissaoestagiarioService } from './admissaoestagiario.service';

export const MeurhAdmissaoestagiarioListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {

    let resolve = {
        'entity': [
            'MeurhAdmissaoestagiarioService',
            '$stateParams',
            '$state',
            '$q',
            function (
                entityService: MeurhAdmissaoestagiarioService,
                $stateParams: angular.ui.IStateParamsService,
                $state: angular.ui.IStateService,
                $q: angular.IQService
            ): angular.ui.IStateParamsService | angular.IQService | {} {

                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                } else {
                    if ($stateParams['solicitacao']) {
                        return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                            entityService.get($stateParams['solicitacao'])
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((error: any) => {
                                    if (error.status === 404) {
                                        if ($state.href('meurh_admissaoestagiario_not_found', $stateParams)) {
                                            $state.go('meurh_admissaoestagiario_not_found', $stateParams);
                                        } else {
                                            $state.go('not_found', $stateParams);
                                        }
                                    }
                                });
                        });
                    } else {
                        return {};
                    }
                }
            }
        ]
    };

    $stateProvider
        .state('meurh_admissaoestagiario_new', {
            url: '/admissaoestagiario/new',
            resolve: resolve,
            template: require('html-loader!./new/admissaoestagiario.new.html'),
            controller: 'MeurhAdmissaoestagiarioNewController',
            controllerAs: 'mrh_admssstgr_frm_nw_cntrllr'
        })
        .state('meurh_admissaoestagiario_show', {
            url: '/admissaoestagiario/show/:solicitacao',
            resolve: resolve,
            template: require('html-loader!./show/admissaoestagiario.show.html'),
            controller: 'MeurhAdmissaoestagiarioShowController',
            controllerAs: 'mrh_admssstgr_frm_shw_cntrllr'
        })
        .state('meurh_admissaoestagiario_edit', {
            url: '/admissaoestagiario/edit/:solicitacao?camposobrigatorios',
            resolve: resolve,
            template: require('html-loader!./edit/admissaoestagiario.edit.html'),
            controller: 'Meurh\AdmissaoestagiarioEditController',
            controllerAs: 'mrh_admssstgr_frm_edit_cntrllr'
        });
    }
];
