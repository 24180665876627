import angular from 'angular';
import { IRetornosAfastamento } from './models/retornoAfastamento.model';

export class MeurhSolicitacoesafastamentosretornoService {
    static $inject = ['nsjRouting', '$http', '$q'];

    public entity: IRetornosAfastamento;
    public loading_deferred: any = null;

    constructor(
        public nsjRouting: any,
        public $http: angular.IHttpService,
        public $q: angular.IQService
    ) { }

    public getAfastamentoretornoIndividual(identifier: string): angular.IHttpPromise<any> {
        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http
                .get(this.nsjRouting.generate(
                    'meurh_solicitacoesafastamentosretorno_get', { id: identifier }, true),
                )
                .then((response: any): void => {
                    resolve(response);
                })
                .catch((response: any): void => {
                    reject(response);
                });
        });
    }
}
