export enum TipoHistoricoEnum {
	CRIACAO = -1,
	ENVIO = 0,
	APROVADO = 1,
	FECHAMENTO = 2,
	EXCLUSÃO = 3,
	REABERTURA = 4,
	EXECUÇÃO = 5,
	EDIÇÃO = 6,
	OUTROS = 7,
	WORKFLOW = 8,
	RETIFICACAO = 9,
	CRIACAO_RETIFICACAO = 10
}
