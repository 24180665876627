import angular = require('angular');
import moment = require('moment');
import { MeurhVagasModalService } from '../../modal/vagas.modal.service';

export class MeurhVagasBeneficiosFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'MeurhVagasModalService'];

    public entity: any;
    public action: string;
    public fields: any = [
        {
            value: 'beneficio.descricao',
            label: 'Benefício',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'tipoperiodoFormatado',
            label: 'Período',
            type: 'string',
            style: 'default',
            copy: '',
        },
        {
            value: 'quantidade',
            label: 'Quantidade',
            type: 'number',
            style: 'default',
            copy: '',
        },
    ];

    public idCount: number = 0; // identificador de cada item da lista de beneficios adicionados

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public MeurhVagasModalService: MeurhVagasModalService
    ) {

    }

    $onInit(): void {
        if (!this.entity.beneficios) {
            this.entity.beneficios = [];
        } else {
            this.formataTipoPeriodo();
            if (this.entity.beneficios.length > 0) {
                this.entity.beneficios.forEach((element: any) => {
                    element['counter'] = this.idCount++;
                });
            }
        }
    }

    abrirModal(beneficio: any = {}, action: string = ''): void {
        let parameters = {
            'componente': 'Benefício',
            'action' : action ? action : this.action,
        };
        let modal = this.MeurhVagasModalService.open(beneficio, parameters);

        modal.result
            .then((response: any): void => {
                if (!this.valorExiste(response.counter)) {
                    response.counter = this.idCount;
                    this.idCount++;
                    this.entity.beneficios.push(angular.copy(response));
                    this.$scope.$applyAsync();
                } else {
                    let achouBeneficio = false;
                    this.entity.beneficios.forEach((element: any, index: any) => {
                        if ((achouBeneficio === false) && (beneficio.counter === element.counter)) {
                            this.entity.beneficios[index] = angular.copy(response);
                            achouBeneficio = true;
                        }
                    });
                }

                this.formataTipoPeriodo();
            })
            .catch((error: any): void => {/**/});
    }

    excluirBeneficio(beneficio: any = {}): void {
        let achouBeneficio = false;
        this.entity.beneficios.forEach((element: any, index: any) => {
            if ((achouBeneficio === false) && (beneficio.counter === element.counter)) {
                achouBeneficio = true;
                this.entity.beneficios.splice(index, 1);
            }
        });
    }

    formataTipoPeriodo() {
        this.entity.beneficios.forEach((element: any, index: any) => {
            switch (element.tipoperiodo) {
                case 0:
                    element['tipoperiodoFormatado'] = 'Sempre';
                    break;
                case 1:
                    element['tipoperiodoFormatado'] = 'Todo mês(x)';
                    break;
                case 2:
                    element['tipoperiodoFormatado'] = 'Entre Mês/Ano';
                    break;
                default:
                    element['tipoperiodoFormatado'] = 'Não identificado';
                    break;
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
