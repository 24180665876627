import ng from 'angular';
import moment from 'moment';
import { IRescisao } from '../models/rescisao.model';
import { IModalConfirmacao } from '../models/modalconfirm';

export class MeurhRescisoesModalConfirmController implements ng.IController {

    static $inject = [
        'entity',
        'textos',
        'prorrogacaoContrato',
        '$uibModalInstance'
    ];

    public dataMinimaProrrogacao: moment.Moment;
    public dataMaximaProrrogacao: moment.Moment;

    constructor(
        public entity: IRescisao,
        public textos: IModalConfirmacao,
        public prorrogacaoContrato: boolean,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    ) {
        this.setDates();
    }

    public confirmar() {
        this.$uibModalInstance.close(this.entity);
    }

    public close() {
        this.$uibModalInstance.dismiss('cancelar');
    }

    private setDates(): void {
        this.dataMinimaProrrogacao = moment(this.entity.datarescisao).add(1, 'days');

        if (this.entity.criadoautomaticamente) {
            this.dataMaximaProrrogacao = this.entity.trabalhador?.categoriatrabalhador === '105'
                ? moment(this.entity.trabalhador?.dataadmissao!).add(730, 'days')
                : moment(this.entity.trabalhador?.dataadmissao!).add(270, 'days');
        }
    }
}
