import angular from 'angular';
import { TagInputController } from './tag.input.controller';

export class TagInputComponent implements angular.IComponentOptions {
  static selector = 'tagInputComponent';
  static controller = TagInputController;
  static template = require('!!html-loader!./tag.input.html');
  static transclude = true;
  static bindings = {
    tags: '=',
    list: '<',
    field: '@',
    name: '@',
    placeholder: '@?',
    disabled: '<?',
    allowUnlistedItems: '<?',
    pattern: '@',
    type: '@',
    gestorinput: '<'
  };
}
