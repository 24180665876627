import angular from 'angular';
import moment from 'moment';
import { MeurhTransferenciaColaboradoresService } from '../transferenciacolaboradores.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { ITransferenciaColaboradores } from '../models/transferenciacolaboradores.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhTransferenciaColaboradoresIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'MeurhTransferenciaColaboradoresService',
        '$location',
        'NewToaster',
        'nsjRouting',
        'ListagemService'
    ];

    public entities: Array<ITransferenciaColaboradores>;
    public filter: any = {};
    public filters: any = {};
    public constructors: any = {};
    public busy: boolean = false;

    public filterSituacao: string = '';
    public filterDataInicio: string | null = null;
    public filterDataFim: string | null = null;
    public filterDataAtual = moment();

    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_transferencias_vagas'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            temPermissao: (arg: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public entityService: MeurhTransferenciaColaboradoresService,
        public $location: angular.ILocationService,
        public NewToaster: any,
        public nsjRouting: any,
        public ListagemService: ListagemService
    ) {

        this.entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = this.entityService.filters = {};
        this.montaListagemVisibilidade();
        this.filter.search = $stateParams['q'];
        this.constructors = entityService.constructors = [];
        this.entities = this.filterReload();

        this.$rootScope.$on('meurh_solicitacoestransferenciasvagas_get_loading', (event: any, args: any) => {
            this.busy = true;
        });

        this.$rootScope.$on('meurh_solicitacoestransferenciasvagas_list_finished', (event: any, args: any) => {
            this.busy = false;
        });

        this.$rootScope.$on('meurh_solicitacoestransferenciasvagas_loaded', (event: any, args: any) => {
            this.busy = false;
        });

        this.$scope.$on('meurh_solicitacoestransferenciasvagas_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.entities = [];
            this.entities = this.entityService.reload();
        });

        this.$scope.$on('meurh_solicitacoestransferenciasvagas_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.entities = [];
            this.entities = this.entityService.reload();
        });
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
    }

    search(): Array<ITransferenciaColaboradores> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }
        return this.entityService.search(this.filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    filterReload(): Array<ITransferenciaColaboradores> {
        delete this.constructors.situacao;
        if (this.filterSituacao !== '') {
            this.constructors.situacao = this.filterSituacao;
        }
        if (this.filterDataInicio !== null && this.filterDataFim !== null && this.filterDataInicio !== '' && this.filterDataFim !== '' && this.filterDataInicio > this.filterDataFim) {

            this.NewToaster.pop({
                type: 'error',
                title: 'A data inícial não pode ser posterior a data final'
            });
            return [];
        }
        let created_at: Array<{condition: string, value: string}> = [];
        if (this.filterDataInicio === undefined || (this.filterDataInicio !== null && this.filterDataInicio.length === 0)) {
        this.filterDataInicio = null;
        this.constructors.datainicial = null;
        }
        if (this.filterDataInicio !== null) {
        created_at.push(
            {
            condition: 'gte',
            value: this.filterDataInicio + ' 00:00:00'
            }
        );
        delete this.constructors.datainicial;
        }
        if (this.filterDataFim === undefined || (this.filterDataFim !== null && this.filterDataFim.length === 0)) {
        this.filterDataFim = null;
        this.constructors.datafinal = null;
        }
        if (this.filterDataFim !== null) {
        created_at.push(
            {
            condition: 'lte',
            value: this.filterDataFim + ' 23:59:00'
            }
        );
        delete this.constructors.datafinal;
        }
        this.constructors.created_at = created_at;

        this.entityService.constructors = this.constructors;
        return this.entityService.reload();
    }

    reload(): void {
        this.filterReload();
    }

    generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }
}
