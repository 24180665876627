import * as angular from 'angular';
import { MeurhAfastamentosFormController } from './afastamentos.form.controller';

export class MeurhAfastamentosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAfastamentosFormComponent';
	static controller = MeurhAfastamentosFormController;
	static template = require('!!html-loader!./afastamentos.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<?',
		busy: '=?',
		action: '<?'
	};
}
