
import * as angular from 'angular';
import { MeurhSolicitacoesFaltasService } from './solicitacoesfaltas.service';
import { MeurhSolicitacoesfaltasListRouting } from './solicitacoesfaltas.routes';
import { MeurhSolicitacoesfaltasIndexController } from './index/solicitacoesfaltas.index.controller';
import { MeurhSolicitacoesfaltasNewController } from './new/solicitacoesfaltas.new.controller';
import { MeurhSolicitacoesfaltasEditController } from './edit/solicitacoesfaltas.edit.controller';
import { MeurhSolicitacoesfaltasShowController } from './show/solicitacoesfaltas.show.controller';
import { MeurhSolicitacoesfaltasShowFormController } from './showform/solicitacoesfaltas.showform.controller';
import { MeurhSolicitacoesfaltasFormComponent } from './form/solicitacoesfaltas.form.component';
import { MeurhSolicitacoesfaltasShowFormComponent } from './showform/solicitacoesfaltas.showform.component';

export const MeurhSolicitacoesfaltasModule = angular.module('MeurhSolicitacoesfaltasModule', ['ui.router.state', ])
    .controller('MeurhSolicitacoesfaltasIndexController', MeurhSolicitacoesfaltasIndexController)
    .controller('MeurhSolicitacoesfaltasNewController', MeurhSolicitacoesfaltasNewController)
    .controller('MeurhSolicitacoesfaltasEditController', MeurhSolicitacoesfaltasEditController)
    .controller('MeurhSolicitacoesfaltasShowController', MeurhSolicitacoesfaltasShowController)
    .controller('MeurhSolicitacoesfaltasShowFormController', MeurhSolicitacoesfaltasShowFormController)
    .component('meurhSolicitacoesfaltasFormComponent', MeurhSolicitacoesfaltasFormComponent)
    .component('meurhSolicitacoesfaltasShowFormComponent', MeurhSolicitacoesfaltasShowFormComponent)
    .service('MeurhSolicitacoesFaltasService', MeurhSolicitacoesFaltasService)
    .config(MeurhSolicitacoesfaltasListRouting)
    .name;
