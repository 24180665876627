import { ui } from 'angular';
import { IModalConfirmacao } from '../models/modalconfirm';

export class MeurhAlteracoesDadosSalariaisModalConfirmService {

    static $inject = ['$uibModal'];

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
    ) { }

    public open(entity: any, textos: IModalConfirmacao): ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modalalteracoesdadossalariais.confirm.html'),
            controller: 'MeurhAlteracoesDadosSalariaisConfirmController',
            controllerAs: 'confirm',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                entity: () => entity,
                textos: () => textos,
            }
        });
    }
}
