import angular from 'angular';

export class MeurhApontamentosGradeModalSalvarController {
    static $inject = ['$uibModalInstance', 'formData', 'getRubrica', 'movimentosTipoSempre', 'trabalhadores'];

    public confirmarValoresApontamento: any;
    public trab: any = [];

    constructor(
        public $uibModalInstance: any,
        public formData: any,
        public getRubrica: any,
        public movimentosTipoSempre: any,
        public trabalhadores: any
    ) {
        formData.forEach((data: any, index: any) => {
            movimentosTipoSempre.forEach((movimento: any, i: any) => {
                if (trabalhadores[index]['trabalhador'] === movimento['trabalhador']['trabalhador']
                    && data.movimentos[movimento.evento.evento] !== undefined
                    && data.movimentos[movimento.evento.evento] > 0
                    && (trabalhadores[index].movimentos[movimento.evento.evento] === undefined
                        || trabalhadores[index].movimentos[movimento.evento.evento].situacao === -1)) {

                    this.confirmarValoresApontamento = true;

                    let indexTrabalhador = this.getIndexByTrabalhador(trabalhadores[index]['trabalhador']);

                    if (indexTrabalhador === false) {
                        let len = this.trab.push({
                            'trabalhador': trabalhadores[index]['trabalhador'],
                            'codigo': trabalhadores[index]['codigo'],
                            'nome': trabalhadores[index]['nome'],
                            'dataadmissao': trabalhadores[index]['dataadmissao'],
                            'numerohorasmensais': trabalhadores[index]['numerohorasmensais'],
                            'rubricas': []
                        });
                        indexTrabalhador = len - 1;
                    }

                    let rubrica = angular.copy(getRubrica(movimento.evento.evento));
                    rubrica.cadastrada = movimento.conteudo;
                    rubrica.apontada = data.movimentos[movimento.evento.evento];

                    this.trab[indexTrabalhador].rubricas.push(rubrica);
                }
            });
        });
    }

    ok() {
        this.trabalhadores.forEach((trabalhador: any, indexTrabalhador: any) => {
            this.trab.forEach((t: any, i: any) => {
                if (trabalhador['trabalhador'] === t['trabalhador']) {
                    t.rubricas.forEach((rubrica: any, n: any) => {
                        this.formData[indexTrabalhador].movimentos[rubrica.evento] = rubrica.apontada;
                    });
                }
            });
        });
        this.$uibModalInstance.close();
    }

    cancel() {
        this.$uibModalInstance.dismiss('fechar');
    }

    getIndexByTrabalhador(trabalhador: any) {
        for (let i = 0; i < this.trab.length; i++) {
            if (this.trab[i]['trabalhador'] === trabalhador) {
                return i;
            }
        }
        return false;
    }
}
