import ng from 'angular';
import { INewToaster } from './new.toaster.interface';

export class NewToasterController implements ng.IController {
  static $inject = [
    '$timeout',
    '$rootScope'
  ];

  public title: string = '';
  public body: string = '';
  public type: 'success' | 'error' | 'warning' | 'info' = 'error';
  public bodyOutputType?: 'trustedHtml' | 'template' | 'templateWithData';

  public showToaster: boolean = false;
  public disabledToaster: boolean = true;

  constructor(
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService
  ) {
    this.onPop();
  }

  public async pop(params: INewToaster): Promise<void> {
    params.timeout = !!params.timeout ? params.timeout : 6000;
    this.title = params.title;
    this.body = params.body;
    this.type = params.type;
    this.bodyOutputType = params.bodyOutputType;

    this.disabledToaster = false;
    await this.$timeout(() => {
      this.showToaster = true;
    }, 500);

    this.$timeout(() => {
      this.hide();
    }, params.timeout);
  }

  public hide(): void {
    this.showToaster = false;
    this.$timeout(() => {
      this.disabledToaster = true;
    }, 1000);
  }

  private onPop(): void {
    this.$rootScope.$on('new-toaster-pop', (event: ng.IAngularEvent, args: any) => {
      this.pop(args);
    });
  }
}
