import angular from 'angular';
import moment from 'moment';
import { MeurhEscalaColaboradoresFormModalService } from './form/colaboradores/modal/colaboradores.modal.service';
import { ModalConfirmService } from '../../../../shared/components/modal-confirm/modal.confirm.service';
import { FolgasService } from '../../../Folgas/folgas.service';
import { MeurhEscalaService } from '../escala.service';
import { TipoMudancaEnum } from '../enums/mudancatrabalhadores';
import { IAdicionarEscalaFolga, IAdicionarEscalaForm, IAdicionarEscalaLotacaoHorario } from '../models/escala.new.model';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhEscalaNewController {

    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        'MeurhEscalaColaboradoresFormModalService',
        'ModalConfirmService',
        'FolgasService',
        'MeurhEscalaService',
        'ListagemService',
        'NewToaster'
    ];
    public form: angular.IFormController;
    public entity = <IAdicionarEscalaForm> {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
        colaboradores: <ITrabalhador[]> []
    };
    public saveResponse: Array<{statusOk: boolean, message: string | null}> = [];
    public busy: boolean = false;
    public busySave = {
        folga: false,
        lotacao: false,
        horario: false
    };

    public filtros = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
    };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_calendario'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            session: any,
            liberadoTela: (arg: string) => boolean,
            temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            nsjGlobals: any
        },
		public $state: angular.ui.IStateService,
        public ColaboradoresModalService: MeurhEscalaColaboradoresFormModalService,
        public ModalConfirmService: ModalConfirmService,
        public FolgasService: FolgasService,
        public MeurhEscalaService: MeurhEscalaService,
        public ListagemService: ListagemService,
        public NewToaster: any,
	) {
        if (
            !this.$rootScope.temPermissao('visualizar_calendario') ||
            !this.$rootScope.liberadoTela('LIBERAR_CALENDARIO') ||
            !this.$rootScope.liberadoTela('LIBERAR_ADICIONAR_ESCALA') ||
            !(
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA') ||
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO') ||
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO')
            )
        ) {
            $state.go('dp');
        }

        this.montaListagemVisibilidade();
        this.onResponseSuccess();
        this.onResponseError();

        this.$scope.$watchGroup([
            'mrh_scl_nw_cntrllr.busySave.folga',
            'mrh_scl_nw_cntrllr.busySave.lotacao',
            'mrh_scl_nw_cntrllr.busySave.horario',
        ], (newValues: Array<boolean>, oldValues: Array<boolean>): void => {
			if (newValues !== oldValues && (newValues.every((busy: boolean) => busy === false))) {
                this.busy = false;
			}
		});

        this.$scope.$on('escala_new_request_show_toast', (event, args: boolean, message: string | null) => {
            this.saveResponse.push({
                statusOk: args,
                message
            });
            if (Object.values(this.busySave).every((busy) => busy === false)) {
                if (this.saveResponse.every(response => response.statusOk === true)) {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A escala foi adicionada com sucesso!'
                    });
                    this.$state.go('meurh_escala');
                } else if (this.saveResponse.every(response => response.statusOk === false)) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao adicionar a escala!',
                        body: this.setErrorMessage(this.saveResponse),
                        bodyOutputType: 'trustedHtml',
                        timeout: 8000,
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'warning',
                        title: 'A escala foi parcialmente adicionada!',
                        body: this.setErrorMessage(this.saveResponse),
                        bodyOutputType: 'trustedHtml',
                        timeout: 8000,
                    });
                }
            }
        });
    }

    montaListagemVisibilidade(): void {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filtros.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filtros.empresa ? this.ListagemService.montaEstabelecimentos(this.filtros.empresa, this.empresas, this.listagemPermissao) : [];
        this.filtros.estabelecimento = this.estabelecimentos.length ? this.estabelecimentos[0].id : '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entity = <IAdicionarEscalaForm> {
            empresa: this.filtros.empresa,
            estabelecimento: this.filtros.estabelecimento,
            colaboradores: <ITrabalhador[]> []
        };
    }

    salvar() {
        this.form.$submitted = true;
        if (this.form.$invalid) {
            return;
        }
        let confirm = this.modal();
        confirm.result.then(() => {
            this.busy = true;
            this.saveResponse = [];
            if (this.entity.selecionados.folga) {
                this.busySave.folga = true;
                this.FolgasService.saveEscala(this.setFolgaPayload());
            }
            if (this.entity.selecionados.lotacao) {
                this.busySave.lotacao = true;
                this.MeurhEscalaService.salvarEscala(
                    this.setMudancaPayload(TipoMudancaEnum.LOTACAO),
                    TipoMudancaEnum.LOTACAO
                );
            }
            if (this.entity.selecionados.horario) {
                this.busySave.horario = true;
                this.MeurhEscalaService.salvarEscala(
                    this.setMudancaPayload(TipoMudancaEnum.HORARIO),
                    TipoMudancaEnum.HORARIO
                );
            }
        }).catch(() => {/**/});
    }

    private modal() {
        return this.ModalConfirmService.open(this.entity, 'Adicionar escala', false, {
            title: 'Adicionar escala',
            confirmText: 'Tem certeza que deseja salvar esta escala?',
            closeButton: 'Cancelar',
            closeButtonIcon: 'invisible',
            confirmButton: 'Salvar',
            confirmButtonIcon: 'invisible',
        });
    }

    private setFolgaPayload(): IAdicionarEscalaFolga {
        let payload = <IAdicionarEscalaFolga> {
            empresa: this.entity.empresa,
            estabelecimento: this.entity.estabelecimento,
            datainicial: this.entity.datainicial,
            datafinal: this.entity.datafinal,
            trabalhadores: this.entity.colaboradores,
            tipoescala: Number(this.entity.folga.tipo),
            descricao: this.entity.folga.descricao
        };
        if (this.entity.folga.tipo === '2') {
            payload = {
                ...payload,
                domingo: this.entity.folga.dias[0].marcado,
                segunda: this.entity.folga.dias[1].marcado,
                terca: this.entity.folga.dias[2].marcado,
                quarta: this.entity.folga.dias[3].marcado,
                quinta: this.entity.folga.dias[4].marcado,
                sexta: this.entity.folga.dias[5].marcado,
                sabado: this.entity.folga.dias[6].marcado,
            };
        } else {
            payload = {
                ...payload,
                tipoiniciofolga: Number(this.entity.folga.tipoiniciofolga),
                diastrabalhados: this.entity.folga.diastrabalhados,
                diasfolgados: this.entity.folga.diasfolgados
            };
        }
        return payload;
    }

    private setMudancaPayload(tipo: number): IAdicionarEscalaLotacaoHorario {
        let tipoMudanca = tipo === TipoMudancaEnum.HORARIO ? 'horario' : 'lotacao';
        let payload = <IAdicionarEscalaLotacaoHorario> {
            empresa: this.entity.empresa,
            estabelecimento: this.entity.estabelecimento,
            datainicial: this.entity.datainicial,
            datafinal: this.entity.datafinal,
            trabalhadores: this.entity.colaboradores,
            tipo,
            tipoescala: Number(this.entity.lotacaohorario.tipo),
        };
        if (this.entity.lotacaohorario.tipo === '2') {
            let folgaSemanal = this.entity.selecionados.folga && this.entity.folga.tipo === '2' ? true : false;
            payload = {
                ...payload,
                domingo: !(folgaSemanal && this.entity.folga.dias[0].marcado) ?
                    this.entity.lotacaohorario.dias[0][tipoMudanca][tipoMudanca] : null,
                segunda: !(folgaSemanal && this.entity.folga.dias[1].marcado) ?
                    this.entity.lotacaohorario.dias[1][tipoMudanca][tipoMudanca] : null,
                terca: !(folgaSemanal && this.entity.folga.dias[2].marcado) ?
                    this.entity.lotacaohorario.dias[2][tipoMudanca][tipoMudanca] : null,
                quarta: !(folgaSemanal && this.entity.folga.dias[3].marcado) ?
                    this.entity.lotacaohorario.dias[3][tipoMudanca][tipoMudanca] : null,
                quinta: !(folgaSemanal && this.entity.folga.dias[4].marcado) ?
                    this.entity.lotacaohorario.dias[4][tipoMudanca][tipoMudanca] : null,
                sexta: !(folgaSemanal && this.entity.folga.dias[5].marcado) ?
                    this.entity.lotacaohorario.dias[5][tipoMudanca][tipoMudanca] : null,
                sabado: !(folgaSemanal && this.entity.folga.dias[6].marcado) ?
                    this.entity.lotacaohorario.dias[6][tipoMudanca][tipoMudanca] : null,
            };
        } else {
            payload = {
                ...payload,
                pk_entidade: this.entity.lotacaohorario[tipoMudanca][tipoMudanca]
            };
        }
        return payload;
    }

    private onResponseSuccess(): void {
        this.$scope.$on('folgas_escala_submitted', (event, args) => {
            this.busySave.folga = false;
            this.responseSuccess('folga');
        });
        this.$scope.$on('mudancastrabalhadores_escala_submitted', (event, args, tipo) =>  {
            tipo === TipoMudancaEnum.LOTACAO ? this.busySave.lotacao = false : this.busySave.horario = false;
            this.responseSuccess(tipo === TipoMudancaEnum.LOTACAO ? 'lotacao' : 'horario');
        });
    }

    private responseSuccess(tipo: string): void {
        this.entity.selecionados[tipo] = false;
        this.$scope.$broadcast('escala_new_request_show_toast', true, null);
    }

    private onResponseError(): void {
        this.$scope.$on('folgas_escala_submit_error', (event, args) => {
            this.busySave.folga = false;
            this.responseError(event, args, 'folga');
        });
        this.$scope.$on('mudancastrabalhadores_escala_submit_error', (event, args, tipo) => {
            tipo === TipoMudancaEnum.LOTACAO ? this.busySave.lotacao = false : this.busySave.horario = false;
            this.responseError(event, args, tipo === TipoMudancaEnum.LOTACAO ? 'lotação' : 'horário');
        });
    }

    private responseError(event: angular.IAngularEvent, args: any, tipo?: string | number): void {
        let message = '';
        if (args.data && args.data.length && tipo === 'folga') {
            let childrenMsg = '';
            args.data.forEach((erro: any) => {
                childrenMsg += `
                    <li>
                        ${erro.trabalhador.nome} - ${moment(new Date(erro.datafolga)).format('DD/MM/YYYY')} - ${erro.erro}
                    </li>
                `;
            });
            message = '<ul>' + childrenMsg + '</ul>';
        }

        if (message === '') {
            message = `<ul><li>Ocorreu um erro ao adicionar a escala de ${tipo}</li></ul>`;
        }
        this.$scope.$broadcast('escala_new_request_show_toast', false, message);
        this.form.$submitted = false;
    }

    private setErrorMessage(saveResponse: Array<{statusOk: boolean, message: string | null}>): string {
        let msg = '';
        saveResponse.forEach(response => {
            if (!response.statusOk) {
                msg += response.message;
            }
        });
        return msg;
    }
}
