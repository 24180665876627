import angular from 'angular';
import { IHorarioDetalhado } from '../models/horariodetalhado.model';

export class MeurhMudancaHorarioModalService {
    static $inject = ['$uibModal'];

    constructor(public $uibModal: any) {}

    open(entity: IHorarioDetalhado): any {
        return this.$uibModal.open({
            template: require('html-loader!./mudancahorario.modal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                function MeurhMudancaHorarioModal(
                    entity: IHorarioDetalhado,
                    $uibModalInstance: any,
                ): void {
                    let selfModal = this;
                    selfModal.entity = angular.copy(entity);

                    selfModal.close = (): void => {
                        $uibModalInstance.dismiss('fechar');
                    };
                }
            ],
            controllerAs: '$ctrl',
            windowClass: 'nsj-modal-lg-no-height',
            resolve: {
                entity: (): IHorarioDetalhado => {
                    return entity;
                }
            }
        });
    }
}
