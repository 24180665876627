import angular from 'angular';
import { LoadParams } from '../../shared/models/loadparams.model';

export class MeurhApontamentosService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entity: any;
    public entities: any = [];
    public loaded: boolean = false;
    public finished: any = false;
    public constructors: any = {};
    public after: any = {};
    public to_load: any = 3;
    public busy: any = false;
    public filters: any = {};
    public loading_deferred: any = null;
    public filter: any = '';
    public loadParams: LoadParams = new LoadParams();

    constructor(
        public $http: any,
        public nsjRouting: any,
        public $rootScope: any,
        public $q: any
    ) { }

    reload() {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.loadParams.busy = false;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;

        return this.load();
    }

    search(filter: any) {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('apontamentos_apontamentos_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    load() {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;
            this._load(this.constructors, this.after, this.filter)
                .then(data => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                    }
                    this.loadParams.finished = true;
                    this.$rootScope.$broadcast('apontamentos_apontamentos_list_finished', this.entities);
                    this.loaded = true;
                    this.loadParams.to_load--;
                }).catch(error => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                }).finally(() => {
                    if (this.loaded || this.loadParams.finished) {
                        this.busy = false;
                    }
                });
        }
        return this.entities;
    }

    loadMore() {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: any) {
        for (var i in this.entities) {
            if (this.entities[i]['apontamento'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }

    delete($identifier: any, force: any) {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['apontamento'];
        }
        if ($identifier) {
            if (force) {
                this.loading_deferred = this.$q.defer();
                this.$http
                    .delete(this.nsjRouting.generate('apontamentos_apontamentos_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
                        { timeout: this.loading_deferred.promise })
                    .then((response: any) => {
                        this.$rootScope.$broadcast('apontamentos_apontamentos_deleted', {
                            entity: this.entity,
                            response: response
                        });
                    })
                    .catch((response: any) => {
                        this.$rootScope.$broadcast('apontamentos_apontamentos_delete_error', {
                            entity: this.entity,
                            response: response
                        });
                    });
            }
        }
    }

    _save(entity: any, autosave: any) {
        var method, url;
        if (entity['apontamento']) {
            method = 'PUT';
            url = this.nsjRouting.generate('apontamentos_apontamentos_edit_apontamento_lote', { estabelecimento: entity.estabelecimento, 'id': entity['apontamento'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('apontamentos_apontamentos_insert_apontamento_lote', angular.extend(this.constructors), true);
        }
        if (!autosave) {
            autosave = false;
            entity.enviando ? entity['$$__submitting'] = true : entity['update'] = true;
        }
        var data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
            .finally(() => {
                if (!autosave) {
                    entity.enviando ? entity['$$__submitting'] = false : entity['update'] = false;
                }
                // $rootScope.$broadcast('apontamentos_apontamentos_submitted', {
                //     entity: entity,
                //     response : response,
                //     autosave : autosave
                // });
            });
    }

    save(entity: any, autosave: boolean = false) {
        entity.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this._save(entity, autosave)
            .then((response: any) => {
                if (response.data.apontamento) {
                    entity['apontamento'] = response.data.apontamento;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('apontamentos_apontamentos_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any) => {
                this.$rootScope.$broadcast('apontamentos_apontamentos_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    draftInsert(entity: any) {
        entity.situacao = 0; // rascunho
        entity.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        // this.loading_deferred = $q.defer();
        entity['$$__submitting'] = true;
        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'post',
                url: this.nsjRouting.generate('apontamentos_apontamentos_insert_apontamento_lote', {
                    'estabelecimento': entity.estabelecimento
                }, true),
                data: angular.copy(entity),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    if (response.data.apontamento) {
                        entity['apontamento'] = response.data.apontamento;
                    }
                    entity['$$_saved'] = true;
                    this.$rootScope.$broadcast('apontamentos_apontamentos_submitted', {
                        entity: entity,
                        response: response
                    });
                })
                .catch((response: any) => {
                    this.$rootScope.$broadcast('apontamentos_apontamentos_submit_error', {
                        entity: entity,
                        response: response
                    });
                })
                .finally((response: any) => {
                    entity['$$__submitting'] = false;
                });
        });
    }

    draftUpdate(entity: any) {
        entity.situacao = 0; // rascunho
        entity.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        // this.loading_deferred = $q.defer();
        entity['$$__submitting'] = true;
        return this.$q((resolve: any, reject: any) => {
            this.$http({
                method: 'put',
                url: this.nsjRouting.generate('apontamentos_apontamentos_edit_apontamento_lote', { estabelecimento: entity.estabelecimento, 'id': entity.apontamento }, true),
                data: angular.copy(entity),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                }).finally((response: any) => {
                    entity['$$__submitting'] = false;
                })
                .finally((response: any) => {
                    entity['$$__submitting'] = false;
                });
        });
    }

    get(estabelecimento: any, identifier: any) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
            this.$http.get(
                this.nsjRouting.generate('apontamentos_apontamentos_get', { 'estabelecimento': estabelecimento, 'id': identifier }, true),
                { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    this.$rootScope.$broadcast('apontamentos_apontamentos_loaded', response.data);
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }
}
