
import * as angular from 'angular';

import { ColaboradorController } from './colaborador.controller';
import { ColaboradorComponent } from './colaborador.component';

export const MeurhColaboradorModule = angular.module('MeurhColaboradorModule', ['ui.router.state'])
    .controller('ColaboradorController', ColaboradorController)
    .component('colaboradorComponent', ColaboradorComponent)
    .name;
