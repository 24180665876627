import angular = require('angular');
import { LoadParams } from '../../../shared/models/loadparams.model';
import { IRecisao } from './models/recisao.model';

export class MeurhMotivosrescisoesService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public entity: IRecisao;
    public entities: Array<IRecisao> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams;
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService,
    ) {
        this.loadParams = new LoadParams();
    }

    reload(): Array<IRecisao> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any = undefined): Array<IRecisao> {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any>  {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('persona_motivosrescisoes_index', angular.extend({}, constructors, {'offset': offset, 'filter': filter}, this.filters), true),
                timeout: this.loading_deferred.promise
            })
            .then((response: any): void => {
                 resolve(response.data);
             })
            .catch((response: any): void => {
                 reject(response);
            });

        });

    }

    load(): Array<IRecisao> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0 ) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any) => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                                                            this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                                this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                                                }
                                            if (data.length < 20) {
                                                this.loadParams.finished = true;
                        this.$rootScope.$broadcast('persona_motivosrescicoes_list_finished', this.entities);
                                            } else {
                        this.loadParams.finished = false;
                    }
                                            this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error: any): void => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally((): void => {
                    if ( this.loaded || this.loadParams.finished ) {
                        this.loadParams.busy = false;
                    }
                                            this.load();
                                        });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: string): IRecisao | null {
      for (var i in this.entities) {
            if (this.entities[i]['solicitacao'] === identifier) {
                return this.entities[i];
            }
        }
        return null;
    }


}
