import * as angular from 'angular';
import { MeurhFeriasMarcacoesNewEditFormController } from './feriasmarcacoes.newedit.form.controller';

export class MeurhFeriasMarcacoesNewEditFormComponent implements angular.IComponentOptions {

  static selector = 'MeurhFeriasMarcacoesNewEditFormComponent';
  static controller = MeurhFeriasMarcacoesNewEditFormController;
  static template = require('!!html-loader!./feriasmarcacoes.newedit.form.html');
  static transclude = true;
  static bindings = {
    entity: '=',
    form: '<',
    busy: '=',
    action: '<',
    outrasSolicitacoes: '=',
    erroPreenchimento: '=?',
    rascunhoApenas: '=?'
  };

}
