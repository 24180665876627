import angular from 'angular';
import { MeurhInclusaodependenteDocumentosanexosFormController } from './documentosanexos.form.controller';

export class MeurhInclusaodependenteDocumentosanexosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhInclusaodependenteDocumentosanexosFormComponent';
	static controller = MeurhInclusaodependenteDocumentosanexosFormController;
	static template = require('!!html-loader!./documentosanexos.form.html');
	static transclude = true;
	static bindings = {
		busy: '<',
		busyTipoDocumentos: '<',
		entityanexo: '<',
		file: '<',
		action: '<',
		mostrarAlerta: '<',
		tiposdocumentosrequeridos: '<',
		editanexo: '<',
		alterouanexo: '<'
	};
}
