import angular from 'angular';
import moment from 'moment';
import { MeurhInformesrendimentosService } from '../informesrendimentos.service';
import { IInformeRendimento } from '../models/informerendimento.model';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhInformesrendimentosListController implements angular.IController {
    static $inject = ['nsjRouting', '$scope', '$rootScope', '$filter', 'NewToaster', '$stateParams' , 'MeurhInformesrendimentosService', 'RelatoriosService', '$http', '$uibModal', '$state', 'ClientSession', 'ModalConfirmService', 'ListagemService'];

    public parametrosSessao: any = null;
    public busy: boolean = false;
    public checkboxPreenchidos: any = [];
    public lookupTrabalhadores: boolean = false;
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public trabalhadores: any;
    public isDownloading: boolean = false;
    public carregandoRelatorio: boolean = false;
    public filterData: any = {
        ano: moment().year(),
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
        trabalhador: ''
    };
    public multiplosInformes: boolean = false;
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;
    public listagemPermissao: Array<string> = ['relatorios_informerendimento'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor(
        public nsjRouting: any,
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean, modoGestorPermissao: (arg: string) => boolean, temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean, nsjGlobals: any, session: any },
        public $filter: angular.IFilterFilter,
        public NewToaster: any,
        public $stateParams: angular.ui.IStateParamsService,
        public entityService: MeurhInformesrendimentosService,
        public RelatoriosService: any,
        public $http: angular.IHttpService,
        public $uibModal: any,
        public $state: angular.ui.IStateService,
        public ClientSession: any,
        public ModalConfirmService: any,
        public ListagemService: ListagemService
    ) {
        if (!this.$rootScope.temPermissao('relatorios_informerendimento')) {
            $state.go('dp', $stateParams);
        }

        this.$rootScope.$on('Trabalhadores_loading', (event: angular.IAngularEvent, args: any): void => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        this.$rootScope.$on('Trabalhadores_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.lookupTrabalhadores = false;
        });

        this.montaListagemVisibilidade();
        this.ClientSession.load();
    }

    $onInit(): void {
        this.carregaClientSession();
        this.onDeleteSuccess();
        this.onDeleteError();
    }

    carregaClientSession(): void {
        this.$rootScope.$on('meurh_clientsession_session_loaded_service', (event: angular.IAngularEvent, args: any): void => {
            if (!angular.isUndefined(args.empresa)) {
                this.parametrosSessao = args;
                this.filter();
            }
        });
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    carregandoLookups(): boolean {
        return this.lookupTrabalhadores;
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    finished(): boolean {
        return this.entityService.loadParams.finished;
    }

    montaListagemVisibilidade(): void {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filterData.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filterData.empresa ? this.ListagemService.montaEstabelecimentos(this.filterData.empresa, this.empresas, this.listagemPermissao) : [];
        this.filterData.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        delete this.filterData.trabalhador;
        this.filter();
    }

    filter(): void {
        if (this.parametrosSessao !== null) {
            this.checkboxPreenchidos = [];
            this.entityService.constructors = angular.copy(this.filterData);

            if (typeof(this.entityService.constructors.trabalhador) === 'object' && this.entityService.constructors.trabalhador !== null) {
                this.entityService.constructors.trabalhador = this.entityService.constructors.trabalhador.trabalhador;
            } else {
                delete this.entityService.constructors.trabalhador;
            }

            this.$rootScope.$broadcast('trabalhador_refresh', this.trabalhadoresConstructor());
            this.trabalhadores = this.entityService.reload();
        }
    }

    trabalhadoresConstructor(): {} {
        return { 'empresa': this.filterData.empresa, 'estabelecimento': this.filterData.estabelecimento, 'forcagestor': this.$rootScope.modoGestorPermissao('relatorios_informerendimento') };
    }

    checar_todos(): void {
        let checktoggle: any = document.getElementById('check_all');
        let elementos: any = document.getElementsByClassName('elementos_check');

        if (!checktoggle.checked) {
            this.checkboxPreenchidos = [];

            for (let i = 0; i < elementos.length; i++) {
                elementos[i].checked = false;
            }

            return;
        }

        this.checkboxPreenchidos = angular.copy(this.trabalhadores);

        for (let i = 0; i < elementos.length; i++) {
            elementos[i].checked = true;
        }
    }

    checar_elemento(entity: IInformeRendimento): void {
        let removeIndex = this.checkboxPreenchidos.map((item: any): string => item.informerendimento).indexOf(entity.informerendimento);
        let checktoggle: any = document.getElementById('check_all');
        let elementos: any = document.getElementsByClassName('elementos_check');
        let temElementoChecked: boolean = false;
        let elementosChecked: any = [];

        elementos.forEach((elemento: any) => {
            if (elemento.checked === true) {
                temElementoChecked = true;
                elementosChecked.push(elemento);
            }
        });
        if (elementosChecked.length === elementos.length && temElementoChecked) {
            checktoggle.checked = true;
        } else {
            checktoggle.checked = false;
        }

        if (removeIndex > -1) {
            this.checkboxPreenchidos.splice(removeIndex, 1);
            return;
        }
        this.checkboxPreenchidos.push(entity);
    }

    desmarcar_todos(): void {
        let checktoggle: any = document.getElementById('check_all');
        let elementos: any = document.getElementsByClassName('elementos_check');

        this.checkboxPreenchidos = [];
        checktoggle.checked = false;

        for (let i = 0; i < elementos.length; i++) {
            elementos[i].checked = false;
        }

        for (let i = 0; i < this.trabalhadores.length; i++) {
            this.trabalhadores[i].check = false;
        }
    }

    download_lote(): void {
        let itens = this.checkboxPreenchidos.map((entity: any): {} => {
            let item = { 'informerendimento': entity.informerendimento, 'idtrabalhador': entity.trabalhador.trabalhador };
            return item;
        });

        this.isDownloading = true;
        let constructor = {
            'empresa': this.filterData.empresa,
            'estabelecimento': this.filterData.estabelecimento
        };

        this.$http({
            method: 'post',
            url: this.nsjRouting.generate('meurh_informesrendimentos_downloadinformeslote', constructor, true),
            data: { 'entities': itens },
            responseType: 'blob'
        })
        .then((response: any): void => {
            let nomeArquivo = '';
            let blob = response.data;
            let fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
            let a = document.createElement('a');

            nomeArquivo = 'informes_lote';
            nomeArquivo += '.zip';

            document.body.appendChild(a);

            a.style.display = 'none';
            a.href = fileURL;
            a.download = nomeArquivo;
            a.click();

            window.URL.revokeObjectURL(fileURL);

            this.desmarcar_todos();
            this.isDownloading = false;
        });
    }

    delete(force: boolean) {
        if (this.checkboxPreenchidos.length < 1) {
            this.NewToaster.pop({
                type: 'error',
                title: 'É necessário selecionar pelo menos um informe de rendimento'
            });
        } else {
            let itens = this.checkboxPreenchidos.map((entity: IInformeRendimento): {} => {
                let item = { 'informerendimento': entity.informerendimento };
                return item;
            });
            let customText = {
                title: 'Excluir',
                confirmText: 'Você tem certeza que deseja excluir o(s) informe(s) de rendimentos selecionado(s)?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, excluir',
            };
            let confirm = this.ModalConfirmService.open(null, 'informe de rendimento', false, customText);
            confirm.result.then( () => {
                itens.forEach((item: any) => {
                    this.entityService.delete(item.informerendimento, force);
                });
            }).catch(() => {/* */});
        }
    }

    onDeleteSuccess() {
        this.$scope.$on('meurh_informesrendimentos_deleted', (event, args) => {
            if (this.checkboxPreenchidos.length > 1) {
                this.multiplosInformes = true;
            }
            this.NewToaster.pop({
                type: 'success',
                title: (this.multiplosInformes ? 'Os informes de rendimentos foram excluídos' : 'O informe de rendimento foi excluído') + ' com sucesso!'
            });
            this.filter();
        });
    }

    onDeleteError() {
        this.$scope.$on('meurh_informesrendimentos_delete_error', (event, args) => {
            if (args.response.data.message && typeof (args.response.data.message) !== 'undefined') {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    });
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir o(s) informe(s) de rendimentos!'
                    });
            }
        });
    }

    abrirInforme(informerendimento: IInformeRendimento): void {
        this.carregandoRelatorio = true;
        let constructor = {
            'id': informerendimento.informerendimento,
            'idtrabalhador': informerendimento.trabalhador.trabalhador,
            'empresa': this.filterData.empresa,
            'estabelecimento': this.filterData.estabelecimento,
            'offset': 0,
            'filter': this.entityService.filter
        };
        let callback = (): void => {
            this.carregandoRelatorio = false;
        };

        this.busy = true;

        this.$http.get(
            this.nsjRouting.generate('meurh_informesrendimentos_get_pdf', constructor, true),
            { responseType: 'blob' }
        )
        .then((response: any): void => {
            let file = new Blob([response.data], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);

            let uibModalInst = this.$uibModal.open({
                animation: true,
                keyboard: true,
                backdrop: true,
                template:
                    `<a id="closeModal" class="pull-right" style="margin-top: -30px;color: lightgray;cursor: pointer;" ng-click="ctrlModal.close()">
                        <i class="fas fa-times"></i>
                    </a>` +
                    '<embed src="' +
                    fileURL +
                    '" width="100%" height="100%" ng-init="ctrlModal.setStyle()"/>',
                controller: function(): void {
                    let selfModal: any = this;

                    selfModal.setStyle = function(): void {
                        let modalDialog: any = document.getElementsByClassName('modal-dialog')[0];
                        modalDialog.style.width = '90%';
                        modalDialog.style.height = '90%';

                        let modalContent: any = document.getElementsByClassName('modal-content')[0];
                        modalContent.style.padding = '40px 20px 20px 20px';
                        modalContent.style.height = '100%';
                    };

                    selfModal.close = function(): void {
                        uibModalInst.close(false);
                    };
                },
                controllerAs: 'ctrlModal'
            });

            uibModalInst.result.then(
                function() {
                    uibModalInst = undefined;
                },
                function() {
                    uibModalInst = undefined;
                }
            );
        })
        .catch((error: any): void => {
            this.NewToaster.pop({
                type: 'error',
                title: error.data.message
            });
        })
        .finally((): void => {
            if (typeof callback === 'function') {
                callback();
            }

            this.busy = false;
        });
    }
}
