export enum TipoSolicitacaoEnum {
  ADMISSAO_PRELIMINAR = 0,
  RECISAO = 1,
  ADIANTAMENTO_AVULSO = 2,
  VT_ADICIONAL = 3,
  ALTERACAO_VT = 4,
  ALTERACAO_DADOS_CADASTRAIS = 5,
  FALTA = 6,
  FERIAS = 7,
  AFASTAMENTO = 8,
  PROMOCAO = 9,
  CREDITOS_E_DESCONTOS = 10,
  ADMISSOESCOMPLETAS = 12,
  TRANSFERENCIA_DE_COLABORADOR = 13,
  MUDANCA_HORARIO = 14,
  ADMISSOESESTAGIARIO = 15,
  INCLUSAODEPENDENTE = 16,
  ALTERACAODADOSSALARIAIS = 20,
  AJUSTERETORNOAFASTAMENTO = 24,
}
