export enum MotivoRescisaoEnum {
      RESCISAO_COM_JUSTA_CAUSA_INICIATIVA_EMPREGADOR = '01',
      RESCISAO_SEM_JUSTA_CAUSA_INICIATIVA_EMPREGADOR = '02',
      RESCISAO_ANTECIPADA_INICIATIVA_EMPREGADOR = '03',
      RESCISAO_ANTECIPADA_INICIATIVA_EMPREGADO = '04',
      RESCISAO_CULPA_RECIPROCA = '05',
      RESCISAO_TERMINO_CONTRATO_TERMO = '06',
      RESCISAO_CONTRATO_INICIATIVA_EMPREGADO = '07',
      RESCISAO_CONTRATO_INTERESSE_EMPREGADO_ARTS_394_E_483 = '08',
      RESCISAO_FALECIMENTO_EMPREGADOR = '09',
      RESCISAO_FALECIMENTO_EMPREGADO = '10',
      TRANSFERENCIA_EMPREGADO_EMPRESA_MESMO_GRUPO_EMPRESARIAL = '11',
      TRANSFERENCIA_EMPREGADO_EMPRESA_CONSORCIADA = '12',
      TRANSFERENCIA_EMPREGADO_EMPRESA_CONSORCIO = '13',
      RESCISAO_CONTRATO_TRABALHO_ENCERRAMENTO_EMPRESA = '14',
      DEMISSAO_APRENDIZES_INADAPTACAO = '15',
      DECLARACAO_NULIDADE = '16',
      RESCISAO_INDIRETA = '17',
      APOSENTADORIA_COMPULSORIA = '18',
      APOSENTADORIA_POR_IDADE = '19',
      APOSENTADORIA_POR_IDADE_E_TEMPO_DE_CONTRIBUICAO = '20',
      REFORMA_MILITAR = '21',
      RESERVA_MILITAR = '22',
      EXONERACAO = '23',
      DEMISSAO_TRABALHADORES_301_E_312 = '24',
      VACANCIA_CARGO_EFETIVO = '25',
      RESCISAO_CONTRATO_TRABALHO_PARALISACAO_TEMPORARIA = '26',
      RESCISAO_FORCA_MAIOR = '27',
      TERMINO_CESSAO = '28',
      REDISTRIBUICAO = '29',
      MUDANCA_REGIME_TRABALHISTA = '30',
      REVERSAO_REINTEGRACAO = '31',
      EXTRAVIO_MILITAR = '32',
      RESCISAO_ACORDO_ENTRE_PARTES = '33',
      TRANSFERENCIA_TITULARIDADE_EMPREGADO_DOMESTICO = '34',
      REMOCAO_EMCASO_ALTERACAO = '37',
      APOSENTADORIA_EXCETO_INVALIDEZ = '38',
      APOSENTADORIA_SERVIDOR_ESTATUARIO = '39',
      TERMINO_EXERCICIO_MANDATO_ELETIVO = '40',
      RESCISAO_CONTRATO_APRENDIZAGEM_INADAPTACAO = '41',
      RESCISAO_CONTRATO_APRENDIZAGEM_AUSENCIA_INJUSTIFICADA = '42'
}
