import angular from 'angular';
import { ISolicitacaoAlteracaoVT } from '../models/solicitacaoalteracaovt.model';

export class MeurhSolicitacoesalteracoesvtsShowFormController implements angular.IController {
    static $inject = [
        'MeurhSolicitacoesalteracoesvtstarifasFormService',
        '$rootScope',
        '$scope',
        'NewToaster'
    ];

    public idCount: number = 0;
    public form: angular.IFormController;
    public entity: ISolicitacaoAlteracaoVT;

    constructor(
        public MeurhSolicitacoesalteracoesvtstarifasFormService: any,
        public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
        public $scope: angular.IScope,
        public NewToaster: any
    ) {
        $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);
    }

    meurhSolicitacoesalteracoesvtstarifasForm(): void {
        let modal = this.MeurhSolicitacoesalteracoesvtstarifasFormService.open({}, {});

        modal.result
            .then((subentity: any): void => {
                subentity.$id = this.idCount++;

                if (this.entity.solicitacoesalteracoesvtstarifas === undefined) {
                    this.entity.solicitacoesalteracoesvtstarifas = [subentity];
                } else {
                    this.entity.solicitacoesalteracoesvtstarifas.push(subentity);
                }
            })
            .catch((error: any): void => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }
}
