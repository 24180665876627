import * as angular from 'angular';
import { WorkflowListController } from './workflow.index.controller';

export class WorkflowDefault implements angular.IComponentOptions {
  static selector = 'workflowDefault';
  static controller = WorkflowListController;
  static controllerAs = 'wkf_lst';
  static template = require('!!html-loader!./workflow.index.html');
  static transclude = true;

  static bindings = {
    workflowBusy: '=',
    workflowAcoes: '<',
    workflowId: '<',
    workflowTrabalhador: '<'
  };
}
