import ng from 'angular';
import { IWorkflowAcao } from '../../models/workflow.acao.model';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';

export class WorkflowCommentModalController implements ng.IController {

    static $inject = [
        'acao',
        'trabalhador',
        '$uibModalInstance',
        'NewToaster'
    ];

    public form: ng.IFormController;
    private toastTimeout: number = 8000;

    constructor(
        public acao: IWorkflowAcao,
        public trabalhador: ITrabalhador,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        public NewToaster: any
    ) {
        this.tratarTextosModal();
    }

    public confirmar(): void {
        if (this.form.$valid) {
            this.$uibModalInstance.close(this.acao);
            return;
        }

        this.NewToaster.pop({
            type: 'error',
            tittle: 'Ocorreu um erro ao enviar o comentário',
            timeout: this.toastTimeout
        });
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }

    private tratarTextosModal(): void {
        this.tratarNomeColaborador(this.acao.modal!.comentario!.textos_acima);
        this.tratarNomeColaborador(this.acao.modal!.comentario!.textos_abaixo);
    }

    private tratarNomeColaborador(textos: string[]): void {
        textos.forEach((texto: string, index: number) => {
            const textoModificado: string = texto.replace('{{nome_colaborador}}', this.trabalhador?.nome);
            textos[index] = textoModificado;
        });
    }
}
