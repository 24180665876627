import angular from 'angular';
import { MeurhSolicitacoesafastamentosretornoFormController } from './solicitacoesafastamentosretorno.form.controller';

export class MeurhSolicitacoesafastamentosretornoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesafastamentosretornoFormComponent';
	static controller = MeurhSolicitacoesafastamentosretornoFormController;
	static template = require('!!html-loader!./solicitacoesafastamentosretorno.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<'
	};
}
