
import * as angular from 'angular';

import { MeurhCreditosdescontosWizardController } from './wizard/creditosdescontos.wizard.controller';
import { MeurhCreditosdescontosPassoumController } from './passo01/creditosdescontos.passoum.controller';
import { MeurhCreditosdescontosPassodoisController } from './passo02/creditosdescontos.passodois.controller';
import { MeurhCreditosdescontosPassotresController } from './passo03/creditosdescontos.passotres.controller';
import { MeurhCreditosdescontosPassoquatroController } from './passo04/creditosdescontos.passoquatro.controller';
import { MeurhCreditosdescontosShowController } from './show/creditosdescontos.show.controller';
import { MeurhCreditosdescontosEditController } from './edit/creditosdescontos.edit.controller';
import { MeurhCreditosdescontosListController } from './index/creditosdescontos.index.controller';
import { MeurhCreditosdescontosShowbuiltinController } from './showbuiltin/creditosdescontos.showbuiltin.controller';
import { MeurhCreditosdescontosEditbuiltinController } from './editbuiltin/creditosdescontos.editbuiltin.controller';
import { MeurhCreditosdescontosFormController } from './form/creditosdescontos.form.controller';

import { MeurhCreditosdescontosPassoumComponent } from './passo01/creditosdescontos.passoum.component';
import { MeurhCreditosdescontosPassodoisComponent } from './passo02/creditosdescontos.passodois.component';
import { MeurhCreditosdescontosPassotresComponent } from './passo03/creditosdescontos.passotres.component';
import { MeurhCreditosdescontosPassoquatroComponent } from './passo04/creditosdescontos.passoquatro.component';
import { MeurhCreditosdescontosFormComponent } from './form/creditosdescontos.form.component';

import { MeurhCreditosdescontosService } from './creditosdescontos.service';
import { MeurhCreditosdescontosAdicionarmodalService } from './modal.adicionar/creditosdescontos.adicionarmodal.service';
import { MeurhCreditosdescontosAlertaduplicidadeService } from './modal.alertaduplicidade/creditosdescontos.alertaduplicidademodal.service';
import { MeurhCreditosdescontosIndisponivelmodalService } from './modal.indisponivel/creditosdescontos.indisponivelmodal.service';
import { RubricasPontoOverLoadService } from '../../Apontamentos/rubricaspontooverloadservice';

import { MeurhCreditosdescontosListRouting } from './creditosdescontos.routes';


export const MeurhCreditosDescontosModule = angular.module('MeurhCreditosDescontosModule', ['ui.router.state', ])
    .controller('MeurhCreditosdescontosWizardController', MeurhCreditosdescontosWizardController)
    .controller('MeurhCreditosdescontosPassoumController', MeurhCreditosdescontosPassoumController)
    .controller('MeurhCreditosdescontosPassodoisController', MeurhCreditosdescontosPassodoisController)
    .controller('MeurhCreditosdescontosPassotresController', MeurhCreditosdescontosPassotresController)
    .controller('MeurhCreditosdescontosPassoquatroController', MeurhCreditosdescontosPassoquatroController)
    .controller('MeurhCreditosdescontosShowController', MeurhCreditosdescontosShowController)
    .controller('MeurhCreditosdescontosEditController', MeurhCreditosdescontosEditController)
    .controller('MeurhCreditosdescontosListController', MeurhCreditosdescontosListController)
    .controller('MeurhCreditosdescontosShowbuiltinController', MeurhCreditosdescontosShowbuiltinController)
    .controller('MeurhCreditosdescontosEditbuiltinController', MeurhCreditosdescontosEditbuiltinController)
    .controller('MeurhCreditosdescontosFormController', MeurhCreditosdescontosFormController)
    .component('meurhCreditosdescontosPassoumComponent', MeurhCreditosdescontosPassoumComponent)
    .component('meurhCreditosdescontosPassodoisComponent', MeurhCreditosdescontosPassodoisComponent)
    .component('meurhCreditosdescontosPassotresComponent', MeurhCreditosdescontosPassotresComponent)
    .component('meurhCreditosdescontosPassoquatroComponent', MeurhCreditosdescontosPassoquatroComponent)
    .component('meurhCreditosdescontosFormComponent', MeurhCreditosdescontosFormComponent)
    .service('MeurhCreditosdescontosService', MeurhCreditosdescontosService)
    .service('MeurhCreditosdescontosFormComponent', MeurhCreditosdescontosFormComponent)
    .service('MeurhCreditosdescontosAdicionarmodalService', MeurhCreditosdescontosAdicionarmodalService)
    .service('MeurhCreditosdescontosAlertaduplicidadeService', MeurhCreditosdescontosAlertaduplicidadeService)
    .service('MeurhCreditosdescontosIndisponivelmodalService', MeurhCreditosdescontosIndisponivelmodalService)
    .service('RubricasPontoOverLoadService', RubricasPontoOverLoadService)
    .config(MeurhCreditosdescontosListRouting)
    .name;
