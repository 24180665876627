import angular from 'angular';
import { MeurhEscalaController } from './index/escala.index.controller';
import { MeurhEscalaNewController } from './new/escala.new.controller';
import { MeurhEscalaService } from './escala.service';
import { MeurhEscalaVisualizacaoModalController } from './modal.visualizacao/escala.visualizacaomodal.controller';
import { MeurhEscalaVisualizacaoModalService } from './modal.visualizacao/escala.visualizacaomodal.service';
import { MeurhEscalaErrosModalController } from './modal.erros/escala.errosmodal.controller';
import { MeurhEscalaErrosModalService } from './modal.erros/escala.errosmodal.service';
import { MeurhEscalaSchedulerService } from './escala.scheduler.service';
import { MeurhEscalaListRouting } from './escala.routes';

import { MeurhEscalaImportacaoController } from './importacao/escala.importacao.controller';
import { MeurhEscalaImportacaoComponent } from './importacao/escala.importacao.component';

import { MeurhEscalaFiltrosController } from './filtros/escala.filtros.controller';
import { MeurhEscalaFiltrosComponent } from './filtros/escala.filtros.component';

import { MeurhEscalaColaboradoresFormController } from './new/form/colaboradores/colaboradores.form.controller';
import { MeurhEscalaColaboradoresFormComponent } from './new/form/colaboradores/colaboradores.form.component';
import { MeurhEscalaColaboradoresFormModalController } from './new/form/colaboradores/modal/colaboradores.modal.controller';
import { MeurhEscalaColaboradoresFormModalService } from './new/form/colaboradores/modal/colaboradores.modal.service';

import { MeurhEscalaDadosIniciaisFormController } from './new/form/dadosiniciais/dadosiniciais.form.controller';
import { MeurhEscalaDadosIniciaisFormComponent } from './new/form/dadosiniciais/dadosiniciais.form.component';

import { MeurhEscalaFolgaFormController } from './new/form/folga/folga.form.controller';
import { MeurhEscalaFolgaFormComponent } from './new/form/folga/folga.form.component';

import { MeurhEscalaLotacaoHorarioFormController } from './new/form/lotacaohorario/lotacaohorario.form.controller';
import { MeurhEscalaLotacaoHorarioFormComponent } from './new/form/lotacaohorario/lotacaohorario.form.component';

export const MeurhEscalaModule = angular.module('EscalaModule', ['ui.router.state', ])
    .controller('MeurhEscalaController', MeurhEscalaController)
    .controller('MeurhEscalaVisualizacaoModalController', MeurhEscalaVisualizacaoModalController)
    .controller('MeurhEscalaErrosModalController', MeurhEscalaErrosModalController)
    .controller('MeurhEscalaNewController', MeurhEscalaNewController)
    .controller('MeurhEscalaColaboradoresFormController', MeurhEscalaColaboradoresFormController)
    .controller('MeurhEscalaColaboradoresFormModalController', MeurhEscalaColaboradoresFormModalController)
    .controller('MeurhEscalaDadosIniciaisFormController', MeurhEscalaDadosIniciaisFormController)
    .controller('MeurhEscalaFolgaFormController', MeurhEscalaFolgaFormController)
    .controller('MeurhEscalaLotacaoHorarioFormController', MeurhEscalaLotacaoHorarioFormController)
    .controller('MeurhEscalaImportacaoController', MeurhEscalaImportacaoController)
    .controller('MeurhEscalaFiltrosController', MeurhEscalaFiltrosController)
    .component('meurhEscalaColaboradoresFormComponent', MeurhEscalaColaboradoresFormComponent)
    .component('meurhEscalaDadosIniciaisFormComponent', MeurhEscalaDadosIniciaisFormComponent)
    .component('meurhEscalaFolgaFormComponent', MeurhEscalaFolgaFormComponent)
    .component('meurhEscalaLotacaoHorarioFormComponent', MeurhEscalaLotacaoHorarioFormComponent)
    .component('meurhEscalaImportacaoComponent', MeurhEscalaImportacaoComponent)
    .component('meurhEscalaFiltrosComponent', MeurhEscalaFiltrosComponent)
    .service('MeurhEscalaService', MeurhEscalaService)
    .service('MeurhEscalaVisualizacaoModalService', MeurhEscalaVisualizacaoModalService)
    .service('MeurhEscalaErrosModalService', MeurhEscalaErrosModalService)
    .service('MeurhEscalaColaboradoresFormModalService', MeurhEscalaColaboradoresFormModalService)
    .service('MeurhEscalaSchedulerService', MeurhEscalaSchedulerService)
    .config(MeurhEscalaListRouting)
    .name;
