import angular from 'angular';
import { MeurhRescisoesDocumentosanexosFormController } from './documentosanexosrescisoes.form.controller';

export class MeurhRescisoesDocumentosanexosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhRescisoesDocumentosanexosFormComponent';
	static controller = MeurhRescisoesDocumentosanexosFormController;
	static template = require('!!html-loader!./documentosanexosrescisoes.form.html');
	static transclude = true;
	static bindings = {
		busy: '=',
		busyTipoDocumentos: '=',
		entity: '=',
		entityanexo: '=',
		file: '=?',
		action: '=?',
		mostrarAlerta: '=',
		tiposdocumentosrequeridos: '=',
		editanexo: '=',
		alterouanexo: '='
	};
}
