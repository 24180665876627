export class ConfiguracoesPortariaModalService {
  static $inject = ['$uibModal'];

  constructor(public $uibModal: ng.ui.bootstrap.IModalService) {}

  open(entity: { nome: string, cpf: string }) {
    return this.$uibModal.open({
      template: require('html-loader!./configuracoes.portariamodal.html'),
      controller: 'ConfiguracoesPortariaModalController',
      controllerAs: 'cnfgrcs_prtr_mdl_cntrllr',
      backdrop: 'static',
      resolve: {
        entity: () => entity
      }
    });
  }
}
