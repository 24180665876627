import angular from 'angular';
import moment from 'moment';
import { MeurhSolicitacoesalteracoesenderecosService } from '../solicitacoesalteracoesenderecos.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { ISolicitacaoAlteracaoEndereco } from '../models/solicitacaoalteracaoendereco.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhSolicitacoesalteracoesenderecosIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'NewToaster',
        'MeurhSolicitacoesalteracoesenderecosService',
        'ListagemService',
        '$location'
    ];

    public busy: boolean = false;
    public filters: any;
    public tipo: string;
    public tipoInicial: string;
    public dataInicial: string | null = null;
    public dataFinal: string | null = null;
    public dataAtual: moment.Moment;
    public constructors: any;
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_alteracaodadoscadastrais'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public options: object = {
        'situacao': 'Situação',
        'estabelecimento': 'Estabelecimento'
    };
    public maxoccurs: object = {};
    public selects: object = {
        'situacao': {
            '0': 'Enviada',
            '1': 'Efetuada',
            '2': 'Cancelada',
            '4': 'Recusada'
        }
    };
    public entities: Array<Partial<ISolicitacaoAlteracaoEndereco>>;
    public fields: any = [
        {
            value: 'trabalhador.nome',
            label: 'Nome',
            type: 'string',
            style: 'default',
            copy: '',
        }, {
            value: 'trabalhador.codigo',
            label: 'Código',
            type: 'string',
            style: 'title',
            copy: '',
        }, {
            value: 'trabalhador.cpf',
            label: 'CPF',
            type: 'string',
            style: 'default',
            copy: '',
        }, {
            value: 'situacao',
            label: 'situacao',
            type: 'options',
            style: 'label',
            copy: '',
            options: {
                'Aberta': 'entity.situacao === "0"',
                'Efetuada': 'entity.situacao === "1"',
                'Cancelada': 'entity.situacao === "2"',
                'Excluída': 'entity.situacao === "3"',
                'Recusada': 'entity.situacao === "4"',
            },
            label_class: '{ "label-warning": entity.situacao === 0, "label-success": entity.situacao === 1, "label-danger": entity.situacao === 2 }',
        },
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            temPermissao: (arg: string) => boolean,
            session: any,
            nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public NewToaster: any,
        public entityService: MeurhSolicitacoesalteracoesenderecosService,
        public ListagemService: ListagemService,
        public $location: angular.ILocationService,
    ) {
        this.dataAtual = moment();
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.tipo = $stateParams['tipo'];
        this.tipoInicial = $stateParams['tipo'];
        this.filters = entityService.filters = {};
        this.montaListagemVisibilidade();
        this.constructors = entityService.constructors = [];
        this.entities = entityService.reload();

        if (!$rootScope.temPermissao('visualizar_alteracaodadoscadastrais')) {
            $state.go('dp', $stateParams);
        }

        if ($stateParams['tipo'] !== null && $stateParams['tipo'] !== '') {
            entityService.constructors['trabalhador_tipo'] = $stateParams['tipo'];
        }

        for (var i in $stateParams) {
            if (['situacao', 'estabelecimento', ].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                entityService.filters[i] = $stateParams[i];
            } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
                entityService.constructors[i] = $stateParams[i];
            }
        }

        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';

        $scope.$on('meurh_solicitacoesalteracoesenderecos_deleted', (event: angular.IAngularEvent) => {
            if (this.constructors.hasOwnProperty('situacao') && (this.constructors.situacao === '' || this.constructors.situacao === -1)) {
                delete this.constructors.situacao;
            }
            delete this.entityService.constructors.trabalhador_tipo;
            this.entities = this.entityService.reload();
        });

        $scope.$on('meurh_solicitacoesalteracoesenderecos_submitted', (event: angular.IAngularEvent, args: any) => {
            delete this.entityService.constructors.trabalhador_tipo;
            if (!args.autosave && args.entity.situacao !== -1) {
                if (this.filters.hasOwnProperty('situacao') && this.filters.situacao === '') {
                    delete this.filters.situacao;
                    delete this.constructors.situacao;
                }
                this.entities = this.entityService.reload();
            }
        });

        $scope.$on('meurh_solicitacoesalteracoesenderecos_cancelada', (event: angular.IAngularEvent, args: any) => {
            delete this.entityService.constructors.trabalhador_tipo;
            this.entities = this.entityService.reload();
        });

        $scope.$on('$destroy', () => {
            if (entityService.loading_deferred) {
                entityService.loading_deferred.resolve();
            }
        });

    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
    }

    filtraTipo(): void {
        if (this.entityService.constructors.hasOwnProperty('trabalhador_tipo') && (this.tipo === '' || this.tipo === '-1')) {
            delete this.entityService.constructors.trabalhador_tipo;
            this.entities = this.entityService.search();
        } else {
            this.entityService.constructors.trabalhador_tipo = this.tipo;
            this.entities = this.entityService.search();
        }
    }

    search(): Array<Partial<ISolicitacaoAlteracaoEndereco>> {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }
        let filter = {search: this.entityService.filter, filters: angular.copy(this.entityService.filters)};
        delete this.entityService.constructors.trabalhador_tipo;

        return this.entityService.search(filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    finished(): boolean {
        return this.entityService.loadParams.finished;
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    filterReload(): void {
        if (this.filters.hasOwnProperty('situacao') && this.filters.situacao === '') {
            delete this.filters.situacao;
            delete this.constructors.situacao;
        }

        let filtroDataInvalido = false;
        if (this.dataInicial !== null && this.dataFinal !== null && this.dataInicial !== '' && this.dataFinal !== '' && this.dataInicial > this.dataFinal) {

            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial não pode ser posterior a data final'
            });
            filtroDataInvalido = true;
        }
        if (!filtroDataInvalido) {
            let created_at: Array<{condition: string, value: string}> = [];
            if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
                this.dataInicial = null;
                this.constructors.datainicial = null;
            }
            if (this.dataInicial !== null) {
            created_at.push(
                {
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
                }
            );
            delete this.constructors.datainicial;
            }
            if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
                this.dataFinal = null;
                this.constructors.datafinal = null;
            }
            if (this.dataFinal !== null) {
            created_at.push(
                {
                condition: 'lte',
                value: this.dataFinal + ' 23:59:59'
                }
            );
            delete this.constructors.datafinal;
            }
            this.constructors.created_at = created_at;
            this.search();
        }
    }
}
