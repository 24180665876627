import angular from 'angular';

export class MeurhSolicitacoesalteracoesenderecosShowFormController implements angular.IController {

  static $inject = ['$scope', '$rootScope'];

  public form: angular.IFormController;

  constructor(
    public $scope: angular.IScope,
    public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
  ) {
    $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
      if (newValue !== oldValue && this.form) {
        this.form.$setDirty();
      }
    }, true);
  }
}
