import angular from 'angular';
import { MeurhMudancaHorarioFormController } from './mudancahorario.form.controller';

export class MeurhMudancaHorarioFormComponent implements angular.IComponentOptions {
	static selector = 'meurhMudancahorarioFormComponent';
	static controller = MeurhMudancaHorarioFormController;
	static template = require('!!html-loader!./mudancahorario.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<?',
		busy: '=?',
		action: '<?'
	};
}
