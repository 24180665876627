import angular from 'angular';
import { IFolhaPonto } from '../models/folhaponto.model';
import { IApuracaoPonto } from '../models/apuracaoponto.model';
import { LoadParams } from '../../../../shared/models/loadparams.model';

export class FolhasdePontoService {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

	public entity: IFolhaPonto;
	public entities: Array<IFolhaPonto> = [];
	public loaded = false;
	public constructors: any = {};
	public after: any = {};
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $q: angular.IQService,
	) {
		this.loadParams = new LoadParams();
	}

	reload() {
		if (this.loading_deferred) {
			this.loading_deferred.resolve();
		}

		this.loadParams.finished = false;
		this.loadParams.to_load = 1;
		this.after = {};
		this.loaded = false;
		this.entities.length = 0;
		this.loadParams.busy = false;

		return this.load();
	}

    search(filter: any = undefined)  {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

	_load(constructors: any, offset: any, filter: any) {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('ponto_apuracoesfolhas_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	load() {
		if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
			this.loadParams.busy = true;

			this._load(this.constructors, this.after, this.filter)
				.then((data: any): void => {
					if (data.length > 0) {
						for (var i = 0; i < data.length; i++) {
							this.entities.push(data[i]);
						}
					}

					this.loadParams.finished = true;
					this.$rootScope.$broadcast('ponto_apuracoesfolhas_index_list_finished', this.entities);


					this.loaded = true;
					this.loadParams.to_load--;

                    this.$rootScope.$broadcast('ponto_apuracoesfolhas_index_loaded', this.entities);
				})
				.catch((error: any): void => {
					if (error.xhrStatus !== 'abort') {
						this.loadParams.finished = true;
					}
				})
				.finally((): void => {
					if (this.loaded || this.loadParams.finished) {
						this.loadParams.busy = false;
					}

					this.load();
				});
		}

		return this.entities;
	}

	loadMore() {
		this.loadParams.to_load = 3;
		this.load();
	}

	gerarFolhas(entity: IApuracaoPonto) {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('ponto_apuracoesprocessamento_iniciaprocessamentofolhas', {
					'apuracaoponto': entity.apuracaoponto
				}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('apuracoesprocessamento_iniciaprocessamentofolhas_iniciado', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('apuracoesprocessamento_iniciaprocessamentofolhas_erro', response);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

	gerarFolhaIndividual(entity: IFolhaPonto) {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('ponto_apuracoesfolhas_gerar_folha', {
					'apuracaoponto': entity.apuracaoponto,
					'trabalhador': entity.trabalhador.trabalhador
				}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_gerar_folha_iniciado', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_gerar_folha_erro', response);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

	regerarFolhaIndividual(entity: IFolhaPonto) {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('ponto_apuracoesfolhas_regerar', {
					'id': entity.apuracaofolha
				}, true),
				data: angular.copy(entity),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_regerar_folha_iniciado', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_regerar_folha_erro', response);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

	visualizarPdf(entity: IFolhaPonto, salvar: boolean = false) {
		this.loading_deferred = this.$q.defer();
		entity['$$__submitting'] = true;

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'get',
				url: this.nsjRouting.generate('ponto_apuracoesfolhas_get_pdf', { 'id': entity.apuracaofolha, 'salvar': salvar }, true),
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_get_pdf_iniciado', response, salvar);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_get_pdf_erro', response, salvar);
				reject(response);
			}).finally(() => {
				entity['$$__submitting'] = false;
			});
		});
	}

	download(entities: Array<IFolhaPonto>) {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('ponto_apuracoesfolhas_downloadfolhaslote', { }, true),
				data: angular.copy(entities),
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_downloadfolhaslote_sucesso', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_downloadfolhaslote_erro', response);
				reject(response);
			});
		});
	}

    downloadUnificado(entities: Array<IFolhaPonto>) {
		this.loading_deferred = this.$q.defer();
		return this.$q((resolve, reject) => {
			this.$http({
				method: 'POST',
				url: this.nsjRouting.generate('ponto_apuracoesfolhas_downloadfolhasunificado', {}, true),
				data: angular.copy(entities),
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_downloadfolhasunificado_sucesso', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_downloadfolhasunificado_erro', response);
				reject(response);
			});
		});
	}

	solicitacaoDeAssinatura(entities: Array<{apuracaofolha: string}>) {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'post',
				url: this.nsjRouting.generate('ponto_apuracoesfolhas_solicitar_assinatura', entities, true),
				data: angular.copy(entities),
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_solicitar_assinatura_sucesso', response);
				resolve(response.data);
			})
			.catch((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_solicitar_assinatura_erro', response);
				reject(response);
				console.log(response);
			});
		});
	}
	get(identifier: string) {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http.get(
				this.nsjRouting.generate('ponto_apuracoesfolhas_get', { 'id': identifier }, true),
				{ timeout: this.loading_deferred.promise }
			)
			.then((response: any) => {
				this.$rootScope.$broadcast('ponto_apuracoesfolhas_loaded', response.data);
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}
}
