import angular from 'angular';

export const SimulacaoFeriasListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('simulacao_ferias', {
                url: '/simulacoes/simulacaoferias',
                template: require('html-loader!./index/simulacaoferias.index.html'),
                controller: 'SimulacaoFeriasIndexController',
                controllerAs: '$ctrl'
            });
    }
];
