import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { IDependente } from '../../../models/dependente.model';

export class MeurhAdmissoesDependentesFormmodalController implements ng.IController {

    static $inject = ['$rootScope'];

    public dependente: IDependente;
    public action: string;
    public uFArray = [
        'AC', 'AL', 'AM', 'AP', 'BA', 'CE',
        'DF', 'ES', 'GO', 'MA', 'MG', 'MS',
        'MT', 'PA', 'PB', 'PE', 'PI', 'PR',
        'RJ', 'RN', 'RO', 'RR', 'RS', 'SC',
        'SE', 'SP', 'TO'
    ];
    public form: angular.IFormController;
    public constructor_: any = {};
    public tiporecebimentoHabilitado: boolean = false;
    public objadmissao: any;
    public anoMinimo: moment.Moment;

    constructor(
        public $rootScope: angular.IRootScopeService
    ) { }

    public $onInit(): void {
        this.bloqueiaAnoMinimo();
        this.constructor_ = this.verificacaoBanco();

        if (this.dependente.matricula === undefined) {
            this.dependente.matricula = ' ';
        }
        if (!!this.dependente.nome) {
            this.dependente.parentesco = this.dependente.parentesco ? this.dependente.parentesco.toString() : this.dependente.parentesco;
            this.dependente.sexo = this.dependente.sexo ? this.dependente.sexo.toString() : this.dependente.sexo;
            this.dependente.tiporecebimento = this.dependente.tiporecebimento ? this.dependente.tiporecebimento.toString() : this.dependente.tiporecebimento;
            this.calculaIdade();
        } else if (this.objadmissao) {
            this.dependente.datainclusao = this.objadmissao.dataadmissao;
        }
    }

    public calculaIdade(): void {
        if (!!this.dependente.datanascimento) {
            this.dependente.idade = moment().diff(this.dependente.datanascimento, 'years');
        }
    }

    public vencimentoEscolarObrigatorio(): boolean {
        return (
            (this.dependente.idade !== undefined && this.dependente.idade !== null) &&
            (this.dependente.idade >= 7 && this.dependente.idade <= 14) &&
            this.dependente.salariofamilia &&
            !this.dependente.incapacidadefisicamental
        );
    }

    public vencimentoVacinacaoObrigatorio(): boolean {
        return (
            (this.dependente.idade !== undefined && this.dependente.idade !== null) &&
            (this.dependente.idade < 7 || this.dependente.idade > 14) &&
            this.dependente.salariofamilia &&
            !this.dependente.incapacidadefisicamental
        );
    }

    public preencheCampo(): void {
        if (this.dependente.tiporecebimento === '128') { // cpf
            this.dependente.chavepix = this.dependente.cpf;
        } else {
            this.dependente.chavepix = '';
        }
    }

    public preenchePix(): void {
        if (this.dependente.tiporecebimento === '128') { // cpf
            this.dependente.chavepix = this.dependente.cpf;
        }
    }

    public verificaBeneficiariopensaoalimenticia(): void {
        if (this.dependente.pensaoalimenticia === true) {
            this.tiporecebimentoHabilitado = true;
        } else {
            this.dependente.tiporecebimento = '';
            this.tiporecebimentoHabilitado = false;

        }
    }

    public verificacaoBanco(): { banco: string | null } {
        if (this.dependente.banco) {
            if (this.dependente.banco.banco) {
                return { 'banco': this.dependente.banco.banco };
            }
        }
        return { 'banco': null };
    }

    public alteraAgenciaPorBanco(): void {
        if (this.dependente.banco) {
            this.$rootScope.$broadcast('agencia_refresh', { banco: this.dependente.banco.banco });
            this.dependente.agencia = null;
        }
    }

    public changeBanco(): void {
        this.alteraAgenciaPorBanco();
        this.constructor_ = this.verificacaoBanco();
    }

    public limpaIncapacidadeFisicaMental(): void {
        if (this.dependente.parentesco?.toString() !== '3') {
            this.dependente.incapacidadefisicamental = false;
        }
    }

    public limpaCamposSalFamiliaComIncapacidadeFisicaMental(): void {
        if (!this.dependente.incapacidadefisicamental) {
            this.dependente.atestadoparanaofrequentarescola = false;
        }
    }

    // inicio - tratamentos
    private bloqueiaAnoMinimo(): void {
        this.anoMinimo = moment().subtract(120, 'years');
    }
    // fim - tratamentos
}
