import angular from 'angular';
import { MeurhPromocoesDocumentosanexosFormController } from './documentosanexos.form.controller';

export class MeurhPromocoesDocumentosanexosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhPromocoesDocumentosanexosFormComponent';
	static controller = MeurhPromocoesDocumentosanexosFormController;
	static template = require('!!html-loader!./documentosanexos.form.html');
	static transclude = true;
	static bindings = {
		busy: '=',
		busyTipoDocumentos: '=',
		entityanexo: '=',
		file: '=?',
		action: '=?',
		mostrarAlerta: '=',
		tiposdocumentosrequeridos: '=',
		editanexo: '=',
		alterouanexo: '=',
		fileSelecionado: '=?',
		validateResult: '=?',
	};
}
