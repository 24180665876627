import angular from 'angular';
import { IBancoHorasColaboradores } from '../models/bancohorascolaboradores.model';
import { LoadParams } from '../../../../shared/models/loadparams.model';

export class ExportacaoArquivoAejService {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

	public entity:  IBancoHorasColaboradores;
	public entities: Array<IBancoHorasColaboradores> = [];
	public loaded: boolean = false;
	public constructors: any = {};
	public after: any = {};
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter: any = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $q: angular.IQService,
	) {
		this.loadParams = new LoadParams();
	}

	exportar(rota: any, constructors: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();
		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'get',
				url: this.nsjRouting.generate(rota, constructors, true),
				responseType: 'blob',
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				var fileName = '';
				let file = new Blob([response.data]);
				let fileUrl = URL.createObjectURL(file);
				let a = document.createElement('a');
				fileName = 'AEJ_' + constructors.datainicial + '_' + constructors.datafinal + '_REP_P';
                fileName += '.zip';

				document.body.appendChild(a);
				a.style.display = 'none';
				a.href = fileUrl;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(fileUrl);
				this.$rootScope.$broadcast('arquivosaej_index_sucesso', response);
			})
			.catch((error) => {
				this.$rootScope.$broadcast('arquivosaej_index_error', error);
				console.log(error);
			});
		});
	}

}
