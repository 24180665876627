import * as angular from 'angular';

export const MeurhCreditosdescontosListRouting = ['$stateProvider', function ($stateProvider: angular.ui.IStateProvider) {

    $stateProvider
        .state('creditosdescontos', {
            url: '/registrarcompleto/{passoumativo}/?trabalhador',
            template: require('html-loader!./wizard/creditosdescontos.wizard.html'),
            controller: 'Meurh\CreditosdescontosWizardController',
            controllerAs: 'mrh_crdtsdscnts_wizard_cntrllr',
            reloadOnSearch: false
        })
        .state('creditosdescontos_show', {
            url: '/registrarcompleto/show/?solicitacao?path',
            template: require('html-loader!./show/creditosdescontos.show.html'),
            controller: 'Meurh\CreditosdescontosShowController',
            controllerAs: 'mrh_crdtsdscnts_shw_cntrllr',
        })
        .state('creditosdescontos_edit', {
            url: '/registrarcompleto/edit/?solicitacao',
            template: require('html-loader!./edit/creditosdescontos.edit.html'),
            controller: 'Meurh\CreditosdescontosEditController',
            controllerAs: 'mrh_crdtsdscnts_edt_cntrllr',
        })
        .state('creditosdescontos_index', {
            url: '/registrarcompleto',
            template: require('html-loader!./index/creditosdescontos.index.html'),
            controller: 'Meurh\CreditosdescontosListController',
            controllerAs: 'mrh_crdtsdscts_lst_cntrllr',
            reloadOnSearch: false
        })
        .state('creditosdescontos_showbuiltin', {
            parent: 'creditosdescontos_index',
            url: '/showbuiltin/:solicitacao',
            template: require('html-loader!./showbuiltin/creditosdescontos.showbuiltin.html'),
            controller: 'Meurh\CreditosdescontosShowbuiltinController',
            controllerAs: 'mrh_crdtsdscnts_shwbltn_cntrllr',
        })
        .state('creditosdescontos_editbuiltin', {
            parent: 'creditosdescontos_index',
            url: '/editbuiltin/:solicitacao',
            template: require('html-loader!./editbuiltin/creditosdescontos.editbuiltin.html'),
            controller: 'Meurh\CreditosdescontosEditbuiltinController',
            controllerAs: 'mrh_crdtsdscnts_edtbltn_cntrllr',
        });

}];
