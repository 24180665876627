import angular from 'angular';
import { LoadParams } from '../../shared/models/loadparams.model';
import { ITrabalhador } from './models/trabalhador.model';

export class TrabalhadoresService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q', '$filter'];

    public entities: Array<ITrabalhador> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams;
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService,
        public $filter: any,
    ) {
        this.loadParams = new LoadParams();
    }

    reload(to_load: any = 3): Array<ITrabalhador> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.filters = {};
        this.loadParams.finished = false;
        this.loadParams.to_load = to_load;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter?: any): Array<ITrabalhador> | angular.IHttpPromise<any> {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;

            Object.keys(this.filters).forEach((k): void => {
                delete this.filters[k];
            });

            for (var fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    var filterSplit = fil.split('.');

                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        /* Solução temporária para contornar o problema do mda-ui-select*/
        if (constructors.status) {
            if (constructors.status.toString().length === 1) {
                constructors.status = '0' + constructors.status;
            }
        }

        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('persona_trabalhadores_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
                .then((response: any) => {
                    response.data.forEach(trabalhador => {
                        trabalhador['tipoTrabalhadorString'] = this.$filter('tipoTrabalhador')(trabalhador.tipo);
                    });
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });

    }

    load(): Array<ITrabalhador> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any): void => {
                    if (data.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }

                        this.after['nome'] = this.entities[this.entities.length - 1]['nome'];
                        this.after['trabalhador'] = this.entities[this.entities.length - 1]['trabalhador'];
                    }
                    if (data.length < 50) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('persona_trabalhadores_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }

                    this.loaded = true;
                    this.loadParams.to_load--;

                    if (this.loadParams.to_load === 0 || data.length <= 50) {
                        this.$rootScope.$broadcast('persona_trabalhadores_list_loaded', this.entities);
                    }
                })
                .catch((error: any): void => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally((): void => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }
                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: string): ITrabalhador | null {
        for (var i in this.entities) {
            if (this.entities[i]['trabalhador'] === identifier) {
                return this.entities[i];
            }
        }

        return null;
    }

    _save(entity: ITrabalhador, autosave: boolean): angular.IHttpPromise<any> {
        var method, url;

        if (entity['trabalhador']) {
            method = 'PUT';
            url = this.nsjRouting.generate('persona_trabalhadores_put', { 'id': entity['trabalhador'] }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('persona_trabalhadores_create', angular.extend(this.constructors), true);
        }

        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }

        var data = angular.copy(entity);

        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }

        this.loading_deferred = this.$q.defer();

        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        })
            .finally((): void => {
                if (!autosave) {
                    entity['$$__submitting'] = false;
                }
            });
    }

    save(entity: ITrabalhador, autosave: boolean): void {
        this._save(entity, autosave)
            .then((response: any): void => {
                if (response.data.trabalhador) {
                    entity['trabalhador'] = response.data.trabalhador;
                }

                entity['$$_saved'] = true;

                this.$rootScope.$broadcast('persona_trabalhadores_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any): void => {
                this.$rootScope.$broadcast('persona_trabalhadores_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: string): any {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http
                .get(this.nsjRouting.generate(
                    'persona_trabalhadores_get', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any): void => {
                    this.$rootScope.$broadcast('persona_trabalhadores_loaded', response.data);
                    this.loadParams.busy = false;

                    resolve(response.data);
                })
                .catch((response: any): void => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }

    getGestoresTrabalhador(identifier: string) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
            this.$http
                .get(this.nsjRouting.generate('persona_trabalhadores_get_gestores', { 'id': identifier }, true),
                    { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }

    loadNoPagination(params?: {
        status: string,
        empresa: string,
        estabelecimento: string
    }): any {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http
                .get(this.nsjRouting.generate(
                    'persona_trabalhadores_indexsempaginacao', { ...params }, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any): void => {
                    this.$rootScope.$broadcast('persona_trabalhadores_sempaginacao_loaded', response.data);
                    this.loadParams.busy = false;

                    resolve(response.data);
                })
                .catch((response: any): void => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }

    getEstabilidadeTrabalhador(identifier: string) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {
          this.$http
            .get(this.nsjRouting.generate('persona_trabalhadores_get_estabilidadetrabalhador', { 'id': identifier }, true),
                { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                resolve(response.data);
            })
            .catch((response: any) => {
                reject(response);
            });
        });
    }


    public ajustarRetorno(params: {
        estabelecimento: string,
        trabalhador: string,
        dataretorno: string,
        afastamentotrabalhador: string
    }): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http
                .post(this.nsjRouting.generate(
                    'meurh_solicitacoesafastamentosretorno_create', {}, true),
                    params,
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any): void => {
                    resolve(response.data);
                })
                .catch((response: any): void => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }

    public getRetornoAfastamento(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http
                .get(this.nsjRouting.generate(
                    'meurh_solicitacoesafastamentosretorno_create', { id: identifier }, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any): void => {
                    resolve(response);
                })
                .catch((response: any): void => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }

    public getFeriadosTrabalhadores(params: {
        datainicio: string,
        datafim: string,
        empresa: string,
		trabalhador_estabelecimento?: string
    }) {
        this.loading_deferred = this.$q.defer();
        this.loadParams.busy = true;

        const urlParams = {
			estabelecimento: '',
			...params
		};

        return this.$q((resolve, reject) => {
            this.$http
                .get(this.nsjRouting.generate(
                    'persona_trabalhadores_feriados_trabalhadores', urlParams, true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response) => {
                    this.$rootScope.$broadcast('persona_trabalhadores_feriados_loaded', response.data);
                    resolve(response);
                })
                .catch((response) => {
                    this.loadParams.busy = false;
                    reject(response);
                });
        });
    }

    getGestoresElegiveis(paramsUrl: {estabelecimento?: string, empresa?: string, filter?: string, trabalhador?: string, tipo: string}) {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: any, reject: any) => {

            this.$http.get(this.nsjRouting.generate('persona_trabalhadores_gestores_elegiveis', paramsUrl, true), { timeout: this.loading_deferred.promise })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((response: any) => {
                    reject(response);
                });
        });
    }
}
