import angular = require('angular');
import moment = require('moment');
import { IDadosInit } from '../models/dadosInit.model';
import { IMensagensPassos } from '../models/mensagensPassos.model';

export class MeurhCreditosdescontosPassoumController {

    static $inject = ['$scope', '$stateParams', '$location', 'TrabalhadoresService', '$rootScope'];

    public busy: boolean = false;

    public entities: any = []; // tipagem: array de obj trabalhador

    public passo: number = 1;

    public trabalhador: string = this.$stateParams['trabalhador'];
    public carregouTudo: boolean = false;

    public colaboradorSelecionado: any = {}; // tipagem: obj trabalhador
    public fields = [
        {
            value: 'nome',
            label: 'Colaborador',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnScope: {
                selecionaColaborador: (entity: any) => {
                    this.selecionaColaboradorFunc(entity);
                },
            },
            customCollumnHtml: () => {
                let someHtmlVar = `
                <input type="radio" ng-click="customCollumnScope.selecionaColaborador(tableRowEntity)"  name="selecionar" ng-model="tableRowEntity.selecionar" value="{{tableRowEntity.selecionar}}" />
                <span class="margin-left-10" ng-bind="tableRowEntity.nome"></span>
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'dataadmissao',
            label: 'Data de admissão',
            type: 'date',
            style: 'title',
            copy: '',
        },
        {
            value: 'cargo',
            label: 'Cargo',
            type: 'string',
            style: 'title',
            copy: '',
        },
    ];
    public filterCargos: any = undefined; // tipagem: obj cargos
    public constructors: any = this.entityService.constructors = [];

    public entity: any;
    public entityDadosCriacao: IDadosInit;
    public mensagensPassos: IMensagensPassos;

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public entityService: any,
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
    ) {

        $scope.$on('persona_trabalhadores_list_finished', (event: any, args: any) => {
            this.entities = this.alteraEntities(this.entities);
            this.busy = false;
        });

        $scope.$on('persona_trabalhadores_list_loaded', (event: any, args: any) => {
            this.entities = this.alteraEntities(this.entities);
            this.busy = false;
        });

    }

    $onInit() {
        if (this.trabalhador === undefined) {
            this.busy = true;
            this.entityService.constructors.status = '00';
            this.entities = this.entityService.reload();
            this.busy = false;
        }
    }

    isBusy() {
        return this.busy;
    }

    search() {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }
        var filter = { search: this.entityService.filter, filters: angular.copy(this.entityService.filters) };
        return this.entityService.search(filter);
    }

    filterReload() {
        this.busy = true;
        if (this.constructors.hasOwnProperty('nivelcargo_cargo')) {
            delete this.constructors.nivelcargo_cargo;
        }

        if (this.filterCargos !== undefined) {
            this.constructors.nivelcargo_cargo = this.filterCargos.cargo;
        }

        this.entityService.constructors = this.constructors;
        this.entities = this.entityService.reload();
        this.busy = false;
    }

    loadMore() {
        this.entityService.loadMore();
    }

    alteraEntities(entities: any): void {
        entities.forEach((item: any) => {
            item.selecionar = true;
        });
        return entities;
    }

    selecionaColaboradorFunc(colaborador: any) {
        this.colaboradorSelecionado = {};
        this.colaboradorSelecionado = colaborador;
    }

    refresh() {
        this.filterCargos = undefined;
        this.entityService.filter = null;
        this.filterReload();
    }

    verificaSelecao() {
      if (this.valorExiste(this.colaboradorSelecionado) && this.valorExiste(this.colaboradorSelecionado.trabalhador)) {
        return false;
      } else {
        return true;
      }
    }

    calculaPasso() {
        this.passo = this.passo + 1;
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

}
