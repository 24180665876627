
import * as angular from 'angular';
import { MeurhAfastamentosService } from './afastamentos.service';
import { MeurhAfastamentosListRouting } from './afastamentos.routes';
import { MeurhAfastamentosIndexController } from './index/afastamentos.index.controller';
import { MeurhAfastamentosNewController } from './new/afastamentos.new.controller';
import { MeurhAfastamentosFormController } from './form/afastamentos.form.controller';
import { MeurhAfastamentosFormComponent } from './form/afastamentos.form.component';
import { MeurhAfastamentosShowController } from './show/afastamentos.show.controller';
import { MeurhAfastamentosEditController } from './edit/afastamentos.edit.controller';

export const MeurhAfastamentosModule = angular.module('MeurhAfastamentosModule', ['ui.router.state', ])
    .controller('MeurhAfastamentosIndexController', MeurhAfastamentosIndexController)
    .controller('MeurhAfastamentosNewController', MeurhAfastamentosNewController)
    .controller('MeurhAfastamentosFormController', MeurhAfastamentosFormController)
    .controller('MeurhAfastamentosShowController', MeurhAfastamentosShowController)
    .controller('MeurhAfastamentosEditController', MeurhAfastamentosEditController)
    .component('meurhAfastamentosFormComponent', MeurhAfastamentosFormComponent)
    .service('MeurhAfastamentosService', MeurhAfastamentosService)
    .config(MeurhAfastamentosListRouting)
    .name;
