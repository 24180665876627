import angular from 'angular';
import moment from 'moment';
import { IVtadicional } from '../models/vtadicional.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { MeurhSolicitacoesvtsadicionaisService } from '../solicitacoesvtsadicionais.service';
import { ListagemService } from '../../../../shared/services/listagem.service';

export class MeurhSolicitacoesvtsadicionaisIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'MeurhSolicitacoesvtsadicionaisService',
        '$location',
        'NewToaster',
        'nsjRouting',
        'ListagemService'
    ];

    public entities: Array<IVtadicional>;
    public busy: boolean = false;
    public tipo: number | string;
    public tipoInicial: string;
    public filters: any;
    public constructors: any = {};
    public situacao: string = '';
    public filter: any = {};
    public maxoccurs = {};
    public fields = [];
    public dataInicial = null;
    public dataFinal = null;
    public dataAtual = moment();

    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_vtadicional'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    public options = { 'situacao': 'Situação', 'estabelecimento': 'Estabelecimento', };
    public selects = { 'situacao': { '0': 'Enviada', '1': 'Efetuada', '2': 'Cancelada', '4': 'Recusada', }, };

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public entityService: MeurhSolicitacoesvtsadicionaisService,
        public $location: angular.ILocationService,
        public NewToaster: any,
        public nsjRouting: any,
        public ListagemService: ListagemService
    ) {
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.tipo = $stateParams['tipo'];
        this.tipoInicial = $stateParams['tipo'];
        this.filters = entityService.filters = {};
        this.montaListagemVisibilidade();
        this.constructors = entityService.constructors = [];
        this.filter.search = $stateParams['q'];
        this.filterReload();

        if (!$rootScope.temPermissao('visualizar_vtadicional')) {
            $state.go('dp', $stateParams);
        }

        for (var i in $stateParams) {
            if (['situacao', 'estabelecimento', ].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                entityService.filters[i] = $stateParams[i];
            } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
                entityService.constructors[i] = $stateParams[i];
            }
        }

        $scope.$on('meurh_solicitacoesvtsadicionais_deleted', (event: angular.IAngularEvent) => {
            this.reload();
        });

        $scope.$on('$destroy', () => {
            if (entityService.loading_deferred) {
                entityService.loading_deferred.resolve();
            }
        });

        $rootScope.$on('meurh_solicitacoesvtsadicionais_submitted', (event: angular.IAngularEvent, args: any) => {
            this.reload();
        });
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
    }

    search(): Array<IVtadicional> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }
        return this.entityService.search(this.filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    finished(): boolean {
        return this.entityService.loadParams.finished;
    }

    filtraTipo(): void {
        if (this.entityService.constructors.hasOwnProperty('trabalhador_tipo') && (this.tipo === '' || this.tipo === '-1' || this.tipo === -1)) {
            delete this.entityService.constructors.trabalhador_tipo;
            this.entities = this.entityService.search();
        } else {
            this.entityService.constructors.trabalhador_tipo = this.tipo;
            this.entities = this.entityService.search();
        }
    }

    reload(): void {
        this.entities = this.entityService.reload();
    }

    filterReload(): void {
        this.constructors.situacao = this.situacao;
        if (this.constructors.situacao === '') {
            delete this.constructors.situacao;
        }

        let filtroDataInvalido = false;
        if (this.dataInicial !== null && this.dataFinal !== null && this.dataInicial !== '' && this.dataFinal !== '' && this.dataInicial > this.dataFinal) {

            this.NewToaster.pop({
                type: 'error',
                title: 'A data inicial não pode ser posterior a data final'
            });
            filtroDataInvalido = true;
        }
        if (!filtroDataInvalido) {
            let created_at: Array<{condition: string, value: string}> = [];
            if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
            this.dataInicial = null;
            this.constructors.datainicial = null;
            }
            if (this.dataInicial !== null) {
            created_at.push(
                {
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
                }
            );
            delete this.constructors.datainicial;
            }
            if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
            this.dataFinal = null;
            this.constructors.datafinal = null;
            }
            if (this.dataFinal !== null) {
            created_at.push(
                {
                condition: 'lte',
                value: this.dataFinal + ' 23:59:00'
                }
            );
            delete this.constructors.datafinal;
            }
            this.constructors.created_at = created_at;

            this.entityService.constructors = this.constructors;
        this.reload();
        }
    }

    generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy() {
        return this.entityService.loadParams.busy;
    }
}
