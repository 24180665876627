
import * as angular from 'angular';

import { MeurhAdmissaoestagiarioListRouting } from './admissaoestagiario.routes';

import { MeurhAdmissaoestagiarioService } from './admissaoestagiario.service';
import { MeurhAdmissaoestagiarioInstituicoesService } from './admissaoestagiario.instituicoes.service';
import { MeurhAdmissaoestagiarioModalService } from './modal/admissaoestagiario.modal.service';

import { MeurhAdmissaoestagiarioNewController } from './new/admissaoestagiario.new.controller';
import { MeurhAdmissoesestagiarioDadosgeraisFormController } from './form/dadosgerais/dadosgerais.form.controller';
import { MeurhAdmissoesestagiarioEnderecoFormController } from './form/endereco/endereco.form.controller';
import { MeurhAdmissoesestagiarioDadoscontatoFormController } from './form/dadoscontato/dadoscontato.form.controller';
import { MeurhAdmissoesestagiarioDadospessoaisFormController } from './form/dadospessoais/dadospessoais.form.controller';
import { MeurhAdmissoesestagiarioDocumentacaoFormController } from './form/documentacao/documentacao.form.controller';
import { MeurhAdmissoesestagiarioOutrasinformacoesFormController } from './form/outrasinformacoes/outrasinformacoes.form.controller';
import { MeurhAdmissoesestagiarioObservacoesFormController } from './form/observacoes/observacoes.form.controller';
import { MeurhAdmissoesestagiarioBeneficiosFormController } from './form/beneficios/beneficios.form.controller';
import { MeurhAdmissoesestagiarioDependentesFormController } from './form/dependentes/dependentes.form.controller';
import { MeurhAdmissoesestagiarioValetransporteFormController } from './form/valetransporte/valetransporte.form.controller';
import { MeurhAdmissoesestagiarioValetransportepersonalizadoFormController } from './form/valetransportepersonalizado/valetransportepersonalizado.form.controller';
import { MeurhAdmissoesestagiarioBeneficiosFormmodalController } from './form/beneficios/formmodal/beneficios.formmodal.controller';
import { MeurhAdmissoesestagiarioDependentesFormmodalController } from './form/dependentes/formmodal/dependentes.formmodal.controller';
import { MeurhAdmissoesestagiarioValetransporteFormmodalController } from './form/valetransporte/formmodal/valetransporte.formmodal.controller';
import { MeurhAdmissoesestagiarioValetransportepersonalizadoFormmodalController } from './form/valetransportepersonalizado/formmodal/valetransportepersonalizado.formmodal.controller';
import { MeurhAdmissoesestagiarioFotoFormController } from './form/foto/foto.form.controller';
import { MeurhAdmissaoestagiarioShowController } from './show/admissaoestagiario.show.controller';
import { MeurhAdmissaoestagiarioEditController } from './edit/admissaoestagiario.edit.controller';

import { MeurhAdmissoesestagiarioDadosgeraisFormComponent } from './form/dadosgerais/dadosgerais.form.component';
import { MeurhAdmissoesestagiarioEnderecoFormComponent } from './form/endereco/endereco.form.component';
import { MeurhAdmissoesestagiarioDadoscontatoFormComponent } from './form/dadoscontato/dadoscontato.form.component';
import { MeurhAdmissoesestagiarioDadospessoaisFormComponent } from './form/dadospessoais/dadospessoais.form.component';
import { MeurhAdmissoesestagiarioDocumentacaoFormComponent } from './form/documentacao/documentacao.form.component';
import { MeurhAdmissoesestagiarioOutrasinformacoesFormComponent } from './form/outrasinformacoes/outrasinformacoes.form.component';
import { MeurhAdmissoesestagiarioObservacoesFormComponent } from './form/observacoes/observacoes.form.component';
import { MeurhAdmissoesestagiarioBeneficiosFormComponent } from './form/beneficios/beneficios.form.component';
import { MeurhAdmissoesestagiarioDependentesFormComponent } from './form/dependentes/dependentes.form.component';
import { MeurhAdmissoesestagiarioValetransporteFormComponent } from './form/valetransporte/valetransporte.form.component';
import { MeurhAdmissoesestagiarioValetransportepersonalizadoFormComponent } from './form/valetransportepersonalizado/valetransportepersonalizado.form.component';
import { MeurhAdmissoesestagiarioBeneficiosFormmodalComponent } from './form/beneficios/formmodal/beneficios.formmodal.component';
import { MeurhAdmissoesestagiarioDependentesFormmodalComponent } from './form/dependentes/formmodal/dependentes.formmodal.component';
import { MeurhAdmissoesestagiarioValetransporteFormmodalComponent } from './form/valetransporte/formmodal/valetransporte.formmodal.component';
import { MeurhAdmissoesestagiarioValetransportepersonalizadoFormmodalComponent } from './form/valetransportepersonalizado/formmodal/valetransportepersonalizado.formmodal.component';
import { MeurhAdmissoesestagiarioFotoFormComponent } from './form/foto/foto.form.component';

export const MeurhAdmissaoestagiarioModule = angular.module('MeurhAdmissaoestagiarioModule', ['ui.router.state', ])
    .controller('MeurhAdmissaoestagiarioNewController', MeurhAdmissaoestagiarioNewController)
    .controller('MeurhAdmissoesestagiarioDadosgeraisFormController', MeurhAdmissoesestagiarioDadosgeraisFormController)
    .controller('MeurhAdmissoesestagiarioEnderecoFormController', MeurhAdmissoesestagiarioEnderecoFormController)
    .controller('MeurhAdmissoesestagiarioDadoscontatoFormController', MeurhAdmissoesestagiarioDadoscontatoFormController)
    .controller('MeurhAdmissoesestagiarioDadospessoaisFormController', MeurhAdmissoesestagiarioDadospessoaisFormController)
    .controller('MeurhAdmissoesestagiarioDocumentacaoFormController', MeurhAdmissoesestagiarioDocumentacaoFormController)
    .controller('MeurhAdmissoesestagiarioOutrasinformacoesFormController', MeurhAdmissoesestagiarioOutrasinformacoesFormController)
    .controller('MeurhAdmissoesestagiarioObservacoesFormController', MeurhAdmissoesestagiarioObservacoesFormController)
    .controller('MeurhAdmissoesestagiarioBeneficiosFormController', MeurhAdmissoesestagiarioBeneficiosFormController)
    .controller('MeurhAdmissoesestagiarioDependentesFormController', MeurhAdmissoesestagiarioDependentesFormController)
    .controller('MeurhAdmissoesestagiarioValetransporteFormController', MeurhAdmissoesestagiarioValetransporteFormController)
    .controller('MeurhAdmissoesestagiarioValetransportepersonalizadoFormController', MeurhAdmissoesestagiarioValetransportepersonalizadoFormController)
    .controller('MeurhAdmissoesestagiarioBeneficiosFormmodalController', MeurhAdmissoesestagiarioBeneficiosFormmodalController)
    .controller('MeurhAdmissoesestagiarioDependentesFormmodalController', MeurhAdmissoesestagiarioDependentesFormmodalController)
    .controller('MeurhAdmissoesestagiarioValetransporteFormmodalController', MeurhAdmissoesestagiarioValetransporteFormmodalController)
    .controller('MeurhAdmissoesestagiarioValetransportepersonalizadoFormmodalController', MeurhAdmissoesestagiarioValetransportepersonalizadoFormmodalController)
    .controller('MeurhAdmissoesestagiarioFotoFormController', MeurhAdmissoesestagiarioFotoFormController)
    .controller('MeurhAdmissaoestagiarioShowController', MeurhAdmissaoestagiarioShowController)
    .controller('MeurhAdmissaoestagiarioEditController', MeurhAdmissaoestagiarioEditController)
    .component('meurhAdmissoesestagiarioEnderecoFormComponent', MeurhAdmissoesestagiarioEnderecoFormComponent)
    .component('meurhAdmissoesestagiarioDadoscontatoFormComponent', MeurhAdmissoesestagiarioDadoscontatoFormComponent)
    .component('meurhAdmissoesestagiarioDadosgeraisFormComponent', MeurhAdmissoesestagiarioDadosgeraisFormComponent)
    .component('meurhAdmissoesestagiarioDadospessoaisFormComponent', MeurhAdmissoesestagiarioDadospessoaisFormComponent)
    .component('meurhAdmissoesestagiarioDocumentacaoFormComponent', MeurhAdmissoesestagiarioDocumentacaoFormComponent)
    .component('meurhAdmissoesestagiarioOutrasinformacoesFormComponent', MeurhAdmissoesestagiarioOutrasinformacoesFormComponent)
    .component('meurhAdmissoesestagiarioObservacoesFormComponent', MeurhAdmissoesestagiarioObservacoesFormComponent)
    .component('meurhAdmissoesestagiarioBeneficiosFormComponent', MeurhAdmissoesestagiarioBeneficiosFormComponent)
    .component('meurhAdmissoesestagiarioDependentesFormComponent', MeurhAdmissoesestagiarioDependentesFormComponent)
    .component('meurhAdmissoesestagiarioValetransporteFormComponent', MeurhAdmissoesestagiarioValetransporteFormComponent)
    .component('meurhAdmissoesestagiarioValetransportepersonalizadoFormComponent', MeurhAdmissoesestagiarioValetransportepersonalizadoFormComponent)
    .component('meurhAdmissoesestagiarioBeneficiosFormmodalComponent', MeurhAdmissoesestagiarioBeneficiosFormmodalComponent)
    .component('meurhAdmissoesestagiarioDependentesFormmodalComponent', MeurhAdmissoesestagiarioDependentesFormmodalComponent)
    .component('meurhAdmissoesestagiarioValetransporteFormmodalComponent', MeurhAdmissoesestagiarioValetransporteFormmodalComponent)
    .component('meurhAdmissoesestagiarioValetransportepersonalizadoFormmodalComponent', MeurhAdmissoesestagiarioValetransportepersonalizadoFormmodalComponent)
    .component('meurhAdmissoesestagiarioFotoFormComponent', MeurhAdmissoesestagiarioFotoFormComponent)
    .service('MeurhAdmissaoestagiarioService', MeurhAdmissaoestagiarioService)
    .service('MeurhAdmissaoestagiarioInstituicoesService', MeurhAdmissaoestagiarioInstituicoesService)
    .service('MeurhAdmissaoestagiarioModalService', MeurhAdmissaoestagiarioModalService)
    .config(MeurhAdmissaoestagiarioListRouting)
    .name;
