import angular from 'angular';
import { MeurhAlteracaoFotoIndexController } from './index/alteracaofoto.index.controller';
import { MeurhAlteracaoFotoNewController } from './new/alteracaofoto.new.controller';
import { MeurhAlteracaoFotoShowController } from './show/alteracaofoto.show.controller';
import { MeurhAlteracaoFotoFormComponent } from './form/alteracaofoto.form.component';
import { MeurhAlteracaoFotoService } from './alteracaofoto.service';
import { MeurhAlteracaoFotoListRouting } from './alteracaofoto.routes';

import { MeurhVisualizarFotoModalController } from './form/modal/visualizarfoto/visualizarfoto.modal.controller';
import { MeurhVisualizarFotoModalService } from './form/modal/visualizarfoto/visualizarfoto.modal.service';

export const MeurhAlteracaoFotoModule = angular.module('AlteracaoFotoModule', ['ui.router.state', ])
    .controller('MeurhAlteracaoFotoIndexController', MeurhAlteracaoFotoIndexController)
    .controller('MeurhAlteracaoFotoNewController', MeurhAlteracaoFotoNewController)
    .controller('MeurhAlteracaoFotoShowController', MeurhAlteracaoFotoShowController)
    .controller('MeurhVisualizarFotoModalController', MeurhVisualizarFotoModalController)
    .component('meurhAlteracaoFotoFormComponent', MeurhAlteracaoFotoFormComponent)
    .service('MeurhAlteracaoFotoService', MeurhAlteracaoFotoService)
    .service('MeurhVisualizarFotoModalService', MeurhVisualizarFotoModalService)
    .config(MeurhAlteracaoFotoListRouting)
    .name;
