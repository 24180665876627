import angular = require('angular');
import moment = require('moment');

export class MeurhAdmissoesestagiarioValetransporteFormmodalController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'MeurhAdmissaoestagiarioModalService'];

    public valetransporte: any;
    public action: string;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
    ) {

    }

    $onInit(): void {
        if (!this.valorExiste(this.valetransporte.quantidade)) {
            this.valetransporte.quantidade = 2;
        }
        this.getTarifaconcess();
     }

    getQuantidade(event: angular.IAngularEvent & { detail: any }): void {
        this.valetransporte.quantidade = event.detail.value;
        this.valetransporte.erroQuantidade = event.detail.error;
        if (this.valorExiste(this.valetransporte.tarifaconcessionariavt) && (!this.valorExiste(this.valetransporte.erroQuantidade) || this.valetransporte.erroQuantidade === false)) {
            this.calculaTotal();
        }
    }

    getTarifaconcess() {
        if (this.valorExiste(this.valetransporte.tarifaconcessionariavt)) {
            this.valetransporte['valor'] = this.valetransporte.tarifaconcessionariavt.valor;
        }
        if (this.valorExiste(this.valetransporte.quantidade) && this.valetransporte.quantidade > 0) {
            this.calculaTotal();
        }
    }

    calculaTotal() {
        this.valetransporte.totaldiario = this.valetransporte.valor * this.valetransporte.quantidade;
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
