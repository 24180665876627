
import angular from 'angular';

import { MeurhAdmissaocompletaListRouting } from './admissaocompleta.routes';

import { MeurhAdmissaocompletaService } from './admissaocompleta.service';
import { MeurhAdmissaocompletaModalService } from './modal/admissaocompleta.modal.service';
import { ConfiguracoesService } from '../../Configuracoes/configuracoes.service';

import { MeurhAdmissaocompletaNewController } from './new/admissaocompleta.new.controller';
import { MeurhAdmissoesDadosgeraisFormController } from './form/dadosgerais/dadosgerais.form.controller';
import { MeurhAdmissoesContratoFormController } from './form/contrato/contrato.form.controller';
import { MeurhAdmissoesEnderecoFormController } from './form/endereco/endereco.form.controller';
import { MeurhAdmissoesDadoscontatoFormController } from './form/dadoscontato/dadoscontato.form.controller';
import { MeurhAdmissoesDadospessoaisFormController } from './form/dadospessoais/dadospessoais.form.controller';
import { MeurhAdmissoesDocumentacaoFormController } from './form/documentacao/documentacao.form.controller';
import { MeurhAdmissoesOutrasinformacoesFormController } from './form/outrasinformacoes/outrasinformacoes.form.controller';
import { MeurhAdmissoesObservacoesFormController } from './form/observacoes/observacoes.form.controller';
import { MeurhAdmissoesBeneficiosFormController } from './form/beneficios/beneficios.form.controller';
import { MeurhAdmissoesDependentesFormController } from './form/dependentes/dependentes.form.controller';
import { MeurhAdmissoesValetransporteFormController } from './form/valetransporte/valetransporte.form.controller';
import { MeurhAdmissoesValetransportepersonalizadoFormController } from './form/valetransportepersonalizado/valetransportepersonalizado.form.controller';
import { MeurhAdmissoesBeneficiosFormmodalController } from './form/beneficios/formmodal/beneficios.formmodal.controller';
import { MeurhAdmissoesDependentesFormmodalController } from './form/dependentes/formmodal/dependentes.formmodal.controller';
import { MeurhAdmissoesValetransporteFormmodalController } from './form/valetransporte/formmodal/valetransporte.formmodal.controller';
import { MeurhAdmissoesValetransportepersonalizadoFormmodalController } from './form/valetransportepersonalizado/formmodal/valetransportepersonalizado.formmodal.controller';
import { MeurhAdmissaocompletaEditController } from './edit/admissaocompleta.edit.controller';
import { MeurhAdmissaocompletaIndexController } from './index/admissaocompleta.index.controller';
import { MeurhAdmissaocompletaShowController } from './show/admissaocompleta.show.controller';
import { MeurhAdmissoesFotoFormController } from './form/foto/foto.form.controller';

import { MeurhAdmissoesDadosgeraisFormComponent } from './form/dadosgerais/dadosgerais.form.component';
import { MeurhAdmissoesContratoFormComponent } from './form/contrato/contrato.form.component';
import { MeurhAdmissoesEnderecoFormComponent } from './form/endereco/endereco.form.component';
import { MeurhAdmissoesDadoscontatoFormComponent } from './form/dadoscontato/dadoscontato.form.component';
import { MeurhAdmissoesDadospessoaisFormComponent } from './form/dadospessoais/dadospessoais.form.component';
import { MeurhAdmissoesDocumentacaoFormComponent } from './form/documentacao/documentacao.form.component';
import { MeurhAdmissoesDocumentosanexosFormComponent } from './form/documentosanexos/documentosanexos.form.component';
import { MeurhAdmissoesOutrasinformacoesFormComponent } from './form/outrasinformacoes/outrasinformacoes.form.component';
import { MeurhAdmissoesObservacoesFormComponent } from './form/observacoes/observacoes.form.component';
import { MeurhAdmissoesBeneficiosFormComponent } from './form/beneficios/beneficios.form.component';
import { MeurhAdmissoesDependentesFormComponent } from './form/dependentes/dependentes.form.component';
import { MeurhAdmissoesValetransporteFormComponent } from './form/valetransporte/valetransporte.form.component';
import { MeurhAdmissoesValetransportepersonalizadoFormComponent } from './form/valetransportepersonalizado/valetransportepersonalizado.form.component';
import { MeurhAdmissoesBeneficiosFormmodalComponent } from './form/beneficios/formmodal/beneficios.formmodal.component';
import { MeurhAdmissoesDependentesFormmodalComponent } from './form/dependentes/formmodal/dependentes.formmodal.component';
import { MeurhAdmissoesValetransporteFormmodalComponent } from './form/valetransporte/formmodal/valetransporte.formmodal.component';
import { MeurhAdmissoesValetransportepersonalizadoFormmodalComponent } from './form/valetransportepersonalizado/formmodal/valetransportepersonalizado.formmodal.component';
import { MeurhAdmissoesFotoFormComponent } from './form/foto/foto.form.component';

export const MeurhAdmissaocompletaModule = angular.module('MeurhAdmissaocompletaModule', ['ui.router.state', ])
    .controller('MeurhAdmissaocompletaNewController', MeurhAdmissaocompletaNewController)
    .controller('MeurhAdmissoesDadosgeraisFormController', MeurhAdmissoesDadosgeraisFormController)
    .controller('MeurhAdmissoesContratoFormController', MeurhAdmissoesContratoFormController)
    .controller('MeurhAdmissoesEnderecoFormController', MeurhAdmissoesEnderecoFormController)
    .controller('MeurhAdmissoesDadoscontatoFormController', MeurhAdmissoesDadoscontatoFormController)
    .controller('MeurhAdmissoesDadospessoaisFormController', MeurhAdmissoesDadospessoaisFormController)
    .controller('MeurhAdmissoesDocumentacaoFormController', MeurhAdmissoesDocumentacaoFormController)
    .controller('MeurhAdmissoesOutrasinformacoesFormController', MeurhAdmissoesOutrasinformacoesFormController)
    .controller('MeurhAdmissoesObservacoesFormController', MeurhAdmissoesObservacoesFormController)
    .controller('MeurhAdmissoesBeneficiosFormController', MeurhAdmissoesBeneficiosFormController)
    .controller('MeurhAdmissoesDependentesFormController', MeurhAdmissoesDependentesFormController)
    .controller('MeurhAdmissoesValetransporteFormController', MeurhAdmissoesValetransporteFormController)
    .controller('MeurhAdmissoesValetransportepersonalizadoFormController', MeurhAdmissoesValetransportepersonalizadoFormController)
    .controller('MeurhAdmissoesBeneficiosFormmodalController', MeurhAdmissoesBeneficiosFormmodalController)
    .controller('MeurhAdmissoesDependentesFormmodalController', MeurhAdmissoesDependentesFormmodalController)
    .controller('MeurhAdmissoesValetransporteFormmodalController', MeurhAdmissoesValetransporteFormmodalController)
    .controller('MeurhAdmissoesValetransportepersonalizadoFormmodalController', MeurhAdmissoesValetransportepersonalizadoFormmodalController)
    .controller('MeurhAdmissaocompletaEditController', MeurhAdmissaocompletaEditController)
    .controller('MeurhAdmissaocompletaIndexController', MeurhAdmissaocompletaIndexController)
    .controller('MeurhAdmissaocompletaShowController', MeurhAdmissaocompletaShowController)
    .controller('MeurhAdmissoesFotoFormController', MeurhAdmissoesFotoFormController)
    .component('meurhAdmissoesEnderecoFormComponent', MeurhAdmissoesEnderecoFormComponent)
    .component('meurhAdmissoesDadoscontatoFormComponent', MeurhAdmissoesDadoscontatoFormComponent)
    .component('meurhAdmissoesDadosgeraisFormComponent', MeurhAdmissoesDadosgeraisFormComponent)
    .component('meurhAdmissoesContratoFormComponent', MeurhAdmissoesContratoFormComponent)
    .component('meurhAdmissoesDadospessoaisFormComponent', MeurhAdmissoesDadospessoaisFormComponent)
    .component('meurhAdmissoesDocumentacaoFormComponent', MeurhAdmissoesDocumentacaoFormComponent)
    .component('meurhAdmissoesDocumentosanexosFormComponent', MeurhAdmissoesDocumentosanexosFormComponent)
    .component('meurhAdmissoesOutrasinformacoesFormComponent', MeurhAdmissoesOutrasinformacoesFormComponent)
    .component('meurhAdmissoesObservacoesFormComponent', MeurhAdmissoesObservacoesFormComponent)
    .component('meurhAdmissoesBeneficiosFormComponent', MeurhAdmissoesBeneficiosFormComponent)
    .component('meurhAdmissoesDependentesFormComponent', MeurhAdmissoesDependentesFormComponent)
    .component('meurhAdmissoesValetransporteFormComponent', MeurhAdmissoesValetransporteFormComponent)
    .component('meurhAdmissoesValetransportepersonalizadoFormComponent', MeurhAdmissoesValetransportepersonalizadoFormComponent)
    .component('meurhAdmissoesBeneficiosFormmodalComponent', MeurhAdmissoesBeneficiosFormmodalComponent)
    .component('meurhAdmissoesDependentesFormmodalComponent', MeurhAdmissoesDependentesFormmodalComponent)
    .component('meurhAdmissoesValetransporteFormmodalComponent', MeurhAdmissoesValetransporteFormmodalComponent)
    .component('meurhAdmissoesValetransportepersonalizadoFormmodalComponent', MeurhAdmissoesValetransportepersonalizadoFormmodalComponent)
    .component('meurhAdmissoesFotoFormComponent', MeurhAdmissoesFotoFormComponent)
    .service('MeurhAdmissaocompletaService', MeurhAdmissaocompletaService)
    .service('MeurhAdmissaocompletaModalService', MeurhAdmissaocompletaModalService)
    .service('ConfiguracoesService', ConfiguracoesService)
    .config(MeurhAdmissaocompletaListRouting)
    .name;
