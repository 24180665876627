import angular from 'angular';
import { MeurhApontamentosService } from '../apontamentos.service';
import { IApontamento } from '../models/apontamentos.model';
export class MeurhApontamentosIndexController {
    static $inject = ['$rootScope', '$scope', '$state', '$http', 'MeurhApontamentosService', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster'];

    public entity: IApontamento;
    public busy: boolean = false;
    public service: any;
    public tipo: any;
    public filter: any = {};
    public constructors: any;
    public adminPodeCriar: any;
    public admin: any;
    public filters: any;
    public nomesMeses: any;
    public entities: any;
    public filtroAno: any;
    public filtroMes: any;
    public fields = [
        {
            value: 'mesreferencia',
            label: 'Mês',
            type: 'options',
            style: 'default',
            copy: '',
            options: {
                'Janeiro': 'entity.mesreferencia == "1"',
                'Fevereiro': 'entity.mesreferencia == "2"',
                'Março': 'entity.mesreferencia == "3"',
                'Abril': 'entity.mesreferencia == "4"',
                'Maio': 'entity.mesreferencia == "5"',
                'Junho': 'entity.mesreferencia == "6"',
                'Julho': 'entity.mesreferencia == "7"',
                'Agosto': 'entity.mesreferencia == "8"',
                'Setembro': 'entity.mesreferencia == "9"',
                'Outubro': 'entity.mesreferencia == "10"',
                'Novembro': 'entity.mesreferencia == "11"',
                'Dezembro': 'entity.mesreferencia == "12"',
            },
        },
        {
            value: 'anoreferencia',
            label: 'Ano',
            type: 'integer',
            style: 'default',
            copy: '',
        },
        {
            value: 'observacoes',
            label: 'Observações',
            type: 'float',
            style: 'default',
            copy: '',
        },
        {
            value: 'situacao',
            label: 'Situação',
            type: 'options',
            style: 'default',
            copy: '',
            options: {
                'Rascunho': 'entity.situacao == "-1"',
                'Enviado': 'entity.situacao == "1"',
            },
        },
    ];

    public meses = [{
        valor: '1',
        mes: 'Janeiro'
    }, {
        valor: '2',
        mes: 'Fevereiro'
    }, {
        valor: '3',
        mes: 'Março'
    }, {
        valor: '4',
        mes: 'Abril'
    }, {
        valor: '5',
        mes: 'Maio'
    }, {
        valor: '6',
        mes: 'Junho'
    }, {
        valor: '7',
        mes: 'Julho'
    }, {
        valor: '8',
        mes: 'Agosto'
    }, {
        valor: '9',
        mes: 'Setembro'
    }, {
        valor: '10',
        mes: 'Outubro'
    }, {
        valor: '11',
        mes: 'Novembro'
    }, {
        valor: '12',
        mes: 'Dezembro'
    }];

    constructor(
        public $rootScope: any,
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public entityService: MeurhApontamentosService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: any,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
    ) {
        $rootScope.carregandoApontamento = false;
        this.busy = false;
        this.service = entityService;
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.tipo = $rootScope['tipo'] = $stateParams.tipo;
        entityService.constructors.tipo = this.tipo;
        this.admin = $rootScope.isAdmin();
        this.adminPodeCriar = $rootScope.configuracoes['APENAS_ADMIN_CRIA_APONTAMENTO'];
        this.nomesMeses = new Array('Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro');
        this.constructors = entityService.constructors = [];

        if (!$rootScope.temPermissao('registrar_apontamento')) {
            $state.go('dp', $stateParams);
        }

        $scope.$on('apontamentos_apontamentos_deleted', (event) => {
            this.reload();
        });
        $scope.$on('$destroy', () => {
            if (entityService.loading_deferred) {
                entityService.loading_deferred.resolve();
            }
        });

        $scope.$on('apontamentos_apontamentos_submitted', (event, args) => {
            this.reload();
        });

        $scope.$on('getProfile', (event, entities) => {
            this.reload();
        });

        $rootScope.$on('estabelecimento_refreshed', (event, args) => {
            this.entity.estabelecimento = null;
            if (args.length === 1) {
                this.entity.estabelecimento = args[0];
            }
        });

        for (var i in $stateParams) {
            if (['situacao', 'estabelecimento'].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                entityService.filters[i] = $stateParams[i];
            } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'apontamento') {
                entityService.constructors[i] = $stateParams[i];
            }
        }

        this.reload();
    }

    search() {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }
        this.entities = this.entityService.search(this.filter);
    }

    reload() {
        this.entities = this.entityService.reload();
    }

    loadMore() {
        this.entityService.loadMore();
    }

    generateRoute(route: any, params: any) {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy() {
        return this.entityService.busy;
    }

    filterReload() {
        if ((this.filtroAno === '' || this.filtroAno === undefined) && this.constructors.hasOwnProperty('anoreferencia')) {
            delete this.constructors.anoreferencia;
        } else if (this.filtroAno !== '' && this.filtroAno !== undefined) {
            this.constructors.anoreferencia = this.filtroAno;
        }

        if ((this.filtroMes === '' || this.filtroMes === undefined) && this.constructors.hasOwnProperty('mesreferencia')) {
            delete this.constructors.mesreferencia;
        } else if (this.filtroMes !== '' && this.filtroMes !== undefined) {
            this.constructors.mesreferencia = this.filtroMes;
        }
        this.entityService.constructors['tipo'] = this.tipo;
        this.entityService.constructors = this.constructors;
        this.entityService.reload();
    }
}

