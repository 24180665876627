import ng from 'angular';
import { IRetornosAfastamento } from '../../models/retornoAfastamento.model';

export class MeurhSolicitacoesafastamentosretornoFormController implements ng.IController {
  static $inject = [];

  public form: ng.IFormController;
  public busy: boolean;
  public entity: IRetornosAfastamento;
  public action: string;
}
