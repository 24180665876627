import angular from 'angular';
import { IVtadicional } from '../models/vtadicional.model';
import { MeurhSolicitacoesvtsadicionaisService } from '../solicitacoesvtsadicionais.service';

export class MeurhSolicitacoesvtsadicionaisEditController implements angular.IController {
	static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesvtsadicionaisService',
        'NewToaster',
        'entity',
        'ModalConfirmService',
        '$q',
        '$transitions'
    ];

    public action = 'update';
	public form: angular.IFormController;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
    constructor (
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IState & { go: (route: string, params: any) => void },
        public entityService: MeurhSolicitacoesvtsadicionaisService,
        public NewToaster: any,
        public entity: IVtadicional,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any
        ) {
        this.entity.datalimiteinicial = this.entity.datalimitefinal = new Date();
        this.entity.contentType = this.entity.tipoconteudo === 1 ? 'currency' : 'number';

        $scope.$watch('mrh_slctcsvtsdcns_frm_cntrllr.entity', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);

        $scope.$watch('mrh_slctcsvtsdcns_frm_cntrllr.entity.tipoconteudo', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.entity.contentType = newValue === 1 ? 'currency' : 'number';
                this.entity.tipoconteudo = newValue;
            }
        }, true);

        $scope.$watch('mrh_slctcsvtsdcns_frm_edit_cntrllr.entity', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
        }, true);

        this.onSubmitSuccess();
        this.onSubmitError();
        this.onDeleteSuccess();
        this.onDeleteError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    submit(): void {
        if (this.form.$valid && !this.entity.$$__submitting && this.entity.conteudo > 0) {
            this.allowRefresh = false;
            this.entityService.save(this.entity, {
                empresa: this.entity.empresaobj.empresa,
                estabelecimento: this.entity.estabelecimentoobj.estabelecimento
            });
        } else {
            if (this.entity.conteudo <= 0) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'O campo de conteúdo deve ser maior do que 0'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Alguns campos do formulário apresentam erros.'
                });
            }
        }
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_submitted', (event: angular.IAngularEvent, args: any): void => {
            var insertSuccess = 'A Solicitação de VT Adicional foi criada com sucesso!';
            var updateSuccess = 'A Solicitação de VT Adicional foi editada com sucesso.';
            this.NewToaster.pop({
                type: 'success',
                title: args.response.config.method === 'PUT' ? updateSuccess : insertSuccess
            });
            if (args.response.config.method !== 'PUT') {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
            }
            this.$state.go('meurh_solicitacoesvtsadicionais_show',
                angular.extend({}, {
                'solicitacao': this.$stateParams['solicitacao'],
                'estabelecimento': this.$stateParams['estabelecimento']
                })
            );
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_submitted_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity, {
                        empresa: this.entity.empresaobj.empresa,
                        estabelecimento: this.entity.estabelecimentoobj.estabelecimento
                    });
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;
                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
                                    if (children[child].errors.hasOwnProperty(error)) {
										message += '<li>' + children[child].errors[error] + '</li>';
									}
                                }
                            }
                        }
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.config.method === 'PUT' ? 'Ocorreu um erro ao atualizar a Solicitação de VT Adicional!' : 'Ocorreu um erro ao enviar a Solicitação de VT Adicional!'
                    });
                }
            }
        });
    }

    delete(force: boolean): void {
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    onDeleteSuccess() {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de VT Adicional foi excluída com sucesso!'
            });
            this.$state.go('meurh_solicitacoesvtsadicionais', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError() {
        this.$scope.$on('meurh_solicitacoesvtsadicionais_delete_error', (event: angular.IAngularEvent, args: any): void => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao tentar excluir a Solicitação de VT Adicional.'
                });
            }
        });
    }

    buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoesvtsadicionais_show',
                    angular.extend({}, {
                    'solicitacao': this.$stateParams['solicitacao']
                    })
                );
            })
                .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_solicitacoesvtsadicionais_show',
                angular.extend({}, {
                'solicitacao': this.$stateParams['solicitacao']
                })
            );
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                    .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoesvtsadicionais_edit' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                    .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
