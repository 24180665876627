import angular from 'angular';
import { ICalendarioFerias } from '../models/calendarioferias.model';
import { MeurhCalendarioFeriasService } from '../calendarioferias.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';

export class ColaboradoresmodalService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: any) { }

	open(objeto: any): any {
        return this.$uibModal.open({
            template: require('html-loader!./calendarioferiasmobile.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                'MeurhCalendarioFeriasService',
                '$scope',
                'ListagemService',
                function MeurhSolicitacoesalteracoesenderecosModal(
					entity: any,
					$uibModalInstance: any,
                    MeurhCalendarioFeriasService: MeurhCalendarioFeriasService,
                    $scope: angular.IScope,
                    ListagemService: ListagemService,
                ) {
                    let selfModal = this;
                    selfModal.entity = entity;

                    selfModal.entities = objeto.entities;
                    selfModal.cores = objeto.cores;
                    selfModal.currentColorObject = objeto.currentColorObject;
                    selfModal.calendarView = objeto.calendarView;
                    selfModal.calendarOptions = objeto.calendarOptions;
                    selfModal.filters = objeto.filters;
                    selfModal.listagemPermissao = objeto.listagemFiltros.listagemPermissao;
                    selfModal.permissaoGrupoEmpresarial = objeto.listagemFiltros.permissaoGrupoEmpresarial;
                    selfModal.empresas = objeto.listagemFiltros.empresas;
                    selfModal.estabelecimentos = objeto.listagemFiltros.estabelecimentos;
                    selfModal.busy = false;
                    selfModal.carregouTudo = true;
                    selfModal.checkboxTodos = false;
                    selfModal.MeurhCalendarioFeriasService = MeurhCalendarioFeriasService;

                    $scope.$on('meurh_solicitacoesferias_calendario_list_finished', (event: angular.IAngularEvent, args: any): void => {
                        selfModal.busy = false;
                        selfModal.carregouTudo = true;
                    });

                    selfModal.mudaEstabelecimentos = function () {
                        selfModal.estabelecimentos = selfModal.filters.empresa ? ListagemService.montaEstabelecimentos(selfModal.filters.empresa, selfModal.empresas, selfModal.listagemPermissao) : [];
                        selfModal.filters.estabelecimento = '';
                        selfModal.filtraEmpresaEstabelecimento();
                    };

                    selfModal.filtraEmpresaEstabelecimento = function () {
                        selfModal.MeurhCalendarioFeriasService.constructors['empresa'] = selfModal.filters.empresa;
                        selfModal.MeurhCalendarioFeriasService.constructors['estabelecimento'] = selfModal.filters.estabelecimento;
                        selfModal.reload();
                    };

                    selfModal.atualizaListaColaboradores = function (entity: ICalendarioFerias, $index: number): void {
                        if (!entity.selecionado) {
                            selfModal.cores[$index] = { 'background-color': 'white' };
                            selfModal.checkboxTodos = false;
                        } else {
                            if (selfModal.currentColorObject < selfModal.calendarOptions.colors.length) {
                                selfModal.currentColorObject++;
                            } else {
                                selfModal.currentColorObject = 0;
                            }
                            selfModal.cores[$index] = {
                                'background-color': selfModal.calendarView === 'mes' ? selfModal.calendarOptions.colors[selfModal.currentColorObject] : '#30C01E',
                                'border-color': selfModal.calendarView === 'mes' ? selfModal.calendarOptions.colors[selfModal.currentColorObject] : '#30C01E',
                            };
                        }
                    };

                    selfModal.loadMore = function (): void {
                        selfModal.busy = true;
                        selfModal.MeurhCalendarioFeriasService.loadMore();
                    };

                    selfModal.search = function (): void {
                        let filter = {
                            search: selfModal.MeurhCalendarioFeriasService.filter,
                            filters: angular.copy(selfModal.MeurhCalendarioFeriasService.filters)
                        };

                        selfModal.busy = true;
                        selfModal.entities = selfModal.MeurhCalendarioFeriasService.search(filter);
                    };

                    selfModal.reload = function (): void {
                        selfModal.busy = true;
                        selfModal.entities = selfModal.MeurhCalendarioFeriasService.reload();
                    };

                    selfModal.checkboxEntidades = function (): void {
                        angular.forEach(selfModal.entities, (entity, index): void => {
                            entity.selecionado = selfModal.checkboxTodos ? true : false;
                        });
                    };

                    selfModal.confirmar = function () {
                        objeto.entities = selfModal.entities;
                        objeto.cores = selfModal.cores;
                        objeto.currentColorObject = selfModal.currentColorObject;
                        $uibModalInstance.close(selfModal.entity);
                    };

                    selfModal.close = function () {
                        $uibModalInstance.dismiss('fechar');
                    };
                }
            ],
            controllerAs: 'ctrllr',
            windowClass: 'nsj-modal-lg-no-height',
            resolve: {
                entity: function () {
                    return objeto;
                }
            }
        });
    }
}
