import angular from 'angular';

export class MeurhInclusaodependenteModalService {
    static $inject = ['$uibModal'];

    constructor(
        public $uibModal: any
    ) { }

    open(entity: any, parameters: any): any {
        return this.$uibModal.open({
            template: require('html-loader!./inclusaodependente.modal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                'NewToaster',
                function MeurhInclusaodependenteModal(
                    entity: any,
                    $uibModalInstance: any,
                    NewToaster: any
                ): void {
                    let selfModal = this;

                    selfModal.NewToaster = NewToaster;
                    selfModal.parameters = parameters;

                    selfModal.form = parameters.form;

                    selfModal.entity = entity;
                    selfModal.entitycopy = angular.copy(entity);

                    selfModal.submit = (): void => {
                        selfModal.form.$submitted = true;
                        if (
                            selfModal.valorExiste(selfModal.entitycopy.beneficio) &&
                            selfModal.valorExiste(selfModal.entitycopy.tipoperiodo) &&
                            (selfModal.valorExiste(selfModal.entitycopy.quantidade) && selfModal.entitycopy.quantidade !== 0) &&
                            selfModal.valorExiste(selfModal.entitycopy.lotacao) &&
                            selfModal.validaValor() &&
                            selfModal.validaTipoperiodo() &&
                            selfModal.validaDesconto()
                        ) {
                            selfModal.entitycopy.desconto = selfModal.entitycopy.desconto ? selfModal.entitycopy.desconto : null;
                            selfModal.entitycopy.descricao = selfModal.entitycopy.descricao ? selfModal.entitycopy.descricao : null;
                            selfModal.entity = angular.copy(selfModal.entitycopy);
                            $uibModalInstance.close(selfModal.entity);
                        } else {
                            selfModal.erroForm();
                        }
                    };

                    selfModal.close = (): void => {
                        $uibModalInstance.dismiss('fechar');
                    };

                    selfModal.valorExiste = (valor: any): boolean => {
                        return valor !== '' && valor !== undefined && valor !== null ? true : false;
                    };

                    selfModal.erroForm = (): void => {
                        selfModal.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário de ' + selfModal.parameters.componente.toLowerCase() + ' apresentam erros',
                            body: 'Favor verificar os campos obrigatórios, assinalados com *.',
                        });
                    };

                    selfModal.validaValor = (): boolean => {
                        if (selfModal.entitycopy && selfModal.entitycopy.beneficio.tipovalor === 1) {
                            if (selfModal.valorExiste(selfModal.entitycopy.valor)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaDesconto = (): boolean => {
                        if (selfModal.entitycopy && selfModal.entitycopy.beneficio.tipodesconto === 2) {
                            if (selfModal.valorExiste(selfModal.entitycopy.desconto)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaTipoperiodo = (): boolean => {
                        if (selfModal.entitycopy.tipoperiodo === '1') { // todo mes x
                            if (selfModal.valorExiste(selfModal.entitycopy.mes)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else if (selfModal.entitycopy.tipoperiodo === '2') { // entre mes e ano
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.mesinicial) &&
                                selfModal.valorExiste(selfModal.entitycopy.anoinicial) &&
                                selfModal.valorExiste(selfModal.entitycopy.anofinal) &&
                                selfModal.valorExiste(selfModal.entitycopy.mesfinal)
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                }
            ],
            controllerAs: '$ctrl',
            backdrop: 'static',
            windowClass: (parameters.componente === 'Dependente' ? 'nsj-modal-lg' : 'nsj-modal-lg-no-height'),
            resolve: {
                entity: (): any => {
                    return entity;
                }
            }
        });
    }
}
