import * as angular from 'angular';
import { MeurhSolicitacoesFormShowController } from './solicitacoes.form.show.controller';

export class MeurhSolicitacoesDefaultShow implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesDefaultShow';
	static controller = MeurhSolicitacoesFormShowController;
	static template = require('!!html-loader!./solicitacoes.form.show.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
