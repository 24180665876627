import angular from 'angular';
import { IApontamento } from '../models/apontamentos.model';

export class ApontamentosModalConfirmacaoController {
    static $inject = ['$uibModalInstance', 'NewToaster', 'entity', 'movimentosTipoSempre', 'semMovimentos', 'SITUACAO_PENDENTE', 'busy'];

    public confirmarValoresApontamento: any = false;
    public trabalhadores: any = [];

    constructor(
        public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        public NewToaster: any,
        public entity: IApontamento,
        public movimentosTipoSempre: any,
        public semMovimentos: any,
        public SITUACAO_PENDENTE: any,
        public busy: any,
    ) {
        if (this.entity.apontamentostrabalhadores.length > 0) {
            this.entity.apontamentostrabalhadores.forEach((aptTrab: any, id: any) => {
                if (aptTrab.movimentos === undefined || aptTrab.movimentos === null || aptTrab.movimentos.length === 0) {
                    this.semMovimentos = true;
                } else {
                    this.movimentosTipoSempre.forEach((movSempre: any, i: any) => {
                        if (aptTrab.trabalhador.trabalhador === movSempre.trabalhador.trabalhador) {
                            aptTrab.movimentos.forEach((movimento: any, idx: any) => {
                                if (movimento.evento.evento === movSempre.evento.evento && movimento.situacao === -1) {
                                    this.confirmarValoresApontamento = true;
                                    let indexTrabalhador: any = this.getIndexByTrabalhador(aptTrab.trabalhador.trabalhador);
                                    if (indexTrabalhador === false) {
                                        this.trabalhadores.push(angular.copy(aptTrab.trabalhador));
                                        indexTrabalhador = this.trabalhadores.lastIndex;
                                        this.trabalhadores[indexTrabalhador].rubricas = [];
                                    }
                                    let rubrica = angular.copy(movSempre.evento);
                                    rubrica.dynamictype = this.selectDtype(rubrica);
                                    rubrica.cadastrada = movSempre.conteudo;
                                    rubrica.apontada = movimento.conteudo;
                                    this.trabalhadores[indexTrabalhador].rubricas.push(rubrica);
                                }
                            });
                        }
                    });
                }
            });
        } else {
            this.semMovimentos = true;
        }
    }

    ok() {
        if (this.semMovimentos === true) {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não é possível salvar um movimento sem rubricas'
            });
        } else {
            this.trabalhadores.forEach((trabalhador: any) => {
                this.entity.apontamentostrabalhadores.forEach((aptTrab: any, i: any) => {
                    if (trabalhador.trabalhador === aptTrab.trabalhador.trabalhador) {
                        trabalhador.rubricas.forEach((rubrica: any) => {
                            aptTrab.movimentos.forEach((movimento: any, idx: any) => {
                                if (movimento.situacao === -1 && rubrica.evento === movimento.evento.evento) {
                                    this.entity.apontamentostrabalhadores[i].movimentos[idx].conteudo = rubrica.apontada;
                                }
                            });
                        });
                    }
                });
            });
            this.entity.enviando = true;
            this.entity.situacao = this.SITUACAO_PENDENTE;
            this.$uibModalInstance.close();
        }
        this.close();
    }


    close() {
        this.$uibModalInstance.dismiss('fechar');
    }

    getIndexByTrabalhador(trabalhador: any) {
        for (let i = 0; i < this.trabalhadores.length; i++) {
            if (this.trabalhadores[i]['trabalhador'] === trabalhador) {
                return i;
            }
        }
        return false;
    }

    selectDtype(rubrica: any) {
        let tipo = rubrica.unidade;
        switch (tipo) {
            case 0:
            case 8:
            case 10:
                return 'interval';
            case 2:
                return 'currency';
            default:
                return 'number';
        }
    }
}
