import * as angular from 'angular';
import { MeurhAdmissoesestagiarioObservacoesFormController } from './observacoes.form.controller';

export class MeurhAdmissoesestagiarioObservacoesFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioObservacoesFormComponent';
	static controller = MeurhAdmissoesestagiarioObservacoesFormController;
	static template = require('!!html-loader!./observacoes.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
