import angular = require('angular');
import { ISolicitacaoAdmissao } from '../models/solicitacaoadmissao.model';
import { MeurhSolicitacoesadmissoesService } from '../solicitacoesadmissoes.service';

export class MeurhSolicitacoesadmissoesIndexController implements ng.IController {
    static $inject = ['$rootScope', '$scope', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'MeurhSolicitacoesadmissoes', '$state'];

    public entities: Array<ISolicitacaoAdmissao>;
    public busy: boolean = false;
    public filters = this.entityService.filters = {};
    public filterAdmitido: any = '0';
    public dataInicial: string = null;
    public dataFinal: string = null;
    public reload = this.entityService.reload;
    public constructors: any = this.entityService.constructors = [];
    public maxoccurs: object = {};
    public selects: object = {};
    public options: object = { 'estabelecimento': 'estabelecimento', };
    public fields: any = [
        {
            value: 'nome',
            label: 'Nome',
            type: 'string',
            style: 'title',
            copy: '',
        },

        {
            value: 'codigo',
            label: 'Código',
            type: 'integer',
            style: 'default',
            copy: '',
        },

        {
            value: 'cpf',
            label: 'CPF',
            type: 'string',
            style: 'default',
            copy: '',
        },

        {
            value: 'dataadmissao',
            label: 'Data de admissão',
            type: 'date',
            style: 'default',
            copy: '',
        },

        {
            value: 'trabalhador',
            label: 'Admitido',
            type: 'guid',
            style: 'label',
            copy: '',
            label_class: '{"label-warning": entity.trabalhador == null,"label-success": entity.trabalhador !== null}',
        },

    ];

    constructor(
        public $rootScope: angular.IRootScopeService & { temPermissao: (arg: string) => boolean },
        public $scope: angular.IScope,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: any,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: MeurhSolicitacoesadmissoesService,
        public $state: angular.ui.IStateService
    ) {
        if (!this.$rootScope.temPermissao('visualizar_admissao')) {
            $state.go('dp', $stateParams);
        }

        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';

        for (let i in $stateParams) {
            if (['estabelecimento'].indexOf(i) > -1 && $stateParams[i] !== undefined) {
                entityService.filters[i] = $stateParams[i];
            } else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
                entityService.constructors[i] = $stateParams[i];
            }
        }
    }

    $onInit(): void {
        this.onSolicitacoesAdmissoesDelete();
        this.onDestroy();
        this.onSolicitacoesAdmissoesSubmit();
        this.filterReload();
    }

    escondeRascunhos(obj: any): boolean {
        return obj.situacao === -1 ? false : true;
    }

    search(filter: any): Array<ISolicitacaoAdmissao> {
        if (filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, filter.filters, { 'q': filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, filter.filters));
        }

        return this.entityService.search(filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }

    onSolicitacoesAdmissoesDelete(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_deleted', (event: angular.IAngularEvent): void => {
            this.filterReload();
        });
    }

    onDestroy(): void {
        this.$scope.$on('$destroy', (): void => {
            if (this.entityService.loading_deferred) {
                this.entityService.loading_deferred.resolve();
            }
        });
    }

    onSolicitacoesAdmissoesSubmit(): void {
        this.$rootScope.$on('meurh_solicitacoesadmissoes_submitted', (event: angular.IAngularEvent, args: any): void => {
            if (!args.autosave &&  args.entity.situacao !== -1) {
                this.reload();
            }
        });
    }

    filterReload(): void {
        if (this.constructors.hasOwnProperty('situacao')) {
            delete this.constructors.situacao;
        }

        if (this.filterAdmitido !== '') {
            this.constructors.situacao = this.filterAdmitido;
        }

        let filtroDataInvalido = false;

        if (this.dataInicial !== null && this.dataFinal !== null && this.dataInicial !== '' && this.dataFinal !== '' && this.dataInicial > this.dataFinal) {
            this.NewToaster.pop({
                type: 'error',
                title: 'A data inícial não pode ser posterior a data final'
            });

            filtroDataInvalido = true;
        }

        if (!filtroDataInvalido) {
            let created_at = [];

            if (this.dataInicial === undefined || (this.dataInicial !== null && this.dataInicial.length === 0)) {
                this.dataInicial = null;
                this.constructors.datainicial = null;
            }

            if (this.dataInicial !== null) {
                created_at.push({
                    condition: 'gte',
                    value: this.dataInicial + ' 00:00:00'
                });

                delete this.constructors.datainicial;
            }

            if (this.dataFinal === undefined || (this.dataFinal !== null && this.dataFinal.length === 0)) {
                this.dataFinal = null;
                this.constructors.datafinal = null;
            }

            if (this.dataFinal !== null) {
                created_at.push({
                    condition: 'lte',
                    value: this.dataFinal + ' 23:59:00'
                });

                delete this.constructors.datafinal;
            }

            this.constructors.created_at = created_at;
            this.entityService.constructors = this.constructors;
            this.entities = this.entityService.reload();
        }
    }
}
