import { NsjUsuarioInstance } from '../../core/usuario/nsj-usuario';
import { Usuario as profile } from '../../core/usuario/models/usuario-model';
import angular from 'angular';
export class Usuarios {

  static $inject = ['$rootScope'];

  public profile: profile = NsjUsuarioInstance.Usuario;

  constructor($rootScope: angular.IScope & { usuario: profile }) {
    $rootScope.usuario = this.profile;
  }

  getProfile() {
    return new Promise((resolve) => {
      resolve({
        data: this.profile
      });
    });
  }

}
