import moment from 'moment';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { ISolicitacaoAlteracaoEndereco } from '../models/solicitacaoalteracaoendereco.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhSolicitacoesalteracoesenderecosFormController {
  static $inject = [
    '$scope',
    '$http',
    'nsjRouting',
    '$stateParams',
    '$rootScope',
    'ListagemService'
  ];

  public entity: ISolicitacaoAlteracaoEndereco;
  public tipo: string = this.$stateParams['tipo'] ? this.$stateParams['tipo'] : '-1';
  public form: angular.IFormController;
  public busy: boolean = false;
  public action: string;

  public filters = {
    empresa: this.$rootScope.session.entities.empresa.empresa,
    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
  };
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;
  public listagemPermissao: Array<string> = ['criar_alteracaodadoscadastrais'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

  public constructorLookupColaborador;

  constructor(
    public $scope: angular.IScope,
    public $http: angular.IHttpService,
    public nsjRouting: any,
    public $stateParams: angular.ui.IStateParamsService,
    public $rootScope: angular.IRootScopeService & {
      temPermissao: (arg: string) => boolean,
      modoGestorPermissao: (arg: string) => boolean,
      temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
      liberadoTela: (arg: string) => boolean,
      session: any,
      nsjGlobals: any,
    },
    public ListagemService: ListagemService
  ) {
    $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
      if (newValue !== oldValue) {
        this.form.$setDirty();
      }
    }, true);
  }

  $onInit(): void {
    this.montaListagemVisibilidade();

    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };

  }

  montaListagemVisibilidade(): void {
    if (this.action === 'update') {
      this.filters.empresa = this.entity.estabelecimentoobj.empresa;
      this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
    }
    this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
    this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
  }

  mudaEstabelecimentos(): void {
    this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
    this.filters.estabelecimento = '';
    this.filtraEmpresaEstabelecimento();
  }

  filtraEmpresaEstabelecimento(): void {
    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.forcaGestor(),
      ...(this.tipo && { 'tipo': this.tipo }),
    };
  }

  selectTrabalhador(): void {
    if (this.entity.trabalhador !== null) {
      this.entity.$$__submitting = true;
      this.$http.get(this.nsjRouting.generate('meurh_solicitacoesalteracoesenderecos_possuiendereco', {
        trabalhador: this.entity.trabalhador.trabalhador,
        empresa: this.entity.trabalhador.empresaobj.empresa,
        estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
      }, true))
      .then((response) => {
        this.entity.solicitacaoaberta = response.data;
          if (this.entity.solicitacaoaberta.length > 0) {
            this.entity.solicitacaoaberta[0].created_at = moment(this.entity.solicitacaoaberta[0].created_at).format('DD-MM-YYYY HH:mm');
            if (this.entity.solicitacao === this.entity.solicitacaoaberta[0].solicitacao) {
              this.entity.solicitacaoaberta = false;
            }
          }
          this.entity.$$__submitting = false;
      })
      .catch((response: any) => {/**/});
    }
  }

  forcaGestor(): boolean {
    return this.$rootScope.modoGestorPermissao('criar_alteracaodadoscadastrais');
  }

  onChangeTrabalhador(trabalhadorSelecionado: any): void {
    if (trabalhadorSelecionado) {
      this.selectTrabalhador();
    } else {
      this.entity.solicitacaoaberta = false;
    }
  }

  onChangePais(paisSelecionado: any) {
    if (paisSelecionado == null || paisSelecionado.pais !== '1058') {
      this.entity.municipioresidencia = null;
    }
  }

}
