import angular from 'angular';
import moment from 'moment';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IInclusaodependente } from '../../models/inclusaodependente.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';

export class MeurhInclusaodependenteDadosgeraisFormController implements angular.IController {

    static $inject = [
        '$rootScope',
        'ListagemService'
    ];

    public busy: boolean;
    public entity: IInclusaodependente;
    public action: string;
    public form: angular.IFormController;
    public desativaportrabalhadortemporario: boolean = false;
    public uFArray = [
        'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES',
        'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE',
        'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC',
        'SE', 'SP', 'TO'
    ];
    public constructor_: { trabalhador: string | null } = {
        trabalhador: null
    };

    // inicio - data de nascimento
    public dataAtual = moment();
    public dataMinima: any;
    // fim - data de nascimento

    public tipo = '-1';

    public dataBaixaIRInvalida: boolean = false;
    public dataBaixaSalFamInvalida: boolean = false;

    public filters = {
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
    };
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['criar_dependente'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    public constructorLookupColaborador;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            liberadoTela: (arg: string) => boolean,
            session: any,
            nsjGlobals: any,
        },
        public ListagemService: ListagemService
    ) {}

    $onInit(): void {
        if (!!this.entity.datanascimento) {
            this.calculaIdade();
        }

        this.montaListagemVisibilidade();

        this.constructorLookupColaborador = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            'forcagestor': this.$rootScope.modoGestorPermissao('criar_dependente'),
            ...(this.tipo && { 'tipo': this.tipo }),
        };

        // obtendo a vaga relacionada ao trabalhador selecionado
        this.$rootScope.$on('persona_trabalhadores_get_resumo_loaded', (event: angular.IAngularEvent, args: any) => {
            this.entity.vaga = args.response.vaga;
        });

    }

    montaListagemVisibilidade(): void {
        if (this.action === 'show') {
            this.filters.empresa = this.entity.estabelecimento.empresa;
            this.filters.estabelecimento = this.entity.estabelecimento.estabelecimento;
        }
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.constructorLookupColaborador = {
            'empresa': this.filters.empresa,
            'estabelecimento': this.filters.estabelecimento,
            'status': '00',
            'forcagestor': this.$rootScope.modoGestorPermissao('criar_dependente'),
            ...(this.tipo && { 'tipo': this.tipo }),
        };
    }

    // cpf
    preenchePix(): void {
        if (this.entity.tiporecebimento === '128') { // cpf
            this.entity.chavepix = this.entity.cpf;
        }
    }

    onChangeTrabalhador(trabalhadorSelecionado: any): void {

        if (trabalhadorSelecionado) {
            this.alteraBeneficiarioPorTrabalhador();
        }
        this.constructor_ = this.verificacaoTrabalhador();

        if (this.entity.beneficios?.length > 0) {
            this.entity.beneficios = [];
        }
    }

    verificacaoTrabalhador(): { trabalhador: string | null } {
        if (this.entity.trabalhador && this.entity.trabalhador.trabalhador) {
            return { 'trabalhador': this.entity.trabalhador.trabalhador };
        }
        return { 'trabalhador': null };
    }

    alteraBeneficiarioPorTrabalhador(): void {
        if (this.entity.trabalhador) {
            this.$rootScope.$broadcast('beneficiariopensao_refresh', { trabalhador: this.entity.trabalhador.trabalhador });
            this.entity.beneficiariopensao = null;
        }
    }

    calculaIdade(): void {
        if (!!this.entity.datanascimento) {
            this.entity['idade'] = moment().diff(this.entity.datanascimento, 'years');
        }
        if (!!this.entity.parentesco && this.entity.impostorenda === true) {
            this.calculaDataBaixa();
        }
        if (this.entity.salariofamilia === true) {
            this.calculaDataBaixaSalFam();
        }
    }

    calculaDataBaixaSalFam(): void {
        let dataBaixaCalc: string | moment.Moment = '';
        if (this.entity.salariofamilia === true && !!this.entity.datanascimento && !this.entity.incapacidadefisicamental) {
            dataBaixaCalc = moment(this.entity.datanascimento, 'YYYY-MM-DD').add(14, 'years');
        }
        this.entity.databaixacertidao = dataBaixaCalc;

        if (moment(this.entity.databaixacertidao).isBefore(this.dataAtual)) {
            this.dataBaixaSalFamInvalida = true;
        } else {
            this.dataBaixaSalFamInvalida = false;
        }
    }

    public calculaDataBaixa(): void {
        if (!!this.entity.parentesco && !!this.entity.datanascimento && !!this.entity.impostorenda) {
            let dataBaixaCalc: string | moment.Moment = '';

            switch (this.entity.parentesco.toString()) {
                case '3': // 21 anos
                case '6':
                case '10':
                    dataBaixaCalc = moment(this.entity.datanascimento).add(21, 'years');
                    break;
                case '4': // 24 anos
                    dataBaixaCalc = moment(this.entity.datanascimento).add(24, 'years');
                    break;
            }

            this.entity.databaixaimpostorenda = dataBaixaCalc;
            this.dataBaixaIRInvalida = (moment(this.entity.databaixaimpostorenda).isBefore(this.dataAtual));
        }
    }

    public limpaIncapacidadeFisicaMental(): void {
        if (this.entity.parentesco.toString() !== '3') {
            this.entity.incapacidadefisicamental = false;
        }
    }

    limpaCamposDependenteIR(): void {
        if (this.entity.impostorenda === false) {
            delete this.entity.databaixaimpostorenda;
        } else {
            this.calculaDataBaixa();
        }
    }

    limpaCamposEhBeneficiariosalfamilia(): void {
        if (this.entity.salariofamilia === false) {
            delete this.entity.databaixacertidao;
            delete this.entity.vencimentoescolar;
            delete this.entity.vencimentovacinacao;
            delete this.entity.beneficiariopensao;
        } else {
            this.calculaDataBaixaSalFam();
        }
    }

    public limpaCamposSalFamiliaComIncapacidadeFisicaMental(): void {
        if (!this.entity.incapacidadefisicamental) {
            this.entity.atestadoparanaofrequentarescola = false;
        } else {
            this.entity.databaixacertidao = null;
            this.entity.vencimentoescolar = null;
            this.entity.vencimentovacinacao = null;
        }
    }

    public validaDataInclusao(): boolean {
        return !!this.entity.datainclusao && moment(this.entity.datainclusao).isSameOrBefore(moment(this.entity.trabalhador!.dataadmissao));
    }

    public validaDataMenorQueAtual(data: string): boolean {
        return moment(data).isBefore(this.dataAtual);
    }
}
