import angular from 'angular';
import { IEntity } from './models/IEntity.model';
import { LoadParams } from '../../../shared/models/loadparams.model';

export class MeurhSolicitacoesadiantamentosavulsosService {

  static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

  public entities: Array<any> = [];
  public entity: IEntity;
  public loaded: boolean = false;
  public constructors: { solicitacao?: string } = {};
  public after = {};
  public filters = {};
  public loadParams: LoadParams & { loading_deferred: any };
  public filter: any = '';
  constructor(
    public $http: angular.IHttpService,
    public nsjRouting: any,
    public $rootScope: angular.IRootScopeService & { temPermissao: Function },
    public $q: angular.IQService
  ) {
    this.loadParams = {
      to_load: 3,
      busy: false,
      finished: false,
      loading_deferred: null,
    };
  }

  reload() {
    if (this.loadParams.loading_deferred) {
      this.loadParams.loading_deferred.resolve();
    }
    this.loadParams.finished = false;
    this.loadParams.to_load = 3;
    this.after = {};
    this.loaded = false;
    this.entities.length = 0;
    this.loadParams.busy = false;
    return this.load();
  }

  search(filter: { search: string, filters: object }) {
    if (typeof filter !== 'undefined') {
      this.filter = filter.search;
      Object.keys(this.filters).forEach((k: string) => { delete this.filters[k]; });
      for (var fil in filter.filters) {
        if (fil.indexOf('.') > -1) {
          var filterSplit = fil.split('.');
          this.filters[filterSplit[0]] = {};
          this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
        } else {
          this.filters[fil] = filter.filters[fil];
        }
      }
    }
    return this.reload();
  }

  _load(constructors: object, offset: object, filter: object) {
    this.loadParams.loading_deferred = this.$q.defer();
    return this.$q((resolve, reject) => {
      this.$http({
        method: 'GET',
        url: this.nsjRouting.generate('meurh_solicitacoesadiantamentosavulsos_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
        timeout: this.loadParams.loading_deferred.promise
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((response) => {
          reject(response);
        });

    });

  }

  load() {
    if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
      this.loadParams.busy = true;
      this._load(this.constructors, this.after, this.filter)
        .then((data: any) => {
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              this.entities.push(data[i]);
            }
            this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
            this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
          }
          if (data.length < 20) {
            this.loadParams.finished = true;
            this.$rootScope.$broadcast('meurh_solicitacoesadiantamentosavulsos_list_finished', this.entities);
          } else {
            this.loadParams.finished = false;
          }
          this.loaded = true;
          this.loadParams.to_load--;
        })
        .catch((error) => {
          if (error.xhrStatus !== 'abort') {
            this.loadParams.finished = true;
          }
        })
        .finally(() => {
          if (this.loaded || this.loadParams.finished) {
            this.loadParams.busy = false;
          }
          this.load();
        });
    }

    return this.entities;
  }

  loadMore() {
    this.loadParams.to_load = 3;
    this.load();
  }

  find(identifier: string) {
    for (var i in this.entities) {
      if (this.entities[i]['solicitacao'] === identifier) {
        return this.entities[i];
      }
    }
    return null;
  }

  delete($identifier: string, force: boolean) {
    if (typeof ($identifier) === 'object') {
      $identifier = $identifier['solicitacao'];
    }
    if ($identifier) {
      if (force || confirm('Tem certeza que deseja deletar?')) {
        this.loadParams.loading_deferred = this.$q.defer();
        this.$http.delete(this.nsjRouting.generate('meurh_solicitacoesadiantamentosavulsos_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
          { timeout: this.loadParams.loading_deferred.promise })
          .then((response) => {
            this.$rootScope.$broadcast('meurh_solicitacoesadiantamentosavulsos_deleted', {
              entity: this.entity,
              response: response
            });
          })
          .catch((response) => {
            this.$rootScope.$broadcast('meurh_solicitacoesadiantamentosavulsos_delete_error', {
              entity: this.entity,
              response: response
            });
          });
      }
    }
  }

  _save(entity: IEntity,
    params: { empresa: string, estabelecimento: string },
    autosave?: boolean) {
    let method: string, url: string;
    if (entity['solicitacao']) {
      method = 'PUT';
      url = this.nsjRouting.generate('meurh_solicitacoesadiantamentosavulsos_put', {
        id: entity['solicitacao'],
        ...params
      }, true);
    } else {
      method = 'POST';
      url = this.nsjRouting.generate('meurh_solicitacoesadiantamentosavulsos_create',
        angular.extend({}, this.constructors, params),
      true);
    }
    if (!autosave) {
      autosave = false;
      entity['$$__submitting'] = true;
    }
    let data = angular.copy(entity);
    if (data.hasOwnProperty('$$__submitting')) {
      delete data['$$__submitting'];
    }
    this.loadParams.loading_deferred = this.$q.defer();
    return this.$http({
      method: method,
      url: url,
      data: data,
      timeout: this.loadParams.loading_deferred.promise
    }).finally(() => {
      if (!autosave) {
        entity['$$__submitting'] = false;
      }
    });
  }

  save(entity: IEntity,
    params: { empresa: string, estabelecimento: string },
    autosave?: boolean) {
    this
      ._save(entity, params, autosave)
      .then((response: any) => {
        if (response.data.solicitacao) {
          entity['solicitacao'] = response.data.solicitacao;
        }
        entity['$$_saved'] = true;
        this.$rootScope.$broadcast('meurh_solicitacoesadiantamentosavulsos_submitted', {
          entity: entity,
          response: response,
          autosave: autosave
        });
      })
      .catch((response) => {
        this.$rootScope.$broadcast('meurh_solicitacoesadiantamentosavulsos_submit_error', {
          entity: entity,
          response: response,
          autosave: autosave
        });
      });
  }

  fechar(entity: IEntity) {
    this.loadParams.loading_deferred = this.$q.defer();
    entity['$$__submitting'] = true;
    return this.$q((resolve, reject) => {
      this.$http({
        method: 'post',
        url: this.nsjRouting.generate('meurh_solicitacoesadiantamentosavulsos_fechar', { 'id': entity.solicitacao }, true),
        data: angular.copy(entity),
        timeout: this.loadParams.loading_deferred.promise
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((response) => {
          reject(response);
        }).finally(() => {
          entity['$$__submitting'] = false;
        });
    });
  }

  get(identifier: string) {
    this.loadParams.loading_deferred = this.$q.defer();

    return this.$q((resolve, reject) => {
      this.$http
        .get(this.nsjRouting.generate('meurh_solicitacoesadiantamentosavulsos_get', { 'id': identifier }, true),
          { timeout: this.loadParams.loading_deferred.promise })
        .then((response) => {
          this.$rootScope.$broadcast('meurh_solicitacoesadiantamentosavulsos_loaded', response.data);
          resolve(response.data);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }
}
