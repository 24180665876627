import ng from 'angular';
import angular from 'angular';
import { IRecisao } from '../../../models/recisao.model';

export class MeurhSolicitacoesrecisoesShowFormController implements ng.IController {

    static $inject = ['$scope', '$stateParams'];

    public entity: IRecisao;
    public tipo: string;
    public form: angular.IFormController;

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService) {

        this.tipo = $stateParams.tipo;

        $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);
    }

    public selectDtype(movimento: any): string {
        let tipo: number = 0;

        if (!!movimento.evento) {
            tipo = movimento.evento.evento_unidade || movimento.evento.unidade || 0;
        }

        const tipos_existentes = {
            0: 'number',
            1: 'number',
            2: 'currency',
            3: 'currency',
            5: 'currency',
            8: 'interval',
            10: 'interval',
            12: 'currency'
        };

        return tipos_existentes[tipo] || 'number';
    }

    public getValorIndenizado(): string {
        return this.entity.indenizado
            ? 'Sim'
            : 'Não';
    }
}
