import angular = require('angular');
import { IVisualizarSolicitacao } from '../models/visualizarsolicitacao.model';

export class MeurhVisualizarSolicitacaoService {
	static $inject = ['PermissaoService', 'MeurhSolicitacoessalariossobdemanda', '$q', '$injector', '$filter'];

	public SITUACOES: any = {
		'0': 'Aberta',
		'1': 'Efetuada',
		'2': 'Cancelada',
		'4': 'Recusada'
	};
	public TIPOS_SOLICITACOES: any = [
		'Admissão Preliminar',
		'Rescisão',
		'Adiantamento Avulso',
		'VT Adicional',
		'Alteração de VT',
		'Alteração de endereço',
		'Falta',
		'Férias',
		// 'Salário sob demanda',
		'Afastamento',
		'Promoção',
		'Créditos e Descontos',
		'',
		'Admissão completa',
		'Transferência de colaborador'
	];

	constructor(
		public PermissaoService: any,
		public MeurhSolicitacoessalariossobdemanda: any,
		public $q: angular.IQService,
		public $injector: any,
		public $filter: any
	) { }

	getServiceName(tiposolicitacao: number | string): string {
		let serviceName;

		switch (tiposolicitacao) {
			case 0: // admissão
				serviceName = 'MeurhSolicitacoesadmissoes';
				break;
			case 1: // rescisão
				serviceName = 'MeurhSolicitacoesrescisoesService';
				break;
			case 2: // adiantamento avulso
				serviceName = 'MeurhSolicitacoesadiantamentosavulsosService';
				break;
			case 3: // vt adicional
				serviceName = 'MeurhSolicitacoesvtsadicionaisService';
				break;
			case 4: // alteração de vt
				serviceName = 'MeurhSolicitacoesalteracoesvtsService';
				break;
			case 5: // alteração de endereço
				serviceName = 'MeurhSolicitacoesalteracoesenderecosService';
				break;
			case 6: // falta
				serviceName = 'MeurhSolicitacoesFaltasService';
				break;
			case 7: // férias
				serviceName = 'MeurhSolicitacoesferiasService';
				break;
			case 8: // afastamento (antigo: salário sob demanda)
				// serviceName = 'MeurhSolicitacoessalariossobdemanda';
				serviceName = 'MeurhAfastamentosService';
				break;
			case 9: // promoções
				serviceName = 'MeurhSolicitacoespromocoesService';
				break;
			case 10: // créditos e descontos
				serviceName = 'MeurhCreditosdescontosService';
				break;
			case 12: // admissão completa
				serviceName = 'MeurhAdmissaocompletaService';
				break;
			case 13: // transferência de colaboradores
				serviceName = 'MeurhTransferenciaColaboradoresService';
				break;
		}

		return serviceName;
	}

	preencherPropriedadesSolicitacao(entidade: IVisualizarSolicitacao | angular.ui.IStateParamsService): angular.IHttpPromise<any> {
		return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
			let serviceName, service;
			serviceName = this.getServiceName(parseInt(entidade.tiposolicitacao, 10));
			service = this.$injector.get(serviceName);

			service.get(entidade.solicitacao)
				.then((entity: any): void => {
					if ((entity.tiposolicitacao === 7 || entity.tiposolicitacao === 12 || entity.tiposolicitacao === 13) && entity.situacao === 0) {
						entity.situacao_nome = 'Enviada';
					} else if (entity.tiposolicitacao === 9 && entity.situacao === 1) {
						entity.situacao_nome = 'Aprovada';
					} else {
						entity.situacao_nome = this.SITUACOES[entity.situacao];
					}
					entity.tiposolicitacaoNome = this.TIPOS_SOLICITACOES[entity.tiposolicitacao];

					switch (entity.tiposolicitacao) {
						case 0: // admissão
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('criar_admissao');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_admissao');
							break;
						case 1: // rescisão
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('cancelar_rescisao');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_rescisao');
							break;
						case 2: // adiantamento avulso
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_adiantamento_avulso');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_adiantamento_avulso');
							break;
						case 3: // vt adicional
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_vtadicional');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_vtadicional');
							break;
						case 4: // alteração de vt
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_alteracaovt');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_alteracaovt');
							break;
						case 5: // alteração de endereço
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_alteracaodadoscadastrais');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_alteracaodadoscadastrais');
							break;
						case 6: // falta
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_falta');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_falta');
							break;
						case 7: // férias
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_ferias');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('criar_ferias');
							break;
						/*
						case 8: // salário sob demanda
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('visualizar_meurh_solicitacoes_salarios_sob_demanda');
							entity.temAcaoExcluir = false;
							break;
						*/
						case 8: // afastamento
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_afastamentos');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_afastamentos');
							break;
						case 9: // promoção
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_promocoes');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_promocoes');
							break;
						case 10: // créditos e descontos
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_creditosdescontos');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_creditosdescontos');
							break;
						case 12: // admissão completa
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_admissao_comp');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_admissao_comp');
							break;
						case 13: // transferência de colaboradores
							entity.temAcaoRejeitar = this.PermissaoService.temPermissao('rejeitar_transferencias_vagas');
							entity.temAcaoExcluir = this.PermissaoService.temPermissao('excluir_transferencias_vagas');
							break;
					}

					entity.identifier = 'solicitacao';
					entity.rotaRejeitar = 'meurh_solicitacoes_fechar';
					entity.rotaExcluir = 'meurh_solicitacoes_excluir';
					entity.descricao = this.$filter('descricaoSolicitacao')(entity);

					resolve(entity);
				})
				.catch((e: any): void => {
					console.log(e);
				});
		});
	}
}
