import angular from 'angular';
import { IFiltrosAtivos, IFiltro } from './filtros.ativos.model';

export class FiltrosAtivosController {

    static $inject = ['$scope', '$element'];
    public tagContainer: any = angular.element(this.$element).find('.tag-container')[0];
    public tags: IFiltrosAtivos = {};

    constructor(
        public $scope: any,
        public $element: any
    ) {
        $scope.$watch('$ctrl.tags', (): void => {
            this.adicionaTags();
		}, true);
    }

    $onInit(): void {
        // detecção do evento de remoção de uma tag
        this.tagContainer.addEventListener('click', (e: any) => {
            const target = e.target as HTMLTextAreaElement;
            if (target.tagName === 'I' && (target.parentElement && target.parentElement.className === 'tag')) {
                const tagLabel = target.getAttribute('data-item');
                if (Array.isArray(this.tags[<string>tagLabel].valor)) {
                    this.tags[<string>tagLabel].valor = [];
                } else {
                    this.tags[<string>tagLabel].valor = null;
                }
            }
        });
    }

    criaTag(key: string, item: IFiltro): HTMLDivElement {
        const div = document.createElement('div');
        div.setAttribute('class', 'tag');

        let tagLabel: string;
        if (Array.isArray(item.valor)) {
            tagLabel = item.nome + ': ' + item.valor.length;
        } else {
            tagLabel = item.nome + ': 1';
        }

        const span = document.createElement('span');
        span.innerHTML = tagLabel;

        div.appendChild(span);

        const closeIcon = document.createElement('i');
        closeIcon.setAttribute('class', 'fa fa-times');
        closeIcon.setAttribute('data-item', key);
        div.appendChild(closeIcon);

        return div;
    }

    limpaTags(): Promise<void> {
        return new Promise ((resolve, reject) => {
            angular.element(this.$element).find('.tag').remove('.tag');
            resolve();
        });
    }

    adicionaTags(): void {
        this.limpaTags().then(() => {
            Object.entries(this.tags).forEach(([key, item]) => {
                if ((Array.isArray(item.valor) && item.valor.length > 0) ||
                (!Array.isArray(item.valor) && typeof item.valor === 'object' && item.valor !== null)) {
                    this.tagContainer.prepend(this.criaTag(key, item));
                }
            });
        });
    }

    limpaFiltros(): void {
        Object.keys(this.tags).forEach((key) => {
            Array.isArray(this.tags[key].valor) ? this.tags[key].valor = [] : this.tags[key].valor = null;
        });
    }
}
