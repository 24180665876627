import angular from 'angular';
import { MeurhAnexosService } from '../../../../modules/Meurh/Anexos/anexos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { IFalta } from '../models/falta.model';
import { MeurhSolicitacoesFaltasService } from '../solicitacoesfaltas.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';

export class MeurhSolicitacoesfaltasNewController implements angular.IController {
	static $inject = [
		'$scope',
		'$stateParams',
		'$state',
		'MeurhSolicitacoesFaltasService',
		'entity',
		'NewToaster',
		'$rootScope',
		'MeurhAnexosService',
		'TiposDocumentosRequeridosService',
		'ModalConfirmService',
		'$q',
		'$transitions'
	];

	public formAnexo: object = {};
	public editanexo: boolean = true;
	public action: string = 'insert';
	public form: angular.IFormController;
	public tiposdocumentosrequeridos: Array<any>;
	public validate: number = 0; // conta quantos erros há em documentos anexos
	public busy: boolean = false;
	public busyTipoDocumentos: boolean = true;
	public allowRefresh: boolean = false;
    public formchanged: boolean = false;
	public entityAnexo: IAnexo = {
        casodeuso: 'FALTAS'
    };
	public meses: Array<string> = [
		'',
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro'
	];

	constructor(
		public $scope: angular.IScope,
		public $stateParams: angular.ui.IStateParamsService,
		public $state: angular.ui.IStateService,
		public entityService: MeurhSolicitacoesFaltasService,
		public entity: IFalta,
		public NewToaster: any,
		public $rootScope: angular.IRootScopeService & { liberadoTela: (arg: string) => boolean },
		public MeurhAnexosService: MeurhAnexosService & { preencheAnexoLista: () => void },
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
		public ModalConfirmService: any,
        public $q: angular.IQService,
        public $transitions: any
	) {
		if (this.entity.mesdescontocalculo === undefined) {
			this.entity.mesdescontocalculo = (new Date()).getMonth() + 1;
		}

		if (this.entity.anodescontocalculo === undefined) {
			this.entity.anodescontocalculo = (new Date()).getFullYear();
		}
	}

	$onInit(): void {
		this.onSubmitSuccess();
		this.onSubmitError();
		this.documentosAnexos();
		this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
			this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});

		this.$scope.$watch('mrh_slctcsflts_frm_nw_cntrllr.entity.trabalhador', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
        }, true);

		document.querySelector('form')?.addEventListener('change', (e) => {
			this.formchanged = true;
		});
	}

	documentosAnexos(): void {
        if (this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE')) {
            this.$scope.$watch('mrh_slctcsflts_frm_nw_cntrllr.entity.trabalhador', (newValue: any, oldValue: any) => {
                if (newValue && newValue !== oldValue) {
                    const params = {
                        empresa: newValue.empresaobj.empresa,
                        estabelecimento: newValue.estabelecimentoobj.estabelecimento
                    };
                    this.setEntityAnexo({ estabelecimento: params.estabelecimento });
                    this.carregaTiposDocumentosRequeridos(params);
                }
            }, true);
        } else {
            this.carregaTiposDocumentosRequeridos();
        }
    }

	carregaTiposDocumentosRequeridos(params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        this.busyTipoDocumentos = true;
        this.resetTiposDocumentosRequeridosService();
        this.TiposDocumentosRequeridosService.constructors = {
            tiposolicitacao: TipoSolicitacaoEnum.FALTA,
            ...params
        };
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
    }

	resetTiposDocumentosRequeridosService(): void {
		this.tiposdocumentosrequeridos = [];
		this.TiposDocumentosRequeridosService.entities = [];
		this.TiposDocumentosRequeridosService.loadParams = {
			to_load: 3,
			busy: false,
			finished: false
		};
		this.TiposDocumentosRequeridosService.after = {};
	}

	setEntityAnexo(params: { estabelecimento: string }): void {
        this.entityAnexo['estabelecimento'] = params.estabelecimento;
    }

	createEntity() {
		this.entity.datas = [this.entity.datas];
		this.entity.data = this.entity.datas.toString();
		this.entity.situacao = 0;
	}

	submit(): void {
		if (
			this.entity.datas === '' ||
			this.entity.datas === undefined ||
			this.entity.datas.length === 0
		) {
			this.form.datas.$setValidity('required', false);
		}

		let podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
			return (
				tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
				tipodocumentorequerido.fileSelecionado
			);
		});

		if (this.form.$valid && !this.entity.$$__submitting && podeSalvar) {
			let oldDate = this.entity.datas;
			this.createEntity();
			this.busy = true;
			this.allowRefresh = false;
			this.entityService.save(this.entity, {
                empresa: this.entity.trabalhador.empresaobj.empresa,
                estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
            });
			this.entity.datas = oldDate;
		} else {
			if (!podeSalvar) {
				this.NewToaster.pop({
					type: 'error',
					title: 'Há documento(s) obrigatório(s) não informado(s)'
				});
			} else {
				this.NewToaster.pop({
					type: 'error',
					title: 'Ocorreu um erro ao enviar a solicitação de falta!',
					body: 'Por favor, verifique os campos destacados em vermelho.'
				});
			}
		}
	}

	setEditAnexo(value: boolean): void {
		this.editanexo = value;
	}

	formInvalid(): boolean {
		let datasVazias = (this.entity.datas === undefined || this.entity.datas === null || this.entity.datas === '') ? true : false;
		return !this.form.$valid || datasVazias;
	}

	onSubmitSuccess(): void {
		this.$scope.$on('meurh_solicitacoesfaltas_submitted', (event: angular.IAngularEvent, args: any): void => {
			this.entityService.constructors.solicitacao = args.entity.solicitacao;
			this.entityAnexo['solicitacao'] = args.entity.solicitacao;

			this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});
			if (this.tiposdocumentosrequeridos.length <= 0) {
				this.NewToaster.pop({
					type: 'success',
					title: 'A solicitação de falta foi enviada com sucesso!'
				});
				this.$state.go('meurh_solicitacoesfaltas_show', { 'solicitacao': args.entity.solicitacao });
			} else {
				this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
					this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
					if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
						this.NewToaster.pop({
							type: 'success',
							title: 'A solicitação de falta foi enviada com sucesso!'
						});
						this.$state.go('meurh_solicitacoesfaltas_show', { 'solicitacao': args.entity.solicitacao });
					}
				});
			}
		});
	}

	onSubmitError(): void {
		this.$scope.$on('meurh_solicitacoesfaltas_submit_error', (event: angular.IAngularEvent, args: any): void => {
			this.busy = false;
			if (args.response.status === 409) {
				if (confirm(args.response.data.message)) {
					this.entity[''] = args.response.data.entity[''];
					this.entityService.save(this.entity, {
						empresa: this.entity.trabalhador.empresaobj.empresa,
						estabelecimento: this.entity.trabalhador.estabelecimentoobj.estabelecimento
					});
				}
			} else {
				if (
					args.response.data &&
					typeof (args.response.data.message) !== 'undefined' &&
					args.response.data.message
				) {
					if (args.response.data.message === 'Validation Failed') {
						let message = '';
						let children = args.response.data.errors.children;
						for (let child in children) {
							if (children[child].errors !== undefined) {
								for (let error in children[child].errors) {
									if (children[child].errors.hasOwnProperty(error)) {
										message += '<li>' + children[child].errors[error] + '</li>';
									}
								}
							}
						}
						this.NewToaster.pop({
							type: 'error',
							title: 'Erro de validação',
							body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
							bodyOutputType: 'trustedHtml'
						});
					} else {
						this.NewToaster.pop({
							type: 'error',
							title: args.response.data.message
						});
					}
				} else {
					this.NewToaster.pop({
						type: 'error',
						title: 'Ocorreu um erro ao enviar a solicitação de falta!',
						body: 'Por favor, verifique os campos em vermelho.'
					});
				}
			}
		});
	}

	valorExiste(valor: any) {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoesfaltas', angular.extend(this.entityService.constructors));
            }).catch((error: any): void => {/**/});
        } else {
            this.$state.go('meurh_solicitacoesfaltas', angular.extend(this.entityService.constructors));
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                }).catch((error: any): void => {/**/});
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (
				trans._targetState._identifier !== 'meurh_solicitacoesfaltas_new' &&
				this.allowRefresh &&
				this.formchanged
			) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                }).catch(function () {/**/});
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
