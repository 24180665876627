import angular from 'angular';

export const MeurhAlteracaoFotoListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		$stateProvider
			.state('meurh_alteracaofoto', {
				url: '/solicitacoes/alteracaofoto',
				template: require('html-loader!./index/alteracaofoto.index.html'),
				controller: 'MeurhAlteracaoFotoIndexController',
				controllerAs: 'mrh_ltrcft_cntrllr'
			})
			.state('meurh_alteracaofoto_new', {
				url: '/solicitacoes/alteracaofoto/new',
				template: require('html-loader!./new/alteracaofoto.new.html'),
				controller: 'MeurhAlteracaoFotoNewController',
				controllerAs: 'mrh_ltrcft_nw_cntrllr',
			})
            .state('meurh_alteracaofoto_show', {
				url: '/solicitacoes/alteracaofoto/show/:solicitacao',
				template: require('html-loader!./show/alteracaofoto.show.html'),
				controller: 'MeurhAlteracaoFotoShowController',
				controllerAs: 'mrh_ltrcft_shw_cntrllr',
			});
	}
];
