import * as angular from 'angular';
import { MeurhAdmissoesestagiarioValetransporteFormmodalController } from './valetransporte.formmodal.controller';

export class MeurhAdmissoesestagiarioValetransporteFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioValetransporteFormmodalComponent';
	static controller = MeurhAdmissoesestagiarioValetransporteFormmodalController;
	static template = require('!!html-loader!./valetransporte.formmodal.html');
	static transclude = true;
	static bindings = {
		valetransporte: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
