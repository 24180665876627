import * as angular from 'angular';
import { MeurhSolicitacoesalteracoesenderecosShowFormController } from './solicitacoesalteracoesenderecos.show.form.controller';

export class MeurhSolicitacoesalteracoesenderecosShowFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesalteracoesenderecosShowFormComponent';
	static controller = MeurhSolicitacoesalteracoesenderecosShowFormController;
	static template = require('!!html-loader!./solicitacoesalteracoesenderecos.show.form.html');
	static transclude = true;
	static bindings = {
		entity: '<',
		form: '<',
		busy: '=',
		action: '<'
	};
}
