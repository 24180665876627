import angular from 'angular';
import { LoadParams } from '../../../shared/models/loadparams.model';
import { ITrabalhador } from '../../Trabalhadores/models/trabalhador.model';

export class TrabalhadoresPontoService {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];
	public entity: ITrabalhador;
	public entities: Array<ITrabalhador> = [];
	public loaded: boolean = false;
	public constructors: any = {};
	public after: any = {};
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter: any = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IRootScopeService,
		public $q: angular.IQService,
	) {
		this.loadParams = new LoadParams();
	}

	reload(loadAll: boolean = false) {
		if (this.loading_deferred) {
			this.loading_deferred.resolve();
		}

		this.loadParams.finished = false;
		this.loadParams.to_load = 3;
		this.after = {};
		this.loaded = false;
		this.entities.length = 0;
		this.loadParams.busy = false;

		return this.load(loadAll);
	}

	_load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve: any, reject: any) => {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('ponto_trabalhadores_index', angular.extend({}, constructors, {
                    'offset': offset,
                    'filter': filter
                }, this.filters), true),
				timeout: this.loading_deferred.promise
			})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((response: any) => {
				reject(response);
			});
		});
	}

	load(loadAll: boolean = false) {
		if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
			this.loadParams.busy = true;
			this._load(this.constructors, this.after, this.filter)
				.then((data: any): void => {
					if (data.length > 0) {
						for (var i = 0; i < data.length; i++) {
							this.entities.push(data[i]);
						}

						this.after['nome'] = this.entities[this.entities.length - 1]['nome'];
                        this.after['trabalhador'] = this.entities[this.entities.length - 1]['trabalhador'];
					}

					if (data.length < 50) {
						this.loadParams.finished = true;
						if (loadAll) {
							this.$rootScope.$broadcast('ponto_trabalhadores_list_all_finished', this.entities);
						} else {
							this.$rootScope.$broadcast('ponto_trabalhadores_list_finished', this.entities);
						}
					} else {
						this.loadParams.finished = false;
					}

					this.loaded = true;
					if (!loadAll) {
						this.loadParams.to_load--;
					}

                    if (this.loadParams.to_load === 0 || data.length <= 50 && !loadAll) {
                        this.$rootScope.$broadcast('ponto_trabalhadores_list_loaded', this.entities);
                    }
				})
				.catch((error: any): void => {
					if (error.xhrStatus !== 'abort') {
						this.loadParams.finished = true;
                        this.$rootScope.$broadcast('ponto_trabalhadores_get_erro', error);
					}
				})
				.finally((): void => {
					if (this.loaded || this.loadParams.finished) {
						this.loadParams.busy = false;
					}
					this.load(loadAll);
				});
		}

		return this.entities;
	}

	loadMore() {
		this.loadParams.to_load = 3;
		this.load();
	}
}
