import angular = require('angular');
import moment = require('moment');

export class MeurhVagasBeneficiosFormmodalController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'MeurhVagasModalService'];

    public beneficio: any;
    public action: string;
    public tipoPeriodoAnos: Array<any>;
    public tipoPeriodoMeses: Array<any>;
    public tipoPeriodo: Array<any>;

    public dataAtual = new Date();
    public anoAtual = this.dataAtual.getFullYear();

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
    ) {
        this.tipoPeriodo = [
            {
                nome: 'Sempre',
                valor: 0
            }, {
                nome: 'Todo mês(x)',
                valor: 1
            }, {
                nome: 'Entre Mês/Ano',
                valor: 2
            }
        ];
        this.tipoPeriodoMeses = [
            {
                mes: 'Janeiro',
                valor: 1
            }, {
                mes: 'Fevereiro',
                valor: 2
            }, {
                mes: 'Março',
                valor: 3
            }, {
                mes: 'Abril',
                valor: 4
            }, {
                mes: 'Maio',
                valor: 5
            }, {
                mes: 'Junho',
                valor: 6
            }, {
                mes: 'Julho',
                valor: 7
            }, {
                mes: 'Agosto',
                valor: 8
            }, {
                mes: 'Setembro',
                valor: 9
            }, {
                mes: 'Outubro',
                valor: 10
            }, {
                mes: 'Novembro',
                valor: 11
            }, {
                mes: 'Dezembro',
                valor: 12
            }
        ];
        this.tipoPeriodoAnos = [
            this.anoAtual, (this.anoAtual + 1), (this.anoAtual + 2), (this.anoAtual + 3), (this.anoAtual + 4), (this.anoAtual + 5)
        ];
    }

    $onInit(): void {
        if (!this.beneficio.quantidade) {
            this.beneficio.quantidade = 1;
        }
    }

    getQuantidade(event: angular.IAngularEvent & { detail: any }): void {
        this.beneficio.quantidade = event.detail.value;
        this.beneficio.erroQuantidade = event.detail.error;
    }
}
