import angular from 'angular';
import { ISolicitacaoAlteracaoVT } from '../models/solicitacaoalteracaovt.model';
import { MeurhSolicitacoesalteracoesvtsService } from '../solicitacoesalteracoesvts.service';

export class MeurhSolicitacoesalteracoesvtsEditController implements angular.IController {

    static $inject = ['MeurhSolicitacoesalteracoesvtstarifasFormService', '$scope', '$stateParams', '$state', 'MeurhSolicitacoesalteracoesvtsService', 'entity', 'NewToaster', 'ModalConfirmService', '$q', '$transitions'];

    public action = 'update';
    public form: angular.IFormController;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
    constructor(
        public MeurhSolicitacoesalteracoesvtstarifasFormService: any,
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesalteracoesvtsService,
        public entity: ISolicitacaoAlteracaoVT,
        public NewToaster: any,
        public ModalConfirmService: any,
        public $q: any,
        public $transitions: any
    ) {
        $scope.$watch('mrh_slctcsltrcsvts_frm_cntrllr.entity', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);

        this.$scope.$watch('mrh_slctcsltrcsvts_frm_edit_cntrllr.entity', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
        }, true);

        this.onSubmitSuccess();
        this.onSubmitError();
        this.onDeleteSuccess();
        this.onDeleteError();
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    submit(): void {
        if (this.form.$valid && !this.entity.$$__submitting) {
            if (this.entity.solicitacoesalteracoesvtstarifas !== undefined && this.entity.solicitacoesalteracoesvtstarifas.length > 0) {
                if (this.entity.solicitacoesalteracoesvtstarifas.some((tarifa: any) => (tarifa.quantidade === null || tarifa.quantidade === 0))) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Tarifa com quantidade inválida'
                    });
                } else {
                    this.allowRefresh = false;
                    this.entityService.save(this.entity, {
                        empresa: this.entity.empresaobj.empresa,
                        estabelecimento: this.entity.estabelecimento.estabelecimento
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível salvar uma solicitação sem tarifas'
                });
            }
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros'
            });
        }
    }

    goToShow(): void {
        this.$state.go(
            'meurh_solicitacoesalteracoesvts_show',
            angular.extend({ }, { 'solicitacao': this.$stateParams['solicitacao'] })
        );
    }

    delete(force: boolean): void {
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    meurhSolicitacoesalteracoesvtstarifasForm(): void {
        let modal = this.MeurhSolicitacoesalteracoesvtstarifasFormService.open();

        modal.result
            .then((subentity: any): void => {
                if (this.entity.solicitacoesalteracoesvtstarifas === undefined) {
                    this.entity.solicitacoesalteracoesvtstarifas = [subentity];
                } else {
                    this.entity.solicitacoesalteracoesvtstarifas.push(subentity);
                }

                this.form.$setDirty();
            })
            .catch((error: any): void => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_submitted', (event: angular.IAngularEvent, args: any): void => {
            let insertSuccess = 'A Solicitação de Alteração do VT foi enviada com sucesso!';
            let updateSuccess = 'A Solicitação de Alteração do VT foi editada com sucesso.';

            this.NewToaster.pop({
                type: 'success',
                title: args.response.config.method === 'PUT' ? updateSuccess : insertSuccess
            });

            if (args.response.config.method !== 'PUT') {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
            }

            this.$state.go(
                'meurh_solicitacoesalteracoesvts_show',
                angular.extend({ }, { 'solicitacao': this.entity.solicitacao, 'estabelecimento': this.$stateParams['estabelecimento'] })
            );
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity, {
                        empresa: this.entity.empresaobj.empresa,
                        estabelecimento: this.entity.estabelecimento.estabelecimento
                    });
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;

                        children.forEach((child: any): void => {
                            if (children[child].errors !== undefined) {
                                children[child].errors.forEach((error: any): void => {
                                    message += '<li>' + children[child].errors[error] + '</li>';
                                });
                            }
                        });

                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.config.method === 'PUT' ? 'Ocorreu um erro ao alterar a Solicitação' : 'Ocorreu um erro ao enviar a Solicitação'
                    });
                }
            }
        });
    }

    onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A Solicitação de Alteração de VT foi excluída com sucesso!'
            });

            this.$state.go('meurh_solicitacoesalteracoesvts', angular.extend(this.entityService.constructors));
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesalteracoesvts_delete_error', (event: angular.IAngularEvent, args: any): void => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop({
                    type: 'error',
                    title: args.response.data.message
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao excluir a Solicitação.'
                });
            }
        });
    }

    buttonDescartarModal() {
        if (this.formchanged) {
            let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', false);
            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.goToShow();
            })
                .catch((error: any): void => {/* */ });
        } else {
            this.goToShow();
        }
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.formchanged && this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                    .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'meurh_solicitacoesalteracoesvts_edit' && this.allowRefresh && this.formchanged) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                    .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
