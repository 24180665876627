import * as angular from 'angular';
import { MeurhVagasBeneficiosFormmodalController } from './beneficios.formmodal.controller';

export class MeurhVagasBeneficiosFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhVagasBeneficiosFormmodalComponent';
	static controller = MeurhVagasBeneficiosFormmodalController;
	static template = require('!!html-loader!./beneficios.formmodal.html');
	static transclude = true;
	static bindings = {
		beneficio: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
