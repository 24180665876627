import * as angular from 'angular';
import { MeurhAdmissoesestagiarioDependentesFormmodalController } from './dependentes.formmodal.controller';

export class MeurhAdmissoesestagiarioDependentesFormmodalComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioDependentesFormmodalComponent';
	static controller = MeurhAdmissoesestagiarioDependentesFormmodalController;
	static template = require('!!html-loader!./dependentes.formmodal.html');
	static transclude = true;
	static bindings = {
		dependente: '=',
		form: '=',
		busy: '=',
		action: '<',
		objadmissao: '=',
	};
}
