export class MeurhAnexosRotasEnumService {
    static $inject: any = [];

    public rotasAnexo: any = {
        'ADMISSOES': 'meurh_solicitacoesadmissoes_documentos_',
        'RESCISOES': 'meurh_solicitacoesrescisoes_documentos_',
        'VTSADICIONAIS': 'meurh_solicitacoesvtsadicionais_documentos_',
        'ALTERACOESVTS': 'meurh_solicitacoesalteracoesvts_documentos_',
        'SALARIOSOBDEMANDA': 'meurh_solicitacoessalariossobdemanda_documentos_',
        'ALTERACOESENDERECOS': 'meurh_solicitacoesalteracoesenderecos_documentos_',
        'FALTAS': 'meurh_solicitacoesfaltas_documentos_',
        'ADIANTAMENTOSAVULSOS': 'meurh_solicitacoesadiantamentosavulsos_documentos_',
        'FERIAS': 'meurh_solicitacoesferias_documentos_',
        'AFASTAMENTO': 'meurh_solicitacoesafastamentos_documentos_',
        'ADMISSOESCOMPLETAS': 'meurh_solicitacoesadmissoescompleta_documentos_',
        'ADMISSOESESTAGIARIO': 'meurh_solicitacoesadmissoesestagiario_documentos_',
        'INCLUSAODEPENDENTE': 'meurh_solicitacoesinclusaodependentes_documentos_',
        'PROMOCOES': 'meurh_solicitacoespromocoes_documentos_',
        'SOLICITACOESGENERICAS': 'meurh_solicitacoesgenericas_documentos_',
        'ALTERACAODADOSRECEBIMENTO': 'meurh_solicitacoesalteracoestiporecebimento_documentos_',
    };

    rotasAnexoEnum() {
        return this.rotasAnexo;
    }
}
