import angular from 'angular';
import { MeurhMudancaHorarioService } from '../mudancahorario.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IMudancaHorario } from '../models/mudancahorario.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhMudancaHorarioIndexController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$location',
        'MeurhMudancaHorarioService',
        'ListagemService'
    ];

    public entities: Array<IMudancaHorario>;
    public busy: boolean = false;
    public constructors: any = {};
    public filter: any = {};
    public filters: any = {};
    public filterSituacao: string = '';

    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_mudancahorario'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $location: angular.ILocationService,
        public entityService: MeurhMudancaHorarioService,
        public ListagemService: ListagemService
    ) {
        this.entities = this.filterReload();
        this.montaListagemVisibilidade();

        this.$scope.$on('meurh_solicitacoesmudancahorario_get_loading', (event: any, args: any) => {
            this.busy = true;
        });

        this.$scope.$on('meurh_solicitacoesmudancahorario_list_finished', (event: any, args: any) => {
            this.busy = false;
        });

        this.$scope.$on('meurh_solicitacoesmudancahorario_loaded', (event: any, args: any) => {
            this.busy = false;
        });

        this.$scope.$on('meurh_solicitacoesmudancahorario_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.entities = [];
            this.entities = this.entityService.reload();
        });

        this.$scope.$on('meurh_solicitacoesmudancahorario_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            this.entities = [];
            this.entities = this.entityService.reload();
        });
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
    }

    search(): Array<IMudancaHorario> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }
        return this.entityService.search(this.filter);
    }

    filterReload(): Array<IMudancaHorario> {
        delete this.constructors.situacao;
        if (this.filterSituacao) {
            this.constructors.situacao = this.filterSituacao;
        }
        this.entityService.constructors = this.constructors;
        return this.entityService.reload();
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }
}
