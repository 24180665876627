import angular from 'angular';
import { MeurhEscalaFiltrosController } from './escala.filtros.controller';

export class MeurhEscalaFiltrosComponent implements angular.IComponentOptions {
	static selector = 'meurhEscalaFiltrosComponent';
	static controller = MeurhEscalaFiltrosController;
	static template = require('!!html-loader!./escala.filtros.html');
	static transclude = true;
	static bindings = {
        schedulerEventos: '=',
        colaboradores: '=',
		filtrosVisibilidade: '='
	};
}
