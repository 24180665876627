import angular from 'angular';

export const MeurhEscalaListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		$stateProvider
			.state('meurh_escala', {
				url: '/escala',
				template: require('html-loader!./index/escala.index.html'),
				controller: 'MeurhEscalaController',
				controllerAs: 'mrh_scl_cntrllr',
			})
			.state('meurh_escala_new', {
				url: '/escala/adicionar',
				template: require('html-loader!./new/escala.new.html'),
				controller: 'MeurhEscalaNewController',
				controllerAs: 'mrh_scl_nw_cntrllr',
			});
	}
];
