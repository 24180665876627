export class PaginaInicialIndexController {
    static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', 'nsjRouting', '$uibModal', '$log', '$document', 'DepartamentoPessoalService', '$modalInstance'];

    public filterData: any = {};
    public filtroAviso: any = null;
    public totalizadores: any;
    public avisos: any;
    public filtro: any;

    constructor(
        public $rootScope: any,
        public $scope: any,
        public $state: any,
        public $http: any,
        public $stateParams: any,
        public nsjRouting: any,
        public $uibModal: any,
        public $log: any,
        public $document: any,
        public DepartamentoPessoalService: any,
        public $modalInstance: any
    ) {
        if ($rootScope.session.entities.grupoempresarial) {
            this.DepartamentoPessoalService.carregaTotalizadores().then((response: any) => {
                this.totalizadores = response.data;
            });

            this.DepartamentoPessoalService.carregaAvisos().then((response: any) => {
                this.avisos = response.data;
                this.DepartamentoPessoalService.busy = false;
            });
        }

        $scope.$on('getProfile', (event: any, entities: any) => {
            this.DepartamentoPessoalService.constructors = { grupoempresarial: entities.grupoempresarial.grupoempresarial };
            this.DepartamentoPessoalService.carregaTotalizadores().then((response: any) => {
                this.totalizadores = response.data;
            });

            this.DepartamentoPessoalService.carregaAvisos().then((response: any) => {
                this.avisos = response.data;
                this.DepartamentoPessoalService.busy = false;
            });
        });
    }

    isBusy() {
        return this.DepartamentoPessoalService.busy;
    }

    ok() {
        this.$modalInstance.close();
    }

    cancel() {
        this.$modalInstance.dismiss('cancel');
    }

    openAvModal(size: any, parentSelector: any) {
        let modalInstance = this.$uibModal.open({
            templateUrl: 'avModal.html',
            controller: 'ModalInstanceCtrl',
            controllerAs: 'self',
            size: size,
            windowClass: 'center-modal',
            backdrop: true,
            resolve: {}
        });

        modalInstance.result.then(() => {/* */ });
    }

    searchAvisos(aviso: any) {

        if (this.filtroAviso === null) {
            return true;
        }
        if (this.filtroAviso === 'avisos' && aviso.tipo_pendencia === 6) {
            return true;
        } else if (this.filtroAviso === 'pendencias' && aviso.tipo_pendencia !== 6) {
            return true;
        }
    }


    filter() {
        this.DepartamentoPessoalService.avisosFilter = this.filtro;
        this.DepartamentoPessoalService.carregaAvisos().then((response: any) => {
            this.avisos = response.data;
            this.DepartamentoPessoalService.busy = false;
        });
    }
}
