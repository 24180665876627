import * as angular from 'angular';
import { MeurhFeriasmarcaremgradePassodoisController } from './feriasmarcaremgrade.passodois.controller';

export class MeurhFeriasmarcaremgradePassodoisComponent implements angular.IComponentOptions {
	static selector = 'meurhFeriasmarcaremgradePassodoisComponent';
	static controller = MeurhFeriasmarcaremgradePassodoisController;
	static template = require('!!html-loader!./feriasmarcaremgrade.passodois.html');
	static transclude = true;
	static bindings = {
		passo: '=',
		checkboxPreenchidos: '=',
		entitiesSolicitacoes: '=',
		solicitacoesRascunhoDeletadas: '=',
	};
}
