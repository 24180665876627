declare var nsjGlobals: any;
import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { MeurhAdmissaoestagiarioService } from '../admissaoestagiario.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { SituacaoAdmissaoEnum } from '../enums/situacao';
import { TipoRecebimentobolsaEnum } from '../enums/tiporecebimentobolsa.enum';
import { StatusProcessoEnum } from '../enums/statusprocesso.enum';
import { IGestoresTrabalhador } from '../../Transferenciacolaboradores/models/transferenciacolaboradores.model';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';

export class MeurhAdmissaoestagiarioEditController implements ng.IController {
    static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhAdmissaoestagiarioService',
        'entity',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        '$rootScope',
        'ModalConfirmService',
        'MeurhAcompanhamentoService',
    ];

    public constructors: any = {};
    public action = 'update';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: any = {};
    public dataAtual = new Date();
    public matriculaAutomatica: boolean = false;
    public toastController = {
        podeEnviar: false,
        podeEncaminhar: false
    };

    public mostrarAlertaDocumentosAnexos: boolean = false;
    public desativaportrabalhadortemporario: boolean = false;
    public mostraobrigatoriosencaminhar: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public validate: number = 0;
    public busyTipoDocumentos: boolean = true;
    public entityAnexo: IAnexo = {};
    public formAnexo: angular.IFormController;
    public editanexo: boolean = true;
    public adicionarAnexo: boolean = false;
    public actionAnexo: string = 'update';
    public alterouanexo: boolean = false;
    public documentosValidados: boolean = false;
    public clienteAna: boolean;

    private situacaoAdmissaoEnum = SituacaoAdmissaoEnum;
    private tipoRecebimentobolsaEnum = TipoRecebimentobolsaEnum;
    private statusprocessoEnum = StatusProcessoEnum;

    constructor(
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhAdmissaoestagiarioService,
        private entity: any,
        private NewToaster: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public $rootScope: angular.IRootScopeService & {
            nsjGlobals: any,
            configuracoes: any,
            codigoHook: number | string,
            liberadoTela: (param: string) => boolean
            temPermissao: (param: string) => boolean
        },
        private ModalConfirmService: any,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
    ) {

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();

        // documentos / anexos
        this.resetTiposDocumentosRequeridosService();
        this.podeEnviarObserver();
        this.podeEncaminharObserver();
        this.onSubmitSuccess();
        this.onSubmitError();
        this.clienteAna = nsjGlobals.get('clienteana');
        this.entity.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;
    }

    async $onInit(): Promise<void> {
        if (this.$rootScope.configuracoes.CODIGO_FUNCIONARIO_AUTOMATICO === true) {
            this.matriculaAutomatica = true;
        }

        this.entity.tipocategoriatrabalhador = { 'tipocolaborador': '2' }; // tipo estagiário

        this.busy = true;

        this.entityService.getFoto(this.entity.solicitacao)
        .then(() => {
            this.busy = false;
        })
        .catch(() => {
            this.busy = false;
        });

        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo['casodeuso'] = 'ADMISSOESESTAGIARIO';
        this.alteraEntityInicio();

        if (
            this.entity.statusprocesso === this.statusprocessoEnum.ENVIADA ||
            this.entity.statusprocesso === this.statusprocessoEnum.EM_ACOMPANHAMENTO
        ) {
            this.editanexo = false;
        }

        // carregamento dos tipos de documentos requeridos
        await this.TiposDocumentosRequeridosService._load(this.TiposDocumentosRequeridosService.constructors, this.TiposDocumentosRequeridosService.after, '')
        .then((documentosCarregados: any) => {
            this.tiposdocumentosrequeridos = documentosCarregados;
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });

        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível carregar os tipos de documentos',
                body: 'Não foi possível carregar os tipos de documentos requeridos para a admissão',
                timeout: 4000
            });
            this.busyTipoDocumentos = false;
        });

    }

    // início - use cases
    public submit(rascunho: boolean = false): void {
        const customText = this.getMensagensModal(rascunho ? 'rascunho' : 'enviar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                this.form.$submitted = true;

                if (
                    (rascunho && !!this.podeSalvarRascunho()) ||
                    (!this.entity.$$__submitting && this.validaEntityEnviar())
                ) {
                    this.alteraEntityEnviar(rascunho);
                    this.entityService.save(this.entitySave);
                } else {
                    if (!this.validaDocumentosAnexos() && !rascunho) {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Há documento(s) obrigatório(s) não informado(s)'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário apresentam erros',
                            body: 'Favor verificar os campos sinalizados com erro.'
                        });
                    }

                    this.busy = false;
                }
            })
            .catch(function () {/**/ });
    }

    public encaminhar(opt?: string): void {
        const customText = this.getMensagensModal('encaminhar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then(async (): Promise<void> => {
                this.busy = true;
                this.constructors.solicitacao = this.entity.solicitacao;

                await this.entityService.encaminhar(this.entity.solicitacao, opt)
                    .then((encaminharResponse: ng.IHttpResponse<any>) => {
                        const titleMessage = opt
                            ? 'Código gerado com sucesso'
                            : 'A admissão foi encaminhada com sucesso.';

                        const bodyMessage = opt
                            ? 'O código da admissão foi copiado, você já pode colar onde quiser para mandar para o futuro colaborador.'
                            : null;

                        this.NewToaster.pop({
                            type: 'success',
                            title: titleMessage,
                            body: bodyMessage
                        });

                        if (!!encaminharResponse.data.codigoverificacao) {
                            navigator.clipboard.writeText(encaminharResponse.data.codigoverificacao);
                        } else {
                            this.$state.go('meurh_admissaoestagiario_show', this.constructors);
                        }
                    })
                    .catch(() => {
                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Ocorreu um erro ao encaminhar a admissão para o aplicativo'
                        });
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            })
            .catch(() => {/** */ });
    }

    public descartar(): void {
        const customText = this.getMensagensModal('descartar');
        const confirm = this.ModalConfirmService.open(this.entity, 'Admissão', false, customText);

        confirm.result
            .then((): void => {
                this.busy = true;
                this.$state.go('meurh_admissaoestagiario_show', { 'solicitacao': this.entity.solicitacao });
            })
            .catch(function () {/**/ });
    }
    // fim - use cases


    // início - controle de estilização dinâmica
    public getSituacaoLabel(): string {
        if (this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA) {
            return 'label label-aberta';
        }

        if (this.entity.situacao === this.situacaoAdmissaoEnum.ENVIADA) {
            return 'label label-warning';
        }

        if (this.entity.situacao === this.situacaoAdmissaoEnum.EFETUADA) {
            return 'label label-success';
        }

        if (
            this.entity.situacao === this.situacaoAdmissaoEnum.CANCELADA ||
            this.entity.situacao === this.situacaoAdmissaoEnum.EXCLUIDA ||
            this.entity.situacao === this.situacaoAdmissaoEnum.RECUSADA
        ) {
            return 'label label-danger';
        }

        return '';
    }

    public getSituacaoBindTemplate(): string {

        if (this.entity.wkf_estado) {
            return this.entity.wkf_estado;
        }

        switch (this.entity.situacao) {
            case this.situacaoAdmissaoEnum.ABERTA:
                return 'Aberta';

            case this.situacaoAdmissaoEnum.ENVIADA:
                return 'Enviada';

            case this.situacaoAdmissaoEnum.EFETUADA:
                return 'Efetuada';

            case this.situacaoAdmissaoEnum.CANCELADA:
                return 'Cancelada';

            case this.situacaoAdmissaoEnum.EXCLUIDA:
                return 'Excluída';

            case this.situacaoAdmissaoEnum.RECUSADA:
                return 'Recusada';

            default:
                return ' - ';
        }
    }

    public getSituacaoEncaminhadaBindTemplate(): string {
        let stringLabel: string = '';

        if (
            this.entity.situacao !== this.situacaoAdmissaoEnum.EXCLUIDA &&
            (
                this.entity.statusprocesso === this.statusprocessoEnum.ENVIADA ||
                this.entity.statusprocesso === this.statusprocessoEnum.EM_ACOMPANHAMENTO
            )
        ) {
            stringLabel = 'encaminhada';
        }

        return stringLabel;
    }
    // fim - controle de estilização dinâmica


    // início - controle de botões e campos
    public podeEnviar(): boolean {
        const podeEnviar = this.validaEntityEnviar();

        this.toastController.podeEnviar = podeEnviar;

        return podeEnviar;
    }

    public podeSalvarRascunho(): boolean {
        return (
            this.form.nome.$valid &&
            this.validaAmbienteTrabalho() &&
            this.validaDadosBancarios()
        );
    }

    public podeEncaminhar(): boolean {
        const podeEncaminhar = this.mostrarEncaminhar() && this.validaEncaminhar();

        this.toastController.podeEncaminhar = podeEncaminhar;

        return this.toastController.podeEncaminhar;
    }

    public podeEnviarEmail(): boolean {
        return (
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA &&
            this.entity.statusprocesso !== this.statusprocessoEnum.FINALIZADA &&
            this.entity.statusprocesso !== this.statusprocessoEnum.RETOMADA
        );
    }

    public mostrarObrigatoriosEnviar(): void {
        this.form.$setPristine();
        this.form.$setSubmitted();

        this.mostrarAlertaDocumentosAnexos = true;
        this.mostraobrigatoriosencaminhar = false;
    }

    public mostrarEncaminhar(): boolean {
        return (
            this.$rootScope.temPermissao('criar_admissao_comp') &&
            this.$rootScope.liberadoTela('LIBERAR_SOL_ADMISSAO_ENCAMINHAR') &&
            this.entity.statusprocesso !== this.statusprocessoEnum.RETOMADA &&
            this.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
        );
    }

    public mostrarObrigatoriosEncaminhar(): void {
        if (this.form) {
            this.form.$setPristine();
            this.form.matricula.$setDirty();
            this.form.nome.$setDirty();
            this.form.email.$setDirty();
            this.form.categoriatrabalhador.$setDirty();
            this.form.valorbolsa.$setDirty();
            this.form.cargo.$setDirty();
            this.form.nivelcargo.$setDirty();
            this.form.departamento.$setDirty();
            this.form.horario.$setDirty();
            this.form.lotacao.$setDirty();
            this.form.unidadebolsa.$setDirty();

            if (this.entity.vagaAtiva) {
                this.form.vaga.$setDirty();
            } else {
                this.form.estabelecimento.$setDirty();
            }
        }

        this.mostrarAlertaDocumentosAnexos = false;
        this.mostraobrigatoriosencaminhar = true;
    }
    // fim - controle de botões e campos


    // início - validações
    private validaEntityEnviar(): boolean {
        return (
            this.validaDadosGerais() &&
            this.validaEndereco() &&
            this.validaDadosContato() &&
            this.validaDadosPessoais() &&
            this.validaDocumentacao() &&
            this.validaDocumentosAnexos() &&
            this.validaOutrasInformacoes()
        );
    }

    private validaEncaminhar(): boolean {
        return (
            this.validaMatricula() &&
            this.form.nome.$valid &&
            !!this.entity.email &&
            this.validaDadosVaga() &&
            this.validaDadosEmpresa() &&
            this.validaBolsa() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaDadosGerais(): boolean {
        return (
            this.validaIdentificacao() &&
            this.validaDadosVaga() &&
            this.validaAmbienteTrabalho() &&
            this.validaDadosEmpresa() &&
            this.validaContrato() &&
            this.validaPeriodos() &&
            this.validaBolsa() &&
            this.validaOutrasInformacoesGerais()
        );
    }

    private validaIdentificacao(): boolean {
        const emailValid = this.validaEmail();

        return (
            this.validaMatricula() &&
            this.form.nome.$valid &&
            this.form.cpf.$valid &&
            !!this.entity.datanascimento &&
            emailValid
        );
    }

    private validaMatricula(): boolean {
        return (
            this.matriculaAutomatica ||
            !!this.entity.matricula
        );
    }

    private validaEmail(): boolean {
        return (
            !!this.entity.emailcorporativo ||
            !!this.entity.email
        );
    }

    private validaDadosVaga(): boolean {
        return (
            !!this.entity.vagaAtiva === !!this.entity.vaga &&
            !!this.entity.cargo &&
            !!this.entity.nivelcargo
        );
    }

    private validaAmbienteTrabalho(): boolean {
        return (
            !this.entity.ambiente ||
            !!this.entity.condicaoambientetrabalho
        );
    }

    private validaDadosEmpresa(): boolean {
        return (
            !!this.entity.estabelecimento &&
            !!this.entity.departamento &&
            !!this.entity.lotacao
        );
    }

    private validaContrato(): boolean {
        return (
            !!this.entity.datainiciocontrato &&
            !!this.entity.datafimcontrato
        );
    }

    private validaPeriodos(): boolean {
        return !(
            !(!!this.entity.numerodiasperiodo) ||
            this.entity.numerodiasperiodo > 31 ||
            !(!!this.entity.numerohorasmensais) ||
            !(!!this.entity.quantidademediahorassemanais)
        );
    }

    private validaBolsa(): boolean {
        return (
            !!this.entity.valorbolsa &&
            !!this.entity.unidadebolsa
        );
    }

    private validaOutrasInformacoesGerais(): boolean {
        return (
            !!this.entity.categoriatrabalhador &&
            !!this.entity.atividade &&
            !!this.entity.horario
        );
    }

    private validaEndereco(): boolean {
        return (
            !!this.entity.cep &&
            !!this.entity.tipologradouro &&
            !!this.entity.logradouro &&
            !!this.entity.numero &&
            !!this.entity.municipio &&
            !!this.entity.pais
        );
    }

    private validaDadosContato(): boolean {
        return this.validaTelefones();
    }

    private validaTelefones(): boolean {
        return (
            this.validaTelefone() &&
            this.validaCelular()
        );
    }

    private validaTelefone(): boolean {
        return !(!!this.entity.telefone) || this.entity.telefone.length >= 8;
    }

    private validaCelular(): boolean {
        return !(!!this.entity.celular) || this.entity.celular.length >= 8;
    }

    private validaDadosPessoais(): boolean {
        return (
            this.validaNascimento() &&
            this.validaIdentificacaoDadosPessoais()
        );
    }

    private validaNascimento(): boolean {

        let infosValidasPaisEstrangeiro: boolean = false;

        if (this.entity.paisnascimento) {
            if (this.entity.paisnascimento.pais !== '1058') {
                infosValidasPaisEstrangeiro = !!this.entity.datachegadapais && !!this.entity.temporesidenciaestrangeiro && !!this.entity.classificacaoestrangeiro;
            } else if (this.entity.paisnascimento.pais === '1058') {
                infosValidasPaisEstrangeiro = true;
            }
        }

        return (
            !!this.entity.municipionascimento &&
            !!this.entity.paisnascimento &&
            !!this.entity.paisnacionalidade &&
            infosValidasPaisEstrangeiro
        );
    }

    private validaIdentificacaoDadosPessoais(): boolean {

        let validacoesClienteAna = true;

        if (this.clienteAna && !this.entity.semfiliacao) {
            validacoesClienteAna = !!this.entity.nomemae || !!this.entity.nomepai;
        }

        return (
            !!this.entity.sexo &&
            !!this.entity.estadocivil &&
            !!this.entity.raca &&
            !!this.entity.grauinstrucao &&
            validacoesClienteAna
        );
    }

    private validaDocumentacao(): boolean {
        return (
            this.validaDocumentacaoObrigatoria() &&
            this.validaCtps()
        );
    }

    private validaDocumentacaoObrigatoria(): boolean {
        return this.validaNis();
    }

    private validaNis(): boolean {
        if (!!this.entity.nis) {
            var ftap: string = '3298765432';
            var total: number = 0;
            var resto: number = 0;
            var strResto: string = '';
            var numPIS: string = this.entity.nis;

            if (!numPIS) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            }

            for (var i = 0; i <= 9; i++) {
                var resultado: number = parseInt(numPIS.slice(i, i + 1), 10) * parseInt(ftap.slice(i, i + 1), 10);
                total = total + resultado;
            }

            resto = (total % 11);

            if (resto !== 0) {
                resto = 11 - resto;
            }

            if (resto === 10 || resto === 11) {
                strResto = resto + '';
                resto = parseInt(strResto.slice(1, 2), 10);
            }

            if (resto !== parseInt(numPIS.slice(10, 11), 10)) {
                this.form.nis.$error = { 'nisInvalido': true };
                return false;
            } else {
                this.form.nis.$error = { 'nisInvalido': false };
                return true;
            }
        } else {
            this.form.nis.$error = {};
            return true;
        }
    }

    private validaCtps(): boolean {
        return !(!!this.entity.numeroctps && !(!!this.entity.ufctps));
    }

    private validaDocumentosAnexos(): boolean {
        if (this.busyTipoDocumentos) {
            return false;
        } else {
            return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
                return (tipodocumentorequerido.fileSelecionado === ( tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado));
            });
        }
    }

    private validaOutrasInformacoes(): boolean {
        return (
            this.validaInformacoesGerais() &&
            this.validaFerias() &&
            this.validaRecebimento() &&
            this.validaEstagio()
        );
    }

    private validaInformacoesGerais(): boolean {
        return (
            !!this.entity.datavencimentoatestadomedico &&
            !!this.form.percentualadiantamento.$error &&
            this.validaPercentualAdiantamento(this.entity.percentualadiantamento)
        );
    }

    private validaPercentualAdiantamento(percentual: number): boolean {
        const PERCENTUAL_MIN = 0;
        const PERCENTUAL_MAX = 0.4;

        return percentual >= PERCENTUAL_MIN && percentual <= PERCENTUAL_MAX;
    }

    private validaFerias(): boolean {
        return !!this.entity.inicioperiodoaquisitivoferias;
    }

    private validaRecebimento(): boolean {
        return (
            this.entity.tiporecebimentobolsa &&
            this.validaDadosBancarios() &&
            this.validaDadosPix()
        );
    }

    private validaDadosBancarios(): boolean {
        if (
            !this.entity.semdadosbancarios &&
            (
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_CORRENTE ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_POUPANCA ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.CONTA_SALARIO ||
                this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_AGENCIA_CONTA
            )
        ) {

            let tipoContaPixValida: boolean = true;

            if (this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_AGENCIA_CONTA && !(!!this.entity.tipocontapix)) {
                tipoContaPixValida = false;
            }

            return (
                !!this.entity.banco &&
                (!!this.entity.agencia || !!this.entity.agencianumero) &&
                !!this.entity.numerocontasalario &&
                !!this.entity.numerocontasalariodv &&
                tipoContaPixValida
            );
        }
        return true;
    }

    private validaDadosPix(): boolean {
        if (
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CPF ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_EMAIL ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CELULAR ||
            this.entity.tiporecebimentobolsa === this.tipoRecebimentobolsaEnum.PIX_CHAVE_ALEATORIA
        ) {
            return !!this.entity.chavepix;
        }
        return true;
    }

    private validaEstagio(): boolean {
        return (
            !!this.entity.nivelestagio &&
            !!this.entity.instituicaoensino
        );
    }
    // fim - validações


    // início - observers
    private podeEnviarObserver(): void {
        this.$scope.$watch('mrh_admssstgr_frm_edit_cntrllr.toastController.podeEnviar', function (newValue: any, oldValue: any) {

            let documentosValidos: boolean = this.busyTipoDocumentos ? false : (this.tiposdocumentosrequeridos.length > 0 ? this.validaDocumentosAnexos() : true);

            if (newValue === true && this.entity.situacao === -1 && documentosValidos) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser enviada',
                    body: 'Todos os dados obrigatórios já foram informados.',
                    timeout: 4000
                });
            }
        }.bind(this), true);
    }

    private podeEncaminharObserver(): void {
        this.$scope.$watch('mrh_admssstgr_frm_edit_cntrllr.toastController.podeEncaminhar', function (newValue: any, oldValue: any) {
            if (!!newValue && this.entity.statusprocesso === this.statusprocessoEnum.NAO_ENCAMINHADA) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A admissão já pode ser encaminhada',
                    body: 'Já é possível encaminhar a admissão para o futuro colaborador informar os dados pelo app.',
                    timeout: 4000
                });
            }
        }.bind(this), true);
    }

    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submitted', (event: angular.IAngularEvent, args: any): void => {
            try {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
                this.entityAnexo['solicitacao'] = args.entity.solicitacao;
                this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
                this.entityAnexo['casodeuso'] = 'solicitacoesadmissoesestagiario';
                this.constructors.solicitacao = args.entity.solicitacao;
                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

                const tituloToast = args.entity.situacao === this.situacaoAdmissaoEnum.ABERTA
                    ? 'A admissão foi editada com sucesso.'
                    : 'A admissão foi enviada com sucesso.';

                if (
                    this.tiposdocumentosrequeridos.length <= 0 ||
                    this.alterouanexo === false
                ) {

                    this.NewToaster.pop({
                        type: 'success',
                        title: tituloToast
                    });

                    this.$state.go('meurh_admissaoestagiario_show', this.constructors);
                } else {
                    this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                        this.$scope.$apply();
                        if (
                            this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult) &&
                            !this.documentosValidados
                        ) {
                            this.documentosValidados = true;

                            this.NewToaster.pop({
                                type: 'success',
                                title: tituloToast
                            });

                            this.$state.go('meurh_admissaoestagiario_show', this.constructors);
                        }
                    });
                }
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro',
                    body: message
                });

                this.busy = false;
                this.$state.go('meurh_admissaoestagiario_show', this.constructors);
            }
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesestagiario_submit_error', (event: angular.IAngularEvent, args: any): void => {
            let mensagemErro = '';
            const tituloErro = args.entity.situacao === -1
                ? 'Ocorreu um erro ao editar a admissão'
                : 'Ocorreu um erro ao enviar a admissão';

            try {
                if (!!args.response && !!args.response.data) {
                    if (!!args.response.data.errors && !!args.response.data.errors.children) {

                        // caso seja um array
                        if (Array.isArray(args.response.data.errors.children)) {
                            args.response.data.errors.children.forEach((element: any) => {
                                mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                            });
                        } else {
                            // caso seja um objeto
                            for (const campo in args.response.data.errors.children) {
                                if (args.response.data.errors.children[campo]['errors']) {
                                    args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                        mensagemErro = mensagemErro + '&bull; ' + element + '<br>';
                                    });
                                }
                            }
                        }

                    } else if (args.response.data && args.response.data.message) {
                        mensagemErro = '&bull; ' + args.response.data.message + '<br>';
                    }
                }

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: mensagemErro,
                    bodyOutputType: 'trustedHtml'
                });
            } catch (error) {
                const message = error.message ? error.message : null;

                this.NewToaster.pop({
                    type: 'error',
                    title: tituloErro,
                    body: message
                });
            } finally {
                this.busy = false;
            }
        });
    }
    // fim - observers


    // inicio - tratamentos de dados
    private alteraEntityInicio(): void {
        // dados gerais
        this.entity.unidadebolsa = this.entity.unidadebolsa
            ? this.entity.unidadebolsa.toString()
            : this.entity.unidadebolsa;
        this.entity.atividade = this.entity.atividade
            ? this.entity.atividade.toString()
            : this.entity.atividade;

        // contrato
        this.entity.tipoatividade = this.entity.tipoatividade
            ? this.entity.tipoatividade.toString()
            : this.entity.tipoatividade;
        this.entity.tiporegimetrabalhista = this.entity.tiporegimetrabalhista
            ? this.entity.tiporegimetrabalhista.toString()
            : this.entity.tiporegimetrabalhista;

        // dados pessoais
        this.entity.sexo = this.entity.sexo
            ? this.entity.sexo.toString()
            : this.entity.sexo;
        this.entity.estadocivil = this.entity.estadocivil
            ? this.entity.estadocivil.toString()
            : this.entity.estadocivil;
        this.entity.grauinstrucao = this.entity.grauinstrucao
            ? this.entity.grauinstrucao.grauinstrucao
            : null;

        // documentacao
        this.entity.categoriacnh = this.entity.categoriacnh
            ? this.entity.categoriacnh.toString()
            : this.entity.categoriacnh;

        // outras informacoes
        this.entity.agencianumero = this.entity.agencia ? this.entity.agencia.agencianumero : this.entity.agencianumero;
        this.entity.tiporecebimentobolsa = (
            this.entity.tiporecebimentobolsa !== undefined &&
            this.entity.tiporecebimentobolsa !== null
        )
            ? this.entity.tiporecebimentobolsa.toString()
            : this.entity.tiporecebimentobolsa;
        this.entity.nivelestagio = this.entity.nivelestagio
            ? this.entity.nivelestagio.toString()
            : this.entity.nivelestagio;

        this.entity.tipocontapix = this.entity.tiporecebimentobolsa === '64' && this.entity.tipocontapix ? this.entity.tipocontapix.toString() : null;
        this.entity.percentualadiantamento /= 100;

        // dependentes
        if (this.entity.dependentes && this.entity.dependentes.length > 0) {
            this.entity.dependentes.forEach((element: any) => {
                element.percentualpensaoalimenticia /= 100;
                element.percentualpensaoalimenticiafgts /= 100;
            });
        }

        this.entity.temporesidenciaestrangeiro = !!this.entity.temporesidenciaestrangeiro ? this.entity.temporesidenciaestrangeiro.toString() : this.entity.temporesidenciaestrangeiro;
        this.entity.classificacaoestrangeiro = !!this.entity.classificacaoestrangeiro ? this.entity.classificacaoestrangeiro.toString() : this.entity.classificacaoestrangeiro;
    }

    private alteraEntityEnviar(rascunho: boolean = false): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = rascunho ? -1 : 0;

        if (!this.entitySave.horarionoturnojornadasemanal) {
            this.entitySave.horarionoturnojornadasemanal = true;
        }

        this.entitySave.gestores = [];
        if (this.entity.gestores) {
            this.entity.gestores.map((gestor: IGestoresTrabalhador) => {
                if (gestor.gestor) {
                    this.entitySave.gestores?.push({ 'gestor': gestor.gestor });
                } else {
                    this.entitySave.gestores?.push({ 'identificacaonasajongestor': gestor.email });
                }
            });
        }
        this.entitySave.gestores = JSON.stringify(this.entitySave.gestores);

        this.entitySave.vaga = this.entitySave.vaga
            ? this.entitySave.vaga.vaga
            : null;
        this.entitySave.estabelecimento = this.entitySave.estabelecimento
            ? this.entitySave.estabelecimento.estabelecimento
            : null;

        this.entitySave.ambiente = !!this.entitySave.ambiente
            ? this.entitySave.ambiente.ambiente
            : null;

        this.entitySave.condicaoambientetrabalho = this.entitySave.condicaoambientetrabalho
            ? this.entitySave.condicaoambientetrabalho.condicaoambientetrabalho
            : null;

        this.entitySave.nivelcargo = this.entitySave.nivelcargo
            ? this.entitySave.nivelcargo.nivelcargo
            : null;
        this.entitySave.departamento = this.entitySave.departamento
            ? this.entitySave.departamento.departamento
            : null;
        this.entitySave.lotacao = this.entitySave.lotacao
            ? this.entitySave.lotacao.lotacao
            : null;
        this.entitySave.grupodeponto = this.entitySave.grupodeponto
            ? this.entitySave.grupodeponto.grupo
            : null;
        this.entitySave.horario = this.entitySave.horario
            ? this.entitySave.horario.horario
            : null;
        this.entitySave.categoriatrabalhador = this.entitySave.categoriatrabalhador
            ? this.entitySave.categoriatrabalhador.categoriatrabalhador
            : null;
        this.entitySave.municipionascimento = this.entitySave.municipionascimento
            ? this.entitySave.municipionascimento.ibge
            : null;
        this.entitySave.paisnascimento = this.entitySave.paisnascimento
            ? this.entitySave.paisnascimento.pais
            : null;
        this.entitySave.paisnacionalidade = this.entitySave.paisnacionalidade
            ? this.entitySave.paisnacionalidade.pais
            : null;
        this.entitySave.raca = this.entitySave.raca
            ? this.entitySave.raca.raca
            : null;
        this.entitySave.tipologradouro = this.entitySave.tipologradouro
            ? this.entitySave.tipologradouro.tipologradouro
            : null;
        this.entitySave.municipio = this.entitySave.municipio
            ? this.entitySave.municipio.ibge
            : null;
        this.entitySave.pais = this.entitySave.pais
            ? this.entitySave.pais.pais
            : null;
        this.entitySave.percentualadiantamento *= 100;
        this.entitySave.instituicaoensino = this.entitySave.instituicaoensino
            ? this.entitySave.instituicaoensino.instituicao
            : null;
        this.entitySave.instituicaointegradora = this.entitySave.instituicaointegradora
            ? this.entitySave.instituicaointegradora.instituicao
            : null;

        this.entitySave.datainiciocontrato = this.entitySave.datainiciocontrato
            ? moment(this.entitySave.datainiciocontrato).format('YYYY-MM-DD')
            : null;
        this.entitySave.inicioperiodoaquisitivoferias = this.entitySave.inicioperiodoaquisitivoferias
            ? moment(this.entitySave.inicioperiodoaquisitivoferias).format('YYYY-MM-DD')
            : null;
        this.entitySave.dataexpedicaorg = this.entitySave.dataexpedicaorg
            ? moment(this.entitySave.dataexpedicaorg).format('YYYY-MM-DD')
            : null;
        this.entitySave.dataexpedicaoctps = this.entitySave.dataexpedicaoctps
            ? moment(this.entitySave.dataexpedicaoctps).format('YYYY-MM-DD')
            : null;
        this.entitySave.datafimcontrato = this.entitySave.datafimcontrato
            ? moment(this.entitySave.datafimcontrato).format('YYYY-MM-DD')
            : null;
        this.entitySave.datanascimento = this.entitySave.datanascimento
            ? moment(this.entitySave.datanascimento).format('YYYY-MM-DD')
            : null;
        this.entitySave.unidadebolsa = this.entitySave.unidadebolsa
            ? this.entitySave.unidadebolsa
            : null;

        this.entitySave.agencia = null;
        if (
            this.entitySave.tiporecebimentobolsa === undefined ||
            this.entitySave.tiporecebimentobolsa === null
        ) {
            delete this.entitySave.tiporecebimentobolsa;
            delete this.entitySave.tipocontapix;
        } else if (this.entitySave.tiporecebimentobolsa === '64') {
            this.entitySave.tipocontapix = parseInt(this.entitySave.tipocontapix, 10);
        }

        if (this.entitySave.banco) {
            this.entitySave.banco = this.entitySave.banco.banco
                ? this.entitySave.banco.banco
                : null;
        }

        // benefícios
        if (this.entitySave.beneficios) {
            this.entitySave.beneficios.forEach((element: any) => {
                element.lotacao = angular.copy(element.lotacao.lotacao);
                element.instituicao = element.instituicao
                    ? angular.copy(element.instituicao.instituicao)
                    : element.instituicao;
                if (element.usobeneficio === 2) {
                    element.cpfdependente = element.dependenteSelecionado.cpf;
                }

                if (element.usobeneficio === 1 && element.cpfdependente != null) {
                    element.cpfdependente = element.dependente = null;
                }
            });

            this.entitySave.beneficios = JSON.stringify(this.entitySave.beneficios);
        }

        // dependentes
        if (this.entitySave.dependentes) {
            this.entitySave.dependentes.forEach((element: any) => {
                element.datainclusao = element.datainclusao
                    ? element.datainclusao
                    : null;
                element.databaixaimpostorenda = element.databaixaimpostorenda
                    ? element.databaixaimpostorenda
                    : null;
                element.vencimentoescolar = element.vencimentoescolar
                    ? element.vencimentoescolar
                    : null;
                element.vencimentovacinacao = element.vencimentovacinacao
                    ? element.vencimentovacinacao
                    : null;
                element.agencia = element.agencia
                    ? angular.copy(element.agencia.agencia)
                    : element.agencia;

                element.percentualpensaoalimenticia *= 100;
                element.percentualpensaoalimenticiafgts *= 100;
                element.matricula = element.matricula.toUpperCase();
            });

            this.entitySave.dependentes = JSON.stringify(this.entitySave.dependentes);
        }

        // vale de transporte
        if (this.entitySave.vts) {
            this.entitySave.vts.forEach((element: any) => {
                element.tarifaconcessionariavt = angular.copy(element.tarifaconcessionariavt.tarifaconcessionariavt);
            });

            this.entitySave.vts = JSON.stringify(this.entitySave.vts);
        }

        this.entitySave.temporesidenciaestrangeiro = !!this.entitySave.temporesidenciaestrangeiro ? parseInt(this.entitySave.temporesidenciaestrangeiro, 10) : null;
        this.entitySave.classificacaoestrangeiro = !!this.entitySave.classificacaoestrangeiro ? this.entitySave.classificacaoestrangeiro : null;
    }

    private getMensagensModal(tipoMensagem: 'rascunho' | 'enviar' | 'encaminhar' | 'descartar'): object {
        const msgAlerta = this.entity.statusprocesso === 0
            ? 'Depois de enviada a admissão, não será possível editá-la.'
            : 'Depois de enviada a admissão, não será possível editá-la e nem o futuro colaborador concluir o informe dos dados.';

        const customTexts = {
            rascunho: {
                title: 'Salvar admissão',
                confirmText: 'Você tem certeza que deseja salvar a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, salvar',
            },
            enviar: {
                title: 'Enviar admissão',
                subtitle: msgAlerta,
                confirmText: 'Você tem certeza que deseja enviar a admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, enviar',
            },
            descartar: {
                title: 'Descartar edição',
                subtitle: 'Todos os dados informados serão perdidos.',
                confirmText: 'Você tem certeza que deseja descartar as alterações feitas na admissão de ' + this.entity.nome + '?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, descartar',
            },
            encaminhar: {
                title: 'Encaminhar admissão',
                alertInfo: {
                    header: 'Sobre a admissão',
                    text: 'Após você encaminhar a admissão, os campos que o futuro colaborador pode preencher através do aplicativo ficarão bloqueados para edição até o momento que o mesmo termine de preencher os dados, ou até que seja necessário retomar a admissão.'
                },
                confirmText: 'Você tem certeza que deseja encaminhar a admissão para ' + this.entity.nome + ' informar os dados pelo aplicativo?',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, encaminhar'
            }
        };

        return customTexts[tipoMensagem];
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.ADMISSOESESTAGIARIO };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    // fim - tratamentos de dados
}
