import angular from 'angular';
import { MeurhAdmissoesValetransportepersonalizadoFormController } from './valetransportepersonalizado.form.controller';

export class MeurhAdmissoesValetransportepersonalizadoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesValetransportepersonalizadoFormComponent';
	static controller = MeurhAdmissoesValetransportepersonalizadoFormController;
	static template = require('!!html-loader!./valetransportepersonalizado.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
