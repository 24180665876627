import angular = require('angular');
import { MeurhAnexosService } from '../../Anexos/anexos.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { DocumentosRotasEnum } from '../../../../shared/enums/DocumentosRotasEnum';
import { ISolicitacaoAdmissao } from '../models/solicitacaoadmissao.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { MeurhSolicitacoesadmissoesService } from '../solicitacoesadmissoes.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';

export class MeurhSolicitacoesadmissoesNewController implements ng.IController {
    static $inject = ['$scope', '$stateParams', '$state', 'MeurhSolicitacoesadmissoes', 'entity', 'NewToaster', '$rootScope', '$sce', 'ClientSession', 'MeurhAnexosService', 'TiposDocumentosRequeridosService', 'ModalConfirmService'];

    public busy: boolean = false;
    public busyTipoDocumentos: boolean = true;
    public action: string = 'insert';
    public rascunhoSendoCriado: boolean = false;
    public form: angular.IFormController;
    public constructors: any = [];
    public formAnexo: any = {};
    public entityAnexo: IAnexo = {};
    public actionAnexo: string = 'insert';
    public entityAnexoLista: Array<IAnexo> = [];
    public mimeType: string;
    public adicionarAnexo: boolean = false;
    public salarioValido: boolean = true;
    public editanexo: boolean = true;
	public tiposdocumentosrequeridos: Array<any>;
	public validate: number = 0; // conta quantos erros há em documentos anexos

    constructor(
        public $scope: angular.IScope,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhSolicitacoesadmissoesService,
        public entity: ISolicitacaoAdmissao,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { codigoHook: number | string },
        public $sce: angular.ISCEService,
        public ClientSession: any,
        public MeurhAnexosService: MeurhAnexosService,
		public TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        public ModalConfirmService: any
    ) {
        this.ClientSession.load();
        this.$rootScope.codigoHook = this.entity.codigo;
    }

    $onInit(): void {
        this.busy = true;

        this.criaRascunho();
        this.onSubmitSuccess();
        this.onDeleteSuccess();
        this.onDeleteError();
        this.onSubmitError();
        this.onDeleteRascunhoSuccess();
        this.onSubmitAnexoSuccess();
        this.resetTiposDocumentosRequeridosService();
		this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

		this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
			this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
				tipodocumentorequerido.validateResult = false;
				tipodocumentorequerido.fileSelecionado = false;
				return tipodocumentorequerido;
			});
		});
    }

    resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.ADMISSAO_PRELIMINAR };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    setAdicionarAnexo(value: boolean): void {
        this.adicionarAnexo = value;
    }

    submit(rascunho: boolean = false): void {
        if (rascunho) {
            this.entityService.constructors.situacao = -1;
            this.entityService.save(this.entity);
        } else {
            let podeSalvar = this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
				return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
			});

            if (this.form.$valid && !this.entity.$$__submitting && podeSalvar) {
                if (rascunho === false && this.entity.situacao === -1) {
                    this.entityService.constructors.situacao = -1;
                }

                if (this.entity.solicitacoesadmissoesdocumentos) {
                    let solicitacoesadmissoesdocumentos = this.entity.solicitacoesadmissoesdocumentos;
                    delete this.entity.solicitacoesadmissoesdocumentos;
                }
                this.entity['solicitacao'] = this.entityAnexo['solicitacao'];
                this.entityService.save(this.entity);

                this.entityService.constructors.situacao = 0;
                this.entity.situacao = 0;
            } else {
                if (!podeSalvar) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Há documento(s) obrigatório(s) não informado(s)'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Alguns campos do formulário apresentam erros'
                    });
                }
            }
        }
    }

    criaRascunho(): void {
        this.rascunhoSendoCriado = true;
        this.entity.situacao = -1;

        this.submit(true);
    }

    descartar(): void {
        if ( this.form.cargo.$dirty === true ||
        this.form.cpf.$dirty === true ||
        this.form.dataadmissao.$dirty === true ||
        this.form.datanascimento.$dirty === true ||
        this.form.departamento.$dirty === true ||
        this.form.horario.$dirty === true ||
        this.form.nome.$dirty === true ) {
            let textos = {
                title: 'Descartar Admissão Preliminar',
                subtitle: 'Após o descarte não será mais possível reverter a ação.',
                confirmText: 'Você tem certeza que deseja descartar as alterações no formulário da Admissão Preliminar',
                confirmTextNum: '',
                closeButton: 'Cancelar',
                confirmButton: 'Sim, descartar alterações',
            };
            let confirm = this.ModalConfirmService.open(this.entity, 'Admissão Preliminar', false, textos);

            confirm.result.then((): void => {
                this.busy = true;
                this.apagaSolicitacaoRascunho();
                this.$state.go('meurh_admissaocompleta', {});
            })
            .catch((error: any): void => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
        } else {
            this.busy = true;
            this.apagaSolicitacaoRascunho();
            this.$state.go('meurh_admissaocompleta', {});
        }
	}

    apagaSolicitacaoRascunho(): void {
        this.$state.go('meurh_admissaocompleta', this.entityService.constructors);
        let docs = this.entityAnexoLista;

        this.constructors = [];
        this.constructors['rotaAnexo'] = DocumentosRotasEnum.ADMISSAO;
        this.constructors['id'] = this.entity.solicitacao;
        this.constructors['solicitacao'] = this.entity.solicitacao;

        this.MeurhAnexosService.deleteAllAnexos(this.constructors);
        this.entityService.delete(this.constructors, true);
    }

    onDeleteRascunhoSuccess(): void {
        this.$scope.$on('meurh_solicitacaodocumento_deleted_All', (event: angular.IAngularEvent, args: any): void => {
            this.entityService.delete(this.constructors, true);
        });
    }

    onSubmitAnexoSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoesdocumentos_create_success', (event: angular.IAngularEvent, args: any): void => {
            this.setAdicionarAnexo(false);
        });
    }

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_submitted', (event: angular.IAngularEvent, args: any): void => {
            if (this.rascunhoSendoCriado) {
                this.rascunhoSendoCriado = false;
                this.entity = args.response.data;
                this.$rootScope.codigoHook = this.entity.codigo;
                this.entityAnexo['solicitacao'] = this.entity.solicitacao;
                this.entityAnexo['casodeuso'] = 'ADMISSOES';
                this.busy = false;
            } else {
                this.busy = true;
				this.entityService.constructors.solicitacao = args.entity.solicitacao;

                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

                if (this.tiposdocumentosrequeridos.length <= 0) {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'Sucesso ao inserir Solicitação de Admissão Preliminar!'
                    });

                    this.busy = false;
                    this.$state.go('meurh_solicitacoesadmissoes_show', this.entityService.constructors);
                } else {
                    this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                        this.$scope.$apply(); // para atualizar os valores dos validateResult vindos do componente anexo
                        if (this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult)) {
                            this.NewToaster.pop({
                                type: 'success',
                                title: 'Sucesso ao inserir Solicitação de Admissão Preliminar!'
                            });

                            this.busy = false;
                            this.$state.go('meurh_solicitacoesadmissoes_show', this.entityService.constructors);
                        }
                    });
                }
            }
        });
    }

    onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.$state.go('meurh_solicitacoesadmissoes', this.entityService.constructors);
        });
    }

    onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_delete_error', (event: angular.IAngularEvent, args: any): void => {
            this.$state.go('meurh_solicitacoesadmissoes', this.entityService.constructors);
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesadmissoes_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity);
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        let message = '';
                        let children = args.response.data.errors.children;

                        for (let child in children) {
                            if (children[child].errors !== undefined) {
                                for (let error in children[child].errors) {
                                    if (children[child].errors.hasOwnProperty(error)) {
                                        message += '<li>' + children[child].errors[error] + '</li>';
                                    }
                                }
                            }
                        }

                        this.NewToaster.pop({
                            type: 'error',
                            title: 'Erro de Validação',
                            body: 'Os seguintes itens precisam ser alterados: <ul>' + message + '</ul>',
                            bodyOutputType: 'trustedHtml'
                        });
                    } else {
                        this.NewToaster.pop({
                            type: 'error',
                            title: args.response.data.message
                        });
                    }
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao inserir Solicitação de Admissão Preliminar!'
                    });
                }
            }
        });
    }

    trustUrl(subentityURL: any): string {
        let url = this.$sce.trustAsResourceUrl(subentityURL);
        return url;
    }

    transformUrl(): void {
        this.entity.solicitacoesadmissoesdocumentos.forEach((element: any): void => {
            element.url = this.trustUrl(element.url);
        });
    }
}
