import ng from 'angular';
import moment from 'moment';
import { IWorkflowAcao } from '../../models/workflow.acao.model';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';

export class WorkflowDynamicModalController implements ng.IController {

    static $inject = [
        'acao',
        'trabalhador',
        '$uibModalInstance',
        'NewToaster'
    ];

    public dataMinimaProrrogacao: moment.Moment;
    public dataMaximaProrrogacao: moment.Moment;
    public formData: ng.IFormController;

    private toastTimeout: number = 8000;

    constructor(
        public acao: IWorkflowAcao,
        public trabalhador: ITrabalhador,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        private NewToaster: any
    ) {
        this.tratarTextosModal();
        if (this.trabalhador?.categoriatrabalhador === '105' || this.trabalhador?.categoriatrabalhador === '106') {
            this.setDates();
        }
    }

    public confirmar(): void {
        if (this.formData.$valid) {
            this.$uibModalInstance.close(this.acao);
            return;
        }

        this.NewToaster.pop({
            type: 'error',
            tittle: 'Ocorreu um erro ao enviar o formulário',
            timeout: this.toastTimeout
        });
    }

    public close(): void {
        this.$uibModalInstance.dismiss('fechar');
    }

    private tratarTextosModal(): void {
        this.tratarNomeColaborador(this.acao.modal!.formulario!.textos_acima);
        this.tratarNomeColaborador(this.acao.modal!.formulario!.textos_abaixo);
    }

    private tratarNomeColaborador(textos: string[]): void {
        textos.forEach((texto: string, index: number) => {
            const textoModificado: string = texto.replace('{{nome_colaborador}}', this.trabalhador?.nome);
            textos[index] = textoModificado;
        });
    }

    private setDates(): void {
        this.dataMinimaProrrogacao = moment(this.trabalhador?.datafimcontrato || '').add(1, 'days');
        this.acao['dataprorrogacaocontrato'] = this.dataMinimaProrrogacao;

        this.dataMaximaProrrogacao = this.trabalhador?.categoriatrabalhador === '105'
            ? moment(this.trabalhador?.dataadmissao!).add(730, 'days')
            : moment(this.trabalhador?.dataadmissao!).add(270, 'days');
    }
}
