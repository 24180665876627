import angular = require('angular');
import moment = require('moment');

export class MeurhAdmissoesestagiarioDadoscontatoFormController implements ng.IController {

    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$rootScope', '$sce', 'Paises'];

    public entity: any;
    public form: any;
    public invalidoTelefone: boolean = false;
    public invalidoCelular: boolean = false;

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: angular.IRootScopeService & { configuracoes: any, session: any },
        public $sce: angular.ISCEService,
        public Paises: any,
    ) {

    }

    preenchePix(): void {
        if (this.entity.tiporecebimentobolsa === '256') { // email
            this.entity.chavepix = this.entity.email;
        } else if (this.entity.tiporecebimentobolsa === '512' && this.valorExiste(this.entity.dddcel) && this.valorExiste(this.entity.celular)) { // celular
            this.entity.chavepix = this.entity.dddcel + this.entity.celular;
        }
    }

    verificaTamanhotelefone(telefone: any, movel: boolean = false): void {
        if (telefone && telefone.length < 8) {
            if (movel === true) {
                this.invalidoCelular = true;
            } else {
                this.invalidoTelefone = true;
            }
        } else {
            if (movel === true) {
                this.invalidoCelular = false;
            } else {
                this.invalidoTelefone = false;
            }
        }
    }

    valorExiste(valor: any) {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
