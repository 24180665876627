import * as angular from 'angular';
import { MeurhAdmissoesestagiarioDadospessoaisFormController } from './dadospessoais.form.controller';

export class MeurhAdmissoesestagiarioDadospessoaisFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioDadospessoaisFormComponent';
	static controller = MeurhAdmissoesestagiarioDadospessoaisFormController;
	static template = require('!!html-loader!./dadospessoais.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
