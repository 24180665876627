import * as angular from 'angular';
import { MeurhAdmissoesestagiarioBeneficiosFormController } from './beneficios.form.controller';

export class MeurhAdmissoesestagiarioBeneficiosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesestagiarioestagiarioBeneficiosFormComponent';
	static controller = MeurhAdmissoesestagiarioBeneficiosFormController;
	static template = require('!!html-loader!./beneficios.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
