import angular from 'angular';
import { ApontamentosImportacaoService } from '../apontamentosimportacao.service';
import { ApontamentosImportacaoModalService } from '../modal.importar/apontamentosimportacao.importarmodal.service';
import { ApontamentosResultadoModalService } from '../modal.resultado/apontamentosimportacao.resultadomodal.service';
import { IApontamentoImportacao, IApontamentoImportacaoArquivo } from '../models/apontamentosimportacao.model';
import { OperacaoApontamentosImportacaoEnum } from '../enums/operacao';
import { ResultadoApontamentosImportacaoEnum } from '../enums/resultado';

export class ApontamentosImportacaoIndexController {

    static $inject = ['$scope', '$rootScope', '$state', 'NewToaster', 'ApontamentosImportacaoService', 'ApontamentosImportacaoModalService', 'ApontamentosResultadoModalService'];

    public busy: boolean = false;
    public entities: Array<IApontamentoImportacao>;
	public carregouParte: boolean = false;

    public fields = [
		{
			value: 'created_at',
			label: 'Data da importação',
			type: 'date',
			style: 'title',
			copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
				<span ng-bind="tableRowEntity.created_at | asDateTime | date: 'dd/MM/yyyy hh:mm:ss'">
                `;
                return someHtmlVar;
            },
		},
		{
			value: 'nomearquivooriginal',
			label: 'Nome do arquivo',
			type: 'string',
			style: 'title',
			copy: '',
		},
/*		{
			value: 'estabelecimento',
			label: 'Estabelecimento',
			type: 'string',
			style: 'title',
			copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span ng-bind="tableRowEntity.estabelecimento.nomefantasia">
                `;
                return someHtmlVar;
            },
		},*/
		{
			value: 'status',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <label class="label {{tableRowEntity.status === 'processando' || tableRowEntity.status === 'aguardando' ? 'label-aberta' :
				(tableRowEntity.status === 'processado' || tableRowEntity.status === 'processado com erros' || tableRowEntity.status === 'erro no processamento' || tableRowEntity.status === 'arquivo vazio' ? 'label-primary' : '')}}"
                ng-bind-template="{{tableRowEntity.status === 'processando' || tableRowEntity.status === 'aguardando' ? 'PROCESSANDO' :
				(tableRowEntity.status === 'processado' || tableRowEntity.status === 'processado com erros' || tableRowEntity.status === 'erro no processamento' || tableRowEntity.status === 'arquivo vazio' ? 'PROCESSADO' : '-')}}">
				</label><span ng-if="tableRowEntity.status === '-'" >{{tableRowEntity.status}}</span>
                `;
                return someHtmlVar;
            },
		},
        {
			value: 'resultado',
			label: 'Resultado',
			type: 'string',
			style: 'title',
			copy: '',
            customCollumnScope: {
                abrirModalResultado: (entity: IApontamentoImportacao): void => {
                    this.ApontamentosResultadoModalService.open(entity).result.catch(() => {/**/});
                },
                resultadoEnum: ResultadoApontamentosImportacaoEnum
            },
            customCollumnHtml: () => {
                let someHtmlVar = `
                <label ng-click="tableRowEntity.resultado > customCollumnScope.resultadoEnum.SUCESSO && customCollumnScope.abrirModalResultado(tableRowEntity)"
                ng-class="{'cursor-pointer': tableRowEntity.resultado > customCollumnScope.resultadoEnum.SUCESSO }"
                class="label {{tableRowEntity.resultado === customCollumnScope.resultadoEnum.SUCESSO ? 'label-success' :
				(tableRowEntity.resultado === customCollumnScope.resultadoEnum.ERRO ? 'label-danger' :
                (tableRowEntity.resultado === customCollumnScope.resultadoEnum.PARCIAL ? 'label-warning' : ''))}}"
                ng-bind-template="{{tableRowEntity.resultado === customCollumnScope.resultadoEnum.SUCESSO ? 'IMPORTADO COM SUCESSO' :
				(tableRowEntity.resultado === customCollumnScope.resultadoEnum.ERRO ? 'ARQUIVO INVÁLIDO' :
                (tableRowEntity.resultado === customCollumnScope.resultadoEnum.PARCIAL ? 'IMPORTADO PARCIALMENTE' : ''))}}">
				</label>
                `;
                return someHtmlVar;
            },
		},
	];

    constructor (
		public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
		public $state: angular.ui.IStateService,
        public NewToaster: any,
        public ApontamentosImportacaoService: ApontamentosImportacaoService,
        public ApontamentosImportacaoModalService: ApontamentosImportacaoModalService,
        public ApontamentosResultadoModalService: ApontamentosResultadoModalService
	) {
        // enquanto nao ha o paginacao no back, so sao carregadas as ultimas importacoes atraves da loadData()
        this.loadData();
        // this.ApontamentosImportacaoService.reload();
        $scope.$on('meurh_importacoesapontamentos_loaded', (event: any, args: any) => {
            this.entities = [... args];
            this.formataEntities();
            this.busy = false;
			this.carregouParte = true;
        });

        this.onSubmitSuccess();
        this.onSubmitError();
    }

    abrirModalImportar(): void {
        let fileDefault: IApontamentoImportacaoArquivo = {
            file: {} as FileList,
            operation: OperacaoApontamentosImportacaoEnum.SOMAR
        };
        let modal = this.ApontamentosImportacaoModalService.open(fileDefault);

        modal.result
        .then((response: IApontamentoImportacaoArquivo): void => {
            this.busy = true;
            let formData = new FormData();
            formData.append('estabelecimento', this.$rootScope.nsjGlobals.a.estabelecimento);
            formData.append('planilha', response.file[0]);
            formData.append('operacao', response.operation.toString());
            this.ApontamentosImportacaoService.save(formData);
        })
        .catch((error: any): void => {/**/});
    }

    formataEntities(): void {
        this.entities.forEach((entity: IApontamentoImportacao) => {
            entity.status = entity.status.trim().toLowerCase();
            if (entity.log) {
                entity.logObj = JSON.parse(entity.log);
                if (entity.status === 'processado com erros') {
                    entity.resultado = ResultadoApontamentosImportacaoEnum.PARCIAL;
                } else if (entity.status === 'processado') {
                    entity.resultado = ResultadoApontamentosImportacaoEnum.SUCESSO;
                } else if (entity.status === 'arquivo vazio') {
                    entity.resultado = ResultadoApontamentosImportacaoEnum.ERRO;
                }
            } else if (!entity.log && (entity.status === 'erro no processamento' || entity.status === 'arquivo vazio')) {
                entity.resultado = ResultadoApontamentosImportacaoEnum.ERRO;
            } else {
                entity.resultado = ResultadoApontamentosImportacaoEnum.PROCESSANDO;
            }
        });
    }

    loadData(): void {
        let quantidadePaginas = 1;
        this.ApontamentosImportacaoService.reload(quantidadePaginas);
    }

    loadMore(): void {
		this.ApontamentosImportacaoService.loadMore();
	}

    onSubmitSuccess(): void {
        this.$scope.$on('meurh_importacoesapontamentos_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A planilha foi enviada com sucesso!'
            });
            this.$state.reload();
        });
    }

    onSubmitError(): void {
        this.$scope.$on('meurh_importacoesapontamentos_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (args.response.data && typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                if (args.response.data.message === 'Validation Failed') {
                    let message = args.response.data.errors.errors[0];

                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro de Validação',
                        body: message,
                        bodyOutputType: 'trustedHtml'
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: args.response.data.message
                    });
                }
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao enviar a planilha!'
                });
            }
        });
    }
}

