import * as angular from 'angular';
import { MeurhPromocoesPassoquatroController } from './promocoes.passoquatro.controller';

export class MeurhPromocoesPassoquatroComponent implements angular.IComponentOptions {
	static selector = 'meurhPromocoesPassoquatroComponent';
	static controller = MeurhPromocoesPassoquatroController;
	static template = require('!!html-loader!./promocoes.passoquatro.html');
	static transclude = true;
	static bindings = {
		passo: '=',
		entity: '=',
		entitySolicitacao: '=',
	};
}
