export class MeurhVisualizarFotoModalController {

    static $inject = ['$uibModalInstance', 'entity'];

    constructor(
        public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        public entity: string
    ) {
    }

    close(): void {
        this.$uibModalInstance.close();
    }
}
