import angular from 'angular';

export const AtestadosAVencerListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        $stateProvider
            .state('relatorios_atestadosavencer', {
                url: '/relatorios/atestadosavencer',
                template: require('html-loader!./index/atestados-a-vencer.index.html'),
                controller: 'AtestadosAVencerIndexController',
                controllerAs: 'tstdsvncr_ctrl'
            });
    }
];

