import angular from 'angular';

export const BancodeHorasListRouting = [
	'$stateProvider',
	function ($stateProvider: angular.ui.IStateProvider): void {
		$stateProvider
			.state('bancodehoras', {
				url: '/ponto/bancodehoras',
				template: require('html-loader!./index/bancodehoras.index.html'),
				controller: 'BancodeHorasIndexController',
				controllerAs: 'mrh_bncdhrs_lst_cntrllr',
				reloadOnSearch: false
			});
	}
];
