import angular from 'angular';
import moment from 'moment';
import { ApontamentosModalConfirmacaoService } from '../modal.confirmacao/modal.confirmacao.service';
import { MeurhApontamentosService } from '../apontamentos.service';
import { IApontamento } from '../models/apontamentos.model';

export class MeurhApontamentosNewController {
    static $inject = ['$scope', '$rootScope', '$stateParams', '$state', 'MeurhApontamentosService', 'entity', 'NewToaster',
        '$uibModal', 'nsjRouting', '$q', '$http', 'ModalConfirmService', 'ApontamentosModalConfirmacaoService'];

    public SITUACAO_RASCUNHO = -1;
    public SITUACAO_PENDENTE = 0;
    public SITUACAO_APROVADO = 1;

    public busy: boolean = false;
    public tipo: any;
    public meses: any = ['', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    public action: any;
    public form: angular.IFormController;
    public semMovimentos: boolean = true;
    public movimentosTipoSempre: any;

    constructor(
        public $scope: angular.IScope,
        public $rootScope: any,
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: MeurhApontamentosService,
        public entity: IApontamento ,
        public NewToaster: any,
        public $uibModal: any,
        public nsjRouting: any,
        public $q: any,
        public $http: angular.IHttpService,
        public ModalConfirmService: any,
        public ApontamentosModalConfirmacaoService: ApontamentosModalConfirmacaoService) {

        this.$rootScope.carregandoApontamento = false;
        this.tipo = this.$stateParams['tipo'];
        this.entity = entity;
        if (this.entity.mesreferencia === undefined) {
            this.entity.mesreferencia = (new Date()).getMonth() + 1;
            if (this.$rootScope.configuracoes['FECHAMENTO_FOLHA'] < moment().date()) {
                this.entity.mesreferencia++;
            }
        }
        if (this.entity.anoreferencia === undefined) {
            this.entity.anoreferencia = (new Date()).getFullYear();
        }

        this.entity.valor = this.entity.mesreferencia.toString();
        this.action = 'insert';
    }

    $onInit(): void {
        this.onSubmitSuccess();
        this.onSubmitError();
    }

    openModalEnvio() {
        if (this.entity.apontamentostrabalhadores) {
            if (this.entity.apontamentostrabalhadores.length > 0) {
                let modal = this.ApontamentosModalConfirmacaoService.open(this.entity, this.movimentosTipoSempre, this.semMovimentos, this.SITUACAO_PENDENTE, this.busy);
                modal.result.then(() => {
                    this.submit();
                }).catch(() => {/* */});
            } else {
                if (this.entity) {
                    this.entity.situacao = this.SITUACAO_PENDENTE;
                    this.submit();
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Não é possível criar um movimento sem datas'
                    });
                }
            }
        } else {
            if (this.entity) {
                this.entity.situacao = this.SITUACAO_PENDENTE;
                this.submit();
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível criar um apontamento sem datas'
                });
            }
        }
    }

    ultimoDiaMes(mes: any, ano: any) {
        let result = 0;
        switch (mes) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                result = 31;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                result = 30;
                break;
            case 2:
                if ((ano % 4) === 0) {
                    result = 29;
                } else {
                    result = 28;
                }
                break;
        }
        return result;
    }

    getDataFechamentoFolha(mes: any, ano: any) {
        // se fechamento é para o próximo mês, incremento o mês e ano se for o caso
        if (this.$rootScope.configuracoes['FOLHA_FECHA_PROXIMO_MES'] === '1') {
            mes = mes + 1;
            if (mes === 13) {
                mes = 1;
                ano = ano + 1;
            }
        }
        let diaFechamento = this.$rootScope.configuracoes['FECHAMENTO_FOLHA'];
        let ultimoDiaMes = this.ultimoDiaMes(mes, ano);
        if (parseInt(diaFechamento, 10) > ultimoDiaMes) {
            diaFechamento = ultimoDiaMes.toString();
        }
        let data = diaFechamento + '-' + mes + '-' + ano;
        let datafinal = moment(data, 'DD-MM-YYYY').startOf('day');

        return datafinal;
    }

    habilitaApontamentoDias() {
        let datafinal = this.getDataFechamentoFolha(this.entity.mesreferencia, this.entity.anoreferencia);
        return moment().startOf('day').isAfter(datafinal);
    }

    setLotacoes(entity: any) {
        entity.apontamentostrabalhadores.forEach((apontamento: any) => {
            apontamento.movimentos.map((elemento: any) => {
                if (elemento.lotacao) {
                    elemento.lotacao = elemento.lotacao.lotacao;
                }
            });
        });
    }

    submit() {
        this.entity.mesreferencia = parseInt(this.entity.valor, 10);
        if (this.form.$valid && !this.entity.$$__submitting) {
            let entityCopy = angular.copy(this.entity);
            if (entityCopy.apontamentostrabalhadores) {
                this.setLotacoes(entityCopy);
            }
            this.entityService.save(entityCopy);
        } else {
            this.entity.situacao = null;
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros'
            });
        }
    }


    onSubmitSuccess(): void {
        this.$scope.$on('apontamentos_apontamentos_submitted', (event, args) => {
            if (this.entity.situacao === this.SITUACAO_RASCUNHO) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Sucesso ao inserir novo Rascunho!'
                });
            } else {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Sucesso ao inserir um novo movimento!'
                });
            }

            this.entityService.constructors.apontamento = args.entity.apontamento;
            this.$state.go('apontamentos_apontamentos_edit', angular.extend(this.entityService.constructors));
        });
    }

    onSubmitError() {
        this.$scope.$on('apontamentos_apontamentos_submit_error', (event, args) => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity);
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    this.entity.situacao = null;

                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: args.response.data.message
                        });
                    this.entity['$$__submitting'] = false;
                } else {
                    this.entity.situacao = null;
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: 'Ocorreu um erro ao inserir os movimentos!'
                        });
                    this.entity['$$__submitting'] = false;
                }
            }
        });
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    verificaEventos(entity: any) {
        let stop = false;
        entity.apontamentostrabalhadores.forEach(apontamento => {
            apontamento.movimentos.forEach((element: any) => {
                if (stop === false) {
                    if (this.valorExiste(element.evento.evento_limitealerta) && this.valorExiste(element.evento.evento_limiteerro)) {
                        if ((element.evento.evento_limitealerta < element.conteudo)
                            && (element.evento.evento_limiteerro >= element.conteudo)) {

                            stop = true;
                            this.NewToaster.pop({
                                type: 'warning',
                                title: 'Atenção: A rubrica ' + element.evento.nome + 'possui valor que excede o limite de alerta.'
                            });

                        }

                    } else if (this.valorExiste(element.evento.evento_limitealerta) && !this.valorExiste(element.evento.evento_limiteerro)) {
                        if (element.evento.evento_limitealerta < element.conteudo) {

                            stop = true;
                            this.NewToaster.pop({
                                type: 'warning',
                                title: 'Atenção: A rubrica ' + element.evento.nome + ' possui valor que excede o limite de alerta.'
                            });

                        }

                    }

                }
            });
        });
    }

    draftUpdate(entity: any) {
        this.verificaEventos(entity);
        this.semMovimentos = false;
        if (this.entity.apontamentostrabalhadores !== undefined && this.entity.apontamentostrabalhadores.length > 0) {
            this.entity.apontamentostrabalhadores.forEach((aptTrab, id) => {
                if (aptTrab.movimentos === undefined || aptTrab.movimentos === null || aptTrab.movimentos.length === 0) {
                    this.semMovimentos = true;
                }
            });
            if (this.semMovimentos) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível salvar um movimento sem rubricas'
                });
            } else {
                this.entity.situacao = this.SITUACAO_RASCUNHO;
                this.submit();
            }
        } else {
            if (this.entity) {
                this.entity.situacao = this.SITUACAO_RASCUNHO;
                this.submit();
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível salvar um movimento sem datas'
                });
            }
        }

    }
}
