import angular from 'angular';
import moment from 'moment';
import { PagamentosPontoService } from '../pagamentos.service';
import { ApuracoesPontoService } from '../../ApuracoesPonto/apuracoesponto.service';
import { RubricasPontoService } from '../../../../Apontamentos/rubricasponto.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IApuracaoPonto } from '../../models/apuracaoponto.model';
import { IPagamentoPonto } from '../../models/pagamentoponto.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';

type IPagamentosPontoFilters = {
	apuracao?: string | null;
	lotacao: { lotacao: string } | null;
	departamento: { departamento: string } | null;
	grupo: { grupo: string } | null;
	trabalhador: { trabalhador: string } | null;
	situacaoColaborador: string | null;
	empresa: string;
	estabelecimento: string;
};

export class PagamentosPontoIndexController {

    static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$stateParams',
		'$location',
		'PagamentosPontoService',
		'ApuracoesPontoService',
		'RubricasPontoService',
		'ModalConfirmService',
		'ModalPagamentoConfirmService',
		'ListagemService',
		'NewToaster'
	];

    // initial state
	public displayEntities: IPagamentoPonto[] = [];
	public entities: any = [];
    public apuracoes: Array<IApuracaoPonto> = [];
    public busy: boolean = false;
    public apuracoesFirstLoad = true;
	public apuracoesSelect:  Array<any> = [];
	public filters: IPagamentosPontoFilters = {
		apuracao: null,
		lotacao: null,
		departamento: null,
		grupo: null,
		trabalhador: null,
		situacaoColaborador: '00',
		empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
	};
	public mes: any;
	public ano: any;
	public filterActive: boolean = false;
	public filterApuracoesStatusPagamento: number | null = null;
    public statusPagamentoInterval: NodeJS.Timeout;
	public constructors: any = {};
	public nomeArquivoDownload: string = 'folhasponto-';
	public rubricasponto: Array<any> = [];

    // table
	public checkboxPreenchidos: Array<IPagamentoPonto> = [];
	public carregouTudo: boolean = false;
	public carregouPart: boolean = false;
	public checkToggle: any = {};
	public fieldGroups: Array<any> = [];
	// *** comentado para quando for implementar o agrupamento de colunas ***
	// public fieldGroups = [
	// 	{
	// 		name: ' ',
	// 		colspan: '4',
	// 	},
	// 	{
	// 		name: 'Horas Extras',
	// 		colspan: '8',
	// 		class: 'table-group-collumn-header'
	// 	},
	// 	{
	// 		name: ' ',
	// 		colspan: '2',
	// 	},
	// ];
	public fields: Array<any> = [];

	public listagemPermissao: Array<string> = ['visualizar_pagamentos'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public estabelecimentos: Array<Estabelecimento>;
    public empresas: Array<Empresa>;


    constructor (
		public $rootScope: angular.IRootScopeService & {
			liberadoTela: (arg: string) => boolean,
			temPermissao: (arg: string) => boolean,
			temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
			configuracoes: any,
			session: any,
			nsjGlobals: any
		},
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: angular.ILocationService,
		public PagamentosPontoService: PagamentosPontoService,
		public ApuracoesPontoService: ApuracoesPontoService,
		public RubricasPontoService: RubricasPontoService,
		public ModalConfirmService: any,
		public ModalPagamentoConfirmService: any,
		public ListagemService: ListagemService,
		public NewToaster: any
	) {
		if (
			!this.$rootScope.temPermissao('visualizar_pagamentos') ||
			!this.$rootScope.liberadoTela('LIBERAR_PAGAMENTOS')
		) {
            $state.go('dp');
        }

        this.busy = true;
		this.carregaApuracoes();
		this.montaListagemVisibilidade();

        $scope.$on('apuracoesponto_index_loaded', () => {
			if (this.ApuracoesPontoService.loadParams.finished ||
                this.ApuracoesPontoService.loadParams.to_load === 0
            ) {
                this.filterApuracoesStatusPagamento = null;
                this.montaApuracaoSelect(this.apuracoesFirstLoad);
                this.apuracoesFirstLoad = false;
            }
        });

        $scope.$on('apuracoesponto_loaded', (event: angular.IAngularEvent, args: IApuracaoPonto) => {
            if (
                args &&
                this.filterApuracoesStatusPagamento !== args.apuracaoprocessamento.statuspagamento
            ) {
                this.apuracoes = this.apuracoes.map(apuracao => {
                    if (apuracao.apuracaoponto === args.apuracaoponto) {
                        apuracao.apuracaoprocessamento.statuspagamento = args.apuracaoprocessamento.statuspagamento;
                    }
                    return apuracao;
                });
		        this.filterApuracoesStatusPagamento = args.apuracaoprocessamento.statuspagamento;
            }
        });

        $scope.$on('pagamentoslancamentos_relatoriopagamento_loaded', (event: angular.IAngularEvent, args: any) => {
			this.RubricasPontoService.constructors['estabelecimento'] = this.filters.estabelecimento;
			this.RubricasPontoService.constructors['empresa'] = this.filters.empresa;
			this.rubricasponto = this.RubricasPontoService.reload();
        });

        $scope.$on('pagamentoslancamentos_relatoriopagamento_list_finished', (event:  angular.IAngularEvent, args: any) => {
			this.carregouTudo = true;
			this.entities = args;
			if (this.fields.length > 3) {
				this.montaDisplayEntities();
			} else {
				this.montaFieldsTable();
			}
			this.busy = false;
		});

        $scope.$on('apontamentos_rubricasponto_index_service_list_finished', (event: angular.IAngularEvent, args: any) => {
			this.rubricasponto = args;
			this.montaFieldsTable();
        });

        $scope.$on('apuracoesponto_liberarpagamentos_iniciado', () => {
            this.carregaApuracoes();
			this.NewToaster.pop({
				type: 'success',
				title: 'Pagamentos liberados com sucesso.',
			});
        });

        $scope.$on('apuracoesponto_liberarpagamentos_erro', () => {
			this.busy = false;
			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao liberar pagamentos.',
			});
        });

        $scope.$on('apuracoesponto_regerarpagamentos_iniciado', (event: angular.IAngularEvent, args: any) => {
			this.busy = false;
			this.NewToaster.pop({
				type: 'success',
				title: 'Pagamentos regerados com sucesso.',
			});
        });

        $scope.$on('apuracoesponto_regerarpagamentos_erro', (event: angular.IAngularEvent, args: any) => {
			this.busy = false;
			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao regerar pagamentos.',
			});
        });

        $scope.$on('apuracoesponto_regerarpagamentosindividual_iniciado', (event: angular.IAngularEvent, args: any) => {
			this.busy = false;
			this.NewToaster.pop({
				type: 'success',
				title: 'Pagamentos do colaborador gerados novamente com sucesso.',
			});
        });

        $scope.$on('apuracoesponto_regerarpagamentosindividual_erro', (event: angular.IAngularEvent, args: any) => {
			this.busy = false;
			this.NewToaster.pop({
				type: 'error',
				title: 'Ocorreu um erro ao gerar pagamentos do colaborador.',
			});
        });

        $scope.$on('pagamentoslancamentos_relatoriopagamentoexportacao_sucesso', (event: angular.IAngularEvent, args: any, response: any) => {
			this.trataDownload(args.data);
			this.busy = false;
        });

        $scope.$on('pagamentoslancamentos_relatoriopagamentoexportacao_erro', (event: angular.IAngularEvent, args: any) => {
			this.busy = false;
        });

        $scope.$watch('mrh_pgmts_lst_cntrllr.filterApuracoesStatusPagamento',
            (newValue: number | null, oldValue: number | null): void => {
                if (newValue !== oldValue) {
                    if (newValue === 4) {
                        clearInterval(this.statusPagamentoInterval);
                        this.statusPagamentoInterval = setInterval(() => {
                            this.ApuracoesPontoService.get(this.filters.apuracao!);
                        }, 60000);
                    } else {
                        clearInterval(this.statusPagamentoInterval);
                    }
                }
        }, true);

        $scope.$on('$destroy', () => {
            clearInterval(this.statusPagamentoInterval);
        });
    }

	montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
		const filtro = {
			empresa: this.filters.empresa,
			estabelecimento: this.filters.estabelecimento
		};
		this.filters = {
			apuracao: null,
			lotacao: null,
			departamento: null,
			grupo: null,
			trabalhador: null,
            situacaoColaborador: '00',
            ...filtro
        };
        this.apuracoesFirstLoad = true;
        this.carregaApuracoes();
        this.$rootScope.$broadcast('lotacao_refresh', filtro);
		this.$rootScope.$broadcast('departamento_refresh', filtro);
        this.$rootScope.$broadcast('grupo_refresh', filtro);
        this.$rootScope.$broadcast('trabalhador_refresh', filtro);
    }

	carregaApuracoes() {
		this.busy = true;
		this.ApuracoesPontoService.constructors['vigente'] = false;
		this.ApuracoesPontoService.constructors['empresa'] = this.filters.empresa;
		this.ApuracoesPontoService.constructors['estabelecimento'] = this.$rootScope.liberadoTela('LIBERAR_REFATORACAO_VISIBILIDADE') ? this.filters.estabelecimento : '';
		this.apuracoes = this.ApuracoesPontoService.reload();
	}

	montaDisplayEntities(): void {
		this.displayEntities = [];
		// aguardando campo statuspagamento ser retornado para descomentar as seguintes linhas.
		// let isOdd = 0;
		this.entities.forEach((entity: IPagamentoPonto, indexEntity: any) => {

			// if (this.filterApuracoesStatusPagamento === null || this.filterApuracoesStatusPagamento === 0) {
			// 	if (isOdd == 0) {
			// 		entity['rowcustomclass'] = 'table-yellow-bg';
			// 		isOdd = 1;
			// 	} else {
			// 		entity['rowcustomclass'] = 'table-yellow-bg-darker';
			// 		isOdd = 0;
			// 	}
			// }

			this.displayEntities[indexEntity] = angular.copy(entity);
			entity.pagamentos.forEach((element: any, index) => {
				let pagamento = angular.copy(element);
				pagamento['index'] = index;
				let pagamentoName = 'pagamento' + pagamento.rubricaponto;

				if (element.valor === 0 || !this.valorExiste(element.valor)) {
					pagamento.lancamento_formato = '';
				}
				if (element.tipo === '5' && element.valor > 0) {
					pagamento.lancamento_formato = element.valor;
				}

				this.displayEntities[indexEntity][pagamentoName] = angular.copy(pagamento);
			});
		});
	}

	montaFieldsTable(): void {
		// *** comentado para quando for implementar o agrupamento de colunas ***
		// let rubricas = [];
		// let rubricashoraextra = [];
		// this.rubricasponto.forEach((rubrica: any) => {
		// 	if (rubrica.nome.includes('Hora Extra') || rubrica.nome.includes('Horas Extras')) {
		// 		// rubrica.nome = rubrica.nome.replace('Hora Extra', '');
		// 		// rubrica.nome = rubrica.nome.replace('Horas Extras', '');
		// 		let nome = rubrica.nome.replace('Hora Extra', '').replace('Horas Extras', '');
		// 		rubrica.nome = nome;
		// 		rubricashoraextra.push(rubrica);
		// 	} else {
		// 		rubricas.push(rubrica);
		// 	}
		// });

		// this.displayEntities[0] = angular.copy(this.entities[0]);
		this.montaDisplayEntities();

		// inserindo primeiros campos do array de fields da table
		// sempre existirão as colunas: Colaboradores, Matrícula. (atualizado: 01/06/22)
		// caso for adicionar mais uma coluna obrigatória, atualizar o fields.length na linha 96
		// e no html da nsj-table na linha 143 e na 159.
		let fields: Array<any> = [
			{
				value: 'codigo',
				label: 'Matrícula',
				type: 'string',
				style: 'title',
				copy: '',
			},
			{
				value: 'nome',
				label: 'Colaboradores',
				type: 'string',
				style: 'title',
				copy: '',
			},
		];

		// *** comentado para quando for implementar o agrupamento de colunas ***
		// rubricas.forEach((rubrica: any) => {
		// 	let stop: boolean = false;
		// 	this.displayEntities.forEach((displayEntity: IPagamentoPonto) => {
		// 		for (let i in displayEntity) {
		// 			if (stop === false && displayEntity[i].codigo === rubrica.codigo) {
		// 				let pagamentoSelecionado = angular.copy(i);

		// 				field = {
		// 					value: pagamentoSelecionado + '.valor',
		// 					label: rubrica.nome,
		// 					type: 'string',
		// 					style: 'title',
		// 					copy: '',
		// 				};
		// 				fields.push(field);
		// 				stop = true;
		// 			}
		// 		// });
		// 		}
		// 	});
		// });
		let field: any = {};
		this.rubricasponto .forEach((rubrica: any) => {
			let stop: boolean = false;
			this.displayEntities.forEach((displayEntity: IPagamentoPonto) => {
				for (let i in displayEntity) {
					if (stop === false && displayEntity[i].codigo === rubrica.codigo) {
						let pagamentoSelecionado = angular.copy(i);

						field = {
							value: pagamentoSelecionado + '.lancamento_formato',
							label: rubrica.nome,
							type: 'string',
							style: 'title',
							copy: '',
						};
						fields.push(field);
						stop = true;
					}
				// });
				}
			});
		});
		fields.push({
			value: 'urlponto',
			label: 'Ações',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: () => {
				let someHtmlVar = `
				<a class="btn btn-sm" href="{{tableRowEntity.urlponto}}" target="_blank" ><i class="fa fa-external-link-alt" aria-hidden="true" uib-tooltip="Ponto" tooltip-placement="top"></i></a>
			  `;
				return someHtmlVar;
			},
		});
		this.fields = angular.copy(fields);
		this.busy = false;

		// *** comentado para quando for implementar o agrupamento de colunas ***
		// this.fieldGroups = [
		// 	{
		// 		name: ' ',
		// 		colspan: rubricas.length + 1,
		// 	},
		// 	{
		// 		name: 'Horas Extras',
		// 		colspan: rubricashoraextra.length,
		// 		class: 'table-group-collumn-header'
		// 	},
		// ];
	}

    /* INÍCIO - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */
	checar_todos(): void {
		this.checkToggle = document.getElementById('check_all') as HTMLInputElement;

		if (this.checkToggle.checked) {
			let checked = this.displayEntities.filter((entity: any): boolean => !entity.disableCheckbox);
			this.checkboxPreenchidos = angular.copy(checked);

			if (checked.length !== this.displayEntities.length) {
				this.NewToaster.pop({
					type: 'info',
					title: 'Atenção!',
					body: 'toaster-html-directive',
					directiveData: { entities: this.displayEntities },
					bodyOutputType: 'directive',
					timeout: 5000
				});
			}
		} else {
			this.checkboxPreenchidos = [];
		}
	}

	temTodosMarcados(): boolean {
		return !!this.displayEntities.length && (this.checkboxPreenchidos.length === this.displayEntities.length);
	}

	naoTemTodosMarcados(): boolean {
		return !!this.checkboxPreenchidos.length && !this.temTodosMarcados();
	}
	/* FIM - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */

	getRowCustomClass(entity: IPagamentoPonto & { rowcustomclass: string} ): string {
		return entity.rowcustomclass;
	}

	montaApuracaoSelect(selectAtual: boolean = true): void {
		this.apuracoesSelect = [];
		this.apuracoes.forEach((element: IApuracaoPonto) => {
			const inicioApuracao = moment(element.inicioapuracao, 'YYYY-MM-DD');
			const terminoApuracao = moment(element.terminoapuracao, 'YYYY-MM-DD');
			let apuracao = {
				periodo: inicioApuracao.format('DD/MM/YYYY') + ' a ' + terminoApuracao.format('DD/MM/YYYY') + this.getReferenciaApuracao(element),
				inicioapuracao: element.inicioapuracao,
				terminoapuracao: element.terminoapuracao,
				apuracaoponto: element.apuracaoponto,
				statuspagamento: element.apuracaoprocessamento.statuspagamento,
			};
			this.apuracoesSelect.push(apuracao);
			if (selectAtual && !this.valorExiste(this.$stateParams.apuracaoponto)) {
				this.filtraApuracaoAtual(inicioApuracao, terminoApuracao, apuracao.apuracaoponto);
			}
		});
		this.filterReload();
	}

	getReferenciaApuracao(apuracao: IApuracaoPonto) {
		if (apuracao.trabalhadorobj && apuracao.trabalhadorobj.trabalhador) {
            return ` - Trabalhador: ${apuracao.trabalhadorobj.nome}`;
        }
		if (apuracao.lotacaoobj && apuracao.lotacaoobj.lotacao) {
            return ` - Lotação: ${apuracao.lotacaoobj.nome} `;
        }
        if (apuracao.estabelecimentoobj && apuracao.estabelecimentoobj.estabelecimento) {
            return ` - Estabelecimento: ${apuracao.estabelecimentoobj.nomefantasia}`;
        }
        return '';
    }

	filtraApuracaoAtual(inicioApuracao: moment.Moment, terminoApuracao: moment.Moment, guidApuracao: string) {
		const dataAtual = moment();
		if (dataAtual.isBetween(inicioApuracao, terminoApuracao, undefined, '[]')) {
			this.filters.apuracao = guidApuracao;
		}
	}

	search(): void {
		this.busy = true;
		if (this.PagamentosPontoService.filter !== '') {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.PagamentosPontoService.filters, { 'q': this.PagamentosPontoService.filter }));
		} else {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.PagamentosPontoService.filters));
		}

		var filter = {
			search: this.PagamentosPontoService.filter,
			filters: angular.copy(this.PagamentosPontoService.filters)
		};

		this.PagamentosPontoService.search(filter);
	}

    filterReload(): void {
		this.busy = true;
        if (!this.valorExiste(this.filters.trabalhador)) {
            delete this.constructors.trabalhador;
			this.filters.trabalhador = null;
        } else {
			this.constructors['trabalhador'] = this.filters.trabalhador!.trabalhador;
		}

		if (!this.valorExiste(this.filters.lotacao)) {
            delete this.constructors.trabalhador_lotacao;
			this.filters.lotacao = null;
        } else {
			this.constructors['trabalhador_lotacao'] = this.filters.lotacao!.lotacao;
		}

        if (!this.valorExiste(this.filters.departamento)) {
            delete this.constructors.departamento;
			this.filters.departamento = null;
        } else {
			this.constructors['departamento'] = this.filters.departamento!.departamento;
		}

        if (!this.valorExiste(this.filters.grupo)) {
            delete this.constructors.grupo;
			this.filters.grupo = null;
        } else {
			this.constructors['grupo'] = this.filters.grupo!.grupo;
		}

		if (!this.valorExiste(this.filters.situacaoColaborador)) {
            delete this.constructors.trabalhador_status;
			this.filters.situacaoColaborador = null;
        } else {
			this.constructors['trabalhador_status'] = this.filters.situacaoColaborador;
		}

		// obrigatorio
		if (!this.valorExiste(this.filters.apuracao)) {
			this.filters.apuracao = this.valorExiste(this.$stateParams.apuracaoponto) ?
				this.$stateParams.apuracaoponto :
				this.apuracoes[0]?.apuracaoponto;
			if (!this.filters.apuracao) {
				this.entities = [];
				this.displayEntities = [];
				this.busy = false;
				return;
			}
		}
		this.constructors['apuracaoponto'] = this.filters.apuracao;

		let filterApuracaoObj = this.apuracoes.filter(element => element.apuracaoponto === this.filters.apuracao);
		this.filterApuracoesStatusPagamento = filterApuracaoObj[0]?.apuracaoprocessamento.statuspagamento;

		this.constructors.empresa = this.filters.empresa;
		this.constructors.estabelecimento = this.filters.estabelecimento;
		this.PagamentosPontoService.constructors = this.constructors;

        if (this.valorExiste(this.PagamentosPontoService.filter)) {
			this.search();
		} else {
			this.entities = this.PagamentosPontoService.reload();
		}
    }

	limparFiltros(): void {
		this.filters.trabalhador = null;
		this.filters.lotacao = null;
		this.filters.departamento = null;
		this.filters.grupo = null;
		this.filters.situacaoColaborador = null;
		this.filters.apuracao = null;
		this.filterReload();
	}

	loadMore(): void {
		this.PagamentosPontoService.loadMore();
	}

    goToPonto(entity: IPagamentoPonto): void {
		window.open(entity.urlponto, '_blank');
    }

	liberarPagamentos(ano: string, mes: string): void {
		this.busy = true;
		this.PagamentosPontoService.liberarPagamentos(this.filters.apuracao!, ano, mes);
	}

	liberarPagamentosConfirm(): void {
        let diaFechamento = this.$rootScope.configuracoes['FECHAMENTO_FOLHA'];
        let feechamentoProximoMes = this.$rootScope.configuracoes['FOLHA_FECHA_PROXIMO_MES'] === '1';
		let mesaux = moment().month() + 1;
		let anoaux = moment().year();
		if (feechamentoProximoMes) {
			mesaux = mesaux + 1;
			if (mesaux === 13) {
				anoaux = anoaux + 1;
				mesaux = 1;
			}
		}
        let textos = {
            title: 'Liberar Pagamentos',
            subtitle: 'Após os pagamentos serem liberados, serão gerados créditos e descontos, e os mesmos enviados para aprovação final. Sugerimos que as informações sejam verificadas antes de serem liberadas, pois essa ação não poderá ser revertida e consequentemente a apuração não poderá mais ser reaberta.',
            confirmText: 'Você tem certeza que deseja liberar os pagamentos',
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, liberar pagamentos',
        };
		let entity = {
			ano: anoaux,
			mes: mesaux,
			diaFechamento: diaFechamento,
			feechamentoProximoMes: feechamentoProximoMes
		};
		let confirm = this.ModalPagamentoConfirmService.open(entity, 'Liberar Pagamentos', false, textos);

        confirm.result.then((): void => {
			this.liberarPagamentos(entity.ano.toString(), entity.mes.toString());
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
	}

	regerarPagamentos(): void {
		this.busy = true;
		this.PagamentosPontoService.regerarPagamentos(this.filters.apuracao!);
	}

	regerarPagamentosConfirm(): void {
		let apuracaopontoDatas = this.apuracoesSelect.filter(element => element.apuracaoponto === this.filters.apuracao);
        let textos = {
            title: 'Gerar novamente',
            subtitle: 'Caso alguma alteração tenha sido feita nos pontos dos colaboradores, ao gerar novamente os pagamentos as informações geradas anteriormente poderão ser alteradas.',
            confirmText: 'Você tem certeza que deseja gerar novamente os pagamentos da apuração de ' + apuracaopontoDatas[0].periodo,
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, gerar novamente',
        };
		let confirm = this.ModalConfirmService.open({}, 'Gerar novamente', false, textos);

        confirm.result.then((): void => {
            this.regerarPagamentos();
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
	}

	regerarPagamentosIndividual(entity: IPagamentoPonto): void {
		this.busy = true;
		this.PagamentosPontoService.regerarPagamentosIndividual(this.filters.apuracao!, entity);
	}

	gerarNovamenteIndividualConfirm(entity: IPagamentoPonto): void {
        let textos = {
            title: 'Gerar novamente',
            subtitle: 'Caso alguma alteração tenha sido feita no ponto do colaborador, ao gerar novamente os pagamentos as informações geradas anteriormente poderão ser alteradas.',
            confirmText: 'Você tem certeza que deseja gerar novamente o pagamento do colaborador ' + entity.nome,
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, gerar novamente',
        };
		let confirm = this.ModalConfirmService.open({}, 'Gerar novamente', false, textos);

        confirm.result.then((): void => {
            this.regerarPagamentosIndividual(entity);
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
	}

	exportar(): void {
		this.busy = true;
		let objetoExportacao = {
			'apuracaoponto': this.filters.apuracao,
			'empresa': this.filters.empresa,
			'estabelecimento': this.filters.estabelecimento,
			'trabalhadores' : [] as { 'trabalhador': string }[],
		};
		this.checkboxPreenchidos.forEach((element: IPagamentoPonto) => {
			objetoExportacao.trabalhadores.push({'trabalhador': element.trabalhador});
		});
		this.PagamentosPontoService.exportar(objetoExportacao);
	}

	trataDownload(data: any): void {
		let nomeArquivo = '';
        var blob = new Blob([data]);
        let fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
        var a = document.createElement('a');

		let apuracao = '';
		this.apuracoesSelect.forEach((element: any) => {
			if (apuracao === '' && element.apuracaoponto === this.filters.apuracao) {
				apuracao = element.inicioapuracao + '-a-' + element.terminoapuracao;
			}
		});
		nomeArquivo = 'pagamentos-apuracao-' + apuracao + '.csv';

        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = fileURL;
        a.download = nomeArquivo;
        a.click();
        window.URL.revokeObjectURL(fileURL);
	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}
}

