import angular from 'angular';

import { MeurhQuadroHorariosIndexController } from './index/quadrohorarios.index.controller';

import { MeurhQuadroHorariosService } from './quadrohorarios.service';

import { MeurhHorariosListRouting } from './quadrohorarios.routes';

export const MeurhQuadroHorariosModule = angular.module('QuadroHorariosModule', ['ui.router.state', ])
    .controller('MeurhQuadroHorariosIndexController', MeurhQuadroHorariosIndexController)
    .service('MeurhQuadroHorariosService', MeurhQuadroHorariosService)
    .config(MeurhHorariosListRouting)
    .name;
