import angular from 'angular';

export class HorarioDetalhadoModalService {
    static $inject = ['$uibModal', ];

    constructor(
        public $uibModal: any,
    ) { }

    open(horario: any, parameters: any): any {
        return this.$uibModal.open({
            template: require('html-loader!./horario-detalhado.modal.html'),
            controller: [
                'horario',
                'NewToaster',
                '$uibModalInstance',
                'MeurhMudancaHorarioService',
                'HorariosService',
                function HorarioDetalhadoModal(
                    horario: any,
                    NewToaster: any,
                    $uibModalInstance: any,
                    meurhMudancaHorarioService: any,
                    horariosService: any,
                ): void {

                    let selfModal = this;
                    selfModal.NewToaster = NewToaster;
                    selfModal.parameters = parameters;
                    selfModal.horario = horario;
                    selfModal.alterouHorario = false;

                    selfModal.busy = false;
                    selfModal.busyTabelaHorario = false;

                    selfModal.$onInit = async () => {

                        selfModal.iniciouComHorario = horario == null ? false : true;

                        // usado para verificar se horário foi alterado
                        selfModal.horarioInicial = angular.copy(horario);

                        // se não iniciou com horário, pegar o primeiro horário da lista de horários e atribuir ao horário, para a modal sempre abrir com dados
                        if (!selfModal.iniciouComHorario && parameters.action !== 'show') {

                            selfModal.busy = true;

                            await horariosService._load({ 'empresa': parameters.empresa, 'descricaoHorario': true }, {}, '').then((horarios) => {
                                if (horarios.length > 0) {
                                    selfModal.horario = horarios[0];
                                }

                            }).finally(() => { selfModal.busy = false; });
                        }

                        selfModal.carregaHorarioDetalhado(selfModal.horario);
                    };

                    selfModal.submit = (): void => {
                        $uibModalInstance.close(selfModal.horario);
                    };

                    selfModal.close = (): void => {

                        $uibModalInstance.dismiss('fechar');

                    };

                    selfModal.carregaHorarioDetalhado = async (horario: any) => {

                        selfModal.busyTabelaHorario = true;

                        if (horario == null) {
                            selfModal.busyTabelaHorario = false;
                            selfModal.horarioInicial = null;
                            return;
                        }

                        if (horario.horario !== selfModal.horarioInicial?.horario) {
                            selfModal.alterouHorario = true;
                        } else {
                            selfModal.alterouHorario = false;
                        }

                        await meurhMudancaHorarioService.getHorarioDetalhado(horario.horario, { empresa: parameters.empresa, estabelecimento: parameters.estabelecimento })
                        .then((response: any) => {
                            selfModal.horarioDetalhado = response.data;
                        })
                        .catch((error) => {
                            selfModal.NewToaster.pop({
                                type: 'error',
                                title: 'Não foi possível carregar o horário detalhado'
                            });
                        })
                        .finally(() => {
                            selfModal.busyTabelaHorario = false;
                        });

                    };

                }
            ],
            controllerAs: '$ctrl',
            backdrop: 'static',
            windowClass: 'modal-horario-detalhado-admissao',
            resolve: {
                horario: (): any => {
                    return horario;
                }
            }
        });
    }

}
