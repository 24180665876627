import angular = require('angular');
import { ISolicitacaoAdmissao } from '../models/solicitacaoadmissao.model';

export class MeurhSolicitacoesadmissoesShowFormController implements ng.IController {
    /* @todo desenvolver o then e o catch da função showDocumento
    e remover o next-line:no-empty */
    static $inject = ['$scope', 'NewToaster', '$sce'];

    public idCount: number = 0;
    public entity: ISolicitacaoAdmissao;
    public form: angular.IFormController;

    constructor(
        public $scope: angular.IScope,
        public NewToaster: any,
        public $sce: angular.ISCEService
    ) {
        $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);
    }

    // meurhSolicitacoesadmissoesdocumentosForm() {
    //     let modal = this.MeurhSolicitacoesadmissoesdocumentosFormService.open({}, {});
    //     modal.result.then((subentity: any) => {
    //         subentity.$id = this.idCount++;
    //         if (this.entity.solicitacoesadmissoesdocumentos === undefined) {
    //             this.entity.solicitacoesadmissoesdocumentos = [subentity];
    //         } else {
    //             this.entity.solicitacoesadmissoesdocumentos.push(subentity);
    //         }
    //     })
    //         .catch((error: any) => {
    //             if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
    //                 this.NewToaster.pop({
    //                     type: 'error',
    //                     title: error
    //                 });
    //             }
    //         });
    // }

    /*
    *
    * * ANEXO **
    *
    */
    // previewDocumento(documento: any) {
    //     var modal = this.MeurhSolicitacoesadmissoesdocumentosVisualizarService.open(documento);
    //     // tslint:disable-next-line:no-empty
    //     modal.result.then(function () { }).catch(function () { });
    // }

    trustUrl(subentityURL: any): string {
        let url = this.$sce.trustAsResourceUrl(subentityURL);
        return url;
    }

    transformUrl(): void {
        this.entity.solicitacoesadmissoesdocumentos.forEach((element: any): void => {
            element.url = this.trustUrl(element.url);
        });
    }
}
