import angular from 'angular';

export class ConfiguracoesPortariaModalController {
  static $inject = ['$uibModalInstance', 'entity'];

  public form: angular.IFormController;
  public entityCopy: { nome: string, cpf: string };

  constructor(
    public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    public entity: { nome: string, cpf: string }
  ) {
    this.entityCopy = angular.copy(this.entity);
  }

  ok() {
    this.form.$submitted = true;
    if (this.form.$invalid) {
        return;
    }
    this.$uibModalInstance.close(this.entityCopy);
  }

  close() {
    this.$uibModalInstance.dismiss('fechar');
  }
}
