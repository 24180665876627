import ng from 'angular';
import { IModalConfirmacao } from '../models/modalconfirm';

export class MeurhAlteracoesDadosSalariaisConfirmController implements ng.IController {

    static $inject = [
        'entity',
        'textos',
        '$uibModalInstance'
    ];

    constructor(
        public entity: any,
        public textos: IModalConfirmacao,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    ) {}

    public confirmar() {
        this.$uibModalInstance.close(this.entity);
    }

    public close() {
        this.$uibModalInstance.dismiss('cancelar');
    }

}
