import angular from 'angular';
import { ListagemService } from '../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../core/usuario/models/usuario-model';
export class MeurhQuadroHorariosIndexController {
    static $inject = ['$rootScope', '$scope', '$state', '$stateParams', 'nsjRouting', '$log', '$document', 'MeurhQuadroHorariosService', 'moment', 'PagerService', 'RelatoriosService', 'ListagemService'];

    public lookupTrabalhadores: any = false;
    public primeiroLookupTrabalhadoresCarregado: any = false;
    public menuOrdenacaoAtiva = 'nome';
    public carregandoRelatorio: boolean = false;
    public filterData: any = {
        tipo: '-1',
        empresa: this.$rootScope.session.entities.empresa.empresa,
        estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
    };
    public tipoFuncionario: any = [
        'Funcionário',
        'Contribuinte',
        'Estagiário'
    ];
    public entities: any;
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_quadro_horarios'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

    constructor(
        public $rootScope: any,
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public nsjRouting: any,
        public $log: any,
        public $document: any,
        public MeurhQuadroHorariosService: any,
        public moment: any,
        public PagerService: any,
        public RelatoriosService: any,
        public ListagemService: ListagemService
    ) {
        if (!$rootScope.temPermissao('visualizar_quadro_horarios')) {
            $state.go('dp', $stateParams);
        }

        $rootScope.$on('Trabalhadores_loading', (event: any, args: any) => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        $rootScope.$on('Trabalhadores_loaded', (event: any, args: any) => {
            this.lookupTrabalhadores = false;
        });

        MeurhQuadroHorariosService.constructors.order = this.filterData.order = {
            'nome': 'ASC'
        };

        $rootScope.$on('cargo_refreshed', (event: any, args: any) => {
            if (args.length === 1) {
                this.filterData.cargo = args[0];
            }
        });

        this.montaListagemVisibilidade();
    }

    carregandoLookups() {
        return this.lookupTrabalhadores;
    }

    montaListagemVisibilidade() {
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filterData.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos() {
        this.estabelecimentos = this.filterData.empresa ? this.ListagemService.montaEstabelecimentos(this.filterData.empresa, this.empresas, this.listagemPermissao) : [];
        this.filterData.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento() {
        delete this.filterData.trabalhador;
        delete this.filterData.lotacao;
        delete this.filterData.horario;
        this.filter();
    }

    montaConstructorColaboradores() {
        return { 'status': this.filterData.situacao, 'tipo_excluir': 1, 'tipo': this.filterData.tipo, 'empresa': this.filterData.empresa, 'estabelecimento': this.filterData.estabelecimento, 'forcagestor': this.$rootScope.modoGestorPermissao('visualizar_quadro_horarios') };
    }

    montaConstructorLotacoes() {
        return { 'empresa': this.filterData.empresa, 'estabelecimento': this.filterData.estabelecimento };
    }

    montaConstructorHorarios() {
        return { 'empresa': this.filterData.empresa };
    }

    forcaGestor() {
        return false;
    }

    refresh() {
        if (this.filterData.estabelecimento) {
            this.$rootScope.$broadcast('cargo_refresh', { estabelecimento: this.filterData.estabelecimento.estabelecimento });
            this.filterData.cargo = null;
        }
    }

    isBusy() {
        return this.MeurhQuadroHorariosService.loadParams.busy;
    }

    finished() {
        return this.MeurhQuadroHorariosService.loadParams.finished;
    }

    redirectPdf() {
        this.carregandoRelatorio = true;

        let filterAux: any = {};
        filterAux.empresa = this.filterData.empresa;
        filterAux.estabelecimento = this.filterData.estabelecimento;
        filterAux.order = this.filterData.order ? this.filterData.order : null;
        filterAux.trabalhador = this.filterData.trabalhador ? this.filterData.trabalhador.trabalhador : null;
        filterAux.situacao = this.filterData.situacao;
        filterAux.lotacao = this.filterData.lotacao ? this.filterData.lotacao.lotacao : null;
        filterAux.horario = this.filterData.horario ? this.filterData.horario.horario : null;
        filterAux.tipo = this.filterData.tipo;

        let callback = () => {
            this.carregandoRelatorio = false;
        };

        this.RelatoriosService.abrirRelatorio('quadrohorarios_pdf', filterAux, callback);
    }

    getTipoFuncionario(tipo: any) {
        return !isNaN(tipo) ? this.tipoFuncionario[parseInt(tipo, 10)] : tipo;
    }

    loadMore() {
        this.MeurhQuadroHorariosService.loadMore();
    }

    filter() {
        let aux = angular.copy(this.filterData);
        if (this.filterData.trabalhador) {
            aux.trabalhador = aux.trabalhador.trabalhador;
        } else {
            delete aux.trabalhador;
        }
        if (this.filterData.lotacao) {
            aux.lotacao = aux.lotacao.lotacao;
        } else {
            delete aux.lotacao;
        }
        if (this.filterData.horario) {
            aux.horario = aux.horario.horario;
        } else {
            delete aux.horario;
        }

        aux.situacao = this.filterData.situacao;

        this.$rootScope.$broadcast('trabalhador_refresh', this.montaConstructorColaboradores());
        this.$rootScope.$broadcast('lotacao_refresh', this.montaConstructorLotacoes());
        this.$rootScope.$broadcast('horario_refresh', this.montaConstructorHorarios());
        this.MeurhQuadroHorariosService.constructors = aux;
        this.entities = this.MeurhQuadroHorariosService.reload();
    }

    imprimeDescanso(obj: any) {
        let str = '';
        let strCount = 0;
        if (obj.repousosegunda) {
            str += 'Segunda-feira ';
            strCount++;
        }
        if (obj.repousoterca) {
            str += 'Terça-feira ';
            strCount++;
        }
        if (obj.repousoquarta) {
            str += 'Quarta-feira ';
            strCount++;
        }
        if (obj.repousoquinta) {
            str += 'Quinta-feira ';
            strCount++;
        }
        if (obj.repousosexta) {
            str += 'Sexta-feira ';
            strCount++;
        }
        if (obj.repousosabado) {
            str += 'Sábado ';
            strCount++;
        }
        if (obj.repousodomingo) {
            str += 'Domingo ';
            strCount++;
        }

        str = strCount === 0 ? '-' : str;

        return str;
    }

    ordenar(param: any) {
        this.menuOrdenacaoAtiva = param;

        this.MeurhQuadroHorariosService.constructors.order = this.filterData.order = {
            [param]: 'ASC'
        };
        this.entities = this.MeurhQuadroHorariosService.reload();
    }
}
