
import * as angular from 'angular';

import { MeurhSolicitacoesalteracoesenderecosIndexController } from './index/solicitacoesalteracoesenderecos.index.controller';
import { MeurhSolicitacoesalteracoesenderecosShowFormController } from './showform/solicitacoesalteracoesenderecos.show.form.controller';
import { MeurhSolicitacoesalteracoesenderecosShowController } from './show/solicitacoesalteracoesenderecos.show.controller';
import { MeurhSolicitacoesalteracoesenderecosFormController } from './form/solicitacoesalteracoesenderecos.form.controller';
import { MeurhSolicitacoesalteracoesenderecosEditController } from './edit/solicitacoesalteracoesenderecos.edit.controller';
import { MeurhSolicitacoesalteracoesenderecosNewController } from './new/solicitacoesalteracoesenderecos.new.controller';

import { MeurhSolicitacoesalteracoesenderecosShowFormComponent } from './showform/solicitacoesalteracoesenderecos.show.form.component';
import { MeurhSolicitacoesalteracoesenderecosFormComponent } from './form/solicitacoesalteracoesenderecos.form.component';
import { MeurhSolicitacoesalteracoesenderecosConfirmFecharService } from './modalfechar/modal.fechar.service';

import { MeurhSolicitacoesalteracoesenderecosListRouting } from './solicitacoesalteracoesenderecos.routes';
import { MeurhSolicitacoesalteracoesenderecosService } from './solicitacoesalteracoesenderecos.service';

export const MeurhSolicitacoesalteracoesenderecosModule = angular.module('MeurhSolicitacoesalteracoesenderecosModule', ['ui.router.state', ])
    .controller('MeurhSolicitacoesalteracoesenderecosIndexController', MeurhSolicitacoesalteracoesenderecosIndexController)
    .controller('MeurhSolicitacoesalteracoesenderecosShowFormController', MeurhSolicitacoesalteracoesenderecosShowFormController)
    .controller('MeurhSolicitacoesalteracoesenderecosShowController', MeurhSolicitacoesalteracoesenderecosShowController)
    .controller('MeurhSolicitacoesalteracoesenderecosFormController', MeurhSolicitacoesalteracoesenderecosFormController)
    .controller('MeurhSolicitacoesalteracoesenderecosEditController', MeurhSolicitacoesalteracoesenderecosEditController)
    .controller('MeurhSolicitacoesalteracoesenderecosNewController', MeurhSolicitacoesalteracoesenderecosNewController)
    .component('meurhSolicitacoesalteracoesenderecosShowFormComponent', MeurhSolicitacoesalteracoesenderecosShowFormComponent)
    .component('meurhSolicitacoesalteracoesenderecosFormComponent', MeurhSolicitacoesalteracoesenderecosFormComponent)
    .service('MeurhSolicitacoesalteracoesenderecosService', MeurhSolicitacoesalteracoesenderecosService)
    .service('MeurhSolicitacoesalteracoesenderecosConfirmFecharService', MeurhSolicitacoesalteracoesenderecosConfirmFecharService)
    .config(MeurhSolicitacoesalteracoesenderecosListRouting)
    .name;
