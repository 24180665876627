import angular from 'angular';
import { MeurhAdmissoesFotoFormController } from './foto.form.controller';

export class MeurhAdmissoesFotoFormComponent implements angular.IComponentOptions {
	static selector = 'meurhAdmissoesFotoFormComponent';
	static controller = MeurhAdmissoesFotoFormController;
	static template = require('!!html-loader!./foto.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
