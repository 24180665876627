import angular from 'angular';
import { ITrabalhador } from '../../../../../../Trabalhadores/models/trabalhador.model';

export class MeurhEscalaColaboradoresFormModalService {
	static $inject = ['$uibModal'];

	constructor(public $uibModal: ng.ui.bootstrap.IModalService) {
	}

	open(
		colaboradoresSelecionados: Array<ITrabalhador>,
		empresa: string,
		estabelecimento: string
	): angular.ui.bootstrap.IModalInstanceService {
		return this.$uibModal.open({
			template: require('html-loader!./colaboradores.modal.html'),
			controller: 'MeurhEscalaColaboradoresFormModalController',
			controllerAs: 'mrh_scl_clbrdrs_frm_mdl_cntrllr',
			windowClass: 'nsj-modal-lg-no-height',
			backdrop: 'static',
			resolve: {
				colaboradoresSelecionados: () => colaboradoresSelecionados,
				empresa: () => empresa,
				estabelecimento: () => estabelecimento
			}
		});
	}
}
