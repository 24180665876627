import angular = require('angular');
import { NsjHttpInterceptorService } from './core/nsj-core';
export const routerConfig = ['$locationProvider', '$urlRouterProvider', '$httpProvider',
    function ($locationProvider: angular.ILocationProvider, $urlRouterProvider: angular.ui.IUrlRouterProvider, $httpProvider: angular.IHttpProvider) {

        $httpProvider.interceptors.push(NsjHttpInterceptorService.Factory);
        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise('/');
    }
];
