
import angular from 'angular';

import { MeurhSolicitacoesafastamentosretornoShowController } from './show/solicitacoesafastamentosretorno.show.controller';
import { MeurhSolicitacoesafastamentosretornoFormController } from './components/form/solicitacoesafastamentosretorno.form.controller';

import { MeurhSolicitacoesafastamentosretornoFormComponent } from './components/form/solicitacoesafastamentosretorno.form.component';

import { MeurhSolicitacoesafastamentosretornoService } from './solicitacoesafastamentosretorno.service';

import { MeurhSolicitacoesafastamentosretornoRouting } from './solicitacoesafastamentosretorno.routes';

export const MeurhSolicitacoesafastamentosretornoModule = angular.module('MeurhSolicitacoesafastamentosretornoModule', ['ui.router.state'])
    .controller('MeurhSolicitacoesafastamentosretornoShowController', MeurhSolicitacoesafastamentosretornoShowController)
    .controller('MeurhSolicitacoesafastamentosretornoFormController', MeurhSolicitacoesafastamentosretornoFormController)
    .component('meurhSolicitacoesafastamentosretornoFormComponent', MeurhSolicitacoesafastamentosretornoFormComponent)
    .service('MeurhSolicitacoesafastamentosretornoService', MeurhSolicitacoesafastamentosretornoService)
    .config(MeurhSolicitacoesafastamentosretornoRouting)
    .name;
