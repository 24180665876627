import angular from 'angular';
import { LoadParams } from '../../../shared/models/loadparams.model';
import { IFalta } from './models/falta.model';

export class MeurhSolicitacoesFaltasService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q', 'NewToaster'];

    public entity: IFalta;
    public entities: Array<IFalta> = [];
    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = {};
    public filters: any = {};
    public loadParams: LoadParams;
    public loading_deferred: any = null;
    public filter: any = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService,
        public NewToaster: unknown
    ) {
        this.loadParams = new LoadParams();
    }

    reload(): Array<IFalta> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any): Array<IFalta> {
        if (typeof filter !== 'undefined') {
            this.filter = filter.search;
            Object.keys(this.filters).forEach((k: any) => { delete this.filters[k]; });
            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');
                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        }

        return this.reload();
    }

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('meurh_solicitacoesfaltas_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
            .then((response: any): void => {
                resolve(response.data);
            })
            .catch((response: any): void => {
                reject(response);
            });

        });

    }

    load(): Array<IFalta> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any): void => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }
                        this.after['created_at'] = this.entities[this.entities.length - 1]['created_at'];
                        this.after['solicitacao'] = this.entities[this.entities.length - 1]['solicitacao'];
                    }
                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_solicitacoesfaltas_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }
                    this.loaded = true;

                    this.loadParams.to_load--;
                })
                .catch((error: any): void => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally((): void => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }

                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: string): IFalta | null {
        for (let i in this.entities) {
            if (this.entities[i]['solicitacao'] === identifier) {
                return this.entities[i];
            }
        }

        return null;
    }

    delete($identifier: any, force: boolean): void {
        if (typeof ($identifier) === 'object') {
            $identifier = $identifier['solicitacao'];
        }
        if ($identifier) {
            if (force || confirm('Tem certeza que deseja cancelar a solicitação?')) {
                this.loading_deferred = this.$q.defer();
                this.$http.delete(
                    this.nsjRouting.generate('meurh_solicitacoesfaltas_delete', angular.extend(this.constructors, { 'id': $identifier }), true),
                    { timeout: this.loading_deferred.promise }
                )
                .then((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesfaltas_deleted', {
                        entity: this.entity,
                        response: response
                    });
                })
                .catch((response: any) => {
                    this.$rootScope.$broadcast('meurh_solicitacoesfaltas_delete_error', {
                        entity: this.entity,
                        response: response
                    });
                });
            }
        }
    }

    _save(entity: IFalta,
        params: { empresa: string, estabelecimento: string },
        autosave: boolean): angular.IHttpPromise<any> {
        let method: string, url: string;
        if (entity['solicitacao']) {
            method = 'PUT';
            url = this.nsjRouting.generate('meurh_solicitacoesfaltas_put', {
                'id': entity['solicitacao'],
                ...params
            }, true);
        } else {
            method = 'POST';
            url = this.nsjRouting.generate('meurh_solicitacoesfaltas_create',
                angular.extend(this.constructors, params),
            true);
        }
        if (!autosave) {
            autosave = false;
            entity['$$__submitting'] = true;
        }
        let data = angular.copy(entity);
        if (data.hasOwnProperty('$$__submitting')) {
            delete data['$$__submitting'];
        }
        this.loading_deferred = this.$q.defer();
        return this.$http({
            method: method,
            url: url,
            data: data,
            timeout: this.loading_deferred.promise
        }).finally((): void => {
            if (!autosave) {
                entity['$$__submitting'] = false;
            }
        });
    }

    save(entity: IFalta,
        params: { empresa: string, estabelecimento: string },
        autosave: boolean = false): void {
        this._save(entity, params, autosave)
            .then((response: any): void => {
                if (response.data.solicitacao) {
                    entity['solicitacao'] = response.data.solicitacao;
                }
                entity['$$_saved'] = true;
                this.$rootScope.$broadcast('meurh_solicitacoesfaltas_submitted', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            })
            .catch((response: any): void => {
                this.$rootScope.$broadcast('meurh_solicitacoesfaltas_submit_error', {
                    entity: entity,
                    response: response,
                    autosave: autosave
                });
            });
    }

    get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http.get(
                this.nsjRouting.generate('meurh_solicitacoesfaltas_get', { 'id': identifier }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response: any): void => {
                this.$rootScope.$broadcast('meurh_solicitacoesfaltas_loaded', response.data);
                resolve(response.data);
            })
            .catch((response: any): void => {
                reject(response);
            });
        });
    }
}
