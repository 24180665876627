import angular from 'angular';
import moment from 'moment';
import { ITrabalhador } from 'modules/Trabalhadores/models/trabalhador.model';
import { MeurhSolicitacoespromocoesService } from '../promocoes.service';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';

export class MeurhPromocoesPassoquatroController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$location',
        '$transitions',
        'nsjRouting',
        'NewToaster',
        'MeurhSolicitacoespromocoesService',
        '$sce',
        'ModalConfirmService',
        '$q',
        'TiposDocumentosRequeridosService',
    ];

    public rascunhoSendoCriado: boolean = false;
    public busy: boolean = false;
    public passo: number = 2;
    public entity: ITrabalhador;
    public entitySolicitacao: any = {};
    public trabalhador: string = this.$stateParams['trabalhador'];
    public allowRefresh: boolean = false;
    public unidadesSalario = {
        1: 'Hora',
        2: 'Dia',
        3: 'Semana',
        4: 'Mês',
        5: 'Tarefa',
        6: 'Variável'
    };

    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;
    public action: string;

    // documentos anexos
    public mostrarAlerta: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public editanexo: boolean = true;
    public entityAnexo: IAnexo = {
		casodeuso: 'PROMOCOES'
	};
    public alterouanexo: boolean = false;
    public documentosValidados: boolean = false;

    constructor(
        public $rootScope: angular.IRootScopeService & {
            modoGestorPermissao: (arg: string) => boolean,
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: MeurhSolicitacoespromocoesService,
        public $sce: angular.ISCEService,
        public ModalConfirmService: any,
        public $q: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
    ) {
        this.onSalvarSuccess();
        this.onSalvarError();

        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    async $onInit(): Promise<void> {
        this.alteraEntity();

        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = await this.TiposDocumentosRequeridosService.load();
        this.onTiposDocumentosRequeridosFinished();

    }

    isBusy(): boolean {
        return this.busy;
    }

    alteraEntity(): void {
        this.entity.dataFormatted = moment(this.entity.data).format('DD/MM/YYYY');

        if (!this.entity.solicitacao) {
            this.action = 'insert';
        } else {
            this.action = 'update';
            this.entityAnexo.solicitacao = this.entity.solicitacao;
        }

    }

    salvar(rascunho: boolean): void {
        this.allowRefresh = false;
        this.busy = true;

        if (!rascunho) {
            this.entitySolicitacao.situacao = 0;
        } else {
            this.rascunhoSendoCriado = rascunho;
        }

        this.entityService.save(this.entitySolicitacao, {
            empresa: this.entity.empresa.empresa,
            estabelecimento: this.entity.estabelecimento.estabelecimento
        });
    }

    onSalvarSuccess(): void {
        this.$scope.$on('meurh_solicitacoespromocoes_submitted', (event: angular.IAngularEvent, args: any): void => {

            if (args.response.config.method === 'PUT') {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Solicitação de promoção editada com sucesso.'
                });
            } else if (this.rascunhoSendoCriado) {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Rascunho da Solicitação de promoção criado com sucesso.'
                });
            } else {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'Solicitação de promoção enviada com sucesso.'
                });
            }

            if (this.tiposdocumentosrequeridos?.length <= 0 || this.alterouanexo === false) {
                this.busy = false;
                this.$state.go('promocoes_show', {'trabalhador': args.entity.trabalhador.trabalhador, 'solicitacao': args.entity.solicitacao});
            } else {
                this.entityService.constructors.solicitacao = args.entity.solicitacao;
                this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
                this.entityAnexo['solicitacao'] = this.action === 'insert' ? args.entity.solicitacao : this.entity.solicitacao;

                this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

                this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                    this.busy = false;
                    this.$state.go('promocoes_show', {'trabalhador': args.entity.trabalhador.trabalhador, 'solicitacao': args.entity.solicitacao});
                });
            }

        });
    }

    onSalvarError(): void {
        this.$scope.$on('meurh_solicitacoespromocoes_submit_error', (event: angular.IAngularEvent, args: any): void => {
            this.busy = false;
            if (!!args.response.data?.errors?.children) {
                let finalMessage = '';
                const messageErrors = args.response.data.errors.children;

                for (let message in messageErrors) {
                    if (!!messageErrors[message]?.errors !== undefined) {
                        for (let error in messageErrors[message].errors) {
                            if (messageErrors[message].errors.hasOwnProperty(error)) {
                                finalMessage += '<li>' + '&bull; ' + messageErrors[message].errors[error] + '</li>';
                            }
                        }
                    }
                }
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Erro de Validação',
                    body: 'Os seguintes itens precisam ser alterados: <ul>' + finalMessage + '</ul>',
                    bodyOutputType: 'trustedHtml'
                });
            } else if (!!args.response.data?.errors) {
                let finalMessage = '';
                const messageErrors: string[] = args.response.data.errors;

                messageErrors.forEach((message: string) => {
                    finalMessage += '<li>' + '&bull; ' + message + '</li>';
                });

                this.NewToaster.pop({
                    type: 'error',
                    title: 'Erro de Validação',
                    body: 'Os seguintes itens precisam ser alterados: <ul>' + finalMessage + '</ul>',
                    bodyOutputType: 'trustedHtml'
                });
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao criar a solicitação de promoção.'
                });
            }
        });
    }

    calculaPasso(continua: boolean): void {
        this.allowRefresh = false;
        this.passo = continua ? this.passo + 1 : this.passo - 1;
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'promocoes' && this.allowRefresh) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, ' criação', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }

    validaDocumentosAnexos(): boolean {

        if (!this.tiposdocumentosrequeridos) {
            return true;
        }

        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio || tipodocumentorequerido.fileSelecionado;
        });
    }

    onTiposDocumentosRequeridosFinished(): void {
        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });
        });
    }

    resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = {
            'tiposolicitacao': this.tipoSolicitacaoEnum.PROMOCAO
        };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

}
