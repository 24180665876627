import angular from 'angular';
import moment from 'moment';
import { MeurhAfastamentosService } from '../afastamentos.service';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IAfastamento } from '../models/afastamento.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhAfastamentosIndexController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'MeurhAfastamentosService',
        'ListagemService',
        '$location',
        'NewToaster',
        'nsjRouting'
    ];

    public entities: Array<IAfastamento>;
    public filter: any = {};
    public filters: any = {};
    public tipo: string = '';
    public tipoInicial: string = '';
    public dataInicial: string | null = null;
    public dataFinal: string | null = null;
    public dataAtual: moment.Moment = moment();
    public constructors: any = {};
    public situacao: string = '';
    public tipohistorico: string = '';
    public busy: boolean = false;
    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_afastamentos'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public motivos = [
      {
        id: 0,
        descricao: 'Acidente de Trabalho',
        tipohistorico: '001'
      },
      {
        id: 1,
        descricao: 'Prorrogação decorrente do mesmo acidente de trabalho',
        tipohistorico: '002'
      },
      {
        id: 2,
        descricao: 'Doença',
        tipohistorico: '003'
      },
      {
        id: 3,
        descricao: 'Acidente não relacionado ao trabalho',
        tipohistorico: '003'
      },
      {
        id: 4,
        descricao: 'Prorrogação decorrente da mesma doença',
        tipohistorico: '004'
      },
      {
        id: 5,
        descricao: 'Prorrogação decorrente do mesmo acidente não relacionado ao trabalho',
        tipohistorico: '004'
      },
      {
        id: 6,
        descricao: 'Paternidade',
        tipohistorico: '005'
      },
      {
        id: 7,
        descricao: 'Maternidade',
        tipohistorico: '006'
      },
      {
        id: 8,
        descricao: 'Maternidade de filho com microencefalia',
        tipohistorico: '033'
      },
      {
        id: 9,
        descricao: 'Maternidade por aborto expontâneo',
        tipohistorico: '008'
      },
      {
        id: 10,
        descricao: 'Serviço militar',
        tipohistorico: '009'
      },
      {
        id: 11,
        descricao: 'Licença não remunerada ou sem vencimentos',
        tipohistorico: '011'
      },
      {
        id: 12,
        descricao: 'Suspensão',
        tipohistorico: '018'
      },
      {
        id: 13,
        descricao: 'Licença remunerada',
        tipohistorico: '025'
      },
      {
        id: 14,
        descricao: 'Adoção',
        tipohistorico: '026'
      },
      {
        id: 15,
        descricao: 'Guarda judicial de criança',
        tipohistorico: '026'
      },
      {
        id: 16,
        descricao: 'Vítima de violência - Lei Maria da Penha', // (não exibir para homens)
        tipohistorico: '028'
      },
      {
        id: 17,
        descricao: 'Prorrogação de licença maternidade',
        tipohistorico: '035'
      },
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
          temPermissao: (arg: string) => boolean,
          temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
          session: any,
          nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public entityService: MeurhAfastamentosService,
        public ListagemService: ListagemService,
        public $location: angular.ILocationService,
        public NewToaster: any,
        public nsjRouting: any
    ) {
        this.entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = this.entityService.filters = {};
        this.montaListagemVisibilidade();
        this.filter.search = $stateParams['q'];
        this.tipo = $stateParams['tipo'];
        this.tipoInicial = $stateParams['tipo'];
        this.constructors = entityService.constructors = [];

        this.entities = this.filterReload();

        $rootScope.$on('meurh_solicitacoesafastamentos_list_finished', (event: any, args: any) => {
            this.busy = false;

        });
        $rootScope.$on('meurh_solicitacoesafastamentos_loaded', (event: any, args: any) => {
            this.busy = false;

        });
        this.$scope.$on('meurh_solicitacoesafastamentos_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });

        this.$scope.$on('meurh_solicitacoesafastamentos_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.busy = true;
            this.entities = [];
            this.entities = this.filterReload();
        });
    }

    montaListagemVisibilidade(): void {
      this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
      this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
      this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
      this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
      this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
      this.filters.estabelecimento = '';
      this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
      this.entityService.constructors['empresa'] = this.filters.empresa;
      this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
      this.filterReload();
    }

    search(): Array<IAfastamento> {
        if (this.filter.search !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.filter.search }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, this.entityService.constructors));
        }
        return this.entityService.search(this.filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    filterReload(): Array<IAfastamento> {
        delete this.constructors.situacao;
        if (this.situacao !== '') {
            this.constructors.situacao = this.situacao;
        }
        delete this.constructors.tipohistorico;
        if (this.tipohistorico !== '') {
            this.constructors.tipohistorico = this.tipohistorico;
        }

        if (
            this.dataInicial !== null &&
            this.dataFinal !== null &&
            this.dataInicial !== '' &&
            this.dataFinal !== '' &&
            this.dataInicial > this.dataFinal
        ) {
        this.NewToaster.pop({
            type: 'error',
            title: 'A data inicial não pode ser posterior a data final'
        });
        return [];
        }
        let created_at: Array<{condition: string, value: string}> = [];
        if (
            this.dataInicial === undefined ||
            (this.dataInicial !== null && this.dataInicial.length === 0)
        ) {
            this.dataInicial = null;
            this.constructors.datainicial = null;
        }
        if (this.dataInicial !== null) {
            created_at.push({
                condition: 'gte',
                value: this.dataInicial + ' 00:00:00'
            });
            delete this.constructors.datainicial;
        }
        if (
            this.dataFinal === undefined ||
            (this.dataFinal !== null && this.dataFinal.length === 0)
        ) {
            this.dataFinal = null;
            this.constructors.datafinal = null;
        }
        if (this.dataFinal !== null) {
            created_at.push({
                condition: 'lte',
                value: this.dataFinal + ' 23:59:00'
            });
            delete this.constructors.datafinal;
        }

        this.constructors.created_at = created_at;
        this.entityService.constructors = this.constructors;
        return this.entityService.reload();
    }

    filtraTipo(): void {
        if (this.entityService.constructors.hasOwnProperty('trabalhador_tipo') && (this.tipo === '' || this.tipo === '-1')) {
            delete this.entityService.constructors.trabalhador_tipo;
            this.entities = this.entityService.search();
        } else {
            this.entityService.constructors.trabalhador_tipo = this.tipo;
            this.entities = this.entityService.search();
        }
    }

    generateRoute(route: string, params: any): string {
        return this.nsjRouting.generate(route, params, true);
    }

    isBusy(): boolean {
        return this.entityService.loadParams.busy;
    }
}
