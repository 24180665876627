
import angular from 'angular';
import { MeurhInclusaodependenteService } from '../inclusaodependente.service';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { MeurhSolicitacoes } from '../../Solicitacoes/services/solicitacoes.service';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IInclusaodependente } from '../models/inclusaodependente.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { IAnexo } from '../../Anexos/models/anexo.model';

export class MeurhInclusaodependenteShowController implements angular.IController {
    static $inject = [
        '$scope',
        '$stateParams',
        '$state',
        'MeurhInclusaodependenteService',
        'entity',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        'ModalConfirmService',
        'MeurhSolicitacoes',
        '$rootScope',
    ];

    public constructors: any = {};
    public action = 'show';
    public tipo = this.$stateParams['tipo'];
    public form: angular.IFormController;
    public busy: boolean = false;
    public entitySave: IInclusaodependente;
    public dataAtual = new Date();
    public expanded: boolean = false;
    public path: number = this.$stateParams.path;

    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;

    // documentos anexos
    public tiposdocumentosrequeridos: Array<any>;
    public entityAnexo: IAnexo = {};
    public editanexo: boolean = false;
    public busyTipoDocumentos: boolean = true;

    public gestaoBeneficioAvancadaAtiva: boolean;

    constructor(
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhInclusaodependenteService,
        private entity: any,
        private NewToaster: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private ModalConfirmService: any,
        private MeurhSolicitacoes: MeurhSolicitacoes,
        private $rootScope: angular.IRootScopeService & { nsjGlobals: any, },
    ) {
        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo.casodeuso = 'INCLUSAODEPENDENTE';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();
        this.onTiposDocumentosRequeridosFinished();
        this.gestaoBeneficioAvancadaAtiva = this.$rootScope.nsjGlobals.a.configuracoes.GESTAO_BENEFICIO_AVANCADA_ATIVA;
    }

    $onInit() {
        this.alteraEntity();
    }

    cancelar(): void {
        let confirm = this.ModalConfirmService.openCancelar(this.entity, 'solicitação de inclusão de dependente');
        confirm.result.then((entity: IInclusaodependente & ISolicitacao) => {
            this.busy = true;
            this.MeurhSolicitacoes.cancelar(this.entity).then((response: any) => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A solicitação de inclusão de dependente foi cancelada com sucesso.'
                });
                this.entityService.reload();
                this.$state.go('meurh_inclusaodependente', angular.extend(this.entityService.constructors));
            }).catch((response: any) => {
                if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: response.data.message
                    });
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao cancelar.'
                    });
                }
            }).finally(() => {
                this.busy = false;
            });
        }).catch((error: any) => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });

    }

    alteraEntity(): void {
        this.entity.origem = this.entity.origem ? this.entity.origem.toString() : this.entity.origem;
        this.entity.parentesco = this.entity.parentesco ? this.entity.parentesco.toString() : this.entity.parentesco;
        this.entity.sexo = this.entity.sexo ? this.entity.sexo.toString() : this.entity.sexo;
        this.entity.situacao = this.entity.situacao ? this.entity.situacao.toString() : this.entity.situacao;
        this.entity.tiporecebimento = this.entity.tiporecebimento ? this.entity.tiporecebimento.toString() : this.entity.tiporecebimento;
        this.entity.percentualpensaoalimenticia = this.entity.percentualpensaoalimenticia ? this.entity.percentualpensaoalimenticia /= 100 : this.entity.percentualpensaoalimenticia;
        this.entity.percentualpensaoalimenticiafgts = this.entity.percentualpensaoalimenticiafgts ? this.entity.percentualpensaoalimenticiafgts /= 100 : this.entity.percentualpensaoalimenticiafgts;
    }

    expandirComprimir(): void {
        this.expanded = !this.expanded;

        let listagem = angular.element(document.querySelector('.nsj-aside-list') as Element);
        let conteudo = angular.element(document.querySelector('.article-area') as Element);
        let formCard = angular.element(document.querySelector('#expand') as Element);
        let formWrapper = angular.element(document.querySelector('#form-wrapper') as Element);

        if (this.expanded) {
            listagem.css('display', 'none');
            conteudo.addClass('col-md-12');
            conteudo.removeClass('col-md-8');
            formCard.addClass('col-md-8');
            formCard.removeClass('col-md-12');
            formWrapper.removeClass('padding-content');
        } else {
            listagem.css('display', 'block');
            conteudo.addClass('col-md-8');
            conteudo.removeClass('col-md-12');
            formCard.addClass('col-md-12');
            formCard.removeClass('col-md-8');
            formWrapper.addClass('padding-content');
        }
    }

    private onTiposDocumentosRequeridosFinished(): void {
        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                tipodocumentorequerido.file = '';
                return tipodocumentorequerido;
            });
        });
    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': this.tipoSolicitacaoEnum.INCLUSAODEPENDENTE };
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
}
